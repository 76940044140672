import React from 'react';
import {Link} from 'react-router-dom';
import {wpRedirects} from "../modules/menu-items";


const wpRedirectFrom = Object.keys(wpRedirects);

const FooterLink = ({children, href, ...rest}) => {
    return wpRedirectFrom.includes(href) ? (
        <a
            href={wpRedirects[href]}
            {...rest} className="text-off-white block mb-1"
        >
            {children}
        </a>
    ) : (
        <Link to={href} {...rest} className="text-off-white block mb-1">
            {children}
        </Link>
    )
}

export default FooterLink;