import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { useSelector } from "react-redux";
import { ButtonPrimary } from "Components/Button";
import { token as tokenSelector } from "modules/auth";
import { LabelledText, WhitePanelWithHeader } from "ui";
import { markAsRead } from "services/rfpService";
import ConfirmationReplyModal from "./ConfirmationReplyModal";
import ReplyToVenueModal from "./ReplyToVenueModal";

const ConfirmationThread = ({ venueId, isVenue, data, expanded = true, managingTrinityUser }) => {
  const [modal, setModal] = useState(false);
  const token = useSelector(tokenSelector);
  const [read, setRead] = useState({});
  const {
    confirmation_messages,
    enquiry_venue_data_id,
  } = data || {};
  const getUser = type => {
    if (parseInt(type) === 1) {
      return isVenue ? (managingTrinityUser ? "Managing Trinity User": "The client") : "You";
    }
    return isVenue ? "You" : "The venue";
  };
  const canReply = () => {
    return true
    // if (isVenue && !date_venue_confirmed) {
    //   return true;
    // }
    // if (!isVenue && !date_user_confirmed) {
    //   return true;
    // }
    // return false;
  };
  return (
    <WhitePanelWithHeader
      title="Messages"
      collapsible
      defaultOpen={expanded}
    >
      <div>
        {confirmation_messages && confirmation_messages.map((message, i) => (
          <div
            key={message.id}
            className={classNames("flex flex-wrap", {
              "mb-5 border-dashed border-b border-black":

                i < confirmation_messages.length - 1,
            })}
          >
            <div className="w-1/3">
              <LabelledText label="From" text={getUser(message.type)} />
              <LabelledText
                label="Date sent"
                text={moment(message.created_at_utc).format("DD/MM/YYYY")}
              />
              {getUser(message.type) !== "You" &&
                !message.read_at &&
                !read[message.id] && (
                  <div className="w-full pb-3">
                    <ButtonPrimary
                      small
                      onClick={() => {
                        setRead(prev => ({ ...prev, [message.id]: true }));
                        markAsRead(message.id, token);
                      }}
                    >
                      Mark as read
                    </ButtonPrimary>
                  </div>
                )}
            </div>
            <div className="w-2/3 pl-5">
              <LabelledText
                label="Message"
                div
                text={
                  <>
                    <p>{message.message}</p>
                    {(message.price_related ||
                      message.terms_related ||
                      message.space_related) && (
                      <p>
                        <em>
                          Related to: {message.price_related && "price"}
                          {message.terms_related &&
                            `${message.price_related ? " & terms" : "terms"}`}
                          {message.space_related &&
                            `${
                              message.price_related || message.terms_related
                                ? " & space"
                                : "space"
                            }`}
                        </em>
                      </p>
                    )}
                  </>
                }
              />
            </div>
          </div>
        ))}

        {canReply() && (
          <div className="text-right">
            <ButtonPrimary onClick={() => setModal(true)}>
              Send message
            </ButtonPrimary>
          </div>
        )}

      </div>
      {modal && isVenue && (
        <ConfirmationReplyModal
          onClose={() => {
            setModal(false);
            window.location.reload();
          }}
          venueId={venueId}
        />
      )}
      {modal && !isVenue && (
        <ReplyToVenueModal
            enquiry_venue_data_id={enquiry_venue_data_id}
          onClose={() => {
            setModal(false);
            window.location.reload();
          }}
          venueId={venueId}
        />
      )}
    </WhitePanelWithHeader>
  );
};

export default ConfirmationThread;
