import VenueCard from "./VenueCard";
import ContactCard from "./ContactCard";
import React from "react";

let ConfirmationSidePanel = ({enquiryVenueData, data}) => {
  return (<div className={'px-3 col-w-full xl:col-w-1/6'}>
    <VenueCard venue={enquiryVenueData.venue} status={enquiryVenueData.status}/>
    <ContactCard
      clientDetails={data.client_details}
      venueContact={enquiryVenueData.venue_contact}
      companyDetails={data.company_details.name}
      companyBranch={data.agency_specific_data?.company_branch}
    />
  </div>)
}

export default ConfirmationSidePanel