import React from "react";
import CorporateBookingDeskForm from "../../../../Components/corporate/CorporateBookingDeskForm";
import { connect } from "react-redux";
import { token } from "../../../../modules/auth";
import { editCorporateBookingDesk } from "../../../../redux/actions";

const BookingDesksContainer = (props) => {
  const { bookingDesks, token, slug } = props;

  const onSubmitBookingDesks = formValues => {
    const bookingDeskId = formValues.get("id");
    return props.editCorporateBookingDesk(slug, bookingDeskId, formValues, token);
  };

  return (
    <div>
      {bookingDesks && bookingDesks.length > 0 ? (
        <>
          {bookingDesks.map(bookingDesk => {
            return <CorporateBookingDeskForm
              key={bookingDesk.id}
              onSubmit={onSubmitBookingDesks}
              bookingDesk={bookingDesk}
              form={`bookingDeskForm-${bookingDesk.id}`}
            />;
          })}
          <div className="mb-4 text-xs border border-grey p-2">
            <p>
              Legal Text replaces the default content displayed on the Confirmation PDF. Please use variables below in the same format for Legal Text (valid HTML allowed):
            </p>
            <ul className="list-reset font-bold">
              <li>:clientVar</li>
              <li>:venueVar</li>
              <li>:companyVar</li>
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: token(state),
  };
};

const mapDispatchToProps = {
  editCorporateBookingDesk,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDesksContainer);
