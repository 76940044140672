import axios from "axios";
import {
  HOTEL_GROUP_ACCOUNT_MANAGER_ERROR,
  HOTEL_GROUP_ACCOUNT_MANAGER_REQUEST,
  HOTEL_GROUP_ACCOUNT_MANAGER_REQUEST_RECEIVED,
  HOTEL_GROUP_SINGLE_ERROR,
  HOTEL_GROUP_SINGLE_REQUEST,
  HOTEL_GROUP_SINGLE_REQUEST_RECEIVED,
  HOTEL_GROUP_SINGLE_UNLOAD,
  HOTEL_GROUP_TYPES_ERROR,
  HOTEL_GROUP_TYPES_REQUEST,
  HOTEL_GROUP_TYPES_REQUEST_RECEIVED,
  HOTEL_GROUP_TYPES_UNLOAD,
  HOTEL_GROUP_VENUES_ERROR,
  HOTEL_GROUP_VENUES_REQUEST,
  HOTEL_GROUP_VENUES_REQUEST_RECEIVED,
  HOTEL_GROUP_VENUES_UNLOAD,
  HOTEL_GROUPS_LIST_ERROR,
  HOTEL_GROUPS_LIST_REQUEST,
  HOTEL_GROUPS_LIST_REQUEST_RECEIVED,
  HOTEL_GROUPS_UNLOAD,
  VENUE_SINGLE_ERROR,
  VENUE_SINGLE_REQUEST,
  VENUE_SINGLE_REQUEST_RECEIVED,
} from "../reducers/types";
import * as ROUTES from "../../Router/venue_routes";
import { removeEmptySearchParams } from "../../utils";
import handleErrors from "./utils";

let cancelTokenSource = axios.CancelToken.source();

export const hotelGroupsListRequest = () => ({
  type: HOTEL_GROUPS_LIST_REQUEST,
});

export const hotelGroupsListRequestReceived = data => ({
  type: HOTEL_GROUPS_LIST_REQUEST_RECEIVED,
  data,
});

export const hotelGroupsListError = error => ({
  type: HOTEL_GROUPS_LIST_ERROR,
  error,
});

export const hotelGroupsUnload = () => ({
  type: HOTEL_GROUPS_UNLOAD,
});

export const hotelGroupSingleRequest = () => ({
  type: HOTEL_GROUP_SINGLE_REQUEST,
});
export const hotelGroupSingleRequestReceived = (data) => ({
  type: HOTEL_GROUP_SINGLE_REQUEST_RECEIVED,
  data,
});
export const hotelGroupSingleError = error => ({
  type: HOTEL_GROUP_SINGLE_ERROR,
  error,
});
export const hotelGroupSingleUnload = () => ({
  type: HOTEL_GROUP_SINGLE_UNLOAD,
});

export const hotelGroupVenuesRequest = () => ({
  type: HOTEL_GROUP_VENUES_REQUEST,
});
export const hotelGroupVenuesRequestReceived = data => ({
  type: HOTEL_GROUP_VENUES_REQUEST_RECEIVED,
  data,
});

export const hotelGroupVenuesError = error => ({
  type: HOTEL_GROUP_VENUES_ERROR,
  error,
});

export const hotelGroupVenuesUnload = () => ({
  type: HOTEL_GROUP_VENUES_UNLOAD,
});

export const hotelGroupTypesRequest = () => ({
  type: HOTEL_GROUP_TYPES_REQUEST,
});

export const hotelGroupTypesRequestReceived = data => ({
  type: HOTEL_GROUP_TYPES_REQUEST_RECEIVED,
  data,
});

export const hotelGroupTypesError = error => ({
  type: HOTEL_GROUP_TYPES_ERROR,
  error,
});

export const hotelGroupTypesUnload = () => ({
  type: HOTEL_GROUP_TYPES_UNLOAD,
});

export const venueSingleRequest = () => ({
  type: VENUE_SINGLE_REQUEST,
});

export const venueSingleRequestReceived = data => ({
  type: VENUE_SINGLE_REQUEST_RECEIVED,
  data,
});

export const venueSingleError = error => ({
  type: VENUE_SINGLE_ERROR,
  error,
});

export const hotelGroupAccountManagerRequest = () => ({
  type: HOTEL_GROUP_ACCOUNT_MANAGER_REQUEST,
});

export const hotelGroupAccountManagerRequestReceived = data => ({
  type: HOTEL_GROUP_ACCOUNT_MANAGER_REQUEST_RECEIVED,
  data,
});

export const hotelGroupAccountManagerError = error => ({
  type: HOTEL_GROUP_ACCOUNT_MANAGER_ERROR,
  error,
});

export const fetchHotelGroups = (token, page = 1, queryParams = {}) => {
  cancelTokenSource.cancel();
  cancelTokenSource = axios.CancelToken.source();
  if (!queryParams?.page) {
    queryParams.page = page;
  }
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  return (dispatch) => {
    dispatch(hotelGroupsListRequest());
    return axios.get(window.API + ROUTES.API.HOTEL_GROUPS.LIST + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: cancelTokenSource.token,
    }).then(response => {
      dispatch(hotelGroupsListRequestReceived(response.data));
    }).catch(error => {
      dispatch(hotelGroupsListError(error));
    });
  };
};

export const createHotelGroup = (token, formValues) => {
  return (dispatch) => {
    dispatch(hotelGroupSingleRequest());
    return axios.post(window.API + ROUTES.API.HOTEL_GROUPS.LIST, formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(hotelGroupSingleRequestReceived(response.data));
      if (response && response.data) {
        window.location.replace(ROUTES.FRONTEND.HOTEL_GROUPS.SINGLE_VIEW.replace(":id", response.data.data.id));
      }
    }).catch(error => {
      dispatch(hotelGroupSingleError(error));
      handleErrors(error);
    });
  };
};

export const fetchHotelGroup = (id, token) => {
  return (dispatch) => {
    dispatch(hotelGroupSingleRequest());
    return axios.get(window.API + ROUTES.API.HOTEL_GROUPS.SINGLE_VIEW.replace(":id", id), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(hotelGroupSingleRequestReceived(response.data));
    }).catch(error => {
      dispatch(hotelGroupSingleError(error));
    });
  };
};

export const fetchHotelGroupTypes = (token) => {
  cancelTokenSource.cancel();
  cancelTokenSource = axios.CancelToken.source();
  return (dispatch) => {
    dispatch(hotelGroupTypesRequest());
    return axios.get(window.API + ROUTES.API.HOTEL_GROUPS.LIST_TYPES, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(hotelGroupTypesRequestReceived(response.data));
    }).catch(error => {
      dispatch(hotelGroupTypesError(error));
    });
  };
};

export const editHotelGroup = (id, token, formValues) => {
  return (dispatch) => {
    dispatch(hotelGroupSingleRequest());
    return axios.post(window.API + ROUTES.API.HOTEL_GROUPS.SINGLE_VIEW.replace(":id", id), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(hotelGroupSingleRequestReceived(response.data));
    }).catch(error => {
      dispatch(hotelGroupSingleError(error));
      handleErrors(error);
    });
  };
};

export const editVenueForHotelGroup = (slug, token, formValues) => {
  return (dispatch) => {
    dispatch(venueSingleRequest());
    return axios.post(window.API + ROUTES.API.HOTEL_GROUP_VENUES.SINGLE.replace(":venueSlug", slug), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      window.location.reload();
      dispatch(venueSingleRequestReceived(response.data));
    }).catch(error => {
      dispatch(venueSingleError(error));
    });
  };
};

export const fetchVenuesForHotelGroup = (id, token) => {
  return (dispatch) => {
    dispatch(hotelGroupVenuesRequest());
    return axios.get(window.API + ROUTES.API.HOTEL_GROUP_VENUES.LIST.replace(":id", id), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(hotelGroupVenuesRequestReceived(response.data));
    }).catch(error => {
      dispatch(hotelGroupVenuesError(error));
    });
  };
};

export const assignAccountManagerToHotelGroup = (id, accountManagerId, token) => {
  return (dispatch) => {
    dispatch(hotelGroupAccountManagerRequest());
    return axios.post(window.API + ROUTES.API.HOTEL_GROUPS.ACCOUNT_MANAGER.replace(":id", id).replace(":accountManagerId", accountManagerId), null, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(hotelGroupSingleRequestReceived(response.data));
    }).catch(error => {
      dispatch(hotelGroupAccountManagerError(error));
    });
  };
};

export const unassignAccountManager = (id, accountManagerId, token) => {
  return (dispatch) => {
    dispatch(hotelGroupAccountManagerRequest());
    return axios.post(window.API + ROUTES.API.HOTEL_GROUPS.UNASSIGN_ACCOUNT_MANAGER.replace(":id", id).replace(":accountManagerId", accountManagerId), null, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(hotelGroupSingleRequestReceived(response.data));
    }).catch(error => {
      dispatch(hotelGroupAccountManagerError(error));
    });
  };
};

export const uploadHotelGroupLogo = (id, token, formValues) => {
  return (dispatch) => {
    dispatch(hotelGroupSingleRequest());
    return axios.post(window.API + ROUTES.API.HOTEL_GROUPS.LOGO_IMAGE.replace(":id", id), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(hotelGroupSingleRequestReceived(response.data)))
      .catch(error => dispatch(hotelGroupSingleError(error)));
  };
};
