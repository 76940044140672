import React from "react";
import { formatMoney } from "./common";

export const TableHeader = ({ className = "", brand, ...props }) => (
  <th
    className={`text-3xl py-4 text-left ${
      brand ? "text-brand font-normal" : "font-light"
    } ${className}`}
    {...props}
  />
);

export const RowHeader = ({ className = "", ...props }) => (
  <th className={`py-3 text-left ${className}`} {...props} />
);

export const GroupHeader = ({ className = "", ...props }) => (
  <th className={`pt-3 pb-1 text-left ${className}`} {...props} />
);

export const GroupSubHeader = ({ className = "", ...props }) => (
  <th className={`pb-3 text-left ${className}`} {...props} />
);

export const Cell = ({ className = "", ...props }) => (
  <td className={`py-3 text-left ${className}`} {...props} />
);

export const MoneyCell = ({ value, ...props }) => (
  <Cell {...props}>{formatMoney(value)}</Cell>
);

export const TR = ({ className = "", noBoarder, ...props }) => (
  <tr
    className={
      (!noBoarder ? "border-t border-black " : "") +
      `hover:bg-off-white -mx-4 px-4 ${className}`
    }
    {...props}
  />
);
