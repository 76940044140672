import React, {Component} from "react";
import {string, number, arrayOf} from "prop-types";
import Pagination from "../../Components/PaginationWrapper";

import {userType, isInternal} from "../../modules/auth";

import {
    filterPostsByUserType,
    loadPosts,
    getPostDetails,
} from "../../modules/cms";

import {connect} from "react-redux";

import Loading from "Components/Loading";
import {NewsArticle} from "../Home/NewsArticle";

class LatestArticle extends Component {
    static propTypes = {
        categories: arrayOf(number).isRequired,
        userType: string.isRequired,
    };

    state = {
        loaded: false,
        error: false,
        article: undefined
    };

    loadArticle() {
        const {categories} = this.props;

        return loadPosts({
            categories,
            params: {
                per_page: 1,
                page: 1,
            },
        });
    }

    componentDidMount() {
        this.loadArticle().then(({posts}) => {
            this.setState(state => ({
                article: posts[0],
                loaded: true,
            }));
        });
    }

    render() {
        const {loaded, error, article} = this.state;
        const {userType, isInternal} = this.props;

        if (error) return <div>Error</div>;

        if (!loaded) {
            return (
                <div
                    className="container flex flex-col justify-center"
                >
                    <Loading />
                </div>
            );
        }
        if ( article === undefined ) {
            return null;
        }


        return <>
            {this.props.children}
            <NewsArticle
            {...getPostDetails(article)}
            linkState={{
                post: article,
            }}
        />
        </>

    }
}

export default connect(state => ({
    userType: userType(state),
    isInternal: isInternal(state),
}))(LatestArticle);
