import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom/cjs/react-router-dom';
import {generalInteraction} from "../modules/analytics";

const HomeVenueType = ({name, slogan, url, generalInteraction}) => {
  return (
    <div onClick={() => {
      generalInteraction({type: 'general_home_quicklink_click', data: slogan.props.children[0]})
    }}>
      <Link to={url}>
        <div
          className="bg-white text-black p-3 sm:pl-8 sm:pr-6 md:p-5 border border-grey-md rounded relative transition hover:bg-light-blue-grey h-full">
          <span className="font-body text-base md:text-lg font-normal">{name}</span>
          <div className="mb-0 md:mb-1 mt-4 xxl:text-2xl">
            {slogan}
          </div>
          <div className="justify-end hidden md:flex absolute" style={{right: 25, bottom: 28}}>
            <img src="/img/arrow-24.svg" alt="Arrow"/>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default connect(null, {generalInteraction})(HomeVenueType);
