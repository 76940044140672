import axios from "axios";
import { createHandleAxiosError } from "../../../modules/api-tools";

export default (value, props, setState) => {
  // remove any hidden fields
  let fieldsToClear = [...props.clearOnSearch];
  if (props.setId) fieldsToClear.push(`${props.name}_id`);
  if (typeof props.setOther === "string") {
    fieldsToClear.push(props.setOther);
  } else if (Array.isArray(props.setOther)) {
    fieldsToClear = fieldsToClear.concat(props.setOther);
  }
  fieldsToClear.forEach(field => {
    props.change(props.formId, field, "");
  });

  // enforce min length
  if (!value || value.length < props.minLength) {
    setState.minLengthWarning();
    return;
  }

  const { history, location } = props;
  const handleAxiosError = createHandleAxiosError({ history, location });

  // do the search
  setState.fetching();
  axios
    .get(props.url, {
      params: props.params(value),
      headers: props.auth
        ? {
            Authorization: `Bearer ${props.token}`,
          }
        : undefined,
      cancelToken: new axios.CancelToken(function executor(c) {
        setState.cancelToken(c);
      }),
    })
    .then(response => {
      setState.results(response.data);
    })
    .catch(
      handleAxiosError(err => {
        setState.error(err);
      })
    );
};
