import React, {Component} from 'react';
import {connect} from "react-redux";
import {token} from "../../../../modules/auth";
import {fetchCorporateAccountLocations, unloadCorporateAccountLocationsList} from "../../../../redux/actions";
import CorporateAccountLocationList from "./CorporateAccountLocationList";

const mapStateToProps = state => ({
  ...state.corporateAccountLocationsList,
  ...state.isFetchingLocations,
  token: token(state)
})

const mapDispatchToProps = {
  fetchCorporateAccountLocations,
  unloadCorporateAccountLocationsList
}

class CorporateAccountListContainer extends Component {
  componentDidMount() {
    this.props.fetchCorporateAccountLocations(this.props.match.params.slug, this.props.token);
  }

  componentWillUnmount() {
    this.props.unloadCorporateAccountLocationsList();
  }

  render() {
    const {corporateAccountLocationsList, isFetchingLocations} = this.props;
    const accountId = this.props.match.params.slug;
    return <CorporateAccountLocationList
      locations={corporateAccountLocationsList}
      isFetching={isFetchingLocations}
      accountId={accountId}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountListContainer)