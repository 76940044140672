export default (
  state = {
    auth: null,
    venue_data: {},
    image: null,
  },
  action
) => {
  switch (action.type) {
    case "@@analytics/AUTHENTICATE": {
      return {
        ...state,
        auth: action.payload, //fetching, error or token
      };
    }
    case "@@analytics/FETCH_VENUE_DATA": {
      return {
        ...state,
        venue_data: {
          ...state.venue_data,
          [action.payload.venue_id]: {
            ...state.venue_data[action.payload.venue_id],
            error: false,
            fetching: true,
          },
        },
      };
    }
    case "@@analytics/SET_VENUE_DATA": {
      return {
        ...state,
        venue_data: {
          ...state.venue_data,
          [action.payload.venue_id]: {
            error: false,
            fetching: false,
            start_date: action.payload.start_date,
            end_date: action.payload.end_date,
            data: action.payload.data,
          },
        },
      };
    }
    case "@@analytics/SET_VENUE_DATA_ERROR": {
      return {
        ...state,
        venue_data: {
          ...state.venue_data,
          [action.payload.venue_id]: {
            error: true,
          },
        },
      };
    }
    case "@@analytics/FETCH_ANALYTICS_IMAGE": {
      return {
        ...state,
        image: action.payload,
      };
    }
    default:
      return state;
  }
};
