import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { token as tokenSelector } from "modules/auth";
import { LabelledText } from "ui";
import { ButtonSpan } from "Components/Button";

const saveData = (function() {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.cssText = "display: none";
  return function(type, data, fileName) {
    const blob = new Blob([data], {
      type,
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const Additional = ({ attachments, notes }) => {
  const token = useSelector(tokenSelector);

  const handleDownload = async (fileName, url, type = "application/pdf") => {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      saveData(type, response.data, fileName);
    } catch (err) {
      alert("Sorry there was a problem fetching the file");
    }
  };

  return (
    <div>
      {notes && <LabelledText label="Additional notes" text={notes} />}
      {attachments.length > 0 && (
        <>
          <h4>Attachments</h4>
          {attachments.map(item => (
            <React.Fragment key={item.id}>
              <ButtonSpan
                className="mb-2 text-brand inline-block p-2 bg-white mr-1 border border-grey"
                onClick={() =>
                  handleDownload(
                    item.name,
                    item.attachment_url,
                    item.content_type
                  )
                }
              >
                {item.name}
              </ButtonSpan>
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

export default Additional;
