import axios from "axios";

import { CATEGORY_GROUP_IDS, sortBy } from "./utils";

const api = "https://cms.edgevenues.com/wp-json/wp/v2";

export const loadPosts = ({
  categories = undefined,
  categoryGroups = undefined,
  params: propParams = {},
} = {}) => {
  const params = { ...propParams };

  if (categories) {
    params.categories = categories.join(",");
  } else if (categoryGroups) {
    params.categories = categoryGroups
      .reduce(
        (group, category) => group.concat(CATEGORY_GROUP_IDS[category]),
        []
      )
      .join(",");
  }

  params._embed = 1;

  return axios
    .get(api + "/posts", {
      params,
    })
    .then(({ data, headers }) => {
      return {
        posts: sortBy(data, "modified_gmt", true),
        postCount: headers["x-wp-total"],
        pageCount: headers["x-wp-totalpages"],
      };
    }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
        console.error(error.config);
        return {
          posts: [],
          postCount: 0,
          pageCount: 0,
        };
      });;
};
