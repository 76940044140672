import React, { Component } from "react";
import { withAxios } from "../modules/withAxios";

import { Profile } from "../Components/destinations";
import Loading from "../Components/Loading";
import { shape, string, func } from "prop-types";
import { Error } from "../Components/Form";

const getDestinationParam = props =>
  props.match.params.destination.toLowerCase();

class Destination extends Component {
  static propTypes = {
    axios: func.isRequired,
    match: shape({
      params: shape({
        destination: string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
    error: false,
    destination: null,
  };

  loadDestination() {
    const { axios } = this.props;
    const destination = getDestinationParam(this.props);

    this.setState({
      loading: true,
      error: false,
    });

    axios
      .get(`/destinations/${destination}/`)
      .then(({ data }) => this.setState({ loading: false, destination: data }))
      .catch(
        axios.handleError(() => {
          this.setState({
            loading: false,
            error: true,
          });
        })
      );
  }

  reloadDestination() {
    this.setState({ destination: null });
    this.loadDestination();
  }

  componentDidMount() {
    this.loadDestination();
  }

  componentDidUpdate(prevProps) {
    if (getDestinationParam(prevProps) !== getDestinationParam(this.props)) {
      this.reloadDestination();
    }
  }

  render() {
    const { loading, error, destination } = this.state;

    if (loading) {
      return (
        <Loading
          classes="min-h-1/2-screen flex items-center justify-center"
          large
        />
      );
    }

    if (error) {
      return (
        <Error error="Sorry, There was an error loading this Destination." />
      );
    }

    return (
      <div>
        <div className="w-full xl:w-3/4 mx-auto">
          {destination ? <Profile destination={destination} /> : null}
        </div>
      </div>
    );
  }
}

export default withAxios(Destination);
