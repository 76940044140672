import React from "react";
import { bool, number, string } from "prop-types";
import { connect } from "react-redux";
import { isVenueAdmin, isAgent } from "modules/auth";

const ChecksheetBreakdown = (props) => {
  const {currency = {currency_code: "GBP"}} = props;
  const priceHeaders = () => {
    return (
      <React.Fragment>
        <th className={"p-2 text-right uppercase text-xs w-auto"}>Net Total</th>
        <th className={"p-2 text-right uppercase text-xs w-16"}>{currency.currency_code === 'GBP' ? `VAT rate` : `Tax Rate`}</th>
        <th className={"p-2 text-right uppercase text-xs w-auto"}>{currency.currency_code === 'GBP' ? `VAT amount` : `Tax amount`}</th>
        {props.showCommission ?
          <th className={"p-2 text-right uppercase text-xs w-20"}>Commission Rate %</th> : null}
        {props.showCommission ?
          <th className={"p-2 text-right uppercase text-xs w-20"}>Commission Value</th> : null}


        <th className={"p-2 text-right uppercase text-xs w-auto"}>Final Total</th>
        <th></th>
      </React.Fragment>
    );
  };

  const generateSpaces = (numberOfSpaces) => {
    let isCommission = props.showCommission;

    let spaceCount = numberOfSpaces + (isCommission ? 2 : 0) + 2;
    let spaces = [];
    for (let i = 0; i < spaceCount; i++) {
      spaces.push(<td key={"spaces-" + props.title + i}></td>);
    }
    return spaces;
  };

  const totalRow = (spaces, totalAmount) => {
    return (
      <tr className={"border-t-2 border-b-0 border-gray-500"}>
        <td className={"p-2 pl-0 text-left font-bold"}>Totals</td>
        {generateSpaces(spaces)}
        <td className={"p-2 text-right font-bold "}>
          {totalAmount}
        </td>
        <td></td>
      </tr>
    );
  };


  return (
    <div>
      {props.title &&
        <h2 className="mb-3 text-3xl">{props.title}</h2>
      }
      <table className={"table-with-border table-responsive"}>
        <thead>
        <tr>
          {props.headers}
          {props.noPriceHeaders === true ? null : priceHeaders()}
        </tr>
        </thead>
        <tbody>
        {props.children}
        {props.noTotals === true ? null : totalRow(props.spaces, props.totalAmount)}
        </tbody>
      </table>
    </div>
  );

};
ChecksheetBreakdown.propTypes = {
  title: string,
  noTotals: bool,
  noPriceHeaders: bool,
  spaces: number,

};

export default connect(state => ({
  isVenue: isVenueAdmin(state),
  isAgency: isAgent(state),
}))(ChecksheetBreakdown);
