import React from "react";
import CmsContent from "../../Components/CmsContent";
import TrinityNews from "../trinity-news/TrinityNews";
import {CATEGORY_GROUP_IDS} from "../../modules/cms";
import Input from "../../Components/Form/Input";

export default class CorporateInsights extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            term: ''
        }
    }

    setTerm = term => {
        this.setState({term});
    }

    render() {
        const title = 'Expert Insights';
        return (
            <div className="container">
                <div>
                    <div className="container pt-5">
                        <h1
                            className={`text-xl sm:flex justify-between items-center uppercase text-white bg-brand p-2 mb-5`}
                        >
                            Expert Insights
                            <Input
                                input={{
                                    onChange: e => this.setTerm(e.target.value),
                                    value: this.state.term,
                                }}
                                labelClassName="sr-only"
                                iconRight="/img/search-icon.svg"
                                meta={{}}
                                name="term"
                                label={`Search ${title}`}
                                placeholder={`Search ${title}`}
                                wrapperClassName="mb-0 mt-2 w-full sm:mt-0 sm:w-64"
                            />
                        </h1>
                        <TrinityNews categories={CATEGORY_GROUP_IDS.expertInsights} filterTerm={this.state.term}/>
                    </div>
                </div>
            </div>
        );
    }
}
