import React, {useRef, useState, useEffect} from "react";
import {connect} from 'react-redux'
import {ButtonBrand} from "Components/Button";
import Loading from "../../Components/Loading";
import startCase from "lodash/startCase";
import {
    FaRegPlusSquare,
    FaRegMinusSquare,
} from "react-icons/fa";
import {useOnScreen} from "../../hooks/useOnScreen";
import {venueInteraction} from "../../modules/analytics";
import {VENUE_TAGS} from "../../modules/analytics/tags";

const HealthAndSafety = ({loading, documents, mobile, anchor, accreditation, policyLink, venueInteraction, venueId, dataScore, impressionId}) => {
    const [expanded, setExpanded] = useState(false);
    const hasBeenOnScreenRef = useRef(false);
    const toggle = () => {
        setExpanded(!expanded);
    };
    const ref = useRef();
    const onScreen = useOnScreen(ref, "-100px");

    documents = documents && documents.filter(document => {
        return document.title !== null;
    });

    useEffect(() => {
      if (onScreen && !hasBeenOnScreenRef.current) {
        hasBeenOnScreenRef.current = true;
        venueInteraction({
          type: VENUE_TAGS.VIEW_HEALTH_AND_SAFETY,
          dataScore: dataScore,
          id: venueId,
          impression_id: impressionId
        })
      }
    }, [onScreen])

    return (
        <div ref={ref}>
          <span className="anchor sr-only" id={anchor}>
            Health and Safety
          </span>
          <div className="bg-white mb-4 shadow text-center" id="health-and-safety">
            <h2
                className={`py-4 ${
                    mobile
                        ? "px-3 text-left w-full flex items-center justify-between"
                        : "px-3 text-left"
                } text-xl ${(expanded || !mobile) &&
                "border-b border-light-blue-grey"}`}
                onClick={toggle}
            >
                Health & Safety
                {mobile && !expanded && <FaRegPlusSquare className="text-base" />}
                {mobile && expanded && <FaRegMinusSquare className="text-base" />}
            </h2>
            {(!mobile || (mobile && expanded)) &&
            (loading ? (
                <Loading />
            ) : (
                <section className="md:pb-0 pb-4">
                    {documents.map((document, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className="row my-2 px-3" key={index}>
                                    <div className="col col-w-full md:col-w-1/2 text-left">
                                        <h4 className="capitalize mt-0">{document.title}</h4>
                                        <p>{document.description}</p>
                                    </div>
                                    <div className="col col-w-full md:col-w-1/2 text-right">
                                        <a
                                            href={document.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <ButtonBrand>View</ButtonBrand>
                                        </a>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </section>
            ))}
        </div>
        </div>
    );
};

export default connect(null, {venueInteraction})(HealthAndSafety);
