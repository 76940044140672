import React from "react";
import classNames from "classnames";
import {
  string,
  object,
  arrayOf,
  oneOfType,
  shape,
  number,
  bool,
} from "prop-types";
import Label from "./Label";
import Error from "./Error";

const Select = ({
  meta: { touched, error, warning },
  input,
  options,
  label,
  type,
  placeholder,
  wrapperClassName,
  selectClassName,
  noPlaceholder,
  disabled = false,
  labelSize
}) => {
  let style = {
    lineHeight: "38px",
    height: "38px",
  };

  return (
    <div className={wrapperClassName || "mb-5"}>
      {label && <Label name={input.name} text={label} labelSize={labelSize}/>}
      <div className="flex">
        <div className="bg-white relative w-full">
          <i className="fal fa-angle-down absolute-center-y pin-r text-2xl mr-2 text-dark-grey" />
          <select
            disabled={disabled}
            {...input}
            placeholder={placeholder}
            type={type}
            style={style}
            className={
              selectClassName ||
              classNames(
                [
                  "pl-1",
                  "pr-8",
                  "py-0",
                  "w-full",
                  "outline-none",
                  "font-normal",
                  "text-xs",
                  "border",
                  "border-grey-md",
                  "focus:border-primary",
                  "border-black",
                  "rounded-none",
                  "bg-transparent",
                  "relative",
                ],
                {
                  "text-black": !!input.value,
                  "text-light-text": !input.value,
                }
              )
            }
          >
            {!noPlaceholder ? (
              <option className="text-light-text" value="">
                {placeholder || "Please select"}
              </option>
            ) : null}
            {options.map(option => (
              <option
                className="text-black"
                value={typeof option === "string" ? option : option.value}
                key={typeof option === "string" ? option : option.value}
              >
                {typeof option === "string" ? option : option.text}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
};

Select.propTypes = {
  options: arrayOf(
    oneOfType([
      string.isRequired,
      shape({
        text: string.isRequired,
        value: oneOfType([string, number]).isRequired,
      }).isRequired,
    ])
  ),
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]),
  type: string,
  placeholder: string,
  wrapperClassName: string,
  labelClassName: string,
  selectClassName: string,
  noPlaceholder: bool,
};

export default Select;
