import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {corporateSecondaryItems, getMenuItems, secondaryItems} from 'modules/menu-items';
import { isCorporate, role, token } from "modules/auth";
import ReportsDashboardContainer from "./corporate/reports/ReportsDashboardContainer";
import * as CORPORATE_ROUTES from "../Router/corporate_routes";
import {
    corporateAccountObject,
    hasActiveCorporateAccount, isEventSpecialist,
    isHeadOfEvents,
    isHeadOfProcurement, isNonEventSpecialist
} from "../modules/permissions";
import CorpDashFeed from "./corporate/Dash/CorpDashFeed";
import AdminDashPanels from "./AdminDashPanels";
import MonthlyEventsReportContainer from "../pages/admin/corporate/reports/MonthlyEventsReportContainer";
import CorpDashUpcomingEvents from "./corporate/Dash/CorpDashUpcomingEvents";

const DashBoard = ({
                       auth,
                       location,
                       isCorporate,
                       match,
                       isHeadOfEvents,
                       isHeadOfProcurement,
                       hasActiveCorporateAccount,
                       corporateAccount,
                       isEventSpecialist,
                       isNonEventSpecialist,
                       user = false,
                       token
                   }) => {
    let title;
    const section = location.pathname.split('/')[2];
    const items = getMenuItems(auth, (isCorporate && hasActiveCorporateAccount) ? corporateSecondaryItems[section] : secondaryItems[section]);
    switch (section) {
        case 'dashboard': // corporate
            title = '';
            break;
        case 'my-edge':
            title = 'My EDGE';
            break;
        case 'venues':
            title = 'Venues';
            break;
        case 'subscriptions':
            title = 'Subscriptions';
            break;
        case 'site-visits':
            title = 'Site Visits';
            break;
        case 'users':
            title = 'Users';
            break;
        case 'rfp':
            title = 'RFP';
            break;
        case 'edge-analytics':
            title = 'EDGE Analytics';
            break;
        case 'edge-cms':
            title = 'EDGE CMS';
            break;
        case 'settings':
            title = 'EDGE Settings';
            break;
        case 'events':
            title = 'Company Events';
            break;
        case 'reports':
            title = 'Reports';
            break;
        default:
            title = 'My EDGE';
    }
    const isCorpDash = match.path.includes(CORPORATE_ROUTES.FRONTEND.DASHBOARD.VIEW);
    const isCorp = match.path.startsWith('/corporate');
    return (
        <div>
            {match.path.startsWith('/corporate/reports') && (
                <div className="m-0 xxl:py-8 xxl:px-16 p-8 corporate-admin__dashboard__hero" style={{
                    backgroundImage: 'url(/img/corp-edge-reports-bg-blue-darker.jpg)',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    width: '100vw',
                    position: 'relative',
                    left: '50%',
                    right: '50%',
                    marginLeft: '-50vw',
                    marginRight: '-50vw',
                    borderBottom: '8px solid var(--primary-light)'
                }}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col'}>
                               <h2 className="text-white mb-6 md:mb-8 font-bold font-heading text-2rem md:text-4xl">Company Overview</h2>
                            </div>
                            <div className={'col lg:col-w-1/2'}>
                                <div id="corpDashSnapshotContainer" style={{
                                    'backgroundColor': 'rgba(255,255,255,0.9)',
                                    'boxShadow': '0 0 15px rgba(0,0,0,0.35)',
                                    'borderRadius': '5px',
                                    'borderTop': '10px solid var(--primary-light)'
                                }}>
                                    <ReportsDashboardContainer ownReport={false}/>
                                </div>
                            </div>
                            <div className={'col lg:col-w-1/2'}>
                                <div id="corpDashSnapshotContainer" style={{
                                    'backgroundColor': 'rgba(255,255,255,0.9)',
                                    'boxShadow': '0 0 15px rgba(0,0,0,0.35)',
                                    'borderRadius': '5px',
                                    'borderTop': '10px solid var(--primary-light)'
                                }}>
                                    <MonthlyEventsReportContainer
                                      dashboardReport={true}
                                      height={450}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}
            <h1 className="my-5">{title}</h1>
            <div className="flex flex-row flex-wrap -mx-2">
                {isCorpDash && (isNonEventSpecialist || isEventSpecialist) ?
                    <>
                      <div className="flex flex-row flex-wrap md:col-w-1/2">
                        <AdminDashPanels items={items} corporateAccount={corporateAccount} corporateStyle={isCorp} twoUp token={token}/>
                      </div>
                      <div className="flex-col md:col-w-1/2 es-nones-feed">
                        <CorpDashUpcomingEvents token={token} />
                      </div>
                    </>
                    : null}
                {isCorpDash && (isHeadOfEvents || isHeadOfProcurement) ?
                    <AdminDashPanels items={items} corporateAccount={corporateAccount} corporateStyle={isCorp} token={token}/>
                    : null}
                {!isCorpDash ?
                    <AdminDashPanels items={items} corporateAccount={corporateAccount} corporateStyle={isCorp} token={token}/> : null}
            </div>
        </div>
    );
};

export default withRouter(
    connect(state => ({
        user: state?.auth?.user,
        auth: state.auth,
        isCorporate: isCorporate(state),
        hasActiveCorporateAccount: hasActiveCorporateAccount(state.auth),
        corporateAccount: corporateAccountObject(state.auth),
        isHeadOfProcurement: isHeadOfProcurement(state.auth),
        isHeadOfEvents: isHeadOfEvents(state.auth),
        isEventSpecialist: isEventSpecialist(state.auth),
        isNonEventSpecialist: isNonEventSpecialist(state.auth),
        token: token(state)
    }))(DashBoard),
);
