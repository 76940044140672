import React from "react";
import classNames from "classnames";

const TableWithCollapsibleRows = ({
                                    admin,
                                    tableIdKey = "id",
                                    showHeader = true,
                                    headers,
                                    data,
                                    maxTd,
                                    tableClass,
                                    expandedRow = [],
                                    shouldShowComponent = false,
                                    mappingMethod,
                                    expandedRowComponent: ExpandedRowComponent,
                                  }) => {
  const renderExpandedRow = (rowData, index) => {
    const objects = rowData[index];
    let rowsArray = [];
    objects.map(item => rowsArray.push(mappingMethod(item)));
    return rowsArray.map((item, i) => {
      return <React.Fragment key={i}>
        <tr key={i} className="border-b-2 border-off-white bg-off-white">
          {headers.map((header, j) => {
            const dataProperty = typeof header === "string" ? header : header.key;
            return (
              <td className={classNames("font-normal", {
                "border-l border-grey-md px-1 py-2": !admin,
                "p-3 text-left": admin && dataProperty !== ":expand",
                "": admin && dataProperty === ":expand",
              })}
                  style={maxTd ? { maxWidth: maxTd } : null}
                  key={j}
              >
                {typeof item[dataProperty] === "function"
                  ? item[dataProperty]()
                  : item[dataProperty]}
                {/*{typeof item[dataProperty] !== "undefined" ? item[dataProperty] : ""}*/}
              </td>
            );
          })}
        </tr>
      </React.Fragment>;
    });
  };

  const renderHeader = (header) => {
    if (typeof header === "function") {
      return header();
    }
    if (typeof header === "string") {
      return header.startsWith(":") ? "" : header;
    }
    if (typeof header.display === "function") {
      return header.display();
    }
    return header.display;
  };

  return (
    <div
      className={classNames("w-full overflow-x-auto", {
        "bg-white p-3 mb-3": admin,
      }, tableClass)}
    >
      <table
        className={classNames({
          "border border-grey-md edge-table": !admin,
        })}
      >
        {showHeader ? (
          <thead>
          <tr>
            {headers.map((header, i) => {
              let headerClass = typeof header.class !== "undefined" ? header.class : "";
              return (
                <th key={i} className={classNames({ "p-3 text-left ": admin }) + headerClass}>
                  {renderHeader(header)}
                </th>
              );
            })}
          </tr>
          </thead>
        ) : null}
        <tbody>
        {data.map((item, i) => {
          return <React.Fragment key={i}>
            <tr key={i} className="border-b-2 border-off-white">
              {headers.map((header, j) => {
                const dataProperty = typeof header === "string" ? header : header.key;
                return (
                  <td className={classNames("font-normal", {
                    "border-l border-grey-md px-1 py-2": !admin,
                    "p-3 text-left": admin && dataProperty !== ":expand",
                    "": admin && dataProperty === ":expand",
                  })}
                      style={maxTd ? { maxWidth: maxTd } : null}
                      key={j}
                  >
                    {typeof item[dataProperty] === "function"
                      ? item[dataProperty]()
                      : item[dataProperty]}
                  </td>
                );
              })}
            </tr>
            {typeof item["extra_data"] === "function" ? item["extra_data"]() : null}
            {expandedRow[item[tableIdKey]] && shouldShowComponent ? (
              <ExpandedRowComponent {...expandedRow} />
            ) : null}
            {expandedRow[item[tableIdKey]] && (
              <>
                {renderExpandedRow(expandedRow, item[tableIdKey])}
              </>
            )}
          </React.Fragment>;
        })}
        </tbody>
      </table>
    </div>
  );
};


export default TableWithCollapsibleRows;
