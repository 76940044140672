import React from "react";
import {connect} from 'react-redux';


export const AUCTION_RAISE_FINAL = process.env.REACT_APP_AUCTION_RAISE_END ? process.env.REACT_APP_AUCTION_RAISE_END  :  15000;

const mapDispatchToProps = {
};

const mapStateToProps = state => ({
});
let CharityAuctionEnd = (props) => {

    return (
        <div className="bg-light-off-blue border-b-12" style={{ minHeight : '200px'}}>
            <div className={'container'}>
                <div className="pt-8 pb-16 md:py-16">
                    <div className="flex flex-col justify-center items-center pl-3 text-center">
                        <h1 className={'pb-5 text-blue '}>The Trinity EDGE Challenge Charity Auction has now ended</h1>
                        <p className={'lg:w-2/3'}>
                            Our Trinity EDGE Challenge charity auction is an annual fundraiser to support charities. This year, we auctioned LOTS kindly donated by hotels and venues to raise money for four small local charities and with your support we raised an incredible £ {AUCTION_RAISE_FINAL}
                        </p>
                    </div>

                    <div className="container">
                        <h1 className={'text-center'}>We raised £{AUCTION_RAISE_FINAL}!</h1>
                        <br/>
                        <div className="flex flex-col justify-center items-center pl-3 text-center">
                            <p className={'lg:w-2/3'}>
                                The money raised will make a real difference to our chosen charities (<a className={'text-blue underline'} href={'https://dontlosehope.co.uk/home/'}>Don’t Lose Hope</a>, <a className={'text-blue underline'} href={'http://www.secondhelpings.org.uk/'}>Second Helpings</a>, <a className={'text-blue underline'} href={'https://bumps-and-beyond.org/'}>Bumps and Beyond</a> and <a className={'text-blue underline'} href={'https://threecountiesdogrescue.org/'}>Three Counties Animal Rescue</a>) - thank you once again to everyone who has supported us.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CharityAuctionEnd);