import axios from "axios";

const getAdditionalItems = async (venueId = "", token) => {
  try {
    const response = await axios.get(
      `${window.API}/rfp/additional-item-type/${venueId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.data.data) {
      return {
        result: [],
      };
    }

    return {
      result: response.data.data.map(type => ({
        text: type.type,
        value: type.id,
      })),
    };
  } catch (error) {
    return { error, result: [] };
  }
};

const addAdditionalItem = async ({ venueId, item }, token) => {
  try {
    let response = await axios.post(
      `${window.API}/rfp/additional-item-type`,
      {
        venue_id: venueId,
        type: item,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.data.data) {
      return {
        result: [],
      };
    }
    return {
      text: response.data.data.type,
      value: response.data.data.id,
    };
  } catch (error) {
    return { error, result: false };
  }
};

export { getAdditionalItems, addAdditionalItem };
