import React from "react";
import CmsPage from "Components/CmsPage";
import Breadcrumbs from "Components/Breadcrumbs";

export default class EventOrganisersGuide extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="bg-white m-1 mt-4 p-5">
          {/*<Breadcrumbs items={[{ name: "Home", to: "/" }, { name: "Event Organisers Guide" }]} />*/}
          <CmsPage id={7665} />
        </div>
      </div>
    );
  }
}
