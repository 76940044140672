import React from "react";
import {connect} from "react-redux";
import {ButtonPrimary, ButtonSpan} from "Components/Button";
import Modal from "Components/Modal";
import { Input, Toggle } from "../Form";
import { bool, email, minLength, required } from "../../modules/validation";
import {Field, reduxForm, getFormValues} from "redux-form";
import { processFormValuesPost } from "../../modules/formTools";
import {token} from "../../modules/auth";
import { useEffect, useState } from "react";
import {agencyCompanyDomains} from "../../services/companyService/agencyCompanyDomains";
import {getErrorMessage} from "../../modules/api-tools";

const mapStateToProps = (state, props) => {
  let suppressNotifications = props.values && props.values.suppress_notifications
  if (props.suppressUserNotifications) {
    suppressNotifications = props.suppressUserNotifications
  }
  let initialValues = {
    suppress_notifications: suppressNotifications
  };
  const v = getFormValues("minimalUserForm")(state) || {};
  return {
    initialValues,
    token:  token(state),
    values: v
  };
};

const v = {
  firstName: required("Please enter your first name"),
  surname: required("Please enter your surname"),
  email: required("Please enter your email address"),
  phone: required("Please enter your phone"),
  password: required("Please enter your password"),
  min8: minLength(8),
  suppress_notifications: bool,
};

const Wrapper = ({children, onClose}) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 600,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3"/>
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

let MinimalUserFormModal = (props) => {

  const [companyDomains, setCompanyDomains] = useState(null);
  const [errorDomain, setErrorDomain] = useState(null);

  useEffect(() => {
    agencyCompanyDomains(props.token).then((response) => {
      setCompanyDomains(response.result)
    })
  }, []);


  const onSubmit = (formValues) => {
    return processFormValuesPost(props, formValues, props.initialValues);
  }
  return (
    <Wrapper onClose={props.onClose}>
      {props.company ? (
        <h1 className="mb-8">Create User for {props.company.name}</h1>
      ) : (
        <h1 className="mb-8">Create User</h1>
      )}
      <form className="border-none" onSubmit={props.handleSubmit(onSubmit)}>
        <div className={'row'}>
          <div className="col col-w-full mb-6">
            <Field
              name="suppress_notifications"
              label="Suppress Notifications?"
              component={Toggle}
              validate={v.suppress_notifications}
              wrapperClassName="inline mb-8 xl:w-1/2"
            />
            <Field
              name="first_name"
              label="First name"
              validate={v.firstName}
              component={Input}
            />
            <Field
              name="surname"
              label="Surname"
              validate={v.surname}
              component={Input}
            />
            <Field
              name="email"
              type="email"
              label="Email address"
              validate={[v.email, email]}
              component={Input}
              onChange={(e) => {
               setErrorDomain('')
               companyDomains.forEach(companyDomain => {
                 let emailDomain = e.target.value.split("@");
                 if(typeof(emailDomain[1]) !== 'undefined' && emailDomain[1] && companyDomain.approved_domains) {
                   let approvedDomains = companyDomain.approved_domains.trim().split(",");
                   approvedDomains.forEach((approvedDomain, companyName) => {
                     let emailDomain = e.target.value.split("@")[1];
                     if(emailDomain === approvedDomain && props.company.name !== companyDomain['name']) {
                       let errorMessage = 'Are you sure? This users email address domain, is in use beneath a different Company [' + companyDomain['name'] + ']';
                       setErrorDomain(errorMessage)

                     }
                   })
                 }

               })
              }}
            />
            <div className={'my-2'}>
              {errorDomain ? <span className="text-warning font-normal">{errorDomain}</span> : null}

            </div>
            <Field
                component={Input}
                name="phone"
              label="Phone"
              validate={v.phone}
            />
            <Field component={Input} name="mobile" label="Mobile"/>
          </div>
          <div className="col">
            <ButtonPrimary type="submit">Save</ButtonPrimary>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

MinimalUserFormModal = reduxForm({
  form: 'minimalUserForm',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(MinimalUserFormModal);

export default connect(mapStateToProps, null)(MinimalUserFormModal);
