import {
  CORPORATE_ACCOUNT_LOCATION_CREATE, CORPORATE_ACCOUNT_LOCATION_CREATE_ERROR,
  CORPORATE_ACCOUNT_LOCATION_RECEIVED, CORPORATE_ACCOUNT_LOCATION_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_LOCATION_REQUEST, CORPORATE_ACCOUNT_LOCATION_UNLOAD,
  CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED,
  CORPORATE_ACCOUNT_LOCATIONS_LIST_REQUEST,
  CORPORATE_ACCOUNT_LOCATIONS_LIST_UNLOAD
} from "./types";

export const corporateAccountLocationsList = (state = {
  corporateAccountLocationsList: {},
  isFetchingLocations: false,
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_LOCATIONS_LIST_REQUEST:
      return {
        ...state,
        isFetchingLocations: true
      }
    case CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED:
      return {
        ...state,
        isFetchingLocations: false,
        corporateAccountLocationsList: action.data
      }
    case CORPORATE_ACCOUNT_LOCATIONS_LIST_UNLOAD:
      return {
        ...state,
        isFetchingLocations: false,
        corporateAccountLocationsList: {},
      }
    default:
      return state;
  }
}

export const corporateAccountLocation = (state = {
  corporateAccountLocation: {},
  isFetchingLocation: false,
  error: false,
  errorData: null,
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_LOCATION_REQUEST:
      return {
        ...state,
        isFetchingLocation: true
      }
    case CORPORATE_ACCOUNT_LOCATION_CREATE:
      return {
        ...state,
        corporateAccountLocation: action.data,
        error: false,
      }
    case CORPORATE_ACCOUNT_LOCATION_CREATE_ERROR:
      return {
        ...state,
        corporateAccountLocation: {},
        error: true,
        errorData: action.data
      }
    case CORPORATE_ACCOUNT_LOCATION_RECEIVED:
      return {
        ...state,
        isFetchingLocation: false,
        corporateAccountLocation: action.data
      }
    case CORPORATE_ACCOUNT_LOCATION_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingLocation: false,
        error: true,
        corporateAccountLocation: {},
        errorData: action.data
      }
    case CORPORATE_ACCOUNT_LOCATION_UNLOAD:
      return {
        corporateAccountLocation: {},
        error: false,
        isFetchingLocation: false,
      }
    default:
      return state;
  }
}