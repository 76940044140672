import React from "react";
import { LabelledText, WhitePanelWithHeader } from "../../../../../ui";

const ContactCard = (props) => {
  return (
    <div>
      {/*<WhitePanelWithHeader title="Venue Contact" icon="far fa-user">*/}
      {/*    <LabelledText label="Email" text='info@XXXXXXXX.com'/>*/}
      {/*    <LabelledText label="Phone" text='01733XXXXXX'/>*/}
      {/*</WhitePanelWithHeader>*/}

      {props.venueContact ? (
        <WhitePanelWithHeader title="Venue Contact for RFP" icon="far fa-user">
          {props.venueContact.name ?
            <LabelledText label="Name" text={props.venueContact.name} styles={null} />
            :
            <LabelledText label="Name" text={"Not provided"} styles={null} />
          }

          <LabelledText label="Email" text={props.venueContact.email} styles={{
            overflowX: "clip",
            textOverflow: "ellipsis",
          }} />
          {props.venueContact.phone ?
            <LabelledText label="Phone" text={props.venueContact.phone} styles={{
              overflowX: "clip",
              textOverflow: "ellipsis",
            }} />
            :
            <LabelledText label="Phone" text={"Not provided"} styles={null} />
          }
          {props.venueContact.mobile ?
            <LabelledText label="Mobile" text={props.venueContact.mobile} styles={null} />
            :
            <LabelledText label="Mobile" text={"Not provided"} styles={null} />
          }
        </WhitePanelWithHeader>
      ) : (
        <WhitePanelWithHeader title="Venue Contact for RFP" icon="far fa-user">
          No Contact found for Venue
        </WhitePanelWithHeader>
      )}


      <WhitePanelWithHeader title="Client Contact" icon="far fa-user">
        {props.companyDetails ?
          <LabelledText
            label="Company Name"
            text={props.clientDetails.name ? props.clientDetails.name + " (" + props.companyDetails.name + ")" : props.companyDetails.name}
            styles={null}
          />
          :
          props.clientDetails ? (
            <LabelledText label="Client Name" text={props.clientDetails.name} styles={null} />
          ) : (
            <LabelledText label="Company Name" text={"Not Provided"} styles={null} />
          )
        }
        {props.companyBranch ? (
          <LabelledText
            label="Company Branch"
            text={props.companyBranch}
            styles={null}
          />
        ) : null}
        {props.clientDetails.email ?
          <LabelledText label="Email" text={props.clientDetails.email} styles={{
            overflowX: "clip",
            textOverflow: "ellipsis",
          }} />
          :
          <LabelledText label="Email" text={"Not provided"} styles={{
            overflowX: "clip",
            textOverflow: "ellipsis",
          }} />
        }
        {props.clientDetails.phone ?
          <LabelledText label="Phone" text={props.clientDetails.phone} styles={null} />
          :
          <LabelledText label="Phone" text={"Not provided"} styles={null} />
        }

        {props.clientDetails.mobile ?
          <LabelledText label="Mobile" text={props.clientDetails.mobile} styles={null} />
          :
          <LabelledText label="Mobile" text={"Not provided"} styles={null} />
        }
      </WhitePanelWithHeader>

    </div>
  );

};

export default ContactCard;