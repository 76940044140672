import React, {useState} from "react";
import {ButtonPrimary} from "../../../../Components/Button";
import {Link} from "react-router-dom";
import {downloadProposalWithoutCommission} from "../../../../services/rfpService/downloadProposalWithoutCommission";
import {
    downloadConfirmationWithoutCommission
} from "../../../../services/rfpService/downloadConfirmationlWithoutCommission";
import moment from 'moment';
import Loading from "../../../../Components/Loading";
import {set} from "lodash";
import ReactTooltip from 'react-tooltip';
import {BOOKED_EVENT_STATUS} from "../../../../constants";
import {ENQUIRY_VENUE_DATA_STATUS} from "../../../../modules/rfp/enquiryVenueDataStatus";

let downloadButton = (fileUrl, logo = false, tooltip) => {
    if (!fileUrl) {
        return (
            <i className={`fas ${logo ? logo : 'fa-file-download'} fa-1x text-lg font-medium px-1 text-dark-grey opacity-25`}></i>
        )
    }
    return (
        <a className={`text-lg font-medium px-1`} href={fileUrl}  data-tip={tooltip}>
            <i className={`fas ${logo ? logo : 'fa-file-download'} fa-1x`}></i>
        </a>
    )
}


let downloadProposalButton = (id, token, logo, setLoadingDocument, hasChecksheet) => {
    return (
        <a className={`text-lg font-medium px-1`}  href={'#'} data-for={`button-${id}`} data-tip>

            <i className={`fas ${logo ? logo : 'fa-file-download '} fa-1x ${!hasChecksheet ? 'text-dark-grey opacity-25' : ''}`} onClick={() => {
                if(hasChecksheet) {
                    setLoadingDocument('proposal');
                    downloadProposalWithoutCommission(id, token).then((response) => {
                        setLoadingDocument('')
                    })
                }
            }}

            ></i>
            {
                !hasChecksheet ?
                    null
                    // <ReactTooltip  id={`button-${id}`} >Awaiting Response</ReactTooltip>
                    :
                    <ReactTooltip  id={`button-${id}`} >Download Proposal</ReactTooltip>
            }

        </a>
    )
}

let downloadConfirmationButton = (id, evdId, name, token, logo, setLoadingDocument) => {
    return (
        <a className={`text-lg font-medium px-1`}  href={'#'} data-tip={"Download Confirmation"}>
            <i className={`fas ${logo ? logo : 'fa-file-download'} fa-1x`} onClick={() => {
                setLoadingDocument('confirmation');
                downloadConfirmationWithoutCommission(id, evdId, name,  token).then((response) => {
                    setLoadingDocument('confirmation')
                })
            }}></i>
        </a>
    )
}



let EventsListDownloadProposalConfirmationActionButtons = ({
                                                               bookedEvent,
                                                               confirmedData,
                                                               canManageEventPreData,
                                                               canManageEventCompletion,
                                                               isCompanyAccount,
                                                               canChaseEvent,
                                                               token,
                                                               openModal,
                                                               closeModal,
                                                               hasModal = true
                                                           }) => {
    const [loadingDocument, setLoadingDocument] = useState('')
    if (!bookedEvent) {
      return null;
    }
    let hasChecksheet = bookedEvent.enquiry.enquiry_venue_datas.filter((evd) => evd.status >= ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED).length;
    let isRfpXpress = bookedEvent?.enquiry?.rfp_xpress_service;
    return (
        <>
            <div className="flex items-center content-center">
              {bookedEvent.event_status && (bookedEvent.event_status.code >= BOOKED_EVENT_STATUS.CONFIRMED && bookedEvent.event_status.code !== BOOKED_EVENT_STATUS.CANCELLED) && confirmedData && isRfpXpress ? (
                <></> // if event is rfp xpress and is confirmed only show 1 button
              ) : loadingDocument === 'proposal' ?
                  <Loading small={true} inline={true} /> :
                  (isCompanyAccount ?
                    downloadProposalButton(bookedEvent.enquiry_id, token, 'fa-book-open', setLoadingDocument, hasChecksheet > 0) :
                    downloadButton("/admin/rfp/" + bookedEvent.enquiry_id,  'fa-book-open', isRfpXpress ? 'View Budget Confirmation' : 'View Proposal')
                  )
                }
                {bookedEvent.event_status && (bookedEvent.event_status.code >= BOOKED_EVENT_STATUS.CONFIRMED && bookedEvent.event_status.code !== BOOKED_EVENT_STATUS.CANCELLED) && confirmedData && (
                    loadingDocument === 'confirmation' ?
                      <Loading small={true} inline={true} /> :
                      (isCompanyAccount ?
                        downloadConfirmationButton(bookedEvent.enquiry_id, bookedEvent.enquiry_venue_datas.id, bookedEvent.enquiry.event_name, token, 'fa-file-contract', setLoadingDocument) :
                        downloadButton("/admin/rfp/" + bookedEvent.enquiry_id + '/confirm/' + confirmedData.id,  'fa-file-contract', isRfpXpress ? 'View Budget Confirmation' : 'View Confirmation')
                      )
                )}
                </div>
        </>
    )
}


export default EventsListDownloadProposalConfirmationActionButtons