import React from "react";
import { Field } from "redux-form";
import { Select } from "Components/Form";

const options = [
  {
    text: "Less than 2 hours",
    value: 120,
  },
  {
    text: "Less than 3 hours",
    value: 180,
  },
  {
    text: "Less than 4 hours",
    value: 240,
  },
  {
    text: "Less than 5 hours",
    value: 300,
  },
  {
    text: "Less than 6 hours",
    value: 360,
  },
  {
    text: "Less than 7 hours",
    value: 420,
  },
  {
    text: "Less than 8 hours",
    value: 480,
  },
  {
    text: "Less than 9 hours",
    value: 540,
  },
  {
    text: "Less than 10 hours",
    value: 600,
  },
  {
    text: "Less than 11 hours",
    value: 660,
  },
  {
    text: "Less than 12 hours",
    value: 720,
  },
];

class FlightChoices extends React.Component {
  render() {
    return (
      <div>
        <Field
          name="flight_time_from_uk"
          component={Select}
          label="Select"
          options={options}
          wrapperClassName="w-64"
          onChange={(e, v) => {
            this.props.search( {
              flight_time_from_uk: v,
            });
          }}
        />
      </div>
    );
  }
}

export default FlightChoices;
//withRouter(
//  connect(
//    undefined,
//    {  }
//  )(FlightChoices)
//);
