import useService from "../../hooks/useService";
import {getRoomStyles} from "../../services/rfpService/roomStyle";
import {Select} from "../../Components/Form";
import { Field } from 'redux-form';
import React from "react";
import {token as tokenSelector} from "../../modules/auth";
import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";

let RoomStylesField = ({searchOnChange, name, label, wrapperClassName = null, labelClassName = null, placeholder = null}) => {

    const { loading, data, error } = useService({
        service: getRoomStyles,
        trigger: true,
        params: true,
    });

    if (loading) {
        return <Loading large />;
    }

    return (
        <Field
            name={name}
            label={label}
            labelClassName={labelClassName}
            wrapperClassName={wrapperClassName}
            placeholder={placeholder}
            component={Select}
            options={data}
            onChange={searchOnChange}
        />
    )
}

export default RoomStylesField