import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchDraftEnquiry, unloadDraftEnquiry } from "../../redux/actions";
import Loading from "../../Components/Loading";
import pick from "lodash/pick";
import BriefSummary from "../../Components/RfpBriefSection/BriefSummary";
import FetchError from "../../Components/FetchError";
import Rfp from "../Rfp";
import VenueSlider from "../Rfp/VenueSlider";
import { fetchVenues, venuesListUnload } from "../../redux/actions/venues";
import { isLoggedIn, token } from "../../modules/auth";
import { ENQUIRY_VENUE_DATA_STATUS } from "../../modules/rfp/enquiryVenueDataStatus";

const shareEmailBody = (url) => {
  const content = `Please provide any feedback on the draft brief ID ${window.location.origin + url}`;

  return encodeURIComponent(content);
};

const DraftRfp = ({
                    match,
                    fetchDraftEnquiry,
                    draftEnquiry,
                    isFetchingDraftEnquiry,
                    unloadDraftEnquiry,
                    draftEnquiryError,
                    fetchVenues,
                    venues,
                    venuesListUnload,
                    isLoggedIn,
                    token,
                  }) => {

  const [editDraft, setEditDraft] = useState(false);
  useEffect(() => {
    fetchDraftEnquiry(match.params.shareId, token).then(response => {
      let venueIds = [];
      if (response.data?.enquiry_venue_datas) {
        response.data.enquiry_venue_datas && response.data.enquiry_venue_datas.map(vd => {
          if (!([ENQUIRY_VENUE_DATA_STATUS.VENUE_OPTED_OUT,
            ENQUIRY_VENUE_DATA_STATUS.REMOVED_BY_CLIENT,
            ENQUIRY_VENUE_DATA_STATUS.ALTERNATIVE_VENUE_BOOKED,
            ENQUIRY_VENUE_DATA_STATUS.DISQUALIFIED,
          ].includes(vd.status))) {
            venueIds.push(vd.venue_id);
          }
        });
      }
      fetchVenues(venueIds);
    });
    if (match.path.includes("/proposal/edit")) {
      setEditDraft(true);
    }
    return () => {
      unloadDraftEnquiry();
      venuesListUnload();
    };
  }, []);

  if (draftEnquiryError) {
    return (
      <div className="bg-white">
        <div className="py-5 container">
          <FetchError />
        </div>
      </div>
    );
  }

  if (isFetchingDraftEnquiry) {
    return <Loading />;
  }

  const brief = {
    ...pick(draftEnquiry, [
      "additional_notes",
      "event_name",
      "enquiry_status",
      "number_of_delegates",
      "maximum_number_of_delegates",
      "bedroom_dates",
      "date_from",
      "date_to",
      "date_flexible",
      "date_flexible_notes",
      "bedrooms_required",
      "bedroom_additional_notes",
      "back_projection",
      "syndicate_room",
      "syndicate_room_notes",
      "exhibition_space",
      "exhibition_space_notes",
      "private_dining",
      "private_dining_notes",
      "any_equipment",
      "any_equipment_notes",
      "attachments",
      "custom_field_values",
    ]),
    room_style: draftEnquiry?.room_style?.name,
  };
  let syndicateRooms = [];
  if (draftEnquiry.syndicate_rooms) {
    draftEnquiry.syndicate_rooms.map(syndicateRoom => {
      syndicateRooms.push(syndicateRoom)
    })
  }
  brief.syndicateRooms = syndicateRooms;
  if (!editDraft) {
    const editRfpLink = `/proposal/edit/${draftEnquiry.share_id}`;
    return (
      <div className="py-5 container">
        <div className="bg-white">
          <h1>{brief.event_name}</h1>
          <div className="flex justify-between mb-3 mt-6">
            <a
              className="flex items-center text-black uppercase font-heading"
              href={`mailto:?subject=Share Feedback on Brief&body=${shareEmailBody(editRfpLink)}`}
            >
              <div className="mr-2 border-2 border-black w-10 h-10 flex items-center justify-center rounded-100">
                <i className="fas fa-share text-2xl"></i>
              </div>
              Share Feedback on Brief
            </a>
            {isLoggedIn && (!brief.authorised_state === "Unauthorised" && !brief.authorised_state === "Authorised") && (
              <a className="border-primary bg-primary hover:bg-dark-blue hover:text-white hover:border-dark-blue
            inline-block tracking-loose font-semibold font-heading px-6 max-w-full uppercase no-underline flex text-center justify-center
            items-center text-white"
                 type="button"
                 href={editRfpLink}>
                Edit Brief
              </a>
            )}
          </div>
          <div className="py-5 container">
            <BriefSummary {...brief} briefPreferences={draftEnquiry.brief_preferences} />
            {venues && venues.length > 0 && (
              <VenueSlider venues={venues} />
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    <Rfp enquiry={draftEnquiry} />
  );
};

const mapDispatchToProps = {
  fetchDraftEnquiry,
  fetchVenues,
  venuesListUnload,
  unloadDraftEnquiry,
};

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
    token: token(state),
    ...state.venues,
    ...state.draftEnquiry,
    ...state.draftEnquiryErrorData,
    ...state.draftEnquiryError,
    ...state.isFetchingDraftEnquiry,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftRfp);
