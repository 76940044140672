import moment from 'moment'





export default (start, end) => {
    var dates = [];

    var month = moment(start); //clone the startDate
    dates.push(month.format('YYYY-MM-DD'));
    while( month.startOf('month') <  moment(end).startOf('month') ) {
        month.add(1, "month");
        dates.push(month.format('YYYY-MM-DD'));
    }

    return dates;
};
