import { ButtonPrimary } from "../../../../Components/Button";
import Loading from "../../../../Components/Loading";
import React, { useState } from "react";
import { token as tokenSelector } from "../../../../modules/auth";
import * as ROUTES from "../../../../Router/corporate_routes";
import { saveData } from "../../../../services/downloadFile";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import { removeEmptySearchParams } from "../../../../utils";

let BookingEventExportButtons = ({ queryParams, isCompanyAccount, isAgencyAdmin }) => {
  const [submitting, setSubmitting] = useState(null);

  const token = useSelector(tokenSelector);

  const handleExport = (type) => async () => {
    if (submitting) {
      return;
    }
    let url = window.API + ROUTES.API.BOOKED_EVENTS.EXPORT_ALL.replace(":type", type);
    setSubmitting(type);
    queryParams = new URLSearchParams(queryParams);
    removeEmptySearchParams(queryParams);
    let queryString = queryParams.toString();
    url = url + "?" + queryString;
    try {
      const response = await axios.get(
        url,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      let filename = type === "invoice" || type === "csv" ?
        `events-${moment().format("DD-MM-YYYY")}.csv` :
        `events-${moment().format("DD-MM-YYYY")}-xero.csv`;

      saveData(
        false,
        response.data,
        filename,
      );
      setSubmitting(false);

    } catch (err) {
      setSubmitting(false);
      alert(`Sorry there was a problem fetching the pdf`);
    }
  };


  return (
    <div className={"flex justify-content-center"}>
      <>
        <ButtonPrimary classes={`mr-2`} onClick={handleExport("csv")} target={"_blank"}>
          {submitting == "csv" ? <Loading inline /> : "Export Events"}
        </ButtonPrimary>
        {
          isCompanyAccount ? null :
            <ButtonPrimary classes={`mr-2`} onClick={handleExport("xero")} target={"_blank"}>
              {submitting == "xero" ? <Loading inline /> : "Download Xero Export"}
            </ButtonPrimary>
        }
      </>
    </div>
  );
};

export default BookingEventExportButtons;