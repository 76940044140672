import React from "react";
import RegistrationLandingPage from "Components/RegistrationLandingPage";

const Agents = () => {
  return (
    <RegistrationLandingPage
      img="/img/landing/agency-banner.jpg"
      title="Register today and find the best venues for your clients next meetings or events."
      heroBody="With over 19,000 venues across 125 destinations, EDGE Venues makes finding the perfect space for your client's meetings and events easy and efficient."
      bannerFeatures={[]}
      bodyFeatures={[
        {
          icon: "globe-americas",
          title: "A Huge Database of Superb Venues at Your Fingertips",
          content: (
            <div>
              <p>
                We take pride in our extensive venue knowledge and have built a comprehensive database to meet the needs
                of meeting and event planners. With our background in the industry, we truly understand what you&#39;re
                looking for in a venue. Whether it&#39;s a unique spot in London or a training room in Manchester, we have
                it all. Our expertise also extends internationally, covering 125 destinations in 45 countries. With a
                growing database of 19,000 venues, you&#39;ll easily find the perfect option for your clients&#39; next event.
              </p>
            </div>
          ),
        },
        {
          icon: "search",
          title: "Effective Search Functionality",
          content: (
            <div>
              <p>
                Simplicity is key. Our platform offers two search options to help you quickly find the right venue. With
                the &#39;simple&#39; search, you can easily view venues by location. The &#39;advanced&#39; search allows you to refine
                your options further by completing a few additional fields, saving you time and effort.
              </p>
            </div>
          ),
        },
        {
          icon: (
            <div className="block mb-4">
              <i className="fal fa-building"></i>
              <i className="fal fa-hotel"></i>
            </div>
          ),
          title: "Venue Comparison",
          content: (
            <div>
              <p>
                Can&apos;t decide on a venue? Our side-by-side comparison tool lets you compare your top choices to see
                which one best suits your clients' needs. You can review meeting room sizes, the number of bedrooms, and
                proximity to airports, helping you make informed decisions for their events.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "Fast Enquiry Process",
          content: (
            <div>
              <p>
                At EDGE Venues, we don&apos;t just stop at helping you search for venues. Once you&apos;ve found the
                perfect options, our enquiry process makes it easy to check availability. With just a few clicks, you
                can express interest in multiple venues. They&apos;ll then respond directly with availability and
                pricing, allowing you to continue your planning without delay.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "News, Offers, and Insights",
          content: (
            <div>
              <p>
                Stay up-to-date with the latest industry news through EDGE Venues. Our editors constantly share new
                stories and features, including venue openings, refurbishments, event concepts, and industry trends. We
                also offer a fantastic selection of venue deals to help you make the most informed choice.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "Commission Deals and FAM Trips",
          content: (
            <div>
              <p>
                We want to help you maximise your revenue. That&apos;s why we offer a range of commission deals to
                ensure you&apos;re getting the most out of EDGE Venues. We also believe in giving you valuable
                experiences, which is why we offer FAM trips and Agents Rates. These allow you to visit and experience
                the venues first-hand, giving you expert knowledge before recommending them to your clients.
              </p>
            </div>
          ),
        },
      ]}
      type="agency"
      successUrl="/agency-registration-complete"
      formColor="#8c8c8b"
      splitFeatureTitle="Find the best venues for meeting spaces for your clients' next event"
      splitFeatureList={[
        "Free to register",
        "Huge database of great quality venues",
        "Simple + effective RFP process",
        "Dedicated client portals",
        "Intuitive Complete process management",
        "Scalable solution for growing businesses",
      ]}
    />
  );
};

export default Agents;
