import {
  SHORTLIST_DELETE_ERROR,
  SHORTLIST_DELETE_REQUEST,
  SHORTLIST_DELETE_REQUEST_RECEIVED,
  SHORTLIST_UNLOAD
} from "./types";

export const shortlist = (state = {
  shortlist: {},
  isFetchingShortlist: false,
  error: false,
  shortlistError: null
}, action) => {
  switch (action.type) {
    case SHORTLIST_DELETE_REQUEST:
      return {
        ...state,
        isFetchingShortlist: true,
        error: true
      }
    case SHORTLIST_DELETE_REQUEST_RECEIVED:
      return {
        ...state,
        shortlist: action.data,
        isFetchingShortlist: false,
        error: false
      }
    case SHORTLIST_DELETE_ERROR:
      return {
        ...state,
        shortlist: {},
        shortlistError: action.error,
        isFetchingShortlist: false,
        error: true,
      }
    case SHORTLIST_UNLOAD:
      return {
        ...state,
        shortlist: {},
        error: false,
        isFetchingShortlist: false,
        shortlistError: false,
      }
    default:
      return state;
  }
}