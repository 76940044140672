import React from "react";
import { ButtonBrand } from "Components/Button";

const img = "/img/landing/expo.jpg";
const title =
  "Want to learn more about the EDGE Venues platform and how it can benefit your business?";

const bannerFeatures = [
  {
    icon: "graduation-cap",
    text: "Learn more",
  },
  {
    icon: "tags",
    text: "Special offers on the day",
  },
  {
    icon: "file-chart-pie",
    text: "Free Business Intelligence Report (worth &pound;1,500)",
  },
  {
    icon: "users",
    text: "Meet the team",
  },
  {
    icon: "project-diagram",
    text: "Networking",
  },
  {
    icon: "layer-group",
    text: "New developments",
  },
];

const events = [
  {
    dateTime: "2020-03-09T00:00:00",
    dateString: "Monday 9th March",
    link: "https://cvent.me/9a3GeG",
  },
  {
    dateTime: "2020-03-10T00:00:00",
    dateString: "Tuesday 10th March",
    link: "https://cvent.me/oPd8OV",
  },
  {
    dateTime: "2020-03-13T00:00:00",
    dateString: "Friday 13th March",
    link: "https://cvent.me/BNmXGm",
  },
  {
    dateTime: "2020-03-17T00:00:00",
    dateString: "Tuesday 17th March",
    link: "https://cvent.me/L8LeGB",
  },
  {
    dateTime: "2020-03-19T00:00:00",
    dateString: "Thursday 19th March",
    link: "https://cvent.me/WLnDgy",
  },
  {
    title: "I’m interested in this event but can’t attend these dates",
    link: "mailto:sales@edgevenues.com",
    buttonLabel: "Contact us",
  },
];

const EdgeExpo2020 = () => {
  return (
    <div className="container">
      <div
        className="p-3 md:p-8 text-white flex"
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: "#2d2d2d",
        }}
      >
        <div className="xl:w-3/5">
          <h1 className="mb-10">{title}</h1>
          <div className="row">
            {bannerFeatures.map((feature, i) => (
              <div className="col col-w-full md:col-w-1/3" key={i}>
                <span className="text-center text-4xl">
                  {typeof feature.icon === "string" ? (
                    <i className={`fal fa-${feature.icon}  block mb-4`}></i>
                  ) : (
                    feature.icon
                  )}
                </span>
                <h3
                  className="text-center mb-10"
                  dangerouslySetInnerHTML={{ __html: feature.text }}
                />
              </div>
            ))}
          </div>
          <p>
            EDGE Venues is inviting you to our 2020 EDGE Expo Event held at our
            offices in Stamford. A perfect opportunity for all venues to find
            out why EDGE Venues is the right platform to advertise your venue
            on, how we can help you maximise your business opportunity and what
            exciting developments are in store for the year.
          </p>
          <p>
            There will be an exclusive offer available on the day for those
            venues that wish to upgrade, sales and marketing presentations and a
            chance to meet the team and ask any questions. All attendees will be
            able to take away a free copy of the Business Intelligence Review
            which is worth &pound;1,500.
          </p>
        </div>
        <div className="md:w-2/5 p-3 md:p-10"></div>
      </div>
      <h2 className="block text-center mt-10 mb-5">EDGE Expo 2020</h2>

      <div className="row">
        <div className="p-3 xl:col-w-2/3">
          <h3 className="block text-center mb-5">Event Dates</h3>
          <div className="row">
            {events.map((event, i) => (
              <div
                className="col col-w-full sm:col-w-1/2 xl:col-w-1/3 mb-4"
                key={i}
              >
                <div className="bg-white h-full p-5">
                  <span className="text-center text-5xl">
                    <i className="fal fa-calendar-alt block mb-4"></i>
                  </span>
                  <p className="text-center">
                    {event.dateString || event.title}
                  </p>
                  <div className="flex justify-center">
                    <ButtonBrand classes="" Element="a" href={event.link}>
                      {event.buttonLabel || "Book Now"}
                    </ButtonBrand>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="p-3 xl:col-w-1/3 xl:pl-5">
          <h3 className="mb-3">Agenda</h3>
          <p>
            We would be delighted if you’d join us at the one of the upcoming
            EDGE EXPOs which will be held at EDGE Offices in the beautiful town
            of Stamford, Lincolnshire.
          </p>
          <p>
            You can choose to attend the morning session (10.00-12.00) or
            afternoon session (14.30-16.30)
          </p>
          <h3 className="mt-5 mb-3">Each session will cover the following:</h3>
          <ul className="mb-5">
            <li>Welcome and introductions</li>
            <li>EDGE in Numbers</li>
            <li>EDGE Demo</li>
            <li>RFP Process</li>
            <li>Marketing Overview</li>
            <li>Testimonials (Video)</li>
          </ul>
          <p>
            The event will also provide the opportunity to network with the EDGE
            venues team and your peers who are using EDGE.
          </p>
          <h4 className="mt-5 mb-3">Registration fees</h4>
          <p>
            This event is free of charge You may bring a guest
            (pre-registrations only)
          </p>
          <p className="mb-5">
            We hope you can make it and look forward to seeing you there.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EdgeExpo2020;
