import React, { useEffect } from "react";
import { string, object, oneOfType } from "prop-types";
import Label from "./Label";
import Error from "./Error";
import { ReactComponent as ToggleOff } from "svg/toggle-off.svg";
import { ReactComponent as ToggleOn } from "svg/toggle-on.svg";

const Toggle = ({
  meta: { touched, error, warning },
  input: { name, onChange, value },
  label,
  wrapperClassName,
  onClick = null,
  defaultValue = 0,
}) => {


  const handleClick = () => {
    const newValue = value ? 0 : 1;


    onChange(newValue);
    if(onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (!value) {
      onChange(0);
    } else {
      onChange(1)
    }

    if(defaultValue > 0)
    {
      onChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={wrapperClassName || "mb-5"}>
      {label && <Label name={name} text={label} />}
      <div className="col col-w-full">
        <span onClick={handleClick} className="pointer text-3xl">
          {Boolean(value) === true && (
            <ToggleOn
              style={{ transform: "scale(2)" }}
              className="fill-current text-green"
            />
          )}
          {Boolean(value) === false && (
            <ToggleOff
              style={{ transform: "scale(2)" }}
              className="fill-current text-dark-grey"
            />
          )}
        </span>
      </div>
      {/* <div>
        <i
          className={`fal fa-toggle-${value ? "on text-brand" : "off"}`}
          role="button"
          tabIndex="0"
          onClick={handleClick}
          style={{ fontSize: 28, lineHeight: 1 }}
        ></i>
      </div> */}
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
};

Toggle.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]),
  wrapperClassName: string,
};

export default Toggle;
