import axios from "axios";

const removeVenue = async ({ venueId, rfpId, reason, reason_other }, token) => {
  try {
    const response = await axios.post(
      `${window.API}/rfp/remove-venue`,
      {
        enquiry_id: rfpId,
        venue_id: venueId,
        reason: reason_other || reason,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    if (response.data) {
      return {result: response.data}
    }
    return {result: true}
  } catch (error) {
    return { error };
  }
};

export { removeVenue };
