import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, getFormValues, reduxForm } from "redux-form";
import {  Textarea } from "Components/Form";
import { ButtonPrimary, ButtonSpan } from "Components/Button";
import Loading from "Components/Loading";
import Modal from "Components/Modal";
import useService from "hooks/useService";
import {  required } from "modules/validation";
import { confirmVenueVenue } from "services/rfpService";

const form = "rfp-site-visit-reply";

const v = {
  required: required(),
};

const Wrapper = ({ children, onClose }) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 1200,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3" />
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

const ConfirmationReplyModal = ({ handleSubmit, onClose, venueId }) => {
  const values = useSelector(getFormValues(form)) || {};
  const [submitData, setSubmitData] = useState(null);
  const { id: rfpId } = useParams();
  const { loading: submitting, data: complete, error } = useService({
    initialLoading: false,
    service: confirmVenueVenue,
    trigger: submitData,
    params: submitData,
  });

  const handleConfirm = () => {
    setSubmitData({
      venue_confirmed: values.accept,
      message: values.notes,
      rfpId,
      venueId,
    });
  };

  if (complete) {
    return (
      <Wrapper onClose={onClose}>
        <p className="text-xl mb-5">
          Thank you.
          <br />
          Your reply has been submitted.
        </p>
      </Wrapper>
    );
  }

  if (submitting) {
    return (
      <Wrapper onClose={onClose}>
        <Loading />
      </Wrapper>
    );
  }

  return (
    <Wrapper onClose={onClose}>
      <form className="relative pb-16" onSubmit={handleSubmit(handleConfirm)}>
        <h1 className="mb-8">Send Message</h1>

        <Field
            label="Please enter your message"
            name="notes"
            rows={7}
            component={Textarea}
            validate={v.required}
        />
        {error && (
          <p className="my-5 text-warning">
            {error.response.data.message ||
              "Sorry, there was a problem submitting your reply"}
          </p>
        )}
        <div className="absolute pin-b pin-r">
          <ButtonPrimary
            type="submit"
            classes="px-5"
            style={{ height: 57, lineHeight: "57px" }}
          >
            Reply
          </ButtonPrimary>
        </div>
      </form>
    </Wrapper>
  );
};

export default reduxForm({ form })(ConfirmationReplyModal);
