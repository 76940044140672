import axios from "axios";

const confirmVenueUser = async (
  { venueId, rfpId, confirm, review_items = [], message },
  token
) => {
  if (confirm) {
    try {
      await axios.post(
        `${window.API}/rfp/user-confirm-booking`,
        {
          user_confirmed: confirm,
          enquiry_id: rfpId,
          venue_id: venueId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { result: true };
    } catch (error) {
      return { error };
    }
  } else {
    try {
      await axios.post(
        `${window.API}/rfp/user-send-confirmation-related-message`,
        {
          enquiry_id: rfpId,
          venue_id: venueId,
          message,
          price_related: !!review_items.find(item => item === "Price/costs"),
          terms_related: !!review_items.find(
            item => item === "The terms and conditions"
          ),
          space_related: !!review_items.find(item => item === "The space"),
          other_related: !!review_items.find(item => item === "Other"),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { result: true };
    } catch (error) {
      return { error };
    }
  }
};

const confirmVenueVenue = async (
  { venueId, rfpId, message, venue_confirmed },
  token
) => {
  if (venue_confirmed) {
    try {
      await axios.post(
        `${window.API}/rfp/venue-confirm-booking`,
        {
          venue_confirmed,
          enquiry_id: rfpId,
          venue_id: venueId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { result: true };
    } catch (error) {
      return { error };
    }
  } else {
    try {
      await axios.post(
        `${window.API}/rfp/venue-send-confirmation-related-message`,
        {
          message,
          enquiry_id: rfpId,
          venue_id: venueId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { result: true };
    } catch (error) {
      return { error };
    }
  }
};

const messageReply = async () => {};

export { confirmVenueUser, confirmVenueVenue, messageReply };
