import React, {Component} from "react";
import {connect} from 'react-redux';
import moment from "moment";
import Modal from "Components/Modal";
import {ButtonPrimary, ButtonSpan} from "../../../../Components/Button";
import {processFormValues, renderFormInput} from "../../../../modules/formTools";
import {Field, reduxForm} from "redux-form";
import DateField from "../../../../ui/Date";
import {Select, Textarea} from "../../../../ui";
import {unloadEvent} from "../../../../redux/actions";
import {isRoleEdgeAdmin, companyObject} from "../../../../modules/permissions";
import {upperFirst} from "lodash";
import {COUNTRIES} from "../../../../constants";

const form = "new-event";

const mapDispatchToProps = {
  unloadEvent
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      start_date: moment(props.event.start),
      end_date: moment.utc(props.event.end).subtract(1, 'minutes').toDate(),
      country_id: COUNTRIES.UNITED_KINGDOM,
      company_id: props.companyId ? props.companyId : companyObject(state.auth)?.id
    },
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth)
  };
}

const Wrapper = ({children, onClose}) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 600,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3"/>
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

class NewEventModal extends Component {
  onSubmit = (formValues) => {
    return processFormValues(this.props, formValues, {});
  }

  componentWillUnmount() {
    this.props.unloadEvent()
  }

  render() {
    const {onClose, event, countries, type, isRoleEdgeAdmin} = this.props;
    return (
      <Wrapper onClose={onClose}>
        <div>
          <h2>Create New {upperFirst(type)} Event for {event.start.toDateString()}</h2>
          <div className="mt-3">
            <form className={'border-none'} onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <div className="col col-w-full mb-8 mt-8">
                <Field
                  label={'Title'}
                  name={'title'}
                  component={renderFormInput}
                  labelClassName={'text-xs'}
                />
                <Field
                  label={'Description'}
                  name={'description'}
                  component={Textarea}
                />

                <Field
                  todayButton="Today"
                  label={'Start Date'}
                  name={'start_date'}
                  component={DateField}
                  classes={'min-h-9.5 '}
                  dateFormat={'ddd Do MMM YYYY'}
                />
                <Field
                  label={'End Date'}
                  name={'end_date'}
                  component={DateField}
                  classes={'min-h-9.5 '}
                  todayButton="Today"
                  dateFormat={'ddd Do MMM YYYY'}
                />
                <Field
                  label={'Location'}
                  name={'location'}
                  component={renderFormInput}
                  labelClassName={'text-xs'}
                />
                <Field
                  name="country_id"
                  label="Country"
                  component={Select}
                  options={countries}
                />
                <div className="row">
                  <div className="col">
                    {(!isRoleEdgeAdmin && type === 'public') ? (<></>) : (
                      <ButtonPrimary type="submit" classes={'ml-auto'}>
                        Create Event
                      </ButtonPrimary>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Wrapper>
    );
  }
}

NewEventModal = reduxForm({
  form,
  enableReinitialize: true
})(NewEventModal);

export default connect(mapStateToProps, mapDispatchToProps)(NewEventModal);
