import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { token } from "./auth";
import { createHandleAxiosError } from "./api-tools";
import pipe from "lodash/fp/pipe";

const createAxiosInstance = ({
  baseURL = undefined,
  token = undefined,
  cancelSource = undefined,
}) => {
  const options = {
    baseURL: baseURL || window.API,
  };

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  if (cancelSource) {
    options.cancelToken = cancelSource.token;
  }

  return axios.create(options);
};

const wrapComponent = pipe(
  connect(state => ({
    token: token(state),
  })),
  withRouter
);

export function withAxios(
  WrappedComponent,
  { cancelOnUnmount = true, createApi } = {}
) {
  class WithAxios extends Component {
    state = {
      activeRequests: 0,
    };

    constructor(props) {
      super(props);

      const { token, history, location } = props;

      const cancelSource = axios.CancelToken.source();
      const axiosInstance = createAxiosInstance({ token, cancelSource });

      axiosInstance.isCancel = axios.isCancel;
      axiosInstance.handleError = createHandleAxiosError({
        history,
        location,
      });

      this.axios = axiosInstance;
      this.cancelSource = cancelSource;

      if (createApi) {
        const api = createApi({ axios: axiosInstance });
        api.handleError = axiosInstance.handleError;

        axiosInstance.interceptors.request.use(config => {
          this.setState(state => ({
            activeRequests: state.activeRequests + 1,
          }));
          return config;
        });

        axiosInstance.interceptors.response.use(config => {
          this.setState(state => ({
            activeRequests: state.activeRequests - 1,
          }));
          return config;
        });

        this.api = api;
      }
    }

    componentWillUnmount() {
      if (cancelOnUnmount) {
        this.cancelSource.cancel("Component Unmounted");
      }
    }

    render() {
      return (
        <WrappedComponent
          axios={this.axios}
          api={this.api}
          apiIsBusy={this.state.activeRequests > 0}
          {...this.props}
        />
      );
    }
  }

  return wrapComponent(WithAxios);
}
