import React from 'react';
import { connect } from 'react-redux';
import { ButtonBrand, ButtonLink } from 'Components/Button';
import { loadArticlesOnly, loadOffersOnly } from '../../modules/articles';
import { Link } from 'react-router-dom';
import { formatDate } from '../../modules/article-utils';
import BackgroundImage from "../../Components/General/BackgroundImage";
import LazyLoad from 'react-lazyload';
import {venueInteraction} from "../../modules/analytics";
import {VENUE_TAGS} from "../../modules/analytics/tags";

function NewsArticles({
                        loaded = false,
                        title,
                        articles,
                        type,
                        buttonText = false,
                        cols = 1,
                        limit = 1,
                        buttonUrl = false,
                        trackInteraction,
                        subTitle
                      }) {
  if (!loaded || !articles || articles.length < 1) {
    return null;
  }

  const filteredArticles = articles.filter(article => {
    return article.type && article.type.name === type;
  });

  if (filteredArticles.length < 1) {
    return null;
  }
  let interactionType = '';
  if (type === 'renovation-news') {
    interactionType = VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_RENOVATION_CLICK;
  } else if (type === 'fam-trips') {
    interactionType = VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_FAMTRIP_CLICK;
  } else if (type === 'movers-and-shakers') {
    interactionType = VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_MOVERSSHAKERS_CLICK;
  } else if (type === 'news') {
    interactionType = VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_NEWS_CLICK;
  }

  return (
    <div className="container mt-6">
      <h2 className="mt-0 mb-4 text-2rem">{title}</h2>
      {subTitle && <h3 className="mt-0 mb-4 text-xxl">{subTitle}</h3>}
      <div className="flex flex-wrap relative mt-8 row">
        {loaded && filteredArticles.slice(0, limit).map(article => (
            <div key={article.id} className={`col mb-6 lg:mb-2 md:col-w-1/${cols}`}>
              {article.venues && article.venues[0] &&
                <p className="text-xs font-bold uppercase text-grey-darker mb-2">{article.venues[0].name}</p>}
              <p className="text-lg font-bold">
                <a
                  href={`/article/${article.slug}`}
                  className="text-black hover:text-blue"
                  onClick={() => {
                      trackInteraction(article.venues[0].id, article.venues[0].data_score, article.venues[0].impression_id, interactionType)
                  }}
                >
                  {article.title}
                </a>
              </p>
            </div>
          ))}

          {buttonText !== false && buttonUrl !== false &&
            <div className="col">
              <Link to={buttonUrl} className="inline-block">
                <ButtonBrand classes="w-full">
                  {buttonText}
                </ButtonBrand>
              </Link>
            </div>
          }
        </div>
    </div>
  )
}

function Offers({
                  sectionTitle,
                  loaded,
                  offers,
                  type,
                  limit = 1,
                  buttonText = false,
                  buttonUrl = false,
                  trackInteraction,
                  sectionSubTitle
                }) {
  if (!loaded || !offers || offers.length < 1) {
    return null;
  }

  const filteredOffers = offers.filter(offer => {
    return offer.type && offer.type.name === type;
  });

  if (filteredOffers.length < 1) {
    return null;
  }

  return loaded &&
    <>
      <div className="col"><h2 className="mt-0 mb-4 text-2rem">{sectionTitle}</h2></div>
      <div className="flex flex-wrap relative mt-8">
        {sectionSubTitle && <div className="col"><h3 className="mt-2 mb-4 text-xxl">{sectionSubTitle}</h3></div>}
        {filteredOffers.slice(0, limit).map(offer => (
          <div key={offer.id} className="col mb-8 lg:mb-8 flex flex-row justify-start">
            <a href={`/offer/${offer.id}`}>
              {offer.image && offer.image.url ?
                <LazyLoad height={200} once offset={300}>
                  <BackgroundImage className={'bg-cover h-24 bg-center w-24 mr-4 flex-no-shrink'} imagePath={offer.image.path} edits={{
                    "resize": {
                      "width": 96,
                      "height": 96,
                      "fit": "cover"
                    }
                  }}/>
                </LazyLoad>
                :
                <div style={{backgroundImage: `url(/img/home-offers-placeholder.png)`}}
                     className="bg-cover h-24 bg-center w-24 mr-4 flex-no-shrink"></div>
              }
            </a>
            <div>
              <p className="text-xs font-bold uppercase text-grey-darker mb-2">{offer.venues[0].name}</p>
              <p className="text-lg font-bold">
                <a
                  href={`/offer/${offer.id}`}
                  className="text-black hover:text-blue"
                  onClick={() => {
                    trackInteraction(offer.venues[0].id, offer.venues[0].data_score, offer.venues[0].impression_id, VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_OFFERS_CLICK)
                  }}
                >{offer.title}
                </a>
              </p>

              {offer.valid_from && (
                <p className="text-xs font-bold uppercase text-grey-darker mb-2"><span
                  className="text-bold">Valid: {formatDate(offer.valid_from)} -{' '}{formatDate(offer.valid_to)}</span>
                </p>
              )}
            </div>
          </div>
        ))}

        {buttonText !== false && buttonUrl !== false &&
          <div className="col">
            <Link to={buttonUrl} className="inline-block">
              <ButtonBrand classes="w-full">
                {buttonText}
              </ButtonBrand>
            </Link>
          </div>}
      </div>
    </>;
}

function VenueOfTheWeek(props) {
  return <>
    <h2 className="mt-0 mb-8 md:mb-8 text-2rem">Venue of the Week</h2>
    <div className="shadow mb-8 md:mb-12">
      {props.venueOfTheWeek.featured_image && props.venueOfTheWeek.featured_image.url &&
      <LazyLoad  height={200} once offset={300}>
          <BackgroundImage
              imagePath={props.venueOfTheWeek.featured_image.path}
              className="bg-cover bg-center h-40 rounded-t-lg bg-light-grey"
              edits={{
                  "resize": {
                      "width": 534,
                      "height": 160,
                      "fit": "cover"
                  }}}
          />
      </LazyLoad>
      }
      {!props.venueOfTheWeek.featured_image && props.venueOfTheWeek.images && props.venueOfTheWeek.images.length > 0 && props.venueOfTheWeek.images[0] && props.venueOfTheWeek.images[0].url &&
      <div style={{ backgroundImage: `url(${props.venueOfTheWeek.images[0].url.replace('localapi', 'api').replace('stage-api', 'api')})` }} className="bg-cover bg-center h-40 rounded-t-lg bg-light-grey"/>
      }
      <div className="bg-white py-8 px-4 md:py-12 md:px-12 -mt-3 relative"
           style={{
             borderRadius: 8,
           }}
      >
        <p className="text-2xl font-bold">
          <a href={`/venue/${props.venueOfTheWeek.slug}`} className="text-black hover:text-blue">{props.venueOfTheWeek.name}</a>
        </p>
        {!!props.venueOfTheWeek.description && (

            <p className="font-normal">
              {props.venueOfTheWeek.description.length < 200
                  ? props.venueOfTheWeek.description
                  : `${props.venueOfTheWeek.description.substr(0, 200).trim()}...`}
            </p>

        )}
        <div className="mt-6">
          <ButtonLink Element="a" href={`/venue/${props.venueOfTheWeek.slug}`} classes="text-lg">
            View Venue Details
          </ButtonLink>
        </div>
      </div>
    </div>
  </>;
}

class NewsAndOffers extends React.Component {
    componentDidMount() {
    const { isAgency } = this.props;

    this.props.loadArticlesOnly({ queryParams: { limit: 10, shallow: true, type: 'news' } });
    this.props.loadOffersOnly({ queryParams: { limit: (isAgency ? 3 : 8), type: 'special-offers' } });

    if (isAgency) {
      this.props.loadArticlesOnly({ queryParams: { limit: 1, shallow: true, type: 'movers-and-shakers' } });
      this.props.loadArticlesOnly({ queryParams: { limit: 1, shallow: true, type: 'renovation-news' } });
      this.props.loadOffersOnly({ queryParams: { limit: 1, type: 'fam-trips' } });
      this.props.loadOffersOnly({ queryParams: { limit: 1, type: 'commission-offers' } });
    }
  }

  render() {
    const { isAgency, venueOfTheWeek, articlesLoaded, offersLoaded, articles, offers, venueInteraction } = this.props;

    const trackInteraction = (venueId, dataScore, impressionId, interactionType) => {
      venueInteraction({
        id: venueId,
        data_score: dataScore,
        impression_id: impressionId,
        type: interactionType
      })
    }

    return (
        <div className="pt-14 pb-12 md:pb-8" id="venue-of-the-week">
          <div className="container">
            <div className="flex flex-wrap relative md:static md:mt-0">
              <div className="col mb-10 lg:mb-13 md:col-w-1/2">
                {venueOfTheWeek && venueOfTheWeek.id && <VenueOfTheWeek venueOfTheWeek={venueOfTheWeek}/>}

                <NewsArticles trackInteraction={trackInteraction} loaded={articlesLoaded} articles={articles} title='Venue News' type='news' buttonText='See all venue news' buttonUrl={'/edge-venue-news'} cols={2} limit={isAgency ? 10 : 6}/>
              </div>
              <div className="col mb-10 lg:mb-13 md:col-w-1/2" ref={this.props.agencyOffersRef}>
                {/*<div className="col"><h2 className="mt-0 mb-4 text-2rem">{isAgency ? 'Agent Offers' : 'Venue Special Offers'}</h2></div>*/}
                <Offers loaded={offersLoaded} offers={offers} sectionTitle={isAgency ? 'Agent Offers' : 'Venue Special Offers'} type='special-offers' limit={isAgency ? 3 : 8} buttonText='See all venue offers' buttonUrl={'/special-offers'}/>

                {isAgency && <>
                  <Offers sectionSubTitle="FAM Trips" trackInteraction={trackInteraction} loaded={offersLoaded} offers={offers} type='fam-trips' limit={1} buttonText='See all familiarisation offers' buttonUrl={'/fam-trips'}/>

                  <Offers sectionSubTitle="Commission Offers" trackInteraction={trackInteraction} loaded={offersLoaded} offers={offers} type='commission-offers' limit={1} buttonText='See all commission offers' buttonUrl={'/commission-offers'}/>

                  <NewsArticles subTitle="Renovation News" trackInteraction={trackInteraction} loaded={articlesLoaded} articles={articles} type='renovation-news' buttonText='See all renovation news' buttonUrl={'/renovation-news'}/>

                  <NewsArticles subTitle="Movers and Shakers" trackInteraction={trackInteraction} loaded={articlesLoaded} articles={articles} type='movers-and-shakers' buttonText='See all Movers and Shakers' buttonUrl={'/movers-and-shakers'}/>
                </>}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default connect(
    state => ({
      articles: state.articles.articles,
      offers: state.articles.offers,
      articlesLoaded: state.articles.loadedArticles,
      articlesLoading: state.articles.loadingArticles,
      offersLoaded: state.articles.loadedOffers,
      offersLoading: state.articles.loadingOffers,
    }),
    { loadArticlesOnly, loadOffersOnly, venueInteraction },
)(NewsAndOffers);
