import Axios from "axios";

const getSiteVisitReports = async (count, token) =>
  await Axios.get(`${window.API}/site-visit${count && "?count=" + count}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

const getSiteVisitForVenue = async (count, venueId,  token) =>
    await Axios.get(`${window.API}/site-visit?count=${count}&venue=${venueId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });

const getSiteVisitPageForVenue = async (count, url, page, venueId,  token) =>
    await Axios.get(`${url}?count=${count}&page=${page}&venue=${venueId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });


const getSiteVisitReportPage = async (url, page, token) =>
    await Axios.get(`${url}?page=${page}`, {
        headers: { Authorization: `Bearer ${token}` },
    });

export { getSiteVisitReports,getSiteVisitForVenue,getSiteVisitPageForVenue, getSiteVisitReportPage };
