import axios from "axios";

const setManagingTrinityUser = ({ venueId, edgeUserId, rfpId, collectCommission }, token) =>
  axios.post(
    `${window.API}/rfp/${
      venueId ? "set-venue-managing-user" : "set-enquiry-managing-user"
    }`,
    {
      edge_user_id: edgeUserId,
      venue_id: venueId,
      enquiry_id: rfpId,
      collect_commission:  collectCommission
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

export { setManagingTrinityUser };
