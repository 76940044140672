import {DRAFT_ENQUIRY_RECEIVED_ERROR, DRAFT_ENQUIRY_RECEIVED_REQUEST, DRAFT_ENQUIRY_REQUEST} from "./types";

export const draftEnquiry = (state = {
  draftEnquiry: {},
  isFetchingDraftEnquiry: false,
  draftEnquiryError: false,
  draftEnquiryErrorData: {}
}, action) => {
  switch (action.type) {
    case DRAFT_ENQUIRY_REQUEST:
      return {
        ...state,
        isFetchingDraftEnquiry: true
      }
    case DRAFT_ENQUIRY_RECEIVED_REQUEST:
      return {
        ...state,
        isFetchingDraftEnquiry: false,
        draftEnquiry: action.data,
        draftEnquiryError: false
      }
    case DRAFT_ENQUIRY_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingDraftEnquiry: false,
        draftEnquiryError: true,
        draftEnquiryErrorData: action.error
      }
    default:
      return state;
  }
}
