import React, { useEffect } from "react";
import { string, object, oneOfType } from "prop-types";
import classnames from "classnames";
import { ButtonSpan } from "Components/Button";
import Label from "./Label";
import Error from "./Error";

const Toggle = ({
  meta: { touched, error, warning },
  input: { name, onChange, value },
  label,
  wrapperClassName,
}) => {
  // const handleClick = () => {
  //   const newValue = value ? 0 : 1;
  //   onChange(newValue);
  // };

  useEffect(() => {
    if (!value) {
        onChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={wrapperClassName || "mb-5"}>
      {label && <Label name={name} text={label} />}
      <div
        className="flex text-xs"
        style={{
          lineHeight: "26px",
          height: "28px",
        }}
      >
        <ButtonSpan
          className={classnames(
            "inline-block flex items-center px-3 border border-grey-md border-r-0",
            {
              "opacity-50": !value,
              "bg-brand": value,
            }
          )}
          onClick={() => onChange(1)}
        >
          YES
        </ButtonSpan>
        <ButtonSpan
          className={classnames(
            "inline-block flex items-center px-3 border border-grey-md",
            {
              "opacity-50": value,
              "bg-brand": !value,
            }
          )}
          onClick={() => onChange(0)}
        >
          NO
        </ButtonSpan>
      </div>
      {/* <div>
        <i
          className={`fal fa-toggle-${value ? "on text-brand" : "off"}`}
          role="button"
          tabIndex="0"
          onClick={handleClick}
          style={{ fontSize: 28, lineHeight: 1 }}
        ></i>
      </div> */}
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
};

Toggle.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]),
  wrapperClassName: string,
};

export default Toggle;
