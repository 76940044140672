import React from "react";
import { ButtonPrimary, ButtonTransparent } from "Components/Button";
import Loading from "Components/Loading";

const NavButtons = props => {
  if (props.loading) {
    return (
      <div className="mt-6">
        <Loading />
      </div>
    );
  }
  return (
    <div className="mt-6">
      {props.hasPrev && (
        <div className="mr-4 inline">
          <ButtonTransparent
            onClick={() => {
              props.onPrev(props.index);
            }}
          >
            Back
          </ButtonTransparent>
        </div>
      )}

      <div className="inline">
        <ButtonPrimary type="submit" form={props.targetForm}>
          {props.hasNext ? "Next" : "Complete"}
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default NavButtons;
