import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Link, useLocation} from 'react-router-dom';
import useService from 'hooks/useService';
import { getEnquiries } from 'services/rfpService';
import { Input, Error } from 'Components/Form';
import {ButtonPrimary, ButtonGrey} from 'Components/Button';
import Loading from 'Components/Loading';
import Table from 'Components/Table';
import TableHeaderSortBy from 'Components/TableHeaderSortBy';
import {Date, Pagination, Select} from 'ui';
import moment from 'moment';
import {getStatusOptions} from '../modules/rfp/enquiryVenueDataStatus';
import {renderBookingDesks} from "./Rfp/bookingDesksFilter";
import {ENQUIRY_STATUS} from "../constants";

const headers = (isVenue, isDraft) => [
  {
    key: 'ref_id',
    display: 'Ref ID',
  },
  {
    key: 'event_name',
    display: 'Event Name',
  },
  {
    key: 'event_start_date',
    display: 'Event Start Date',
  },
  {
    key: 'requested_by',
    display: 'Requested By',
    nosort: true,
  },
  !isVenue && (
    {
      key: 'venues_contacted',
      display: 'Venues',
    }
  ),
  {
    key: 'date_submitted',
    display: 'Date Submitted',
  },

  {
    key: isVenue
      ? 'venue_unread_message_count'
      : 'enquirer_unread_message_count',
    display: 'New Messages',
    nosort: true,
  },
  !isDraft && (
    {
      key: 'status',
      display: 'Status',
      nosort: true,
    }
  ),
  ':view',
];

const RfpTable = ({ isVenue, isSuperAgent, isRoleTrinityEdgeRFP, corporateBookingDesks, isDraft = false, isAgent = false}) => {
  const [term, setTerm] = useState('');
  const [status, setStatus] = useState(isDraft ? 'draft' : '');
  const [bookingDesk, setBookingDesk] = useState('');
  const [bookingDesks, setBookingDesks] = useState([]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [filter, setFilter] = useState(0);
  const [params, setParams] = useState({
    sortBy: {
      field: 'date_submitted',
      order: 'DESC',
    },
    term: '',
    page: 1,
    status: status,
    bookingDesk: bookingDesk,
    type: isDraft ? 'draft' : isVenue ? 'direct,agent,managed' : (isRoleTrinityEdgeRFP ? 'personal' : undefined),
  });
  const {loading, data: rfps, error} = useService({
    initialData: {
      enquiries: [],
      current_page: 1,
      last_page: 1,
    },
    initialLoading: false,
    service: getEnquiries,
    params,
    trigger: params,
  });
  const location = useLocation();

  useEffect(() => {
    if (isSuperAgent && typeof corporateBookingDesks !== "undefined" && Object.keys(corporateBookingDesks).length > 0) {
      setBookingDesks(corporateBookingDesks);
    } else if (!isSuperAgent) {
      setBookingDesks([])
    }
  }, []);

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      term,
      status,
      dateFrom,
      dateTo,
      bookingDesk
    }));
  }, [bookingDesk])

  const handleSearch = () => {
    setParams(prev => ({
      ...prev,
      term,
      status,
      dateFrom,
      dateTo,
      bookingDesk
    }));
  };

  useEffect(() => {
    handleSearch();
  }, [filter]);

  const handleFilter = () => {
    handleSearch();
    setFilter(1);
  };

  const handleSort = sortBy => {
    setParams(prev => ({
      ...prev,
      sortBy,
    }));
  };

  const handleSetPage = page => {
    setParams(prev => ({
      ...prev,
      page,
    }));
  };

  const resetFilters = () => {
    setStatus('');
    setDateTo('');
    setDateFrom('');
    setBookingDesk('');
    setTerm('');
    setFilter(0);
  };

  return (
    <div className="py-5">
      <div className="mb-5">
        {isDraft ? (
          <h1>Draft RFPs</h1>
        ) : (
          <h1>Your RFPs</h1>
        )}
      </div>
      <div className="flex flex-col lg:flex-row justify-between align-bottom p-3 bg-white mb-3 lg:items-end">
        {!isDraft && (
          <div className="mb-2 lg:mb-0">
            <Select
              name="status"
              label="Status"
              component={Select}
              options={getStatusOptions()}
              wrapperClassName="mb-0"
              input={{
                onChange: e => setStatus(e.target.value),
                value: status,
              }}
              meta={{}}
            />
          </div>
        )}
        <div className="mb-2 lg:mb-0">
          <Date
            name="date_from"
            label="Date From"
            meta={{}}
            wrapperClassName="mb-0"
            classes={'min-h-9.5 '}
            onChange={e => {
              if (e) {
                setDateFrom(moment(e).format("YYYY-MM-DD"));
              } else {
                setDateFrom(null)
              }
            }}
            input={{
              onChange: e => {
                if (e) {
                  setDateFrom(moment(e).format("YYYY-MM-DD"));
                } else {
                  setDateFrom(null)
                }
              },
              value: dateFrom,
            }}
          />
        </div>
        <div className="mb-2 lg:mb-0">
          <Date
            name="date_to"
            label="Date To"
            meta={{}}
            wrapperClassName="mb-0"
            classes={'min-h-9.5 '}
            onChange={e => {
              if (e) {
                setDateTo(moment(e).format("YYYY-MM-DD"));
              } else {
                setDateTo(null)
              }
            }}
            input={{
              onChange: e => {
                if (e) {
                  setDateTo(moment(e).format("YYYY-MM-DD"));
                } else {
                  setDateTo(null)
                }
              },
              value: dateTo,
            }}
          />
        </div>
        <div className="mb-2 lg:mb-0">
          <Input
            input={{
              onChange: e => setTerm(e.target.value),
              value: term,
            }}
            meta={{}}
            wrapperClassName="mb-0"
            name="term"
            label="Search Term"
            labelClassName="block uppercase font-heading text-xs mb-1"
          />
        </div>
        <div className="mb-2 lg:mb-0">
          <ButtonPrimary classes="mb-0" onClick={handleFilter}>
            {loading ? <Loading inline/> : 'Filter'}
          </ButtonPrimary>
        </div>
        <div className="mb-2 lg:mb-0">
          {/*{(status || dateFrom || dateTo || term) && (*/}
          <ButtonGrey classes="mb-0" onClick={resetFilters} title="Reset filters">
            <i className="fal fa-redo"></i>
          </ButtonGrey>
          {/*)}*/}
        </div>
      </div>
        {bookingDesks.length > 0 ? (
          renderBookingDesks(bookingDesks, setBookingDesk, bookingDesk)
        ) : null}
      {error && (
        <div className="mb-5">
          <Error
            touched
            error={
              error.message || 'Sorry there was an error fetching the data'
            }
          />
        </div>
      )}
      {!loading && rfps && rfps.enquiries && !!rfps.enquiries.length && (
        <>
          <Table
            admin
            headers={headers(isVenue, isDraft).map(header =>
              (header.nosort || typeof header === 'string')
                ? header
                : {
                  key: header.key,
                  display: () => (
                    <TableHeaderSortBy
                      active={
                        params.sortBy.field === header.key
                          ? params.sortBy.order
                          : null
                      }
                      display={header.display}
                      field={header.key}
                      onClick={handleSort}
                    />
                  ),
                },
            )}
            data={rfps.enquiries.map(rfp => ({
              ...rfp,
              event_name: () => (
                <span>
                  {rfp.event_name}
                  {rfp.is_highly_confidential && !isVenue ? (
                    <>
                      <br/><span className="inline-block bg-red text-white p-1 mt-2 text-xs mr-1">Confidential</span>
                    </>
                  ) : null}
                  {rfp.rfp_xpress_service && isAgent ? (
                    <>
                      <br/><span className="inline-block bg-green text-white p-1 mt-2 text-xs mr-1">Xpress/BC</span>
                    </>
                  ) : null}
                  {isAgent && rfp.agent_input_required && (rfp.enquiry_status.code === ENQUIRY_STATUS.DRAFT) ? (
                    <>
                      <br/><span className="inline-block bg-red text-white p-1 mt-2 text-xs mr-1">Agent Input Required</span>
                    </>
                  ) : null}
                  {isAgent && !rfp.requested_by.isDeleted && rfp.requested_by.company ? <span className={'text-xs mt-1'}><br/>{rfp.requested_by.company}</span> : ''}<br/>


                  {!isVenue || !rfp.is_managed &&
                    <>
                      <span className={'text-xs mb-2'}>
                        {rfp.company_name || rfp.requested_by.email}
                      </span>
                      <br/>
                    </>
                  }
                  {(!isVenue && rfp.department) && (
                    <span className="inline-block bg-blue text-white p-1 mt-2 text-xs mr-1">{rfp.department}</span>
                  )}
                  {(!isVenue && rfp.booking_desk) && (
                    <span className="inline-block bg-blue text-white p-1 mt-2 text-xs mr-1">{rfp.booking_desk}</span>
                  )}
                  <div className={'mt-2'}>
                    {(rfp.is_managed && (isVenue || isSuperAgent)) &&
                      <span className={'ftd-rfp-managed text-xs'}>Managed</span>
                    }
                  </div>
                </span>
              ),
              requested_by: () => {
                return (
                  <div>
                    {isVenue && rfp.is_managed && rfp.assigned_to ?
                      <span>{rfp.assigned_to ? rfp.assigned_to : 'N/A'}
                        <br/>
                        <span
                          className="text-xs">{!rfp.requested_by.isDeleted && rfp.requested_by.company ? rfp.requested_by.company : ''}</span>
                      </span>
                      :
                      <span
                        title={!rfp.requested_by.isDeleted && rfp.requested_by.email ? rfp.requested_by.email : 'N/A'}>{!rfp.requested_by.isDeleted && rfp.requested_by.name ? rfp.requested_by.name : 'N/A'}
                        <br/>
                        <span
                          className="text-xs">{!rfp.requested_by.isDeleted && rfp.requested_by.company ? rfp.requested_by.company : ''}</span>
                      </span>
                    }
                  </div>
                );
              },
              venue_unread_message_count: () => (
                <Link to={`${location.pathname}/${rfp.id}`}>
                  <span
                    className={classNames(
                      'inline-block rounded-100 h-8 w-8 flex items-center justify-center',
                      {
                        'bg-grey-md text-black': !rfp.venue_unread_message_count,
                        'bg-red text-white': rfp.venue_unread_message_count,
                      },
                    )}
                  >
                    {rfp.venue_unread_message_count}
                  </span>
                </Link>
              ),
              enquirer_unread_message_count: () => rfp.is_managed ? null : (
                <Link to={`${location.pathname}/${rfp.id}`}>
                  <span
                    className={classNames(
                      'inline-block rounded-100 h-8 w-8 flex items-center justify-center',
                      {
                        'bg-grey-md text-black': !rfp.enquirer_unread_message_count,
                        'bg-red text-white': rfp.enquirer_unread_message_count,
                      },
                    )}
                  >
                    {rfp.enquirer_unread_message_count}
                  </span>
                </Link>
              ),
              status: () => (
                <span className={'text-xs'}>
                  {rfp.enquiry_status.code === ENQUIRY_STATUS.DENIED ? 'Denied' : rfp.status}
                </span>
              ),
              ':view': (
                <ButtonPrimary
                  classes="w-full"
                  Element={Link}
                  to={`${location.pathname}/${rfp.id}`}
                >
                  View
                </ButtonPrimary>
              ),
            }))}
          />
          <Pagination
            current={rfps.current_page}
            last={rfps.last_page}
            onClick={handleSetPage}
          />
        </>
      )}
      {loading && <Loading large/>}
      {!loading && rfps && rfps.enquiries && !rfps.enquiries.length && (
        <p>No results found</p>
      )}
    </div>
  );
};

export default RfpTable;
