import React, { useState, useEffect } from "react";
import Select from "react-select";

const styles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    height: 37,
  }),
  container: (provided, state) => ({
    ...provided,
    height: 37,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 37,
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  }),
};

const Multiselect = props => {
  const { input, options } = props;
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const { value } = input;
    const selectedOptions = options.filter(option =>
      value.includes(option.value)
    );
    setSelected(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const onChangeValues = value => {
    if (!!value) {
      setSelected(value);
      input.onChange(value.map(type => type.value));
    }
  };

  return (
      <React.Fragment>
        {props.label && typeof props.label !== 'undefined' ?  <label className="mb-1 block font-normal font-heading text-sm uppercase">{props.label}</label> : null }

        <Select
            className="w-full text-sm font-normal text-light-text"
            {...input}
            onChange={onChangeValues}
            onBlur={() => input.onBlur(input.value)}
            options={options}
            styles={styles}
            value={selected}
            isMulti
        />
      </React.Fragment>

  );
};

export default Multiselect;
