import axios from "axios";

const getCurrencies = async (token) => {
  try {
    const response = await axios.get(`${window.API}/venues/currencies`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.data) {
      return {
        result: [],
      };
    }

    return {
      result: response.data,
    };
  } catch (error) {
    return { error };
  }
};

const getExchangeRate = async (code, token) => {
  try {
    const response = await axios.get(`${window.API}/currency/exchange-rate/${code}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.data) {
      return {
        result: [],
      };
    }
    return {
      result: response.data,
    };
  } catch (error) {
    return Promise.reject(error);
  }
};


export { getCurrencies, getExchangeRate };
