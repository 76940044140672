import axios from "axios";
import parseErrors from "../../modules/parse-errors";

const saveCustomFields = ({ corporateAccount, json }, token) => {
  return axios.post(
    `${window.API}/corporate-accounts/${corporateAccount}/custom-fields`,
    {
      custom_fields: json,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  ).catch(error => {
    return parseErrors(error);
  });
};

export { saveCustomFields };
