import React, { memo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isLoggedIn } from "../modules/auth";
import { connect } from "react-redux";
import { ButtonBlack } from "../Components/Button";

function RegisterToViewPanel({
                               isLoggedIn,
                               registerText = "To view additional content for this venue please login or register below",
                             }) {
  const history = useHistory();
  const location = useLocation();
  if (isLoggedIn) {
    return;
  }
  const handleClick = () => {
    history.push(
      `/login?return=${encodeURIComponent(location.pathname)}&logInToProceed=1`,
    );
  };

  return (
    <div className="flex flex-wrap justify-between bg-primary px-3 py-6 font-bold text-white my-5">
      <p className="flex-2 mr-3">{registerText || ""}</p>
      <ButtonBlack onClick={handleClick}>
        Login/register
      </ButtonBlack>
    </div>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps)(memo(RegisterToViewPanel));
