import countBy from "lodash/countBy";
import {
  createDateRange,
  dateRangeFilter,
} from "../../../pages/admin/Analytics/date-utils";
import { allTypes, uniqByUuid, venueInteractions } from "./utils";

const INDUSTRY_GROUPS = [
  {
    name: "Services, construction, agriculture, mining",
    label: "Construction",
    colour: "#e65100",
  },
  {
    name: "Charity, government, utilities",
    label: "Utilities",
    colour: "rgb(0, 153, 255)",
  },
  {
    name: "Retail",
    label: "Retail",
    colour: "#9BCB41",
  },
  {
    name: "Entertainment, media, food & drink, travel",
    label: "Media",
    colour: "#f28f00",
  },

  {
    name: "Pharma, health",
    label: "Pharmaceutical",
    colour: "#9863c5",
  },
  {
    name: "Communications, media",
    label: "Communications",
    colour: "#777",
  },
  {
    name: "Trinity - Internal",
    label: "Trinity Internal",
    colour: "#99cc33",
  },
  {
    name: "Finance",
    label: "Finance",
    colour: "#f49ac2",
  },
  {
    name: "Other",
    label: "Other",
    colour: "#aaa",
  },
];

export default (id, start, end, filter, type) => state => {
  let data;
  if (!filter) {
    data = allTypes(id)(state);
  } else if (filter === "interactions") {
    data = venueInteractions(id)(state);
  } else {
    data = [];
  }

  if (type && type !== "All Visitors") {
    data = data.filter(item => item.user_type === type);
  }

  const dateRange = createDateRange(start, end);
  const filtered = dateRangeFilter(data, dateRange, "day");

  const unique = uniqByUuid(filtered);

  const industryNames = INDUSTRY_GROUPS.map(group => group.name);

  // Any user_industry not in INDUSTRY_GROUPS is counted under "Other"
  const groupCounts = countBy(unique, item =>
    industryNames.includes(item.user_industry) ? item.user_industry : "Other"
  );

  return INDUSTRY_GROUPS.map(group => ({
    ...group,
    value: groupCounts[group.name] || 0,
  }));
};
