import React from "react";
import classNames from "classnames";
import { bool, string } from "prop-types";

Loading.propTypes = {
  inline: bool,
  large: bool,
  dark: bool,
  classes: string,
};

export default function Loading({ inline, large, small, classes, dark }) {
  let className = "inline-block m-1";
  className += dark ? " bg-black" : " bg-brand";
  className += large ? " w-8 h-8" : small ?  " w-2 h-2" : " w-4 h-4";
  const style = {
    animationName: "fade",
    animationDuration: "1.7s",
    animationIterationCount: "infinite",
    animationFillMode: "both",
  };
  return (
    <div
      className={classNames(
        {
          "inline-block flex items-center": inline,
          "text-center": !inline,
        },
        classes
      )}
    >
      <span className={classNames(className)} style={style} />
      <span
        className={classNames(className)}
        style={{
          ...style,
          animationDelay: ".3s",
        }}
      />
      <span
        className={classNames(className)}
        style={{
          ...style,
          animationDelay: ".6s",
        }}
      />
    </div>
  );
}

export const LoadingHalfPage = ({ noContainer }) => (
  <div
    className={`${noContainer ? "" : "container"} flex flex-col justify-center`}
    style={{
      minHeight: "50vh",
    }}
  >
    <Loading large />
  </div>
);
