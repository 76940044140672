import ChecksheetBreakdown from './ChecksheetBreakdown';
import { Input } from '../../../../../Components/Form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Field, formValueSelector, getFormSyncWarnings } from 'redux-form';
import moment from 'moment';
import {
  gatherTotals, getVatRateById, calculatePricePerNightPerPersonTotalFromUnit, calculateAndSetPricePerNightPerPersonFinalTotalFromNetAndVatUnits, calculateCommissionFromUnit,
} from '../Helpers/calculateTotals';
import { getFormValues, getHiddenRows } from '../Helpers/getFormState';
import { isAgent, isVenueAdmin } from '../../../../../modules/auth';
import { flagChanges } from '../Helpers/formHelper';
import { ENQUIRY_VENUE_DATA_STATUS } from '../../../../../modules/rfp/enquiryVenueDataStatus';

function getAccommodationDelegatesKey(id) {
  return `delegates[accommodation_${id}]`;
}

function getMinAccommodationDelegatesKey(id) {
  return `minDelegates[accommodation_${id}]`;
}

function getAccommodationRemoveKey(id) {
  return `remove[accommodation_${id}]`;
}

let AccommodationBreakdown = (props) => {
  let { currency = {currency_code: "GBP"} } = props;
  let totals = {};
  let commissionTotals = {};
  let commissionTotalsGBP = {};
  const [grandTotal, setGrandTotal] = useState(0);
  const [, setGrandCommissionTotal] = useState(0);
  const [grandTotalGBP, setGrandTotalGBP] = useState(0);
  const [, setGrandCommissionTotalGBP] = useState(0);
  const [valueChanged, ] = useState(false);

  useEffect(() => {
    gatherTotals(totals, setGrandTotal, props.setAccommodationTotal);
    gatherTotals(commissionTotals, setGrandCommissionTotal, props.setAccommodationCommissionTotal);
    gatherTotals(commissionTotalsGBP, setGrandCommissionTotalGBP, props.setAccommodationCommissionTotalGBP);
  }, [props.values, totals]);

  const onChange = (event, newValue, previousValue, name) => {
    flagChanges(props.initialValues, props.values, props.hiddenRows, newValue, name, props.setAccommodationValueChanged);
  };

  return (

      <ChecksheetBreakdown
          currency={currency}
          headers={(
              <React.Fragment>
                <th className={'p-2 pl-0 text-left uppercase text-xs min-14 w-auto'}>Description</th>
                <th className={'p-2 text-left uppercase text-xs w-20'}>No. Rooms</th>
                <th className={'p-2 text-right uppercase text-xs w-auto'}>Net Price/Person</th>
              </React.Fragment>
          )}
          spaces={3}
          totalAmount={props.currencyFormatter.format(grandTotal)}
          showCommission={props.showCommission}
      >
        {props.accommodations.map(
            ({
               id,
               arrival_date,
               occupancy,
               no_of_nights,
               bedroom_type,
               breakfast_included,
               price_net,
               price_vat,
               price_net_gbp,
               price_vat_gbp,
               included_in_package,
               no_of_rooms,
               display_price_including_vat,
               price_vat_rate_id,
               min_number_of_delegates_for_qualify,
               breakfast_price_net,
               breakfast_price_vat,
               breakfast_price_net_gbp,
               breakfast_price_vat_gbp,
               breakfast_price_vat_rate_id,
             }, index) => {
              let vatRate = getVatRateById(props.vatRates, price_vat_rate_id).number;
              let breakfastVatRate = breakfast_price_vat_rate_id ? getVatRateById(props.vatRates, breakfast_price_vat_rate_id).number : false;
              let isRemoved = typeof props.hiddenRows[getAccommodationRemoveKey(id)] !== 'undefined';

              let netTotal = isRemoved || included_in_package ? 0 : calculatePricePerNightPerPersonTotalFromUnit(props.values, getAccommodationDelegatesKey(id), price_net, no_of_nights);
              let vatTotal = isRemoved || included_in_package ? 0 : calculatePricePerNightPerPersonTotalFromUnit(props.values, getAccommodationDelegatesKey(id), price_vat, no_of_nights);
              let finalTotal = isRemoved || included_in_package ?
                  0 :
                  calculateAndSetPricePerNightPerPersonFinalTotalFromNetAndVatUnits(props.values, getAccommodationDelegatesKey(id), price_net, price_vat, no_of_nights, included_in_package, totals);

              let commission = props.commissionItems && (!included_in_package || !breakfast_included) ? props.commissionItems.find(item => parseInt(item.step_id) === 6) : 0;
              let commissionRate = commission ? commission.rate : 0;
              let commissionAppliedOn = commission ? commission.applied_on : 0;
              let commissionIncludeVat = commission ? commission.include_vat : 0;
              let commissionTotal = 0;
              let commissionTotalGBP = 0;

              const multiplier = Number(no_of_nights) * Number(props.values[`${getAccommodationDelegatesKey(id)}`]);
              if (!isRemoved && !included_in_package) {
                commissionTotal = commission ? calculateCommissionFromUnit(commissionIncludeVat, price_net, price_vat, props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getAccommodationDelegatesKey(id), commissionTotals) : 0;
                commissionTotalGBP = commission ? calculateCommissionFromUnit(commissionIncludeVat, price_net_gbp, price_vat_gbp, props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getAccommodationDelegatesKey(id), commissionTotalsGBP) : 0;
              }

              let breakfastNetTotal = isRemoved || breakfast_included ? 0 : calculatePricePerNightPerPersonTotalFromUnit(props.values, getAccommodationDelegatesKey(id), breakfast_price_net, no_of_nights);
              let breakfastVatTotal = isRemoved || breakfast_included ? 0 : calculatePricePerNightPerPersonTotalFromUnit(props.values, getAccommodationDelegatesKey(id), breakfast_price_vat, no_of_nights);
              let breakfastFinalTotal = isRemoved || breakfast_included ? 0 : Number(breakfastNetTotal) + Number(breakfastVatTotal);
              if (!isRemoved && !breakfast_included) {
                totals[`${id}`] = Number(breakfastFinalTotal);
              }

              let commissionBreakfastTotal = 0;
              let commissionBreakfastTotalGBP = 0;
              if (!isRemoved && !breakfast_included) {
                commissionBreakfastTotal = commission ?
                    calculateCommissionFromUnit(commissionIncludeVat, breakfast_price_net, breakfast_price_vat, props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getAccommodationDelegatesKey(id), commissionTotals) :
                    0;
                commissionBreakfastTotalGBP = commission ?
                    calculateCommissionFromUnit(commissionIncludeVat, breakfast_price_net_gbp, breakfast_price_vat_gbp, props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getAccommodationDelegatesKey(id), commissionTotalsGBP) :
                    0;

                /* SPECIFICALLY OVERRIDDEN HERE IN THIS CASE */
                commissionTotals[getAccommodationDelegatesKey(id)] = Number(commissionTotal) + Number(commissionBreakfastTotal);
                commissionTotalsGBP[getAccommodationDelegatesKey(id)] = Number(commissionTotalGBP) + Number(commissionBreakfastTotalGBP);

              }

              return (
                  <React.Fragment key={`accommodations_${id}_container`}>
                    <tr key={`accommodations_${id}`}
                        className={`${props.hiddenRows[`${getAccommodationRemoveKey(id)}`] !== 'undefined' && props.hiddenRows[`${getAccommodationRemoveKey(id)}`] === true ? 'row-fadeout' : ''} border-b-0 ${props.accommodations.count ===
                        (index + 1) ? 'border-none' : ''}`}>
                      <td className={'p-2 pl-0 text-left '}>
                        <p className={'mb-0'}>Arriving: {moment(arrival_date).format('DD/MM/YYYY')}</p>
                        <p className={'mb-0'}>{`${no_of_nights} ${no_of_nights > 1 ? 'nights' : 'night'}, ${bedroom_type.venue_bedroom_type_name}, ${occupancy} person occupancy`}</p>
                        <p className={'mb-0'}>
                          {included_in_package && <small className={'mb-0'}>(Included in Package)</small>}
                          {breakfast_included && <small className={'mb-0'}>(Breakfast included)</small>}
                        </p>
                      </td>
                      <td className={'p-2 text-left'}>
                        {/*{included_in_package ? no_of_rooms :*/}
                            <Field
                                name={getAccommodationDelegatesKey(id)}
                                wrapperClassName="w-full mr-2"
                                component={Input}
                                type={'number'}
                                step={1}
                                readOnly={props.status == ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS || props.status > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS}
                                normalize={val => (val || '').replace(/[^\d]/g, '')}
                                onChange={onChange}
                            />
                        {/*}*/}
                        <Field
                            component={Input}
                            name={getMinAccommodationDelegatesKey(id)}
                            type="hidden"
                            style={{ height: 0 }}
                        />
                      </td>
                      <td className={'p-2 text-right'}>{included_in_package ? 'N/A' : (props.currencyFormatter.format(price_net))} </td>
                      <td className={'p-2 text-right'}>{included_in_package ? 'N/A' : props.currencyFormatter.format(netTotal)}</td>
                      <td className={'p-2 text-right'}>{included_in_package ? 'N/A' : `${vatRate}%`}</td>
                      <td className={'p-2 text-right'}>{included_in_package ? 'N/A' : props.currencyFormatter.format(vatTotal)}</td>
                      {props.showCommission ?
                          <td className={'p-2 text-right'}>
                            {included_in_package ? 'N/A' : `${commissionRate}%`}
                          </td>
                          :
                          null
                      }
                      {props.showCommission ?
                          <td className={'p-2 text-right'}>
                            {included_in_package ? 'N/A' : props.currencyFormatter.format(commissionTotal)}
                          </td>
                          :
                          null
                      }
                      <td className={'p-2 text-right'}>{included_in_package ? 'N/A' : props.currencyFormatter.format(Number(finalTotal))}</td>
                      <td className={'p-2 text-left w-8'}>
                        {props.status == ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS || props.status > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS ? null :
                            (included_in_package ? null :
                                <Field
                                    name={getAccommodationRemoveKey(id)}
                                    component={props.renderCheckBox}
                                    onChange={onChange}
                                />)}

                      </td>
                    </tr>
                    {!breakfast_included &&
                    <tr key={`accommodations_${id}_breakfast`}
                        className={`${props.hiddenRows[`${getAccommodationRemoveKey(id)}`] !== 'undefined' && props.hiddenRows[`${getAccommodationRemoveKey(id)}`] === true ? 'row-fadeout' : ''} border-b-0 ${props.accommodations.count ===
                        (index + 1) ? 'border-none' : ''}`}>
                      <td className={'p-2 pl-0 text-left'}>
                        <p className={'mb-0'}>Breakfast Cost</p>
                      </td>
                      <td className={'p-2 text-left'}>
                        {/*{no_of_rooms}*/}
                        {/*<Field*/}
                        {/*    component={Input}*/}
                        {/*    name={getMinAccommodationDelegatesKey(id)}*/}
                        {/*    type="hidden"*/}
                        {/*    style={{height: 0}}*/}
                        {/*/>*/}
                      </td>
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(breakfast_price_net)} / room</td>
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(breakfastNetTotal)}</td>
                      <td className={'p-2 text-right'}>{`${breakfastVatRate}%`}</td>
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(breakfastVatTotal)}</td>
                      {props.showCommission ?
                          <td className={'p-2 text-right'}>
                            {commissionRate}%
                          </td>
                          :
                          null
                      }
                      {props.showCommission ?
                          <td className={'p-2 text-right'}>
                            {props.currencyFormatter.format(commissionBreakfastTotal)}
                          </td>
                          :
                          null
                      }
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(Number(breakfastFinalTotal))}</td>
                      <td className={'p-2 text-left w-8'}>
                        {/*{ props.status == 50 || props.status > 51  ? null :*/}

                        {/*        <Field*/}
                        {/*            name={getAccommodationRemoveKey(id)}*/}
                        {/*            component={props.renderCheckBox}*/}
                        {/*            onChange={onChange}*/}
                        {/*        />*/}
                        {/*}*/}

                      </td>
                    </tr>}


                    <tr key={`accommodations_${id}_error`} className={'border-none'}>
                      <td colSpan={5}> {typeof props.submitWarnings['accommodation_' + id] !== 'undefined' ?
                          <span className=" table-cell w-100 border-0 text-warning font-normal mb-2"> {props.submitWarnings['accommodation_' + id]} </span> :
                          null}</td>
                    </tr>

                  </React.Fragment>

              );
            },
        )}
        {valueChanged ?
            <p>As you have adjusted the number required from your original brief, the venue may need to requote this
              element </p>
            : null}
      </ChecksheetBreakdown>
  );

};

const selector = formValueSelector('confirmBooking');

export default withRouter(
    connect((state, props) => ({
          hiddenRows: getHiddenRows(props.accommodations, getAccommodationRemoveKey, state, selector),
          values: getFormValues(props.accommodations, getAccommodationDelegatesKey, state, selector),
          submitWarnings: getFormSyncWarnings('confirmBooking')(state),
          isVenue: isVenueAdmin(state),
          isAgency: isAgent(state),
        }),
    )(AccommodationBreakdown),
);
