import React from "react";
import Dropzone from "react-dropzone";
import { bool, func, oneOf } from "prop-types";
import Loading from "Components/Loading";

export default class DropzoneArea extends React.Component {
  static propTypes = {
    onDrop: func.isRequired,
    uploading: bool.isRequired,
    type: oneOf(["image", "document", "csv", "document_plus"]).isRequired,
  };

  render() {
    const {onDrop, uploading, type, maxSize = '10MB', minWidth = '1000px', minHeight = '600px'} = this.props;
    return (
      <Dropzone
        onDrop={onDrop}
        className="relative flex justify-center items-center border-2 border-black border-dashed rounded p-5 mb-5 pointer w-72"
      >
        {uploading ? (
          <div
            className="absolute pin-t pin-l w-full h-full"
            style={{ backgroundColor: "rgba(0,0,0,.5)" }}
          >
            <div className="absolute-center">
              <Loading inline />
            </div>
          </div>
        ) : null}
        <div>
          <i className="fas fa-upload mr-3" />
          Upload {type === 'document_plus' ? 'document': type}
          <br />
          <span className="text-xs inline-block mt-3">
            <b>FILE TYPE</b>: {type === "image" ? "jpg/jpeg" : type === 'document_plus' ? "PDF, DOCX, DOC, XLSX" : type === "csv" ? "CSV" : "pdf"}
          </span>
          <span className="text-xs block mt-2">
            <b>FILE SIZE</b>: max {maxSize}
          </span>
          {type === "image" ? (
            <span className="text-xs inline-block mt-2">
              <b>SIZE</b>: min width {minWidth}, min height {minHeight}
            </span>
          ) : null}
        </div>
      </Dropzone>
    );
  }
}
