import {fetchNamedLocationTypesPending, fetchNamedLocationTypesSuccess, fetchNamedLocationTypesError} from 'modules/named-locations.js';

function fetchNamedLocationTypes(city) {
    return dispatch => {
        dispatch(fetchNamedLocationTypesPending());
        fetch(`${window.API}/named/locations/type?city=${city}`)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(fetchNamedLocationTypesSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchNamedLocationTypesError(error));
            })
    }
}

export default fetchNamedLocationTypes;