import React from "react";
import { Field, reduxForm } from "redux-form";
import Validate from "../Validation/NewSiteVisitReportValidation.js";
import NavButtons from "../Components/NavButtons";

import { Select, Date, Textarea } from "Components/Form";

const SiteVisitReport = props => {
  return (
    <div className={`bg-white ${props.className}`}>
      <h2 className="flex justify-between">
        <span>Report Details</span>
      </h2>
      <form onSubmit={props.handleSubmit}>
        <div className={`mt-6`}>
          <div className="w-full flex flex-col lg:flex-row">
            <Field
              label="Visit date from"
              name="visit_date_from"
              component={Date}
              wrapperClassName="w-full lg:w-auto mb-5 mr-2"
              validate={Validate.visit_date_from}
            />
            <Field
              label="Visit date to"
              name="visit_date_to"
              component={Date}
              wrapperClassName="w-full lg:w-auto mb-5 mr-2"
              validate={Validate.visit_date_to}
            />
          </div>
          <div className="w-full">
            <Field
              component={Select}
              label="Reason for visit"
              name="reason_for_visit"
              wrapperClassName="w-full lg:w-auto mb-5 mr-2"
              noPlaceholder={false}
              options={[
                { text: "Visit with client", value: "Visit with client" },
                { text: "Visit without client", value: "Visit without client" },
                { text: "Attending event", value: "Attending event" },
                { text: "Other", value: "Other" },
              ]}
              validate={Validate.reason_for_visit}
            />
          </div>
          <div className="w-full">
            <Field
              component={Textarea}
              label="Impressions"
              name="impressions"
              wrapperClassName="w-full lg:w-auto mb-5 mr-2"
              rows={8}
              placeholder={
                "Your overall thoughts and review of the venue or destination"
              }
              validate={Validate.impressions}
            />
          </div>
          <div className="w-full">
            <Field
              component={Textarea}
              label="Considerations"
              name="considerations"
              wrapperClassName="w-full lg:w-auto mb-5 mr-2"
              rows={5}
              placeholder={
                "eg. Difficult to find, well equipped for disabled access"
              }
              validate={Validate.considerations}
            />
          </div>

          <NavButtons {...props} />
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
})(SiteVisitReport);
