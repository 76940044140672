import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ButtonBrand } from "Components/Button";
import { pushEvent } from "modules/google-tag-manager";
import LetsConnect from "Components/LetsConnect";

const VenueRegistrationComplete = () => {
  useEffect(() => {
    pushEvent("user_registered");
  }, []);

  return (
    <div className="container">
      <div className="flex">
        <div className="w-full mt-4 p-12">
          <span className="text-center block text-5xl">
            <i className="fal fa-envelope-open-text"></i>
          </span>
          <h1 id="user-registered" className="text-center">
            Thank you for registering
          </h1>
          <p className="text-center mt-10 text-lg">
            An e-mail has just been sent to you.
          </p>
          <p className="text-center text-lg">
            Please find our e-mail and click the link to verify your EDGE
            account.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/3 p-2">
          <div className="bg-white h-full p-10">
            <span className="text-center text-5xl">
              <i className="fal fa-hotel block mb-4"></i>
            </span>
            <h3 className="text-center mb-5">Update your profile</h3>
            <p>
              Join our 100% club and ensure all your data fields are complete to
              give you maximum exposure and increase your search ratings.
            </p>
            <ButtonBrand Element={Link} to={"/login"} classes="w-full mb-5">
              {"Update your profile"}
            </ButtonBrand>
          </div>
        </div>
        <div className="w-full lg:w-1/3 p-2">
          <div className="bg-white h-full p-10">
            <span className="text-center text-5xl">
              <i className="fal fa-camera block mb-4"></i>
            </span>
            <h3 className="text-center mb-5">Quality images</h3>
            <p>
              People buy from good photography. Ensure you have high-quality
              images in your photo gallery to help promote your venue.
            </p>
            <ButtonBrand Element={Link} to={"/login"} classes="w-full mb-5">
              {"Update your photos"}
            </ButtonBrand>
          </div>
        </div>
        <div className="w-full lg:w-1/3 p-2">
          <div className="bg-white h-full p-10">
            <span className="text-center text-5xl">
              <i className="fal fa-megaphone block mb-4"></i>
            </span>
            <h3 className="text-center mb-5">Engagement</h3>
            <p>
              Share your latest news and offers and keep the EDGE Venues
              community up to date with what’s happening at your venue.
            </p>
            <ButtonBrand Element={Link} to={"/login"} classes="w-full mb-5">
              {"Share news and offers"}
            </ButtonBrand>
          </div>
        </div>
      </div>

      <LetsConnect />
    </div>
  );
};

export default VenueRegistrationComplete;
