import React, {Component} from 'react';
import TemplatesListTable from "./TemplatesListTable";
import {connect} from "react-redux";
import {token} from "../../../../modules/auth";
import {eventsListUnload, fetchBookedEventsList} from "../../../../redux/actions";
import {Pagination} from "../../../../ui";
import Loading from "../../../../Components/Loading";
import TemplateFilters from "./TemplateFilters";

const mapStateToProps = state => {
  return {
    ...state.bookedEvents,
    ...state.bookedEventsPage,
    ...state.bookedEventsLastPage,
    token: token(state)
  }
}

const mapDispatchToProps = {
  fetchBookedEventsList,
  eventsListUnload
}

class TemplatesListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.bookedEventsPage,
      queryParams: null
    }
  }

  filterBookedEvents = (queryParams) => {
    if (performance.getEntriesByType("navigation")[0].type === 'reload') {
      this.setPage(1);
    }

    this.setState({
      queryParams: queryParams
    })
    return this.props.fetchBookedEventsList(this.props.token, this.props.bookedEventsPage, queryParams)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      return this.props.fetchBookedEventsList(this.props.token, this.state.page, this.state.queryParams)
    }
  }

  componentWillUnmount() {
    this.props.eventsListUnload();
  }

  setPage = (page) => {
    this.setState(prev => ({
      page,
      queryParams: {
        ...prev.queryParams,
        page
      }
    }))
  }

  render() {
    const {
      bookedEvents = [],
      isFetchingBookedEvents,
      bookedEventsPage,
      bookedEventsLastPage,
      token
    } = this.props;

    return (
      <div className="py-5">
        <div className="md:flex md:justify-between mb-5">
          <h1>Create an event from a previous template</h1>
        </div>
        <TemplateFilters
          filterBookedEvents={this.filterBookedEvents}
          currentPage={bookedEventsPage}
        />
        {isFetchingBookedEvents ? (
          <Loading/>
        ) : (
          <TemplatesListTable
            bookedEvents={bookedEvents}
            token={token}
          />
        )}
        <Pagination
          current={bookedEventsPage}
          last={bookedEventsLastPage}
          onClick={this.setPage}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesListContainer);
