import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { token } from "modules/auth";

const useService = ({
                      initialData = null,
                      initialLoading = true,
                      params,
                      service,
                      trigger,
                    }) => {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(initialLoading);
  const authToken = useSelector(token);

  useEffect(() => {
    const hitApi = async () => {
      setLoading(true);

      const { error, result } = await service(params, authToken);

      setError(error);
      setData(result);
      setLoading(false);
    };

    if (trigger) {
      hitApi();
    }
  }, [authToken, trigger, params, service]);

  return { data, error, loading, onUpdate: setData };
};

export default useService;
