import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ButtonPrimary, ButtonBlack } from "Components/Button";
import Loading from "Components/Loading";
import useService from "hooks/useService";
import { chaseVenue } from "services/rfpService";
import RfpProposalActionModal from "./RfpProposalActionModal";

const ChaseVenueModal = ({ onClose, venue }) => {
  const [submitData, setSubmitData] = useState(null);
  const { id: rfpId } = useParams();
  const { loading: submitting, data: complete, error } = useService({
    initialLoading: false,
    service: chaseVenue,
    trigger: submitData,
    params: submitData,
  });

  const handleConfirm = () => {
    setSubmitData({
      enquiry_id: rfpId,
      venue_id: venue.id,
    });
  };
  if(error){
  }
  return (
    <RfpProposalActionModal
      title="Chase Up Venue"
      onConfirm={handleConfirm}
      onClose={onClose}
      venue={venue}
      content={
        <div className="relative h-full pb-16">
          {complete ? (
            <p className="text-xl mb-5">
              Thank you.
              <br />
              We have notified the venue.
            </p>
          ) : (
            <>
              <p className="text-xl mb-5">
                Would you like to chase up the venue and remind them you're
                awaiting a response?
              </p>
              <div className="flex justify-center">
                <ButtonBlack
                  classes="px-5"
                  onClick={submitting ? () => {} : onClose}
                  style={{ height: 57, lineHeight: "57px" }}
                >
                  No cancel
                </ButtonBlack>
                <p className="mr-6 mt-6"></p>
                <ButtonPrimary
                  classes="px-5"
                  onClick={handleConfirm}
                  style={{ height: 57, lineHeight: "57px" }}
                >
                  {submitting ? <Loading inline /> : "Yes go ahead"}
                </ButtonPrimary>
              </div>
              {error && (
                <p className="my-5 text-warning">
                  {error.response.data.message.includes('24') ? error.response.data.message : 'Sorry, there was a problem submitting your request' }
                </p>
              )}
            </>
          )}
        </div>
      }
    />
  );
};

export default ChaseVenueModal;
