import React, {useState} from "react";
import {Link} from "react-router-dom";
import useAxios from "hooks/useAxios";
import Loading from "Components/Loading";
import FeaturedSection from "Components/FeaturedSection";
import Input from "../Components/Form/Input";

const Regions = (props) => {
  const {data, loading, error} = useAxios({
    url: `${window.API}/regions`,
  });

  if (loading) {
    return (
      <div className="mb-8">
        <Loading/>
      </div>
    );
  }

  if (error) return <p>Sorry, there was a problem fetching the data</p>;

  return (
    <div className="row mb-8">
      {data.map(region =>
        region.locations.filter(location => {
          return location ? location.name.toLowerCase().includes(props.region ? props.region.toLowerCase() : '') : true;
        }).length > 0 ? (
          <div className="col md:col-w-1/2 lg:col-w-1/3 mb-5" key={region.name}>
            <h3 className="bg-brand p-3 text-white mb-3">{region.name}</h3>
            <div style={{columnCount: 2}}>
              {region.locations.filter(location => {
                return location ? location.name.toLowerCase().includes(props.region ? props.region.toLowerCase() : '') : true;
              }).map(location => (
                <Link
                  key={location.name}
                  to={`/venues-in-${location.name
                    .toLowerCase()
                    .replace(/ /g, "-")}`}
                  title={`Venues is ${location.name}`}
                  className="block mb-2 text-black underline font-normal"
                >
                  {location.name}
                </Link>
              ))}
            </div>
          </div>
        ) : null
      )}
    </div>
  );
};

const UkVenueLocations = () => {
  const [region, setRegion] = useState("");
  return (
    <div className="container">
      <div className="row">
        <div className="col col-w-1/2">
          <h1 className="mt-8 mb-5">EDGE UK Venue Locations</h1>
        </div>
        <div className="col col-w-1/2">
          <Input
            input={{
              onChange: e => setRegion(e.target.value),
              value: region,
            }}
            labelClassName="sr-only"
            iconRight="/img/search-icon.svg"
            meta={{}}
            name="term"
            label={`Search Regions`}
            placeholder={`Search Regions`}
            wrapperClassName="mb-5 mt-8 w-full"
          />
        </div>
      </div>
      <FeaturedSection
        url={`${window.API}/destinations-list?uk=1&featured=1`}
      />
      <Regions region={region}/>
    </div>
  );
};

export default UkVenueLocations;
