import { venueInteractions, uniqByUuid } from "./utils";
import getDatesArray from "../get-dates-array";
import {VENUE_TAGS} from '../tags';

export default (id, start, end) => state => {
  const dates = getDatesArray(start, end);
  const engagements = venueInteractions(id)(state).filter(e =>
    dates.includes(e.day)
  );

  const galleryViews = engagements.filter(e => e.type === "view_gallery_image");
  const uniqueGalleryViewers = uniqByUuid(galleryViews).length;

  const data = {
    telephone: engagements.filter(e =>
      [
        VENUE_TAGS.TELEPHONE.CLICK_CONTACT_PHONE,
        VENUE_TAGS.TELEPHONE.CLICK_CALL_VENUE,
        VENUE_TAGS.TELEPHONE.VIEW_PHONE,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_NEWS_PHONE_CLICK,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_SPECIAL_OFFERS_PHONE_CLICK,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_FAMTRIP_PHONE_CLICK,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_RENOVATION_PHONE_CLICK,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_COMMISSION_OFFERS_PHONE_CLICK
      ].includes(e.type)
    ),
    email: engagements.filter(e =>
      [
        VENUE_TAGS.EMAIL.CLICK_CONTACT_EMAIL,
        VENUE_TAGS.EMAIL.VIEW_EMAIL,
        VENUE_TAGS.EMAIL.CLICK_EMAIL_VENUE,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_NEWS_EMAIL_CLICK,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_SPECIAL_OFFERS_EMAIL_CLICK,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_FAMTRIP_EMAIL_CLICK,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_RENOVATION_EMAIL_CLICK,
        VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_COMMISSION_OFFERS_EMAIL_CLICK
      ].includes(e.type)
    ),
    website: engagements.filter(e => e.type === VENUE_TAGS.WEBSITE.VIEW_WEBSITE),
    social: engagements.filter(e =>
      [VENUE_TAGS.SOCIALS.VIEW_TWITTER, VENUE_TAGS.SOCIALS.VIEW_INSTAGRAM, VENUE_TAGS.SOCIALS.VIEW_TRIPADVISOR].includes(e.type)
    ),
    view_get_directions: engagements.filter(e => [VENUE_TAGS.VIEW_GET_DIRECTIONS].includes(e.type)),
    view_health_and_safety: engagements.filter(e => [VENUE_TAGS.VIEW_HEALTH_AND_SAFETY].includes(e.type)),
    view_facilities: engagements.filter(e => [VENUE_TAGS.VIEW_FACILITIES].includes(e.type)),
    view_tripadvisor: engagements.filter(e => [VENUE_TAGS.SOCIALS.VIEW_TRIPADVISOR].includes(e.type)),
    view_instagram: engagements.filter(e => [VENUE_TAGS.SOCIALS.VIEW_INSTAGRAM].includes(e.type)),
    view_twitter: engagements.filter(e => [VENUE_TAGS.SOCIALS.VIEW_TWITTER].includes(e.type)),
    view_site_visits: engagements.filter(e => [VENUE_TAGS.VIEW_SITE_VISITS].includes(e.type)),
    view_wifi: engagements.filter(e => ["wifi_expanded"].includes(e.type)),
    web_enquiries: engagements.filter(
      e =>
        e.type.startsWith("rfq_") ||
        e.type === VENUE_TAGS.WEB_ENQUIRIES.BUY_IT_NOW ||
        e.type === VENUE_TAGS.WEB_ENQUIRIES.ENQUIRE_SIDEBAR
    ),
    venue_compared: engagements.filter(e => [VENUE_TAGS.COMPARE].includes(e.type)),
    venue_shortlisted: engagements.filter(e => [VENUE_TAGS.VENUE_SHORTLISTED.ADD_TO_SHORTLIST].includes(e.type)),
    view_meeting_rooms: engagements.filter(e =>
      [
        VENUE_TAGS.VIEW_MEETING_ROOMS.VIEW_MEETING_ROOMS
      ].includes(e.type)
    ),
    view_meeting_room: engagements.filter(e => [VENUE_TAGS.VIEW_MEETING_ROOMS.VIEW_MEETING_ROOM].includes(e.type)),
    view_catering: engagements.filter(e =>
      [
        VENUE_TAGS.VIEW_CATERING.VIEW_CATERING,
        VENUE_TAGS.VIEW_CATERING.CATERING_EXPANDED
      ].includes(e.type)
    ),
    view_parking: engagements.filter(e =>
      [
        VENUE_TAGS.VIEW_PARKING.VIEW_PARKING,
        VENUE_TAGS.VIEW_PARKING.PARKING_EXPANDED
      ].includes(e.type)
    ),
    view_disabled_access: engagements.filter(
      e => e.type === VENUE_TAGS.VIEW_DISABLED_ACCESS
    ),
    view_leisure: engagements.filter(e =>
      [
        VENUE_TAGS.VIEW_LEISURE.VIEW_LEISURE,
        VENUE_TAGS.VIEW_LEISURE.LEISURE_EXPANDED
      ].includes(e.type)
    ),
    venue_shared: engagements.filter(e => [
      VENUE_TAGS.VENUE_SHARED.SHARE_INDIVIDUAL_VENUE,
      VENUE_TAGS.COMPARE_FEEDBACK,
      VENUE_TAGS.VENUE_SHARED.SHARE_COMPARISON
    ].includes(e.type)),
    view_csr: engagements.filter(e => [
      VENUE_TAGS.VIEW_CSR_LINK,
      'csr_expanded'
    ].includes(e.type)),
    // view_csr: engagements.filter(e => e.type === VENUE_TAGS.VIEW_CSR_LINK),
    view_misc: engagements.filter(e => e.type === VENUE_TAGS.VIEW_MISC.MISC_EXPANDED),
    venue_content_viewed: engagements.filter(e =>
      [
        VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_NEWS_CLICK,
        VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_OFFERS_CLICK,
        VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_RENOVATION_CLICK,
        VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_FAMTRIP_CLICK,
        VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_MOVERSSHAKERS_CLICK
      ].includes(e.type)
    ),
    average_images_viewed: galleryViews.length
      ? Math.ceil(galleryViews.length / uniqueGalleryViewers)
      : 0,
  };
  return data;
};
