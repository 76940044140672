import Modal from "../Modal";
import React, {useState} from "react";
import {ButtonPrimary, ButtonPrimaryRed, ButtonSpan} from "../Button";
import {Textarea, Toggle} from "../Form";
import { Field, reduxForm } from "redux-form";
import useService from "../../hooks/useService";
import {required} from "../../modules/validation";
import Loading from "../Loading";
import {isAgency, isInternal} from "../../modules/auth";
import {connect} from 'react-redux';
import {ENQUIRY_STATUS} from "../../constants";
import {ENQUIRY_VENUE_DATA_STATUS} from "../../modules/rfp/enquiryVenueDataStatus";
import {denyRFP} from "../../services/rfpService/denyRFP";
import {Radio} from "../../ui";
import {getAvailabilityTypes} from "../../services/rfpService";
import {getDeclineReasons} from "../../services/rfpService/declineReasons";

const form = "rfp-denied";
const v = {
    required: required(),
};

const mapStateToProps = (state, props) => {
    return {
        isInternal: isInternal(state),
        isAgency: isAgency(state),

        initialValues: {
            decline_reason_id: props.enquiry.brief.decline_reason_id,
            decline_reason_text: props.enquiry.brief.decline_reason_text ? props.enquiry.brief.decline_reason_text : '',
        },
    };
};


const Wrapper = ({ children, onClose }) => (
    <Modal>
        <div className="modal">
            <div
                className="modal-inner"
                style={{
                    width: 1200,
                    maxWidth: "90%",
                }}
            >
                <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
                    <i className="fas fa-times-circle fa-2x ml-3" />
                </ButtonSpan>
                {children}
            </div>
        </div>
    </Modal>
);

let RFPDeclineModalForm = ({onClose, enquiry, handleSubmit, isInternal, isAgency, dispatch, change}) => {
    const [submitData, setSubmitData] = useState(null);
    const [requiresText, setRequiresText] = useState(!!enquiry.brief.decline_reason_text);
    const [isDelete, setIsDelete] = useState(false);

    const { loading: submitting, data: data, error } = useService({
        initialLoading: false,
        service: denyRFP,
        trigger: submitData,
        params: submitData,
    });

    const { data: declineReasons } = useService({
        initialData: [],
        service: getDeclineReasons,
        trigger: true,
    });

    const handleCancel = (values) => {
        if(isDelete) {
            if(window.confirm('Are you sure you wish to delete this rfp?') === true) {
                setSubmitData({
                    declineReasonId: values.decline_reason_id,
                    declineReasonText: values.decline_reason_text ? values.decline_reason_text : null ,
                    rfpId: enquiry.id,
                    isDelete: isDelete
                });
            }
        }  else
        {
            setSubmitData({
                declineReasonId: values.decline_reason_id,
                declineReasonText: values.decline_reason_text ? values.decline_reason_text : null ,
                rfpId: enquiry.id,
                isDelete: isDelete
            });
        }

    };


    if (data) {

        return (
            <Wrapper onClose={onClose(isDelete)}>
                <p className="text-xl mb-5">
                    Thank you.
                    <br />
                    {isDelete ? 'This event has been deleted' : 'This event has been declined' }
                </p>
                { (isAgency || isInternal) && data.length > 0  ?
                    <div>
                        <p>The following venues do not have emails and will need to be contacted separately</p>

                        { data.map((missingVenue) => {
                            return <p>{missingVenue}</p>
                        })}
                    </div>
                    :
                    null
                }

            </Wrapper>
        );
    }
    let confirmData = enquiry ? enquiry.enquiry_venue_datas.find((evd) => evd.status === ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED) : null;
    return (
        <Wrapper onClose={onClose}>
            <form className="relative pb-16" onSubmit={handleSubmit(handleCancel)}>
                <h1 className="mb-8">Are you sure you want to decline your booking?</h1>
                <Field
                    name="decline_reason_id"
                    label="Option*"
                    block
                    component={Radio}
                    options={declineReasons}
                    validate={v.required}
                    onChange={e => {
                       let reason = declineReasons.at(e - 1);
                       setRequiresText(reason.requires_text)
                    }}
                />
                {requiresText ?
                    <Field
                        label="Please advise your reason for cancelling"
                        name="decline_reason_text"
                        rows={7}
                        component={Textarea}
                        validate={v.required}
                    />
                    : null
                }
                {isInternal ?

                    <p className="text-dark-red font-bold text-right">Deleting RFP will cause it to no longer show up in any lists</p>

                : null}

                <div className="absolute pin-b pin-r">
                    <div className={'flex'}>
                        {
                            isInternal && !confirmData  ?
                                <ButtonPrimaryRed
                                    type="submit"
                                    classes="px-5 mx-5"
                                    style={{ height: 57, lineHeight: "57px" }}
                                    onClick={(e) => {
                                        setIsDelete(true);


                                    }}
                                >
                                    {submitting ? <Loading inline/> :   "Delete Rfp" }
                                </ButtonPrimaryRed>
                                :
                                null
                        }

                        <ButtonPrimary
                            type="submit"
                            classes="px-5"
                            style={{ height: 57, lineHeight: "57px" }}
                            onClick={(e) => {
                                setIsDelete(false);
                                // handleSubmit(handleCancel)
                            }}
                        >
                            {submitting ? <Loading inline/> :  enquiry.brief.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED ?  "Confirm Decline Request" : "Edit Decline Info" }
                        </ButtonPrimary>
                    </div>

                </div>
            </form>
        </Wrapper>
    );
}

RFPDeclineModalForm = reduxForm({
    form: form,
    enableReinitialize: true
})(RFPDeclineModalForm);

export default connect(mapStateToProps, null)(RFPDeclineModalForm);