import React from "react";
import Label from "./Label";

const LabelledText = ({ label, text, div, Wrapper, className, hideLabel, styles={ wordBreak: "break-all", whiteSpace: "pre-wrap" } }) => {
  const TextEl = div ? "div" : "p";

  if (Wrapper) {
    return (
      <Wrapper className={className}>
        {!hideLabel && <Label text={label} />}
        <TextEl
          className="leading-none mb-4"
          style={styles}
        >
          {text}
        </TextEl>
      </Wrapper>
    );
  }
  return (
    <>
      {!hideLabel && <Label text={label} />}
      <TextEl className="leading-none mb-4" style={styles}>
        {text}
      </TextEl>
    </>
  );
};

export default LabelledText;
