import axios from "axios";
import { isArray } from 'lodash';

const submitInitialResponse = async (
  {
    availability_type_id,
    availability_child_type_id,
    date_from,
    date_to,
      note,
    venue_id,
    enquiry_id,
      availabilityType,
      patch
  },
  token
) => {
  try {
      let response = null;
      if(patch){
          response = await axios.patch(
              `${window.API}/rfp/venue-initial-response`,
              {
                  availability_type_id:  isArray(availabilityType.children) && availabilityType.children.length > 0 && availability_child_type_id ? availability_child_type_id : availability_type_id,
                  availability_note: note ? note : (date_from && date_to ? `${date_from},${date_to}` : null),
                  venue_id,
                  enquiry_id,
              },
              {
                  headers: { Authorization: `Bearer ${token}` },
              }
          );
      }else
      {
           response = await axios.post(
              `${window.API}/rfp/venue-initial-response`,
              {
                  availability_type_id:  isArray(availabilityType.children) && availabilityType.children.length > 0 && availability_child_type_id ? availability_child_type_id : availability_type_id,
                  availability_note: note ? note : (date_from && date_to ? `${date_from},${date_to}` : null),
                  venue_id,
                  enquiry_id,
              },
              {
                  headers: { Authorization: `Bearer ${token}` },
              }
          );
      }

    return { result: response.data };
  } catch (error) {
    return { error };
  }
};

export { submitInitialResponse };
