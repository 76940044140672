import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { venueInteraction } from "../../modules/analytics";

import { loadedArticles, loadingArticles } from "modules/articles";
import { connect } from "react-redux";
import Loading from "Components/Loading";
import Breadcrumbs from "Components/Breadcrumbs";
import { formatDate, getArticleImage, getVenueImageFromArticle, offerStyles } from "../../modules/article-utils";
import { tidyWysiwygContent } from "../../modules/string-tools";
import { VENUE_TAGS } from "../../modules/analytics/tags";

const offerById = (offers, id) => offers.find(offer => Number(offer.id) === Number(id));

class ViewOffer extends React.Component {
  state = {
    showEmail: false,
    showPhone: false,
  };

  loadArticles() {
    const urls = ["/cms/articles", "/cms/offers"];
    const { loadingArticles, loadedArticles } = this.props;

    loadingArticles();

    Promise.all(urls.map(url => axios.get(window.API + url))).then(
      ([articles, offers]) => {
        loadedArticles({
          articles: articles.data,
          offers: offers.data,
        });
      },
    );
  }

  loadOffer(offerId) {
    const urls = ["/cms/offers/" + offerId];
    const { loadingArticles, loadedArticles } = this.props;

    loadingArticles();

    Promise.all(urls.map(url => axios.get(window.API + url))).then(
      (response) => {
        loadedArticles({
          articles: [],
          offers: [response[0]?.data],
        });
      },
    );
  }

  showEmail = () => {
    this.setState({ showEmail: true });
    const offer = offerById(this.props.offers, this.props.match.params.offerId);
    // console.log(offer.type.name)
    let venues = offer.venues;
    let venue = venues.length === 1 ? venues[0] : null;
    let interactionType;
    if (offer.type.name === "news") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_NEWS_EMAIL_CLICK;
    } else if (offer.type.name === "offers") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_OFFERS_EMAIL_CLICK;
    } else if (offer.type.name === "renovation") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_RENOVATION_EMAIL_CLICK;
    } else if (offer.type.name === "famtrip") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_FAMTRIP_EMAIL_CLICK;
    } else if (offer.type.name === "special-offers") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_SPECIAL_OFFERS_EMAIL_CLICK;
    } else if (offer.type.name === "commission-offers") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_COMMISSION_OFFERS_EMAIL_CLICK;
    }
    if (venues.length > 0) {
      venues.forEach(venue => {
        this.props.venueInteraction({
          type: interactionType,
          id: venue.id,
          data_score: venue.data_score
        });
      });
    } else if (venue) {
      this.props.venueInteraction({
        type: interactionType,
        id: venue.id,
        data_score: venue.data_score
      });
    }
  };

  showPhone = () => {
    this.setState({ showPhone: true });
    const offer = offerById(this.props.offers, this.props.match.params.offerId);
    let venues = offer.venues;
    let venue = venues.length === 1 ? venues[0] : null;
    let interactionType;
    if (offer.type.name === "news") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_NEWS_PHONE_CLICK;
    } else if (offer.type.name === "offers") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_OFFERS_PHONE_CLICK;
    } else if (offer.type.name === "renovation") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_RENOVATION_PHONE_CLICK;
    } else if (offer.type.name === "famtrip") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_FAMTRIP_PHONE_CLICK;
    } else if (offer.type.name === "special-offers") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_SPECIAL_OFFERS_PHONE_CLICK;
    } else if (offer.type.name === "commission-offers") {
      interactionType = VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_COMMISSION_OFFERS_PHONE_CLICK;
    }
    if (venues.length > 0) {
      venues.forEach(venue => {
        this.props.venueInteraction({
          type: interactionType,
          id: venue.id,
        });
      });
    } else if (venue) {
      this.props.venueInteraction({
        type: interactionType,
        id: venue.id,
      });
    }
  };

  componentDidMount() {
    if (this.props.match.params.offerId) {

      this.loadOffer(this.props.match.params.offerId);
    } else {
      this.loadArticles();
    }
  }

  render() {
    const { offers, match, loaded } = this.props;
    const { offerId } = match.params;
    if (!loaded) {
      return (
        <div
          className="container flex flex-col justify-center"
          style={{
            minHeight: "50vh",
          }}
        >
          <Loading large />
        </div>
      );
    }
    const offer = offerById(offers, offerId);

    if (!offer) {
      return (
        <div className="container bg-white p-6 text-center">
          <p>Not Found</p>
        </div>
      );
    }

    const offerStyle = offerStyles[offer.type.name];

    const Icon = ({ name }) => (
      <i
        className={`fas ${name} mr-2`}
        style={{ color: `rgb(${offerStyle.color})` }}
      />
    );

    const venue = offer.venues.length === 1 ? offer.venues[0] : undefined;

    const headerImage = getVenueImageFromArticle(offer);
    const headerBackground = headerImage
      ? `url(${headerImage.url.replace("localapi", "api").replace("stage-api", "api")}?raw=1) no-repeat center center / cover`
      : "linear-gradient(100deg, #284044 0%,#6c777c 52%,#d14969 100%)";
    const articleImage = getArticleImage(offer);

    const detailItemStyle = "w-full mb-4";

    return (
      <React.Fragment>
        <div
          className="container h-72 p-0 relative overflow-hidden"
          style={{
            background: headerBackground,
          }}
        >
          <div
            className="h-full w-64 absolute "
            style={{
              backgroundColor: `rgba(${offerStyle.color},0.8)`,
              transform: "skew(-15deg)",
              left: "-45px",
              zIndex: 0,
            }}
          />

          <div className="absolute w-full">
            <h1 className="text-white uppercase">{offerStyle.title}</h1>
          </div>

          <div className="absolute w-full pin-b pin-r m-3">
            <h2
              className="text-white text-2xl float-right"
              style={{
                textShadow: "2px 2px 4px rgba(0,0,0,0.8)",
              }}
            >
              {venue && (
                <Link className="text-white" to={`/venue/${venue.slug}`}>
                  {venue.name}
                </Link>
              )}
            </h2>
          </div>
        </div>

        <div className="container">
          <div
            className="bg-white m-1 mt-4 mb-4 p-3"
            style={{
              minHeight: "50vh",
            }}
          >
            {venue && (
              <Link
                className="float-right inline-block tracking-loose font-semibold font-heading px-4 border uppercase no-underline text-center mb-5 text-white border-brand bg-brand"
                to={`/venue/${venue.slug}`}
                onClick={() => {
                  this.props.venueInteraction({
                    type: "venue_content_" + offer.type.name.replace("-", "_") + "_profile_click",
                    id: venue.id,
                  });
                }}
              >
                Go to Venue
              </Link>
            )}
            <Breadcrumbs
              items={[
                { name: "Home", to: "/" },
                { name: offerStyle.name || "Offer" },
              ]}
            />

            <header className="mb-5">
              <h1 className="text-3x1">{offer.title}</h1>
            </header>
            <div
              className="wysiwyg-content"
              dangerouslySetInnerHTML={{
                __html: tidyWysiwygContent(offer.content),
              }}
            />

            <div className="flex flex-wrap my-6">
              <div className="w-full md:w-1/2 px-4">
                {offer.valid_from && (
                  <div className={detailItemStyle}>
                    <Icon name="fa-calendar-alt" />
                    <span className="text-bold">
                      Valid: {formatDate(offer.valid_from)} -{" "} {formatDate(offer.valid_to)}
                    </span>
                  </div>
                )}

                {offer.valid_booking_from && (
                  <div className={detailItemStyle}>
                    <Icon name="fa-calendar-alt" />
                    <span className="text-bold">
                      Booking: {formatDate(offer.valid_booking_from)} -{" "} {formatDate(offer.valid_booking_to)}
                    </span>
                  </div>
                )}

                {offer.files && !!offer.files.length && offer.files.map(file => (
                  <div className={detailItemStyle} key={file.id}>
                    <a href={file.url}>
                      <Icon name="fa-arrow-alt-circle-down" />
                      <span className="text-bold">{file.name}</span>
                    </a>
                  </div>
                ))}

                {offer.email.length && (
                  <div className={detailItemStyle}>
                    {this.state.showEmail ? (
                      <span>
                        <Icon name="fa-envelope" />{" "}
                        <a href={`mailto:${offer.email}`}>{offer.email}</a>
                      </span>
                    ) : (
                      <span className="cursor-pointer" onClick={this.showEmail}>
                        <Icon name="fa-envelope" />
                        Email
                      </span>
                    )}
                  </div>
                )}

                {offer.phone_number && offer.phone_number.length && (
                  <div className={detailItemStyle}>
                    {this.state.showPhone ? (
                      <span>
                        <Icon name="fa-phone" />{" "}
                        <a href={`tel:${offer.phone_number}`}>
                          {offer.phone_number}
                        </a>
                      </span>
                    ) : (
                      <span className="cursor-pointer" onClick={this.showPhone}>
                        <Icon name="fa-phone" /> Call
                      </span>
                    )}
                  </div>
                )}

                <div className={detailItemStyle}>
                  <Icon name="fa-user" /> Posted: {offer.author} {formatDate(offer.created_at)}
                </div>
                {offer.terms_and_conditions && (
                  <div className={detailItemStyle}>
                    <Icon name="fa-calendar-check" />
                    {offer.terms_and_conditions}
                  </div>
                )}
              </div>
              <div className="w-full md:w-1/2 p-2">
                <img src={`${articleImage.url.replace("localapi", "api").replace("stage-api", "api")}?raw=1`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    offers: state.articles.offers,
    loading: state.articles.loading,
    loaded: state.articles.loaded,
  }),
  { loadingArticles, loadedArticles, venueInteraction },
)(ViewOffer);
