import React, {useEffect, useState} from 'react';
import Table from '../../../../Components/Table';
import {format} from 'date-fns';
import {ButtonPrimary} from "../../../../Components/Button";
import {Link} from "react-router-dom";

const mapEnquiryToTemplate = enquiry => {
  if (enquiry) {
    let venueIds = [];
    enquiry.enquiry_venue_datas && enquiry.enquiry_venue_datas.map(vd => {
      venueIds.push(vd.venue_id)
    });
    return {
      enquiry: {
        event_name: enquiry.event_name + " (New)",
        number_of_delegates: enquiry.number_of_delegates,
        room_style_id: enquiry.room_style_id,
        bedrooms_required: enquiry.bedrooms_required,
        back_projection: enquiry.back_projection,
        syndicate_room: enquiry.syndicate_room,
        syndicate_room_notes: enquiry.syndicate_room_notes,
        exhibition_space: enquiry.exhibition_space,
        exhibition_space_notes: enquiry.exhibition_space_notes,
        private_dining: enquiry.private_dining,
        private_dining_notes: enquiry.private_dining_notes,
        any_equipment: enquiry.any_equipment,
        any_equipment_notes: enquiry.any_equipment_notes,
        event_timings: enquiry.event_timings,
        additional_notes: enquiry.additional_notes,
        department_id: enquiry.department_id
      },
      venueIds: venueIds,
    }
  }
  return {}
}

const renderButtons = bookedEvent => {
  return (
    <>
      <ButtonPrimary
        classes={"mx-2 w-30 text-xs"}
        Element={Link}
        to={{
          pathname: '/corporate/request-for-proposal',
          state: {template: mapEnquiryToTemplate(bookedEvent?.enquiry)}
        }}
      >
        New Enquiry from Template
      </ButtonPrimary>
    </>
  )
}

const TemplatesListTable = (props) => {
  const {bookedEvents = []} = props;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(getBookedEventsData());
  }, []);

  const getBookedEventsData = () => {
    return bookedEvents.map(bookedEvent => {
      if (bookedEvent.data) {
        let confirmedData = bookedEvent.data.enquiry_venue_datas;
        let enquiry = bookedEvent.data.enquiry;
        return {
          event_date: () => (
            <span className="text-xs">
              {format(bookedEvent.data.event_date_from, 'DD/MM/YYYY')} - {format(bookedEvent.data.event_date_to, 'DD/MM/YYYY')}
            </span>
          ),
          id: () => (
            <span className="font-bold">
              {enquiry && enquiry.client_company ? enquiry.client_company.code : ''}{enquiry.id}
            </span>
          ),
          event_name: () => (
            <span>
              {enquiry.event_name}
            </span>
          ),
          status: () => (
            <>
              {props.isCompanyAccount && bookedEvent.data.event_status && parseInt(bookedEvent.data.event_status.code) === 30 ?
                <span className="p-1 bg-blue-light text-xs">
                  Completed
                </span>
                :
                <span className="p-1 bg-blue-light text-xs">
                  {bookedEvent.data.event_status && bookedEvent.data.event_status.name}
                </span>
              }
            </>
          ),
          manage: (
            <div className="flex">
              {renderButtons(bookedEvent, confirmedData, props.canManageEventPreData, props.canManageEventCompletion, (props.isCompanyAdmin || props.isCompanyUser), props.canChaseEvent, props.token, props.openModal, props.closeModal)}
            </div>
          ),
          download: (
            <div className="flex">
              {renderButtons(bookedEvent.data)}
            </div>
          ),
        }
      } else {
        return {}
      }
    })
  }

  return (
    <Table
      admin
      headers={tableHeaders}
      data={tableData}
    />
  )
}

const tableHeaders = [
  {
    key: 'id',
    display: 'ID',
  },
  {
    key: 'event_date',
    display: 'Event Date',
  },
  {
    key: 'event_name',
    display: 'Event Title',
  },
  {
    key: 'status',
    display: 'Status',
  },
  {
    key: 'download',
    display: '',
  }
];

export default TemplatesListTable;
