import React, {Fragment, useEffect, useState} from "react";
import Loading from "../../../../Components/Loading";
import {Select} from "../../../../Components/Form";
import {fetchCorporateAccountLocation, unloadCorporateAccountLocation} from "../../../../redux/actions";
import {connect} from 'react-redux';
import {token} from "../../../../modules/auth";
import {Link, useParams} from "react-router-dom";
import FavouriteLocationsMap from "./FavouriteLocationsMap";
import {ButtonPrimary} from "../../../../Components/Button";
import { formatCurrency } from "../../../../utils";
import { GOOGLE_MAPS_API_KEY } from "../../../../constants";

const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`

const CorporateAccountFavouriteLocations = ({
                                              fetchCorporateAccountLocation,
                                              corporateAccountLocation,
                                              unloadCorporateAccountLocation,
                                              locations,
                                              isFetching,
                                              token
                                            }) => {
  const [location, setLocation] = useState(null);
  const {slug} = useParams();

  useEffect(() => {
    if (Object.keys(locations).length > 0) {
      const locationsSorted = locations.data.sort((a, b) => {
        return a.created_at < b.created_at
      })
      let firstLocation = locationsSorted.length > 0 ? locationsSorted[0].id : null;
      setLocation(firstLocation)
    }
  }, [locations])

  useEffect(() => {
    if (location !== null) {
      fetchCorporateAccountLocation(slug, location, token);
    }
    // cleanup
    return () => {
      unloadCorporateAccountLocation()
    }
  }, [location])

  if (isFetching) {
    return <Loading/>
  }

  if (typeof locations === 'undefined' || (locations.data && locations.data.length === 0)) {
    return (
      <div className="p-3 bg-lighter-blue w-full my-6">
        <p className={'mb-0'}><i className="fas text-2lg fa-info-circle mr-3"/>No locations found for this account</p>
      </div>
    )
  }

  const convertMetresToMiles = (metres) => {
    return metres * 0.000621371192;
  }

  const calculateDistance = (latitude, longitude) => {
    if (typeof window.google !== "undefined") {
      let pointOne = new window.google.maps.LatLng(parseFloat(corporateAccountLocation[0].latitude), parseFloat(corporateAccountLocation[0].longitude));
      let pointTwo = new window.google.maps.LatLng(parseFloat(latitude), parseFloat(longitude))
      return convertMetresToMiles(window.google.maps.geometry.spherical.computeDistanceBetween(
        pointOne,
        pointTwo
      )).toFixed()
    }
  }

  const renderLocations = () => {
    if (corporateAccountLocation[0]) {
      return corporateAccountLocation[0].venue_details.map(location => {
        return (
          <tr key={location.id}>
            <td>
              <Link className={'text-black font-bold hover:text-brand'} to={`/venue/${location.venue.slug}`}
                    target="_blank">
                {location.venue.name}
              </Link>
            </td>
            <td>
              <a className={'text-black'} href={`tel:${location.telephone_override}`}>
                {location.telephone_override}
              </a>
            </td>
            <td>{formatCurrency(location['24_hour_rate_override_net'])}</td>
            <td>{formatCurrency(location['24_hour_rack_rate_override_net'])}</td>
            <td>{formatCurrency(location.ddr_rate_override_net)}</td>
            <td>{formatCurrency(location.ddr_rack_rate_override_net)}</td>
            <td>{formatCurrency(location.bed_breakfast_rate_override_net)}</td>
            <td>{formatCurrency(location.bed_breakfast_rack_rate_override_net)}</td>
            <td>{calculateDistance(location.venue.lat, location.venue.long)}</td>
            <td className="flex">
              {location.venue.lat && location.venue.long ? (
                <ButtonPrimary
                  Element="a"
                  classes="mr-2"
                  target="_blank"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                    location.venue.lat + ',' + location.venue.long
                  )}`}
                >
                  Get Directions
                </ButtonPrimary>
              ) : null}
              <ButtonPrimary
                Element={Link}
                to={`/request-for-proposal?venues=${location.venue.id}`}
              >
                Enquire
              </ButtonPrimary>
            </td>
          </tr>
        )
      })
    }
  }

  if (Object.keys(locations).length > 0) {
    return (
      <div className="min-w-full">
        <h1 className="my-4">
          Company Locations
        </h1>
        <div className={'bg-white p-5'}>
          <div className="row">
            <div className="col md:w-1/2">
              <Select
                label="Choose a Location"
                options={locations.data.map(location => ({
                  text: location.name,
                  value: location.id,
                }))}
                meta={{}}
                input={{
                  name: 'location_id',
                  value: location ? location : '',
                  onChange: ({target}) => {
                    setLocation(target.value);
                  },
                }}
              />
            </div>
          </div>
          {corporateAccountLocation.length > 0 && (
            <Fragment>
              <FavouriteLocationsMap
                loadingElement={<div style={{height: `100%`}}/>}
                googleMapURL={GOOGLE_MAP_URL}
                containerElement={<div style={{height: `400px`}}/>}
                mapElement={<div style={{height: `100%`}}/>}
                defaultCenter={{
                  lat: parseFloat(corporateAccountLocation[0].latitude),
                  lng: parseFloat(corporateAccountLocation[0].longitude),
                }}
                locations={corporateAccountLocation[0].venue_details}
              />
              <table className="edge-table px-4">
                <thead>
                <tr>
                  <th colSpan={2}>Venue Name</th>
                  <th>24Hr</th>
                  <th>24Hr Rack</th>
                  <th>DDR</th>
                  <th>DDR Rack</th>
                  <th>BB</th>
                  <th>BB Rack</th>
                  <th>List Miles</th>
                  <th></th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                {renderLocations()}
                </tbody>
              </table>
              <p>*Prices exclude VAT</p>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
  return (
    <>
    </>
  )
}

const mapStateToProps = state => ({
  token: token(state),
  ...state.corporateAccount,
  ...state.corporateAccountLocation
})

const mapDispatchToProps = {
  fetchCorporateAccountLocation,
  unloadCorporateAccountLocation
}


export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountFavouriteLocations)
