function selectMulti(
  result,
  {
    change,
    clearFields,
    formId,
    getText,
    multi,
    multiIdValues,
    multiValues,
    name,
  }
) {
  const resultId = result.id;

  const isAlreadySelected = multiIdValues.includes(resultId);

  if (isAlreadySelected) {
    clearFields(formId, false, false, [name]);
    return;
  }

  change(formId, multi, [...multiIdValues, resultId]);
  change(formId, `${multi}_text`, [...multiValues, getText(result)]);

  // clear search field to allow additional values
  clearFields(formId, false, false, [name]);
}

function selectSingle(result, props) {
  props.change(props.formId, props.name, props.getText(result));

  if (props.setId) {
    props.change(props.formId, `${props.name}_id`, result.id);
  }

  if (props.setRaw) {
    props.change(props.formId, `${props.name}_raw`, result);
  }

  // introduced to allow setting of other fields from the response (first used on countries advanced search - needed the ISO)
  // could have used the custom callback below, but this is easier to use
  if (typeof props.setOther === "string") {
    props.change(props.formId, props.setOther, result[props.setOther]);
  } else if (Array.isArray(props.setOther)) {
    props.setOther.forEach(other => {
      props.change(props.formId, other, result[other]);
    });
  }
}

export default (result, props) => {
  if (props.multi) {
    selectMulti(result, props);
  } else {
    selectSingle(result, props);
  }

  // custom callback
  props.onSelect && props.onSelect(result);
};
