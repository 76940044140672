import {
  VAT_RATES_LIST_REQUEST,
  VAT_RATES_LIST_RECEIVED,
  VAT_RATES_LIST_ERROR
} from "./types";

export const vatRate = (state = {
  vatRates: {},
  isFetchingRates: false,
  error: false,
  errorData: {}
}, action) => {
  switch (action.type) {
    case VAT_RATES_LIST_REQUEST:
      return {
        ...state,
        isFetchingRates: true,
        error: false,
      }
    case VAT_RATES_LIST_RECEIVED:
      return {
        ...state,
        isFetchingRates: false,
        vatRates: action.data ? action.data.data.data : {},
        error: false,
      }
    case VAT_RATES_LIST_ERROR:
      return {
        ...state,
        isFetchingRates: false,
        errorData: action.data.error,
        error: true
      }
    default:
      return state;
  }
}
