import React from "react";
import ResetPasswordForm from "./ResetPasswordForm";

class ResetPassword extends React.Component {
  render() {
    return (
      <div className="container bg-white">
        <h1 className="-ml-3 inline-block text-base relative bg-black text-white uppercase py-5 font-normal pl-5 pr-24 sm:pr-32">
          Reset Password
          <div
            className="absolute pin-r pin-t h-full"
            style={{
              borderLeft: "30px solid transparent",
              borderRight: "30px solid transparent",
              borderBottom: "64px solid white",
              marginRight: "-30px",
            }}
          />
        </h1>
        <div className="-mx-3 md:flex">
          <div className="p-8 xl:p-16 md:w-1/2">
            <ResetPasswordForm />
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
