import React, { useState } from "react";
import VenueSearchField from "./VenueSearchField";
import VenueSearchResults from "./VenueSearchResults";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { token } from "modules/auth";
import { getVenue } from "services/venueService";
import { SearchVenues } from "services/searchVenueService";
import _has from "lodash/has";

let VenueSearch = props => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const searchVenues = values => {
    setloading(true);
    setSearchResults([]);

    SearchVenues(values.venue_search_field, props.token)
      .then(value => {
        setSearchResults(value.data);
      })
      .catch(e => {
        setError(true);
        setErrorMessage("Oops, something went wrong. Please try again.");
      })
      .finally(() => {
        setloading(false);
      });
  };

  const loadVenue = slug => {
    setloading(true);
    setError(false);
    setErrorMessage(undefined);

    getVenue(slug)
      .then(response => {
        if (!_has(response, "result")) {
          throw new Error();
        }
        props.change("selected_venue", { ...response.result });
      })
      .catch(e => {
        props.change("selected_venue", undefined);
        setError(true);
        setErrorMessage(
          "We couldn't load that venue. If this issue persists, please contact EDGE Venues at info@edgevenues.com"
        );
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <>
      <VenueSearchField
        onSubmit={searchVenues}
        loading={loading}
        searchResults={searchResults}
        clear={() => {
          setSearchResults([]);
        }}
      />

      {error && <div className="my-4 text-red">{errorMessage}</div>}

      {searchResults.length > 0 && (
        <div className="overflow-y-scroll mt-3" style={{ maxHeight: "400px" }}>
          <VenueSearchResults
            searchResults={searchResults}
            loadVenue={slug => {
              loadVenue(slug);
              setSearchResults([]);
            }}
          />
        </div>
      )}
    </>
  );
};

VenueSearch = reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
})(VenueSearch);

export default connect(state => ({
  token: token(state),
}))(VenueSearch);
