import React from "react";

const TitledBlock = ({ title, children, className }) => {
  return (
    <div className={className}>
      <h2 className="mb-2">{title}</h2>
      <div className="mb-2">{children}</div>
    </div>
  );
};

export default TitledBlock;
