import moment from "moment";
import orderBy from "lodash/orderBy";

const getNow = () => moment().format("YYYY-MM-DD hh:mm:ss");

const orderList = articles => orderBy(articles, "updated_at", "desc");

export const createApi = ({ axios }) => ({
  loadList: ({ type }) =>
    axios
      .get(`/cms/admin/${type}`)
      .then(({ data }) =>
        Array.isArray(data) ? orderList(data.slice()) : data
      ),

  unapproveItem: ({ item }) =>
    axios
      .patch(`/cms/admin/${item.type.name}/${item.id}`, {
        approved_at: null,
      })
      .then(({ data }) => data),

  approveItem: ({ item }) =>
    axios
      .patch(`/cms/admin/${item.type.name}/${item.id}`, {
        approved_at: getNow(),
      })
      .then(({ data }) => data),
});
