import axios from "axios";
import {token} from "../../auth";

const authenticate = () => (d, getState) => {
    d({
        type: "@@analytics/AUTHENTICATE",
        payload: "fetching",
    });
    axios
        .post(`${process.env.REACT_APP_ANALYTICS}/auth`, {"token": token(getState())})
        .then(response => {
            d({
                type: "@@analytics/AUTHENTICATE",
                payload: response.data.token,
            });
        })
        .catch(() => {
            d({
                type: "@@analytics/AUTHENTICATE",
                payload: "error",
            });
        });
};

export {authenticate};
