import axios from "axios";
import { createHandleAxiosError } from "../../../modules/api-tools";

export default (props, setState) => {
  const { history, location } = props;
  const handleAxiosError = createHandleAxiosError({ history, location });

  setState.loading();

  axios
    .get(
      `${window.API}/venues/${props.match.params.venue}/${props.name.replace(
        "_",
        "-"
      )}s`,
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      }
    )
    .then(response => {
      setState.complete(response.data[`${props.name}s`]);
    })
    .catch(
      handleAxiosError(() => {
        setState.failed();
      })
    );
};
