import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { arrayOf, shape, string, number } from "prop-types";
import {venueInteraction} from "modules/analytics";

import {isAgency, isAgencyApproved} from "../../../modules/auth";
import {
  FaChevronRight,
  FaRegPlusSquare,
  FaRegMinusSquare,
} from "react-icons/fa";
import {VENUE_TAGS} from "../../../modules/analytics/tags";

const agencyTypes = [
  "movers-and-shakers",
  "renovation-news",
  "commission-offers",
  "agency-rates",
  "fam-trips",
];

const filterItems = (items, isAgency) =>
  isAgency
    ? items
    : items.filter(item => !agencyTypes.includes(item.type.name));

const renderNewsItem = ({ id, title, slug, type }, index, array) => (
  <li
    key={id}
    className={`py-5 px-3 ${index < array.length - 1 &&
    "border-b-2 border-light-blue-grey"}`}
  >
    <div
      className={`bg-black inline-block rounded-sm py-1 px-2 uppercase text-white text-sm font-normal mr-2 mb-2`}
    >
      {type.name}
    </div>
    <Link to={`/article/${slug}`} className="text-black flex"
          onClick={() => {
            venueInteraction({
              id: id,
              type: VENUE_TAGS.VENUE_CONTENT_VIEWED.VENUE_CONTENT_NEWS_CLICK,
            });
          }}>
      <div className="w-4/5">
        <h3 className="mb-0">{title}</h3>
      </div>
      <div className="w-1/5 flex justify-end items-center">
        <FaChevronRight className="text-light-blue-grey text-2xl" />
      </div>
    </Link>
  </li>
);

const News = ({ items, isAgency, mobile, isAgencyApproved }) => {
  const _items = filterItems(items, isAgency);
  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded(!expanded);
  };

  if (!_items.length) return null;

  return (
    <div className="bg-white mb-3 shadow" id="news">
      <h2
        className={`mb-2 py-4 ${
          mobile
            ? "px-3 text-left w-full flex items-center justify-between"
            : "text-center"
        } text-xl ${(expanded || !mobile) &&
          "border-b-2 border-light-blue-grey"}`}
        onClick={toggle}
      >
        {(isAgency && isAgencyApproved) ? "Latest Agency " : "Latest "}
        Venue News
        {mobile && !expanded && <FaRegPlusSquare className="text-base" />}
        {mobile && expanded && <FaRegMinusSquare className="text-base" />}
      </h2>
      {(!mobile || (mobile && expanded)) && (
        <ul className="list-reset">{_items.slice(0, 3).map(renderNewsItem)}</ul>
      )}
    </div>
  );
};

News.propTypes = {
  items: arrayOf(
    shape({
      id: number.isRequired,
      title: string.isRequired,
      content: string.isRequired,
      slug: string.isRequired,
    })
  ),
};

export default connect(state => ({
  isAgency: isAgency(state),
  isAgencyApproved: isAgencyApproved(state),
}), {venueInteraction})(News);
