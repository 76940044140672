import React from "react";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import NavButtons from "../Components/NavButtons";
import moment from "moment";
import ImageGrid from "../Components/ImageGrid";

const SummarySection = props => {
  return (
    <>
      <div className="w-full my-4">
        <h3 className="py-2">{props.title}</h3>
        <span className="py-2 block">{props.children}</span>
      </div>
    </>
  );
};

let Summary = props => {
  const { formData } = props;

  return (
    <div className={`bg-white ${props.className}`}>
      <h2 className="flex justify-between mb-2">
        <span>Report Summary</span>
      </h2>

      <form onSubmit={props.handleSubmit}>
        <SummarySection title="Location">
          {formData.visited_type === "venue" && formData.selected_venue.name}
          {formData.visited_type === "destination" &&
            formData.selected_destination.name}
        </SummarySection>

        <SummarySection title="Reason For Visit">
          {formData.reason_for_visit}
        </SummarySection>

        <SummarySection title="Date Visited">
          {moment(formData.visit_date_from).format("MMMM Do YYYY")}
          {formData.visit_date_from !== formData.visit_date_to &&
            ` - ${moment(formData.visit_date_to).format("MMMM Do YYYY")}`}
        </SummarySection>

        {formData.contacts && (
          <SummarySection title="Contacts">
            <div className="flex flex-col lg:flex-row">
              {formData.contacts.map((contact, index) => {
                return (
                  <div className="pr-4 mb-2" key={index}>
                    <span className="block">{contact.name}</span>
                    <span className="block">{contact.position}</span>
                    <span className="block">{contact.telephone}</span>
                    <span className="block">{contact.email}</span>
                  </div>
                );
              })}
            </div>
          </SummarySection>
        )}

        <SummarySection title="Impressions">
          {formData.impressions}
        </SummarySection>

        <SummarySection title="Considerations">
          {formData.considerations}
        </SummarySection>
        {(formData.images || formData.meta_links) && (
          <SummarySection title="Media">
            <div className="flex flex-col lg:flex-row">
              {formData.images && (
                <div className="w-full lg:w-1/2 mb-4">
                  <h4 className="mb-2">Images</h4>

                  <ImageGrid>
                    {formData.images.map(image => {
                      return (
                        <div
                          className="re-16/9 bg-center bg-cover w-64 bg-grey relative"
                          style={{ backgroundImage: `url(${process.env.REACT_APP_API + '/' +image.url})` }}
                        ></div>
                      );
                    })}
                  </ImageGrid>
                </div>
              )}
              {formData.media_links && (
                <div className="w-full lg:w-1/2">
                  <h4 className="mb-2">Video Link</h4>
                  <span className="block font-semibold">
                    {formData.media_links[0].name}
                  </span>
                  <span className="block">{formData.media_links[0].path}</span>
                </div>
              )}
            </div>
          </SummarySection>
        )}

        <SummarySection title="Report Options">
          <span className="block mb-2">
            Show author name:{" "}
            <span className="font-semibold">
              {formData.author_visible ? "Yes" : "No"}
            </span>
          </span>
          <span className="block mb-2">
            Show author image:{" "}
            <span className="font-semibold">
              {formData.author_image_visible ? "Yes" : "No"}
            </span>
          </span>
          <span className="block mb-2">
            Share with company:{" "}
            <span className="font-semibold">
              {formData.share_internally ? "Yes" : "No"}
            </span>
          </span>
        </SummarySection>
        {props.validationError && (
          <div className="mt-6">
            <h3 className="text-warning mb-2">
              Please fix these errors before submitting again.
            </h3>
            {props.errors.map(error => {
              return <span className="text-warning block mb-2">{error}</span>;
            })}
          </div>
        )}
        <NavButtons {...props} />
      </form>
    </div>
  );
};

Summary = reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
})(Summary);

export default connect(state => {
  return {
    formData: getFormValues("CreateSiteVisitForm")(state),
  };
})(Summary);
