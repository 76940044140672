import axios from "axios";

const getRoomStyles = async (_, token) => {
  try {
    const response = await axios.get(`${window.API}/rfp/room-style?showInAdminOnly=true`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.data.data) {
      return {
        result: [],
      };
    }

    return {
      result: response.data.data.map(item => ({
        text: item.name,
        value: item.id,
      })),
    };
  } catch (error) {
    return { error };
  }
};

export { getRoomStyles };
