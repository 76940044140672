import React from "react";
import { connect } from "react-redux";
import { Field, getFormValues } from "redux-form";
import { Radio } from "Components/Form";

class LocationChoices extends React.Component {
  render() {
    return (
      <div>
        <Field
          name="location_type"
          component={Radio}
          label="Search by"
          options={["Map", "Country", "Flight time from the UK" ]}
          onChange={() => {
            this.props.reset();
          }}
        />
      </div>
    );
  }
}

export default connect(state => {
  const v = getFormValues("advanced-search")(state) || {};
  return {
    location_type: v.location_type,
  };
})(LocationChoices);
