import React from "react";
import { LiveSearch } from "Components/Form";

class CountryLookup extends React.Component {
  render() {
    return (
      <div>
        <label className="mb-1 block font-normal font-heading text-sm uppercase">
          Country
        </label>
        <LiveSearch
          formId="advanced-search"
          name="country"
          setId
          url={`${window.API}/search/countries`}
          wrapperClassName="w-64"
          onSelect={() => {
            this.props.search();
          }}
        />
      </div>
    );
  }
}

export default CountryLookup;
//withRouter(
//  connect(
//    undefined,
//    { }
//  )(CountryLookup)
//);
