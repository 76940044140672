import React from 'react';
import SnapshotReportContainer from "../../../pages/admin/corporate/reports/SnapshotReportContainer";

const ReportsDashboardContainer = ({ownReport}) => {
  return (
    <div>
      <SnapshotReportContainer ownReport={ownReport} showFilters={false} dashboardReport={true}/>
    </div>
  )
}

export default ReportsDashboardContainer
