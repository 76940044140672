import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { withRouter } from "react-router-dom";
import { audienceByDay, audienceMetaByType } from "../../../modules/analytics";
import LineGraphByDay from "./Components/LineGraphByDay";
import DevicePieChart from "./Components/DevicePieChart";
import IndustryPieChart from "./Components/IndustryPieChart";
import AudienceTownCity from "./Components/AudienceTownCity";
import OtherVenuesViewed from "./Components/OtherVenuesViewed";
import { Tabs, Title, WhiteBox } from "./common";
import { Row, Column } from "../../../Components/grid";
import { TR, RowHeader, Cell } from "./clean-table";
import AgencyViews from "./Components/AgencyViews";
import CorporateViews from "./Components/CorporateViews";
import getDatesArray from "../../../modules/analytics/get-dates-array";
import {createRangeYearToDate} from "./date-utils";
import {venueInteractions} from "../../../modules/analytics/selectors/utils";

const views = [
  "All Visitors",
  "Agency",
  "Corporate",
  "Hotel",
  "Other",
  "Individual",
  "Association/Charity",
];

const Audience = ({ audienceByDay, audienceMetaByType, engagements }) => {
  const [view, setView] = useState("All Visitors");

  const meta = audienceMetaByType[view];

  return (
    <Fragment>
      <Row reverse>
        <Column w="1/3">
          <WhiteBox tall>
            <Title>Audience</Title>

            <table className="mb-2 mt-6">
              <tbody>
                <TR noBoarder>
                  <RowHeader>Visitors</RowHeader>
                  <Cell>{meta.visitors}</Cell>
                </TR>
                <TR>
                  <RowHeader>New Visitors</RowHeader>
                  <Cell>{meta.new_visitors}</Cell>
                </TR>
                <TR>
                  <RowHeader>Sessions</RowHeader>
                  <Cell>{meta.sessions}</Cell>
                </TR>
                <TR>
                  <RowHeader>Sessions per User</RowHeader>
                  <Cell>{meta.sessions_per_user}</Cell>
                </TR>
                <TR>
                  <RowHeader>Page Views</RowHeader>
                  <Cell>{meta.page_views}</Cell>
                </TR>
                <TR>
                  <RowHeader>Pages per Session</RowHeader>
                  <Cell>{meta.pages_per_session}</Cell>
                </TR>
                <TR>
                  <RowHeader>Avg. Session Duration</RowHeader>
                  <Cell>{meta.average_session_duration}</Cell>
                </TR>
                <TR>
                  <RowHeader>Bounce Rate</RowHeader>
                  <Cell>{meta.bounce_rate}</Cell>
                </TR>
              </tbody>
            </table>
          </WhiteBox>
        </Column>
        <Column w="2/3">
          <WhiteBox tall flexCol>
            <Tabs
              items={views}
              selected={view}
              onChange={item => setView(item)}
            />

            <LineGraphByDay height={350} data={audienceByDay[view]} />
          </WhiteBox>
        </Column>
      </Row>
      <Row>
        <Column w="1/2">
          <WhiteBox tall>
            <Title>Top Agencies viewers</Title>
            <AgencyViews engagements={engagements} />
          </WhiteBox>
        </Column>

        <Column w="1/2">
          <WhiteBox tall>
            <Title>Top corporate viewers</Title>
            <CorporateViews engagements={engagements}/>
          </WhiteBox>
        </Column>
      </Row>

      <Row>
        <Column w="1/2">
          <WhiteBox tall>
            <Title>Industry types</Title>
            <IndustryPieChart typeFilter={view} noPanel />
          </WhiteBox>
        </Column>

        <Column w="1/2">
          <WhiteBox tall>
            <Title>What devices are your visitors using?</Title>
            <DevicePieChart />
          </WhiteBox>
        </Column>
      </Row>

      <Row>
        <Column w="1/2">
          <WhiteBox tall>
            <Title>Other venues your visitors viewed?</Title>
            <OtherVenuesViewed />
          </WhiteBox>
        </Column>

        <Column w="1/2">
          <WhiteBox tall>
            <Title>Where does your audience live?</Title>
            <AudienceTownCity />
          </WhiteBox>
        </Column>
      </Row>
    </Fragment>
  );
};

export default withRouter(
  connect(state => {
    const v = getFormValues("analytics")(state) || {};
    let dates = getDatesArray(createRangeYearToDate().start, createRangeYearToDate().end);
    return {
      audienceByDay: audienceByDay(v.venue_id, v.start_date, v.end_date)(state),
      engagements: venueInteractions(v.venue_id)(state).filter(e => dates.includes(e.day)),
      audienceMetaByType: audienceMetaByType(
        v.venue_id,
        v.start_date,
        v.end_date
      )(state),
    };
  })(Audience)
);
