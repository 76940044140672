import {
    calculateCommissionAs,
    calculateCommissionForCalculator,
    calculateCommissionFromUnit, calculatePricePerNightPerPerson, calculateTotalAs,
} from "../../../rfp/Confirmation/Helpers/calculateTotals";
import {
    commissionFormItem,
    getCommissionItemsForStep, getDescriptionValues
} from "./proposalDataGatherer";
import {
    COMMISSION_VALUE, PRICE_TOTAL,
    PRICE_VAT,
} from "../components/FinalCommissionValuesModal";


function createAdditionalCostingsCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat, currency)
{
    let additionalCostingsCommissionData = getCommissionItemsForStep(enquiryVenueData, 10);
    return  enquiryVenueData.proposal_data.additional_items.filter((ac) => ac.is_commissionable).forEach( additionalCosting => {
        let hasSrcCurVals = !!additionalCosting[`price_net_src_cur`];
        let totals =  calculateTotalAs( 'Net',vatRates, 41,  parseFloat(Number(additionalCosting[`price_net${currency && hasSrcCurVals? '_src_cur' : ''}`])).toFixed(2));
        let commissionTotals =  calculateCommissionAs(
            additionalCostingsCommissionData.include_vat,
            vatRates,
            includesVatRate,
            includesVat,
            additionalCostingsCommissionData ? calculateCommissionForCalculator(
                    false,
                    Number(additionalCosting[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]),
                    additionalCosting.vat_rate.rate,
                    additionalCostingsCommissionData.rate,
                    'Net'
                )
                : {total: 0, vat: 0})
        commissionFormItem(
            `${additionalCosting.type_name}`+   getDescriptionValues(
                additionalCosting.quantity,
                additionalCosting.unit_price ? additionalCosting.unit_price : additionalCosting.price_net,
                Number(additionalCosting[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]),
                null,
                currency,
                true
            ),
            null,
            null,
            null,
            Number(additionalCosting[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
            totals.vat ,
            includesVat,
            totals.total,
            additionalCosting.price_vat_rate_id ? additionalCosting.price_vat_rate_id : 41,
            additionalCostingsCommissionData ? additionalCostingsCommissionData.rate : 0,
            'Net',
            commissionTotals,
            'additional_items',
            additionalCosting.id,
            formValues
        );
    });
}

function calculateAdditionalCostingsCommission(ac, enquiryVenueData)
{
    let additionalCostingsCommissionData = getCommissionItemsForStep(enquiryVenueData, 4);


    return calculateCommissionFromUnit(
        additionalCostingsCommissionData.include_vat,
        ac.price_net,
        ac.price_vat,
        20,
        1,
        parseFloat(additionalCostingsCommissionData.rate).toFixed(2),
        additionalCostingsCommissionData.applied_on,
        null,
        0)
}



function buildInitialAdditionalCostingItems(enquiryVenueData, initialCommissionItems)
{
    enquiryVenueData.proposal_data.additional_items.map(ac => {
        if(ac.is_commissionable) {
            initialCommissionItems.push({id: ac.id, type: 'additional_items', disabled: [], readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]})
        }
    })
}

function handleAdditionalCostingItemsFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
{

    if(editedItem.includes('additional')  && editedItem.includes('items'))
    {
        let ac = enquiryVenueData.proposal_data.additional_items.find(ac => parseInt(ac.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'additional_items_' + id,
            id,
            value,
            ac.price_net,
        )

    }
}

export
{
    createAdditionalCostingsCommissionItems,
    buildInitialAdditionalCostingItems,
    handleAdditionalCostingItemsFieldUpdate

};