import axios from "axios";
import { useReducer, useEffect, useState } from "react";

const CancelToken = axios.CancelToken;

const initialState = {
  loading: true,
  error: false,
  data: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH":
      return {
        ...state,
        loading: true,
        error: false,
      };

    case "ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "RESPONSE":
      return {
        loading: false,
        error: false,
        data: action.payload,
      };

    case "PAGED_RESPONSE":
      const curData = state.data !== null ? state.data : [];
      return {
        loading: false,
        error: false,
        data: [...curData, ...action.payload],
      };

    default:
      return state;
  }
};

const defaults = source => ({
  cancelToken: source.token,
});

const useAxios = (options = {}, trigger = true, perPage = false, limit = false ) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, setPage] = useState(perPage  !== false ? 1 : false);


  const getData = async ({ source }) => {
    dispatch({
      type: "FETCH",
    });

    if ( perPage !== false ) {
      if ( !options.params ) {
        options.params = {};
      }
      options.params.offset = perPage * (page-1);
      options.params.limit = perPage;
    }else if ( limit !== false ) {
      if ( !options.params ) {
        options.params = {};
      }
      options.params.limit = limit;
    }

    try {
      const { data } = await axios({
        ...defaults(source),
        ...options,
      });
      if ( perPage !== false && data.length > 0) {

        dispatch({
          type: "PAGED_RESPONSE",
          payload: data,
        });

        setPage(page + 1);
      }else{

        dispatch({
          type: "RESPONSE",
          payload: data,
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) return;

      dispatch({
        type: "ERROR",
        payload: error,
      });
    }
  };

  useEffect(() => {
    const source = CancelToken.source();
    if (trigger) {
      getData({ source });
    }

    return () => source.cancel("Request cancelled");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger,page]);

  return { ...state };
};

export default useAxios;
