import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { venueInteraction } from "modules/analytics";

class CollapsiblePanel extends React.Component {
  state = {
    open: false,
  };

  toggle = () => {
    if (this.props.venueInteraction && !this.state.open) {
      this.props.track(this.props.venueInteraction);
    }
    this.setState({
      open: !this.state.open,
    });
  };

  UNSAFE_componentWillMount() {
    if (this.props.location.hash === `#${this.props.anchor}`) {
      this.setState({
        open: true,
      });
    }
  }

  componentDidUpdate(prev) {
    if (
      prev.location.hash !== `#${this.props.anchor}` &&
      this.props.location.hash === `#${this.props.anchor}`
    ) {
      this.setState({
        open: true,
      });
    }
  }

  render() {
    const sectionClass = this.props.inForm
      ? "mb-6"
      : "bg-white px-3 pt-3 mb-6 text-lg shadow overflow-hidden";
    const h2Class = this.props.inForm
      ? "relative pr-5 mb-3 pointer text-sm font-normal uppercase"
      : "relative pr-5 mb-3 pointer";
    return (
      <React.Fragment>
        {this.props.anchor && (
          <span className="anchor sr-only" id={this.props.anchor}>
            {this.props.title}
          </span>
        )}
        <section className={sectionClass}>
          <h2 onClick={this.toggle} className={h2Class}>
            {this.props.title}
            <i
              className={`${this.props.inForm ? "ml-2" : "absolute"} fal fa-${
                this.state.open ? "minus" : "plus"
              } pin-r pin-t`}
            />
          </h2>
          {this.state.open ? this.props.children : null}
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    undefined,
    { track: venueInteraction }
  )(CollapsiblePanel)
);
