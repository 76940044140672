import React from "react";
import { ButtonBrand } from "../Button";
import { Link } from 'react-router-dom';

export const CompareButton = ({venues = [], onClick, buttonText = "Compare venues", classes = 'w-full mb-4'}) => {
  const hasVenues = venues.length > 0;

  return (
    <Link to={
      hasVenues
        ? `/compare-venues?venues=${venues.map(v => v.id).join(",")}`
        : "#"
    }>
      <ButtonBrand
        disabled={!hasVenues}
        title={
          hasVenues
            ? "Compare selected Venues"
            : "Select one or more Venues to compare"
        }
        classes={classes}
        to={
          hasVenues
            ? `/compare-venues?venues=${venues.map(v => v.id).join(",")}`
            : "#"
        }
        //   onClick={onClick}
        >
        {buttonText}
        </ButtonBrand>
      </Link>
  );
};
