import strip from "strip";

export const capitalizeWord = word => word[0].toUpperCase() + word.slice(1);

export const capitalize = text =>
  text
    .split(/(\s+)/g)
    .map(capitalizeWord)
    .join("");

// spell-checker:disable
const COMMON_REPLACEMENTS = {
  amp: "&",
  copy: "©",
  nbsp: " ",
  hellip: "…",
  pound: "£",
  qoute: '"',
  gt: ">",
  lt: "<",
  "#038": "&",
  "#8220": "“",
  "#8221": "”",
  "#8211": "–",
  "#8217": "’",
  "#8230": "…",
  "#8216": "‘",
};
// spell-checker:enable

export const replaceCommonEncodings = html =>
  html.replace(
    /&(#?\w{2,5});/g,
    (_, match) => COMMON_REPLACEMENTS[match.toLowerCase()] || ""
  );

export const htmlExcerpt = (html, maxLength) => {
  const html2 = replaceCommonEncodings(html);
  let excerpt = strip(html2.replace(/<\/p>/g, "</p> ")).slice(0, maxLength);

  // Remove part word at the end
  const lastSpaceIndex = excerpt.lastIndexOf(" ");

  if (excerpt.length === maxLength && lastSpaceIndex !== -1) {
    return excerpt.slice(0, lastSpaceIndex) + "...";
  }

  return excerpt;
};

export const tidyWysiwygContent = content => {
  return content.replace(/<p(?: class="[^"]+")?><br><\/p>/g, "");
};
