export const ManageFadeOut = (stateItem, setState, resetForm = false, redirect = false, history = null, location = null) => {
    let stateObj  = {}
    stateObj[stateItem] = true
    setState(stateObj)
    setTimeout(function(){
        stateObj[stateItem] = false
        setState(stateObj);
        if(resetForm)
        {
            resetForm();
        }
        if(redirect)
        {
           //redirectFunction();
        }
    }.bind(this),1500);  // wait 5 seconds, then reset to false

}


export const ManageFadeOutRedirection = (stateItem, setState, resetForm = false, history = null, location = null) => {
    let stateObj  = {}
    stateObj[stateItem] = true
    setState(stateObj)
    setTimeout(function(){
        stateObj[stateItem] = false
        setState(stateObj);
        if(resetForm)
        {
            resetForm();
        }
        history.push(location)
    }.bind(this),1500);  // wait 5 seconds, then reset to false

}

