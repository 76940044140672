import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { objectDiff, renderFormInput } from "../../modules/formTools";
import { Select } from "../../ui";
import { ButtonPrimary } from "../Button";
import Loading from "../Loading";
import { Failed, Succeeded } from "../Form";
import { required } from "../../modules/validation";
import isArray from "lodash/isArray";

const mapStateToProps = (state, props) => {
  const { companyBranch } = props;
  let initialValues = {};
  if (companyBranch && Object.entries(companyBranch).length > 0) {
    initialValues = {
      ...companyBranch,
      ...companyBranch.address,
      id: companyBranch.id,
      address_id: companyBranch?.address?.id,
    };
  }
  return {
    initialValues,
  };
};

const v = {
  required: required(),
};

const form = "companyBranchForm";

let CompanyBranchForm = (props) => {
  const [showCreateSuccessMessage, setShowCreateSuccessMessage] = useState(false);
  const [showSuccessEditMessage, setShowSuccessEditMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const {
    companyBranch,
    handleSubmit,
    initialValues,
    dirty,
    token,
    countries = {},
    submitSucceeded,
    submitError,
    submitting,
    onDeactivateBranch,
    showDelete = true
  } = props;

  useEffect(() => {
    if (submitSucceeded && !companyBranch && !submitError) {
      setShowCreateSuccessMessage(true);
      setTimeout(() => {
        setShowCreateSuccessMessage(false);
      }, 2000);
    }
    if (submitSucceeded && !submitError) {
      setShowSuccessEditMessage(true);
      setTimeout(() => {
        setShowSuccessEditMessage(false);
      }, 2000);
    }
    if ((companyBranch && companyBranch.error) || submitError) {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 2000);
    }
  }, [submitSucceeded, companyBranch, submitError]);

  const options = [];
  if (Object.entries(countries).length > 0) {
    countries.sort((a, b) => a.name.localeCompare(b.name));
    countries.map((country) => {
      options.push({
        text: country.name,
        value: country.id,
      });
    });
    options.forEach(function(country, i) {
      if (country.text === "United Kingdom") {
        options.splice(i, 1);
        options.unshift(country);
      }
    });
  }

  const onSubmit = formValues => {
    const formData = new FormData();
    if (dirty && (Object.keys(initialValues).length > 0)) {
      formData.append("_method", "PATCH");
      const data = objectDiff(initialValues, formValues);
      Object.entries(data).forEach(([key, value]) => {
        if (value && !isArray(value)) {
          formData.append(key, value);
        } else {
          if (value && value.length > 0) {
            formData.append(key, JSON.stringify(value));
          }
          if (key == "address2" || key == "address3" || key == 'invoice_email_address') {
            formData.append(key, value);
          }
        }
      });
      return props.onSubmit(formData, token);
    } else {
      Object.entries(formValues).forEach(
        ([key, value]) => value && formData.append(key, value),
      );
      return props.onSubmit(formData, token);
    }
  };
  return (
    <div className="py-4">
      <div className="overflow-hidden border-gray-200">
        <form className="border-none" onSubmit={handleSubmit(onSubmit)}>
          {showDelete ? (
            <div className="row text-right">
              <div className="col col-w-full">
                <ButtonPrimary
                  className="text-right text-warning pointer"
                  onClick={() => onDeactivateBranch(companyBranch.id)}
                >
                  <i className="fas fa-trash-alt ml-3" />
                </ButtonPrimary>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className={"col md:col-w-1/2"}>
              <Field name="name" label="Branch Name" component={renderFormInput} validate={v.required} />
            </div>
            <div className="col md:col-w-1/2">
              <Field name="invoice_email_address" label="Invoice Email Address" component={renderFormInput} />
            </div>
          </div>
          <div className="row">
            <div className={"col col-w-full md:col-w-1/3"}>
              <Field name="address1" label="Address Line 1" component={renderFormInput} validate={v.required} />
            </div>
            <div className={"col col-w-full md:col-w-1/3"}>
              <Field name="address2" label="Address Line 2" component={renderFormInput} />
            </div>
            <div className={"col col-w-full md:col-w-1/3"}>
              <Field name="postcode" label="Postcode" component={renderFormInput} validate={v.required} />
            </div>
          </div>
          <div className="row">
            <div className={"col col-w-full md:col-w-1/3"}>
              <Field name="city" label="City" component={renderFormInput} validate={v.required} />
            </div>
            <div className={"col col-w-full md:col-w-1/3"}>
              <Field name="county" label="County" component={renderFormInput} />
            </div>
            <div className={"col col-w-full md:col-w-1/3"}>
              <Field name="country_id" label="Country" className="min-h-9.5" component={Select} options={options}
                     validate={v.required} />
            </div>
          </div>
          <ButtonPrimary type="submit">
            {submitting ? <Loading inline /> : "Save"}
          </ButtonPrimary>
          {showCreateSuccessMessage ?
            <Succeeded text="Company Branch created successfully" /> : null}
          {showSuccessEditMessage ? <Succeeded /> : null}
          {showErrorMessage ? <Failed leftAlign /> : null}
        </form>
      </div>
    </div>
  );
};

CompanyBranchForm = reduxForm({
  form: form,
  enableReinitialize: true,
  destroyOnUnmount: true,
})(CompanyBranchForm);

export default connect(mapStateToProps, null)(CompanyBranchForm);
