import React from "react";
import ShortlistsDisplay from "Components/Shortlists/Display";

class Shortlists extends React.Component {
  render() {
    return (
      <div className="max-w-sm py-8">
        <h1 className="mb-5">Your Shortlists</h1>
        <ShortlistsDisplay hasIconsInList />
      </div>
    );
  }
}

export default Shortlists;
