import pick from "lodash/pick";


let convertPriceToSourceCurrency = (isSourceCurrency, p, value) => {
    if(isSourceCurrency) {
        return p[value + '_src_cur']
    }
    return p[value];
}


export default (packages, isSourceCurrency = false) =>
  packages.map(p => {
      return{
    ...pick(p, [
      "id",
      "total_number",
      'min_number_of_delegates_for_qualify',
      "includes_accommodation",
      "includes_main_room_hire",
      "includes_flipchart",
      "includes_free_wifi",
      "includes_paper_and_pencils",
      "includes_lcd_projector",
      "includes_screen",
    ]),
    price_per_delegate: p.display_price_including_vat ? Number(convertPriceToSourceCurrency(isSourceCurrency, p, 'price_per_delegate_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, p, 'price_per_delegate_vat')): convertPriceToSourceCurrency(isSourceCurrency, p, 'price_per_delegate_net'),
    package_type: p.package_type_id,
    package_name: p.description,
    num_delegates: p.no_of_delegates,
    min_number_of_delegates_for_qualify: p.min_number_of_delegates_for_qualify,
    num_days: p.no_of_days,
    day: p.applicable_days,
    includes_other: p.other_equipment_notes,
    includes_notes: p.catering_note,
    display_price_including_vat: p.display_price_including_vat,
    price_vat_rate_id: p.price_vat_rate_id,
    value: p.value,
    price_total: p.display_price_including_vat ? Number(convertPriceToSourceCurrency(isSourceCurrency, p, 'price_total_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, p, 'price_total_vat')) : convertPriceToSourceCurrency(isSourceCurrency, p, 'price_total_net'),
    reduced_from_price: p.display_price_including_vat ? Number(convertPriceToSourceCurrency(isSourceCurrency, p, 'price_reduced_from_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, p, 'price_reduced_from_vat')) : convertPriceToSourceCurrency(isSourceCurrency, p, 'price_reduced_from_net'),
    reduced_from_total: p.display_price_including_vat ? Number(convertPriceToSourceCurrency(isSourceCurrency, p, 'price_reduced_from_total_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, p, 'price_reduced_from_total_vat')) : convertPriceToSourceCurrency(isSourceCurrency, p, 'price_reduced_from_total_net'),
    saving: p.price_percentage_saving,
    includes: p.catering_includes.map(ci => ({
      id: ci.id,
      served_with: ci.served_with,
      serving_instructions: ci.serving_instructions,
      room_id: ci.room_id,
      catering_location_id: ci.room_id,
      catering_type_id: ci.catering_type_id,
    })),
  }});
