import React, {useState} from "react";
import DropzoneArea from "../../../../../Components/Form/File/DropzoneArea";
import {renderFormInput} from "../../../../../modules/formTools";
import {Field} from "redux-form";
import {ButtonSpan} from "../../../../../Components/Button";
import moment from 'moment';
import {Select} from "Components/Form";
import input from "../../../../../ui/Input";
import {BOOKED_EVENT_STATUS} from "../../../../../constants";
import {downloadEdgeDocument} from "../../../../../services/downloadEdgeDocument";
import {token} from "../../../../../modules/auth";
import {connect} from "react-redux";

const mapStateToProps = state => {

    return{

        token: token(state)
    }
}

let ConfirmedEventInformation = (
    {
        setDelegateUploadedDocument,
        setContractUploadedDocument,
        contractDocument,
        delegateDocument,
        contractUploadedDocument,
        delegateUploadedDocument,
        deleteFile,
        event,
        delegateToggleType,
        canViewContractUploader,
        token
    }) => {
    const [delegateToggle, setDelegateToggle] = useState(delegateToggleType);
    let uploadContractDocument = (file) => {
        setContractUploadedDocument(file);
    }

    let uploadDelegateDocument = (file) => {
        setDelegateUploadedDocument(file)
    }
    let _deleteFile = (uploadDocument, loadedDocument) =>
    {

        if(typeof uploadDocument !== 'undefined' && uploadDocument && uploadDocument.length > 0)
        {
           setContractUploadedDocument(null);
        }else if (typeof loadedDocument[0] !== 'undefined')
        {

            return deleteFile(loadedDocument[0].id)
        }
    }
    let fetchFileName = (uploadDocument) =>
    {
        if(uploadDocument && typeof uploadDocument !== 'undefined' && typeof uploadDocument[0] !== 'undefined')
        {
            return uploadDocument[0].name
        }

        return null
    }
    return (
            <div className={'row  p-3 mb-5 '}>
                {canViewContractUploader ?
                    <div className="col col-w-1/2 flex  flex-col ">

                        <h3>Venue Contract</h3>
                        <p>Once the event is confirmed, please upload a copy of the contract here</p>
                        {typeof contractDocument !== 'undefined' && contractDocument.length > 0 && event.contract_uploader ?
                            <React.Fragment>
                                <>
                                    <p>Uploaded
                                        By: {event.contract_uploader.first_name + ' ' + event.contract_uploader.surname} ({event.contract_uploader.email})</p>
                                    <p>Uploaded
                                        At: {moment(event.contract_upload_date).format('DD/MM/YYYY hh:mm:ss')}</p>
                                </>
                                <a className={'font-bold'} style={{cursor: "pointer"}}  onClick={() =>
                                    event && event.contract_file && typeof event.contract_file[0] !== 'undefined' ?
                                        downloadEdgeDocument( event.contract_file[0].id, event.contract_file[0].name,  token) : null
                                } >Download Contract File</a>
                            </React.Fragment>
                            : <span className="text-red">No documents have been uploaded</span>
                        }

                        {(contractUploadedDocument) ? (
                            <div className={'flex flex-row'}>
                                <p>{fetchFileName(contractUploadedDocument, contractDocument)}</p>
                                <React.Fragment>
                                    <ButtonSpan
                                        className="pointer text-warning"
                                        onClick={() => _deleteFile(contractUploadedDocument, contractDocument)}
                                    >
                                        <i className="ml-3 fas fa-trash-alt"/>
                                    </ButtonSpan>
                                </React.Fragment>

                            </div>
                        ) : (typeof contractDocument !== 'undefined' && contractDocument.length > 0 && event.contract_uploader ?
                                null :
                                (event.event_status.code >= BOOKED_EVENT_STATUS.COMPLETED ? null : <DropzoneArea
                                    type="document_plus"
                                    minWidth={'200px'}
                                    minHeight={'100px'}
                                    onDrop={uploadContractDocument}
                                    uploading={false}
                                />)
                        )}
                    </div> : null
                }
                <div className="col col-w-1/2 flex flex-col ">
                    <h3>Client Delegates (optional)</h3>

                    <p>In case of emergency you can upload a delegate contact list here, or provide a web link to one. This can be done until the event begins.</p>
                    {event.event_status.code >= BOOKED_EVENT_STATUS.PENDING_INVOICE  ?
                        <p className="text-red">No delegate list has been added</p>
                    :  <Field
                        wrapperClassName="mb-5 mr-5 max-w-full"
                        name={`delegate_upload_option`}
                        label="Delegate List"
                        component={Select}
                        disabled={ event.event_status.code >= BOOKED_EVENT_STATUS.PENDING_INVOICE }
                        placeholder={'Please choose type'}
                        options={[
                            {value: 'document', text: 'Document Upload'},
                            {value: 'link', text: 'Link to website/document'},
                        ]}
                        onChange={e => setDelegateToggle(e.target.value)}
                    />}

                    {delegateToggle === 'document' ?
                         (delegateUploadedDocument) ? (
                            <div className={'flex flex-row'}>
                                <p>{fetchFileName(delegateUploadedDocument)}</p>
                                <React.Fragment>
                                    <ButtonSpan
                                        className="pointer text-warning"
                                        onClick={() => setDelegateUploadedDocument(null)}
                                    >
                                        <i className="ml-3 fas fa-trash-alt" />
                                    </ButtonSpan>
                                </React.Fragment>

                            </div>
                        ) :
                             (event.event_status.code >= BOOKED_EVENT_STATUS.COMPLETED ? null  :    <DropzoneArea
                                 type="document_plus"
                                 minWidth={'200px'}
                                 minHeight={'100px'}
                                 onDrop={uploadDelegateDocument}
                                 uploading={false}
                             />)

                        : null

                    }

                    {delegateToggle === 'link' ?
                        <Field
                            name="delegate_text"
                            label={'Link to Delegates List'}
                            component={input}
                            value={event.delegate_text}
                            readOnly={event.event_status.code >= BOOKED_EVENT_STATUS.PENDING_INVOICE }
                        />
                        : null
                    }

                    {delegateToggle === 'document' ?
                    typeof delegateDocument !== 'undefined' && delegateDocument.length > 0 && event.delegate_list_uploader ?
                        <div>
                            <p>Uploaded By: {event.delegate_list_uploader.first_name+ ' ' + event.delegate_list_uploader.surname} ({event.delegate_list_uploader.email})</p>
                            <p>Uploaded At: {moment(event.delegate_upload_date).format('DD/MM/YYYY hh:mm:ss')}</p>
                            <div className={'flex flex-row'}>
                                <a className={'font-bold'} style={{cursor: "pointer"}}  onClick={() =>
                                    event && event.delegate_file && typeof event.delegate_file[0] !== 'undefined' ?
                                        downloadEdgeDocument( event.delegate_file[0].id, event.delegate_file[0].name,  token) : null
                                } >Download Delegate File</a>
                                <ButtonSpan
                                    className="pointer text-warning"
                                    onClick={() => _deleteFile(delegateUploadedDocument, delegateDocument)}
                                >
                                    <i className="ml-3 fas fa-trash-alt" />
                                </ButtonSpan>
                            </div>
                        </div> : null
                        : null }
                </div>
            </div>
    )

}

export default connect(mapStateToProps, )(ConfirmedEventInformation);