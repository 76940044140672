import { differenceInMonths } from "date-fns";
import React from "react";
import { connect } from "react-redux";
import { Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { getFormValues } from "redux-form";
import colors from "styles/colors";
import { ResizeContainer } from "./ResizeContainer";

const LineGraphByDay = ({ data, reverseX, reverseY, ticks, height = 300 }) =>
  Boolean(data) && (
    <ResizeContainer height={height}>
      {resizeProps => (
        <LineChart data={data} {...resizeProps}>
          <XAxis dataKey="day" reversed={reverseX} ticks={ticks} />
          <YAxis
            dataKey="total"
            type="number"
            domain={["dataMin", "dataMax"]}
            allowDecimals={false}
            interval="preserveStartEnd"
            reversed={reverseY}
          />
          <Tooltip />

          <Line
            dataKey="total"
            stroke={colors.primary}
            strokeWidth={2}
            yAxisId={0}
            dot={false}
          />
        </LineChart>
      )}
    </ResizeContainer>
  );

export default connect((state, props) => {
  const v = getFormValues("analytics")(state) || {};

  const diff = differenceInMonths(v.end_date, v.start_date);
  let ticks;

  if (diff === 2 || diff === 3) {
    ticks = [
      ...new Set(
        props.data
          .map(item => item.day)
          .filter(item => item.includes(" 1st") || item.includes(" 15th"))
      ),
    ];
  } else if (diff > 3) {
    ticks = [
      ...new Set(
        props.data.map(item => item.day).filter(item => item.includes(" 1st"))
      ),
    ];
  }
  return {
    ticks,
  };
})(LineGraphByDay);
