import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import useAxios from "../../../../hooks/useAxios";
import {ApiForm, Failed, LiveSearch, Succeeded} from "../../../../Components/Form";
import {ButtonPrimary} from "../../../../Components/Button";
import Loading from "../../../../Components/Loading";

const AdminVOTW = (props) => {

    const [
        venueOfTheWeek,
        setVenueOfTheWeek,

    ] = useState();

    const {  data: votw } = useAxios({
        url: `${window.API}/venue-of-the-week`,
    });

    useEffect(() => {
        setVenueOfTheWeek(votw)
    }, [votw]);

    const form = () => `v-ft:general-settings`;

    return (
        <div>
            <ApiForm
                form={form()}
                url={`/venue-of-the-week/set`}
                fieldsWithText={[
                    "venueId",
                ]}
                handleResponse={data => {
                    setVenueOfTheWeek(data);
                }}
                render={(submitting, failed, succeeded) => (
                    <React.Fragment>
                        <h3 className="uppercase mb-3">Venue of the Week</h3>
                        <div className="flex flex-wrap flex-col w  mb-5">
                            <p>Current Venue of The Week: {venueOfTheWeek ? venueOfTheWeek.display_name : null}</p>
                            <LiveSearch
                                formId={form()}
                                name="venue_of_the_week"
                                label="Pick Venue Of The Week"
                                auth
                                setId
                                url={`${window.API}/search/venues`}
                                wrapperClassName={'w-1/4'}
                            />
                        </div>
                        <div className="text-right">
                            <ButtonPrimary type="submit">
                                {submitting ? <Loading inline /> : "Save"}
                            </ButtonPrimary>
                        </div>
                        {succeeded ? <Succeeded /> : null}
                        {failed ? <Failed /> : null}
                    </React.Fragment>
                )}
            />

        </div>
    );
}

export default withRouter(AdminVOTW);
