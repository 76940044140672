import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { notes, token, updateNotes } from "modules/auth";
import { ButtonSpan } from "Components/Button";
import axios from "axios";
import { createHandleAxiosError } from "modules/api-tools";

class Notes extends React.Component {
  delete = note => {
    const { history, location } = this.props;
    const handleAxiosError = createHandleAxiosError({ history, location });

    if (window.confirm("Are you sure you want to delete the note")) {
      axios
        .delete(`${window.API}/users/me/notes/${note.venue.slug}`, {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .then(response => {
          this.props.updateNotes(response.data);
        })
        .catch(
          handleAxiosError(() => {
            alert("Sorry there was an error deleting your note");
          })
        );
    }
  };

  render() {
    return (
      <div className="max-w-sm py-8">
        <h1 className="mb-5">Your Notes</h1>
        {!this.props.notes.length ? (
          <p>You don't have any notes</p>
        ) : (
          <ul className="list-reset">
            {this.props.notes.map(item => (
              <li key={item.id} className="block mb-5">
                <Link
                  to={`/venue/${item.venue.slug}`}
                  className="font-normal mr-3"
                >
                  <span className="text-black">{item.venue.name}</span>
                  <i className="ml-1 fal fa-external-link text-brand" />
                </Link>
                <ButtonSpan onClick={() => this.delete(item)}>
                  <i className="fal fa-trash-alt text-warning" />
                </ButtonSpan>
                <p>{item.content}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      notes: notes(state),
      token: token(state),
    }),
    {
      updateNotes,
    }
  )(Notes)
);
