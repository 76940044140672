import convertPriceToSourceCurrencyValue from "../../../../../helpers/convertPriceToSourceCurrencyValue";

const mapEquipmentFromRfp = (rfp, isSourceCurrency) =>
  rfp.equipment.map(item => {
  return {
    equipment_id: item.equipment_id,
    quantity: item.quantity,
    equipment_name: typeof item.equipment_name !== 'undefined' ? item.equipment_name : null,
    room_id: item.room_id,
    cost: item.cost ? item.cost.unit_price ?  Number(item.cost.unit_price) :  ( item.cost.display_price_including_vat ?
        Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, 'price_net')) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, 'price_vat')) :
        convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, 'price_net')) : undefined,
    reduced_from: item.cost ? item.cost.unit_price ?  item.cost.unit_price_reduced_from : ( item.cost.display_price_including_vat ?
        Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, 'price_reduced_from_net')) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, 'price_reduced_from_vat')) :
        convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, 'price_reduced_from_net')) : undefined,
    saving: item.cost ? item.cost.percentage_saving : undefined,
    include_vat: item.cost ? item.cost.display_price_including_vat : undefined,
    vat_rate_id: item.cost ? item.cost.price_vat_rate_id : undefined,
//    value: item.value
  }});

export default mapEquipmentFromRfp;
