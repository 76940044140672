import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { devices } from "../../../../modules/analytics";
import { PieChart } from "./PieChart";

export default connect(state => {
  const v = getFormValues("analytics")(state);
  return {
    data: devices(v.venue_id, v.start_date, v.end_date)(state),
  };
})(PieChart);
