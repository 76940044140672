import React from 'react';
import Modal from 'Components/Modal';
import { ButtonSpan } from 'Components/Button';
import { LabelledText } from 'ui';
import useService from 'hooks/useService';
import {
  getSupplementaryCateringTypes,
  getAdditionalItems,
} from 'services/rfpService';
import { getRoomStyles } from 'services/roomStyleService';
import Additional from './Additional';
import getSaving from './get-saving';
import { currencyFormatter, getCurrencySymbol } from "utils";
import ToggleRevealer from './Components/ToggleRevealer';
import BreakdownTable from './Components/BreakdownTable';
import { getVatRateById } from '../../pages/admin/rfp/Confirmation/Helpers/calculateTotals';
import getSingleSaving from "./get-single-saving";

const CateringPriceBreakdownModal = ({
                                       total = {},
                                       onClose,
                                       rooms,
                                       venueId,
                                       catering,
                                       additional,
                                       attachments,
                                       notes,
                                       vatRates = [],
                                       currency = {currency_code: "GBP"}
                                     }) => {
  const { data: supplementaryCateringTypes } = useService({
    initialData: [],
    service: getSupplementaryCateringTypes,
    trigger: venueId,
    params: venueId,
  });
  const { data: roomStyles } = useService({
    initialData: [],
    service: getRoomStyles,
    trigger: true,
  });
  const { data: additionalItems } = useService({
    initialData: [],
    service: getAdditionalItems,
    trigger: venueId,
    params: venueId,
  });

  if (vatRates.length < 1) {
    return null;
  }
  return (
      <Modal>
        <div className="modal">
          <div
              className="modal-inner"
              style={{
                width: 1200,
                maxWidth: '90%',
              }}
          >
            <ButtonSpan
                className="m-3 absolute pin-t pin-r z-10"
                onClick={onClose}
            >
              <i className="fas fa-times-circle fa-2x ml-3"/>
            </ButtonSpan>
            <div>
              <h2 className="mb-3 text-3xl">Catering</h2>
              <h3 className="mb-3">Supplementary Catering</h3>
              {catering.map(
                  ({
                     id,
                     supplement_type_id,
                     served_with,
                     price_per_person_net,
                     price_per_person_vat,
                     price_vat_rate_id,
                     quantity_required,
                     price_reduced_from_net,
                     price_reduced_from_vat,
                     price_percentage_saving,
                     serving_instructions,
                     meeting_room,
                     location_served
                   }) => (
                      <div className="mb-5 pt-3 border-t border-black" key={id}>
                        <h3 className="mb-3">
                          {
                            (
                                supplementaryCateringTypes.find(
                                    type => type.value === supplement_type_id,
                                ) || {}
                            ).text
                          }
                        </h3>
                        <div className="flex flex-wrap">
                          <LabelledText
                              Wrapper="div"
                              className="mr-8"
                              label="Served with"
                              text={served_with}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8"
                              label="Serving instructions"
                              text={serving_instructions}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8 text-right"
                              label={"Net Unit Price (" + getCurrencySymbol(currency) + ")"}
                              text={currencyFormatter(currency.currency_code).format(price_per_person_net)}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8"
                              label="Quantity"
                              text={quantity_required}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8 text-right"
                              label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                              text={currencyFormatter(currency.currency_code).format(price_reduced_from_net)}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8 text-right"
                              label={"Total (" + getCurrencySymbol(currency) + ")"}
                              text={currencyFormatter(currency.currency_code).format(quantity_required * price_per_person_net)}
                          />
                          {/*<LabelledText*/}
                          {/*  Wrapper="div"*/}
                          {/*  className="mr-8"*/}
                          {/*  label="Includes VAT"*/}
                          {/*  text={price_includes_vat ? "Yes" : "No"}*/}
                          {/*/>*/}
                          <LabelledText
                              Wrapper="div"
                              className="mr-8"
                              label="Cost saving (%)"
                              text={`${price_percentage_saving}%`}
                          />

                            <LabelledText
                                Wrapper="div"
                                className="mr-8"
                                label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                text={getSingleSaving( quantity_required * price_reduced_from_net, quantity_required * price_per_person_net, currency)}
                            />
                        </div>

                        <div className="flex flex-wrap">
                          <ToggleRevealer toggleText="Show Tax Breakdown">
                            <BreakdownTable
                                headings={['Unit Price', 'Reduced From', 'Total']}
                                vatRate={getVatRateById(vatRates, price_vat_rate_id).number}
                                currency={currency}
                                rows={[
                                  [
                                    currencyFormatter(currency.currency_code).format(price_per_person_net),
                                    currencyFormatter(currency.currency_code).format(price_reduced_from_net),
                                    currencyFormatter(currency.currency_code).format(quantity_required * price_per_person_net),
//                          currencyFormatter(currency.currency_code).format(quantity_required * price_reduced_from_net),
                                  ],
                                  [
                                    currencyFormatter(currency.currency_code).format(price_per_person_vat),
                                    currencyFormatter(currency.currency_code).format(price_reduced_from_vat),
                                    currencyFormatter(currency.currency_code).format(quantity_required * price_per_person_vat),
//                          currencyFormatter(currency.currency_code).format(quantity_required * price_reduced_from_vat),
                                  ],

                                  [
                                    currencyFormatter(currency.currency_code).format(Number(price_per_person_net) + Number(price_per_person_vat)),
                                    currencyFormatter(currency.currency_code).format(Number(price_reduced_from_net) + Number(price_reduced_from_vat)),
                                    currencyFormatter(currency.currency_code).format(quantity_required * (Number(price_per_person_net) + Number(price_per_person_vat))),
//                          currencyFormatter(currency.currency_code).format(quantity_required * (Number(price_reduced_from_net) + Number(price_reduced_from_vat))),
                                  ],
                                ]}/>
                          </ToggleRevealer>
                        </div>
                          {meeting_room ?
                          <>
                              <div className="flex flex-wrap mt-3">
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Location served"
                                      text={
                                          meeting_room.location_served
                                      }
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Room style"
                                      text={
                                          (
                                              roomStyles.find(
                                                  style => style.value === meeting_room.room_style_id,
                                              ) || {}
                                          ).text
                                      }
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Capacity"
                                      text={meeting_room.capacity}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Location/floor"
                                      text={meeting_room.location_or_floor}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Aircon"
                                      text={meeting_room.aircon ? 'Yes' : 'No'}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Natural daylight"
                                      text={meeting_room.natural_daylight ? 'Yes' : 'No'}
                                  />
                                  <div className="w-full flex flex-wrap">
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8 text-right"
                                          label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                                          text={currencyFormatter(currency.currency_code).format(
                                              meeting_room.price_reduced_from_total_net,
                                          )}
                                      />
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8 text-right"
                                          label={"Total (" + getCurrencySymbol(currency) + ")"}
                                          text={currencyFormatter(currency.currency_code).format(meeting_room.price_total_net)}
                                      />
                                      {/*<LabelledText*/}
                                      {/*  Wrapper="div"*/}
                                      {/*  className="mr-8"*/}
                                      {/*  label="Includes VAT"*/}
                                      {/*  text={meeting_room.price_includes_vat ? "Yes" : "No"}*/}
                                      {/*/>*/}
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8 text-right"
                                          label="Cost saving (%)"
                                          text={`${meeting_room.price_percentage_saving}%`}
                                      />
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8"
                                          label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                          text={getSingleSaving( meeting_room.price_reduced_from_total_net, meeting_room.price_total_net, currency)}
                                      />
                                  </div>
                                  <ToggleRevealer toggleText="Show Tax Breakdown">
                                      <BreakdownTable
                                          headings={['Reduced From', 'Total']}
                                          vatRate={getVatRateById(vatRates, meeting_room.price_vat_rate_id).number}
                                          currency={currency}
                                          rows={[
                                              [
                                                  currencyFormatter(currency.currency_code).format(meeting_room.price_reduced_from_total_net),
                                                  currencyFormatter(currency.currency_code).format(meeting_room.price_total_net),
                                              ],
                                              [
                                                  currencyFormatter(currency.currency_code).format(meeting_room.price_reduced_from_total_vat),
                                                  currencyFormatter(currency.currency_code).format(meeting_room.price_total_vat),
                                              ],

                                              [
                                                  currencyFormatter(currency.currency_code).format(Number(meeting_room.price_reduced_from_total_net) + Number(meeting_room.price_reduced_from_total_vat)),
                                                  currencyFormatter(currency.currency_code).format(Number(meeting_room.price_total_net) + Number(meeting_room.price_total_vat)),
                                              ],
                                          ]}/>
                                  </ToggleRevealer>
                              </div>
                          </> : null
                          }

                      </div>
                  ),
              )}
              {additional.length > 0 && (
                  <div className="mb-5 pt-3 border-t border-black">
                    <h3 className="mb-3">Additional Costings: not included in totals, guide prices only</h3>
                    <table>
                      <tbody>
                      {additional.map((item, i) => (
                          <tr key={item.id}>
                            <td>
                              <LabelledText
                                  Wrapper="div"
                                  className="mr-8"
                                  label="Item"
                                  hideLabel={!!i}
                                  text={
                                    (
                                        additionalItems.find(
                                            ac =>
                                                ac.value === item.additional_item_type_id,
                                        ) || {}
                                    ).text
                                  }
                              />
                            </td>
                            <td>
                              <LabelledText
                                  Wrapper="div"
                                  className="mr-8 text-right"
                                  label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                                  hideLabel={!!i}
                                  text={currencyFormatter(currency.currency_code).format(item.price_reduced_from_net)}
                              />
                            </td>
                            <td>
                              <LabelledText
                                  Wrapper="div"
                                  className="mr-8 text-right"
                                  label={"Cost (" + getCurrencySymbol(currency) + ")"}
                                  hideLabel={!!i}
                                  text={currencyFormatter(currency.currency_code).format(item.price_net)}
                              />
                            </td>
                            {/*<td>*/}
                            {/*  <LabelledText*/}
                            {/*    Wrapper="div"*/}
                            {/*    className="mr-8"*/}
                            {/*    label="Includes VAT"*/}
                            {/*    hideLabel={!!i}*/}
                            {/*    text={item.includes_vat ? "Yes" : "No"}*/}
                            {/*  />*/}
                            {/*</td>*/}
                            <td>
                              <LabelledText
                                  Wrapper="div"
                                  className="mr-8 text-right"
                                  label="Cost saving (%)"
                                  hideLabel={!!i}
                                  text={`${item.price_percentage_saving}%`}
                              />
                            </td>
                              <td>
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                      hideLabel={!!i}
                                      text={getSingleSaving(item.price_reduced_from_net, item.price_net, currency)}
                                  />
                              </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
              )}
              <div className="mb-5 pt-3 border-t border-black">
                <h3 className="mb-3">Total</h3>
                <div className="flex flex-wrap">
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label={currency.currency_code === 'GBP' ? `Total Including VAT (${getCurrencySymbol(currency)})` : `Total including Tax (${getCurrencySymbol(currency)})`}
                      text={currencyFormatter(currency.currency_code).format(total.total_inc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label={currency.currency_code === 'GBP' ? `Total Excluding VAT (${getCurrencySymbol(currency)})` : `Total Excluding Tax (${getCurrencySymbol(currency)})`}
                      text={currencyFormatter(currency.currency_code).format(total.total_exc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label={"Reduced From (" + getCurrencySymbol(currency) + ")"}
                      text={currencyFormatter((currency.currency_code)).format(total.reduced_from_exc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Cost Saving (%)"
                      text={getSaving(total, currency)}
                  />
                </div>
              </div>
              <Additional attachments={attachments} notes={notes}/>
            </div>
          </div>
        </div>
      </Modal>
  );
};

export default CateringPriceBreakdownModal;
