import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { ButtonPrimary } from "../../../../Components/Button";
import { token } from "../../../../modules/auth";
import { saveCustomFields } from "../../../../services/corporateServices/saveCustomFields";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "prismjs/components/prism-json";
import "prismjs/themes/prism.css";
import "./prism.css";
import Loading from "../../../../Components/Loading";
import Warning from "../../../../Components/Warning";

const mapStateToProps = state => {
  return ({
    token: token(state),
  });
};

const mapDispatchToProps = {};

const CustomFieldContainer = (props) => {
  const { corporateAccount, token } = props;
  const [code, setCode] = useState(corporateAccount.custom_field_questions);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  let save = () => {
    setLoading(true);
    setErrorMessage();
    saveCustomFields({ corporateAccount: corporateAccount.id, json: code }, token).then((response) => {
      setLoading(false);
      if (response && response.custom_fields) {
        setErrorMessage(response.custom_fields);
      } else if (response && response.status && response.status !== 200) {
        setErrorMessage("Something went wrong, please check your data and try again.");
      }
    });
  };

  return (
    <Fragment>
      <h3 className={"mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center uppercase"}>
        Custom Fields
      </h3>
      <p>To edit Custom fields please liaise with Free Thinking who can Inspect to assist.</p>
      <div className={"hidden"}>
        <p>Add custom fields to the brief.</p>
        {errorMessage ? <div className="mb-4">
          <Warning>{errorMessage}</Warning>
        </div> : null}
        <div style={{ height: "25rem", overflow: "auto", backgroundColor: "#efefef" }}>
          <Editor
            value={code ? code : null}
            onValueChange={code => setCode(code)}
            highlight={code => code ? Prism.highlight(code, Prism.languages.json) : null}
            padding={10}
            style={{
              fontFamily: "\"Fira code\", \"Fira Mono\", monospace",
              fontSize: 12,
            }}
          />
        </div>
        <ButtonPrimary classes={"mt-4"} onClick={() => save()}>
          {loading ? (
            <Loading inline />
          ) : (
            "Save"
          )}
        </ButtonPrimary>
        <hr className="my-6" />
      </div>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldContainer);