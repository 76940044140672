import React, {useState} from "react";
import {Field, reduxForm, getFormValues} from "redux-form";
import {Failed, Input} from "Components/Form";
import NavButtons from "../Components/NavButtons";
import {StoreImage} from "services/genericImageService";
import Dropzone from "react-dropzone";
import Loading from "Components/Loading";
import {token} from "modules/auth";
import {connect} from "react-redux";
import _get from "lodash/get";
import {ValidateImage} from "services/genericImageService";
import ImageGrid from "../Components/ImageGrid";

let AddMedia = props => {
    const [uploading, setUploading] = useState(false);
    const [uploadErrors, setUploadErrors] = useState([]);

    const removeImage = id => {
        let currentImages = [...props.formData.images];
        currentImages = currentImages.filter(image => {
            return image.id !== id;
        });

        props.change("images", currentImages);
    };

    const uploadImage = files => {
        let errors = false;

        if (!files.length) return;
        if (_get(props.formData, "images", []).length + files.length > 10) {
            setUploadErrors([
                ...uploadErrors,
                "You can upload a maximum of 10 images.",
            ]);
            return;
        }

        let fileUploads = [];

        // Validate all files before beginning requests
        for (let i = 0; i < files.length; i++) {
            try {
                ValidateImage(files[i], {
                    extensionIn: ["jpg", "jpeg", "png"],
                    maxSize: {
                        size: 10 * 1024 * (100 * 5 /* << MB */ ),
                        message: "5MB",
                    },
                });
            } catch (e) {
                setUploadErrors([...uploadErrors, e]);
                errors = true;
            }
        }

        if (errors) {
            return;
        }

        setUploading(true);

        for (let i = 0; i < files.length; i++) {
            fileUploads.push(StoreImage(files[i], props.token));
        }

        Promise.all(fileUploads)
            .then(values => {
//        console.log(values);
                props.change("images", [
                    ..._get(props.formData, "images", []),
                    ...values.map(response => response.data),
                ]);
            })
            .catch(e => {
//        console.log(e);
            })
            .finally(() => {
                setUploading(false);
            });
    };

    return (
        <div className={`bg-white ${props.className}`}>
            <h2 className="flex justify-between mb-6">
                <span>Add Images</span>
            </h2>
            <form onSubmit={props.handleSubmit}>
                <Dropzone
                    onDrop={uploadImage}
                    className="relative flex justify-center items-center border-2 border-black border-dashed rounded p-5 mb-5 pointer w-72"
                >
                    {uploading ? (
                        <div
                            className="absolute pin-t pin-l w-full h-full"
                            style={{backgroundColor: "rgba(0,0,0,.5)"}}
                        >
                            <div className="absolute-center">
                                <Loading inline/>
                            </div>
                        </div>
                    ) : null}
                    <div>
                        <i className="fas fa-upload mr-3"/>
                        Upload image
                        <br/>
                        <span className="text-xs block mt-3">
              <b>FILE TYPE</b>: jpg/jpeg
            </span>
                        <span className="text-xs block mt-2">
              <b>FILE SIZE</b>: max 5MB
            </span>
                        <span className="text-xs block mt-2">
              <b>IMAGE</b>: min width 1000px, min height 600px
            </span>
                    </div>
                </Dropzone>
                {uploadErrors.map(error => <Failed text={error} leftAlign key={error}/>)}
                {props.formData.images && (
                    <ImageGrid>
                        {props.formData.images.map(image => {
                            return (
                                <div className="" key={image.id}>
                                    <div
                                        className="re-16/9 bg-center bg-cover w-64 bg-grey relative"
                                        style={{backgroundImage: `url(${process.env.REACT_APP_API + '/' + image.url})`}}
                                    >
                    <span
                        role="button"
                        tabIndex="0"
                        className="absolute pin-t pin-r p-1 bg-white"
                        onClick={() => {
                            removeImage(image.id);
                        }}
                    >
                      <i className="text-warning fas fa-trash-alt"></i>
                    </span>
                                    </div>
                                    {/* <span className="absolute">delete</span> */}
                                </div>
                            );
                        })}
                    </ImageGrid>
                )}

                <div className="flex flex-col lg:flex-row mt-8">
                    <Field
                        type={"text"}
                        name="media_links[0].path"
                        label="Youtube Video Link"
                        component={Input}
                        wrapperClassName={"w-full lg:w-1/2 lg:pr-4 mb-4"}
                    />
                    <Field
                        type={"text"}
                        name="media_links[0].name"
                        label="Video Title"
                        component={Input}
                        wrapperClassName={"w-full lg:w-1/2 mb-4"}
                    />
                </div>

                <NavButtons {...props} />
            </form>
        </div>
    );
};

AddMedia = connect(state => ({
    token: token(state),
    formData: getFormValues("CreateSiteVisitForm")(state),
}))(AddMedia);

export default reduxForm({
    form: "CreateSiteVisitForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(AddMedia);
