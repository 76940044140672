import React, {useState, useEffect} from "react";
import {getSiteVisitReports} from "services/siteVisitService";
import SiteVisitPreview from "./SiteVisitPreview";
import {token, user} from "modules/auth";
import {connect} from "react-redux";

import {ButtonBrand} from "Components/Button";
import {getSiteVisitReportPage} from "../../services/siteVisitService/getSiteVisitReports";
import LatestReports from "./Components/LatestReports";

const SiteVisitsHome = ({user, token}) => {
    const [latestReports, setLatestReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if(user){
            getSiteVisitReports(6, token)
                .then(response => {
                    setLatestReports(response.data);
                })
                .catch(e => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }, [token]);



    const renderItems = ({items}) => (
        items.map((report, index) =>
            <SiteVisitPreview {...report} className="bg-white " key={index}/>
        )
    );

    const getPage = ({path, page}) => {
        getSiteVisitReportPage(path, page, token)
            .then(response => {
                setLatestReports(response.data)
            })
            .catch(e => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="bg-off-white pt-2">
            <div className="container mx-auto bg-white text-center">
                <div className="p-3">
                    <h1 className="mt-2 mb-5 text-4xl xl:text-5xl">Site Visit Reports</h1>
                    <p>
                        Site visit reports allow you to gain an insight into a venue or
                        hotel with genuine reports made by agents and clients that have
                        visited.
                    </p>
                    <p>
                        Visit an individual venue page to see any site visit reports that
                        have been made.
                    </p>
                    {/* search area hidden, functionality needs to be built once changes can be made with algolia */}
                    {/* <div
            style={{ backgroundColor: "rgba(0,0,0,.8)" }}
            className="p-5  my-10  text-left"
          >
            <div className="w-full md:w-3/4 lg:w-1/2 mx-auto">
              <h3 className="text-white mb-4">Search site visits by venue</h3>
              <SiteVisitSearchForm />
            </div>
          </div> */}
                </div>
            </div>

            <div className="container mx-auto bg-white my-4 pb-4">
                <div className="p-3 text-center flex flex-col">

                            <h1 className="mt-2 mb-5">Create Your Report</h1>
                            <p>
                                Adding your own site visit reports are a great way to document your
                                visits, for yourself and your team as well as informing other other
                                potential clients (placeholder)
                            </p>
                    <div className="flex justify-center items-center">
                            <ButtonBrand Element="a" href="/admin/site-visit/create">
                                Create a report
                            </ButtonBrand>
                    </div>

                </div>
            </div>

            {user ?
                <LatestReports latestReports={latestReports} getPage={getPage} renderItems={renderItems} error={error} loading={loading}/>
            : null}
        </div>
    );
};

export default connect(state => ({
    token: token(state),
    user: user(state),
}))(SiteVisitsHome);
