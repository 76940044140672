import React, { Component } from "react";
import queryString from "query-string";
import { withAxios } from "../modules/withAxios";
import pipe from "lodash/fp/pipe";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clearBrand, setBrand, awaitingBrand } from "../modules/brand";
import { Helmet } from "react-helmet";

class ReferrerBrandRedirect extends Component {
  loadBranding = ({ code }) => {
    const { axios } = this.props;
    return axios.get(`/refer-code/${code}`).then(({ data }) => data);
  };

  checkQueryString = () => {
    const {
      location,
      history,
      clearBrand,
      setBrand,
      awaitingBrand,
      brand,
      axios: { handleError },
    } = this.props;
    const { referrer: code, ...query } = queryString.parse(location.search);

    if (!code) return;

    const newSearch = queryString.stringify(query);
    const newLocation = {
      ...location,
      search: newSearch ? "?" + newSearch : "",
    };
    history.replace(newLocation);

    if (code === "edge") {
      clearBrand();
      return;
    }

    // If there is no current brand, Hide the Edge Venues logo while loading the brand data
    if (!brand.id) {
      awaitingBrand();
    }

    this.loadBranding({ code })
      .then(brand => setBrand(brand))
      .catch(handleError(() => clearBrand()));
  };

  componentDidMount() {
    this.checkQueryString();
  }

  render() {
    const { brand } = this.props;
    if (brand.company_name) {
      const title = `${brand.company_name} | powered by EDGE Venues`;
      return (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      );
    }

    return null;
  }
}

const wrap = pipe(
  connect(
    status => ({
      brand: status.brand,
    }),
    {
      clearBrand,
      setBrand,
      awaitingBrand,
    }
  ),
  withAxios,
  withRouter
);

export default wrap(ReferrerBrandRedirect);
