import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { ButtonTransparent } from "Components/Button";

export default function FlagAnIssue({link = ''}) {
  return (
    <ButtonTransparent
      Element="a"
      href="mailto:info@edgevenues.com?subject=Report an issue&body=Hi EDGE Team, I'd like to report an issue on your new website:"
      classes="w-full bg-white"
    >
      <FaExclamationTriangle className="mr-4" />
      Feedback
    </ButtonTransparent>
  );
}
