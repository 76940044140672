import axios from "axios";

const markAsRead = async (id, token) => {
  try {
    await axios.post(
      `${window.API}/rfp/mark-as-read`,
      {
        id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { result: true };
  } catch (error) {
    return { error };
  }
};

export { markAsRead };
