import audienceMeta from "./audience-meta";

const OtherOrUnknown = ({ user_type }) =>
  user_type === "Other" || user_type === "Unknown" || user_type === null;

export default (id, start, end) => state => {
  return {
    "All Visitors": audienceMeta(id, start, end)(state),
    Agency: audienceMeta(id, start, end, "Agency")(state),
    Corporate: audienceMeta(id, start, end, "Corporate")(state),
    Hotel: audienceMeta(id, start, end, "Hotel")(state),
    Other: audienceMeta(id, start, end, OtherOrUnknown)(state),
    Individual: audienceMeta(id, start, end, "Individual")(state),
    "Association/Charity": audienceMeta(id, start, end, "Association/Charity")(
      state
    ),
  };
};
