import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { ButtonPrimary } from "./Button";

let LOADED_AT;

export const init = () => {
  LOADED_AT = Date.now();

  Sentry.init({
    dsn: "https://e81dd5a39b6543cc9092c955b538cbc5@sentry.io/1383878",
  });
};

const isChuckLoadError = error => {
  return error && error.message && error.message.startsWith("Loading chunk ");
};

export default class SentryErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    const pageLoadedTime = Date.now() - LOADED_AT;

    // A chuck loading error is likely due to a new deployment and the browser
    // needing to reload to get the updated file name hashes
    //
    // Check the time the page has been loaded to prevent a continual reload
    // loop
    if (isChuckLoadError(error) && pageLoadedTime > 30 * 1000) {
      return window.location.reload(true);
    }

    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div
          className="flex flex-col justify-around"
          style={{
            minHeight: "50vh",
          }}
        >
          <div className="text-center">
            <h1>Very Sorry</h1>
            <p>There has been an error with Edge Venues.</p>
            <div className="my-4">
              <ButtonPrimary onClick={() => Sentry.showReportDialog()}>
                Report feedback
              </ButtonPrimary>
            </div>
            <small>Giving feedback greatly helps us fixing issues.</small>
          </div>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
