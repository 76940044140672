import React, {Fragment} from "react";
import {connect, useDispatch} from 'react-redux';
import {Field, reduxForm, reset} from "redux-form";
import {processFormValuesPost, renderFormInput} from "../../modules/formTools";
import {Select} from "../Form";
import Loading from "../Loading";
import {ButtonPrimary} from "../Button";

const corporateDepartmentForm = 'corporateAccountDepartmentForm';

const mapStateToProps = () => {
  return {
    initialValues: {}
  }
}

const CorporateAccountDepartmentForm = (props) => {
  const {departments} = props;
  const dispatch = useDispatch();
  const onSubmit = formValues => {
    return processFormValuesPost(props, formValues).then(() => {
      dispatch(reset(corporateDepartmentForm))
    })
  }

  const getDepartmentOptions = () => {
    let options = [];
    departments.map(department => {
      if (department.parent === null) {
        return options.push({
          value: department.id,
          text: department.name
        })
      }
    })
    return options;
  }

  return (
    <Fragment>
      <form className="border-none pb-2 pt-4" onSubmit={props.handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col col-w-full md:col-w-1/4">
            <Field
              name="name"
              component={renderFormInput}
              label="Department Name"
            />
          </div>
          {getDepartmentOptions().length > 0 && (
            <div className="col col-w-full md:col-w-1/4">
              <Field
                name="parent_dept_id"
                component={Select}
                label="Parent Department"
                options={getDepartmentOptions()}
              />
            </div>
          )}
          {props.dirty && (
            <ButtonPrimary type="submit" classes={'ml-auto'}>
              {props.submitting ? <Loading inline/> : "Save"}
            </ButtonPrimary>
          )}
        </div>
      </form>
    </Fragment>
  )
}

export default connect(mapStateToProps, null)(reduxForm({
  form: corporateDepartmentForm,
  enableReinitialize: true
})(CorporateAccountDepartmentForm));
