import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {token} from "../../../../modules/auth";
import {
  corporateDepartmentsUnload,
  createCorporateDepartment,
  deactivateDepartment,
  fetchCorporateDepartments
} from "../../../../redux/actions";
import CorporateAccountDepartmentForm from "../../../../Components/corporate/CorporateAccountDepartmentForm";
import {ButtonPrimary} from "../../../../Components/Button";

const mapStateToProps = state => {
  return ({
    corporateAccount: state.corporateAccount.corporateAccount,
    token: token(state),
    ...state.corporateDepartments
  })
}

const mapDispatchToProps = {
  fetchCorporateDepartments,
  corporateDepartmentsUnload,
  deactivateDepartment,
  createCorporateDepartment
}

const DepartmentsContainer = (props) => {
  const {corporateAccount, token, corporateDepartments} = props;
  const [showDepartmentForm, setShowDepartmentForm] = useState(false);

  useEffect(() => {
    if (Object.keys(corporateAccount).length > 0) {
      props.fetchCorporateDepartments(corporateAccount.id, token)
    }
    return () => {
      props.corporateDepartmentsUnload()
    }
  }, [corporateAccount])

  const disableDepartment = (department) => {
    if (department.parent === null) {
      if (window.confirm("Are you sure you want to deactivate " + department.name + " this will deactivate child departments as well?")) {
        return props.deactivateDepartment(corporateAccount.id, department.id, token)
      }
    } else {
      if (window.confirm("Are you sure you want to deactivate " + department.name + "?")) {
        return props.deactivateDepartment(corporateAccount.id, department.id, token)
      }
    }
  }

  const onSubmit = formValues => {
    return props.createCorporateDepartment(corporateAccount.id, formValues, token).then(() => {
      setShowDepartmentForm(false)
    })
  }

  const renderDepartmentsList = () => {
    let levelOneDepartments = [];
    let levelTwoDepartments = [];
    let departments = corporateDepartments.slice()
    departments.forEach(department => {
      if (department.parent === null) {
        levelOneDepartments.push(department)
      } else {
        levelTwoDepartments.push(department);
      }
    })
    return (
      <Fragment>
        {levelOneDepartments.map(department => (
          <div key={department.id}>
            <div className="row">
              {/* Parent Departments */}
              <div className="col col-w-full flex mt-4">
                <h4>{department.name}</h4>
                <i className="ml-2 fas fa-trash text-red cursor-pointer" onClick={() => disableDepartment(department)}/>
              </div>
            </div>
            {/* Child departments */}
            <ul className="list-reset">
              {levelTwoDepartments.map(levelTwoDepartment => (
                (levelTwoDepartment.parent.id === department.id)
                && (
                  <Fragment key={levelTwoDepartment.id}>
                    <li className={'mx-4'}>
                      <span>{levelTwoDepartment.name}</span>
                      <i className="pt-3 ml-2 fas fa-trash text-red cursor-pointer"
                         onClick={() => disableDepartment(levelTwoDepartment)}/>
                    </li>
                  </Fragment>
                )))}
            </ul>
          </div>
        ))}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <h3 className={'mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center uppercase'}>
        Departments
      </h3>
      <p>Define the departments and any sub categories for this account. You can then administer Users
        linked to the Company under this Corporate Account, to choose from these departments as a default selection if
        the User is selected as the meeting host.</p>
      {renderDepartmentsList()}
      {showDepartmentForm && (
        <CorporateAccountDepartmentForm
          form={`corporateAccountDepartmentForm`}
          departments={corporateDepartments}
          onSubmit={onSubmit}
        />
      )}
      <ButtonPrimary classes={'mt-4'} onClick={() => setShowDepartmentForm(!showDepartmentForm)}>
        {!showDepartmentForm ? ("Add another department") : "Cancel"}
      </ButtonPrimary>
      <hr className="my-6"/>

    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsContainer)