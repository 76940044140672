import React from 'react'

const RoundedIcon = ({name, classes, ...rest}) => {
    return (
        <div className={`rounded-50 bg-light-blue-grey flex items-center justify-center ${classes || ''}`} {...rest} style={{width: 24, height: 24}}>
            <img src={`/img/${name}-icon.svg`} alt={name} />
        </div>
    )
}

export default RoundedIcon