import axios from "axios";
import { createHandleAxiosError } from "modules/api-tools";

export default (id, props) => {
  const { history, location } = props;
  const handleAxiosError = createHandleAxiosError({ history, location });

  axios
    .delete(`${props.path}/${id}`, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    })
    .then(response => {
      // nowt for now
    })
    .catch(handleAxiosError());
};
