import React from "react";
import { func, string } from "prop-types";
import classNames from "classnames";

const TableHeaderSortBy = ({ active, display, field, onClick }) => {
  const handleClick = () => {
    onClick({ field, order: active === "DESC" ? "ASC" : "DESC" });
  };

  return (
      <div className="flex items-center">
        {display}
        <i
            className={classNames("fad ml-3 pointer", {
              "text-primary": active,
              "fa-sort": !active,
              "fa-sort-down": active === "DESC",
              "fa-sort-up": active === "ASC",
            })}
            onClick={handleClick}
        ></i>
      </div>
  );
};

TableHeaderSortBy.propTypes = {
  active: string,
  display: string.isRequired,
  field: string.isRequired,
  onClick: func.isRequired,
};

export default TableHeaderSortBy;
