import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getPostDetails, withEdgeInsights } from "../../../modules/cms";
import Loading from "../../../Components/Loading";
import { replaceCommonEncodings } from "../../../modules/string-tools";
import {
  FaChevronRight,
  FaRegPlusSquare,
  FaRegMinusSquare,
} from "react-icons/fa";

const renderItem = (post, index, array) => {
  const { id, title, linkTo, excerpt } = getPostDetails(post);

  return (
    <li
      key={id}
      className={`py-5 px-3 ${index < array.length - 1 &&
        "border-b border-light-blue-grey"}`}
    >
      <Link
        to={{ pathname: linkTo, state: { post } }}
        className="text-black flex"
      >
        <div className="w-4/5">
          <h3 className="mb-0">{replaceCommonEncodings(title)}</h3>
          <p className="text-xs mb-1">{excerpt}</p>
        </div>
        <div className="w-1/5 flex justify-end items-center">
          <FaChevronRight className="text-light-blue-grey text-2xl" />
        </div>
      </Link>
    </li>
  );
};

const EdgeInsights = ({ loading, posts, mobile }) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="bg-white mb-4 shadow text-center" id="insights">
      <h2
        className={`mb-2 py-4 ${
          mobile
            ? "px-3 text-left w-full flex items-center justify-between"
            : "text-center"
        } text-xl ${(expanded || !mobile) &&
          "border-b border-light-blue-grey"}`}
        onClick={toggle}
      >
        EDGE Insights
        {mobile && !expanded && <FaRegPlusSquare className="text-base" />}
        {mobile && expanded && <FaRegMinusSquare className="text-base" />}
      </h2>
      {(!mobile || (mobile && expanded)) &&
        (loading ? (
          <Loading />
        ) : (
          <ul className="list-reset text-left">
            {posts.slice(0, 3).map(renderItem)}
          </ul>
        ))}
    </div>
  );
};

export default withEdgeInsights(EdgeInsights);
