import {
    calculateCommissionAs,
    calculateCommissionForCalculator,
    calculateCommissionFromUnit,
    calculatePricePerNightPerPerson, calculateTotalAs
} from "../../../rfp/Confirmation/Helpers/calculateTotals";
import {
    commissionFormItem,
    getCommissionItemsForStep, getDescriptionValues
} from "./proposalDataGatherer";
import {
    COMMISSION_VALUE,
    PRICE_NET, PRICE_TOTAL,
    PRICE_VAT
} from "../components/FinalCommissionValuesModal";


function createAdditionalRequirementsCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat, currency)
{
    let additionalRequirementsCommissionData = getCommissionItemsForStep(enquiryVenueData, 5);
    return  enquiryVenueData.proposal_data.supplementary_items.forEach( si => {
        let hasSrcCurVals = !!si[`price_per_person_net_src_cur`];
        let totals =  calculateTotalAs('Net', vatRates, si.vat_rate.id,  parseFloat(calculatePricePerNightPerPerson( si.quantity_required, si[`price_per_person_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0,  1)).toFixed(2));

        let commissionTotals =  calculateCommissionAs(
            includesVat,
            vatRates,
            includesVatRate,
            includesVat,
            additionalRequirementsCommissionData ? calculateCommissionForCalculator(
                    false,
                    calculatePricePerNightPerPerson( si.quantity_required, si[`price_per_person_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0,  1),
                    si.vat_rate.rate,
                    additionalRequirementsCommissionData.rate,
                    'Net',
                )
                : {total: 0, vat: 0})

        commissionFormItem(
            `${si.supplement_type.type}` + getDescriptionValues(
                si.quantity_required,
                si[`price_per_person_net${currency && hasSrcCurVals ? '_src_cur' : ''}`],
                calculatePricePerNightPerPerson( si.quantity_required, si[`price_per_person_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0,  1),
                1,
            currency
            ),
            si.quantity_required,
            Number(si[`price_per_person_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]),
            null,
            calculatePricePerNightPerPerson( si.quantity_required, si[`price_per_person_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0,  1),
            totals.vat,
            includesVat,
            totals.total,
            si.price_vat_rate_id ? si.price_vat_rate_id: 41,
            additionalRequirementsCommissionData ? additionalRequirementsCommissionData.rate : 0,
            'Net',
            commissionTotals,
            'additional_requirements',
            si.id,
            formValues
        );
        if(si.meeting_room) {
            totals =  calculateTotalAs( 'Net', vatRates, si.vat_rate.id,  parseFloat(Number(si.meeting_room[`price_total_net${currency && hasSrcCurVals ? '_src_cur' : ''}`])).toFixed(2));
            commissionTotals =  calculateCommissionAs(
                additionalRequirementsCommissionData.include_vat,
                vatRates,
                includesVatRate,
                includesVat,
                additionalRequirementsCommissionData ? calculateCommissionForCalculator(
                        false,
                        parseFloat(Number(si.meeting_room[`price_total_net${currency && hasSrcCurVals ? '_src_cur' : ''}`])).toFixed(2),
                        si.vat_rate.rate,
                        additionalRequirementsCommissionData.rate,
                        'Net',
                    )
                    : {total: 0, vat: 0})
            commissionFormItem(
                `${si.supplement_type.type} - Room Cost` + getDescriptionValues(
                    null,
                    null,
                    Number(si.meeting_room[`price_total_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]),
                    null,
                    currency
                ),
                null,
                null,
                null,
                Number(si.meeting_room[`price_total_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]),
                totals.vat,
                additionalRequirementsCommissionData.include_vat,
                totals.total,
                si.meeting_room.price_vat_rate_id ? si.meeting_room.price_vat_rate_id : 41,
                additionalRequirementsCommissionData ? additionalRequirementsCommissionData.rate : 0,
                'Net',
                commissionTotals,
                'additional_requirements',
                si.id + '_room',
                formValues
            );
        }

    });
}
function calculateAdditionalRequirementCommission(si, enquiryVenueData)
{
    let additionalRequirementsCommissionData = getCommissionItemsForStep(enquiryVenueData, 5);

    return calculateCommissionFromUnit(
        additionalRequirementsCommissionData.include_vat,
        si.price_per_person_net,
        si.price_per_person_vat,
        20,
        si.quantity_required,
        additionalRequirementsCommissionData.rate,
        additionalRequirementsCommissionData.applied_on,
        null,
        0)
}
function calculateAdditionalRequirementRoomCommission(mr, enquiryVenueData)
{
    let additionalRequirementsCommissionData = getCommissionItemsForStep(enquiryVenueData, 5);



    return calculateCommissionFromUnit(
        additionalRequirementsCommissionData.include_vat,
        mr.price_net,
        mr.price_vat,
        20,
        1,
        parseFloat(additionalRequirementsCommissionData.rate).toFixed(2),
        additionalRequirementsCommissionData.applied_on,
        null,
        0
    )
}


function buildInitialAdditionalRequirementItems(enquiryVenueData, initialCommissionItems)
{
    enquiryVenueData.proposal_data.supplementary_items.map(si => {
        initialCommissionItems.push({id: si.id, type: 'additional_requirements', disabled: [], readonly: [PRICE_VAT,COMMISSION_VALUE, PRICE_TOTAL]})
        initialCommissionItems.push({id: si.id + '_room', type: 'additional_requirements', disabled: [], readonly: [PRICE_VAT,COMMISSION_VALUE, PRICE_TOTAL]})
    })
}

function handleAdditionalRequirementFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
{
    if(editedItem.includes('additional') && editedItem.includes('requirements') && !editedItem.includes('room'))
    {
        let si = enquiryVenueData.proposal_data.supplementary_items.find(si => parseInt(si.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'additional_requirements_' + id,
            id,
            value,
            si.price_per_person_net,
            Number(si.vat_rate.rate)
        )
    }

    if(editedItem.includes('additional') && editedItem.includes('requirements') && editedItem.includes('room'))
    {
        let si = enquiryVenueData.proposal_data.supplementary_items.find(si => parseInt(si.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'additional_requirements_' + id + '_room',
            id,
            value,
            si.meeting_room.price_total_net,
            Number(si.meeting_room.vat_rate.rate)
        )
    }

}

export
{
    createAdditionalRequirementsCommissionItems,
    buildInitialAdditionalRequirementItems,
    handleAdditionalRequirementFieldUpdate

};