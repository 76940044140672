import convertPriceToSourceCurrencyValue from "../../../helpers/convertPriceToSourceCurrencyValue";

const mapCommissionFromProposalData = (commissionItems, isSourceCurrency = false) => {
  const sections = [
    { name: "conference_packages", step_id: 2 },
    { name: "meeting_rooms", step_id: 3 },
    { name: "equipments", step_id: 4 },
    { name: "catering", step_id: 5 },
    { name: "accommodation", step_id: 6 },
    { name: "total", step_id: 9 },
  ];

  sections.forEach(section => {
    const commission_item = commissionItems.find(
      item => item.step_id === section.step_id,
    );
    if (commission_item) {
      commission_item.total_commission_gbp = commission_item.total_commission;
      commission_item.total_commission = convertPriceToSourceCurrencyValue(isSourceCurrency, commission_item, "total_commission");
    }
  });

  return commissionItems;
};

export default mapCommissionFromProposalData;
