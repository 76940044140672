import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import {connect} from 'react-redux';
import {ReactComponent as SliderPrev} from "svg/slider-prev.svg";
import {token} from "../../../modules/auth";
import {fetchAirTableData} from "../../../services/airtableService/fetchAirTableData";
import Loading from "../../../Components/Loading";
import Panel from "../../../ui/Panel";
import {CharityAuctionSlide} from "./CharityAuctionSlide";
import {ButtonBrand} from "../../../Components/Button";
import {generalInteraction} from "../../../modules/analytics";

export const AUCTION_RAISE_TARGET = process.env.REACT_APP_AUCTION_RAISE_TARGET ? process.env.REACT_APP_AUCTION_RAISE_TARGET  :  15000;

const mapDispatchToProps = {
    generalInteraction,
};

const mapStateToProps = state => ({
    token: token(state),
});

const PrevArrow = ({onClick}) => {
    return (
        <SliderPrev
            className="absolute-center pointer z-10 hover:text-green transition"
            style={{left: "-25px"}}
            onClick={onClick}
        />
    );
};

const NextArrow = ({onClick}) => {
    return (
        <SliderPrev
            className="absolute-center-y rotate-180-center-y pointer z-10 hover:text-green transition"
            style={{right: "-25px"}}
            onClick={onClick}
        />
    );
};

const settings = {
    draggable: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow/>,
    nextArrow: <NextArrow/>,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                arrows: false,
                dots: false,
                draggable: true,
                autoplay: true
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                arrows: false,
                dots: false,
                draggable: true,
                autoplay: true
            },
        },
        {
            breakpoint: 1200,
            settings: {
                draggable: false,
                autoplay: false
            },
        },
    ],
};


let CharityAuction = (props) => {
    const {generalInteraction} = props;
    let [airTableData, setAirTableData] = useState(props.airTableData)

    useEffect(() => {
        fetchAirTableData(props.token).then((response) => {
            setAirTableData(response)
        })

    }, []);
    if(airTableData === false) {
        return <></>
    }
    if (!airTableData || airTableData.length === 0) {
        return <Loading/>
    }
    let raisedValue =   airTableData.reduce((accumulator, auction) => {
        return  accumulator + (!isNaN(auction.raised) && auction.raised !== null ? parseFloat(auction.raised) : 0.00)
    }, 0.00)
    return (
        <div className="bg-light-off-blue border-b-12" style={{ minHeight : '500px'}}>
            <div className={'container'}>
            <div className="pt-8 pb-16 md:py-16">
                <div className="flex flex-col justify-center items-center pl-3 text-center">
                    <h1 className={'pb-5 text-blue '}>Welcome to the Trinity EDGE Challenge Charity Auction</h1>
                    <p className={'lg:w-2/3'}>
                        The Trinity EDGE Challenge charity auction is an annual fundraiser to support charities. This year, we're auctioning LOTS kindly donated by hotels and venues to raise money for four small charities. The LOTS will be released in batches over the next four weeks so just in time for bidders to bag a bargain gift in time for Christmas!
                        LOTS include everything from luxury weekends away, golf breaks, a signed England rugby shirt, and afternoon teas to canvas art, fine dining, an escape room experience, and more..
                    </p>
                </div>
                <div className="container mt-8 mb-8 lg:mb-6 pb-1">
                    <Slider
                        {...settings}
                        className="auction-carousel flex flex-col "
                        style={{
                            width: "calc(100% + 1.5rem)",
                            marginLeft: "-0.75rem",
                            marginRight: "-0.75rem",
                        }}
                    >
                        {airTableData &&
                            airTableData
                                .filter((auction) => auction.showInCarousel)
                                .sort((auctionA, auctionB) => {
                                    let status = {
                                        'Running': 1,
                                        'Starts Soon': 2,
                                        'Ended': 3,

                                    };
                                    return status[auctionA.status] - status[auctionB.status]
                                })
                                .map(auction => {
                                return (
                                    <CharityAuctionSlide
                                        key={auction.venueId}
                                        auction={auction}
                                        generalInteraction={generalInteraction}
                                    />
                                );
                            })}
                    </Slider>
                </div>
                <div className="container">
                    <Panel additionalClasses={'shadow pt-4 px-4 pb-8'}>
                        <div className={'flex flex-row'}>
                        <div className={'col text-center'}>
                            <h2 className={'font-bold text-xs uppercase'}>Raised</h2>
                            <h3 className={'text-edge-blue md:text-3xl'}>&pound;{
                               raisedValue
                            }</h3>
                        </div>
                        <div className={'col text-center'}>
                            <h2 className={'font-bold text-xs uppercase'}>Target</h2>
                            <h3 className={'text-edge-blue md:text-3xl'}>&pound;{AUCTION_RAISE_TARGET}</h3>
                        </div>
                        </div>
                        <div className={'row my-3 w-full'}>
                            <div className={'col relative w-full'}>

                                <div className={'bg-brand rounded absolute h-6 z-20'} style={{
                                    width: raisedValue < AUCTION_RAISE_TARGET ? parseFloat(raisedValue / AUCTION_RAISE_TARGET).toFixed(2) * 100 + '%' : '100%'
                                }}></div>

                                <div className={'bg-grey-md rounded h-6 absolute w-full'}></div>


                            </div>


                        </div>

                    </Panel>
                    <div className={'flex justify-center'}>
                        <a href={'https://uk.givergy.com/trinityedgechallenge/?controller=lots&category=All%20Lots'} onClick={() => {
                            generalInteraction({type: "general_auction_view_all_click"})
                        }} ><ButtonBrand
                            classes="p-3"
                            style={{height: "auto", lineHeight: 1}}
                        >
                            View all auction lots
                        </ButtonBrand></a>
                    </div>

                </div>
            </div>

        </div>
    </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CharityAuction);