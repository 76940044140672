import React from "react";

const TrapezeHeader = ({ icon, bgColor, title, bigText, textLeft }) => {
  return (
    <div
      className={`px-3 w-3/4 bg-${bgColor || "white"}
         trapeze-header 
         ${bgColor === "dark-red" && "trapeze-header-dark-red"} 
         ${bgColor === "grey-darker" && "trapeze-header-grey-darker"} 
         ${!textLeft && "justify-center"}
         flex items-center`}
    >
      {icon}
      <span
        className={`text-white font-heading font-semibold ${
          bigText ? "text-md" : "text-lg lg:text-sm"
        }`}
      >
        {title}
      </span>
    </div>
  );
};

export default TrapezeHeader;
