import { venues as authVenues } from "../../auth";

export default state => {
  // TESTING
  // return [
  //   {
  //     text: "Trinity Towers",
  //     value: 24189
  //   },
  //   {
  //     text: "RB",
  //     value: 4341
  //   }
  // ];
  const vs = authVenues(state);
  if (vs === "*") {
    return vs;
  } else {
    return vs
      .filter(v => ["paid", "premium"].includes(v.temp_permission))
      .map(v => ({
        text: v.name,
        value: v.id,
      }));
  }
};
