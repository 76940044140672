import axios from 'axios';
import handleErrors from './utils';
import {
  AGENCY_LIST_ERROR,
  AGENCY_LIST_RECEIVED,
  AGENCY_LIST_REQUEST,
  AGENCY_LIST_UNLOAD,
  AGENCY_RECEIVED,
  AGENCY_RECEIVED_ERROR,
  AGENCY_REQUEST,
  AGENCY_ROLES_UPDATE_RECEIVED_REQUEST,
  AGENCY_UNLOAD,
  AGENCY_USER_RECEIVED,
  AGENCY_USER_RECEIVED_ERROR,
  AGENCY_USER_REQUEST,
  CREATE_AGENCY,
  CREATE_AGENCY_ERROR,
  DELETE_AGENCY_USER,
  DELETE_AGENCY_USER_ERROR,
  EDIT_AGENCY,
  EDIT_AGENCY_ERROR,
  EDIT_AGENCY_USER,
  EDIT_AGENCY_USER_ERROR,
  SUPER_AGENCIES_REQUEST,
  SUPER_AGENCIES_RECEIVED_REQUEST,
  SUPER_AGENCIES_RECEIVED_ERROR
} from '../reducers/types';
import * as ROUTES from '../../Router/corporate_routes';
import {rolesUpdateReceivedError, userRolesUpdateRequest} from "./roles";
import {removeEmptySearchParams} from "../../utils";

/** Agencies */
export const agencyCreated = (data) => ({
  type: CREATE_AGENCY,
  data,
});
export const agencyCreatedError = (error) => ({
  type: CREATE_AGENCY_ERROR,
  error,
});
export const agencyListRequest = () => ({
  type: AGENCY_LIST_REQUEST,
});
export const agencyListReceivedRequest = (data) => ({
  type: AGENCY_LIST_RECEIVED,
  data,
});
export const agencyListReceivedError = (error) => ({
  type: AGENCY_LIST_ERROR,
  error,
});
export const agencyRequest = () => ({
  type: AGENCY_REQUEST,
});
export const agencyReceivedRequest = (data) => ({
  type: AGENCY_RECEIVED,
  data,
});
export const agencyReceivedError = (data) => ({
  type: AGENCY_RECEIVED_ERROR,
  data,
});
export const agencyEdit = (data) => ({
  type: EDIT_AGENCY,
  data,
});
export const agencyEditError = (error) => ({
  type: EDIT_AGENCY_ERROR,
  error,
});
export const agencyUserRequest = () => ({
  type: AGENCY_USER_REQUEST,
});
export const agencyUserReceivedRequest = (data) => ({
  type: AGENCY_USER_RECEIVED,
  data,
});
export const agencyUserReceivedError = (data) => ({
  type: AGENCY_USER_RECEIVED_ERROR,
  data,
});
export const agencyUserEdit = (data) => ({
  type: EDIT_AGENCY_USER,
  data,
});
export const agencyUserEditError = (error) => ({
  type: EDIT_AGENCY_USER_ERROR,
  error,
});
export const agencyUserDelete = (data) => ({
  type: DELETE_AGENCY_USER,
  data,
});
export const agencyUserDeleteError = (error) => ({
  type: DELETE_AGENCY_USER_ERROR,
  error,
});
export const agencyUnload = () => ({
  type: AGENCY_UNLOAD
});
export const unloadAgencyList = () => ({
  type: AGENCY_LIST_UNLOAD
});
export const agencyRolesUpdateReceivedRequest = (data) => ({
  type: AGENCY_ROLES_UPDATE_RECEIVED_REQUEST,
  data
});

export const superAgenciesRequest = () => ({
  type: SUPER_AGENCIES_REQUEST
})

export const superAgenciesReceivedRequest = data => ({
  type: SUPER_AGENCIES_RECEIVED_REQUEST,
  data
})

export const superAgenciesReceivedError = error => ({
  type: SUPER_AGENCIES_RECEIVED_ERROR,
  error
})

/** Agencies */
export const fetchAgencies = (token, page = 1, queryParams = {}) => {
  if (!queryParams.page) {
    queryParams.page = page;
  }
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString()
  return (dispatch) => {
    dispatch(agencyListRequest());
    return axios.get(window.API + ROUTES.API.AGENCY.LIST + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(agencyListReceivedRequest(response.data)))
        .catch(error => dispatch(agencyListReceivedError(error)));
  };
};
export const fetchAgency = (id, token) => {
  return (dispatch) => {
    dispatch(agencyRequest());
    return axios.get(window.API + ROUTES.API.AGENCY.SINGLE.replace(':id', id), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(agencyReceivedRequest(response.data)))
        .catch(error => {
          dispatch(agencyReceivedError(error));
        });
  };
};
export const createAgency = (token, formValues) => {
  return (dispatch) => {
    dispatch(agencyRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.LIST, formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(agencyCreated(response)))
        .catch(error => {
          dispatch(agencyCreatedError(error));
          handleErrors(error);
        });
  };
};
export const editAgency = (id, formValues, token) => {
  return (dispatch) => {
    dispatch(agencyRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.SINGLE.replace(':id', id), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(agencyEdit(response)))
        .catch(error => {
          dispatch(agencyEditError(error));
          handleErrors(error);
        });
  };
};
export const editAgencyUser = (email, formValues, token) => {
  return (dispatch) => {
    dispatch(agencyRequest());
    return axios.post(window.API + ROUTES.API.USER.SINGLE.replace(':email', email), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(agencyUserEdit(response));
      window.location.reload();
    })
        .catch(error => dispatch(agencyUserEditError(error)));
  };
};
export const deleteAgencyUser = (email, formValues, token) => {
  return (dispatch) => {
    dispatch(agencyUserRequest());
    return axios.post(window.API + ROUTES.API.USER.SINGLE.replace(':email', email), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(agencyUserDelete(response));
    })
        .catch(error => dispatch(agencyUserDeleteError(error)));
  };
};
export const giveAgencyUserRoles = (formValues, token) => {
  return (dispatch) => {
    dispatch(userRolesUpdateRequest());
    return axios.post(window.API + ROUTES.API.ROLES.UPDATE, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(agencyRolesUpdateReceivedRequest(response.data))
    })
      .catch(error => dispatch(rolesUpdateReceivedError(error)));
  }
}

export const revokeAgencyUserRoles = (formValues, token) => {
  return (dispatch) => {
    dispatch(userRolesUpdateRequest());
    return axios.post(window.API + ROUTES.API.ROLES.REVOKE, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(agencyRolesUpdateReceivedRequest(response.data))
    })
      .catch(error => dispatch(rolesUpdateReceivedError(error)));
  }
}

export const fetchSuperAgencies = token => {
  return (dispatch) => {
    dispatch(superAgenciesRequest());
    return axios.get(window.API + ROUTES.API.AGENCY.SUPER_AGENCY, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(superAgenciesReceivedRequest(response.data)))
      .catch(error => dispatch(superAgenciesReceivedError(error)))
  }
}

export const fetchAgencyBySlug = slug => {
  return (dispatch) => {
    dispatch(agencyRequest())
    return axios.get(window.API + ROUTES.API.AGENCY.SINGLE_BY_SLUG.replace(':slug', slug))
      .then(response => dispatch(agencyReceivedRequest(response.data)))
      .catch(error => {
        dispatch(agencyReceivedError(error));
        dispatch(agencyUnload());
      })
  }
}