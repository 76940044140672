import React from "react";
import {ButtonBrand} from "Components/Button";
import {Link as ScrollLink} from "react-scroll";

const SplitFeature = ({img, linkText, title, text, link}) => {
    return (
        <div className="md:flex mb-16">
            <div
                className="h-32 md:h-auto md:w-1/2 bg-cover bg-center"
                style={{
                    backgroundImage: `url(${img})`,
                }}
            />
            <div className="md:w-1/2 bg-grey p-5 md:p-8">
                <div className="w-2/3">
                    <h2 className="mb-5">{title}</h2>
                    {typeof text === "string" ? (
                        <p className="mb-5">{text}</p>
                    ) : (
                        <div className="mb-5">{text}</div>
                    )}
                    <ScrollLink
                        to={link}
                        smooth
                        offset={-100}
                        className="pointer"
                    >
                        <ButtonBrand classes="mr-3 text-lg">
                            {linkText || "Read more"}
                        </ButtonBrand>
                    </ScrollLink>

                </div>
            </div>
        </div>
    );
};

export default SplitFeature;
