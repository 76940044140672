import React from 'react';
import {reduxForm, reset} from 'redux-form'
import {LiveSearch} from "../Form";
import {useDispatch} from 'react-redux';

const AddVenueEnquiry = ({addVenue, disableAdd}) => {
  const dispatch = useDispatch();
  return (
      <LiveSearch
        id="venue-search"
        formId={'venueSearch'}
        url={`${window.API}/venues/quick`}
        onSelect={result => {
          if (!disableAdd) {
            addVenue(result.id)
          }
          dispatch(reset('venueSearch'))
        }}
        label="Add another venue by name"
        name="venue"
        placeholder="Search by venue name"
        fieldClassName="w-full"
        wrapperClassName="mb-2"
        noBottom
        white
        getText={item => item.display_name}
      />
  )
}


export default reduxForm({
  form: 'venueSearch',
  forceUnregisterOnUnmount: true,
})(AddVenueEnquiry);
