import React, {useEffect} from "react";
import {connect} from 'react-redux';
import Modal from "../Modal";
import {ButtonPrimary, ButtonSpan} from "../Button";
import {fetchEvent, unloadEvent} from "../../redux/actions";
import {token} from "../../modules/auth";
import {upperFirst} from "lodash";
import { isRoleEdgeAdmin } from "../../modules/permissions";

const mapDispatchToProps = {
  fetchEvent,
  unloadEvent
}

const mapStateToProps = (state, props) => {
  return {
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    event: {...props.event},
    token: token(state)
  }
}

const Wrapper = ({children, onClose}) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 600,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3"/>
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

const ViewEventModal = ({onClose, event, token, fetchEvent, countries, isRoleEdgeAdmin, deleteEvent}) => {
  useEffect(() => {
    fetchEvent(event.id, token)
  }, []);

  const onDelete = () => {
    return deleteEvent(event.id, token);
  }

  useEffect(() => {
    return () => {
      unloadEvent()
    }
  })
  const country = countries && countries.filter(country => country.value === event.country_id)
  return (
    <Wrapper onClose={onClose}>
      <div className={`${event.type.toLowerCase()}-event-modal`}>
        <h2>{upperFirst(event.type)} Event Details</h2>
        <div className="mt-3">
          <div className="event-details text-lg my-6">
            <table className="edge-table">
              <tbody className="divide-y divide-gray-200">
              <tr>
                <td>Ref / Title</td>
                <td><strong>{event.ref_id ? `: ${event.ref_id}` : ''} {event.title}</strong></td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{event.description}</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>{event.location}</td>
              </tr>
              <tr>
                <td>Start</td>
                <td>{new Date(event.start).toDateString()}</td>
              </tr>
              <tr>
                <td>End</td>
                <td>{new Date(event.end).toDateString()}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{country[0].text}</td>
              </tr>
              </tbody>
            </table>
          </div>
          {isRoleEdgeAdmin ? (
            <div className="flex flex-auto">
              <ButtonPrimary classes={'bg-dark-red'} onClick={onDelete}>
                <i className="fas fa-trash mr-3"/> Delete Event
              </ButtonPrimary>
            </div>
          ) : null}
        </div>
      </div>
    </Wrapper>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEventModal)