import React, { Fragment } from "react";

let AuthorisationTriggeredBanner = ({ missingEmailError }) => {
  return (
    <Fragment>
      <div className="bg-primary px-3 py-6 my-5 block font-bold text-white">
        <p className={"mb-0"}>Submitting this enquiry for approval will pass to to the events team for review. You will
          receive notifications of the progression of your enquiry shortly.</p>
      </div>
      {missingEmailError ? (
        <div className="bg-red px-3 py-6 my-5 block font-bold text-white">
          <p className={"mb-0"}>The selected corporate account is missing an authorising email. Please set one before
            submitting the enquiry.</p>
        </div>
      ) : null}
    </Fragment>
  );
};

export default AuthorisationTriggeredBanner;