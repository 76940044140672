import React from "react";
import moment from 'moment';
let AuthorisationBanner = ({data}) => {
    if(!data || !data.brief.authorise_id || data.brief.authorised_state === 'Unauthorised' || data.brief.authorising_user == null)
    {
        return null;
    }
    return (
        <div className={'w-full'}>
            <p className={(data.brief.authorised_state === 'Authorised'? 'bg-primary ' : 'bg-warning ') + 'mb-5 text-white p-4'}>This brief was  {data.brief.authorised_state === 'Declined' ? 'declined' : 'approved'} by {data.brief.authorising_user.first_name + ' ' + data.brief.authorising_user.surname} on {moment(data.brief.authorised_at).format("DD/MM/YYYY")}</p>
        </div>

    )
}

export default AuthorisationBanner