import React from "react";
import classNames from "classnames";
import {
  string,
  object,
  arrayOf,
  oneOfType,
  shape,
  number,
  func,
  bool,
} from "prop-types";
import Label from "./Label";
import Error from "./Error";

export default class Select extends React.Component {
  static propTypes = {
    options: arrayOf(
      oneOfType([
        string.isRequired,
        shape({
          text: string.isRequired,
          value: oneOfType([string, number]).isRequired,
        }).isRequired,
      ])
    ),
    input: object.isRequired,
    meta: object.isRequired,
    label: oneOfType([string, object]).isRequired,
    type: string,
    placeholder: string,
    icon: string,
    wrapperClassName: string,
    labelClassName: string,
    selectClassName: string,
    disableStyle: bool,
    right: func,
    white: bool,
    noPlaceholder: bool,
    readOnly: bool,
  };

  render() {
    const {
      meta: { touched, error, warning },
      input,
      options,
      label,
      type,
      placeholder,
      icon,
      wrapperClassName,
      labelClassName,
      selectClassName,
      right,
      white,
      disableStyle,
      noPlaceholder,
      disabled = false,
    } = this.props;
    let style = disableStyle ? {} : {
      height: "37px",
    };
    if (icon) {
      style = {
        ...style,
        backgroundImage: `url(${icon})`,
        paddingLeft: 35,
        backgroundPosition: "5px center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "25px 25px",
      };
    }
    return (
      <div className={wrapperClassName || "mb-5"}>
        <Label name={input.name} className={labelClassName} text={label} />
        <div className="bg-white relative w-full">
          <i className="fal fa-angle-down absolute-center-y pin-r text-2xl mr-2 text-dark-grey" />
          <select
            {...input}
            placeholder={placeholder}
            type={type}
            style={style}
            disabled={disabled}
            className={
              selectClassName ||
              classNames(
                [
                  "pl-2",
                  "pr-8",
                  "py-0",
                  "w-full",
                  "outline-none",
                  "font-normal",
                  "text-sm",
                  "border",
                  "focus:border-primary",
                  "border-black",
                  "rounded-none",
                  "bg-transparent",
                  "relative",
                ],
                {
                  "text-black": !!input.value,
                  "text-light-text": !input.value,
                  "border-r-0": right,
                  "border-grey-md": !white,
                  "border-white": white,
                }
              )
            }
          >
            {!noPlaceholder ? (
              <option className="text-light-text" value="">
                {placeholder || "Please select"}
              </option>
            ) : null}
            {options.map(option => (
              <option
                className="text-black"
                value={typeof option === "string" ? option : option.value}
                key={typeof option === "string" ? option : option.value}
              >
                {typeof option === "string" ? option : option.text}
              </option>
            ))}
          </select>
        </div>
        {right && right()}
        <Error touched={touched} warning={warning} error={error} />
      </div>
    );
  }
}
