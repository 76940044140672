import pick from 'lodash/pick';

let convertPriceToSourceCurrency = (isSourceCurrency, p, value) => {
    if(isSourceCurrency) {
        return p[value + '_src_cur']
    }
    return p[value];
}

const mapRoomsFromRfp = (rooms = [], isSourceCurrency = false) =>
    rooms.map(room => {
      const values = {
        ...pick(room, [
          'id',
          'room_id',
          'location_or_floor',
          'capacity',
          'square_meterage',
          'aircon',
          'natural_daylight',
          'room_style_id',
          'room_purpose_id',
          'min_number_of_delegates_for_qualify',
            'price_per_day_net',
            'price_per_day_vat',
          'price_total_net',
          'price_total_vat',
          'price_vat_rate_id',
          'display_price_including_vat',
            'price_per_day_reduced_from_net',
            'price_per_day_reduced_from_vat',
          'price_reduced_from_total_net',
          'price_reduced_from_total_vat',
            'applicable_days',
            'no_of_days'
        ]),
      };
      values.cost = room.display_price_including_vat ? Number(convertPriceToSourceCurrency(isSourceCurrency, room, 'price_per_day_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, room, 'price_per_day_vat')) : convertPriceToSourceCurrency(isSourceCurrency, room, 'price_per_day_net');
      values.include_vat = room.display_price_including_vat;
      values.reduced_from =room.display_price_including_vat ? Number(convertPriceToSourceCurrency(isSourceCurrency, room, 'price_per_day_reduced_from_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, room, 'price_per_day_reduced_from_vat')) : convertPriceToSourceCurrency(isSourceCurrency, room, 'price_per_day_reduced_from_net');
      values.vat_rate_id = room.price_vat_rate_id;
      values.saving = room.price_percentage_saving;
      values.min_number_of_delegates_for_qualify = room.min_number_of_delegates_for_qualify;
      if (room.overnight_room_data) {
        values.access_time = room.overnight_room_data.access_time;
        values.overnight_hold = room.overnight_room_data.overnight_hold || 1; // server doesnt stick

        values.overnight_hold_include_vat =room.overnight_room_data.display_overnight_price_including_vat;

        values.overnight_hold_cost = room.overnight_room_data.display_overnight_price_including_vat ?
            Number(convertPriceToSourceCurrency(isSourceCurrency, room.overnight_room_data, 'overnight_price_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, room.overnight_room_data, 'overnight_price_vat')) :
            convertPriceToSourceCurrency(isSourceCurrency, room.overnight_room_data, 'overnight_price_net');

        values.overnight_hold_reduced_from = room.overnight_room_data.display_overnight_price_including_vat ?
            Number(convertPriceToSourceCurrency(isSourceCurrency, room.overnight_room_data, 'overnight_price_reduced_from_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, room.overnight_room_data, 'overnight_price_reduced_from_vat')) :
          convertPriceToSourceCurrency(isSourceCurrency, room.overnight_room_data, 'overnight_price_reduced_from_net')

        values.overnight_hold_saving = room.overnight_room_data.overnight_price_percentage_saving;
        values.overnight_hold_vat_rate_id = room.overnight_room_data.overnight_price_vat_rate_id;
      }
      if (room.setup_data) {
        values.setup_access_time = room.setup_data.access_time;
        values.setup = room.setup_data.setup || 1; // server doesnt stick

        values.setup_include_vat =room.setup_data.display_setup_price_including_vat;

        values.setup_cost = room.setup_data.display_setup_price_including_vat ?
            Number( Number(convertPriceToSourceCurrency(isSourceCurrency, room.setup_data, 'setup_price_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, room.setup_data, 'setup_price_vat'))) :
          convertPriceToSourceCurrency(isSourceCurrency, room.setup_data, 'setup_price_net');

        values.setup_reduced_from = room.setup_data.display_setup_price_including_vat ?
            Number(convertPriceToSourceCurrency(isSourceCurrency, room.setup_data, 'setup_price_reduced_from_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, room.setup_data, 'setup_price_reduced_from_vat')) :
            convertPriceToSourceCurrency(isSourceCurrency, room.setup_data, 'setup_price_reduced_from_net');

        values.setup_saving = room.setup_data.setup_price_percentage_saving;
        values.setup_vat_rate_id = room.setup_data.setup_price_vat_rate_id;
      }
      if (room.breakdown_data) {
        values.breakdown_access_time = room.breakdown_data.access_time;
        values.breakdown = room.breakdown_data.breakdown || 1; // server doesnt stick

        values.breakdown_include_vat =room.breakdown_data.display_breakdown_price_including_vat;

        values.breakdown_cost = room.breakdown_data.display_breakdown_price_including_vat ?
            Number(convertPriceToSourceCurrency(isSourceCurrency, room.breakdown_data, 'breakdown_price_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, room.breakdown_data, 'breakdown_price_vat')) :
            convertPriceToSourceCurrency(isSourceCurrency, room.breakdown_data, 'breakdown_price_net');

        values.breakdown_reduced_from = room.breakdown_data.display_breakdown_price_including_vat ?
            Number( convertPriceToSourceCurrency(isSourceCurrency, room.breakdown_data, 'breakdown_price_reduced_from_net')) + Number(convertPriceToSourceCurrency(isSourceCurrency, room.breakdown_data, 'breakdown_price_reduced_from_vat')) :
            convertPriceToSourceCurrency(isSourceCurrency, room.breakdown_data, 'breakdown_price_reduced_from_net');

        values.breakdown_saving = room.breakdown_data.breakdown_price_percentage_saving;
        values.breakdown_vat_rate_id = room.breakdown_data.breakdown_price_vat_rate_id;
      }
      return values;
    });

export default mapRoomsFromRfp;
