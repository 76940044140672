import get from "lodash/fp/get";

// Redux Reducer

const initState = {};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case "CLEAR_BRAND":
      return initState;

    case "SET_BRAND":
      return action.payload;

    // Grab the brand from the user login data
    case "LOGIN":
      return (
        (action.payload && action.payload.user && action.payload.user.brand) ||
        state
      );

    default:
      return state;
  }
};

// Actions

export const clearBrand = () => ({
  type: "CLEAR_BRAND",
});

export const setBrand = brand => ({
  type: "SET_BRAND",
  payload: brand,
});

export const awaitingBrand = () => ({
  type: "SET_BRAND",
  payload: {
    id: "id",
    branding_image_url:
      "data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=",
    company_name: "",
    contact_number: "",
  },
});

// State getters

export const stateToReferCode = get("brand.refer_code");
