import React from "react";
import { components } from "react-select";

export const allOption = {
  label: "Select all",
  value: "*",
};

export const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  const { cacheKey, cache = false } = props.selectProps;
  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }
  let selectedFromStorage = [];
  if (cache) {
    let cachedParams = window.localStorage.getItem(cacheKey) !== null ? window.localStorage.getItem(cacheKey) : "";
    cachedParams.split(",").map(param => {
      selectedFromStorage.push(...props.options.filter(option => {
        if (param !== "") {
          return param == option.value;
        }
      }));
    });
  }
  return (
    <components.ValueContainer {...props}>
      {selectedFromStorage.length || currentValues.length} Value(s) Selected
    </components.ValueContainer>
  );
};

export const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

export const Option = ({ ...props }) => {
  let isSelected = false;
  const { cacheKey, cache = false } = props.selectProps;
  if (cache) {
    let cachedParams = window.localStorage.getItem(cacheKey) !== null ? window.localStorage.getItem(cacheKey) : "";
    cachedParams.split(",").map(param => {
      if ((props.data && param !== "") && props.data.value === Number(param)) {
        isSelected = true;
      }
    });
  }
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={isSelected || props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};