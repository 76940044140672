import React from "react";
import { withRouter } from "react-router-dom";

class FetchError extends React.Component {
  render() {
    return (
      <p className="text-lg mb-5">
        {this.props.error === 502
          ? "We are currently performing an upgrade"
          : "There was a problem fetching the data"}
        , please try{" "}
        <a
          href={this.props.location.pathname + this.props.location.search}
          className="font-normal"
        >
          reloading the page
        </a>
        . If the problem persists, give us a call on +44(0) 1780 484051, or drop
        us an email at{" "}
        <a href="mailto:info@edgevenues.com" className="font-normal">
          info@edgevenues.com
        </a>
      </p>
    );
  }
}

export default withRouter(FetchError);
