export const token = state => {
  if (
    state.analytics.auth &&
    state.analytics.auth !== "error" &&
    state.analytics.auth !== "fetching"
  ) {
    return state.analytics.auth;
  }
  return null;
};

export const authenticating = state => state.analytics.auth === "fetching";
export const error = state => state.analytics.auth === "error";
