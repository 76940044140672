import React, {useEffect, useState} from "react";
import {fetchUserInvite, registerInvitedUser, userInviteUnload} from "../../redux/actions/corporateInvites";
import {token} from "../../modules/auth";
import {connect} from 'react-redux'
import Loading from "../../Components/Loading";
import NotFound from "../NotFound";
import {isLoggedIn} from "../../modules/auth";
import UserInvitedForm from "../../Components/corporate/UserInvitedForm";
import {Redirect, useHistory} from "react-router-dom";
import {refreshUser, refreshUserCompany} from "../../modules/auth-thunks";

const mapStateToProps = (state) => {
    return ({
        ...state.userInvite,
        ...state.isFetchingUserInvite,
        user: state.auth.user,
        token: token(state),
        isLoggedIn: isLoggedIn(state),
    })
}

const mapDispatchToProps = {
    fetchUserInvite,
    userInviteUnload,
    registerInvitedUser,
    refreshUser,
    refreshUserCompany
}

const CompanyLandingPage = ({
                                match,
                                fetchUserInvite,
                                isFetchingUserInvite,
                                userInvite,
                                userInviteUnload,
                                isLoggedIn,
                                registerInvitedUser,
                                user,
                                token,
                                refreshUser,
                                refreshUserCompany
                            }) => {
    const history = useHistory();
    const [loggedIn, setLoggedIn] = useState(false)
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        if (isLoggedIn) {
            setLoggedIn(true)

        }
        fetchUserInvite(match.params.invite_code)
        return () => {
            userInviteUnload();
        }
    }, []);


    if (userInvite === null && isFetchingUserInvite) {
        return <Loading/>
    }
    if (userInvite === null && !isFetchingUserInvite) {
        return (
            <NotFound>
                Sorry, the page your are looking for has not been found.
                Please check the URL and try again.
            </NotFound>
        )
    }

    if(userInvite !== null && !isFetchingUserInvite && userInvite.is_existing_user && !isLoggedIn)
    {
        if(userInvite.is_existing_user) {
            return <Redirect to={{
                pathname: `/login`,
                search: `?return=/invite-company-user/${userInvite.invite_code}`,
                state: {
                    message: "Please login to join."
                }
            }}/>

        }
    }

    if(userInvite !== null && user && userInvite.company.id === user.company_id)
    {
        if(userInvite?.company?.corporate_account) {
            window.location.href = '/corporate/dashboard' ;
        }

        return <Redirect to={{
            pathname: '/admin/welcome',
            search: ``,
            state: {
                message: `You already belong to ${userInvite.company.name}.`
            }
        }}/>
    }




    const onSubmit = formValues => {
        return registerInvitedUser(userInvite.invite_code,userInvite.is_existing_user,token,formValues).then(() => {
            refreshUser();
            refreshUserCompany();
            setSuccess(true);

        })
    }

    if(success)
    {
        if(userInvite.is_existing_user) {
            if(userInvite?.company?.corporate_account) {
               window.location.href = '/corporate/dashboard' ;
            }
            return <Redirect to={{
                pathname: '/admin/welcome',
                search: ``,
                state: {
                    message: `You have successfully joined ${userInvite.company.name}.`
                }
            }}/>


        }else{
            return <Redirect to={{
                pathname: '/login',
                search: ``,
                state: {
                    message: "You have successfully registered. Please login."
                }
            }}/>

        }
    }

    const AdminEDGELogo = userInvite?.company?.corporate_account?.logo_link ? userInvite.company.corporate_account.cdn_path : '/img/CE-logo-alt.png';
    const logoAlt = userInvite?.company?.corporate_account?.account_name ? userInvite.company.corporate_account.account_name + ' logo' : "EDGE Venues logo";
    const corpWrapperClasses = userInvite?.company?.corporate_account?.colour_scheme_classname ? 'invite-bg flex justify-center md:py-16 lg:pt-2' : 'flex justify-center md:py-16 lg:pt-2';
    const corpTitleClasses = userInvite?.company?.corporate_account?.colour_scheme_classname ? 'mt-4 mb-8 md:mb-8 text-2rem text-white' : 'mt-4 mb-8 md:mb-8 text-2rem';
    const isCorporateInvitation = userInvite?.company?.corporate_account;
    const corporateLogoContainerClasses = userInvite?.company?.corporate_account?.logo_link ? 'rounded-100 bg-white overflow-hidden flex justify-center items-center p-2 shadow-md' : 'rounded-100 bg-black overflow-hidden flex justify-center items-center p-2 shadow-md';

    return (
        <div className={corpWrapperClasses}>
            <div className="max-w-full mt-8" style={{width: 900}}>
                <div className="branding text-center flex flex-col items-center">
                    {isCorporateInvitation &&
                        <div className={corporateLogoContainerClasses} style={{width: 125, height: 125}}>
                            <img
                                src={AdminEDGELogo}
                                alt={logoAlt}
                            />
                        </div>
                    }
                    <h2 className={corpTitleClasses}>You are invited to
                        create {isCorporateInvitation ? ' a Corporate EDGE' : ' an'} account
                        with <br/>{userInvite && userInvite.company.name}</h2>
                </div>
                <UserInvitedForm
                    userInvite={userInvite}
                    onSubmit={onSubmit}
                />
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLandingPage)
