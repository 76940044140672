import React from "react";

export default class SuggestVenue extends React.Component {
  render() {
    return (
      <div className="container">
        <p className="text-xl py-8">Content coming soon</p>
      </div>
    );
  }
}
