import React from "react";
import CmsPage from "Components/CmsPage";
import Breadcrumbs from "Components/Breadcrumbs";

export default class Help extends React.Component {
  render() {
    return (
      <div className="container">
        <div
          className="bg-white m-1 mt-4 p-5"
          style={{
            minHeight: "50vh",
          }}
        >
          <Breadcrumbs items={[{ name: "Home", to: "/" }, { name: "Help" }]} />
          <CmsPage id={471} />
        </div>
      </div>
    );
  }
}
