import convertPriceToSourceCurrencyValue from "../../../helpers/convertPriceToSourceCurrencyValue";

export default (packages, isSourceCurrency = true) => {
  return packages.map(p => {
    let price_per_delegate_net_gbp = p.price_per_delegate_net;
    let price_per_delegate_vat_gbp = p.price_per_delegate_vat;
    return {
      ...p,
      price_per_delegate_net_gbp,
      price_per_delegate_vat_gbp,
      price_per_delegate_vat: convertPriceToSourceCurrencyValue(isSourceCurrency, p, "price_per_delegate_vat"),
      price_per_delegate_net: convertPriceToSourceCurrencyValue(isSourceCurrency, p, "price_per_delegate_net"),
      price_total_net:  convertPriceToSourceCurrencyValue(isSourceCurrency, p, "price_total_net"),
      price_total_vat: convertPriceToSourceCurrencyValue(isSourceCurrency, p, "price_total_vat"),
      price_reduced_from_vat: convertPriceToSourceCurrencyValue(isSourceCurrency, p, "price_reduced_from_vat"),
      price_reduced_from_net: convertPriceToSourceCurrencyValue(isSourceCurrency, p, "price_reduced_from_net"),
      price_reduced_from_total_vat: convertPriceToSourceCurrencyValue(isSourceCurrency, p, "price_reduced_from_total_vat"),
      price_reduced_from_total_net: convertPriceToSourceCurrencyValue(isSourceCurrency, p, "price_reduced_from_total_net"),
    };
  });
}
