import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  companyUnload,
  fetchCompaniesForEvents,
  fetchCompany,
  fetchCorporateAccount,
  fetchSnapshotReport,
  snapshotReportUnload,
  unloadCompanyList,
  unloadCorporateAccount,
} from "../../../../redux/actions";
import { token, user } from "../../../../modules/auth";
import { companyObject, corporateAccountObject, isAgencyUser } from "../../../../modules/permissions";
import Loading from "../../../../Components/Loading";
import SnapshotReportTable from "../../../../Components/corporate/reports/SnapshotReportTable";
import classnames from "classnames";
import { ButtonPrimary, ButtonSpan } from "../../../../Components/Button";
import { Select } from "../../../../ui";
import { nameFormatter, removeEmptySearchParams } from "../../../../utils";
import * as ROUTES from "../../../../Router/corporate_routes";
import { saveData } from "../../../../services/downloadFile";

const SnapshotReportContainer = (props) => {
  const {
    corporateAccount,
    token,
    snapshotReport,
    fetchSnapshotReport,
    isFetchingSnapshotReport,
    ownReport = false,
    companyObject,
    snapshotReportUnload,
    companies,
    isAgency,
    fetchCompaniesForEvents,
    fetchCorporateAccount,
    unloadCompanyList,
    fetchCompany,
    showFilters = true,
    user,
    companyUnload,
    dashboardReport = false,
  } = props;
  const [dateFilter, setDateFilter] = useState("created_at");
  const [companyUserOptions, setCompanyUserOptions] = useState([]);
  const [company, setCompany] = useState(companyObject);
  const [companyUser, setCompanyUser] = useState("");
  const [exportingCsv, setExportingCsv] = useState(false);
  useEffect(() => {
    if (isAgency) {
      fetchCompaniesForEvents(token);
    }
    if (!isAgency && corporateAccount && Object.entries(corporateAccount).length > 0) {
      let client;
      if (ownReport) {
        client = user.id;
        setCompanyUser(user.id);
      }
      fetchSnapshotReport(corporateAccount.id, token, { client: client });
      let options = [];
      company && company.users && company.users.length > 0 && company.users
        .sort((a, b) => {
          return (a.first_name !== null && b.first_name !== null) ? a.first_name.localeCompare(b.first_name) : -1;
        })
        .map(user => {
          options.push({
            text: nameFormatter(user),
            value: user.id,
          });
        });
      setCompanyUserOptions(options);
    }

    return () => {
      unloadCompanyList();
      companyUnload();
      snapshotReportUnload();
    };
  }, []);

  useEffect(() => {
    let options = [];
    if (company === "") {
      companyUnload();
      setCompanyUserOptions([]);
      snapshotReportUnload();
    }
    if (typeof company === "string") {
      fetchCompany(company, token);
    }
    if (!isAgency && company && Object.entries(company).length > 0) {
      fetchCompany(company.id, token);
      company && company.users && company.users.length > 0 && company.users
        .sort((a, b) => {
          return (a.first_name !== null && b.first_name !== null) ? a.first_name.localeCompare(b.first_name) : -1;
        })
        .map(user => {
          options.push({
            text: nameFormatter(user),
            value: user.id,
          });
        });
      setCompanyUserOptions(options);
    }
    if (isAgency && company) {
      companies && Object.keys(companies).length > 0 && companies.map(com => {
        if (Number(com.id) === Number(company)) {
          if (com.corporate_account_id) {
            fetchCorporateAccount(com.corporate_account_id, token);
            fetchSnapshotReport(com.corporate_account_id, token);
          } else {
            snapshotReportUnload();
          }
        }
      });
    }
  }, [company]);

  useEffect(() => {
    let options = [];
    if (companyObject && Object.entries(companyObject).length > 0 && companyObject.corporate_account_id) {
      companyObject && companyObject.users && companyObject.users.length > 0 && companyObject.users
        .sort((a, b) => {
          return (a.first_name !== null && b.first_name !== null) ? a.first_name.localeCompare(b.first_name) : -1;
        })
        .map(user => {
          options.push({
            text: nameFormatter(user),
            value: user.id,
          });
        });
      setCompanyUserOptions(options);
    } else {
      snapshotReportUnload();
      setCompanyUserOptions([]);
    }
  }, [companyObject]);

  useEffect(() => {
    if (isAgency && corporateAccount && Object.entries(corporateAccount).length > 0 && companyObject && Object.entries(companyObject).length > 0) {
      fetchSnapshotReport(corporateAccount.id, token, {
        dateColumn: dateFilter,
        client: companyUser,
      });
    } else if (!isAgency && corporateAccount && Object.entries(corporateAccount).length > 0) {
      fetchSnapshotReport(corporateAccount.id, token, {
        dateColumn: dateFilter,
        client: companyUser,
      });
    } else {
      fetchSnapshotReport(corporateAccount.id, token, {
        dateColumn: dateFilter,
      });
    }
  }, [dateFilter, companyUser]);

  const getCompaniesOptions = () => {
    let options = [];
    if (companies && Object.keys(companies).length > 0) {
      companies.sort((a, b) => a.name.localeCompare(b.name)).filter(company => company.corporate_account_id !== null).map(company => {
        options.push({
          text: company.name,
          value: company.id,
        });
      });
    }
    return options;
  };

  const runSnapshotReportExport = async () => {
    setExportingCsv(true);
    if (exportingCsv) {
      return;
    }
    try {
      let queryString = "";
      if (companyUser) {
        let queryParams = {
          client: companyUser,
        };
        let queryFilters = new URLSearchParams(queryParams);
        removeEmptySearchParams(queryFilters);
        queryString = queryFilters.toString();
      }
      let response = await axios.get(
        window.API + ROUTES.API.CORPORATE.REPORTS.SNAPSHOT_EXPORT.replace(":slug", corporateAccount.id) + "?" + queryString,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      saveData(
        "csv",
        response.data,
        `spend-report.csv`,
      );
      setExportingCsv(false);
    } catch (e) {
      setExportingCsv(false);
      console.error(e);
    }
  };

  if (isFetchingSnapshotReport) {
    return <div className={"text-center"}><Loading classes="py-4" /><span
      className="text-sm italic text-grey-darker p-2">Loading report data</span></div>;
  }

  return (
    <div className="">
      {!dashboardReport ? (
        <h1 className="my-4">
          {ownReport ? `My Snapshot Report` : `Company Snapshot Report`}
        </h1>
      ) : null}
      <div
        className={`flex flex-col lg:flex-wrap lg:flex-row align-bottom p-3 mb-3 lg:mb-0 lg:items-end ${!dashboardReport ? " bg-white" : ""}`}>
        {!isFetchingSnapshotReport && (
          <div className="mb-2 xxl:mb-0 mr-4">
            <div className="flex text-xs min-h-9.5">
              <ButtonSpan
                className={classnames(
                  "inline-block flex items-center px-3 border border-grey-md border-r-0",
                  {
                    "opacity-50": dateFilter === "event_date_from",
                    "bg-brand": dateFilter !== "event_date_from",
                  },
                )}
                onClick={() => setDateFilter("created_at")}
              >
                Enquiry Date
              </ButtonSpan>
              <ButtonSpan
                className={classnames(
                  "inline-block flex items-center px-3 border border-grey-md",
                  {
                    "opacity-50": dateFilter === "created_at",
                    "bg-brand": dateFilter !== "created_at",
                  },
                )}
                onClick={() => setDateFilter("event_date_from")}
              >
                Event Date
              </ButtonSpan>
            </div>
          </div>)}
        {showFilters ? (
          <>
            {getCompaniesOptions().length > 0 ? (
              <div className="mb-2 xxl:mb-0 mr-4">
                <Select
                  name="company"
                  label="Filter by Company"
                  component={Select}
                  options={getCompaniesOptions()}
                  wrapperClassName="mb-0"
                  input={{
                    onChange: e => setCompany(e.target.value),
                    value: company,
                  }}
                  meta={{}}
                />
              </div>
            ) : null}
            {companyUserOptions.length > 0 ? (
              <div className="mb-2 xxl:mb-0 mr-4">
                <Select
                  name="user"
                  label="Filter by User"
                  placeholder="Viewing All Users"
                  component={Select}
                  options={companyUserOptions}
                  wrapperClassName="mb-0"
                  input={{
                    onChange: e => setCompanyUser(e.target.value),
                    value: companyUser,
                  }}
                  meta={{}}
                />
              </div>
            ) : null}
          </>
        ) : null}

        {!isFetchingSnapshotReport && snapshotReport && snapshotReport.length > 0 ?
          <SnapshotReportTable data={snapshotReport} /> : null}
        {!dashboardReport ? (
          <div className={"row mb-2"}>
            <div className={"col flex"}>
              <ButtonPrimary classes={"mt-4"} onClick={() => runSnapshotReportExport()}>
                {exportingCsv ? <Loading inline/> : 'Export to CSV'}
              </ButtonPrimary>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  let corporateAccount = Object.entries(corporateAccountObject(state.auth)).length > 0 ? corporateAccountObject(state.auth) : state.corporateAccount?.corporateAccount;
  let companyAccount = Object.entries(companyObject(state.auth)).length > 0 ? companyObject(state.auth) : state.company?.company;
  return {
    ...state.snapshotReport,
    companies: state.companyList.companiesForEvents ? state.companyList.companiesForEvents.data : [],
    corporateAccount: corporateAccount,
    companyObject: companyAccount,
    isAgency: isAgencyUser(state.auth),
    token: token(state),
    user: user(state),
  };
};

const mapDispatchToProps = {
  fetchSnapshotReport,
  snapshotReportUnload,
  fetchCompaniesForEvents,
  fetchCorporateAccount,
  unloadCompanyList,
  unloadCorporateAccount,
  fetchCompany,
  companyUnload,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotReportContainer);
