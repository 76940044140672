import React from "react";
import { string, object, oneOf } from "prop-types";
import Label from "./Label";

export default class ReadOnly extends React.Component {
  static propTypes = {
    input: object.isRequired,
    label: string.isRequired,
    wrapperClassName: string,
    labelClassName: string,
    size: oneOf(["sm", "md", "lg"]),
  };

  render() {
    const { input, label, size, wrapperClassName, labelClassName } = this.props;
    let wrapperClass = "max-w-full mb-5";
    if (size) {
      wrapperClass += " mr-6 ";
      switch (size) {
        case "sm":
          wrapperClass += "w-32";
          break;
        case "md":
          wrapperClass += "w-64";
          break;
        case "lg":
          wrapperClass += "w-80";
          break;
        default:
          wrapperClass += "";
      }
    }
    return (
      <div className={wrapperClassName || wrapperClass}>
        <Label name={input.name} className={labelClassName} text={label} />
        <div>{input.value}</div>
      </div>
    );
  }
}
