import React from "react";
import { ButtonBrand } from "Components/Button";

const VisitedSummary = props => {
  const { name, image, address1, address2, website, description, link } = props;

  return (
    <div className="flex flex-col lg:flex-row p-5 bg-off-white mt-6">
      <div className="col-w-full lg:col-w-1/2 lg:pr-4 mb-4 lg:mb-0">
        <div>
          <h2 className="mb-2">{name}</h2>
          <span className="block">
            {`${address1}${address2 && `, ` + address2}`}
          </span>
          {website && (
            <a
              className="my-4 block break-words"
              target="_blank"
              rel="noopener noreferrer"
              href={website}
            >
              {website}
            </a>
          )}
          <p className="mt-2 mb-5">{description}</p>
          <ButtonBrand
            Element="a"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            See full details
          </ButtonBrand>
        </div>
      </div>
      <div className="col-w-full lg:col-w-1/2 text-center lg:text-right">
        <img src={image ? image : "/img/venue-placeholder.jpg"} alt="" />
      </div>
    </div>
  );
};

export default VisitedSummary;
