import React from "react";
import filterImageExtensions from "../../helpers/filterImageExtensions";

class BackgroundImage extends React.Component {

    constructor(props) {
        super(props);
        this.fetchImage = this.fetchImage.bind(this);
    }

    fetchImage()
    {
        const {imagePath, edits} = this.props;
        if(!filterImageExtensions(imagePath)) {
            return null;
        }
        const imageRequest = JSON.stringify({
            bucket: "switchstance-s3",
            key: imagePath ,
            edits: edits
        });
        return `${window.CDN_URL}/${btoa(imageRequest)}`
    }


    render() {
        const {className, imagePath, placeholderImagePath, nonDiv, alt, width, height} = this.props;

        if(nonDiv && imagePath && alt) {
            return <img src={this.fetchImage()} alt={alt} width={width} height={height} className={className} />
        }
        if(nonDiv && !imagePath && placeholderImagePath && alt) {
            return <img src={placeholderImagePath} alt={alt} width={width} height={height}  className={className}/>
        }
        if(!imagePath && placeholderImagePath) {
            return(
            <div className={className} style={{ backgroundImage: `url(${placeholderImagePath})`}}>
                {this.props.children}
            </div>
            )
        }
        if(!imagePath) {
            return (
                <div className={className}>
                    {this.props.children}
                </div>
            )
        }
        return (
            <div className={className} style={{ backgroundImage: `url(${this.fetchImage()})`}}>
                {this.props.children}
            </div>
        );
    }
}

export default BackgroundImage