import React from "react";
import { withRouter, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, getFormInitialValues } from "redux-form";
import { token } from "modules/auth";
import FetchError from "Components/FetchError";
import Loading from "Components/Loading";
import { Failed } from "Components/Form";
import propTypes from "./propTypes";
import hydrate from "./hydrate";
import patch from "./patch";
import post from "./post";

class ApiForm extends React.Component {
  static propTypes = propTypes;

  state = {
    error: false,
    loading: false,
  };

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.initialized || this.props.create) return;
    hydrate(this.props, {
      loading: () =>
        this.setState({
          loading: true,
        }),
      complete: () =>
        this.setState({
          loading: false,
          error: false,
        }),
      error: code =>
        this.setState({
          error: code,
          loading: false,
        }),
    });
  }

  componentDidUpdate(props) {
    if (!props.submitFailed && this.props.submitFailed) {
      this.myRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  submit = values => {
    return this.props.create
      ? post(values, this.props)
      : patch(values, this.props);
  };

  render() {
    if (this.state.error) return <FetchError error={this.state.error} />;
    if (this.state.loading) return <Loading large />;

    return (
      <form noValidate onSubmit={this.props.handleSubmit(this.submit)}>
        <div ref={this.myRef} />
        <Prompt
          when={this.props.dirty}
          message={() =>
            `You have UNSAVED CHANGES, are you sure you want to leave WITHOUT saving them?`
          }
        />
        {this.props.submitFailed ? <Failed leftAlign /> : null}
        {this.props.render(
          this.props.submitting,
          this.props.submitFailed,
          this.props.submitSucceeded
        )}
      </form>
    );
  }
}

export default withRouter(
  reduxForm()(
    connect((state, props) => ({
      token: token(state),
      spanshot: getFormInitialValues(props.form)(state) || {},
    }))(ApiForm)
  )
);
