import axios from 'axios';
import handleErrors from "./utils";
import * as ROUTES from '../../Router/rfp_routes';
import {
  PROPOSAL_INVITE_RECEIVED,
  PROPOSAL_INVITE_RECEIVED_ERROR,
  PROPOSAL_INVITE_REQUEST,
  PROPOSAL_UNLOAD
} from "../reducers/types";

export const proposalInviteRequest = () => ({
  type: PROPOSAL_INVITE_REQUEST
});

export const proposalInviteReceived = data => ({
  type: PROPOSAL_INVITE_RECEIVED,
  data
});

export const proposalInviteReceivedError = error => ({
  type: PROPOSAL_INVITE_RECEIVED_ERROR,
  error
});

export const sendProposalInvite = (id, formValues, token) => {
  return (dispatch) => {
    dispatch(proposalInviteRequest());
    return axios.post(window.API + ROUTES.API.PROPOSAL.INVITE.replace(':id', id), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(response => {
        dispatch(proposalInviteReceived(response))
      })
      .catch(error => {
        console.clear()
        // console.log(error)
        dispatch(proposalInviteReceivedError(error))
        handleErrors(error)
      })
  }
}
