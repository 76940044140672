import React from "react";
import { object, func } from "prop-types";
import { withRouter } from "react-router-dom";

class HeaderWrap extends React.Component {
  static propTypes = {
    location: object.isRequired,
    children: func.isRequired,
  };

  state = {
    menuOpen: false,
    adminMenuOpen: true,
    width: undefined,
    height: undefined,
  };

  UNSAFE_componentWillMount = () => {
    this.updateDimensions();
  };

  componentDidMount = () => {
    if (this.state.width < 1200) {
      this.setState({
        adminMenuOpen: false,
      });
    } else {
      this.setState({
        adminMenuOpen: true,
      });
    }
    window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  componentDidUpdate = prev => {
    if (
      prev.location.pathname !== this.props.location.pathname &&
      this.state.width < 1200
    ) {
      this.setState({
        menuOpen: false,
        adminMenuOpen: false,
      });
    } else if (this.state.width >= 1200 && !this.state.adminMenuOpen) {
      this.setState({
        adminMenuOpen: true,
      });
    }
  };

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  toggleMenu = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  toggleAdminMenu = () => {
    this.setState({
      adminMenuOpen: !this.state.adminMenuOpen,
    });
  };

  render() {
    return this.props.children({
      menuOpen: this.state.menuOpen,
      adminMenuOpen: this.state.adminMenuOpen,
      toggleMenu: this.toggleMenu,
      toggleAdminMenu: this.toggleAdminMenu,
    });
  }
}

export default withRouter(HeaderWrap);
