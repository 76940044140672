import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { Input, Select } from "../../ui";
import { corporateDataCapture as v } from "./validate";
import { token } from "../../modules/auth";
import {
  fetchBookingDesks,
  fetchCompanyBranches,
  unloadAgencyBookingDesks,
  unloadCompanyBranches,
} from "../../redux/actions";

const mapStateToProps = state => {
  return ({
    token: token(state),
    ...state.companyBranches,
    ...state.agencyBookingDesks,
  });
};

const mapDispatchToProps = {
  fetchCompanyBranches,
  unloadCompanyBranches,
  fetchBookingDesks,
  unloadAgencyBookingDesks,
};

const CompanyQuestions = ({
                            company,
                            token,
                            fetchCompanyBranches,
                            unloadCompanyBranches,
                            companyBranches,
                            isSuperAgent,
                            agencyBookingDesks,
                            unloadAgencyBookingDesks,
                            fetchBookingDesks,
                            change,
                            form
                          }) => {
  const [companyBranch, setCompanyBranch] = useState();
  const [bookingDesk, setBookingDesk] = useState();
  useEffect(() => {
    if (company) {
      fetchCompanyBranches(company.id, token);
      if (company.agency_id) {
        fetchBookingDesks(company.agency_id, token);
      }
    }
    if (!isSuperAgent) {
      change(form, 'agency_booking_desk_id', company.default_booking_desk_id)
    }
    return () => {
      unloadCompanyBranches();
      unloadAgencyBookingDesks();
    };
  }, [company]);
  const getCompanyBranchesOptions = () => {
    let options = [];
    if (companyBranches && companyBranches.length > 0) {
      companyBranches.map(branch => {
        if (branch.deleted_at === null) {
          options.push({
            text: branch.name,
            value: branch.id,
          });
        }
      });
    }
    return options;
  };

  const getAgencyBookingDeskOptions = () => {
    let options = [];
    if (agencyBookingDesks && agencyBookingDesks.length > 0) {
      agencyBookingDesks.map(desk => {
        if (isSuperAgent) {
          options.push({
            text: desk.name,
            value: desk.id,
          });
        } else {
          if (desk.id == company.default_booking_desk_id) {
            options.push({
              text: desk.name,
              value: desk.id,
            });
          }
        }
      });
    }
    if (agencyBookingDesks.length === 0 && company.default_booking_desk) {
      options.push({
        text: company.default_booking_desk.name,
        value: company.default_booking_desk.id,
      })
    }
    return options;
  };

  return (
    <div className="row mb-8 -mt-4">
      {!isSuperAgent ? null : !company.corporate_account_id ? (
        getAgencyBookingDeskOptions().length > 0 ? (
          <div className="col col-w-1/4">
            <Field
              name="agency_booking_desk_id"
              label="Booking Desk"
              component={Select}
              options={getAgencyBookingDeskOptions()}
              onChange={e => setBookingDesk(e.target.value)}
              wrapperClassName="inline mb-8 xl:w-1/2"
            />
          </div>
        ) : null
      ) : null}
      {getCompanyBranchesOptions().length > 0 ? (
        <div className="col col-w-1/4">
          <Field
            name="branch_id"
            label="Branch"
            component={Select}
            options={getCompanyBranchesOptions()}
            onChange={e => setCompanyBranch(e.target.value)}
            wrapperClassName="inline mb-8 xl:w-1/2"
            validate={v.branch_id}
          />
        </div>
      ) : null}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyQuestions);
