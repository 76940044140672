import axios from "axios";
import { removeEmptySearchParams } from "../../utils";
import * as ROUTES from "./../../Router/misc_routes";
import {
  SITE_VISITS_LIST_ERROR,
  SITE_VISITS_LIST_REQUEST,
  SITE_VISITS_LIST_REQUEST_RECEIVED,
  SITE_VISITS_LIST_UNLOAD,
} from "../reducers/types";

export const siteVisitsListRequest = () => ({
  type: SITE_VISITS_LIST_REQUEST,
});

export const siteVisitsListRequestReceived = data => ({
  type: SITE_VISITS_LIST_REQUEST_RECEIVED,
  data,
});

export const unloadSiteVisitsList = () => ({
  type: SITE_VISITS_LIST_UNLOAD,
});

export const siteVisitsListError = error => ({
  type: SITE_VISITS_LIST_ERROR,
  error,
});

export const fetchSiteVisits = (token, queryParams = {}, page = 1) => {
  if (!queryParams.page) {
    queryParams.page = page;
  }
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString();
  return (dispatch) => {
    dispatch(siteVisitsListRequest());
    return axios.get(window.API + ROUTES.API.SITE_VISITS.LIST + "?" + queryString, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(siteVisitsListRequestReceived(response.data)))
      .catch(error => dispatch(siteVisitsListError(error)));
  };
};
