import React from "react";
import _get from "lodash/get";
import _truncate from "lodash/truncate";
import { ButtonBrand } from "Components/Button";
import moment from "moment";
import BackgroundImage from "../../Components/General/BackgroundImage";

const SiteVisitPreview = props => {
  return (
    <div className={props.className}>
      {props.type === "venue" && <RenderVenueSiteVisitPreview {...props} />}
      {props.type === "destination" && (
        <RenderDestinationSiteVisitPreview {...props} />
      )}
    </div>
  );
};

const RenderVenueSiteVisitPreview = props => {
  const image =
      (props.visited.featured_image && props.visited.featured_image.path) ||
      (props.visited.images && props.visited.images[0]  && props.visited.images[0].path) ||
      null;


  let visit_date = `${moment(props.visit_date_from).format("MMMM Do YYYY")}`;

  // Add second date only if the visit was more than 1 day
  if (props.visit_date_from !== props.visit_date_to) {
    visit_date = `${visit_date} - ${moment(props.visit_date_to).format(
      "MMMM Do YYYY"
    )}`;
  }


  return (
    <>
      <div className="row p-4">
        <div className="col col-w-full">
          <h3 className="mr-4 font-bold">
            Venue - {props.visited.display_name}
          </h3>
          <p className="my-1">
            {props.author_visible && props.author && typeof props.author !== 'undefined'
              ? `${props.author.first_name} ${props.author.surname} - Agent`
              : "Agent Insight"}
          </p>
          <small className="my-2 block">{visit_date}</small>
        </div>
      </div>
      <div className="row px-4 pb-4 ">
        <div className="col col-w-full lg:col-w-2/3">
          {props.is_legacy ?
              <p className="" dangerouslySetInnerHTML={{
                __html: _truncate(props.impressions, { length: 150 })
              }}/>
            :
              <p className="">"{_truncate(props.impressions, { length: 150 })}"</p>
          }
          <div className="flex justify-start">
          <ButtonBrand Element="a" href={`/site-visits/${props.id}`}>
            View full report
          </ButtonBrand>
          </div>
        </div>
        <div className="hidden lg:block col col-w-full lg:col-w-1/3 text-right">
          {image ?
            <BackgroundImage
                imagePath={image}
                className="bg-cover bg-center h-40 rounded-t-lg bg-light-grey"
                edits={{
                  "resize": {
                    "width": 150,
                    "height": 150,
                    "fit": "cover"
                  }}} />

            :     <img src={'/img/venue-placeholder.jpg'} className="w-full" alt="Venue Featured" /> }
        </div>
      </div>
    </>
  );
};

// To be built with destination functionality
const RenderDestinationSiteVisitPreview = props => {
  return (
    <>
      <div className="w-full lg:w-1/2">
        <h3>{props.visited.display_name}</h3>
      </div>
      <div className="w-full lg:w-1/2"></div>
    </>
  );
};

export default SiteVisitPreview;
