import moment from 'moment';
import React from "react";
import {isAgency, isInternal} from "../../modules/auth";
import {connect} from 'react-redux';

const mapStateToProps = (state, props) => {
    return {
        isInternal: isInternal(state),
        isAgency: isAgency(state),
    };
};

let RFPCancelMessage = ({enquiry, isAgency, isInternal}) => {

    if(!enquiry.brief.decline_reason && !enquiry.brief.decline_date && !enquiry.brief.declined_by )
    {
        return null;
    }

    return (
        <React.Fragment>
            <div className='bg-warning px-3 py-6 my-5 block font-bold text-white'>
                <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
                    {`This enquiry was declined at ${moment(enquiry.brief.decline_date).format('DD/MM/YYYY')} by ${enquiry.brief.declined_by.first_name} ${enquiry.brief.declined_by.surname} for the following reason: ${enquiry.brief.decline_reason.description} ${enquiry.brief.decline_reason_text}`}
                </p>
            </div>
        </React.Fragment>
    )

}
export default connect(mapStateToProps, null)(RFPCancelMessage);
