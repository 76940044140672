import axios from 'axios';
import {
  UPLOAD_USER_PROFILE_IMAGE_REQUEST,
  UPLOAD_USER_PROFILE_IMAGE_ERROR,
  UNLOAD_USER,
  DELETE_USER_PROFILE_IMAGE_REQUEST,
  DELETE_USER_PROFILE_IMAGE_ERROR,
  USER_REQUEST,
  USER_CREATED,
  USER_CREATED_ERROR,
  USER_EXISTS_CHECK_REQUEST,
  USER_EXISTS_RECEIVED_REQUEST,
  USER_EXISTS_RECEIVED_ERROR
} from '../reducers/types';
import {updateUser} from "../../modules/auth";
import handleErrors from "./utils";

/** Agencies */
export const userProfilePictureUploadedRequest = (data) => ({
  type: UPLOAD_USER_PROFILE_IMAGE_REQUEST,
  data,
});


export const userProfilePictureUploadError = (error) => ({
  type: UPLOAD_USER_PROFILE_IMAGE_ERROR,
  error,
});

export const userProfilePictureDeleteRequest = () => ({
  type: DELETE_USER_PROFILE_IMAGE_REQUEST,
});

export const userProfilePictureDeleteError = (data) => ({
  type: DELETE_USER_PROFILE_IMAGE_ERROR,
  data,
});

export const unloadUser = () => ({
  type: UNLOAD_USER
});

export const userRequest = () => ({
  type: USER_REQUEST
});

export const userCreated = (data) => ({
  type: USER_CREATED,
  data
});

export const userCreatedError = (error) => ({
  type: USER_CREATED_ERROR,
  error
});

export const checkUserExistsRequest = () => ({
  type: USER_EXISTS_CHECK_REQUEST
});

export const checkUserExistsReceivedRequest = data => ({
  type: USER_EXISTS_RECEIVED_REQUEST,
  data
});

export const checkUserExistsReceivedError = error => ({
  type: USER_EXISTS_RECEIVED_ERROR,
  error
});

export const createUser = (token, formValues) => {
  return (dispatch) => {
    dispatch(userRequest());
    return axios.post(window.API + '/users/', formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(userCreated(response.data)))
      .catch(error => {
        dispatch(userCreatedError(error))
        handleErrors(error)
      });
  }
}

/** Profile Picture */
export const uploadUserProfileImage = (token, formValues) => {
  return (dispatch) => {
    dispatch(userProfilePictureUploadedRequest());
    return axios.post(window.API + '/users/me/profile-image', formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(updateUser({user: response.data})))
      .catch(error => dispatch(userProfilePictureUploadError(error)));
  };
};

export const deleteUserProfileImage = (token) => {
  return (dispatch) => {
    dispatch(userProfilePictureDeleteRequest());
    return axios.delete(window.API + '/users/me/profile-image', {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(updateUser({user: response.data})))
      .catch(error => dispatch(userProfilePictureDeleteError(error)));
  };
};

export const checkUserExists = (email, token) => {
  return (dispatch) => {
    dispatch(checkUserExistsRequest());
    return axios.get(window.API + `/users/${email}`, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(checkUserExistsReceivedRequest(response.data)))
      .catch(error => dispatch(checkUserExistsReceivedError(error)))
  }
}