import React from "react";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import { isLoggedIn } from "../modules/auth";

const RedirectToLoginComponent = ({ location, state }) => (
  <Redirect
    to={{
      pathname: "/login",
      search: `?return=${encodeURI(location.pathname + location.search)}&logInToProceed=1`,
      state,
    }}
    push
  />
);

export const RedirectToLogin = withRouter(RedirectToLoginComponent);

const RequireLoginComponent = ({ isLoggedIn, ...props }) =>
  isLoggedIn ? null : <RedirectToLogin {...props} />;

export const RequireLogin = connect(state => ({
  isLoggedIn: isLoggedIn(state),
}))(RequireLoginComponent);
