import { format } from "date-fns";
import groupBy from "lodash/groupBy";
import getDatesArray from "./get-dates-array";

export default (data, start, end, key) => {
  if (!data) return null;
  const byDay = groupBy(data, item => {
    return item.day;
  });
  const dates = getDatesArray(start, end);
  if (!Array.isArray(dates)) {
    return null;
  }
  return dates.map(date => {
    const data = {
      day: format(date, "MMM Do"),
    };
    if (byDay[date]) {
      data.total = Math.max.apply(
        Math,
        byDay[date].map(function(o) {
          return o[key];
        })
      );
    }
    return data;
  });
};
