import axios from "axios";

const setDirect = ({ rfpId, collectCommission }, token) =>
  axios.post(
    `${window.API}/rfp/enquiry-set-direct`,
    {
      enquiry_id: rfpId,
      collect_commission: collectCommission,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

export { setDirect };
