import React, {useState} from "react";
import Drawer from "react-drag-drawer";
import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import {homepageItems, wpRedirects} from "modules/menu-items";
import {isAgent, isInternal, isLoggedIn, isVenueAdmin} from "modules/auth";
import {Link} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {generalInteraction} from "../../modules/analytics";

const MenuDrawer = ({open, toggle, generalInteraction}) => {
  const [expanded, setExpanded] = useState(
    homepageItems
      .filter(item => !!item.submenu)
      .reduce((all, current) => {
        all[current.text] = false;
        return all;
      }, {})
  );
  const loggedIn = useSelector(state => isLoggedIn(state));
  const isAgency = useSelector(state => isAgent(state));
  const isUserInternal = useSelector(state => isInternal(state));

  const linkClass = "font-semibold text-black text-md lg:text-lg uppercase";

  const toggleExpanded = item => {
    let newState = expanded;
    newState[item] = !expanded[item];

    setExpanded({...newState});
  };


  const wpRedirectFrom = Object.keys(wpRedirects);

  const MenuItem = ({item}) => {
    return (
        <li
            className={`px-10 py-2 lg:py-3 w-full items-center ${
                !!item.submenu && expanded[item.text] ? "bg-light-blue-grey" : ""
            }`}
        >
          {wpRedirectFrom.includes(item.path) ? (
              <a
                  href={wpRedirects[item.path]}
                  className={linkClass}
              >
                {item.text}
              </a>
          ) : (
              <Link
                  to={item.path}
                  className={linkClass}
                  onClick={item.submenu ? () => toggleExpanded(item.text) : undefined}
              >
                {item.text}
              </Link>
          )}
          {!!item.submenu && !expanded[item.text] && (
              <FaAngleDown className="ml-1 text-black"/>
          )}
          {!!item.submenu && expanded[item.text] && (
              <FaAngleUp className="ml-1 text-black"/>
          )}
          {!!item.submenu && expanded[item.text] && (
              <ul className="list-reset px-4 mt-4">
                {item.submenu.map(subItem => (
                    <li className="py-3">
                      <Link to={subItem.path} className={linkClass}>
                        {subItem.text}
                      </Link>
                    </li>
                ))}
              </ul>
          )}
        </li>
    )
  }

  return (
    <Drawer
      open={open}
      onRequestClose={toggle}
      direction="x"
      modalElementClass="menu-drawer"
      containerElementClass="justify-end-i xl:hidden"
    >
      <div>
        <div className="py-5 px-6">
          <div className="flex justify-end">
            <img
              src="/img/close.svg"
              alt="Close menu"
              className="pointer"
              onClick={toggle}
            />
          </div>
        </div>
        <div className="mb-8">
          <ul className="list-reset py-2 xl:flex xl:justify-end">
            {homepageItems.map(item => {
              if (item.text === "Your venues") {
                if (loggedIn && isVenueAdmin)
                  return <MenuItem item={item} key={item.text}/>;
                return null;
              }
              if (item.text === "Offers" && isAgency) {
                return <MenuItem item={item} key={item.text}/>;
              } else if (item.text === "Offers" && !isAgency) {
                delete item.submenu;
                return <MenuItem item={item} key={item.text}/>;
              }

              if (item.text === "Venue news" && isAgency) return null;
              if (item.text === "Site visits" && !isUserInternal) return null;
              if (item.text === "Agency Info" && !isAgency) return null;

              return <MenuItem item={item} key={item.text}/>;
            })}
          </ul>
        </div>
      </div>
      <hr className="text-grey w-full"/>
      <div className="px-10">
        <ul className="px-0 list-reset py-2 xl:flex xl:justify-end">
          <li className="py-3 w-full">
            <Link to="#" className={linkClass}>
              Suggest a venue
            </Link>
          </li>
          {loggedIn ? (
            <>
              <li className="py-3 w-full">
                <Link to="/logout" className={linkClass}>
                  Log out
                </Link>
              </li>
              <li className="py-3 w-full">
                <Link to="/admin" className={linkClass}>
                  Account
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className="py-3 w-full">
                <Link to="/login" className={linkClass}>
                  Log in
                </Link>
              </li>
              <li className="py-3 w-full">
                <Link to="/register" className={linkClass}>
                  Register
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <div onClick={() => {
        generalInteraction({type: "general_header_edge_phone_click"})
      }}>
        <a
          href="tel:441780484051"
          className="absolute pin-b w-full bg-green py-4"
        >
          <p className="text-center mb-0 text-white font-heading font-semibold">
            Call us on +44(0) 1780 484051{" "}
          </p>
        </a>
      </div>
    </Drawer>
  );
};

export default connect(null, {generalInteraction})(MenuDrawer);
