import groupBy from "lodash/groupBy";
import {
  createDateRange,
  dateRangeFilter,
} from "../../../pages/admin/Analytics/date-utils";
import { allTypes } from "./utils";

export default (id, start, end) => state => {
  const dateRange = createDateRange(start, end);

  const all = dateRangeFilter(allTypes(id)(state), dateRange, "day");

  const grouped = groupBy(all, item => {
    return item.device;
  });
  return [
    {
      name: "Mobile",
      value: grouped.mobile ? grouped.mobile.length : 0,
      colour: "#e65100",
    },
    {
      name: "Tablet",
      value: grouped.tablet ? grouped.tablet.length : 0,
      colour: "rgb(0, 153, 255)",
    },
    {
      name: "Desktop",
      value: grouped.desktop ? grouped.desktop.length : 0,
      colour: "#9BCB41",
    },
  ];
};
