import React, { Fragment } from "react";
import { Field, FieldArray } from "redux-form";
import {
  Input,
  Checkbox,
  Select,
  LiveSearch,
  Textarea,
  Radio,
  File,
} from "Components/Form";
import CollapsiblePanel from "Components/CollapsiblePanel";
import Modal from "Components/Modal";
import { ButtonSpan, ButtonBrand } from "Components/Button";
import { required, optional, int, email } from "modules/validation";

const v = {
  required: required(),
  optionalInt: optional(int),
};

export const EnquiryModal = ({ children, closeModal, onClickLogo }) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 1200,
          maxWidth: "90%",
        }}
      >
        <div className="m-3 absolute pin-t pin-r flex items-start">
          <img
            className="mr-2"
            src="/img/logo-black.svg"
            alt="EDGE Venues logo"
            width="150"
            height="73"
            // Remove before commit
            onClick={onClickLogo}
          />
          <ButtonSpan className="block text-right" onClick={closeModal}>
            <i className="fas fa-times ml-3" />
          </ButtonSpan>
        </div>
        {children}
      </div>
    </div>
  </Modal>
);

export const EventName = () => (
  <Field
    name="event_name"
    label="Event name"
    size="lg"
    component={Input}
    validate={v.required}
  />
);

export const Catering = ({ buyItNow }) => (
  <CollapsiblePanel title="Catering" inForm>
    <Field
      name="catering_requirements"
      component={Checkbox}
      label=""
      options={[
        "Morning/arrival break",
        "Breakfast rolls",
        "Mid-morning break",
        "Lunch",
        "Afternoon break",
        buyItNow ? null : "Dinner",
      ].filter(Boolean)}
    />
  </CollapsiblePanel>
);

export const Equipment = () => (
  <CollapsiblePanel title="Equipment" inForm>
    <Field
      name="equipment_requirements"
      label="Equipment Requirements"
      labelClassName="sr-only"
      component={Checkbox}
      options={[
        "Flipchart",
        "LCD Projector",
        "Screen",
        "Extension Cable",
        "Staging",
        "Lectern",
        "Laptop",
        "Hearing Loop",
        "Wi-Fi",
        "PA System",
        "Microphone",
        "Conference Phone",
        "Video Conferencing",
        "Organiser's Office",
        "Remote Mouse Clicker",
        "Plasma Screen",
        "Smartboard",
      ]}
    />
  </CollapsiblePanel>
);

export const VenueSearch = ({ label }) => (
  <LiveSearch
    name="venues_query"
    label={label}
    formId="request-for-quote"
    auth
    multi="venues"
    setId
    url={`${window.API}/venues/quick`}
    getText={item => item.display_name}
  />
);

export const MeetingHost = () => (
  <Field name="meeting_host" label="Meeting Host" size="md" component={Input} />
);

export const MeetingRooms = () => (
  <CollapsiblePanel title="Meeting Rooms" inForm>
    <div className="flex flex-wrap">
      <Field
        name="meeting_room_layout"
        label="Main room layout"
        component={Select}
        options={["Theatre", "Cabaret", "U-Shape", "Classroom", "Boardroom"]}
        wrapperClassName="mr-6 mb-5"
      />
      <Field
        name="back_projection"
        label="Do you require back projection?"
        component={Radio}
        wrapperClassName="mr-6 mb-5"
      />
      <Field
        name="total_syndicate_rooms"
        label="No. Syndicate Rooms"
        component={Input}
        size="md"
        validate={v.optionalInt}
      />
    </div>
    <h3>Additional Syndicate Rooms</h3>
    <FieldArray
      name="syndicate_rooms"
      component={({ fields, meta: { error, submitFailed } }) => (
        <Fragment>
          <table className="edge-table">
            <thead>
              <tr>
                <th className="w-1/3">Layout</th>
                <th className="w-1/3">Back Projection</th>
                <th className="w-1/3" />
              </tr>
            </thead>
            <tbody>
              {fields.map((member, index, fields) => (
                <tr key={index}>
                  <td>
                    <Field
                      name={`${member}.layout`}
                      label="Layout"
                      component={Select}
                      options={[
                        "Theatre",
                        "Cabaret",
                        "U-Shape",
                        "Classroom",
                        "Boardroom",
                      ]}
                      wrapperClassName="mb-0"
                      labelClassName="sr-only"
                    />
                  </td>
                  <td>
                    <Field
                      name={`${member}.back_projection`}
                      label="Do you require back projection?"
                      component={Radio}
                      wrapperClassName="mb-0"
                    />
                  </td>
                  <td
                    className="text-right text-warning pointer"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    Delete
                    <i className="fas fa-trash-alt ml-3" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {submitFailed && error && (
            <div className="text-warning my-1 mb-3">{error}</div>
          )}
          <div className="text-right">
            <ButtonBrand classes="mb-5" onClick={() => fields.push({})}>
              <i className="fal fa-plus" />
            </ButtonBrand>
          </div>
        </Fragment>
      )}
    />
  </CollapsiblePanel>
);

export const AdditionalInfo = () => (
  <Field
    name="notes"
    label="Additional Information"
    wrapperClassName="w-full"
    component={Textarea}
    rows={7}
  />
);

export const Contact = () => (
  <Fragment>
    <p className="font-bold">Best way of communicating with you?</p>
    <Field
      size="md"
      name="email"
      label="Email"
      component={Input}
      validate={value => value && email(value)}
    />
    <Field
      size="md"
      name="phone"
      label="Tel"
      component={Input}
      validate={(value, allValues) =>
        !value && !allValues.email
          ? "Either an email addresss or a phone number is required"
          : undefined
      }
    />
  </Fragment>
);

export const Attachments = () => (
  <File
    label="Attachments"
    type="document"
    array
    path={`${window.API}/cms/files`}
    readFrom="files"
    formId="request-for-quote"
  />
);
