import React,{useState} from "react";
import Modal from "../../../../Components/Modal";
import {ButtonPrimary, ButtonSpan} from "../../../../Components/Button";
import {Input} from "../../../../Components/Form";
import { Field, getFormValues, reduxForm } from "redux-form";
import {token as tokenSelector} from "../../../../modules/auth";
import { useSelector, connect } from "react-redux";
import {email,} from "modules/validation";
import {chaseEvent} from "../../../../redux/actions";
import moment from 'moment';

const v = {
    email,
};

const mapStateToProps = (state, props) => {
    return{
        initialValues: {
            chase_to: props.event.enquiry_venue_datas.venue_contact?.email
        },
        chaseTo: props.event.enquiry_venue_datas.venue_contact?.email
    }
}
const mapDispatchToProps = {
    chaseEvent
}

let EventsChaseModal = ({event, chaseContract, chaseEvent,  closeModal, handleSubmit, chaseTo}) => {

    const token = useSelector(tokenSelector);
    const values = useSelector(getFormValues('chaseModal')) || {};
    const [submissionError, setSubmissionError] = useState(null);
    const [submissionSuccess, setSubmissionSuccess] = useState(null);

    let onSubmit = (formValues) =>
    {
        return chaseEvent(event.id, chaseContract, token,  {
            event_id: event.id,
            chase_to: formValues.chase_to
        }).then(
            (response) => {
                setSubmissionError(null);
                setSubmissionSuccess(true);
                setTimeout(function(){
                    closeModal();
                }.bind(this),1500);
            }
        ).catch(
            (error) => {
              setSubmissionError(error)
              setSubmissionSuccess(false);
            }
        );
    }
    return (
        <>
            <Modal>
                <div className="modal">
                    <div
                        className="modal-inner"
                        style={{
                            width: 600,
                            maxWidth: "90%",
                        }}
                    >
                        <ButtonSpan
                            className="m-3 absolute pin-t pin-r z-10"
                            onClick={closeModal}
                        >
                            <i className="fas fa-times-circle fa-2x ml-3" />
                        </ButtonSpan>
                        <form  className="relative pb-16" onSubmit={handleSubmit(onSubmit)} >
                            <h1 className="mb-8">Chase the venue for the {chaseContract ? "contract" : 'invoice'}</h1>
                            {!chaseContract && event.invoice_last_chased_date ? <p>Last Chased At: {moment(event.invoice_last_chased_date).format('DD/MM/YYYY HH:mm:ss')}</p> : null}
                            {chaseContract && event.contract_last_chased_date ? <p>Last Chased At: {moment(event.contract_last_chased_date).format('DD/MM/YYYY HH:mm:ss')}</p> : null}
                            <p>A chaser email will be sent to the original Venue contact from the RFP to prompt them to upload the {chaseContract ? "contract" : 'invoice'}</p>
                            {chaseTo ? null : <span className="text-red my-4 block">{`Could not find suitable venue contact`}</span> }

                            <Field
                                name="chase_to"
                                label="Email to send chase to"
                                component={Input}
                                wrapperClassName="w-full mb-8 mr-6"
                                validate={v.email}
                            />

                            {submissionError && (
                                <p className="my-5 text-warning">
                                    Sorry, there was a problem sending the email
                                </p>
                            )}

                            {submissionSuccess && (
                                <p className="my-5 text-success">
                                    Chase email sent to: {values['chase_to']}
                                </p>
                            )}

                            <div className="absolute pin-b pin-r">
                                <ButtonPrimary
                                    type="submit"
                                    classes="px-5"
                                    style={{ height: 57, lineHeight: "57px" }}
                                >
                                    Confirm and Send
                                </ButtonPrimary>
                            </div>

                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )

}

EventsChaseModal = reduxForm({
    form: 'chaseModal',
    enableReinitialize: true
})(EventsChaseModal);

export default connect(mapStateToProps, mapDispatchToProps)(EventsChaseModal);

