import React from "react";
import { reduxForm } from "redux-form";

const VenueSearchResults = props => {
  return (
    <table className="edge-table mt-3">
      <tbody>
        {props.searchResults.map(venue => {
          return (
            <tr
              className="cursor-pointer"
              key={venue.id}
              onClick={() => {
                props.loadVenue(venue.slug);
              }}
            >
              <td className="capitalize font-bold">{venue.name}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
})(VenueSearchResults);
