import React from "react";
import {ButtonBrand} from "../../../../Components/Button";
import {Link} from "react-router-dom";
import * as ROUTES from "../../../../Router/corporate_routes";
import Loading from "../../../../Components/Loading";
import ItemList from "../../../../Components/Layout/ItemList";


let CorporateAccountDocumentCategoryList = ({isFetching, categories, accountSlug, isCorp=false}) => {
    if (isFetching) {
        return (
            <div>
                <Loading/>
            </div>
        )
    }
    if (typeof categories === 'undefined' || (Object.entries(categories).length === 0)) {
        return (
            <div className="p-3 bg-lighter-blue w-full my-6">
                <p className={'mb-0'}><i className="fas text-2lg fa-info-circle mr-3"/>No documents available for this account</p>
            </div>
        )
    }

    return (
        <div>
            <h1 className="my-5">Corporate Account Documents</h1>
            <div className="flex flex-row flex-wrap -mx-2">
                {categories.map(category => {
                    let categoryLink = accountSlug ? ROUTES.FRONTEND.CORPORATE.DOCUMENTS.ADMIN_SINGLE_CATEGORY.replace(':slug', accountSlug).replace(':section', 'view-documents').replace(':category', category.id) : ROUTES.FRONTEND.CORPORATE.DOCUMENTS.SINGLE_CATEGORY.replace(':category', category.id);
                    return(

                        <ItemList
                            corporateStyle={isCorp}
                            key={category.id}
                            title={category.name}
                            link={categoryLink}
                            buttonText={`View ${category.name} Documents`}
                            icon={"fal fa-file-download"}
                            download={false}
                            externalLink={false}
                        />

                    )
                })}
            </div>
        </div>
    )
}

export default CorporateAccountDocumentCategoryList