import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import {Textarea} from "Components/Form";
import {ButtonPrimary, ButtonSpan} from "Components/Button";
import Modal from "Components/Modal";
import {processFormValues} from "../../modules/formTools";
import {unloadNote} from "../../redux/actions/notes";

const form = "rfp-agents-notes";

const mapDispatchToProps = {
  unloadNote
}

const Wrapper = ({children, onClose}) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 1200,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3"/>
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

const AgentsNotesModal = props => {
  const {onClose, handleSubmit, unloadNote} = props;
  useEffect(() => {
    unloadNote();
  }, []);

  const onSubmit = (formValues) => {
    return processFormValues(props, formValues, {});
  }

  return (
    <Wrapper onClose={onClose}>
      <form className="relative pb-16" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="mb-8">Add a Note</h1>
        <Field
          label="Note"
          labelClassName="sr-only"
          name="note"
          component={Textarea}
          rows={9}
        />
        <div className="absolute pin-b pin-r">
          <ButtonPrimary
            type="submit"
            classes="px-5"
            style={{height: 57, lineHeight: "57px"}}
          >
            Add Note
          </ButtonPrimary>
        </div>
      </form>
    </Wrapper>
  );
};

export default reduxForm({
  form
})(connect(null, mapDispatchToProps)(AgentsNotesModal))
