import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";

export const CalendarToolbar = (props) => {
  const navigate = action => {
    props.onNavigate(action)
  }

  const location = useLocation();

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => navigate('TODAY')}>Today</button>
        <button type="button" onClick={() => navigate('PREV')}>Back</button>
        <button type="button" onClick={() => navigate('NEXT')}>Next</button>
      </span>
      <span className="rbc-toolbar-label">{props.label}</span>
      {location.pathname !== "/admin/corporate-accounts/calendar-events" && (
        <div className="rbc-btn-group filters">
          <button type="button" className={props.activeFilter === "all" ? `active-filter` : ``} onClick={() => props.eventFiltersHandler("all")}>Show All</button>
          {!props.isEdgeAdmin && (
            <button type="button" className={props.activeFilter === "ownEvents" ? `active-filter` : ``} onClick={() => props.eventFiltersHandler("ownEvents")}>My Events</button>
          )}
          <button type="button" className={props.activeFilter === "companyEvents" ? `active-filter` : ``} onClick={() => props.eventFiltersHandler("companyEvents")}>Company Events</button>
          <button type="button" className={props.activeFilter === "industryEvents" ? `active-filter` : ``} onClick={() => props.eventFiltersHandler("industryEvents")}>Industry Events</button>
          <button type="button" className={props.activeFilter === "publicEvents" ? `active-filter` : ``} onClick={() => props.eventFiltersHandler("publicEvents")}>Public Events</button>
        </div>
      )}
    </div>
  )
}
