import { groupBy, sumBy, values } from "lodash";
import {
  createDateRange,
  dateRangeFilter,
} from "../../../pages/admin/Analytics/date-utils";
import { allTypes, uniqByUuid, venuePageViews } from "./utils";
import { distanceInWordsStrict } from "date-fns";
export const getSessionCount = items => {
  // ropey way of marking sessions
  // Count the unique uuid's per hour and add them together
  const itemsByHour = values(
    groupBy(items, item => `${item.day} ${item.hour}`)
  );

  return sumBy(itemsByHour, items => uniqByUuid(items).length);
};

export default (id, start, end, typeOrFilter = () => true) => state => {
  const dateRange = createDateRange(start, end);

  const typeCheck =
    typeof typeOrFilter === "function"
      ? typeOrFilter
      : item => item.user_type === typeOrFilter;

  const all = dateRangeFilter(allTypes(id)(state), dateRange, "day").filter(
    typeCheck
  );

  const unique = uniqByUuid(all);
  const new_visitors = all.filter(item => item.new).length;

  // ropey way of marking sessions
  //*
  const sessions = getSessionCount(all);

  const pageViewsData = dateRangeFilter(
    venuePageViews(id)(state),
    dateRange,
    "day"
  ).filter(typeCheck);

  const page_views = pageViewsData.length;

  let seconds = 0;
  let bounces = 0;
  pageViewsData.forEach(view => {
    if (view.seconds) {
      seconds = seconds + view.seconds;
    } else {
      bounces++;
    }
  });

  return {
    visitors: unique.length,
    new_visitors,
    sessions,
    sessions_per_user: Math.round(sessions / unique.length) || 0,
    page_views,
    pages_per_session: Math.round(page_views / sessions) || 0,
    average_session_duration: parseInt(seconds) === 0 && parseInt(page_views) === 0 ? 0 : distanceInWordsStrict(
      0,
      (seconds / page_views) * 1000
    ),
    bounce_rate: `${Math.round(bounces / page_views) * 100 || 0}%`,
  };
};
