import axios from "axios";

const saveCarbonImpact = ({ values, venue }, token) => {
    return axios.patch(
        `${window.API}/venues/${venue}/carbon-impact`,
        values,
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    ).catch(error => {
        return Promise.reject(error)
    });
};

const fetchCarbonImpact = ({venue }, token) => {
    return axios.get(
        `${window.API}/venues/${venue}/carbon-impact`,
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    ).catch(error => {
        return Promise.reject(error)
    });
};

const fetchCarbonImpactAccreditation = (token) => {
    return axios.get(
        `${window.API}/carbon-impact-accreditations`,
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    ).catch(error => {
        return Promise.reject(error)
    });
};

const updateEventCarbonImpact = (event, values, token) => {
    return axios.post(
        `${window.API}/event/${event}/update-carbon-impact`,
        values,
        {
            headers: { Authorization: `Bearer ${token}` },
        },
    ).catch(error => {
        return Promise.reject(error)
    });
};


export { saveCarbonImpact, fetchCarbonImpact, fetchCarbonImpactAccreditation, updateEventCarbonImpact};
