import { calculateVat as realCalcVat } from "../../../../../utils";

function calculateVat(total, vatRate, price_includes_vat) {
  if (price_includes_vat) {
    return 0;
  } else {
    return realCalcVat(price_includes_vat, total, vatRate).vat;
  }
}

function calculateTotalWithVat(total, vatRate, price_includes_vat) {
  if (price_includes_vat) {
    return total;
  } else {
    return realCalcVat(price_includes_vat, total, vatRate).gross;
//    return parseFloat(total * (1 + (vat / 100))).toFixed(2);
  }
}

function calculatePricePerNightPerPerson(delegates, netPrice, vatPrice, noOfNights)
{

  return parseFloat(delegates) * parseFloat(Number(netPrice) + Number(vatPrice)) * parseFloat(noOfNights);
}


function calculatePricePerNightPerPersonTotalFromUnit(valuesFromForm, delegatesKey, unitPrice, noOfNights) {
  return Number(valuesFromForm[`${delegatesKey}`]) * Number(unitPrice) * Number(noOfNights);
}

function calculateAndSetPricePerNightPerPersonFinalTotalFromNetAndVatUnits(valuesFromForm, delegatesKey, netPrice, vatPrice, noOfNights, includedInPackage, currentTotal) {

  let total = calculatePricePerNightPerPerson(valuesFromForm[`${delegatesKey}`], netPrice, vatPrice, noOfNights);
  if (!includedInPackage) {
    currentTotal[`${delegatesKey}`] = parseFloat(total);
  }
  return total;

}


//function calculatePricePerNightPerPersonNetTotal(valuesFromForm, delegatesKey, price, noOfNights) {
//  return calculatePricePerNightPerPersonTotalFromUnit(valuesFromForm, delegatesKey, price, noOfNights);
//}

//function calculatePricePerNightPerPersonVat(valuesFromForm, delegatesKey, price, noOfNights, vatRate, priceIncludesVat) {
//  let total = parseFloat(valuesFromForm[`${delegatesKey}`]) * parseFloat(price) * parseFloat(noOfNights);
//  return calculateVat(total, vatRate, priceIncludesVat);
//}

function calculatePricePerNightPerPersonFinalTotal(valuesFromForm, delegatesKey, price, noOfNights, vatRate, priceIncludesVat, includedInPackage, currentTotal) {

  let total = parseFloat(valuesFromForm[`${delegatesKey}`]) * parseFloat(price) * parseFloat(noOfNights);
  if (!includedInPackage) {
    currentTotal[`${delegatesKey}`] = parseFloat(calculateTotalWithVat(total, vatRate, priceIncludesVat));
  }
  return calculateTotalWithVat(total, vatRate, priceIncludesVat);

}

function calculateSupplementaryItemMeetingRoomVat(priceIncludesVat, price, vatRate) {
  return calculateVat(price, vatRate, priceIncludesVat);
}

function calculateSupplementaryItemMeetingRoomTotal(priceIncludesVat, price, id, currentTotal, vatRate) {

  currentTotal[`meeting_rooms_${id}`] = calculateTotalWithVat(price, vatRate, priceIncludesVat);
  return calculateTotalWithVat(price, vatRate, priceIncludesVat);
}

function calculateNetTotal(delegatesKey, pricePerDelegate, valuesFromForm) {
  return parseFloat(valuesFromForm[`${delegatesKey}`]) * parseFloat(pricePerDelegate);
}

function calculateVatTotal(priceIncludesVat, delegatesKey, pricePerDelegate, valuesFromForm, vatRate) {
  let total = parseFloat(valuesFromForm[`${delegatesKey}`]) * parseFloat(pricePerDelegate);
  return calculateVat(total, vatRate, priceIncludesVat);
}

function calculateFinalTotal(priceIncludesVat, delegatesKey, pricePerDelegate, valuesFromForm, currentTotal, vatRate) {

  let total = parseFloat(valuesFromForm[`${delegatesKey}`]) * parseFloat(pricePerDelegate);
  currentTotal[`${delegatesKey}`] = calculateTotalWithVat(total, vatRate, priceIncludesVat);
  return calculateTotalWithVat(total, vatRate, priceIncludesVat);
}

function gatherTotals(totals, setGrandTotal, passTotals = null) {
  var sumOfTotals = 0;
  if (totals) {
    for (const [, value] of Object.entries(totals)) {

      sumOfTotals += parseFloat(value);
    }
  }
  setGrandTotal(parseFloat(sumOfTotals).toFixed(2));
  if (passTotals) {
    passTotals(sumOfTotals);
  }
}


function calculateCommissionFromUnit(priceIncludesVat, netUnit, vatUnit, vatRate, multiplier, commissionRate, appliedOn, delegatesKey = null, currentTotal) {

  let total = Number(netUnit);
  if (appliedOn !== "Net") {
    total += Number(vatUnit);
  }

  let commission = Number(total * (commissionRate / 100)).toFixed(2);

  if (priceIncludesVat) {
    commission = calculateTotalWithVat(commission, vatRate, !priceIncludesVat);
  }
  commission = commission * multiplier;

  if (delegatesKey) {
    currentTotal[`${delegatesKey}`] = commission;
  }

  return commission;
}

function calculateCommission(priceIncludesVat, netTotal, vatTotal, vatRate, rate, appliedOn, delegatesKey, currentTotal) {

  let total = Number(netTotal);
  if (appliedOn !== "Net") {
    total += Number(vatTotal);
  }
  let commission = Number(total.toFixed(2) * (rate / 100));
  if (priceIncludesVat) {
    commission = calculateTotalWithVat(commission, vatRate, !priceIncludesVat);
  }
  currentTotal[`${delegatesKey}`] = commission;
  return commission;
}

function calculateCommissionForCalculator(priceIncludesVat, total, vatRate, rate, appliedOn) {
  let commission = Number(total).toFixed(2) * (rate / 100);
  if (priceIncludesVat) {
    commission = calculateTotalWithVat(commission, vatRate, !priceIncludesVat);
  }
  return commission;
}


function getVatRateById(vatRates, vatRateId) {
  return vatRates.find(rate => Number(rate.value) === Number(vatRateId));
}

function calculateTotalAs(appliedOn, vatRates, vatFromForm, value) {
  let vat = vatRates.find((vatRate) => {
    return Number(vatRate.id) == Number(vatFromForm);
  })?.rate;
  if (appliedOn === "Gross") {
    let vatAsDecimal = (parseFloat(vat) / 100) + 1;
    let grossTotal = parseFloat(value) / parseFloat(vatAsDecimal);
    let vatTotal = parseFloat(value - grossTotal);
    return {
      net: Number(value - vatTotal).toFixed(2),
      vat: Number(vatTotal).toFixed(2),
      total: Number(value).toFixed(2),
    };
  } else {
    let vatAsDecimal = (parseFloat(vat) / 100) + 1;
    let grossTotal = parseFloat(value) * parseFloat(vatAsDecimal);
    let vatTotal = parseFloat(value) * (parseFloat(vat) / 100);
    return {
      net: Number(value).toFixed(2),
      vat: Number(vatTotal).toFixed(2),
      total: Number(grossTotal).toFixed(2),
    };
  }
}

function calculateCommissionAs(incVat, vatRates, vatFromForm, includesVatFromForm, value, onChange = false) {
  let vat = vatRates.find((vatRate) => {
    return Number(vatRate.id) == Number(vatFromForm);
  })?.rate;
  if (includesVatFromForm) {
    const rcv = realCalcVat(incVat, value, vat);
    rcv.net = rcv.net.toFixed(2);
    rcv.vat = rcv.vat.toFixed(2);
    rcv.total = rcv.gross.toFixed(2);
    return rcv;
  }
  return {
    net: Number(value).toFixed(2),
    vat: 0.00,
    total: Number(value).toFixed(2),
  };
}

export
{
  calculateVatTotal,
  calculateFinalTotal,
  calculateNetTotal,
  calculateCommission,
  calculateCommissionFromUnit,
  gatherTotals,
  calculatePricePerNightPerPersonTotalFromUnit,
  calculateAndSetPricePerNightPerPersonFinalTotalFromNetAndVatUnits,
//  calculatePricePerNightPerPersonNetTotal,
//  calculatePricePerNightPerPersonVat,
  calculatePricePerNightPerPersonFinalTotal,
  calculateSupplementaryItemMeetingRoomTotal,
  calculateSupplementaryItemMeetingRoomVat,
  calculateVat,
  calculateTotalWithVat,
  getVatRateById,
  calculatePricePerNightPerPerson,
  calculateTotalAs,
  calculateCommissionAs,
  calculateCommissionForCalculator,
};
