import React from "react";
import { number } from "prop-types";
import { getStatusIcon, getStatusPrettyName } from '../../../../../modules/rfp/enquiryVenueDataStatus';


const EnquiryStatus = (props) =>  {
    return(
      <span className="ml-3">
        {getStatusPrettyName(props.status)}
      </span>
    )
}

EnquiryStatus.propTypes = {
    status: number,
};
export default EnquiryStatus;
