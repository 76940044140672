import React, {useEffect, useState} from 'react';
import {ButtonGrey, ButtonPrimary} from "../../../../Components/Button";
import {Input} from "../../../../Components/Form";

const TemplateFilters = ({filterBookedEvents}) => {
  const [term, setTerm] = useState("");
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    filterBookedEvents({})
  }, []);

  useEffect(() => {
    setQueryParams({
      'search': term,
      'page': 1,
    })
  }, [term]);

  const resetFilters = () => {
    setTerm('')
    setQueryParams({})
    filterBookedEvents()
  }

  return (
    <>
      <div
        className="flex flex-col lg:flex-wrap lg:flex-row justify-between align-bottom p-3 bg-white mb-3 lg:items-end">
        <div className="mb-2 xxl:mb-0">
          <Input
            input={{
              onChange: e => setTerm(e.target.value),
              value: term,
            }}
            meta={{}}
            name="search"
            label="Search Term"
            labelClassName="block uppercase font-heading text-xs mb-1"
            wrapperClassName="mb-0"
          />
        </div>
        <div className="mb-2 xxl:mb-0">
          <ButtonPrimary classes="mb-0" onClick={() => filterBookedEvents(queryParams)}>
            Filter
          </ButtonPrimary>
        </div>
        <div className="mb-2 xxl:mb-0">
          <ButtonGrey classes="mb-0" onClick={resetFilters} title="Reset filters">
            <i className="fal fa-redo"></i>
          </ButtonGrey>
        </div>
      </div>
    </>
  );
}

export default TemplateFilters;
