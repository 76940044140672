    import {currencyFormatter} from "../utils";
import priceBanding from "../pages/admin/EditVenue/ConferencePricing/PriceBanding";

let prettifyPriceBandingTitle = (pricingBand, separator = ':') => {
    if(!pricingBand) {
        return '';
    }
    if(pricingBand.min_price === '0.00')
    {
        return pricingBand.band_title + `${separator} Under `  + currencyFormatter('GBP').format(pricingBand.max_price)
    }
    if(!pricingBand.max_price)
    {
        return pricingBand.band_title + `${separator} `  + currencyFormatter('GBP').format(pricingBand.min_price) + ' plus';
    }

    return pricingBand.band_title + `${separator} `  + currencyFormatter('GBP').format(pricingBand.min_price) + ' - ' + currencyFormatter('GBP').format(pricingBand.max_price)
}

let prettifyInternationalPriceBandingTitle = (pricingBand) => {
    if(!pricingBand) {
        return '';
    }
    switch(pricingBand.band_code)
    {
        case 'A':
            return pricingBand.band_title + ` - $ (budget)`;
        case 'B':
            return pricingBand.band_title + ` - $$ (economical)`;
        case 'C':
            return pricingBand.band_title + ` - $$$ (mid-range)`;
        case 'D':
            return pricingBand.band_title + ` - $$$$ (premium)`;
        case 'E':
            return pricingBand.band_title + ` - $$$$$ (luxury)`;
    }
    return pricingBand.band_title;
}


export { prettifyPriceBandingTitle, prettifyInternationalPriceBandingTitle}