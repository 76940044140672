import React from 'react';
import {Input} from "../../../../../ui";
import {Field} from "redux-form";

let CarbonImpactEventInformation = ({proposalData}) => {
    return (
        <div>
            <Field
                name="estimated_carbon_impact_value"
                component={Input}
                readOnly={true}
                additionalReadonlyClass={' text-sm py-1 h-auto bg-light-grey'}
                label={'Estimated Carbon Impact (C02e)'}
            />
            <Field
                name="total_carbon_impact_value"
                component={Input}
                additionalReadonlyClass={' text-sm py-1 h-auto bg-light-grey'}
                label={'Final Carbon Impact (C02e)'}
            />
        </div>
    )

}

export default CarbonImpactEventInformation