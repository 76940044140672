import React, {useState, useEffect} from 'react'
import {currencyFormatter} from "../../utils";
import {ButtonSpan} from "../Button";
import CarbonImpactModal from "./CarbonImpactModal";
import {fetchCarbonImpact} from "../../services/venueService/carbonImpactService";
import {token} from "../../modules/auth";
import { connect } from "react-redux";
const mapStateToProps = state => ({
    token: token(state),
});

let CarbonImpactForCard = ({proposalData, venue, token}) => {

    const [modal, setModal] = useState(false);
    
    let [venueCarbonImpact, setVenueCarbonImpact] = useState({})

    useEffect(() => {
        fetchCarbonImpact({venue: venue.slug}, token).then((response) => {
            setVenueCarbonImpact(response.data)
        })
    }, []);


    if (typeof proposalData.carbon_impact === 'undefined' || !proposalData.carbon_impact) {
        return <></>;
    }
    if(typeof venueCarbonImpact.has_valid_carbon_impact === 'undefined' || !venueCarbonImpact.has_valid_carbon_impact)
    {
        return <></>;
    }

    return (
        <div className="p-5 border-2 border-carbon  bg-white text-carbon">
            <div className={'mb-5'}>
                <span className="font-bold">Estimated Carbon Footprint</span>
            </div>
            <div className={'flex justify-between items-center'}>
                <span className="text-xl font-bold">{proposalData.carbon_impact.carbon_impact_value_total} C02e tonnes</span>
                <span className="text-lg">
                    <ButtonSpan className="text-base font-normal underline ml-2" onClick={() => setModal(true)}>view</ButtonSpan>
                </span>
            </div>
            {modal && (
                <CarbonImpactModal
                    onClose={() => setModal(false)}
                    carbonImpact={proposalData.carbon_impact}
                    venue={venue}
                    offsetStatement={venueCarbonImpact.offset_statement}
                    accreditations={venueCarbonImpact.accreditations}
                />
            )}
        </div>
    )
}

export default connect(mapStateToProps, null)(CarbonImpactForCard);