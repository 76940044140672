import Modal from "../Modal";
import React, {useState} from "react";
import {ButtonPrimaryRed, ButtonSpan} from "../Button";
import {Textarea, Toggle} from "../Form";
import { Field, reduxForm } from "redux-form";
import useService from "../../hooks/useService";
import {required} from "../../modules/validation";
import {cancelRFP, uncancelRFP} from "../../services/rfpService/cancelRFP";
import Loading from "../Loading";
import {isAgency, isInternal, token} from "../../modules/auth";
import {connect} from 'react-redux';
import {ENQUIRY_STATUS} from "../../constants";
import {ENQUIRY_VENUE_DATA_STATUS} from "../../modules/rfp/enquiryVenueDataStatus";

const form = "rfp-cancel";
const v = {
    required: required(),
};

const mapStateToProps = (state, props) => {
    return {
        isInternal: isInternal(state),
        isAgency: isAgency(state),
        token: token(state),
        initialValues: {
            cancellation_reason: props.enquiry.brief.cancellation_reason,
            cancellation_charges_apply: props.enquiry.event.cancellation_charges_apply ? props.enquiry.event.cancellation_charges_apply : false,
            cancellation_agency_note: props.enquiry.brief.cancellation_agency_note
        },
    };
};


const Wrapper = ({ children, onClose }) => (
    <Modal>
        <div className="modal">
            <div
                className="modal-inner"
                style={{
                    width: 1200,
                    maxWidth: "90%",
                }}
            >
                <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
                    <i className="fas fa-times-circle fa-2x ml-3" />
                </ButtonSpan>
                {children}
            </div>
        </div>
    </Modal>
);

let RFPCancelModalForm = ({onClose, enquiry, handleSubmit, isInternal, isAgency, token}) => {
    const [submitData, setSubmitData] = useState(null);
    const [withoutEmails, setWithoutEmails] = useState(false);

    const { loading: submitting, data: data, error } = useService({
        initialLoading: false,
        service: cancelRFP,
        trigger: submitData,
        params: submitData,
    });
    const handleCancel = (values) => {
        setSubmitData({
            cancellationReason: values.cancellation_reason,
            agencyNote: values.cancellation_agency_note ? values.cancellation_agency_note : null ,
            chargesApply: values.cancellation_charges_apply,
            withoutEmails : withoutEmails,
            rfpId: enquiry.id,
        });
    };

    if (data) {

        return (
            <Wrapper onClose={onClose}>
                <p className="text-xl mb-5">
                    Thank you.
                    <br />
                    This event has been cancelled
                </p>
                { (isAgency || isInternal) && data.length > 0  ?
                    <div>
                        <p>The following venues do not have emails and will need to be contacted separately</p>

                        { data.map((missingVenue) => {
                            return <p>{missingVenue}</p>
                        })}
                    </div>
                    :
                    null
                }

            </Wrapper>
        );
    }
    let confirmData = enquiry ? enquiry.enquiry_venue_datas.find((evd) => evd.status === ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED) : null;

    return (
        <Wrapper onClose={onClose}>
            <form className="relative pb-16" onSubmit={handleSubmit(handleCancel)}>
                <h1 className="mb-8">Are you sure you want to cancel your booking?</h1>
                {confirmData && confirmData.proposal_data.commission_items.length > 0 ? <div className="bg-warning p-4 mb-5 block font-bold text-white"><p className="mb-0"><i className="fas text-2lg fa-exclamation-triangle mr-3"></i> This event has been confirmed, so charges may apply.</p></div> : null}

                <Field
                    label="Please advise your reason for cancelling"
                    name="cancellation_reason"
                    rows={7}
                    component={Textarea}
                    validate={v.required}
                />
                {
                    (isInternal || isAgency) && confirmData && confirmData.proposal_data.commission_items.length > 0 ?
                        <Field
                            name="cancellation_charges_apply"
                            label="Cancellation charges apply"
                            component={Toggle}
                            wrapperClassName="mb-8 flex-grow"
                        />
                        :
                        null
                }
                {
                    (isInternal || isAgency)  ?
                        <Field
                            label="Agency Note"
                            name="cancellation_agency_note"
                            rows={7}
                            component={Textarea}
                        />
                        :
                        null
                }

                <p className="text-dark-red font-bold text-right">We will now cancel your booking and release any venue space we are holding</p>

                <div className="absolute pin-b pin-r">
                    <div className={'flex'}>
                        {
                            isInternal && !confirmData &&  enquiry.brief.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED ?
                                <ButtonPrimaryRed
                                    type="submit"
                                    classes="px-5 mx-5"
                                    style={{ height: 57, lineHeight: "57px" }}
                                    onClick={(e) => {

                                        setWithoutEmails(true);
                                        // handleSubmit(handleCancel)
                                    }}
                                >
                                    {submitting ? <Loading inline/> :   "Confirm Cancellation Request (without emails)" }
                                </ButtonPrimaryRed>
                                :
                                null
                        }
                        {
                            isInternal && enquiry.brief.enquiry_status.code == ENQUIRY_STATUS.CANCELLED ?
                                <ButtonPrimaryRed
                                    classes="px-5 mx-5"
                                    style={{ height: 57, lineHeight: "57px" }}
                                    onClick={(e) => {
                                        uncancelRFP(enquiry.id, token).then(r => {
                                            window.location.reload();
                                        })
                                        // handleSubmit(handleCancel)
                                    }}
                                >
                                    {submitting ? <Loading inline/> :   "Uncancel RFP" }
                                </ButtonPrimaryRed>
                                :
                                null
                        }

                        <ButtonPrimaryRed
                            type="submit"
                            classes="px-5"
                            style={{ height: 57, lineHeight: "57px" }}
                            onClick={(e) => {
                                setWithoutEmails(false);
                                // handleSubmit(handleCancel)
                            }}
                        >
                            {submitting ? <Loading inline/> :  enquiry.brief.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED ?  "Confirm Cancellation Request" : "Edit Cancellation Info" }
                        </ButtonPrimaryRed>
                    </div>

                </div>
            </form>
        </Wrapper>
    );
}

RFPCancelModalForm = reduxForm({
    form: form,
    enableReinitialize: true
})(RFPCancelModalForm);

export default connect(mapStateToProps, null)(RFPCancelModalForm);