import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import FeaturedSection from "Components/FeaturedSection";
import Input from "../Components/Form/Input";
import { fetchCountries } from "../redux/actions";
import { countriesOptions } from "../modules/countries-options";
import { Select } from "../ui";

const WorldwideVenueLocations = ({ fetchCountries, countries }) => {
  const [location, setLocation] = useState("");
  const [country, setCountry] = useState();
  const filteredCountriesRef = useRef([]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const filterCities = useCallback(
    (city) => {
      const lowerCaseCity = city.city.toLowerCase();
      const lowerCaseLocation = location ? location.toLowerCase() : "";

      if (country) {
        if (!location) {
          return city.country_id == country;
        }
        return (
          city.country_id == country &&
          lowerCaseCity.includes(lowerCaseLocation)
        );
      }

      if (location) {
        return lowerCaseCity.includes(lowerCaseLocation);
      }

      return true;
    },
    [country, location],
  );

  const onDestinationsFetch = (data) => {
    const locationCountryIds = new Set(
      data.map((location) => location.country_id),
    );
    if (Array.isArray(countries) ? countries.length > 0 : Object.keys(countries).length > 0) {
      filteredCountriesRef.current = countries.filter((country) =>
        locationCountryIds.has(country.id),
      );
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col col-w-full md:col-w-1/3">
          <h1 className="mt-8 mb-5">Worldwide Venue Locations</h1>
        </div>
        <div className="col col-w-full md:col-w-1/3">
          <Select
            name="country"
            placeholder={"Search Country"}
            label=""
            component={Select}
            options={countriesOptions(filteredCountriesRef.current.length > 0 ? filteredCountriesRef.current : countries)}
            wrapperClassName="mt-8"
            input={{
              onChange: (e) => setCountry(e.target.value),
              value: country,
            }}
            meta={{}}
          />
        </div>
        <div className="col col-w-full md:col-w-1/3">
          <Input
            input={{
              onChange: (e) => setLocation(e.target.value),
              value: location,
            }}
            labelClassName="sr-only"
            iconRight="/img/search-icon.svg"
            meta={{}}
            name="term"
            label={`Search Locations`}
            placeholder={`Search Locations`}
            wrapperClassName="mt-8 mb-5 w-full"
          />
        </div>
      </div>

      <FeaturedSection
        url={`${window.API}/destinations-list?worldwide=1`}
        sort={(a, b) => {
          const textA = a.city.toUpperCase();
          const textB = b.city.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }}
        filter={filterCities}
        onDataFetch={onDestinationsFetch}
      />
      <div className="mb-5" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.countries,
  };
};

const mapDispatchToProps = {
  fetchCountries,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(WorldwideVenueLocations));
