import React from "react";
import SavedSearchesDisplay from "Components/SavedSearches/Display";

class SavedSearches extends React.Component {
  render() {
    return (
      <div className="max-w-sm py-8">
        <h1 className="mb-5">Your Saved Searches</h1>
        <SavedSearchesDisplay />
      </div>
    );
  }
}

export default SavedSearches;
