import React, { Component } from "react";
import {
  fetchCompany,
  fetchCountries,
  companyUnload,
  fetchRoles,
  fetchCorporateRoles,
  fetchCompanyBranches, unloadCompanyBranches, fetchBookingDesks, unloadAgencyBookingDesks,
} from "../../../redux/actions";
import { connect } from "react-redux";
import { isAgency, token } from "../../../modules/auth";
import CompanyEdit from "./CompanyEdit";
import { fetchIndustries } from "../../../modules/industries";
import { fetchCompanyUserInvites } from "../../../redux/actions/corporateInvites";
import startCase from "lodash/startCase";
import { isCompanyAdmin, isInternal } from "../../../modules/permissions";

const mapStateToProps = state => {
  return {
    ...state.company,
    ...state.countries,
    ...state.roles,
    ...state.industries,
    ...state.userInvites,
    ...state.companyBranches,
    ...state.agencyBookingDesks,
    auth: state.auth,
    isCompanyAdmin: isCompanyAdmin(state.auth),
    isAgency: isAgency(state),
    isInternal: isInternal(state.auth),
    token: token(state),
    isFetchingCompany: state.company.isFetchingCompany,
    isFetchingCompanyBranches: state.companyBranches.isFetchingCompanyBranches,
  };
};

const mapDispatchToProps = {
  fetchCompany,
  fetchCountries,
  fetchIndustries,
  companyUnload,
  fetchRoles,
  fetchCorporateRoles,
  fetchCompanyUserInvites,
  fetchCompanyBranches,
  unloadCompanyBranches,
  fetchBookingDesks,
  unloadAgencyBookingDesks
};

class CompanyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserRoles: [],
    };
  }

  componentDidMount() {
    this.props.fetchRoles(this.props.token);
    this.props.fetchCorporateRoles(this.props.token);
    let companyId = typeof this.props.match.params.id === "undefined" ? this.props.auth.user.company_id ? this.props.auth.user.company_id : null : this.props.match.params.id;
    this.props.fetchCompany(companyId, this.props.token).then((response) => {
      if (response.data.data.agency_id) {
        this.props.fetchBookingDesks(response.data.data.agency_id, this.props.token);
      }
      let cur = response.data.data.users.sort((a, b) => a.email.localeCompare(b.email)).map((user) => {
        return [
          {
            user: user.id,
            roles: user.corporate_roles.map((role) => {
              return {
                label: startCase(role),
                value: role,
              };
            }),
          },
        ];
      });
      this.setState({ currentUserRoles: cur });
    });
    this.props.fetchCountries(this.props.token);
    this.props.fetchIndustries(this.props.token);
    this.props.fetchCompanyUserInvites(companyId, this.props.token);
    this.props.fetchCompanyBranches(companyId, this.props.token);
  }

  componentWillUnmount() {
    this.props.companyUnload();
    this.props.unloadCompanyBranches();
    this.props.unloadAgencyBookingDesks();
  }

  render() {
    const {
      company,
      isFetchingCompany,
      companyBranches,
      isFetchingCompanyBranches,
      countries,
      roles,
      industries,
      userInvites,
      corporateRoles,
      isCompanyAdmin,
      isAgency,
      isInternal,
      agencyBookingDesks,
    } = this.props;
    return (
      <div>
        <CompanyEdit
          company={company}
          userInvites={userInvites}
          isFetchingCompany={isFetchingCompany}
          countries={countries}
          roles={roles}
          corporateRoles={corporateRoles}
          industries={industries}
          isCompanyAdmin={isCompanyAdmin}
          isAgency={isAgency}
          isInternal={isInternal}
          currentUserRoles={this.state.currentUserRoles}
          companyBranches={companyBranches}
          isFetchingCompanyBranches={isFetchingCompanyBranches}
          agencyBookingDesks={agencyBookingDesks}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainer);
