import React, { useCallback, useEffect, useState } from "react";
import qs from "query-string";
import { getWidth } from "redux-window";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { reduxForm, Field, getFormValues } from "redux-form";
import { token, isLoggedIn, updateShortlist, shortlists } from "modules/auth";
import { Select } from "Components/Form";
import Loading from "Components/Loading";
import { ButtonPrimary } from "Components/Button";
import RequestForQuote from "Components/RequestForQuote";
import { stateToReferCode } from "modules/brand";
import { user, isAgent, isInternal } from "modules/auth";
import { createHandleAxiosError } from "modules/api-tools";
import { venueInteraction } from "modules/analytics";
import Slider from "Components/Slider";
import VenueSlide from "Components/VenueSlide";
import {VENUE_TAGS} from "../modules/analytics/tags";

const CompareVenues = ({
  brand,
  canRequestOver5,
  handleSubmit,
  history,
  location,
  loggedIn,
  referCode,
  shortlists,
  sortBy,
  token,
  trackInteraction,
  updateShortlistAction,
  user,
  visibleVenues,
  isInternal
}) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedVenues, setSelectedVenues] = useState([]);

  useEffect(() => {
    const query = qs.parse(location.search);
    if (query.venues) {
      query.venues.split(',').map(venue => {
        trackInteraction({
          type: VENUE_TAGS.COMPARE,
          id: venue
        })
      })
    }
  }, [])

  const params = useCallback(() => {
    const query = qs.parse(location.search);
    return {
      venues: (query.venues || "").split(",").filter(v => !isNaN(v)).map(v => parseInt(v)),
      name: query.name,
      id: query.id ? parseInt(query.id, 10) : null,
    };
  }, [location.search]);

  const updateShortlist = (shortlistId, venueId) => {
    const original = shortlists.find(s => s.id === shortlistId);
    const payload = {
      venues: original.venues.filter(
        v => parseInt(v.id, 10) !== parseInt(venueId, 10)
      ),
    };

    const handleAxiosError = createHandleAxiosError({history, location});

    return axios
      .patch(`${window.API}/users/me/shortlists/${shortlistId}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        updateShortlistAction(response.data.id, response.data);
      })
      .catch(handleAxiosError());
  };

  const handleClickQuote = () => {
    if (!canRequestOver5 && selectedVenues.length > 5) {
      alert(
        "This quote request allows a maximum of 5 venues to be selected, please refine your request."
      );
    } else {
      history.push(`/request-for-proposal?venues=${selectedVenues.join(",")}`);
    }
  };

  const handleSelectVenue = id => {
    if (selectedVenues.includes(id)) {
      setSelectedVenues(selectedVenues.filter(venue => venue !== id));
    } else {
      setSelectedVenues([...selectedVenues, id]);
    }
  };

  const handleBuyItNow = slug => {
    history.push(`/venue/${slug}?buy-it-now`);
  };

  const handleShareComparison = () => {
    data.forEach(venue =>
      trackInteraction({
        type: VENUE_TAGS.VENUE_SHARED.SHARE_COMPARISON,
        id: venue.id,
        data_score: venue.data_score,
      })
    );
  };

  const handleRemove = id => {
    const query = qs.parse(location.search);
    const venues = query.venues.split(",");
    venues.splice(venues.indexOf(id), 1);
    history.push(
      `${location.pathname}?${qs.stringify({
        ...query,
        venues: venues.join(","),
      })}`
    );
    if (loggedIn && params().id) {
      updateShortlist(params().id, id);
    }
  };

  const shareEmailBody = () => {
    const comparisonOf = params().name || "venues";

    const sharedBy =
      user && user.first_name ? `${user.first_name} ${user.surname}` : "";
    const venuesSearch = window.location.search;

    const url =
      isInternal ? window.location.origin + '/compare-venues-feedback' + venuesSearch + (referCode ? `&referrer=` + referCode : "") :
        window.location.origin + '/compare-venues' + venuesSearch + (referCode ? `&referrer=` + referCode : "");

    const from =
      brand && brand.company_name
        ? `${brand.company_name}, powered by EDGE Venues`
        : "EDGE Venues";

    const content = isInternal
      ? `Please provide ${sharedBy} with your feedback on the suggested venue shortlist previously shared with you.
      
Shortlist with venues removed: ${url}

Your feedback:`
      : `A comparison of ${comparisonOf} has been shared with you ${sharedBy}.

  ${url}

${from}`;

    return encodeURIComponent(content);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  useEffect(() => {
    const getVenues = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`${window.NODE_AS}/compare`, {
          params: {
            venues: params().venues,
            sortBy: sortBy,
          },
        });
        setLoading(false);
        setError(null);
        setData(response.data);
      } catch (err) {
        setLoading(false);
        setError(true);
      }
    };
    setSelectedVenues(params().venues);
    const venues = params().venues;
    if (venues.length) {
      getVenues();
    }
  }, [params, sortBy]);

  const slides = data.map(venue => () => {
    return (
      <VenueSlide
        venue={venue}
        onBuyItNow={handleBuyItNow}
        onSelectVenue={handleSelectVenue}
        onRemove={handleRemove}
        selectedVenues={selectedVenues}
      />
    );
  });

  let showing = visibleVenues < data.length ? visibleVenues : data.length;

  // on desktop if we remove the sides we show 4
  if (visibleVenues === 3 && data.length === 4) {
    showing = 4;
  }

  return (
    <div className="bg-white">
      <form className="container py-5" onSubmit={handleSubmit(() => {})}>
        {loading ? (
          <Loading large />
        ) : error ? (
          <p className="font-normal text-2xl text-warning">
            Sorry there was an error fetching the data
          </p>
        ) : (
          <div>
            <div className="md:flex justify-between items-end">
              <div>
                <h1 className="text-4xl mb-3">Compare venues</h1>
                <p>
                  Showing{" "}
                  <b>
                    {showing} of {data.length}
                  </b>{" "}
                  venues
                </p>
              </div>
              <div className="flex flex-col items-end">
                <a
                  className="flex items-center text-black uppercase font-heading"
                  href={`mailto:?subject=Venues comparison&body=${shareEmailBody()}`}
                  onClick={handleShareComparison}
                >
                  <div className="mr-2 border-2 border-black w-10 h-10 flex items-center justify-center rounded-100">
                    <i className="fas fa-share text-2xl"></i>
                  </div>
                  Share venues
                </a>
                <Field
                  name="sortBy"
                  label="Sort By"
                  placeholder="Sort by..."
                  wrapperClassName="w-48 my-3"
                  labelClassName="sr-only"
                  options={[
                    "Capacity",
                    "Bedrooms",
                    "Meeting rooms",
                    "Buy it now",
                    "24hr DR",
                    "DDR",
                  ]}
                  component={Select}
                />
              </div>
            </div>
            <Slider slides={slides} useSwiper={false} />
            <div className="flex justify-center mt-5">
              <ButtonPrimary
                classes={`px-6 py-2 flex items-center ${
                  selectedVenues.length ? "" : "opacity-50"
                }`}
                onClick={selectedVenues.length ? handleClickQuote : () => {}}
                style={{ height: "auto" }}
              >
                Get a quote on selected venues{" "}
                <i className="hidden sm:inline ml-5 fal fa-chevron-circle-right text-3xl"></i>
              </ButtonPrimary>
            </div>
          </div>
        )}
        {modal ? (
          <RequestForQuote
            closeModal={handleCloseModal}
            initialValues={{
              type: "General",
              venues: selectedVenues,
              venues_text: data
                .filter(v => selectedVenues.includes(v.id))
                .map(v => v.name),
            }}
          />
        ) : null}
      </form>
    </div>
  );
};

export default withRouter(
  reduxForm({
    form: "compare",
  })(
    connect(
      state => {
        const v = getFormValues("compare")(state) || {};
        const width = getWidth(state);
        let visibleVenues = 1;
        if (width > 767 && width < 1199) {
          visibleVenues = 2;
        } else if (width >= 1200) {
          visibleVenues = 3;
        }
        return {
          canRequestOver5: isAgent(state) || isInternal(state),
          sortBy: v.sortBy,
          loggedIn: isLoggedIn(state),
          token: token(state),
          brand: state.brand,
          user: user(state),
          shortlists: shortlists(state),
          referCode: stateToReferCode(state),
          visibleVenues,
          isInternal: isInternal(state)
        };
      },
      {
        updateShortlistAction: updateShortlist,
        trackInteraction: venueInteraction,
      }
    )(CompareVenues)
  )
);
