import React from "react";
import { getWidth } from "redux-window";
import { useSelector } from "react-redux";
import classNames from "classnames";

/**
 * stages: [{ name: 'Stage name', complete: true/false }]
 * currentStage is a number - the index - so if on stage 1 it'll be 0
 *
 * You'll see it updated at http://localhost:3000/request-for-proposal?venues=18437,1876,17045,1617,7332,25010
 * pages/Rfp/Rfp.js
 *
 * ...this component should never have to calculate the current page,
 * the complete state of each stage, it just takes data and renders a bar
 <i className="fas fa-check-circle"></i>
 <i className="far fa-circle"></i>
 */
const ProgressBar = ({ breakpoint = 600, currentStage, stages, navId = false }) => {
  const width = useSelector(getWidth);

  return (
    <nav className="flex justify-between mb-5" id={navId !== false ? navId : '' }>
      {stages.map((item, i) => {
        const active = currentStage === i;

        if (width < breakpoint) {
          return (
            <div
                key={i+1}
              onClick={!active ? item.onClick : undefined}
              className={classNames("p-3", {
                "bg-black text-white": active,
                "text-brand font-bold": !active && item.complete,
                pointer: !active && item.onClick,
              })}
            >
              {i + 1}
            </div>
          );
        }

        return (
          <div key={item.name} className="flex-grow text-center">
            <div className="flex justify-center items-center h-8">
              <div
                className="bg-black flex-grow -mr-px"
                style={{ height: "0.125rem" }}
              />
              <i
                className={classNames({
                  "fal fa-exclamation-circle fa-lg text-warning": item.error,
                  "fas fa-check-circle fa-lg text-brand":
                    !item.error && item.complete,
                  "far fa-circle": !item.error && !item.complete,
                })}
              />
              <div
                className="bg-black flex-grow -ml-px"
                style={{ height: "0.125rem" }}
              />
            </div>
            <div
              onClick={!active ? item.onClick : undefined}
              className={classNames("py-3 px-5 inline-block uppercase", {
                "text-warning font-bold": item.error,
                "bg-black text-white": !item.error && active,
                "text-brand font-bold": !item.error && !active && item.complete,
                pointer: !active && item.onClick,
              })}
            >
              {i + 1}. {item.name}
            </div>
          </div>
        );
      })}
    </nav>
  );
};

export default ProgressBar;
