import convertPriceToSourceCurrencyValue from "../../../../../helpers/convertPriceToSourceCurrencyValue";

const getTerms = v => {
  switch (v) {
    case 1:
      return "allocation";
    case 2:
      return "contract";
    default:
      return "";
  }
};
const getRoomType = v => {
  switch (v) {
    case 1:
      return "Single";
    case 2:
      return "Double";
    case 3:
      return "Twin";
    case 11:
      return "Suite";
    case 9:
      return "Family";
    default:
      return "";
  }
};

const mapAccommodationFromRfp = (accommodation = [], dateFromOverride, isSourceCurrency = false) => {
    return accommodation.map(item => {
      let mappedAccommodation = {
        id: item.id,
        date_of_arrival: dateFromOverride ? null : item.arrival_date,
        num_nights: item.no_of_nights,
        num_rooms: item.no_of_rooms,
        room_type: getRoomType(item.venue_bedroom_type_id),
        occupancy: item.occupancy,
        reduced_from: item.display_price_including_vat ?
            Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'price_reduced_from_net')) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'price_reduced_from_vat')) :
            convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'price_reduced_from_net'),
        include_vat: item.display_price_including_vat,
        cost: item.display_price_including_vat ?
            Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'price_net')) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'price_vat')) :
        convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'price_net'),
        saving: item.price_percentage_saving,
        breakfast_included: item.breakfast_included,

        breakfast_cost: item.breakfast_display_price_including_vat ?
            Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'breakfast_price_net')) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'breakfast_price_vat')) :
            convertPriceToSourceCurrencyValue(isSourceCurrency, item, 'breakfast_price_net'),
//        breakfast_reduced_from: item.breakfast_display_price_including_vat ? Number(item.breakfast_price_reduced_from_net) + Number(item.breakfast_price_reduced_from_vat) : item.breakfast_price_reduced_from_net,
        breakfast_vat_rate_id: item.breakfast_price_vat_rate_id,
        breakfast_saving: item.breakfast_price_percentage_saving,
        breakfast_include_vat: item.breakfast_display_price_including_vat,

        release_date: item.term_type_id === 1 ? item.release_date : null,
        rooms_allocated: item.term_type_id === 1 ? item.rooms_allocated : null,
        included_in_package: item.included_in_package,
        vat_rate_id: item.price_vat_rate_id,
        value: item.value,
      }
      if(item.term_type_id) {
        mappedAccommodation['terms'] =  getTerms(item.term_type_id);
      }

      if(item.cancellation_policy) {

        mappedAccommodation['cancellation_policy'] =  item.cancellation_policy;
      }
      return mappedAccommodation;
  });
};

export default mapAccommodationFromRfp;
