import React from "react";
import {LabelledText, WhitePanelWithHeader} from "ui";

const ManagedTrinityUser = ({name, email, phone}) => {
  return (
    <WhitePanelWithHeader title="Managed By" icon="far fa-user">
      <LabelledText label="Agent Name" text={name}/>
      <LabelledText label="Agent Email" text={email}/>
      <LabelledText label="Agent Phone" text={phone ? phone : "Not Provided"}/>
    </WhitePanelWithHeader>
  );
};

export default ManagedTrinityUser;
