import axios from 'axios';
import * as ROUTES from "../../Router/misc_routes";
import {
  VAT_RATES_LIST_REQUEST,
  VAT_RATES_LIST_RECEIVED,
  VAT_RATES_LIST_ERROR
} from "../reducers/types";
import handleErrors from "./utils";

export const vatRateRequest = () => ({
  type: VAT_RATES_LIST_REQUEST
});

export const vatRatesReceivedRequest = (data) => ({
  type: VAT_RATES_LIST_RECEIVED,
  data
});

export const vatRatesReceivedError = (error) => ({
  type: VAT_RATES_LIST_ERROR,
  error
});

export const fetchVatRates = (token) => {
  return (dispatch) => {
    dispatch(vatRateRequest());
    return axios.get(window.API + ROUTES.API.VAT_RATE.LIST, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(vatRatesReceivedRequest(response))
    })
      .catch(error => {
        dispatch(vatRatesReceivedError(error))
        handleErrors(error);
      });
  }
}