import React from "react";
import { bool, string } from "prop-types";
import classNames from "classnames";

export default class TrueFalseListItem extends React.Component {
  static propTypes = {
    name: string.isRequired,
    value: bool.isRequired,
  };

  render() {
    return (
      <li className="mb-3 leading-normal">
        {this.props.name}
        <i
          className={classNames("ml-2 fal", {
            "fa-check": this.props.value,
            "text-brand": this.props.value,
            "fa-times": !this.props.value,
            "text-warning": !this.props.value,
          })}
        />
      </li>
    );
  }
}
