import React, {useEffect, useState} from "react";
import SelectVisitedType from "./Forms/SelectVisitedType";
import SiteVisitReport from "./Forms/SiteVisitReport";
import AddContacts from "./Forms/AddContacts";
import AddMedia from "./Forms/AddMedia";
import SetReportOptions from "./Forms/SetReportOptions";
import Summary from "./Forms/Summary";
import ReportSubmitted from "./Components/ReportSubmitted";
import { connect } from "react-redux";
import { ProgressBar } from "ui";
import { reduxForm, reset } from "redux-form";
import { submitSiteVisitReport } from "services/siteVisitService";
import { token } from "modules/auth";
import moment from "moment";
import _get from "lodash/get";
import {isUndefined} from "lodash";
const reportSections = [
  {
    name: "Where Did You Visit?",
    component: SelectVisitedType,
  },
  {
    name: "Report Details",
    component: SiteVisitReport,
  },
  {
    name: "Add Contacts",
    component: AddContacts,
  },
  {
    name: "Add Media",
    component: AddMedia,
  },
  {
    name: "Report Options",
    component: SetReportOptions,
  },
  {
    name: "Summary",
    component: Summary,
  },
];

let CreateSiteVisitReport = props => {
  const [currentStage, setCurrentStage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);

  const CurrentSection = reportSections[currentStage].component;

  // Set completed status of each section to false as default
  const [completed, setCompleted] = useState(
    reportSections.map((section, index) => ({
      name: section.name,
      completed: false,
    }))
  );




  const nextPage = values => {
    if (currentStage + 1 === reportSections.length) {
      submitForm(values);
    }

    if (currentStage + 1 < reportSections.length) {
      completeSection(currentStage);
      setCurrentStage(currentStage + 1);
    }
  };

  const prevPage = () => {
    if (currentStage > 0) {
      setCurrentStage(currentStage - 1);
    }
  };

  const submitForm = values => {
    setLoading(true);
    setError(false);
    setErrors([]);
    // Transform values to conform to API
    let formValues = Object.assign({}, values);

    formValues.visit_date_from = moment(formValues.visit_date_from).format(
      "YYYY-MM-DD hh:mm:ss"
    );
    formValues.visit_date_to = moment(formValues.visit_date_to).format(
      "YYYY-MM-DD hh:mm:ss"
    );


    if(formValues.media_links && isUndefined(formValues.media_links[0].name) && isUndefined(formValues.media_links[0].path))
    {
      delete formValues.media_links;
    }

    if(formValues.media_links && !isUndefined(formValues.media_links[0].name) && !isUndefined(formValues.media_links[0].path)) {
      if(formValues.media_links[0].name.length === 0 && formValues.media_links[0].path.length === 0 )
      {
        delete formValues.media_links;
      }
    }

    if (formValues.media_links) {
      formValues.media_links[0].type = "youtube";
    }

    formValues.visited_id =
      formValues.visited_type === "venue"
        ? formValues.selected_venue.id
        : formValues.selected_destination.id;
    formValues.selected_venue = null;
    submitSiteVisitReport(formValues, props.token)
      .then(response => {
        setComplete(true);
        props.destroy();
      })
      .catch(e => {
        setError(true);
        let responseErrors = [];
        if (Number(e.response.status) === 422) {
          responseErrors = _get(e, "response.data.errors", {
            error: ["Oops something went wrong"],
          });
        } else {
          responseErrors = { error: ["Oops something went wrong"] };
        }

        let errorMessages = [];

        for (const [, value] of Object.entries(responseErrors)) {
          errorMessages.push(...value);
        }
        setErrors([...errorMessages]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const completeSection = currentIndex => {
    let newCompleted = [...completed];
    newCompleted[currentIndex].completed = true;
    setCompleted(newCompleted);
  };

  return (
    <div className="container">
      <h1 className="my-5">Submit new site visit report</h1>
      <div className="bg-white">
        <div className="p-6">
          <ProgressBar
            stages={reportSections.map((section, index) => ({
              name: section.name,
              complete: completed[index].completed,
            }))}
            currentStage={currentStage}
          />
          {!complete ? (
            <CurrentSection
              className="mt-12"
              hasNext={currentStage + 1 < reportSections.length}
              hasPrev={currentStage > 0}
              onSubmit={nextPage}
              onPrev={prevPage}
              loading={loading}
              validationError={error}
              errors={errors}
            />
          ) : (
            <ReportSubmitted />
          )}
        </div>
      </div>
    </div>
  );
};

CreateSiteVisitReport = reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
})(CreateSiteVisitReport);

export default connect(state => ({ token: token(state) }))(
  CreateSiteVisitReport
);
