import React, { useState } from 'react';

const ToggleRevealer = ({ toggleText = 'Toggle', children }) => {
  const [visible, setVisible] = useState(false);
  return (
      <div>
        <button className="text-sm font-heading bg-grey p-1 mb-2 border-0 focus:border-0 hover:border-0 focus:outline-none focus:bg-light-grey" onClick={() => {
          setVisible(!visible);
        }}>{toggleText}</button>
        {visible && children}
      </div>
  );
};

export default ToggleRevealer;
