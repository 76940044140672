import React from "react";
import { object, bool } from "prop-types";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { reduxForm, Field, SubmissionError } from "redux-form";
import Input from "Components/Form/Input";
import { ButtonBrand } from "Components/Button";
import Warning from "Components/Warning";
import Loading from "Components/Loading";
import parseErrors from "modules/parse-errors";
import { minLength } from "modules/validation";

const form = "update-password";
const v = {
  password: minLength(8),
  passwordConfirm: (v, vs) =>
    v === vs.password ? "" : "The passwords don't match",
};

class UpdatePassword extends React.Component {
  static propTypes = {
    match: object.isRequired,
    launch: bool,
  };

  state = {
    error: undefined,
    complete: false,
  };

  updatePassword = values => {
    return axios
      .post(
        `${window.API}/auth/${
          this.props.launch ? "launch" : "forgotten-password"
        }/${this.props.match.params.token}`,
        values
      )
      .then(() => {
        this.setState({
          complete: true,
          error: undefined,
        });
      })
      .catch(err => {
        const errors = parseErrors(err);
        if (errors) {
          throw new SubmissionError({
            ...errors,
            _error: "Password update failed",
          });
        } else {
          this.setState({
            complete: false,
            error: true,
          });
        }
      });
  };

  render() {
    if (this.state.complete) {
      return (
        <p className="text-xl font-normal">
          Your password has been updated. <Link to="/login">Login</Link>
        </p>
      );
    }
    return (
      <form onSubmit={this.props.handleSubmit(this.updatePassword)} noValidate>
        <p className="mb-5 font-normal">
          Please enter and confirm your new password.
        </p>
        <Field
          name="password"
          type="password"
          label="New password"
          validate={v.password}
          component={Input}
        />
        <Field
          name="password_confirmation"
          type="password"
          label="Confirm new password"
          validate={v.passwordConfirm}
          component={Input}
          right={() => (
            <ButtonBrand type="submit">
              {this.props.submitting ? <Loading inline /> : "Update"}
            </ButtonBrand>
          )}
        />
        {this.state.error ? (
          <Warning>
            <div className="mb-2">
              Sorry there was a problem updating your password.
            </div>
            <div>Your email link may have expired.</div>
            <div>
              <Link to="/reset-password">Request a new reset email</Link>
            </div>
          </Warning>
        ) : null}
      </form>
    );
  }
}

export default withRouter(reduxForm({ form })(UpdatePassword));
