import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { func, object } from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Field, getFormValues, initialize, reduxForm } from "redux-form";
import { ButtonOutlined, ButtonPrimary } from "Components/Button";
import { Input, LiveSearch } from "Components/Form";
import Location from "Components/Search/Location";
import { generalInteraction } from "../modules/analytics";
import { agencyObject, companyObject } from "../modules/permissions";
import { DEFAULT_AGENCY_CONTACT, EDGE_ENQUIRIES_EMAIL, EDGE_PHONE_NUMBER } from "../constants";

const form = "simple-search-start";

const SearchBannerCorporateHoE = ({
                                    footer,
                                    capacity,
                                    destroy,
                                    handleSubmit,
                                    initialize,
                                    history,
                                    venueOfTheWeek,
                                    generalInteraction,
                                    user = false,
                                    agency = DEFAULT_AGENCY_CONTACT,
                                  }) => {
  const [location, setLocation] = useState(null);
  const [name] = useState(null);
  const [error, setError] = useState(null);
  const [closePanels, setClosePanels] = useState(false);
  const [searchFields, setSearchFields] = useState("");
  const blankVenueInfo = { id: false, link: false };
  const [venueInfo, setVenueInfo] = useState(blankVenueInfo);
  if (agency) {
    if (typeof agency.phone_number === "undefined" || agency.phone_number === "" || agency.phone_number === null) {
      agency.phone_number = EDGE_PHONE_NUMBER;
    }
    if (typeof agency.email_address === "undefined" || agency.email_address === "" || agency.email_address === null) {
      agency.email_address = EDGE_ENQUIRIES_EMAIL;
    }
  }
  const submit = () => {
    if (location) {
      const locationParams = location ? location : {};
      initialize(
        "simple-search",
        {
          ...locationParams,
          sortBy: "ContentUpdate",
          capacity,
          name,
        },
        {
          updateUnregisteredFields: true,
        },
      );
      history.push("/search");
      reset();
    } else {
      setError(true);
    }
  };
  useEffect(() => {
    if (location !== null) {
      generalInteraction({
        type: "general_home_location_click",
        data: location && location.location,
      });
    }
  }, [location]);

  useEffect(() => {
    setError(false);
  }, [location]);

  useEffect(() => {
    if (history.location.pathname === "/") {
      setClosePanels(true);
    }
  }, []);

  const reset = () => {
    destroy(form);
    setLocation(null);
  };

  const primaryActionClicked = (e) => {
    if (searchFields === "venue") {
      generalInteraction({
        type: "corporate_home_venue_info_click",
        data: location && location.location,
      });
      history.push(venueInfo.link);
      e.preventDefault();
    } else {
      generalInteraction({
        type: "corporate_home_quick_search_click",
        data: location && location.location,
      });
    }
  };
  const secondaryActionClicked = () => {
    if (searchFields === "venue") {
      history.push("/request-for-proposal?venues=" + venueInfo.id);
    } else {
      const link = "/advanced-search?results=13326&back_projection=0&exhibition=0&" + (location && location.location ? "location=" + location.location + "&" : "") + (location && location.lat ? "lat=" + location.lat + "&" : "") + (location && location.lng ? "lng=" + location.lng + "&" : "") + "location_type=Map&radius=24140.16" + (capacity ? "&capacity=" + capacity : "") + `&closePanels=${closePanels}&sortBy=ContentUpdate&types=1&types=2&types=3&types=4&types=5&types=6&types=7&types=8&types=9&types=10&types=11&types=12&types=13`;
      history.push(link);
    }
  };

  const primaryActionText = searchFields === "venue" ? "Venue Info" : "Quick Search";
  const secondaryActionText = searchFields === "venue" ? "Enquire Now" : "Advanced Search";
  return (
    <div className={"py-16"}>
      <form className="edge-home-hero-search-form" onSubmit={handleSubmit(submit)} autoComplete="off">
        {!footer && (
          <h1 className="text-shadow mb-6 md:mb-8 text-white font-bold font-heading text-2rem md:text-3xl lg:text-4xl">
            Plan your next event...
          </h1>
        )}
        <div>
          <div className="col-w-full">
            <div className="row col-w-full">
              <div className="col lg:col-w-1/6 mb-4">
                <Location
                  formId={form}
                  id="location-search"
                  icon="/img/map-marker.svg"
                  label="Location"
                  labelClassName="sr-only"
                  name="location"
                  placeholder="Location"
                  fieldClassName="w-full mb-0"
                  wrapperClassName="mb-0"
                  noBottom
                  white
                  tall
                  onPlaceFound={(
                    lat,
                    lng,
                    name,
                    { placeArea },
                    addressType,
                  ) => {
                    // console.log('Place found');
                    if (name !== "") {
                      setSearchFields("location");
                    } else if (searchFields === "location") {
                      setSearchFields("");
                    }

                    setLocation({
                      location: name,
                      lat: lat,
                      lng: lng,
                      placeArea: placeArea,
                      addressType,
                    });
                  }}
                  disabled={searchFields === "venue"}
                  onPlaceChange={(
                    locationFieldValue,
                  ) => {
                    // console.log('Place changed');
                    if (locationFieldValue !== "") {
                      setSearchFields("location");
                    } else if (searchFields === "location") {
                      setSearchFields("");
                    }
                  }}
                />
              </div>
              <div className="col lg:col-w-1/6 mb-4">
                <LiveSearch
                  disabled={searchFields === "location"}
                  id="venue-search"
                  formId={form}
                  url={`${window.API}/venues/quick`}
                  onSelect={result => {
                    setVenueInfo({ id: result.id, link: "/venue/" + result.slug });
                    setSearchFields("venue");
                  }}
                  onClear={() => {

                    setVenueInfo(blankVenueInfo);
                    setSearchFields("");
                  }}
                  label="Venue name"
                  labelClassName="sr-only"
                  name="venue"
                  placeholder="Venue name"
                  fieldClassName="w-full"
                  wrapperClassName="mb-0"
                  noBottom
                  white
                  tall
                  getText={item => item.display_name}
                />
              </div>
              <div className="col lg:col-w-1/6 mb-4">
                <Field
                  name="capacity"
                  placeholder="No. of Attendees"
                  white
                  tall
                  noBottom
                  className="md:mr-3 lg:mr-5 xl:mr-8"
                  wrapperClassName="mb-0"
                  labelClassName="mb-0"
                  component={Input}
                />
              </div>
              <div className="col lg:col-w-1/6 mb-4">
                <ButtonPrimary
                  big
                  submit
                  style={{ "width": "inherit" }}
                  classes="text-xl xl:text-base xxl:text-xxl font-bold"
                  onClick={() => {
                    primaryActionClicked();
                  }}
                >
                  {primaryActionText}
                </ButtonPrimary>
              </div>
              <div className="col lg:col-w-1/6 mb-4 lg:text-center flex justify-center">
                <span className="font-bold text-white lg:justify-center lg:self-center text-xxl my-2 font-heading"> OR </span>
              </div>
              <div className="col lg:col-w-1/6 mb-4">
                <ButtonOutlined
                  big
                  classes="text-xl xl:text-base xxl:text-xxl border-3 border-dark-green text-grey-darker font-bold"
                  style={{ "width": "inherit" }}
                  onClick={() => {
                    secondaryActionClicked();
                  }}
                >
                  {secondaryActionText}
                </ButtonOutlined>
              </div>
            </div>
            <div className="row w-full">
              {error && (
                <div className="xl:flex">
                  <div className="col-w-full justify-center md:justify-start xl:col-w-3/5 flex pt-4">
                    <p className="text-white bg-dark-red p-1 mt-2 font-bold">
                      Please choose a location or Venue
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

      </form>
      <div>
        <p className="mt-6 mb-6 text-shadow text-white text-3xl font-heading" style={{ lineHeight: "1.1" }}>Call us
          on {" "}
          <a href={`tel:${agency.phone_number}`}>{agency.phone_number}</a>, email {" "}
          <a href={`mailto:${agency.email_address}`}>{agency.email_address}</a> or</p>
        <div className="row w-full">
          <div className="col lg:col-w-2/5 mb-4">
            <Link to="/corporate/request-for-proposal">
              <ButtonPrimary
                big
                style={{ "width": "inherit" }}
                classes="text-md xl:text-base xxl:text-xxl font-bold bg-dark-grey"
              >
                Send a new enquiry
              </ButtonPrimary>
            </Link>
          </div>
          <div className="col lg:col-w-1/5 mb-4 lg:text-center flex justify-center">
                                <span
                                  className="font-bold text-white lg:justify-center lg:self-center text-xxl my-2 font-heading"> OR </span>
          </div>
          <div className="col lg:col-w-2/5 mb-4">
            <Link to="/corporate/templates">
              <ButtonPrimary
                big

                classes="text-xl xl:text-base xxl:text-xxl font-bold w-full"
              >
                Use a template
              </ButtonPrimary></Link>
          </div>
        </div>
      </div>
    </div>
  );
};

SearchBannerCorporateHoE.propTypes = {
  initialize: func.isRequired,
  history: object.isRequired,
};

const mapStateToProps = state => {
  let agency = {};
  if (agencyObject(state.auth).length > 0) {
    agency = agencyObject(state.auth);
  } else if (companyObject(state.auth)) {
    agency = companyObject(state.auth).agency_id ? companyObject(state.auth).agency : {};
  }
  return {
    capacity: (getFormValues(form)(state) || {}).capacity,
    agency: agency,
  };
};

export default withRouter(
  reduxForm({ form })(
    connect(
      mapStateToProps,
      { initialize, generalInteraction },
    )(SearchBannerCorporateHoE),
  ),
);
