import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import ReferrerBrandRedirect from "../Components/ReferrerBrandRedirect";

const isCmsPage = pathname => {
  return (
    [
      "/",
      "/about",
      "/contact",
      "/cookies",
      "/buy-it-now",
      "/privacy",
      "/define-venue",
      "/refine-venue-search",
      "/search-for-venue",
      "/help",
      "/terms-and-conditions",
    ].includes(pathname) ||
    pathname.startsWith("/article/") ||
    pathname.startsWith("/venue/") ||
    pathname.startsWith("/venues-in")
  );
};

const Seo = ({ location }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!isCmsPage(location.pathname)) {
      return;
    }

    const setMeta = () => {
      Axios.get(`${window.API}/seo-data?path=${location.pathname}`)
        .then(response => {
          if (response && response.data) {
            setData(response.data);
          }
        })
        .catch(e => {});
    };

    setMeta();
  }, [location.pathname]);

  return data ? (
    <Helmet>
      {data.title && <title>{data.title}</title>}
      <ReferrerBrandRedirect />
      {data.meta_description && (
        <meta name="description" content={data.meta_description} />
      )}
      {data.canonical && <link rel="canonical" href={data.canonical} />}
    </Helmet>
  ) : (
    <ReferrerBrandRedirect />
  );
};

export default withRouter(Seo);
