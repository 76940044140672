import {
  BUSINESS_UNIT_CREATED,
  BUSINESS_UNIT_CREATED_ERROR,
  BUSINESS_UNIT_DELETED,
  BUSINESS_UNIT_DELETED_ERROR,
  BUSINESS_UNIT_REQUEST_RECEIVED_ERROR,
  BUSINESS_UNIT_UPDATED,
  BUSINESS_UNITS_LIST_REQUEST,
  BUSINESS_UNITS_LIST_REQUEST_RECEIVED,
  BUSINESS_UNITS_UNLOAD,
} from "./types";

export const businessUnits = (state = {
  businessUnits: [],
  isFetchingBusinessUnits: false,
  businessUnitsError: false,
  showBusinessUnitForm: false,
}, action) => {
  switch (action.type) {
    case BUSINESS_UNITS_LIST_REQUEST:
      return {
        ...state,
        businessUnits: [],
        isFetchingBusinessUnits: true,
        businessUnitsError: false,
      };
    case BUSINESS_UNITS_LIST_REQUEST_RECEIVED:
      return {
        ...state,
        businessUnits: action.data.data,
        isFetchingBusinessUnits: false,
        businessUnitsError: false,
      };
    case BUSINESS_UNIT_REQUEST_RECEIVED_ERROR:
      return {
        ...state,
        businessUnits: [],
        isFetchingBusinessUnits: false,
        businessUnitsError: action.error,
      };
    case BUSINESS_UNIT_CREATED:
      state.businessUnits.push(action.data.data);
      return {
        ...state,
        businessUnits: [...state.businessUnits],
      };
    case BUSINESS_UNIT_CREATED_ERROR:
      return {
        ...state,
        isFetchingBusinessUnits: false,
        businessUnitsError: true,
      };
    case BUSINESS_UNIT_UPDATED:
      let updatedBusinessUnit = state.businessUnits.findIndex(businessUnit => businessUnit.id === action.data.data.id);
      state.businessUnits[updatedBusinessUnit] = action.data.data;
      return {
        ...state,
        businessUnits: state.businessUnits,
      };
    case BUSINESS_UNIT_DELETED:
      return {
        ...state,
        businessUnits: [...state.businessUnits.filter(obj => {
          return obj.id !== action.data.data.id;
        })],
        isFetchingBusinessUnits: false,
        businessUnitsError: false,
      };
    case BUSINESS_UNIT_DELETED_ERROR:
      return {
        ...state,
      };
    case BUSINESS_UNITS_UNLOAD:
      return {
        ...state,
        businessUnits: [],
        isFetchingBusinessUnits: false,
        businessUnitsError: false,
      };
    default:
      return state;
  }
};
