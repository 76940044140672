import React from 'react';
import {connect} from "react-redux";
import LatestArticle from "../../../pages/trinity-news/LatestArticle";
import {CATEGORY_GROUP_IDS,} from "../../../modules/cms";
import {loadArticles} from "../../../modules/articles";
import {renderArticleImage} from "../../../pages/Home/NewsArticle";
import useCmsContent from "../../../hooks/useCmsContent";
import {Link} from "react-router-dom";
import {htmlExcerpt} from "../../../modules/string-tools";
import {isHeadOfEvents, isHeadOfProcurement} from "../../../modules/permissions";
import * as ROUTES from "../../../Router/corporate_routes";

const ArticleSegment = ({item}) => {
    return <Link to={{pathname: item.link}} className="block">
        <div className="flex flex-col sm:flex-row mb-1 p-2 text-black"
             style={{
                 backgroundColor: "#f5f5f5",
             }}
        >
            <div className="mr-2">{renderArticleImage(item.img, false)}</div>
            <div>
                <h4 className="mt-1 mb-1 text-sm"
                    dangerouslySetInnerHTML={{__html: item.title}}
                />
                <div className="text-xs">
                    <span dangerouslySetInnerHTML={{__html: htmlExcerpt(item.content, 100)}}/>{" "}
                    <span className="font-heading font-bold uppercase text-xs text-brand"
                          style={{
                              whiteSpace: "nowrap",
                          }}>
                                Read more
                            </span>
                </div>
            </div>
        </div>
    </Link>
}

const CorpDashFeed = (props) => {
    const content = useCmsContent(24);
    const articles = content['news'];
    const offers = content['specialOffers'];

    /* START VERTICAL FEED */
    return <div className={'p-4 mt-2 lg:mt-0 lg:mx-2 rounded-lg text-white h-full corporate-admin__feed'} style={{backgroundImage: 'none'}}>


        {/* FEED ITEM */}
        {!props.isHeadOfEvents && !props.isHeadOfProcurement ?
            <>
                {(articles && articles.length > 0) ?
                    <div className="flex flex-row items-center my-1">
                        <span className="text-2xl mr-4"><i className='fal fa-newspaper'/></span>
                        <h2 className="mb-0 text-lg xl:text-xl font-normal"><Link className="text-white hover:text-brand" title="View all updates" to={ROUTES.FRONTEND.CORPORATE_USER.NEWS.VENUE_NEWS}>Latest News <i
                            className="xl:visible fa fas fa-arrow-right text-white ml-2 text-xl"></i></Link></h2>
                    </div> : null}
                {articles && articles.length > 0 ? <ArticleSegment item={articles[0]}/> : null}
                {/*{articles && articles.length > 1 ? <ArticleSegment item={articles[1]}/> : null}*/}
                {(offers && offers.length > 0) ?
                    <div className="flex flex-row items-center">
                        <span className="text-2xl mr-4"><i className='fal fa-piggy-bank'/></span>
                        <h2 className="mb-0 text-lg xl:text-xl font-normal"><Link className="text-white hover:text-brand" title="View all updates" to={ROUTES.FRONTEND.CORPORATE_USER.NEWS.SPECIAL_OFFERS}>Latest Offers <i
                            className="xl:visible fa fas fa-arrow-right text-white ml-2 text-xl"></i></Link></h2>
                    </div> : null}
                {offers && offers.length > 0 ? <ArticleSegment item={offers[0]}/> : null}
                {/*{offers && offers.length > 1 ? <ArticleSegment item={offers[1]}/> : null}*/}
            </>
            : null}


        {/*<LatestArticle categories={CATEGORY_GROUP_IDS.corporateTeamUpdates}>*/}
        {/*    <div className="flex flex-row items-center my-1">*/}
        {/*        <span className="text-2xl mr-4"><i className='fal fa-alarm-exclamation'/></span>*/}
        {/*        <h2 className="mb-0 text-lg xl:text-xl font-normal"><Link className="text-white hover:text-brand" title="View all updates" to={ROUTES.FRONTEND.CORPORATE_USER.NEWS.CORPORATE_TEAM}>EDGE Team Updates <i*/}
        {/*            className="xl:visible fa fas fa-arrow-right text-white ml-2 text-xl"></i></Link></h2>*/}
        {/*    </div>*/}
        {/*</LatestArticle>*/}

        {/*{props.isHeadOfEvents || props.isHeadOfProcurement ?*/}

            <LatestArticle categories={CATEGORY_GROUP_IDS.expertInsights}>
                <div className="flex flex-row items-center my-1">
                    <span className="text-2xl mr-4"><i className='fal fa-star'/></span>
                    <h2 className="mb-0 text-lg xl:text-xl font-normal"><Link className="text-white hover:text-brand" title="View all updates" to={ROUTES.FRONTEND.CORPORATE_USER.NEWS.INSIGHTS}>Industry Insights <i
                        className="xl:visible fa fas fa-arrow-right text-white ml-2 text-xl"></i></Link></h2>
                </div>
            </LatestArticle>
            {/*: null}*/}

        {(props.isHeadOfEvents || props.isHeadOfProcurement) && offers && offers.length > 0 ?
            <>
                <div className="flex flex-row items-center my-1">
                    <span className="text-2xl mr-4"><i className='fal fa-hotel'/></span>
                    <h2 className="mb-0 text-lg xl:text-xl font-normal"><Link className="text-white hover:text-brand" title="View all updates" to={ROUTES.FRONTEND.CORPORATE_USER.NEWS.SPECIAL_OFFERS}>Venue Offers <i
                        className="xl:visible fa fas fa-arrow-right text-white ml-2 text-xl"></i></Link></h2>
                </div>
                {offers && offers.length > 0 ? <ArticleSegment item={offers[0]}/> : null}
                {offers && offers.length > 1 ? <ArticleSegment item={offers[1]}/> : null}
                {/*{offers && offers.length > 1 ? <ArticleSegment item={offers[1]}/> : null}*/}
            </>
            : null}
    </div>

}


export default connect(
    state => ({
        articles: state.articles.articles,
        offers: state.articles.offers,
        articlesLoaded: state.articles.loaded,
        articlesLoading: state.articles.loading,
        isHeadOfProcurement: isHeadOfProcurement(state.auth),
        isHeadOfEvents: isHeadOfEvents(state.auth)
    }), {loadArticles})(CorpDashFeed);