import React, { Component, Fragment } from "react";
import PaginationNav from "../Pagination";

export default class LaravelPaginatorWrapper extends Component {

    state = {
        page: 1,
    };

    setPage(page) {
        const path = this.props.path;
        this.props.getPage({path, page})
        this.setState({
            page: Math.min(Math.max(1, page), this.props.last_page),
        });
    };


    render() {
        const items = this.props.data;
        const firstItem = this.props.total === 0 ? 0 : (this.props.current_page - 1) * this.props.per_page + 1;
        const lastItem = Math.min(firstItem + this.props.per_page - 1, this.props.total);
        const {page} = this.state;
        if(!this.props.data)
        {
            return null;
        }
        return(
        <Fragment>
            {this.props.render({items})}
            <div className="my-2 flex flex-row justify-end">
               <PaginationNav
                    total={this.props.total}
                    current_page={page}
                    from={firstItem}
                    to={lastItem}
                    last_page={this.props.last_page}
                    goToPage={page => this.setPage(page)}
                />
            </div>
        </Fragment>
        );
    }


}
