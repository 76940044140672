import axios from "axios";
import { createHandleAxiosError } from "../../../modules/api-tools";

export default (props, setState) => {
  const { history, location } = props;
  const handleAxiosError = createHandleAxiosError({ history, location });

  axios
    .get(`${window.API}${props.url}`, {
      headers: { Authorization: `Bearer ${props.token}` },
      params: props.params,
    })
    .then(response => {
      setState.done(response.data);
    })
    .catch(
      handleAxiosError(() => {
        setState.error();
      })
    );
};
