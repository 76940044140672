import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, change } from 'redux-form';
import { Select } from 'Components/Form';
import fetchNamedLocationTypesAction from './fetchNamedLocationTypes';
import fetchNamedLocationSubGroupsAction from './fetchNamedLocationSubGroups';
import fetchNamedLocationsAction from './fetchNamedLocations';
import resetNamedLocationsAction from './resetNamedLocations';
import resetFormAction from './resetForm';
import queryString from "query-string";

import {
  getNamedLocationTypes,
  getNamedLocationSubGroups,
  getNamedLocations,

} from 'modules/named-locations';

class NamedLocation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      type: null,
      group: null,
      initial: true,
      searchTerm: this.props.searchTerm
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
  }

  componentDidUpdate(nextProps) {
    const { searchTerm, fetchNamedLocationTypes, resetForm, form } = this.props;
    const { initial } = this.state;

    if ((nextProps.searchTerm !== searchTerm)) {
      if (form['advanced-search'].values.location && searchTerm) {
        fetchNamedLocationTypes(searchTerm);
        resetForm();
        this.setState({
          initial: false,
          type: null,
          group: null,
          searchTerm: searchTerm
        });
      }
    } else {
      if (initial) {
        if (form['advanced-search'].values.location && (form['advanced-search'].values.location === searchTerm)) {
          fetchNamedLocationTypes(searchTerm);
          resetForm();
          this.setState({
            initial: false,
            type: null,
            group: null,
            searchTerm: searchTerm
          });
        }

      }
    }
  }
  componentDidMount() {
    const { location, resetForm, fetchNamedLocationTypes } = this.props;
    let params = queryString.parse(location.search);
    if('location' in params) {
      fetchNamedLocationTypes(params['location']);
      resetForm();
      this.setState({
        searchTerm: params['location']
      })
    }
  }

  componentWillMount() {
    this.props.resetForm();
    this.props.resetNamedLocations();
  }

  componentWillUnmount() {
    this.props.resetNamedLocations();
  }

  searchOnChange = (e, v, pv, name) => {
    const matchedLocation = this.props.namedLocations.find(loc => Number(loc.id) === Number(v));
    if (matchedLocation) {

      this.props.change('advanced-search', 'lat', matchedLocation.lat);
      this.props.change('advanced-search', 'lng', matchedLocation.lng);
      this.props.change('advanced-search', 'radius', 1609.344);
      this.props.search({
        [name]: v,
      });

    }
  };

  onTypeChange(e, v, pv, name) {
    const { fetchNamedLocationSubGroups, namedLocationsType,  fetchNamedLocations } = this.props;
    const {searchTerm} = this.state

    if (!e.target.value) {
      this.setState({
        type: null,
        group: null,
      });
    }
    let type = namedLocationsType.find(type => Number(type.id) === Number(e.target.value));
    this.setState({
      type: type,
    });

    if (type && type['has_group'] === 1) {
      fetchNamedLocationSubGroups(searchTerm, type['name']);
    }

    if (type && type['has_group'] === 0) {
      fetchNamedLocations(searchTerm, type['name']);
    }

  }

  onGroupChange(e, v, pv, name) {
    const { fetchNamedLocations } = this.props;
    const {searchTerm} =  this.state;
    const { type } = this.state;
    if (!e.target.value) {
      this.setState({
        group: null,
      });
    }

    this.setState({
      group: e.target.value,
    });
    fetchNamedLocations(searchTerm, type['name'], e.target.value);

  }

  render() {
    const { type, group } = this.state;
    const { namedLocationsType, namedLocationsSubGroups, namedLocations } = this.props;
    let selectAll = { display_name: 'All Zones', name: 'all' };
    selectAll = [selectAll].concat(namedLocationsSubGroups);

    if (!namedLocationsType) {
      return null;
    }

    return (
        <div>
          {namedLocationsType && namedLocationsType.length > 0 ?
              <Field
                  name="named_location_type"
                  component={Select}
                  label="Tube/Location"
                  options={namedLocationsType && namedLocationsType.length > 0 ? namedLocationsType.map(o =>
                      ({
                        text: o.display_name,
                        value: o.id,
                      }),
                  ) : namedLocationsType}
                  defaultValue={''}
                  onChange={this.onTypeChange}
                  wrapperClassName="max-w-full mb-5 mr-6 w-80"
              />

              : null}
          {type && type['has_group'] === 1 && selectAll.length > 0 ? <Field
              name="named_location_group"
              component={Select}
              label="Zone"
              options={selectAll ? selectAll.map(o =>
                  ({
                    text: o.display_name,
                    value: o.name,
                  }),
              ) : selectAll}
              defaultValue={selectAll ? selectAll.map(o =>
                  ({
                    text: o.display_name,
                    value: o.name,
                  })[0],
              ) : selectAll}
              onChange={this.onGroupChange}
              wrapperClassName="max-w-full mb-5 mr-6 w-80"
          /> : null}
          {(group || (type && type['has_group'] === 0)) && namedLocations.length > 0 ? <Field
              name="named_location"
              component={Select}
              label={type.id === 1 ? 'Station' : 'Place'}
              options={namedLocations.length > 0 ? namedLocations.map(o =>
                  ({
                    text: o.name,
                    value: o.id,
                  }),
              ) : namedLocations}
              defaultValue={namedLocations.length > 0 ? namedLocations.map(o =>
                  ({
                    text: o.name,
                    value: o.id,
                  })[0],
              ) : namedLocations}
              wrapperClassName="max-w-full mb-5 mr-6 w-80"
              onChange={this.searchOnChange}
          /> : null}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    namedLocationsType: getNamedLocationTypes(state),
    namedLocationsSubGroups: getNamedLocationSubGroups(state),
    namedLocations: getNamedLocations(state),
    form: state.form,
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  fetchNamedLocationTypes: fetchNamedLocationTypesAction,
  fetchNamedLocationSubGroups: fetchNamedLocationSubGroupsAction,
  fetchNamedLocations: fetchNamedLocationsAction,
  resetNamedLocations: resetNamedLocationsAction,
  resetForm: resetFormAction,
  change,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NamedLocation);


