import convertPriceToSourceCurrencyValue from "helpers/convertPriceToSourceCurrencyValue";

export default function mapCateringFromProposalData(catering, isSourceCurrency = false) {
  catering.map(value => {
    if (value.meeting_room) {
      value.meeting_room.price_total_net_gbp = value.meeting_room.price_total_net;
      value.meeting_room.price_total_vat_gbp = value.meeting_room.price_total_vat;

      value.meeting_room.price_per_day_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_per_day_vat"));
      value.meeting_room.price_total_net = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_total_net"));
      value.meeting_room.price_total_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_total_vat"));
      value.meeting_room.price_reduced_from_total_net = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_reduced_from_total_net"));
      value.meeting_room.price_reduced_from_total_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_reduced_from_total_vat"));
      value.meeting_room.price_per_day_net = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_per_day_net"));
      value.meeting_room.price_per_day_reduced_from = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_per_day_reduced_from_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_per_day_reduced_from_vat"));
      value.meeting_room.price_per_day_reduced_from_net = convertPriceToSourceCurrencyValue(isSourceCurrency, value.meeting_room, "price_per_day_reduced_from_net");
    }
    value.price_per_person_net_gbp = value.price_per_person_net;
    value.price_per_person_vat_gbp = value.price_per_person_vat;

    value.price_net = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_net");
    value.price_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_vat");
    value.price_per_person_net = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_per_person_net");
    value.price_per_person_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_per_person_vat");
    value.price_reduced_from_net = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_reduced_from_net");
    value.price_reduced_from_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_reduced_from_vat");
  });
  return [
    ...catering,
  ];
};

