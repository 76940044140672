import React, {Component} from "react";
import {connect} from 'react-redux';
import moment from 'moment';
import Modal from "Components/Modal";
import {ButtonPrimary, ButtonSpan} from "../../../../Components/Button";
import {processFormValues, renderFormInput} from "../../../../modules/formTools";
import {Field, reduxForm} from "redux-form";
import DateField from "../../../../ui/Date";
import {Select, Textarea} from "../../../../ui";
import {deleteEvent, fetchEvent, unloadEvent} from "../../../../redux/actions";
import {token} from "../../../../modules/auth";
import {upperFirst} from "lodash";
import {isRoleEdgeAdmin} from "../../../../modules/permissions";

const form = "edit-event";

const mapDispatchToProps = {
  fetchEvent,
  unloadEvent,
  deleteEvent
}

const mapStateToProps = (state, props) => {
  return {
    event: {...props.event},
    initialValues: {...props.event},
    token: token(state),
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth)
  };
};

const Wrapper = ({children, onClose}) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 600,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3"/>
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

class EditEventModal extends Component {
  componentDidMount() {
    this.props.fetchEvent(this.props.event.id, this.props.token);
  }

  onSubmit = (formValues) => {
    this.props.setEvent(this.props.event)
    return processFormValues(this.props, formValues, this.props.initialValues);
  }

  componentWillUnmount() {
    this.props.setEvent({})
    this.props.unloadEvent()
  }

  onDelete = () => {
    return this.props.deleteEvent(this.props.event.id, this.props.token);
  }

  render() {
    const {onClose, event, countries, isRoleEdgeAdmin} = this.props;
    return (
      <Wrapper onClose={onClose}>
        <div>
          <h2>Edit {upperFirst(event.type)} Event</h2>
          <div className="mt-3">
            <form className={'border-none'} onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <div className="col col-w-full mb-8 mt-8">
                <Field
                  label={'Title'}
                  name={'title'}
                  component={renderFormInput}
                />
                <Field
                  label={'Description'}
                  name={'description'}
                  component={Textarea}
                />

                <Field
                  todayButton="Today"
                  label={'Start Date'}
                  name={'start_date'}
                  component={DateField}
                  classes={'min-h-9.5 '}
                  dateFormat={'ddd Do MMM YYYY'}
                  selected={moment(event.start)}
                />
                <Field
                  label={'End Date'}
                  name={'end_date'}
                  component={DateField}
                  classes={'min-h-9.5 '}
                  todayButton="Today"
                  dateFormat={'ddd Do MMM YYYY'}
                  selected={moment(event.end)}
                />
                <Field
                  label={'Location'}
                  name={'location'}
                  component={renderFormInput}
                  labelClassName={'text-xs'}
                />
                <Field
                  name="country_id"
                  label="Country"
                  component={Select}
                  options={countries}
                />
              </div>
              {(!isRoleEdgeAdmin && event.type === 'public') ? (<></>) : (
                <div className="flex flex-auto">
                  <ButtonPrimary classes={'bg-dark-red'} onClick={this.onDelete}>
                    <i className="fas fa-trash mr-3"/> Delete Event
                  </ButtonPrimary>
                  <ButtonPrimary type="submit" classes={'ml-auto'}>
                    Save Event
                  </ButtonPrimary>
                </div>
              )}
            </form>
          </div>
        </div>
      </Wrapper>
    );
  }
}

EditEventModal = reduxForm({
  form,
  enableReinitialize: true
})(EditEventModal);

export default connect(mapStateToProps, mapDispatchToProps)(EditEventModal);
