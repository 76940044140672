export const API = {
  HOTEL_GROUPS: {
    LIST: "/api/hotel-groups",
    LIST_TYPES: "/api/hotel-group-types",
    SINGLE_VIEW: "/api/hotel-groups/:id",
    LOGO_IMAGE: "/api/hotel-groups/:id/logo",
    ACCOUNT_MANAGER: "/api/hotel-groups/:id/account-manager/:accountManagerId",
    UNASSIGN_ACCOUNT_MANAGER: "/api/hotel-groups/:id/account-manager/:accountManagerId/unassign",
  },
  HOTEL_GROUP_VENUES: {
    LIST: "/api/hotel-groups/:id/venues",
    SINGLE: "/venues/:venueSlug/overview",
  },
  SELF_SERVICE: {
    STRIPE: {
      GET_SUBSCRIPTION: "/:venueSlug/self-service/subscription/",
      GET_PRICE: "/:venueSlug/self-service/price",
      APPLY_DISCOUNT: "/:venueSlug/self-service/apply-discount",
      CREATE_SESSION: "/:venueSlug/self-service/create",
      CREATE_SUBSCRIPTION: "/:venueSlug/self-service/create-subscription",
    },
  },
};

export const FRONTEND = {
  VENUE: {
    PUBLIC_VIEW: "/venue/:venue",
    DASHBOARD: "/admin/venues",
    EDIT: "/admin/edit-venue/:venue/:section?"
  },
  MANAGE_VENUE_LICENCE: {
    INDEX: "/admin/venues/manage-licence",
    UPGRADE: "/admin/venues/manage-licence/upgrade",
    LICENSED_VENUE_PROMO_PAGE: "/admin/venues/manage-licence",
    PAYMENT_REDIRECT: "/admin/venues/manage-licence/:venueSlug/",
  },
  HOTEL_GROUPS: {
    INDEX: "/admin/venues/hotel-groups",
    SINGLE_VIEW: "/admin/venues/hotel-groups/:id",
    CREATE: "/admin/venues/hotel-groups/create",
  },
};