import React, { useState, useEffect } from "react";
import { Field } from "redux-form";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { Select, Input } from "Components/Form";
import { ButtonPrimary } from "Components/Button";
import Loading from "Components/Loading";
import { required } from "modules/validation";
import useService from "hooks/useService";
import { removeVenue } from "services/rfpService";
import RfpProposalActionModal from "./RfpProposalActionModal";

const v = {
  required: required(),
};

const options = [
  "Found another venue",
  "Cancelled event",
  "Too expensive",
  "Other",
];

const form = "proposal-remove-venue";

const RemoveVenueModal = ({ onClose, venue, onRemove }) => {
  const values = useSelector(getFormValues(form)) || {};
  const [submitData, setSubmitData] = useState(null);
  const { id: rfpId } = useParams();
  const { loading: submitting, data: complete, error } = useService({
    initialLoading: false,
    service: removeVenue,
    trigger: submitData,
    params: submitData,
  });

  const handleConfirm = () => {
    setSubmitData({
      reason: values.reason,
      reason_other: values.reason_other,
      venueId: venue.id,
      rfpId,
    });
    if (complete) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (complete) {
      onRemove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  return (
    <RfpProposalActionModal
      form={form}
      title="Remove Venue"
      onConfirm={handleConfirm}
      onClose={onClose}
      venue={venue}
      content={
        <div className="relative h-full pb-16">
          {complete ? (
            <p className="text-xl mb-5">
              Thank you.
              <br />
              {complete}
            </p>
          ) : (
            <>
              <p className="text-xl mb-5">
                Please let us know why you want to remove the venue
              </p>
              <Field
                label="Reason"
                name="reason"
                component={Select}
                options={options}
                validate={v.required}
                labelClassName="sr-only"
              />
              {values.reason === "Other" && (
                <Field
                  label="Please specify"
                  name="reason_other"
                  component={Input}
                  validate={v.required}
                />
              )}
              {error && (
                <p className="my-5 text-warning">
                  Sorry, there was a problem submitting your request
                </p>
              )}
              <div className="text-center">
                <ButtonPrimary
                  type="submit"
                  classes="px-5"
                  style={{ height: 57, lineHeight: "57px" }}
                >
                  {submitting ? <Loading inline /> : "Remove Venue"}
                </ButtonPrimary>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default RemoveVenueModal;
