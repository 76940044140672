import {resetNamedLocations} from 'modules/named-locations.js';
import {  change} from "redux-form";

function resetForm() {
    return dispatch => {
        dispatch(change('advanced-search','named_location',null));
        dispatch(change('advanced-search','named_location_group',null));
        dispatch(change('advanced-search','named_location_type',null));
        dispatch(resetNamedLocations());
    }
}

export default resetForm;
