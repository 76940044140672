import React, {useState} from 'react';
import {formatCurrency, GBPFormatter} from "../../../utils";
import {formatDate} from "../../../modules/dates";
import * as ROUTES from "../../../Router/corporate_routes";
import {Link} from "react-router-dom";

const SpendReportTable = ({report = []}) => {
  const [showVenueRows, setShowVenueRows] = useState({});
  const [showEventsRows, setShowEventsRows] = useState({});

  const toggleVenueRows = groupName => {
    setShowVenueRows({...showVenueRows, [groupName]: showVenueRows[groupName] ? !showVenueRows[groupName] : true});
  }
  const toggleEventRows = venueName => {
    setShowEventsRows({...showEventsRows, [venueName]: showEventsRows[venueName] ? !showEventsRows[venueName] : true});
  }

  /**
   * Loop through events and output the rows. Conditionally show the events rows if clicked.
   * @param venuesEvents
   * @returns {unknown[]}
   */
  const getVenueRows = venuesEvents => {
    let venueGrossTotal = 0;
    return Object.entries(venuesEvents).map((venue, key) => {
      if (venue[0] !== 'groupTotals') {
        return (
          <>
            <tr key={key} onClick={() => toggleEventRows(venue[0])} className="pointer hover:bg-light-grey pl-6  border-b border-grey">
              <td className="py-2">
                <i className={`${showEventsRows[venue[0]] ? 'fa-minus-square' : 'fa-plus-square'} fas ml-6 mr-2 text-xs`}/><strong>{venue[0]}</strong>
              </td>
              {venue[1].venueTotals.map(value => {
                venueGrossTotal = value.netTotal;
                return (
                  <>
                    <td className="text-right">{GBPFormatter.format(venueGrossTotal)}</td>
                    <td className="text-right">{parseInt(Number(value.marketShare).toFixed(0))}%</td>
                    <td className="text-right">{GBPFormatter.format(value.costSavings)}</td>
                    <td className="text-right">{parseInt(Number(value.percentageCostSavings).toFixed(0))}%</td>
                  </>
                )
              })}
            </tr>
            {showEventsRows[venue[0]] && getEventsRows(venue[1])}
          </>
        )
      }
    })
  }

  /**
   * Loop through events and output rows.
   * @param events
   * @returns {unknown[]}
   */
  const getEventsRows = (events) => {
    return Object.entries(events).map((event) => {
      if (event[0] !== 'venueTotals') {
        return event[1].map((ev, key) => {
          return (
            <>
              <tr key={key} className="hover:bg-light-grey text-sm">
                <td className="py-1 pl-12">
                  <Link
                    className="text-black p-2 hover:underline"
                    to={ROUTES.FRONTEND.BOOKED_EVENTS.SINGLE.replace(':slug', ev.event.id)}>
                  <strong>{ev.event.ref_id} - {ev.event.name}</strong> - {formatDate(ev.event.event_date_from, 'DD/MM/YYYY')}
                  </Link>
                </td>
                <td className="text-right">{GBPFormatter.format(ev.netTotal)}</td>
                <td className="text-right">-</td>
                <td className="text-right">{GBPFormatter.format(ev.netCostSavings)}</td>
                <td className="text-right">{ev?.percentageCostSavings}%</td>
              </tr>
            </>
          )
        })
      }
    })
  }

  return (
    <>
      <table>
        <thead>
        {report && report['totals'] ? (
          <tr>
            <td className="font-bold py-2 border-b border-grey">Totals</td>
            <td className="font-bold py-2 border-b border-grey text-right">{report['totals'].netTotal && GBPFormatter.format(report['totals'].netTotal)}</td>
            <td className="font-bold py-2 border-b border-grey text-right"></td>
            <td className="font-bold py-2 border-b border-grey text-right">{report['totals'].costSavings && GBPFormatter.format(report['totals'].costSavings)}</td>
            <td className="font-bold py-2 border-b border-grey text-right">{report['totals']?.percentageCostSavings}%</td>
          </tr>
        ) : null}
        </thead>
        <thead>
        <tr>
          <td className="font-bold py-2 border-b border-grey">Venue / Group</td>
          <td className="font-bold py-2 border-b border-grey text-right">Net Value</td>
          <td className="font-bold py-2 border-b border-grey text-right">Market Share</td>
          <td className="font-bold py-2 border-b border-grey text-right">Net Cost Savings</td>
          <td className="font-bold py-2 border-b border-grey text-right">Savings %</td>
        </tr>
        </thead>
        <tbody>
        {report && Object.entries(report).length > 0 && Object.entries(report).map((groups, key) => {
          if (groups[0] !== 'totals') {
            return (
              <>
                <tr className="pointer hover:bg-light-grey border-b border-grey" key={key} onClick={() => toggleVenueRows(groups[0])}>
                  <td className="py-2">
                    <i className={`${showVenueRows[groups[0]] ? 'fa-minus-square' : 'fa-plus-square'} fas mx-2 text-xs`}/><strong>{groups[0]}</strong>
                  </td>
                  {Object.entries(groups[1]).length > 0 && groups[1].groupTotals.map(value => {
                    return (
                      <>
                        <td className="text-right">{GBPFormatter.format(value.netTotal)}</td>
                        <td className="text-right">{parseInt(Number(value.marketShare).toFixed(0))}%</td>
                        <td className="text-right">{GBPFormatter.format(value.costSavings)}</td>
                        <td className="text-right">{parseInt(Number(value.percentageCostSavings).toFixed(0))}%</td>
                      </>
                    )
                  })}
                </tr>
                {showVenueRows[groups[0]] && getVenueRows(groups[1])}
              </>
            )
          }
        })}
        </tbody>
      </table>
    </>
  )
}

export default SpendReportTable;
