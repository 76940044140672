import React from "react";
import useCmsContent from "hooks/useCmsContent";
import ArticleSectionAltPaginated from "Components/ArticleSectionAltPaginated";
import SearchBanner from "Components/SearchBanner";

const CmsContent = ({ dataKey, title, perPage = 22, showSearch = true }) => {
  const content = useCmsContent();

  return (
    <div>
      <div className="container pt-5">
        <ArticleSectionAltPaginated
          h1
          title={title}
          articles={content[dataKey]}
          brand
          perPage={perPage}
        />
      </div>
        {showSearch ?
            <div className="flex justify-center bg-primary">
                <div className="container xxl:px-40">
                    <SearchBanner footer />
                </div>
            </div>
        : null}
    </div>
  );
};

export default CmsContent;
