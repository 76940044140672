import React from "react";

const ImageGrid = props => {
  const gridStyle = {
    display: "grid",
    gridGap: "20px",
    gridTemplateColumns: "repeat( auto-fill, minmax(300px, 1fr) )",
    gridTemplateRows: "auto",
  };

  return <div style={gridStyle}>{props.children}</div>;
};

export default ImageGrid;
