import React from "react";
import axios from "axios";
import { token} from "../../modules/auth";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";

const TechnicalSpecificationDownload = props => {

    let saveData = (function() {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.cssText = "display: none";
        return function(type, data, fileName) {
            const blob = new Blob([data], {
                type,
            });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.setAttribute("download", fileName);
            a.click();
            window.URL.revokeObjectURL(url);
        };
    })();

     async function handleDownload( type,url, name)  {
        try {
            const response = await axios.get(url, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${props.token}`,
                },
            });
            saveData(type, response.data, name);
        } catch (err) {
            alert("Sorry there was a problem fetching the file");
        }
    }

    if(props.technicalSpec.length > 0 && props.technicalSpec[0] !== 'undefined')
    {
        return (
            <button className={'technical-spec-button'}  onClick={() =>
                handleDownload(
                    props.technicalSpec[0].type,
                    props.technicalSpec[0].url,
                    props.technicalSpec[0].name,
                )}> DOWNLOAD TECHNICAL SPECIFICATION </button>
            )

    }

    return (<></>);


}

export default withRouter(
    connect(
        state => ({
            token: token(state)
        }),
    )(TechnicalSpecificationDownload)
);

