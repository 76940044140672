import React from "react";
import {
  func,
  string,
  object,
  oneOfType,
  array,
  bool,
  oneOf,
  node,
} from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { change, getFormValues } from "redux-form";
import { Failed } from "Components/Form";
import { token } from "modules/auth";
import DropzoneArea from "./DropzoneArea";
import upload from "./upload";
import deleteFile from "./delete";
import Image from "./Image";
import FileListItem from "./FileListItem";
import Label from "../Label";

class File extends React.Component {
  static propTypes = {
    type: oneOf(["image", "document"]).isRequired,
    formId: string.isRequired,
    path: string.isRequired,
    // start weird block
    // files will just be an array of objects when images is updated on the back end, objectName & idName will become redundent
    readFrom: string,
    objectName: string,
    idName: string,
    files: oneOfType([object, array, string]),
    // end weird block
    array: bool,
    params: object,
    label: node,
    change: func.isRequired,
    token: string.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    match: object.isRequired,
  };

  state = {
    uploading: false,
    error: false,
  };

  onDrop = files => {
    this.setState({
      uploading: true,
    });
    upload(files, this.props, {
      done: () =>
        this.setState({
          error: false,
          uploading: false,
        }),
      error: error =>
        this.setState({
          error,
          uploading: false,
        }),
    });
  };

  remove = i => {
    if (this.props.type === "document") {
      const newObjs = [...this.props.files];
      newObjs.splice(i, 1);
      this.props.change(this.props.formId, this.props.readFrom, newObjs);
      deleteFile(this.props.files[i].id, this.props);
    } else {
      this.props.change(this.props.formId, this.props.idName, "");
      this.props.change(this.props.formId, this.props.objectName, "");
    }
  };

  renderFiles = () => {
    if (
      !this.props.files ||
      (this.props.array && !Array.isArray(this.props.files))
    ) {
      return null;
    }
    let files = [];
    if (Array.isArray(this.props.files)) {
      files = this.props.files;
    } else {
      files.push(this.props.files);
    }
    return files.map((file, i) =>
      this.props.type === "image" ? (
        <Image
          key={i}
          i={i}
          remove={this.remove}
          src={
            file.url ? `${file.url}?raw=1` : `${window.IMG}/${file.path}?raw=1`
          }
          alt={file.name || "Lorem ipsum"}
        />
      ) : (
        <ul key={i}>
          <FileListItem
            i={i}
            remove={this.remove}
            name={file.name}
            url={file.url}
          />
        </ul>
      )
    );
  };

  renderInput = () => {
    if (!this.props.array && this.props.files) {
      return null;
    }
    return (
      <DropzoneArea
        type={this.props.type}
        onDrop={this.onDrop}
        uploading={this.state.uploading}
      />
    );
  };

  render() {
    return (
      <div>
        {this.props.label && <Label text={this.props.label} />}
        {this.renderInput()}
        {this.renderFiles()}
        {this.state.error ? <Failed text={this.state.error} leftAlign /> : null}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => ({
      token: token(state),
      files: getFormValues(props.formId)(state)
        ? getFormValues(props.formId)(state)[props.readFrom || props.objectName] // when images like docs just use readFrom
        : null,
    }),
    { change }
  )(File)
);
