import React from "react";
import CmsContent from "../../Components/CmsContent";

export default class CorporateSpecialOffers extends React.Component {
  render() {
    return (
      <div className="container">
          <CmsContent title="Special Offers" dataKey="specialOffers" showSearch={false}/>
      </div>
    );
  }
}
