import React, {useEffect, useState} from "react";
import DropzoneArea from "../Form/File/DropzoneArea";
import {ButtonPrimary, ButtonSpan} from "../Button";
import {WhitePanelWithHeader} from "../../ui";
import {formatDate} from "../../modules/dates";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {token} from "../../modules/auth";
import {storeSingleBookedEventConfirmation} from "../../redux/actions";
import Loading from "../Loading";

let VenueConfirmedInformation = ({
                                   deleteFile,
                                   event,
                                   storeSingleBookedEventConfirmation,
                                   token
                                 }) => {
  let [contractUploadedDocument, setContractUploadedDocument] = useState();
  let [submitting, setSubmitting] = useState(false);
  let uploadContractDocument = (file) => {
    setContractUploadedDocument(file);
  }



  const _deleteFile = (uploadDocument, loadedDocument) => {
    if (typeof uploadDocument !== 'undefined' && uploadDocument && uploadDocument.length > 0) {
      setContractUploadedDocument(null);
    } else if (typeof loadedDocument[0] !== 'undefined') {
      return deleteFile(loadedDocument[0].id)
    }
  }

  const fetchFileName = (uploadDocument) => {
    if (uploadDocument && typeof uploadDocument !== 'undefined' && typeof uploadDocument[0] !== 'undefined') {
      return uploadDocument[0].name
    }

    return null
  }

  const uploadFileContractFile = () => {
    setSubmitting(true)
    const formData = new FormData();
    if (contractUploadedDocument) {
      formData.append('contract_document', contractUploadedDocument[0])
    }
    return storeSingleBookedEventConfirmation(event.id, token, formData).then((response) => {
      setSubmitting(false);
      window.location.reload();
    })
  }

  return (
    <WhitePanelWithHeader
      title={'Confirmed Event Information'}
      collapsible
      defaultOpen={true}
    >
      <div className={'row  p-3 mb-5 '}>
        <div className="col col-w-1/2 flex  flex-col ">
          <h3>Venue Contract</h3>
          <p>Once the event is confirmed, please upload a copy of the contract here</p>
          {(typeof event.contract_file !== 'undefined' && event.contract_file.length > 0 && event.contract_uploader && !contractUploadedDocument) ?
            <React.Fragment>
              <p>Uploaded
                By: {event.contract_uploader.first_name + ' ' + event.contract_uploader.surname} ({event.contract_uploader.email})</p>
              <p>Uploaded At: {formatDate(event.contract_upload_date, 'DD/MM/YYYY hh:mm:ss')}</p>
              <a className={'font-bold'} href={event.contract_file_url} target={'_blank'}>Download Contract File</a>
            </React.Fragment>
            : "No documents have been uploaded"}

          {(contractUploadedDocument) ? (
            <div className={'flex flex-row'}>
              <p>{fetchFileName(contractUploadedDocument, event.contract_file)}</p>
              <React.Fragment>
                <ButtonSpan
                  className="pointer text-warning"
                  onClick={() => _deleteFile(contractUploadedDocument, event.contract_file)}
                >
                  <i className="ml-3 fas fa-trash-alt"/>
                </ButtonSpan>
              </React.Fragment>
            </div>
          ) : (typeof event.contract_file !== 'undefined' && event.contract_file.length > 0 && event.contract_uploader ?
              null :
              (event.event_status.code >= 30 ? null :
                <DropzoneArea
                  type="document_plus"
                  minWidth={'200px'}
                  minHeight={'100px'}
                  onDrop={uploadContractDocument}
                  uploading={false}
                />)
          )}
          {typeof event.contract_file === 'undefined' || event.contract_file.length === 0 ?
              <div>
                <ButtonPrimary  type="submit" small onClick={() => uploadFileContractFile()}>
                  {submitting ? <Loading inline/> : "Save"}
                </ButtonPrimary>
              </div>
          : null}

        </div>
      </div>
    </WhitePanelWithHeader>
  )
}

const mapStateToProps = state => ({
  token: token(state)
})

const mapDispatchToProps = {
  storeSingleBookedEventConfirmation
}

VenueConfirmedInformation = reduxForm({
  form: 'venueConfirmedInformationForm',
  enableReinitialize: true
})(VenueConfirmedInformation);

export default connect(mapStateToProps, mapDispatchToProps)(VenueConfirmedInformation);
