import ConferencePackageBreakdown from "./ConferencePackageBreakdown";
import SupplementalItemBreakdown from "./SupplementalItemBreakdown";
import AccommodationBreakdown from "./AccomodationBreakdown";
import MeetingRoomBreakdown from "./MeetingRoomBreakdown";
import React, {useEffect, useState} from 'react';
import CommissionBreakdown from "./CommissionBreakdown";
import EquipmentBreakdown from "./EquipmentBreakdown";
import AdditionalCostingsBreakdown from "./AdditionalCostingsBreakdown";

let PageBreakdown = (props) => {
    const {currentVatRateForCommission} = props;
    let currency = props.enquiryVenueData.currency;

    const [meetingRoomsTotal, setMeetingRoomsTotal] = useState(0)
    const [meetingRoomsCommissionTotal, setMeetingRoomsCommissionTotal] = useState(0)
    const [conferencePackagingTotal, setConferencePackagingTotal] = useState(0)
    const [conferencePackagingCommissionTotal, setConferencePackagingCommissionTotal] = useState(0)
    const [supplementaryItemTotal, setSupplementaryItemTotal] = useState(0)
    const [supplementaryItemCommissionTotal, setSupplementaryItemCommissionTotal] = useState(0)
    const [accommodationTotal, setAccommodationTotal] = useState(0)
    const [accommodationCommissionTotal, setAccommodationCommissionTotal] = useState(0)
    const [equipmentTotal, setEquipmentTotal] = useState(0);
    const [equipmentCommissionTotal, setEquipmentCommissionTotal] = useState(0);
    const [additionalCostingsTotal, setAdditionalCostingsTotal] = useState(0);
    const [additionalCostingsCommissionTotal, setAdditionalCostingsCommissionTotal] = useState(0);
    const [meetingRoomsCommissionTotalGBP, setMeetingRoomsCommissionTotalGBP] = useState(0)
    const [conferencePackagingCommissionTotalGBP, setConferencePackagingCommissionTotalGBP] = useState(0)
    const [supplementaryItemCommissionTotalGBP, setSupplementaryItemCommissionTotalGBP] = useState(0)
    const [accommodationCommissionTotalGBP, setAccommodationCommissionTotalGBP] = useState(0)
    const [equipmentCommissionTotalGBP, setEquipmentCommissionTotalGBP] = useState(0)
    const [additionalCostingsCommissionTotalGBP, setAdditionalCostingsCommissionTotalGBP] = useState(0)

    const [conferenceValueChanged, setConferenceValueChanged] = useState(false)
    const [accommodationValueChanged, setAccommodationValueChanged] = useState(false)
    const [cateringValueChanged, setCateringValueChanged] = useState(false)
    const [meetingRoomValueChanged, setMeetingRoomValueChanged] = useState(false)
    const [equipmentValueChanged, setEquipmentValueChanged] = useState(false)
    const [additionalCostingsValueChanged, setAdditionalCostingsValueChanged] = useState(false)

    useEffect(() => {
        props.setAnyValueChanged(false);
        if(conferenceValueChanged || accommodationValueChanged || cateringValueChanged || meetingRoomValueChanged || equipmentValueChanged)
        {
            props.setAnyValueChanged(true);
        }
    }, [conferenceValueChanged, accommodationValueChanged, cateringValueChanged, meetingRoomValueChanged, equipmentValueChanged]);

    const renderWarning = () => {
        return (
            <div className="bg-edge-light-blue p-2 mb-4 mt-2 font-bold text-sm"><p className="mb-0">As you have adjusted the number required from your original brief, the venue may need to requote this
                element </p></div>
        )
    }
    return (
    <div>
            {
            props.enquiryVenueData.proposal_data.conference_packages && props.enquiryVenueData.proposal_data.conference_packages.length > 0 ?
                <div className="mb-10">
                        <h2 className="mt-2 text-xl">Conference Packages</h2>
                        <ConferencePackageBreakdown
                            brief={props.brief}
                            enquiryVenueData={props.enquiryVenueData}
                            currentVatRateForCommission={currentVatRateForCommission}
                            vatRates={props.vatRates}
                            conferencePackages={props.enquiryVenueData.proposal_data.conference_packages}
                            commissionItems={props.enquiryVenueData.proposal_data.commission_items}
                            meetingRooms={props.enquiryVenueData.proposal_data.meeting_rooms}
                            currentVat={props.currentVat}
                            status={props.enquiryVenueData.status}
                            renderCheckBox={props.renderCheckBox}
                            setConferencePackagingTotal={setConferencePackagingTotal}
                            setConferencePackagingCommissionTotal={setConferencePackagingCommissionTotal}
                            setConferencePackagingCommissionTotalGBP={setConferencePackagingCommissionTotalGBP}
                            currencyFormatter={props.currencyFormatter}
                            showCommission={props.showCommission}
                            setConferenceValueChanged={setConferenceValueChanged}
                            initialValues={props.initialValues}
                            currency={currency}
                        />
                        {conferenceValueChanged ?
                           renderWarning()
                            : null}
                </div>
                :

                null
            }
            {
                    props.enquiryVenueData.proposal_data.meeting_rooms && props.enquiryVenueData.proposal_data.meeting_rooms.length > 0 ?
                        <div className="mb-10">
                                <h2 className="mt-2 text-xl">Meeting Rooms</h2>
                                <MeetingRoomBreakdown
                                    brief={props.brief}
                                    enquiryVenueData={props.enquiryVenueData}
                                    currentVatRateForCommission={currentVatRateForCommission}
                                    vatRates={props.vatRates}
                                    meetingRooms={props.enquiryVenueData.proposal_data.meeting_rooms}
                                    prices={props.enquiryVenueData.proposal_data.summary.summary_items.filter(item => item.step_id === 3)}
                                    currentVat={props.currentVat}
                                    commissionItems={props.enquiryVenueData.proposal_data.commission_items}
                                    renderCheckBox={props.renderCheckBox}
                                    status={props.enquiryVenueData.status}
                                    meetingRoomRemoveRow={props.meetingRoomRemoveRow}
                                    setMeetingRoomsTotal={setMeetingRoomsTotal}
                                    setMeetingRoomsCommissionTotal={setMeetingRoomsCommissionTotal}
                                    setMeetingRoomsCommissionTotalGBP={setMeetingRoomsCommissionTotalGBP}
                                    currencyFormatter={props.currencyFormatter}
                                    showCommission={props.showCommission}
                                    setMeetingRoomChanged={setMeetingRoomValueChanged}
                                    initialValues={props.initialValues}
                                    equipment={props.enquiryVenueData.proposal_data && props.enquiryVenueData.proposal_data.equipment ?props.enquiryVenueData.proposal_data.equipment : null}
                                    currency={currency}
                                />
                            {meetingRoomValueChanged ?
                                renderWarning()
                                : null}
                        </div>
                        :

                        null
            }
        {
            props.enquiryVenueData.proposal_data.equipment && props.enquiryVenueData.proposal_data.equipment.length > 0 ?
                <div className="mb-10">
                    <h2 className="mt-2 text-xl">Room Equipment</h2>
                    <EquipmentBreakdown
                        currentVatRateForCommission={currentVatRateForCommission}
                        vatRates={props.vatRates}
                        equipment={props.enquiryVenueData.proposal_data.equipment }
                        currentVat={props.currentVat}
                        commissionItems={props.enquiryVenueData.proposal_data.commission_items}
                        currencyFormatter={props.currencyFormatter}
                        status={props.enquiryVenueData.status}
                        renderCheckbox={props.renderCheckBox}
                        setEquipmentTotal={setEquipmentTotal}
                        setEquipmentCommissionTotal={setEquipmentCommissionTotal}
                        setEquipmentCommissionTotalGBP={setEquipmentCommissionTotalGBP}
                        setEquipmentValueChanged={setEquipmentValueChanged}
                        initialValues={props.initialValues}
                        showCommission={props.showCommission}
                        currency={currency}
                    />
                    {equipmentValueChanged ?
                        renderWarning()
                        : null}
                </div>

                :
                null
        }



            {
                props.enquiryVenueData.proposal_data.supplementary_items && props.enquiryVenueData.proposal_data.supplementary_items.length > 0 ?
                    <div className="mb-10">
                        <h2 className="mt-2 text-xl">Additional Requirements</h2>
                        <SupplementalItemBreakdown
                            currentVatRateForCommission={currentVatRateForCommission}
                            vatRates={props.vatRates}
                            supplementalItems={props.enquiryVenueData.proposal_data.supplementary_items}
                            currentVat={props.currentVat}
                            status={props.enquiryVenueData.status}
                            commissionItems={props.enquiryVenueData.proposal_data.commission_items}
                            renderCheckBox={props.renderCheckBox}
                            setSupplementaryItemTotal={setSupplementaryItemTotal}
                            setSupplementaryItemCommissionTotal={setSupplementaryItemCommissionTotal}
                            setSupplementaryItemCommissionTotalGBP={setSupplementaryItemCommissionTotalGBP}
                            currencyFormatter={props.currencyFormatter}
                            showCommission={props.showCommission}
                            setCateringValueChanged={setCateringValueChanged}
                            initialValues={props.initialValues}
                            currency={currency}
                        />
                        {cateringValueChanged ?
                            renderWarning()
                            : null}
                    </div>
                    :

                    null
            }

        {props.enquiryVenueData.proposal_data.additional_items.filter((ai) => {
            return ai.is_in_proposal_costs
        } ).length > 0 ?

            <div className="mb-10">
                <h2 className="mt-2 text-xl">Additional Costings</h2>
                <AdditionalCostingsBreakdown
                    currentVatRateForCommission={currentVatRateForCommission}
                    vatRates={props.vatRates}
                    additionalCostings={props.enquiryVenueData.proposal_data.additional_items.filter((ai) => {
                        return ai.is_in_proposal_costs
                    } )}
                    commissionItems={props.enquiryVenueData.proposal_data.commission_items}
                    currentVat={props.currentVat}
                    status={props.enquiryVenueData.status}
                    renderCheckBox={props.renderCheckBox}
                    setAdditionalCostingsTotal={setAdditionalCostingsTotal}
                    setAdditionalCostingsCommissionTotal={setAdditionalCostingsCommissionTotal}
                    setAdditionalCostingsCommissionTotalGBP={setAdditionalCostingsCommissionTotalGBP}
                    currencyFormatter={props.currencyFormatter}
                    showCommission={props.showCommission}
                    setAdditionalCostingsValueChanged={setAdditionalCostingsValueChanged}
                    initialValues={props.initialValues}
                    currency={currency}
                />
                {additionalCostingsValueChanged ?
                    renderWarning()
                    : null}
            </div>

        : null }

            {
                props.enquiryVenueData.proposal_data.accommodations && props.enquiryVenueData.proposal_data.accommodations.length > 0 ?
                    <div className="mb-10">
                        <h2 className="mt-2 text-xl">Accommodation</h2>
                        <AccommodationBreakdown
                            currentVatRateForCommission={currentVatRateForCommission}
                            vatRates={props.vatRates}
                            accommodations={props.enquiryVenueData.proposal_data.accommodations}
                            meetingRooms={props.enquiryVenueData.proposal_data.meeting_rooms}
                            commissionItems={props.enquiryVenueData.proposal_data.commission_items}
                            currentVat={props.currentVat}
                            status={props.enquiryVenueData.status}
                            renderCheckBox={props.renderCheckBox}
                            accommodationRemoveRow={props.accommodationRemoveRow}
                            accommodationNoOfDelegates={props.accommodationNoOfDelegates}
                            setAccommodationTotal={setAccommodationTotal}
                            setAccommodationCommissionTotal={setAccommodationCommissionTotal}
                            setAccommodationCommissionTotalGBP={setAccommodationCommissionTotalGBP}
                            currencyFormatter={props.currencyFormatter}
                            showCommission={props.showCommission}
                            setAccommodationValueChanged={setAccommodationValueChanged}
                            initialValues={props.initialValues}
                            currency={currency}
                        />
                        {accommodationValueChanged ?
                            renderWarning()
                            : null}
                    </div>
                    :

                    null
            }



        <div className={'flex justify-between mb-6 font-heading border-b border-grey-md'}>
            <p className={'font-bold text-xl text-left'}>
                {currency.currency_code === 'GBP' ? `Grand Total (inc. VAT)` : `Grand Total (inc. Tax)`}
            </p>
            <p className={'font-bold text-xl text-left mr-8'}>{props.currencyFormatter.format(meetingRoomsTotal + equipmentTotal + supplementaryItemTotal + conferencePackagingTotal + accommodationTotal + additionalCostingsTotal)}</p>
        </div>

        {props.showCommission ?
            <div className={'p-4 border border-off-white bg-light-grey'}>
                <h2 className={'text-xl mb-2'}>Commission Payable</h2>
                <CommissionBreakdown
                    commissionItems={props.enquiryVenueData.proposal_data.commission_items}
                    currencyFormatter={props.currencyFormatter}
                    meetingRoomsCommissionTotal={meetingRoomsCommissionTotal}
                    conferencePackagingCommissionTotal={conferencePackagingCommissionTotal}
                    supplementaryItemCommissionTotal={supplementaryItemCommissionTotal}
                    accommodationCommissionTotal={accommodationCommissionTotal}
                    equipmentCommissionTotal={equipmentCommissionTotal}
                    additionalCostingsCommissionTotal={additionalCostingsCommissionTotal}
                    currency={currency}
                    meetingRoomsCommissionTotalGBP={meetingRoomsCommissionTotalGBP}
                    conferencePackagingCommissionTotalGBP={conferencePackagingCommissionTotalGBP}
                    supplementaryItemCommissionTotalGBP={supplementaryItemCommissionTotalGBP}
                    accommodationCommissionTotalGBP={accommodationCommissionTotalGBP}
                    equipmentCommissionTotalGBP={equipmentCommissionTotalGBP}
                    additionalCostingsCommissionTotalGBP={additionalCostingsCommissionTotalGBP}
                />
            </div>
            : null}

    </div>
)

}

export default PageBreakdown
