import React from "react";
import {ButtonBlack, ButtonPrimary, ButtonPrimaryRed} from "Components/Button";

const RfpSubmit = ({
                     page,
                     prevPage,
                     enabled = true,
                     hasVenues,
                     editEnquiry,
                     setSubmitToAgent,
                     setEnquiryToSubmitted,
                     isSuperAgent = false,
                     isInternal = null,
                     isAgency = null,
                     setSubmitToDraft,
                     isVenueUser = false,
                     enquiry,
                       requiresAuthorising,
                       setRequiresAuthorising
                   }) => {
  let buttonText = "Save Draft";
  if (enquiry && enquiry.enquiry_status.code !== "DRAFT") {
    buttonText = "Save";
  }
  let showSendEnquiry = requiresAuthorising ? false :  (!enquiry && hasVenues) || (enquiry && enquiry.enquiry_status.code === "DRAFT" && hasVenues);

  /**
   * If a brief has no venues and goes to auth, and is authorized, an agent input is required on the draft to add venues.
   * This allows them to decide whether to push back through Authorization workflow, or just add venues and move to new enquiries
   * @type {false|boolean}
   */
  let saveAndMoveScreen = (page === 2 && enquiry && enquiry.enquiry_status.code === "DRAFT" &&  enquiry.authorised_state === 'Authorised' && setRequiresAuthorising && enquiry.authorise_id !== null  && (isAgency || isInternal || isSuperAgent));
  if(saveAndMoveScreen)
  {
      return (
          <div className="justify-end flex flex-col md:flex-row">
              {prevPage && (
                  <ButtonBlack
                      onClick={() => prevPage()}
                      classes="px-5 md:mr-8"
                      style={{height: 57, lineHeight: "57px"}}
                  >
                      <i className="pr-2 fas fa-angle-left"></i>
                      Back
                  </ButtonBlack>
              )}
              {
                  page === 2 && enquiry && enquiry.authorised_state === 'Authorised' && enquiry.enquiry_status.code === "DRAFT" ? null :
                      <ButtonPrimary
                          classes={`px-5 md:mr-8`}
                          submit
                          // disabled={!enabled}
                          onClick={() => {
                              if (page === 2 && buttonText === "Save Draft") {
                                  setRequiresAuthorising(false)
                                  setSubmitToDraft(true)
                                  setSubmitToAgent(false)
                              }
                              // if (page === 2) {
                              //   setSubmitToDraft(true)
                              // }
                          }}
                          style={{height: 57, lineHeight: "57px"}}
                          disabled={isVenueUser}
                      >
                          {page === 2 ? buttonText : "Tell us more"}
                      </ButtonPrimary>
              }
              <ButtonPrimaryRed
                  classes="px-5 ml-2 "
                  submit
                  // disabled={!enabled}
                  onClick={() => {
                      setSubmitToAgent(false)
                      setRequiresAuthorising(true)
                  }}
                  style={{height: 57, lineHeight: "57px"}}
              >
                  Submit for approval AGAIN
              </ButtonPrimaryRed>
              {
                  isInternal || isAgency || isSuperAgent ?
                  <ButtonPrimary
                      classes="px-5 ml-2 "
                      submit
                      // disabled={!enabled}
                      onClick={() => {
                          setSubmitToAgent(false)
                          setRequiresAuthorising(false)
                      }}
                      style={{height: 57, lineHeight: "57px"}}
                  >
                      Save Brief
                  </ButtonPrimary>

                  : null
              }

              {hasVenues ?
                  <ButtonPrimary
                      classes="px-5 ml-2"
                      submit
                      // disabled={!enabled}
                      onClick={() => {
                          setSubmitToAgent(false)
                          setRequiresAuthorising(false)
                          setEnquiryToSubmitted(true)
                      }}
                      style={{height: 57, lineHeight: "57px"}}
                  >
                      Save and move to new enquiries
                  </ButtonPrimary>
                  : null }
          </div>
      )
  }
  return (
    <div className="justify-end flex flex-col md:flex-row">
      {prevPage && (
        <ButtonBlack
          onClick={() => prevPage()}
          classes="px-5 md:mr-8"
          style={{height: 57, lineHeight: "57px"}}
        >
          <i className="pr-2 fas fa-angle-left"></i>
          Back
        </ButtonBlack>
      )}
        {
            page === 2 && enquiry && enquiry.authorised_state === 'Authorised' && enquiry.enquiry_status.code === "DRAFT" &&  enquiry.authorise_id !== null? null :
                <ButtonPrimary
                    classes={`px-5 md:mr-8`}
                    submit
                    // disabled={!enabled}
                    onClick={() => {
                        if (page === 2 && buttonText === "Save Draft") {
                            setRequiresAuthorising(false)
                            setSubmitToDraft(true)
                            setSubmitToAgent(false)
                        }
                        // if (page === 2) {
                        //   setSubmitToDraft(true)
                        // }
                    }}
                    style={{height: 57, lineHeight: "57px"}}
                    disabled={isVenueUser}
                >
                    {page === 2 ? buttonText : "Tell us more"}
                </ButtonPrimary>
        }

      {(showSendEnquiry && page === 2) && (
        <ButtonPrimary
          classes="px-5"
          submit
          // disabled={!enabled}
          style={{height: 57, lineHeight: "57px"}}
          onClick={() => setEnquiryToSubmitted(true)}
        >
          Send Enquiry
        </ButtonPrimary>
      )}
      {(!hasVenues && page === 2 && !requiresAuthorising && (!enquiry || enquiry && enquiry.enquiry_status.code === "DRAFT")) && (
        <ButtonPrimary
          classes="px-5 ml-2"
          submit
          // disabled={!enabled}
          onClick={() => setSubmitToAgent(true)}
          style={{height: 57, lineHeight: "57px"}}
        >
          Submit to Agent
        </ButtonPrimary>
      )}
        {(requiresAuthorising && page === 2 && (!enquiry || enquiry && (enquiry.authorised_state !== 'Authorised' || enquiry.authorise_id === null))) && (
        <ButtonPrimary
            classes="px-5 ml-2 "
            submit
            // disabled={!enabled}
            onClick={() => {
                setSubmitToAgent(false)
                setRequiresAuthorising(true)
            }}
            style={{height: 57, lineHeight: "57px"}}
        >
            Submit for Approval
        </ButtonPrimary>
        )}
    </div>
  );
};

export default RfpSubmit;
