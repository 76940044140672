import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {token} from "../../../modules/auth";
import {fetchAirTableDataSingle} from "../../../services/airtableService/fetchAirTableData";
import {CharityAuctionSlideSingle} from "./CharityAuctionSlide";
import Loading from "../../../Components/Loading";
import {generalInteraction} from "../../../modules/analytics";

const mapDispatchToProps = {
    generalInteraction,
};

const mapStateToProps = state => ({
    token: token(state),
});

let CharityAuctionSingle = (props) => {
    const {generalInteraction} = props;
    let [airTableData, setAirTableData] = useState([])
    let [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchAirTableDataSingle(props.venueId, props.token).then((response) => {
            setAirTableData(response)
            setLoading(false)
        })

    }, []);

    if (loading) {
        return <Loading/>
    }

    if(airTableData === false || airTableData.length === 0 ) {
        return <></>
    }


    return (
        <div className={'border-edge-blue border-2 border-solid mb-5 shadow'}>
            <div className="bg-edge-blue text-white text-center py-5 px-2">
                <h2 className={'font-bold text-md'}>Charity Auction 2023</h2>
            </div>
            <div className="bg-white p-4 text-center">
                {airTableData &&
                    airTableData.map((auction, i, row) => {
                        return (
                            <div key={auction.venueId}>
                                <CharityAuctionSlideSingle
                                    key={auction.venueId}
                                    auction={auction}
                                    generalInteraction={generalInteraction}
                                />
                                {(i + 1 !== row.length) ?
                                    <hr className={'my-5'}/> : null
                                }
                            </div>
                        );
                    })}
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CharityAuctionSingle);