import {
    calculateCommissionAs,
    calculateCommissionForCalculator,
    calculateCommissionFromUnit, calculatePricePerNightPerPerson, calculateTotalAs,
} from "../../../rfp/Confirmation/Helpers/calculateTotals";
import {
    commissionFormItem,
    getCommissionItemsForStep, getDescriptionValues
} from "./proposalDataGatherer";
import {
    APPLIED_ON,
    COMMISSION,
    COMMISSION_VALUE,
    PER_PERSON,
    PER_PERSON_VAT,
    PRICE_NET, PRICE_TOTAL,
    PRICE_VAT,
    QUANTITY, VAT
} from "../components/FinalCommissionValuesModal";


function createMeetingRoomCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat, currency)
{
    let meetingRoomsCommissionData = getCommissionItemsForStep(enquiryVenueData, 3);
    if(meetingRoomsCommissionData) {
        return  enquiryVenueData.proposal_data.meeting_rooms.forEach( mr =>
        {
            let hasSrcCurVals = !!mr[`price_total_net_src_cur`];
            let totals =  calculateTotalAs('Net',vatRates, mr.vat_rate.id,  parseFloat(Number(mr[`price_total_net${currency && hasSrcCurVals ? '_src_cur' : ''}`])).toFixed(2));
            let commissionTotals =  calculateCommissionAs(
                includesVat,
                vatRates,
                includesVatRate,
                includesVat,
                meetingRoomsCommissionData ? calculateCommissionForCalculator(
                        false,
                        Number(mr[`price_total_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]),
                        mr.vat_rate.rate,
                        meetingRoomsCommissionData.rate,
                        'Net',
                    )
                : {total: 0, vat: 0})
            commissionFormItem(
                `${mr?.room?.name} (${mr?.location_or_floor} - ${mr?.room_style?.name}) ${mr?.room_purpose.id === 1 ? '(Main Meeting Room)' : ''} ${mr?.room_purpose.id === 1 && !mr?.price_net ? '(included in package)'
                    :  getDescriptionValues(
                        null,
                        null,
                        Number(mr.price_total_net),
                        null,
                        currency
                    )}`,
                null,
                null,
                null,
                Number(mr[`price_total_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
                totals.vat ,
                includesVat,
                totals.total,
                mr.price_vat_rate_id ? mr.price_vat_rate_id :  41,
                meetingRoomsCommissionData ? meetingRoomsCommissionData.rate : 0,
                'Net',
                commissionTotals,
                'meeting_rooms',
                mr.id,
                formValues
            );
            if(!!mr.overnight_room_data) {
                let totals =  calculateTotalAs( 'Net',vatRates, mr.vat_rate.id,  parseFloat( Number(mr.overnight_room_data[`overnight_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`])).toFixed(2));
                let commissionTotals =  calculateCommissionAs(
                    includesVat,
                    vatRates,
                    includesVatRate,
                    includesVat,
                    meetingRoomsCommissionData ? calculateCommissionForCalculator(
                            false,
                            Number(mr.overnight_room_data[`overnight_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
                            mr.vat_rate.rate,
                            meetingRoomsCommissionData.rate,
                            'Net',
                        )
                        : {total: 0, vat: 0})
                commissionFormItem(
                    `Overnight Hold`,
                    null,
                    null,
                    null,
                    Number(mr.overnight_room_data[`overnight_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
                    totals.vat ,
                    includesVat,
                    totals.total,
                    mr.overnight_room_data.overnight_room_data_vat_rate_id ? mr.overnight_room_data.overnight_room_data_vat_rate_id : 41,
                    meetingRoomsCommissionData ? meetingRoomsCommissionData.rate : 0,
                    'Net',
                    commissionTotals,
                    'meeting_rooms',
                    mr.id + '_overnight',
                    formValues
                );
            }
            if(!!mr.setup_data) {
                let totals =  calculateTotalAs( 'Net',vatRates, mr.vat_rate.id,  parseFloat( Number(mr.setup_data[`setup_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`])).toFixed(2));
                let commissionTotals =  calculateCommissionAs(
                    includesVat,
                    vatRates,
                    includesVatRate,
                    includesVat,
                    meetingRoomsCommissionData ? calculateCommissionForCalculator(
                            false,
                            Number(mr.setup_data[`setup_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
                            mr.vat_rate.rate,
                            meetingRoomsCommissionData.rate,
                            'Net',
                        )
                        : {total: 0, vat: 0})
                commissionFormItem(
                    `Setup Cost`,
                    null,
                    null,
                    null,
                    Number(mr.setup_data[`setup_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
                    totals.vat ,
                    includesVat,
                    totals.total,
                    mr.setup_data.setup_data_vat_rate_id ? mr.setup_data.setup_data_vat_rate_id  : 41,
                    meetingRoomsCommissionData ? meetingRoomsCommissionData.rate : 0,
                    'Net',
                    commissionTotals,
                    'meeting_rooms',
                    mr.id + '_setup',
                    formValues
                );
            }
            if(!!mr.breakdown_data) {
                let totals =  calculateTotalAs( 'Net',vatRates, mr.vat_rate.id,  parseFloat( Number(mr.breakdown_data[`breakdown_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`])).toFixed(2));
                let commissionTotals =  calculateCommissionAs(
                    includesVat,
                    vatRates,
                    includesVatRate,
                    includesVat,
                    meetingRoomsCommissionData ? calculateCommissionForCalculator(
                            false,
                            Number(mr.breakdown_data[`breakdown_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
                            mr.vat_rate.rate,
                            meetingRoomsCommissionData.rate,
                            'Net',
                        )
                        : {total: 0, vat: 0})
                commissionFormItem(
                    `Breakdown Cost`,
                    null,
                    null,
                    null,
                    Number(mr.breakdown_data[`breakdown_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
                    totals.vat ,
                    includesVat,
                    totals.total,
                    mr.breakdown_data.breakdown_price_vat_rate_id ? mr.breakdown_data.breakdown_price_vat_rate_id : 41,
                    meetingRoomsCommissionData ? meetingRoomsCommissionData.rate : 0,
                    'Net',
                    commissionTotals,
                    'meeting_rooms',
                    mr.id + '_breakdown',
                    formValues
                );
            }
        });
    }

}
function calculateMeetingRoomCommission(mr, enquiryVenueData)
{
    let meetingRoomsCommissionData = getCommissionItemsForStep(enquiryVenueData, 3);

    return  calculateCommissionFromUnit(
        meetingRoomsCommissionData.include_vat,
        mr.price_total_net,
        mr.price_total_vat,
        20,
        1,
        parseFloat(meetingRoomsCommissionData.rate).toFixed(2),
        meetingRoomsCommissionData.applied_on,
        null,
        0);

}

function calculateMeetingRoomOvernightHoldCommission(mr, enquiryVenueData)
{
    let meetingRoomsCommissionData = getCommissionItemsForStep(enquiryVenueData, 3);
    if(!!mr.overnight_room_data) {
        return calculateCommissionFromUnit(
            meetingRoomsCommissionData.include_vat,
            mr.overnight_room_data.overnight_price_net,
            mr.overnight_room_data.overnight_price_vat,
            20,
            1,
            parseFloat(meetingRoomsCommissionData.rate).toFixed(2),
            meetingRoomsCommissionData.applied_on,
            null,
            0);
    }
    return 0;
}

function buildInitialMeetingRoomItems(enquiryVenueData, initialCommissionItems)
{
    enquiryVenueData.proposal_data.meeting_rooms.map(mr => {
        if(mr.room_purpose.id === 1 && !mr.price_total_net ) {
            initialCommissionItems.push({id: mr.id, type: 'meeting_rooms', disabled: [], readonly: [PRICE_NET, PRICE_VAT,PER_PERSON_VAT,VAT,COMMISSION,APPLIED_ON,COMMISSION_VALUE, PRICE_TOTAL]})

        }else {
            initialCommissionItems.push({id: mr.id, type: 'meeting_rooms', disabled: [], readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]})

        }
        if(!!mr.overnight_room_data) {
            initialCommissionItems.push({id: mr.id + '_overnight', type: 'meeting_rooms', disabled: [], readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]})
        }
        if(!!mr.setup_data) {
            initialCommissionItems.push({id: mr.id + '_setup', type: 'meeting_rooms', disabled: [], readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]})
        }
        if(!!mr.breakdown_data) {
            initialCommissionItems.push({id: mr.id + '_breakdown', type: 'meeting_rooms', disabled: [], readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]})
        }
    })


}

function handleMeetingRoomFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
{
    if(editedItem.includes('meeting') && editedItem.includes('rooms'))
    {
        let mr = enquiryVenueData.proposal_data.meeting_rooms.find(mr => parseInt(mr.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'meeting_rooms_' + id,
            id,
            value,
            mr.price_total_net,
            Number(mr.vat_rate.rate)
        )
    }

    if(editedItem.includes('meeting') && editedItem.includes('overnight'))
    {
        let mr = enquiryVenueData.proposal_data.meeting_rooms.find(mr => parseInt(mr.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'meeting_rooms_' + id + '_overnight',
            id,
            value,
            mr.overnight_room_data.overnight_price_net,
            Number(mr.vat_rate.rate)
        )
    }

    if(editedItem.includes('meeting') && editedItem.includes('setup'))
    {
        let mr = enquiryVenueData.proposal_data.meeting_rooms.find(mr => parseInt(mr.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'meeting_rooms_' + id + '_setup',
            id,
            value,
            mr.setup_data.setup_price_net,
            Number(mr.setup_data.vat_rate.rate)
        )
    }

    if(editedItem.includes('meeting') && editedItem.includes('breakdown'))
    {
        let mr = enquiryVenueData.proposal_data.meeting_rooms.find(mr => parseInt(mr.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'meeting_rooms_' + id + '_breakdown',
            id,
            value,
            mr.breakdown_data.breakdown_price_net,
            Number(mr.breakdown_data.vat_rate.rate)
        )
    }
}

export
{
    createMeetingRoomCommissionItems,
    buildInitialMeetingRoomItems,
    handleMeetingRoomFieldUpdate

};