import React, {useEffect} from "react";
import { connect } from "react-redux";
import {updateEventCarbonImpact} from "../../services/venueService/carbonImpactService";
import {WhitePanelWithHeader} from "../../ui";
import CarbonImpactEventInformation from "../../pages/admin/corporate/events/components/CarbonImpactEventInformation";
import {ButtonPrimary} from "../Button";
import { Field, reduxForm, change, getFormValues} from 'redux-form'
import { useDispatch } from "react-redux";
import {isAgency, isVenue, token} from "../../modules/auth";

const mapStateToProps = (state) => {
    return {
        ...state.notes,
        token: token(state),
    };
};
let VenueUserCarbonInformation = ({event, token, handleSubmit}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if(event){
            dispatch(change("RfpBriefSectionForm", 'estimated_carbon_impact_value', event.estimated_carbon_impact_value ?? null));
            dispatch(change("RfpBriefSectionForm", 'total_carbon_impact_value', event.total_carbon_impact_value ?? null));
        }
    }, []);
    let onSubmit = (values) => {
        updateEventCarbonImpact(event.id, values, token).then((response) => {
            let estimatedValue = response.data.estimated_carbon_impact_value ? response.data.estimated_carbon_impact_value :
                dispatch(change("RfpBriefSectionForm", 'estimated_carbon_impact_value', response.data.estimated_carbon_impact_value));
            dispatch(change("RfpBriefSectionForm", 'total_carbon_impact_value', response.data.total_carbon_impact_value));

        })
    }


    return (
        <WhitePanelWithHeader
            title={'Carbon Impact'}
            collapsible
            defaultOpen={true}
        >
            <form  onSubmit={handleSubmit(onSubmit)}>
                <CarbonImpactEventInformation event={event} />
                <ButtonPrimary  type="submit" small >
                    Save
                </ButtonPrimary>
            </form>
        </WhitePanelWithHeader>

    )
}



VenueUserCarbonInformation = reduxForm({
    form: 'RfpBriefSectionForm',
    enableReinitialize: true
})(VenueUserCarbonInformation);
export default (connect(
    mapStateToProps,
    null,
)(VenueUserCarbonInformation));