import Loading from "../Loading";
import {Link} from "react-router-dom";
import {capitalize} from "../../modules/string-tools";
import List from "../../pages/admin/content-management/List";
import React from "react";
import classNames from "classnames";
import CorporateAccountListContainer from "../../pages/admin/corporate/CorporateAccountListContainer";
import {curry} from "lodash";


const renderHeader = (header) => {
    if(typeof header !== 'undefined'){
        return (
            <React.Fragment>
                <h1 className="mb-5">{header}</h1>
            </React.Fragment>
        )
    }
   return null;
};

const sections = (sectionTypes) => (
    sectionTypes.map(type => {
        const name = capitalize(type.name.replace(/-/g, " "));

        return {
            name,
            component: type.component,
            admin: typeof type.admin !== 'undefined' && type.admin
        };
    })
)

const  currentTab = (sectionTypes, match) => {
    return sectionTypes.find((item) => {
        const path = getPathFromName(item.name);
        return (path === match.params.section)
    })
}

const getPathFromName = name =>
    name
        .toLowerCase()
        .replace(/ /g, "-")
        .replace("&", "and");

let AdminPageWIthSideBarDynamic = ({headerText, sectionTypes, basePath, match, children, account, hideSideBar = false}) =>  {
    let CurrentTab = currentTab(sectionTypes, match) ? currentTab(sectionTypes, match).component : '';
    if(hideSideBar)
    {
        return (
            <div className={`w-full px-12`}>
                {children ? children :  <CurrentTab match={match} account={account}/>}

            </div>
        )
    }
    return (
        <div className="py-5">
                {renderHeader(headerText)}
                <div className="flex">
                    <div className="pt-10">
                        <nav className="bg-black">
                            {sections(sectionTypes).map(item => {
                                const path = getPathFromName(item.name);
                                return (
                                    <Link
                                        key={path}
                                        className={classNames(
                                            "block font-heading px-4 py-2 flex-no-shrink xxl:text-lg xxl:py-3",
                                            {
                                                "text-black": path === match.params.section,
                                                "text-dull-white":
                                                    path !== match.params.section,
                                                "bg-dull-white":
                                                    path === match.params.section,
                                            }
                                        )}
                                        to={basePath + path}
                                    >
                                        {`${item.admin  ? '(Admin) ' : ''}${item.name}`}
                                    </Link>
                                );
                            })}
                        </nav>
                    </div>
                    <div className={`w-full px-12`}>
                        {children ? children :  <CurrentTab match={match} account={account}/>}

                    </div>

                </div>


        </div>
    );


}
export default AdminPageWIthSideBarDynamic;