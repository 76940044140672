import axios from "axios";
import { getMimeType } from "../utils";

const handleDownload = async (fileName, url, type = "application/pdf", token) => {
  try {
    const response = await axios.get(url, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    saveData(type, response.data, fileName);
  } catch (err) {
    alert("Sorry there was a problem fetching the file");
  }
};

const saveData = (function() {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.cssText = "display: none";
  return function(type, data, fileName) {
    let blob = fetchBlob(type, data);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const fetchBlob = (type, data) => {
  let mimeType = getMimeType(type);
  return new Blob([data], {
    type: mimeType,
  });
};

export {
  saveData,
  handleDownload,
};