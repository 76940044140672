import { venueInteractions } from "./utils";
import getDatesArray from "../get-dates-array";
import {VENUE_TAGS} from "../tags";

export default (id, start, end) => state => {
  const dates = getDatesArray(start, end);
  const engagements = venueInteractions(id)(state).filter(e =>
    dates.includes(e.day)
  );
  const data = {
    telephone: engagements.filter(e =>
      [
        VENUE_TAGS.TELEPHONE.CLICK_CONTACT_PHONE,
        VENUE_TAGS.TELEPHONE.VIEW_PHONE
      ].includes(e.type)
    ).length,
    email: engagements.filter(e =>
      [
        VENUE_TAGS.EMAIL.CLICK_CONTACT_EMAIL,
        VENUE_TAGS.EMAIL.VIEW_EMAIL
      ].includes(e.type)
    ).length,
    social: engagements.filter(e =>
      [
        VENUE_TAGS.SOCIALS.VIEW_TWITTER,
        VENUE_TAGS.SOCIALS.VIEW_INSTAGRAM,
        VENUE_TAGS.SOCIALS.VIEW_TRIPADVISOR
      ].includes(e.type)
    ).length,
    web_enquiries: engagements.filter(e => e.type.startsWith("rfq_")).length,
    website: engagements.filter(e => e.type === VENUE_TAGS.WEBSITE.VIEW_WEBSITE).length,
  };
  data.total =
    data.telephone +
    data.email +
    data.social +
    data.web_enquiries +
    data.website;
  return data;
};
