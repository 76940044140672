import React from "react";
import { string, bool } from "prop-types";

class Failed extends React.Component {
  static propTypes = {
    text: string,
    leftAlign: bool,
  };

  render() {
    return (
      <div className={this.props.leftAlign ? "" : "text-right"}>
        <div className="inline-block text-lg mt-3 mb-5 py-3 px-5 text-white bg-warning font-normal">
          {this.props.text ||
            "There is a problem with your submission, please check the fields for error messages."}
        </div>
      </div>
    );
  }
}

export default Failed;
