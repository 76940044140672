import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { any, bool, string } from "prop-types";
import { isLoggedIn } from "modules/auth";
import { venueInteraction } from "modules/analytics";
import { pushEvent } from "../modules/google-tag-manager";
import { ButtonBrand } from "./Button";

class LinkedButton extends React.Component {
  static propTypes = {
    iconClassName: string.isRequired,
    link: string,
    className: string,
    classes: string,
    phone: bool,
    email: bool,
    newWindow: bool,
    auth: bool,
    clickType: string,
    text: any,
  };

  state = {
    show: false,
  };

  handleClick = e => {
    if (this.props.auth && !this.props.isLoggedIn) {
      e.preventDefault();
      this.props.history.push(
        `/login?return=${encodeURI(
          this.props.location.pathname,
        )}&logInToProceed=1`,
      );
      return;
    }
    if (this.props.venueInteraction) {
      this.props.track(this.props.venueInteraction);
    }

    if (this.props.googleTagEvent) {
      pushEvent(this.props.googleTagEvent);
    }

    if (this.props.phone) {
      e.preventDefault();
      this.setState({
        show: !this.state.show,
      });
    } else {
      let prefix = "";
      if (this.props.phone) {
        prefix += "tel:";
      } else if (this.props.email) {
        prefix += "mailto:";
      }
      window.location.href = prefix + this.props.link;
    }
  };

  render() {
    const {
      newWindow,
      link,
      icon,
      phone,
      email,
      textOnShow,
    } = this.props;
    let prefix = "";
    if (phone) {
      prefix += "tel:";
    } else if (email) {
      prefix += "mailto:";
    }

    if (this.state.show) {
      return (
        <ButtonBrand
          Element="a"
          href={`${prefix}:${textOnShow}`}
          classes={`rounded-sm flex items-center ` + this.props.classes}
        >
          {textOnShow}
        </ButtonBrand>
      );
    } else {
      return (
        <ButtonBrand
          onClick={this.handleClick}
          Element="span"
          classes={`rounded-sm flex items-center ` + this.props.classes}
        >
          {icon ? icon : null}
        </ButtonBrand>
      );
    }
  }
}

export default withRouter(
  connect(
    state => ({
      isLoggedIn: isLoggedIn(state),
    }),
    { track: venueInteraction },
  )(LinkedButton),
);
