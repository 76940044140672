import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { hasAgencyRoles, isAgent, isInternal, token as tokenSelector } from "modules/auth";
import Modal from "Components/Modal";
import { ButtonHighlight, ButtonPrimary, ButtonSpan } from "Components/Button";
import Loading from "Components/Loading";
import { ENQUIRY_VENUE_DATA_STATUS } from "../../modules/rfp/enquiryVenueDataStatus";
import moment from "moment";

const saveData = (function() {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.cssText = "display: none";
  return function(isCsv, data, fileName) {
    const blob = new Blob([data], {
      type: isCsv
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
        : "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const ExportRfpProposalsModal = ({ onClose, data, enquiryData, loggedInUserId }) => {
  const [submitting, setSubmitting] = useState("");
  const [isRfpXpress, setRfpXpress] = useState(Boolean(data?.brief?.rfp_xpress_service));
  const { id } = useParams();
  const isAgency = useSelector(state => isAgent(state));
  const hasAgency = useSelector(state => hasAgencyRoles(state));
  const hasInternal = useSelector(state => isInternal(state));
  const token = useSelector(tokenSelector);
  // check corporate account export net settings, default to false
  let exportNet = false;

  if (data && data.company_details && data.company_details.name && data.company_details.name.corporate_account) {
    exportNet = Boolean(data.company_details.name.corporate_account.export_net);
  }

  const handleExport = (type, disableCommission = false, submittingType, includeVat) => async () => {
    if (submitting) {
      return;
    }
    setSubmitting(submittingType);
    let url = `${window.API}/rfp/enquiry/${id}/export/${type}`;
    let queryParams = new URLSearchParams();
    if (disableCommission) {
      queryParams.set("disable_commission", 1);
    }
    if (includeVat) {
      queryParams.set("includeVat", 1); // if includeVat return gross
    }
    url += "?" + queryParams.toString();
    try {
      const response = await axios.get(
        url,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      saveData(
        type === "csv",
        response.data,
        `your-enquiry-${id}.${type === "csv" ? "xlsx" : type}`,
      );
      setSubmitting("");
      onClose();
    } catch (err) {
      setSubmitting("");
      alert(`Sorry there was a problem fetching your ${type}`);
    }
  };

  const handleConfirmationExport = (enquiryId, enquiryVenueDataId, name, disableCommission, type, includeVat) => async () => {
    if (submitting) {
      return;
    }
    setSubmitting(type);
    let url = `${window.API}/rfp/${enquiryId}/confirm/${enquiryVenueDataId}/export`;
    let queryParams = new URLSearchParams();
    if (disableCommission) {
      queryParams.set("disable_commission", 1);
    }
    if (includeVat) {
      queryParams.set("include_vat", 1); // if includeVat return gross
    }
    url += "?" + queryParams.toString();
    setSubmitting(type);
    try {
      const response = await axios.get(
        url,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      saveData(
        false,
        response.data,
        `${name.replace(/\s/g, "-")}-${moment().format("DD-MM-YYYY")}-proposal-ref-${enquiryVenueDataId}-confirmation${disableCommission ? "-without-commission" : ""}.pdf`,
      );
      setSubmitting("");

    } catch (err) {
      setSubmitting("");
      alert(`Sorry there was a problem fetching the pdf`);
    }
  };

  return (
    <Modal>
      <div className="modal">
        <div
          className="modal-inner"
          style={{
            width: 600,
            maxWidth: "90%",
          }}
        >
          <ButtonSpan
            className="m-3 absolute pin-t pin-r z-10"
            onClick={onClose}
          >
            <i className="fas fa-times-circle fa-2x ml-3" />
          </ButtonSpan>

          {enquiryData && enquiryData.status !== ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED ? (
            <>
              {isRfpXpress ? (
                <h2 className="mb-3 text-3xl">Export Budget Confirmation</h2>
              ) : (
                <h2 className="mb-3 text-3xl">Export Proposal</h2>
              )}
              <div className="bg-off-white md:flex py-8 px-3">
                <div className="md:w-1/2 flex justify-center items-center">
                  <i className="fas fa-5x fa-cloud-download-alt"></i>
                </div>
                <div className="md:w-2/5 flex flex-col justify-center items-center">
                  <ButtonHighlight
                    classes="mb-3 w-full"
                    highlight={exportNet}
                    onClick={handleExport("pdf", false, "pdfNetWithCommission", false)}
                  >
                    {submitting === "pdfNetWithCommission" ? <Loading /> : "Export NET"}{" "}
                  </ButtonHighlight>
                  <ButtonHighlight
                    classes="mb-3 w-full"
                    highlight={!exportNet}
                    onClick={handleExport("pdf", false, "pdfGrossWithCommission", true)}
                  >
                    {submitting === "pdfGrossWithCommission" ? <Loading /> : "Export Gross"}
                  </ButtonHighlight>

                  {(isAgency || hasAgency || hasInternal) && enquiryData && enquiryData.proposal_data.commission_items.length > 0 ?
                    <>
                      <ButtonHighlight
                        classes="mb-3 w-full"
                        highlight={exportNet}
                        onClick={handleExport("pdf", true, "pdfNetWithoutCommission", false)}
                      >
                        {submitting === "pdfNetWithoutCommission" ?
                          <Loading /> : "Export Net Without Commission"}
                      </ButtonHighlight>
                      <ButtonHighlight
                        classes="mb-3 w-full"
                        highlight={!exportNet}
                        onClick={handleExport("pdf", true, "pdfGrossWithoutCommission", true)}
                      >
                        {submitting === "pdfGrossWithoutCommission" ?
                          <Loading /> : "Export Gross Without Commission"}
                      </ButtonHighlight>
                    </>
                    : null}

                  {!isRfpXpress ? (
                    <ButtonPrimary classes="w-full" onClick={handleExport("csv")}>
                      {submitting === "csv" ? <Loading /> : "Export as Excel"}{" "}
                    </ButtonPrimary>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
          {enquiryData && enquiryData.status === ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED ?
            <>
              {isRfpXpress ? (
                <h2 className="mb-3 mt-3 text-3xl">Export Budget Confirmation</h2>
              ) : (
                <h2 className="mb-3 mt-3 text-3xl">Export Confirmation</h2>
              )}
              <div className="bg-off-white md:flex py-8 px-3">
                <div className="md:w-1/2 flex justify-center items-center">
                  <i className="fas fa-5x fa-cloud-download-alt"></i>
                </div>
                <div className="md:w-2/5 flex flex-col justify-center items-center">
                  <ButtonHighlight
                    classes={`mb-3 w-full`}
                    highlight={exportNet}
                    onClick={handleConfirmationExport(id, enquiryData.id, data.brief.event_name, false, "confirmNetWithCommission", false)}
                  >
                    {submitting === "confirmNetWithCommission" ? <Loading /> : "Export Net"}
                  </ButtonHighlight>
                  <ButtonHighlight
                    classes="mb-3 w-full"
                    highlight={!exportNet}
                    onClick={handleConfirmationExport(id, enquiryData.id, data.brief.event_name, false, "confirmGrossWithCommission", true)}
                  >
                    {submitting === "confirmGrossWithCommission" ? <Loading /> : "Export Gross"}
                  </ButtonHighlight>
                  {(isAgency || hasAgency || hasInternal) && enquiryData && enquiryData.proposal_data.commission_items.length > 0 ? (
                    <>
                      <ButtonHighlight
                        classes="mb-3 w-full"
                        highlight={exportNet}
                        onClick={handleConfirmationExport(id, enquiryData.id, data.brief.event_name, true, "confirmNetWithoutCommission", false)}
                      >
                        {submitting === "confirmNetWithoutCommission" ?
                          <Loading /> : "Export Net Without Commission"}
                      </ButtonHighlight>
                      <ButtonHighlight
                        classes="mb-3 w-full"
                        highlight={!exportNet}
                        onClick={handleConfirmationExport(id, enquiryData.id, data.brief.event_name, true, "confirmGrossWithoutCommission", true)}
                      >
                        {submitting === "confirmGrossWithoutCommission" ?
                          <Loading /> : "Export Gross Without Commission"}
                      </ButtonHighlight>
                    </>
                  ) : null}
                </div>
              </div>
            </>
            : null}
        </div>
      </div>
    </Modal>
  );
};

export default ExportRfpProposalsModal;
