import React from 'react';
import BackgroundImage from "./General/BackgroundImage";
import LazyLoad from 'react-lazyload';

const RegistrationCTA = ({ subtitle = false, title, content, img = false }) => (
  <div className="bg-white h-auto" style={{ borderRadius: 8 }}>
    {img !== false &&
        <LazyLoad  height={200} once offset={300}>
            <BackgroundImage className={'bg-cover bg-center h-40 rounded-edges'}
                             imagePath={img}
                             edits={{
                                 "resize": {
                                     "width": 516,
                                     "height": 271.578,
                                     "fit": "cover"
                                 }}}/>
        </LazyLoad>
     }

    <div className="p-8 relative">
      {subtitle && <p className="mb-3 text-lg font-normal">{subtitle}</p>}
      <h2 className="mt-0 mb-4 text-1.5rem">{title}</h2>
      {content}
    </div>
  </div>
);

export default RegistrationCTA;
