import React from "react";
import moment from "moment";

export default (data, edit, trash) => (
  <div className="mb-5 bg-off-white p-3 border-t border-grey md:flex">
    <div className="md:flex-grow">
      <h3 className="mb-2" onClick={edit}>
        {data.title}
        <i className="fal fa-edit text-brand ml-3" onClick={edit} />
      </h3>
      <footer>
        <p className="text-sm font-normal mb-0">
          {data.author}, {moment(data.created_at).format("MMM Do YYYY")}
        </p>
      </footer>
    </div>
    <div className="self-end">
      <i className="fal fa-trash-alt text-warning" onClick={trash} />
    </div>
  </div>
);
