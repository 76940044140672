import React from "react";
import { connect } from "react-redux";
import { getFormValues, destroy } from "redux-form";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { token, addSearch, savedSearches } from "../../modules/auth";
import Loading from "Components/Loading";
import { ButtonGrey, ButtonBrand } from "Components/Button";
import { createHandleAxiosError } from "../../modules/api-tools";
import { FaStar } from "react-icons/fa";
import {generalInteraction} from "../../modules/analytics/actions/tracking";

const ButtonOutline = ({ children, onClick }) => (
  <ButtonBrand outlined classes="border-3 rounded-sm w-full" onClick={onClick}>
    {children}
  </ButtonBrand>
);

class SearchWidgets extends React.Component {
  state = {
    savingSearch: false,
    savedSearch: false,
  };

  ButtonComponent = this.props.outline ? ButtonOutline : ButtonGrey;

  componentDidUpdate(prev) {
    if (prev.results !== this.props.results) {
      this.setState({
        savedSearch: false,
      });
    }
  }

  getSearchName = () => {
    const values = this.props.searchParams;

    if (!values) return;
    return values.location || values.name || values.venueType;
  };

  saveSearch = () => {
    this.setState({
      savingSearch: true,
    });
    let values;
    if(this.props.searchForm === 'simple-search') {
       values = this.props.searchParamsSimple;
    }else {
       values = this.props.searchParamsAdvanced;
    }


    const search_raw = JSON.stringify({
      values,
      type: this.props.searchForm,
    });
    const { history, location } = this.props;
    const handleAxiosError = createHandleAxiosError({ history, location });

    axios
      .post(
        `${window.API}/users/me/shortlists`,
        {
          search_raw,
          name: this.getSearchName(),
        },
        {
          headers: { Authorization: `Bearer ${this.props.token}` },
        }
      )
      .then(response => {
        this.props.addSearch(response.data);
        this.setState({
          savingSearch: false,
          savedSearch: true,
        });
        this.props.generalInteraction({type: 'general_search_saved'})
      })
      .catch(
        handleAxiosError(() => {
          this.setState({
            savingSearch: false,
            savedSearch: false,
          });
          alert("Sorry there was an error saving your search");
        })
      );
  };

  render() {
    const { savedSearches } = this.props;
    const saveName = this.getSearchName();
    const hasSaveName = !!saveName;
    const saved =
      !this.state.savedSearch &&
      hasSaveName &&
      savedSearches.find(({ name }) => name === saveName);

    return this.state.savingSearch ? (
      <this.ButtonComponent disabled>
        <Loading inline />
      </this.ButtonComponent>
    ) : saved ? (
      <this.ButtonComponent
        disabled
        disabledText={false}
        classes={`${this.props.classes || ""}`}
      >
        {!this.props.noStar && <FaStar className="mr-2" />}
        Saved
      </this.ButtonComponent>
    ) : (
      <this.ButtonComponent
        classes={`${this.props.classes || ""}`}
        onClick={this.saveSearch}
        // disabled={!hasSaveName}
      >
        {!this.props.noStar && <FaStar className="mr-2" />}
        Save search
      </this.ButtonComponent>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => {
      return {
        token: token(state),
        searchParamsAdvanced: getFormValues("advanced-search")(state),
        searchParamsSimple: getFormValues("simple-search")(state),
        savedSearches: savedSearches(state),
      };
    },
    { destroy, addSearch, generalInteraction }
  )(SearchWidgets)
);
