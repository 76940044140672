import React, { useRef, useMemo } from "react";
import { reduxForm } from "redux-form";
import LiveSearch from "./index";

/*
Self contained version of Live Search

Differences
  * Works without formId and reduxForm container
  * Selected items are received from onChange not from redux
  * The state is not saved to localStorage

Apart from onChange and initQuery all other props are parsed through to LiveSearch

*/

let formIdInc = 0;

export function LiveSearchSimple({
  initQuery = "",
  onChange = () => {},
  onClear = () => {},
  ...props
}) {
  const formId = useRef();
  if (!formId.current) {
    formId.current = `live-search-${formIdInc++}-NO_STORE`;
  }

  const LiveSearchWithForm = useMemo(
    () =>
      reduxForm({
        form: formId.current,
        initialValues: {
          query: initQuery,
        },
      })(LiveSearch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formId.current]
  );

  return (
    <LiveSearchWithForm
      formId={formId.current}
      name="query"
      onSelect={value => onChange(value)}
      onClear={() => onClear()}
      {...props}
    />
  );
}
