import React, {useRef, useEffect} from 'react';
import moment from "moment";

export const listFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });

export const whitelistedCurrencyCodes = ['GBP', 'USD', 'EUR'];

export const GBPFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  // minimumFractionDigits: 0,
  // maximumFractionDigits: 0,
});

export const currencyFormatter = (currency) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency,
    // minimumFractionDigits: 0,
    // maximumFractionDigits: 0,
  });
}

export const getCurrencySymbol = (currency = {currency_code: "GBP"}) => {
  let currencyCode;
  if (typeof currency === 'object' && currency.currency_code) {
    currencyCode = currency.currency_code;
  } else if (typeof currency === 'string') {
    currencyCode = currency;
  } else {
    console.error('Invalid currency input:', currency);
    return null;
  }
  try {
    const format = new Intl.NumberFormat(undefined, { style: 'currency', currency: currencyCode });
    const parts = format.formatToParts(0);
    const symbol = parts.find(part => part.type === 'currency').value;
    return symbol
  } catch (e) {
    console.error(e)
  }
}

export const venuePackageUpdatedCuttoff = new Date('2022-01-01 00:00:00');

export const nameFormatter = (userObj, includeEmail = false) => {
  if (userObj && userObj.first_name && userObj.surname) {
    return `${userObj.first_name} ${userObj.surname}${includeEmail ? ' ('+ userObj?.email + ')' : ''}`
  }
  return '';
}

export const sortName = (a, b) => {
  if (!a.first_name) {
    return +1
  }
  if (!b.first_name) {
    return -1
  }
  return a.first_name.localeCompare(b.first_name);
}

export const formatCurrency = input => {
  let convertedInput = 0;
  if (Number.isInteger(input) || Number.parseFloat(input)) {
    convertedInput = input;
  }
  return convertedInput;
};
export const htmlCodeToUnicode = htmlCode => {
  switch(htmlCode)
  {
    case '&euro;':
        return '\u20AC'
    case '&dollar;':
          return '\u0024'
    case '&pound;':
      return '\u00a3'
    default:
        return htmlCode;
  }
}

export const parseAndRound = (val1, val2) => {
  let exchangedValue = Number(Number(val1).toFixed(2) / Number(val2).toFixed(2)).toFixed(2);
  return isNaN(exchangedValue) ? null : exchangedValue
}

export const normalizeCurrency = val => {
  let output = val.replace(/,/g, "");
  output = output.replace(/£/g, "");
//  console.log("VALUE: " + output);
  return output;
};

/**
 * Get Vat Multiplier from Vat Rate
 *
 * Vat Rate should be in form of 1 = 1% e.g. 17.5% VAT = 17.5 NOT 0.175
 *
 * @param vatRate
 * @returns {number}
 */
const vatMultiplierFromVatRate = (vatRate) => {
  return 1 + (Number(vatRate) / 100);
}

/**
 * @param {Number} netValue
 * @param {*} vatRate
 */
function grossFromNet(netValue, vatRate) {
  const vatMultiplier = vatMultiplierFromVatRate(vatRate);
  return Number(netValue * vatMultiplier).toFixed(2)
}


/**
 * @param {Number} grossValue
 * @param {*} vatRate
 */
function netFromGross(grossValue, vatRate) {
  const vatMultiplier = vatMultiplierFromVatRate(vatRate);
  return Number(grossValue / vatMultiplier).toFixed(2);
}

/**
 * Return the total VAT from netValue with a given vatRate.
 *
 * @param {Number} netValue
 * @param {Number} vatRate
 *
 * @returns {Number} vatAmount
 */
function vatFromNet(netValue, vatRate) {
  const gross = grossFromNet(netValue, vatRate);
  return Number(gross - netValue).toFixed(2);
}

/**
 * Return the total VAT from grossValue with the give vatRate.
 *
 * @param {Number} grossValue
 * @param {Number} vatRate
 *
 * @returns {Number} vatAmount
 */
function vatFromGross(grossValue, vatRate) {
  const net = netFromGross(grossValue, vatRate);
  return Number(grossValue - net).toFixed(2);
}

/**
 * Return all components of a figure from a grossValue and vatRate.
 *
 * @param {Number} grossValue
 * @param {Number} vatRate
 *
 * @returns {Object} components
 */
function grossToComponents(grossValue, vatRate) {
  return {
    net: Number(netFromGross(grossValue, vatRate)),
    vat: Number(vatFromGross(grossValue, vatRate)),
    gross: Number(grossValue)
  };
}

/**
 * Return all components of a figure from a value, a vatRate and whether the initial value includes VAT .
 *
 * @param {boolean} includesVat
 * @param {*} value
 * @param {number} vatRate
 *
 * @returns {Object} components
 */
export const calculateVat = (includesVat, value, vatRate) => {
  let gross = value;
  if (!includesVat) {
    let vat = vatFromNet(value, vatRate);
    gross = Number(value) + Number(vat);
  }
  return grossToComponents(gross, vatRate);
}

export const removeEmptySearchParams = (queryParams) => {
  let keysForDel = [];
  queryParams.forEach((value, key) => {
    if (value === '' || value === null) {
      keysForDel.push(key);
    }
  });
  keysForDel.forEach(key => {
    queryParams.delete(key);
  });
}

export const downloadButton = (fileUrl, logo = false, tooltip) => {
  if (!fileUrl) {
    return (
      <i className={`fas ${logo ? logo : 'fa-file-download'} fa-1x text-lg font-medium px-1 text-dark-grey opacity-25`}></i>
    )
  }
  return (
    <a className={`text-lg font-medium px-1`} href={fileUrl}  data-tip={tooltip} target={'_blank'}>
      <i className={`fas ${logo ? logo : 'fa-file-download'} fa-1x`}></i>
    </a>
  )
}

export const formattedApplicableDates = (startDate, endDate, applicableDays = [], dateFormat = 'Do MMM YYYY') => {
  const days = [];
  let m = moment(startDate);
  do {
    days.push(m.format(dateFormat));
    m.add(1, 'days');
  } while (m.diff(endDate, 'days') <= 0);


  const o = days.filter((d, i, days) => {
    return applicableDays.includes(i + 1);
  });

  return o;
}

export const applicableDayOptions = (startDate, endDate, applicableDays, setApplicableDays) => {
  const days = [];
  let m = moment(startDate);
  do {
    days.push(m.format('DD/MM'));
    m.add(1, 'days');
  } while (m.diff(endDate, 'days') <= 0);
  return (
      <div className="flex">
        {days.map((d, i, days) => {
          return <span key={i}
                       onClick={()=>{
                         if (applicableDays.includes(i+1)) {
                           // if more than one day is selected
                           if ( applicableDays.length > 1 ) {
                             // remove the day
                             setApplicableDays([...applicableDays.filter(e => e !== i + 1)]);
                           }
                         } else {
                           // add the day
                           setApplicableDays([...applicableDays, i+1]);
                         }
                       }}
                       className={`inline-block flex items-center px-2 text-xs py-2 border border-grey-md ${i + 1 !== days.length ? 'border-r-0' : ''} ${applicableDays.includes(i + 1) ? 'bg-brand' : ''}`} //hover:bg-grey-md
                       role="button"
          >{d}</span>
        })}
      </div>
  );
}

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const shortMonthNameArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const slugify = text => text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

/**
 * @param text
 * @returns {string}
 */
export const camelCaseToWords = text => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

/**
 * Taken from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @returns {string[]}
 */
export const allowedDocMimeTypes = () => {
  return [
    'application/msword', // .doc
    'application/docx', // not sure
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/doc',
    'application/pdf',
    'application/xlsx', // not sure
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.oasis.opendocument.spreadsheet', // .ods
    'application/vnd.oasis.opendocument.text' // .odt
  ]
}

export const getMimeType = type => {
  switch (type) {
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "odt":
      return "application/vnd.oasis.opendocument.text";
    case "ods":
      return "application/vnd.oasis.opendocument.spreadsheet";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "xls":
      return "application/vnd.ms-excel";
    case "csv":
      return "text/csv";
    default:
      return "application/pdf";
  }
}

export const preAddUrl = link => {
  let testForHttp = /^((http|https|ftp):\/\/)/;
  if (link && !testForHttp.test(link)) {
    return "http://" + link;
  }
  return link;
};

export const getSuperAgencyPill = ({ super_agency_code }) => {
  if (super_agency_code === null || super_agency_code === '' ) {
    return ''
  }
  let color;
  switch (super_agency_code) {
    case 'edge':
      color = '#9bcb41';
      break;
    case 'trin':
      color = '#1a9bfc';
      break;
    case 'gtmc':
      color = '#000';
      break;
    default:
      color = '#9bcb41';
      break;
  }
  return <span className={'bg-blue text-white p-1 mt-2 text-xs mr-1'} style={{backgroundColor: color}} title={super_agency_code}>
    {super_agency_code?.charAt(0).toUpperCase()}
  </span>
}

export const useWhyDidYouUpdate = (name, props) => {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();
  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // console.log("[why-did-you-update]", name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}