import {
  NOTE_CREATED,
  NOTE_CREATED_ERROR,
  NOTE_REQUEST,
  NOTES_LIST_REQUEST,
  NOTES_RECEIVED_ERROR,
  NOTES_RECEIVED_REQUEST,
  UNLOAD_NOTE,
  NOTE_UPDATED
} from "./types";

export const notes = (state = {
  note: {},
  notes: [],
  error: false,
  isFetchingNote: false,
  newNoteModal: false
}, action) => {
  switch (action.type) {
    case NOTE_REQUEST:
      return {
        ...state,
        isFetchingNote: true,
      }
    case NOTE_CREATED:
      state.notes.push(action.data);
      state.notes.sort((a, b) => b.created_at.localeCompare(a.created_at))
      return {
        ...state,
        isFetchingNote: false,
        error: false,
        note: action.data,
        notes: state.notes,
        newNoteModal: false,
      }
    case NOTE_UPDATED:
const notes = state.notes.map(item => {
      if (item.id !== action.data.id) {
        // not our item, return it as is
        return item;
      }

      // this is our relevant item, return a new copy of it with modified fields
      return {
        ...action.data
      }
    });
      notes.sort((a, b) => b.created_at.localeCompare(a.created_at))
      return {
        ...state,
        isFetchingNote: false,
        error: false,
        note: action.data,
        notes,
        newNoteModal: false,
      }
    case NOTE_CREATED_ERROR:
      return {
        ...state,
        isFetchingNote: false,
        error: true,
        errorData: action.error
      }
    case UNLOAD_NOTE:
      return {
        ...state,
        error: false,
        reminder_date: null,
        note: {}
      }
    case NOTES_LIST_REQUEST:
      return {
        ...state,
        isFetchingNote: true
      }
    case NOTES_RECEIVED_REQUEST:
      return {
        ...state,
        isFetchingNote: false,
        error: false,
        notes: action.data
      }
    case NOTES_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingNote: false,
        error: true,
        errorData: action.error
      }
    default:
      return state;
  }
}
