import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import { useHistory } from "react-router";
import useService from "hooks/useService";
import {getEnquiryById} from "services/rfpService";
import Loading from "Components/Loading";
import RfpBriefSection from "Components/RfpBriefSection";
import RfpProposals from "Components/RfpProposals";
import RfpTable from "Components/RfpTable";
import Warning from "Components/Warning";
import {getNotesForRfp} from "../../../redux/actions/notes";
import {connect, useDispatch, useSelector, useStore} from "react-redux";
import axios from 'axios';
import {isAgency, isInternal, isRoleTrinityEdgeRFP, token, token as tokenSelector} from "../../../modules/auth";
import {isSuperAgent} from "../../../modules/permissions";
import {ButtonPrimary, ButtonRed} from "../../../Components/Button";
import {reduxForm, reset} from 'redux-form';
import {AddVenue} from "../../../Components/Rfp/AddVenue";
import {ENQUIRY_STATUS} from "../../../constants";
import AuthorisationBanner from "../../../Components/AuthorisationBanner";
import RFPDeclineModalForm from "../../../Components/Rfp/RFPDeclineModalForm";
import RFPDenyMessage from "../../../Components/Rfp/RFPDenyMessage";

// THIS IS THE AGENT/USER VIEW
// TABLE -> BRIEF/PROPOSALS/ACTIONS MODALS

const DraftRfps = (props) => {
  const {id} = useParams();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [submitting, setSubmitting] = useState("");
  const _isInternal = useSelector(state => isInternal(state));
  let history = useHistory();

  const {loading, data, error} = useService({
    service: getEnquiryById,
    trigger: id,
    params: id,
  });
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const store = useStore();
  const notes = store.getState().notes.notes;
  useEffect(() => {
    if (id) {
      dispatch(getNotesForRfp(id, token))
    }
  }, [id, dispatch]);

  if (!id) {
    return <RfpTable
      isVenue={false}
      isSuperAgent={props.isSuperAgent}
      isRoleTrinityEdgeRFP={props.isRoleTrinityEdgeRFP}
      isDraft={true}
    />;
  }

  if (loading) {
    return <Loading large/>;
  }

  if (error) {
    return <Warning>There was a problem fetching data</Warning>;
  }

  let handleAddVenuesToRFP = async (values) => {
    try {
      await axios.post(
        `${window.API}/rfp/enquiry/${id}/add-venues`, {
          venues: values['true'],
          addFromAdmin: true
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.token}`,
          },
        },
        ).then((response) => {
          if (response.status === 200) {
            dispatch(reset('addVenuesToRFP'));
            window.location.reload(false);
          }
        });
    } catch (err) {}
  };
  return (
    <div className="py-5">
      {showCancelModal && (
          <RFPDeclineModalForm
              onClose={(isDelete) => {
                setShowCancelModal(false);
                window.location.reload();
              }}
              enquiry={data}
              noVenues={true}
          />
      )}

      <p className="p-5 text-xl text-warning edge-rfp-download-ie-message">Sorry, your browser is out of date so this
        feature will not work. Please upgrade to a later version</p>
      <div className="flex justify-between mb-3">
        <h1 className="mb-2">{data.brief.event_name}</h1>
        <p className="text-l">Ref: {data.client_details.ref_id}</p>
        {data.brief.enquiry_status.code === ENQUIRY_STATUS.DRAFT && ((!data.brief.authorised_state || !data.brief.authorise_id || data.brief.authorised_state === 'Declined') && !data.brief.agent_input_required ) ? (
          <ButtonPrimary
            Element={Link}
            to={`/proposal/edit/${data.brief.share_id}`}
          >
            Edit Brief
          </ButtonPrimary>
        ):null}
      </div>
      {data.enquiry_status === ENQUIRY_STATUS.DRAFT && !data.brief.agent_input_required && (!data.brief.authorised_state === 'Unauthorised' && !data.brief.authorised_state === 'Authorised') ? (
        <div className={'w-full'}>
          <p className={'bg-primary mb-5 text-white p-4'}>Draft proposal - this is a draft only and has not yet been submitted. Please click Edit Brief to confirm and submit.</p>
        </div>
      ):null}
      {!props.isAgency && data.brief.agent_input_required && !data.brief.enquiry_status.code === ENQUIRY_STATUS.DENIED ? (
        <div className={'w-full'}>
          <p className={'bg-primary mb-5 text-white p-4'}>Your brief has been passed to an Agent to review and suggest venues - you will receive an email update shortly.</p>
        </div>
      ):null}
      {data.brief.authorised_state === 'Unauthorised'  &&  !data.brief.agent_input_required  ? (
          <div className={'w-full'}>
            <p className={'bg-warning mb-5 text-white p-4'}>The brief is awaiting authorisation.</p>
          </div>
      ):null}
      <AuthorisationBanner data={data}/>
      {data.brief.enquiry_status.code === ENQUIRY_STATUS.DENIED ?
          <RFPDenyMessage enquiry={data} /> : null
      }

      <RfpBriefSection
        brief={data.brief}
        briefPreferences={data.brief_preferences}
        clientDetails={data.client_details}
        rfpId={id}
        agencySpecificData={data.agency_specific_data}
        managingTrinityUser={data.managing_trinity_user}
        notes
      />
      {data.enquiry_venue_datas.length > 0 && (
        <RfpProposals data={data}/>
      )}
      {props.isAgency && (
        <AddVenue
          handleSubmit={props.handleSubmit}
          handleAddVenuesToRFP={handleAddVenuesToRFP}
          submitting={props.submitting}
          change={props.change}
        />
      )}
      {/*<div className={"flex justify-center border-t border-grey pt-8 mt-2"}>*/}
      {/*  <ButtonRed onClick={() => setShowCancelModal(true)}>*/}
      {/*    {submitting ? <Loading*/}
      {/*        inline /> : data.brief.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED ? "Decline Enquiry" : "Edit Decline Info"}*/}
      {/*  </ButtonRed>*/}
      {/*</div>*/}
    </div>
  );
};

export default reduxForm({
  form: 'addVenuesToRFP',
})(
  connect((state => ({
    isSuperAgent: isSuperAgent(state.auth),
    isAgency: isAgency(state),
    token: token(state),
    isRoleTrinityEdgeRFP: isRoleTrinityEdgeRFP(state),
  })))(DraftRfps)
);
