import React, { useState } from "react";
import classNames from "classnames";

const WhitePanelWithHeader = ({
  children,
  title,
  icon,
  defaultOpen,
  collapsible,
  onToggle = () => {}
}) => {
  const [open, setOpen] = useState(defaultOpen || !collapsible);

  /**
   * Added this method, so I could detect when this panel opens/closes in a parent component.
   * Using this for when open make api call, when closing unload
   */
  const handleTogglePanel = () => {
    if (collapsible && !open) {
      setOpen(true);
      if (onToggle) {
        onToggle(true);
      }
    }
  }

  return (
    <div
      className={classNames("bg-white mb-5 shadow", {
        pointer: collapsible && !open,
      })}
      onClick={handleTogglePanel}
      // onClick={collapsible && !open ? () => setOpen(true) : null}
    >
      <div
        className={classNames("p-2 font-normal", {
          pointer: collapsible,
        })}
        onClick={collapsible && open ? () => {
          if (onToggle) {
            onToggle(false);
          }
          setOpen(false);
        } : null}
        style={{
          backgroundImage: "linear-gradient(to right, #f7f7f7, #dfdfdf)",
        }}
      >
        {(collapsible || icon) && (
          <i
            className={classNames("mr-2 text-brand", {
              [icon]: !collapsible,
              "fas fa-caret-up": open,
              "fas fa-caret-down": !open,
            })}
          ></i>
        )}
        {title}
      </div>
      <div className="p-3">{open ? children : "..."}</div>
    </div>
  );
};

export default WhitePanelWithHeader;
