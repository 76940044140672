import React from "react";
import { func, string, number } from "prop-types";
import { ButtonSpan } from "Components/Button";

export default class Image extends React.Component {
  static propTypes = {
    src: string.isRequired,
    alt: string.isRequired,
    remove: func.isRequired,
    i: number,
  };

  render() {
    return (
      <div className="w-72 max-w-full mb-5 relative">
        <ButtonSpan
          onClick={() => {
            this.props.remove(this.props.i);
          }}
          className="absolute pin-t pin-r p-1 bg-white"
        >
          <i className="text-warning fas fa-trash-alt" />
        </ButtonSpan>
        <img
          className="w-full h-auto"
          src={this.props.src}
          alt={this.props.alt}
        />
      </div>
    );
  }
}
