import {
    calculateCommissionAs,
    calculateCommissionForCalculator,
    calculateCommissionFromUnit, calculatePricePerNightPerPerson, calculateTotalAs,
} from "../../../rfp/Confirmation/Helpers/calculateTotals";
import {
    commissionFormItem,
    getCommissionItemsForStep, getDescriptionValues
} from "./proposalDataGatherer";
import {
    COMMISSION_VALUE, PRICE_TOTAL,
    PRICE_VAT,
} from "../components/FinalCommissionValuesModal";


function createEquipmentCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat, currency)
{
    let equipmentCommissionData = getCommissionItemsForStep(enquiryVenueData, 4);
    return  enquiryVenueData.proposal_data.equipment.forEach( eq => {
        let hasSrcCurVals = !!eq.cost[`price_net_src_cur`];
        let totals =  calculateTotalAs( 'Net',vatRates, 41,  parseFloat(Number(eq.cost[`price_net${currency && hasSrcCurVals? '_src_cur' : ''}`])).toFixed(2));
        let commissionTotals =  calculateCommissionAs(
            equipmentCommissionData.include_vat,
            vatRates,
            includesVatRate,
            includesVat,
            equipmentCommissionData ? calculateCommissionForCalculator(
                    false,
                    Number(eq.cost[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]),
                    eq.cost.vat_rate.rate,
                    equipmentCommissionData.rate,
                    'Net'
                )
                : {total: 0, vat: 0})
        commissionFormItem(
            `${eq.room_equipment.equipment_name === 'Other' ? eq.equipment_name : eq.room_equipment.equipment_name} (${eq.room.name})` +   getDescriptionValues(
                eq.quantity,
                eq.cost.unit_price ? eq.cost.unit_price : eq.cost.price_net,
                Number(eq.cost[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]),
                null,
                 currency,
                 true
            ),
            null,
            null,
            null,
            Number(eq.cost[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]) ,
            totals.vat ,
            includesVat,
            totals.total,
            eq.cost.price_vat_rate_id ? eq.cost.price_vat_rate_id : 41,
            equipmentCommissionData ? equipmentCommissionData.rate : 0,
            'Net',
            commissionTotals,
            'equipment',
            eq.id,
            formValues
        );
    });
}

function calculateEquipmentCommission(eq, enquiryVenueData)
{
    let equipmentCommissionData = getCommissionItemsForStep(enquiryVenueData, 4);


    return calculateCommissionFromUnit(
        equipmentCommissionData.include_vat,
        eq.cost.price_net,
        eq.cost.price_vat,
        20,
        1,
        parseFloat(equipmentCommissionData.rate).toFixed(2),
        equipmentCommissionData.applied_on,
        null,
        0)
}



function buildInitialEquipmentItems(enquiryVenueData, initialCommissionItems)
{
    enquiryVenueData.proposal_data.equipment.map(eq => {
        initialCommissionItems.push({id: eq.id, type: 'equipment', disabled: [], readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]})
    })
}

function handleEquipmentFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
{
    if(editedItem.includes('equipment'))
    {
        let eq = enquiryVenueData.proposal_data.equipment.find(eq => parseInt(eq.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'equipment_' + id,
            id,
            value,
            eq.cost.price_net,
        )

    }
}

export
{
    createEquipmentCommissionItems,
    buildInitialEquipmentItems,
    handleEquipmentFieldUpdate

};