import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Textarea } from "Components/Form";
import { isLoggedIn, venueNotes, token, updateNotes } from "modules/auth";
import TrapezeHeader from "Components/TrapezeHeader";
import {
  FaRegMinusSquare,
  FaRegPlusSquare,
  FaRegStickyNote,
  FaCheck,
} from "react-icons/fa";
import { ButtonBrand } from "Components/Button";
import { useHistory } from "react-router-dom";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { createHandleAxiosError } from "../../modules/api-tools";
import axios from "axios";
import { getWidth } from "redux-window";
import {venueInteraction} from "../../modules/analytics";
import {VENUE_TAGS} from "../../modules/analytics/tags";

const VenueNote = props => {
  const {venueId, venueInteraction} = props;
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => isLoggedIn(state));
  const note = useSelector(state => venueNotes(venueId)(state)[0]);
  const userToken = useSelector(state => token(state));
  const [noteValue, setNoteValue] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [noteUpdated, setNoteUpdated] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const mobile = useSelector(state => getWidth(state) < 992);

  useEffect(() => {
    if (note) {
      setNoteValue(note.content);
    }
  }, [note]);

  const toggle = () => {
    setExpanded(!expanded);
  };

  const setValue = ({ target }) => {
    setNoteUpdated(false);
    setNoteValue(target.value);
  };

  const saveNote = () => {
    const handleAxiosError = createHandleAxiosError({ history, location });

    axios[note ? "patch" : "post"](
      `${window.API}/users/me/notes/${params.venue}`,
      {
        content: noteValue,
      },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    )
      .then(response => {
        dispatch(updateNotes(response.data));
        setNoteUpdated(true);
        venueInteraction({
          id: venueId,
          type: VENUE_TAGS.VENUE_SHARED.VENUE_USER_NOTE_ADDED
        })
      })
      .catch(
        handleAxiosError(() => {
          alert("Sorry there was an error saving your note");
        })
      );
  };

  if (loggedIn) {
    return (
      <div className="mb-5">
        <div
          className={`flex flex-wrap justify-between items-center bg-white pointer ${
            mobile ? "pr-4" : ""
          }`}
          onClick={toggle}
        >
          <TrapezeHeader
            bgColor="grey-darker"
            textLeft
            bigText
            title="Your Notes"
            icon={
              <div className="relative">
                {!!note && (
                  <div
                    className="bg-green rounded-100 flex justify-center items-center absolute"
                    style={{ width: 13, height: 13, top: -5, right: 5 }}
                  >
                    <FaCheck className="text-white" style={{ fontSize: 8 }} />
                  </div>
                )}
                <FaRegStickyNote className="mr-2 text-white text-lg" />
              </div>
            }
          />
          {expanded ? (
            <FaRegMinusSquare className="pointer text-xl md:text-base mr-3" />
          ) : (
            <FaRegPlusSquare className="pointer text-xl md:text-base mr-3" />
          )}
        </div>
        {expanded && (
          <div className="bg-white p-3">
            <Textarea
              label=""
              rows={8}
              placeholder="Add your notes about this venue"
              wrapperClassName="mb-2 block"
              input={{
                name: "note",
                value: noteValue,
                onChange: setValue,
              }}
              meta={{}}
            />

            <ButtonBrand classes="w-full" onClick={saveNote}>
              {note ? "Update " : "Save "}note
            </ButtonBrand>
            {noteUpdated && <p className="text-center">Note saved</p>}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default connect(null, {venueInteraction})(VenueNote);
