import React from "react";
import RoundedCheckbox from "../RoundedCheckbox";
import PriceBandingReduced from "../PriceBanding/PriceBandingReduced";
import { venuePackageUpdatedCuttoff } from "../../utils";
import { ButtonLink } from "../Button";
import { InfoWindow } from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { FaInfoCircle } from "react-icons/fa";

export default function MarkerWithVenuePopup({
                                          result,
                                          onClick,
                                          selectedId,
                                          onCloseClick,
                                          hasPrice,
                                          checked,
                                          toggleShortlist,
                                          priceBandings,
                                          venuePriceBandings,
                                        }) {
  return (
    <MarkerWithLabel
      position={{
        lat: parseFloat(result.lat) - 0.0005,
        lng: parseFloat(result.lng),
      }}
      labelAnchor={new window.google.maps.Point(0, 30)}
      icon="/img/hotel-marker.svg"
      clickable
      onClick={onClick}
      labelClass={`text-white ${
        selectedId && selectedId === result.id
          ? "bg-primary"
          : hasPrice
            ? "bg-brand"
            : "bg-black"
      } font-heading text-base rounded p-2 marker-label`}
    >
      <>
        <div
          className="marker-indicator"
          style={{
            width: 15,
            height: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {hasPrice ? (
            <span
              dangerouslySetInnerHTML={{
                __html: result.currency_html_code,
              }}
            />
          ) : (
            <FaInfoCircle />
          )}
        </div>
        {selectedId && selectedId === result.id && (
          <InfoWindow
            onCloseClick={onCloseClick}
            position={{
              lat: parseFloat(result.lat),
              lng: parseFloat(result.lng),
            }}
          >
            <div>
              <div
                className="w-full p-3 h-32 bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${
                    result.image
                      ? `${window.API}/venues/${result.slug}/images/${result.image}`
                      : "/img/venue-placeholder.jpg"
                  })`,
                }}
              >
                <RoundedCheckbox
                  size={31}
                  checked={checked}
                  onClick={toggleShortlist}
                  className="bg-white"
                />
              </div>
              <div className="py-3">
                <p className="font-normal text-base ">{result.name}</p>
                <div className="mb-2">
                  {priceBandings && venuePriceBandings ? (
                    <PriceBandingReduced
                      venuePriceBindings={venuePriceBandings}
                      typeClass={"uppercase text-xs text-grey-darker leading-none"}
                      type={"ddr"}
                      currencyClass={"font-heading font-bold text-sm leading-none"}
                      bandTitleClass={"font-heading font-bold text-sm ml-2 leading-none"}
                    />
                  ) : null}
                </div>
                <div className="mb-2">
                  {priceBandings &&
                  venuePriceBandings &&
                  venuePriceBandings["show_24hr_on_profile"] ? (
                    <PriceBandingReduced
                      venuePriceBindings={venuePriceBandings}
                      typeClass={"uppercase text-xs text-grey-darker leading-none"}
                      currencyClass={"font-heading font-bold text-sm leading-none"}
                      bandTitleClass={"font-heading font-bold text-sm ml-2 leading-none"}
                      type={"24hr"}
                    />
                  ) : null}
                </div>
                <div className="mb-2">
                  {priceBandings &&
                  venuePriceBandings &&
                  !venuePriceBandings["show_24hr_on_profile"] ? (
                    <PriceBandingReduced
                      venuePriceBindings={venuePriceBandings}
                      typeClass={"uppercase text-xs text-grey-darker leading-none"}
                      type={"bb"}
                      currencyClass={"font-heading font-bold text-sm leading-none"}
                      bandTitleClass={"font-heading font-bold text-sm ml-2 leading-none"}
                    />
                  ) : null}
                </div>

                {priceBandings && venuePriceBandings ? null : result.ddr_price_ex_vat > 0 && (
                  <div className="mb-2">
                    <div className="flex">
                      <div className="w-24">
                        <span className="uppercase text-xs text-grey-darker leading-none">
                          DDR
                        </span>
                      </div>
                      <span className="font-heading font-bold text-sm ml-2 leading-none">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: result.currency_html_code,
                          }}
                        />
                        {new Date(result.ddr_updated_at) > venuePackageUpdatedCuttoff ? (
                          result.ddr_price_ex_vat.toFixed(0)
                        ) : (
                          <span>POA</span>
                        )}
                      </span>
                    </div>
                  </div>
                )}
                {priceBandings && venuePriceBandings ? null : result["24hr_ddr_price_ex_vat"] > 0 && (
                  <div className="mb-2">
                    <div className="flex">
                      <div className="w-24">
                        <span className="uppercase text-xs text-grey-darker leading-none">
                          24HR
                        </span>
                      </div>
                      <span className="font-heading font-bold text-sm ml-2 leading-none">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: result.currency_html_code,
                          }}
                        />
                        {new Date(result["24hr_updated_at"]) > venuePackageUpdatedCuttoff ? (
                          result["24hr_ddr_price_ex_vat"].toFixed(0)
                        ) : (
                          <span>POA</span>
                        )}
                      </span>
                    </div>
                  </div>
                )}

                <div className="min-w-48">
                  <div className="mb-2">
                    <div className="flex">
                      <div className="w-24">
                        <span className="uppercase text-xs text-grey-darker leading-none">
                          Max delegates
                        </span>
                      </div>
                      <span className="font-heading font-bold text-sm ml-2 leading-none">
                        {result.capacity}
                      </span>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="flex">
                      <div className="w-24">
                        <span className="uppercase text-xs text-grey-darker leading-none">
                          Meeting rooms
                        </span>
                      </div>
                      <span className="font-heading font-bold text-sm ml-2 leading-none">
                        {result.meeting_rooms}
                      </span>
                    </div>
                  </div>

                  {priceBandings && venuePriceBandings ? null : result.buy_it_now_price_exc_vat > 0 && (
                    <div className="mb-2">
                      <div className="flex">
                        <div className="w-24">
                          <span className="uppercase text-xs text-grey-darker leading-none">
                            Buy it now
                          </span>
                        </div>
                        <span className="font-heading font-bold text-sm ml-2 leading-none">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: result.currency_html_code,
                            }}
                          />
                          {result.buy_it_now_price_exc_vat.toFixed(0)}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-end">
                  <a href={`/venue/${result.slug}`}>
                    <ButtonLink>View details</ButtonLink>
                  </a>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </>
    </MarkerWithLabel>
  );
}
