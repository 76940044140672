import useService from "../../../../hooks/useService";
import {getCurrencies, getExchangeRate} from "../../../../services/rfpService/currencies";
import React, {useEffect, useState, useContext} from "react";
import {token as tokenSelector, token} from "../../../../modules/auth";
import { useSelector } from "react-redux";
import {Input, Select, Toggle} from "../../../../ui";
import { Field, reduxForm } from "redux-form";
import {positiveFloat} from "../../../../modules/validation";
import ChecksheetContext from "./ChecksheetContext";
import { getCurrencySymbol } from "../../../../utils";
import Loading from "../../../../Components/Loading";

const CurrencySelector = ({
    change,
    values,
    rfpCurrencyId,
    onCurrencyChange = null
}) => {

    let currencyId = typeof rfpCurrencyId !== 'undefined' ? rfpCurrencyId : null
    let [currencies, setCurrencies] = useState([])
    let [selectedCurrency, setSelectedCurrency] = useState(values.currency_id)
    let [exchangeRate, setExchangeRate] = useState(values.exchange_rate)
    let [loading, setLoading] = useState(false);
    let [error, setError] = useState(false);
    const token = useSelector(tokenSelector);
    useEffect(() => {
        if (!currencies || currencies.length === 0)
        {
          getCurrencies(token).then((response) => {
            let currencyArray = response.result;
            currencyArray.sort(function(a, b) {
              let order = ['GBP', 'EUR', 'USD'];
              if (a.currency_code === b.currency_code) {
                return a.name.localeCompare(b.name);
              }
              if (order.includes(a.currency_code) && order.includes(b.currency_code)) {
                return order.indexOf(a.currency_code) - order.indexOf(b.currency_code);
              }
              if (order.includes(a.currency_code)) {
                return -1;
              }
              if (order.includes(b.currency_code)) {
                return 1;
              }
              return a.name.localeCompare(b.name);
            });
            setCurrencies(currencyArray)
          })
        }
        if (currencies && currencies.length > 0) {
            if(currencyId && !selectedCurrency) {
                setCurrency(currencyId);
                currencyId = null;
            }
        }
    }, [currencies]);

    let setCurrency = (value) => {
        let currency = currencies.find((curr) => {
            return parseInt(curr.id) === parseInt(value);
        })

        setSelectedCurrency(currency.id)
        let currencyCode = currency.currency_code.toLowerCase();
        if(currencyCode === 'gbp') {
            setSelectedCurrency(null);
            setExchangeRate(null);
            change(`currency_id`, null);
            change(`exchange_rate`, null);
            change(`currency_code`, null);
            change(`default_vat_rate_id`, 41);
            if(typeof onCurrencyChange === 'function') {
                onCurrencyChange();
            }
        }else {
            setLoading(true);
            getExchangeRate(currencyCode, token).then(r => {
                if (r.result && r.result['exchangeRate']) {
                    setExchangeRate(r.result['exchangeRate'])
                    change(`currency_id`, value);
                    //reset(form);sss
                    change(`exchange_rate`, r.result['exchangeRate']);
                    change(`default_vat_rate_id`, 1);
                    change(`currency_code`, currency.currency_code.toUpperCase());
                    if (typeof onCurrencyChange === 'function') {
                        onCurrencyChange(currency.currency_code ? currency.currency_code : currency.html_code);
                    }
                    setLoading(false)
                }
            }).catch((response) => {
                setLoading(false);
                setError(true);
            })
        }
        return selectedCurrency;
    }

    if(loading)
    {
        return <Loading />
    }
    if(error)
    {
        return <p className={"font-normal text-warning"}>There has been an error fetching the exchange rate</p>
    }

    return (<>
        <Field
            name="currency_id"
            label="Currency"
            component={Select}
            wrapperClassName="mb-5 mr-5"
            options={currencies.map((curr) => {
                return {
                    text: curr.name + ` (${getCurrencySymbol(curr)})`,
                    value: parseInt(curr.id)
                }
            })}
            input={{
                onChange: e => setCurrency(e.target.value),
                value: selectedCurrency
            }}
            noPlaceholder={true}
        />
        {exchangeRate ?
            <Field
                readOnly
                name={"exchange_rate"}
                label="Exchange Rate"
                component={Input}
                wrapperClassName="mr-5 mb-5"
                validate={positiveFloat}
                value={exchangeRate}
                additionalReadonlyClass={' text-sm h-10 bg-light-grey py-1'}
            />
            : null}
        {exchangeRate ?
            <Field
                hidden={true}
                name={"currency_code"}
                component={Input}
                wrapperClassName="mr-5 mb-5"
            />
            : null}
    </>)
}

export default CurrencySelector