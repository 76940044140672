import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";
import PaginationWrapper from "../../../Components/PaginationWrapper";
import { Select } from "../../../Components/Form";
import Loading from "../../../Components/Loading";

import { ButtonBlack } from "../../../Components/Button";

import ArticlesFormModal from "../../../Components/Form/ArticlesForm/FormModal";
import OffersFormModal from "../../../Components/Form/OffersForm/FormModal";
import { withApi } from "../../../modules/api-tools";
import { createApi } from "./api";
import Warning from "../../../Components/Warning";
import AdminVOTW from "../settings/GeneralSettingsComponents/AdminVOTW";

const form = "content-management-pagination";

const filterOptions = [
  { value: "HIDE_DELETED", text: "All" },
  { value: "ONLY_APPROVED", text: "Approved" },
  { value: "ONLY_UNAPPROVED", text: "Unapproved" },
  { value: "ALL", text: "All (with deleted)" },
  { value: "ONLY_DELETED", text: "Only Deleted" },
];

const articleTypes = ["blog", "news", "movers-and-shakers", "renovation-news"];

class List extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  };

  state = {
    items: [],
    loading: true,
    selectedItem: null,
    error: false,
  };

  componentDidMount() {
    this.refreshList();
  }

  refreshList = () => {
    const { type, api } = this.props;

    this.setState({ loading: true, selectedItem: null });
    if (type !== 'venue-of-the-week') {
      api
          .loadList({ type })
          .then(this.setItems)
          .catch(
              api.handleError(() => {
                this.setState({
                  loading: false,
                  error: "There has been an error loading data.",
                });
              })
          );
    }

  };

  setItems = items => {
    this.setState({ items, loading: false });
  };

  approveItem = item => {
    const { api } = this.props;
    api
      .approveItem({ item })
      .then(() => this.updateItem())
      .catch(
        api.handleError(() =>
          window.alert("Sorry there was an issue performing the action")
        )
      );
  };

  unapproveItem = item => {
    const { api } = this.props;
    api
      .unapproveItem({ item })
      .then(() => this.updateItem())
      .catch(
        api.handleError(() =>
          window.alert("Sorry there was an issue performing the action")
        )
      );
  };

  updateItem = () => {
    this.refreshList();
  };

  filterItems() {
    const { items } = this.state;
    const { filter } = this.props;

    switch (filter) {
      case "HIDE_DELETED":
        return items.filter(item => !item.deleted_at);

      case "ONLY_DELETED":
        return items.filter(item => item.deleted_at);

      case "ONLY_APPROVED":
        return items.filter(item => !item.deleted_at && item.approved_at);

      case "ONLY_UNAPPROVED":
        return items.filter(item => !item.deleted_at && !item.approved_at);

      case "ALL":
      default:
        return items;
    }
  }

  renderItem = item => {
    const state = item.deleted_at
      ? "deleted"
      : item.approved_at
      ? "approved"
      : "unapproved";

    return (
      <tr key={item.id}>
        <td className="cursor-pointer" onClick={() => this.selectItem(item)}>
          <div className="flex flex-row items-center">
            <div className="flex-initial">
              <i className="fal fa-edit px-3" />
            </div>
            <div style={{ textOverflow: "ellipsis" }} title={item.title}>
              {item.title}
            </div>
          </div>
        </td>
        <td>{item.author}</td>
        <td>{state}</td>
        <td>{item.updated_at}</td>
        <td>
          {state === "approved" ? (
            <ButtonBlack
              classes="text-sm"
              onClick={() => this.unapproveItem(item)}
            >
              Unapprove
            </ButtonBlack>
          ) : null}
          {state === "unapproved" ? (
            <ButtonBlack
              classes="text-sm"
              onClick={() => this.approveItem(item)}
            >
              Approve
            </ButtonBlack>
          ) : null}
        </td>
      </tr>
    );
  };

  renderItems = ({ items }) => (
    <table className="border border-grey-md edge-table">
      <thead>
        <tr>
          <th className="pl-4">Title</th>
          <th>Author</th>
          <th>State</th>
          <th>Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>{items.map(this.renderItem)}</tbody>
    </table>
  );

  selectItem(item) {
    this.setState({
      selectedItem: item,
    });
  }

  render() {
    const { type, name } = this.props;
    const { loading, error } = this.state;
    const items = this.filterItems();

    if (error) return <Warning>{error}</Warning>;

    if(type === 'venue-of-the-week') {
      return (
          <AdminVOTW />
      )
    }
    return (
      <div>
        <div className="flex justify-end">
          <h2 className="mb-2 flex-grow">{name}</h2>
          <div className="w-full lg:w-1/6">
            <Field
              label="Filter Items"
              name="filter"
              options={filterOptions}
              component={Select}
            />
          </div>
        </div>

        <div>
          {loading ? (
            <Loading large />
          ) : (
            <PaginationWrapper
              items={items}
              itemsPerPage={20}
              render={this.renderItems}
            />
          )}
          {this.state.selectedItem ? (
            articleTypes.includes(this.state.selectedItem.type.name) ? (
              <ArticlesFormModal
                title={type}
                formId={type}
                url={`/cms/admin/${type}`}
                data={this.state.selectedItem}
                venueOptions={this.state.selectedItem.user.venues}
                paid={true}
                premium={true}
                closeModalRefresh={() => {
                  this.updateItem();
                }}
                closeModal={() => this.setState({ selectedItem: null })}
              />
            ) : (
              <OffersFormModal
                title={type}
                formId={type}
                url={`/cms/admin/${type}`}
                data={this.state.selectedItem}
                venueOptions={this.state.selectedItem.user.venues}
                paid={true}
                premium={true}
                closeModalRefresh={() => {
                  this.updateItem();
                }}
                closeModal={() => this.setState({ selectedItem: null })}
              />
            )
          ) : null}
        </div>
      </div>
    );
  }
}

export default withApi(createApi)(
  reduxForm({
    form,
    initialValues: {
      filter: "HIDE_DELETED",
    },
    // Keep the same filter between content types
    destroyOnUnmount: false,
  })(
    connect(state => ({
      filter: getFormValues(form)(state).filter,
    }))(List)
  )
);
