import axios from "axios";

// This is deprecated but cannot currently be removed from the DB
const accommodationCateringTypeId = 6;


const getCateringTypes = async (_, token) => {
  try {
    const response = await axios.get(`${window.API}/rfp/catering-type`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.data.data) {
      return {
        result: [],
      };
    }

    return {
      result: response.data.data.filter(type => { return type.id !== accommodationCateringTypeId; }).map(type => ({
        text: type.type,
        value: type.id,
      })),
    };
  } catch (error) {
    return { error };
  }
};

export { getCateringTypes, accommodationCateringTypeId };
