import React, {  useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import qs from "query-string";
import axios from "axios";
import Warning from "Components/Warning";
import Loading from "Components/Loading";
import { login } from "modules/auth";
import {pushEvent} from "modules/google-tag-manager";
import {generalInteraction} from "../modules/analytics";
import {GENERAL_TAGS} from "../modules/analytics/tags";
import {refreshUser, refreshUserCompany} from "../modules/auth-thunks";


const SSOLogin = ({
  login,
  history,
  location,
  generalInteraction,
  refreshUserCompany,
  refreshUser
}) => {
  const [loginError, setLoginError] = useState();

  const {
    h,
    r,
  } = qs.parse(location.search);


  const handleLogin = values => {
    return axios
      .post(`${window.API}/auth/login`, values)
      .then(response => {
        const role = response.data["roles-string"];

        login({
          token: response.data.access_token,
          role,
          venues: response.data.venues,
          user: response.data.user,
        });

        history.push("/");
        generalInteraction({type: GENERAL_TAGS.GENERAL_LOGIN_SUCCESS, data: response.data.user.email})
        pushEvent("user-login-success");
        refreshUserCompany()
        refreshUser()
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          switch (err.response.data.error) {
            case "Account not active":
              setLoginError("Your agency or company is not currently disabled - please contact EDGE Venues to discuss");
              break;
            case "Unauthorized":
              setLoginError("The email and password don't match");
              break;
            default:
              setLoginError("Sorry there was a problem logging in, please check your details and try again");
          }
        } else {
          setLoginError("Sorry there was a problem logging in, please check your details and try again");
        }
        pushEvent("user-login-fail");
      });
  };

  handleLogin({ssoH: h, ssoR: r});

  return (
    <div className="flex justify-center px-3 py-8 md:py-16">
      <div className="bg-white p-8 max-w-full" style={{width: 900}}>
        <div className="text-center">
          <h1 className="mb-5">Logging into your EDGE account</h1>
          <Loading />
            <Warning className="mt-3">{loginError}</Warning>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
    connect(
      undefined,
      { login, generalInteraction, refreshUserCompany, refreshUser }
    )(SSOLogin)
  );
