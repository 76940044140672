import React from "react";

const ReportSubmitted = props => {
  return (
    <div className={`bg-white text-center ${props.className}`}>
      <h2 className="mb-4">Report submitted</h2>
      <p>
        Thank you for submitting your site visit report. An administrator will
        review your report before it is published.
      </p>
      <p>You will be notified once the report has been published.</p>
    </div>
  );
};

export default ReportSubmitted;
