import React from "react";
import { connect } from "react-redux";
import { agencyObject, companyObject } from "../../modules/permissions";
import { DEFAULT_AGENCY_CONTACT, EDGE_ENQUIRIES_EMAIL, EDGE_PHONE_NUMBER } from "../../constants";

function CorporateFooter({
                           agency = DEFAULT_AGENCY_CONTACT,
                         }) {
  if (agency) {
    if (typeof agency.phone_number === "undefined" || agency.phone_number === "" || agency.phone_number === null) {
      agency.phone_number = EDGE_PHONE_NUMBER;
    }
    if (typeof agency.email_address === "undefined" || agency.email_address === "" || agency.email_address === null) {
      agency.email_address = EDGE_ENQUIRIES_EMAIL;
    }
  }

  return (
    <div className="bg-black text-white mt-20 text-center border-t-8 border-brand border-corporate-override" style={{
      width: "100vw",
      position: "relative",
      left: "50%",
      right: "50%",
      marginLeft: "-50vw",
      marginRight: "-50vw",
    }}>
      <div className="container m-auto">
        <div className={"flex flex-col lg:flex-row"}>
          <div className="bg-white text-black uppercase flex flex-row items-center" style={{
            clipPath: "polygon(0% 0%, 85% 0%, 100% 100%, 15% 100%)",
            paddingLeft: "100px",
            paddingRight: "100px",
          }}>
            <div className="flex-no-shrink font-small">Powered by:</div>
            <img
              src="/img/logo-black.svg"
              alt="EDGE Venues logo"
              className="pt-3"
              width="150px"
            />
          </div>
          <div className="flex flex-row items-center">
            <p className="my-4">
              If you need any help please call the EDGE team on <a
              href={`tel:${agency.phone_number}`}>{agency.phone_number}</a> or email
              on{" "}
              <a href={`mailto:${agency.email_address}`} className="font-normal">
                {agency.email_address}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  let agency = {};
  if (agencyObject(state.auth).length > 0) {
    agency = agencyObject(state.auth);
  } else if (companyObject(state.auth)) {
    agency = companyObject(state.auth).agency_id ? companyObject(state.auth).agency : {};
  }
  return {
    agency: agency,
  };
};

export default connect(mapStateToProps, null)(CorporateFooter);
