import axios from "axios";

const authoriseProposal = async (id, token) => {
    try {
        await axios.post(
            `${window.API}/rfp/enquiry/authorise`,
            {
                id,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return { result: true };
    } catch (error) {
        return { error };
    }
};

export { authoriseProposal };
