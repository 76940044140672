import axios from "axios";

const getCateringLocations = async (venueId = "", token) => {
  try {
    const response = await axios.get(
      `${window.API}/rfp/conference-package-catering-location/${venueId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.data.data) {
      return {
        result: [],
      };
    }

    return {
      result: response.data.data.map(type => ({
        text: type.location,
        value: type.id,
      })),
    };
  } catch (error) {
    return { error, result: [] };
  }
};

const addCateringLocation = async ({ venueId, location }, token) => {
  try {
    let res = await axios.post(
      `${window.API}/rfp/conference-package-catering-location/`,
      {
        venue_id: venueId,
        location,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return {
      result: true,
      location: res && res.status === 201 && res.data && res.data.data && res.data.data.id ? {
        "text": res.data.data.location,
        "value": res.data.data.id
      } : {}
    };
  } catch (error) {
    return { error, result: false };
  }
};

export { getCateringLocations, addCateringLocation };
