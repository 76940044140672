import classNames from "classnames";

const componentClasses = (defaults = [], additional = [], remove = []) => {
  let allClasses = [...defaults];

  if (remove.length) {
    allClasses = allClasses.filter(className => !remove.includes(className));
  }

  if (additional.length) {
    allClasses = allClasses.concat(additional);
  }

  return classNames(allClasses);
};

export default componentClasses;
