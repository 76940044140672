import { format } from "date-fns";
import groupBy from "lodash/groupBy";
import getDatesArray from "./get-dates-array";

const DAILY_DATE_FORMAT = "MMM Do, YYYY";

export default (data, start, end, getTotal) => {
  if (!data) return null;
  const byDay = groupBy(data, item => {
    return item.day;
  });
  const dates = getDatesArray(start, end);
  if (!Array.isArray(dates)) {
    return null;
  }
  return dates.map(date => {
    if (getTotal) {
      return {
        day: format(date, DAILY_DATE_FORMAT),
        total: getTotal(byDay[date] || []),
      };
    }
    return {
      day: format(date, DAILY_DATE_FORMAT),
      total: byDay[date] ? byDay[date].length : 0,
    };
  });
};
