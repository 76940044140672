import get from "lodash/get";
import mapValues from "lodash/mapValues";

export default requestError => {
  const apiFormErrors = get(requestError, "response.data.errors");

  if (!apiFormErrors) return;

  // Only return the first error for each field
  return mapValues(apiFormErrors, fieldErrors => fieldErrors[0]);
};
