import {ButtonPrimary} from "../../../../../../Components/Button";
import React from "react";
import {COMMISSION_VALUE, PRICE_TOTAL, PRICE_VAT} from "../FinalCommissionValuesModal";
import {createConferencePackageCommissionItems} from "../../helpers/conferencePackageHandler";
import {createMeetingRoomCommissionItems} from "../../helpers/meetingRoomHandler";
import {createEquipmentCommissionItems} from "../../helpers/equipmentHandler";
import {createAdditionalRequirementsCommissionItems} from "../../helpers/additionalRequirementsHandler";
import {createAccommodationCommissionItems} from "../../helpers/accommodationHandler";
import filterFormValues from "../Helpers/filterFormValues";
import {connect, useDispatch, useSelector} from 'react-redux';
import {Field, reduxForm, getFormValues, autofill,} from "redux-form";
import {createAdditionalCostingsCommissionItems} from "../../helpers/additionalCostingsHelper";

let ReloadFromConfirmation = ({setUpCommissionItems, data, vatRates, includesVatRate, includesVat, reduxFormActions, event, commissionVatRate, currency}) => {
    const values = useSelector(getFormValues('finalCommissionValues')) || {};
    let resetForm = () => {
        setUpCommissionItems(true);
        let formValues = {};
        let enquiryVenueData = data.enquiry_venue_datas.find(evd => evd.id === event.enquiry_venue_datas.id);
        createConferencePackageCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat,currency)
        createMeetingRoomCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat,currency)
        createEquipmentCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat,currency)
        createAdditionalRequirementsCommissionItems(enquiryVenueData, formValues,vatRates, includesVatRate, includesVat,currency)
        createAccommodationCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat,currency)
        createAdditionalCostingsCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat,currency)
        for (const [key, value] of Object.entries(formValues)) {
            reduxFormActions.change(key, value);
        }
        let customFields =  filterFormValues('custom', values);
        for(const [key,value] of Object.entries(customFields)){
            reduxFormActions.dispatch(reduxFormActions.autofill('finalCommissionValues', key, undefined))
        }
    }


    return (
        <ButtonPrimary  classes={`mx-2 bg-dark-red`} onClick={() => {resetForm()}} >
            {"Reload from Confirmation"}
        </ButtonPrimary>
    )
}


ReloadFromConfirmation = reduxForm({
    form: 'finalCommissionValues',

})(ReloadFromConfirmation);

export default connect(null, null)(ReloadFromConfirmation);

