import React from "react";
import { Field, change } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { token as tokenSelector } from "modules/auth";
import { LabelledText, Select, WhitePanelWithHeader } from "ui";
import { setVenueContact } from "services/rfpService";
import { ButtonTransparent } from "Components/Button";

const HotelContact = ({
  contacts,
  setShowContactForm,
  values,
  venueId,
  rfpId,
  validation = {},
  form,
  checksheet,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const contact = contacts.find(
    contact => parseInt(contact.value) === parseInt(values.hotel_contact)
  );

  const handleChangeContact = async (_, venueContactId) => {
    if (venueContactId && token && rfpId && venueId) {
      if (
        !checksheet ||
        window.confirm(
          "The new contact will receive all the notifications for the proposal, do you wish to continue?"
        )
      ) {
        const { error } = await setVenueContact(
          { venueId, rfpId, venueContactId },
          token
        );
        if (error) {
          dispatch(change(form, "hotel_contact", ""));
          alert("Sorry we couldn't set the venue contact");
        }
      } else {
        setTimeout(() => {
          dispatch(change(form, "hotel_contact", values.hotel_contact));
        }, 1);
      }
    }
  };

  return (
    <WhitePanelWithHeader
      icon="far fa-concierge-bell"
      title="Hotel Contact"
      wrapperClassName="h-full"
    >
      <div className="flex">
        <div className="w-full sm:w-1/2 pr-5">
          <Field
            name="hotel_contact"
            label="Hotel Contact*"
            component={Select}
            options={contacts}
            validate={validation.hotel_contact}
            onChange={handleChangeContact}
          />
          <ButtonTransparent small onClick={() => setShowContactForm(true)}>
            Add new contact
          </ButtonTransparent>
        </div>
        <div className="w-full sm:w-1/2">
          {contact && (
            <>
              <LabelledText label="Name" text={contact.text} />
              <LabelledText label="Email" text={contact.email} />
              <LabelledText label="Phone" text={contact.phone} />
            </>
          )}
        </div>
      </div>
    </WhitePanelWithHeader>
  );
};

export default HotelContact;
