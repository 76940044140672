import React from "react";
import {currencyFormatter} from "../../../../../utils";

let PriceBandingInformation = ({priceBandings, isInternational = false}) => {
    return (
        <div>
            <p className={'m-0'}><strong className={'leading-3'}>Approx pricing per person</strong></p>
            {priceBandings.map((bands) => {
                if(isInternational)
                {
                    switch(bands.band_code)
                    {
                        case 'A':
                            return  <><p className={'m-0'}>{bands.band_title}: <strong> - $ (budget)</strong></p></>;
                        case 'B':
                            return  <><p className={'m-0'}>{bands.band_title}: <strong> - $$ (economical)</strong></p></>;
                        case 'C':
                            return  <><p className={'m-0'}>{bands.band_title}: <strong> - $$$ (mid-range)</strong></p></>;
                        case 'D':
                            return  <><p className={'m-0'}>{bands.band_title}: <strong> - $$$$ (premium)</strong></p></>;
                        case 'E':
                            return  <><p className={'m-0'}>{bands.band_title}:<strong> - $$$$$ (luxury)</strong></p></>;
                    }
                }

                if(bands.min_price === '0.00')
                {
                    return <p className={'m-0'}>{bands.band_title}: <strong>Under {currencyFormatter('GBP').format(bands.max_price)}</strong></p>
                }
                if(!bands.max_price)
                {
                    return  <p className={'m-0'}>{bands.band_title}: <strong>{currencyFormatter('GBP').format(bands.min_price)} plus</strong></p>
                }
                return(
                    <p className={'m-0'}>{bands.band_title}: <strong>{currencyFormatter('GBP').format(bands.min_price)} - {currencyFormatter('GBP').format(bands.max_price)}</strong></p>

                    )
            })}
        </div>
    )
}

export default PriceBandingInformation