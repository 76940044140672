import React, { memo, useState } from "react";
import Slider from "react-slick";
import useAxios from "hooks/useAxios";
import Loading from "Components/Loading";
import SingleCarouselItem from "./SingleCarouselItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ButtonBrand, GroupButton} from "Components/Button";
import { useSelector } from "react-redux";
import { getWidth } from "redux-window";
import { ReactComponent as SliderPrev } from "svg/slider-prev.svg";
import {Link} from "react-router-dom";

const PrevArrow = ({ onClick }) => {
  return (
    <SliderPrev
      className="absolute-center pointer z-10 hover:text-green transition"
      style={{ left: "-25px" }}
      onClick={onClick}
    />
  );
};

const NextArrow = ({ onClick }) => {
  return (
    <SliderPrev
      className="absolute-center-y rotate-180-center-y pointer z-10 hover:text-green transition"
      style={{ right: "-25px" }}
      onClick={onClick}
    />
  );
};

const LocationsCarousel = () => {
  const mobile = useSelector(state => getWidth(state) < 768);
  const { loading, error, data } = useAxios({
    url: `${window.API}/destinations-list?uk=1&featured=1`,
  });
  const {
    loading: worldwideLoading,
    error: worldwideError,
    data: worldwideData,
  } = useAxios({
    url: `${window.API}/destinations-list?worldwide=1&featured=1`,
  });
  const [activeList, setActiveList] = useState("uk");

  const settings = {
    autoplay: true,
    cssEase: "linear",
    draggable: false,
    dots: false,
    infinite: true,
    speed: 500,
    autoPlaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1.3,
          arrows: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          draggable: false,
        },
      },
    ],
  };

  const ButtonGroup = ({ active }) => {
    return (
      <div className="flex overflow-x-scroll sm:overflow-x-auto flex-no-shrink">
        <GroupButton
          classes="whitespace-no-wrap sm:whitespace-normal"
          onClick={() => setActiveList("uk")}
          active={active === "uk"}
        >
          UK Locations
        </GroupButton>
        <GroupButton
          classes="whitespace-no-wrap sm:whitespace-normal"
          onClick={() => setActiveList("worldwide")}
          active={active === "worldwide"}
        >
          {!mobile ? "Worldwide Locations" : " Worldwide"}
        </GroupButton>
      </div>
    );
  };

  const ukSlider = (
    <>
      {loading && <Loading />}
      {!loading && error && <p>Sorry, there was a problem fetching the data</p>}
      {!loading ? (
        <Slider
          {...settings}
          className="location-carousel mt-8 mb-6 md:mb-0"
          style={{
            width: "calc(100% + 1.5rem)",
            marginLeft: "-0.75rem",
            marginRight: "-0.75rem",
          }}
        >
          {Array.isArray(data) &&
            !!data.length &&
            data.map(destination => {
              const name = destination.city;
              const slug = name.toLowerCase().replace(/ /g, "-");

              return (
                <SingleCarouselItem
                  key={slug}
                  href={`/venues-in-${slug}`}
                  name={name}
                  img={`${window.BASE_URL}/location-imgs/${slug}-md.jpg`}
                />
              );
            })}
        </Slider>
      ) : null}
    </>
  );

  const worldwideSlider = (
    <>
      {worldwideLoading && <Loading />}
      {!worldwideLoading && worldwideError && (
        <p>Sorry, there was a problem fetching the data</p>
      )}
      <Slider
        {...settings}
        className="location-carousel mt-8 mb-6 md:mb-0"
        style={{
          width: "calc(100% + 1.5rem)",
          marginLeft: "-0.75rem",
          marginRight: "-0.75rem",
        }}
      >
        {!worldwideLoading &&
          Array.isArray(worldwideData) &&
          !!worldwideData.length &&
          worldwideData.map(destination => {
            const name = destination.city;
            const slug = name.toLowerCase().replace(/ /g, "-");

            return (
              <SingleCarouselItem
                key={slug}
                href={`/venues-in-${slug}`}
                name={name}
                img={`${window.BASE_URL}/location-imgs/${slug}-md.jpg`}
              />
            );
          })}
      </Slider>
    </>
  );

  return (
    <div className="pt-8 pb-16 md:py-16">
      <div className="flex justify-between items-center pl-3">
        <ButtonGroup active={activeList} />
        <Link to={`/${activeList}-venue-locations`} className="hidden md:inline-block">
              <ButtonBrand>
                  See all locations
              </ButtonBrand>
          </Link>
      </div>
      <div className="container">
        <div>{activeList === "uk" ? ukSlider : worldwideSlider}</div>
      </div>
    </div>
  );
};

export default memo(LocationsCarousel);
