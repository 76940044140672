import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { change, Field, reduxForm } from "redux-form";
import qs from "query-string";
import axios from "axios";
import Input from "Components/Form/Input";
import { ButtonPrimary } from "Components/Button";
import Warning from "Components/Warning";
import Loading from "Components/Loading";
import { email as emailValidator, required } from "modules/validation";
import { login } from "modules/auth";
import { pushEvent } from "modules/google-tag-manager";
import Success from "../Components/Success";
import { generalInteraction } from "../modules/analytics";
import { refreshUser, refreshUserCompany } from "../modules/auth-thunks";

const form = "login";
const requiredEmail = required("Please enter your email address");

const ManualSSOLogin = ({
                          change,
                          handleSubmit,
                          history,
                          location,
                          submitting,
                        }) => {
  const [loginError, setLoginError] = useState();
  const [loginMessage, setLoginMessage] = useState();

  const {
    email,
    message,
    return: returnAfterLogin,
    sessionExpired,
    logInToProceed,
  } = qs.parse(location.search);

  const handleLogin = values => {
    return axios
      .post(`${window.API}/auth/sso-login`, values)
      .then(response => {
        window.location.replace(response.data?.loginUrl);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          switch (err.response.data.error) {
            case "Account not active":
              setLoginError("Your agency or company is not currently enabled - please contact EDGE Venues to discuss.");
              break;
            case "Unauthorized":
              setLoginError("There was a problem logging in.");
              break;
            case "SSO Login Required":
              setLoginError("Sorry your account does not have SSO set up.");
              break;
            default:
              setLoginError("Sorry there was a problem logging in, please check your details and try again.");
          }
        } else {
          setLoginError("Sorry there was a problem logging in, please check your details and try again.");
        }
        pushEvent("user-login-fail");
      });
  };

  useEffect(() => {
    if (history.location.state && history.location.state.message) {
      setLoginMessage(history.location.state.message);
    }
  }, []);

  useEffect(() => {
    if (email && message === "account_activated") {
      change(form, "email", email);
      pushEvent("user_activated");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, message]);

  return (
    <div className="flex justify-center px-3 py-8 md:py-16">
      <div className="bg-white p-8 max-w-full" style={{ width: 900 }}>
        <div className="text-center">
          <h1 className="mb-5">Log in via SSO</h1>
          <p className="mb-5">Please enter your email address to log in.</p>
        </div>
        <div className="sm:px-8 md:px-16 lg:px-32">
          <form onSubmit={handleSubmit(handleLogin)} noValidate>
            {sessionExpired ? (
              <p className="text-lg mb-5 bg-warning p-3 text-white font-normal">
                Your session has expired
              </p>
            ) : null}
            {logInToProceed ? (
              <p className="text-lg mb-5 bg-primary p-3 text-white font-normal">
                Please login or register an account to proceed
              </p>
            ) : null}
            {message === "account_activated" ? (
              <p className="text-lg mb-5 bg-primary p-3 text-white font-normal">
                Your account is activated, please log in below.
              </p>
            ) : null}
            <div className="bg-off-white p-5 mb-5">
              <Field
                name="email"
                type="email"
                label="Email address"
                component={Input}
                validate={[requiredEmail, emailValidator]}
              />
            </div>
            <div className="sm:flex justify-between items-center">
              <ButtonPrimary type="submit">
                {submitting ? <Loading inline /> : "Log in"}
              </ButtonPrimary>
            </div>
            <Warning className="mt-3">{loginError}</Warning>
            <Success className="mt-3">{loginMessage}</Success>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  reduxForm({
    form,
  })(
    connect(
      undefined,
      { login, change, generalInteraction, refreshUserCompany, refreshUser },
    )(ManualSSOLogin),
  ),
);
