import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {shortlists, token} from "../../modules/auth";
import {Select} from "../../ui";
import {ButtonPrimary, ButtonRed} from "../Button";
import {isArray} from "lodash";
import {deleteShortlist} from "../../redux/actions/shortlists";

const mapStateToProps = state => ({
  shortlists: shortlists(state),
  token: token(state)
});

const mapDispatchToProps = {
  deleteShortlist
}

const AddFromShortlist = ({shortlists, values, setShortlistIds, setShortlistNames, change, token, deleteShortlist}) => {
  const [selectedShortlist, setSelectedShortlist] = useState(null);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const getShortlistOptions = () => {
    const shortListOptions = [];
    shortlists.map(shortlist => {
      shortListOptions.push({
        value: shortlist.id,
        text: shortlist.name
      })
    })
    return shortListOptions
  }
  useEffect(() => {
    if (selectedShortlist) {
      setShowActionButtons(true)
    } else {
      setShowActionButtons(false)
    }
  }, [selectedShortlist])

  const loadVenues = () => {
    let addShortlist = shortlists.filter(shortlist => {
      return Number(shortlist['id']) === Number(selectedShortlist)
    })[0]
    let venueIds = [];
    let venueNames = [];
    if (values && isArray(values.true) && isArray(values.true_text)) {
      venueIds.push(...values.true)
      venueNames.push(...values.true_text)
    }
    addShortlist.venues.map(venue => {
      if (!venueIds.includes(venue.id)) {
        venueIds.push(venue.id)
        venueNames.push(venue.name + " (id: " + venue.id + ")")
      }
    })
    setShortlistIds(venueIds)
    setShortlistNames(venueNames)
    change('true', venueIds)
    change('true_text', venueNames)
  }

  const handleDeleteShortlist = () => {
    const shortlistName = shortlists.find(shortlist => {
      return Number(shortlist.id) === Number(selectedShortlist)
    }).name
    if (window.confirm(
      `Are you sure you want to delete this shortlist ${shortlistName}?`
    )) {
      deleteShortlist(selectedShortlist, token).then(() => {
        window.location.reload();
      })
    }
  }

  if (!shortlists || shortlists.length === 0) {
    return <></>;
  }

  return (
    <div className={'flex mb-4'}>
      <Select
        name="shortlist"
        label="Bulk load from shortlist"
        component={Select}
        labelSize={'text-sm'}
        options={getShortlistOptions()}
        wrapperClassName="md:w-1/2"
        input={{
          onChange: e => setSelectedShortlist(e.target.value),
          value: selectedShortlist,
        }}
        meta={{}}
      />
      {showActionButtons && (
        <div className="flex self-end">
          <div className="mx-2">
            <ButtonPrimary onClick={() => {
              loadVenues()
            }}>
              Load Venues
            </ButtonPrimary>
          </div>
          <div className="mx-2">
            <ButtonRed onClick={() => {
              handleDeleteShortlist()
            }}>
              Delete Shortlist
            </ButtonRed>
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFromShortlist);
