import React from "react";
import { Field, reduxForm } from "redux-form";
import { Toggle } from "Components/Form";
import NavButtons from "../Components/NavButtons";

const SetReportOptions = props => {
  return (
    <div className={`bg-white ${props.className}`}>
      <h2 className="flex justify-between mb-6">
        <span>Report Options</span>
      </h2>
      <form onSubmit={props.handleSubmit}>
        <Field
          name="author_visible"
          label="Show author name"
          component={Toggle}
        />
        <Field
          name="author_image_visible"
          label="Include your profile picture"
          component={Toggle}
        />
        <Field
          name="share_internally"
          label="Share with your company"
          component={Toggle}
        />

        <NavButtons {...props} />
      </form>
    </div>
  );
};

export default reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SetReportOptions);
