import {
  VAT_RATES_LIST_REQUEST,
  VAT_RATES_LIST_RECEIVED,
  VAT_RATES_LIST_ERROR, NOMINAL_CODE_LIST_REQUEST, NOMINAL_CODE_LIST_RECEIVED, NOMINAL_CODE_LIST_ERROR
} from "./types";

export const nominalCodes = (state = {
  nominalCodes: {},
  isFetchingCodes: false,
  error: false,
  errorData: {}
}, action) => {
  switch (action.type) {
    case NOMINAL_CODE_LIST_REQUEST:
      return {
        ...state,
        isFetchingCodes: true,
        error: false,
      }
    case NOMINAL_CODE_LIST_RECEIVED:
      return {
        ...state,
        isFetchingCodes: false,
        nominalCodes: action.data ? action.data.data.data : {},
        error: false,
      }
    case NOMINAL_CODE_LIST_ERROR:
      return {
        ...state,
        isFetchingCodes: false,
        errorData: action.data.error,
        error: true
      }
    default:
      return state;
  }
}
