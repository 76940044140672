import React, { Fragment } from "react";
import { connect } from "react-redux";
import { object, string } from "prop-types";
import { withRouter } from "react-router-dom";
import { role } from "../../modules/auth";
import SearchBannerCorporate from "../../Components/SearchBannerCorporate";
import Dashboard from "../../Components/Dashboard";
import * as CORPORATE_ROUTES from "../../Router/corporate_routes";
import {
  isEventSpecialist,
  isHeadOfEvents,
  isHeadOfProcurement, isNonEventSpecialist,
} from "../../modules/permissions";
import ReportsDashboardContainer from "../../Components/corporate/reports/ReportsDashboardContainer";
import CorpDashFeed from "../../Components/corporate/Dash/CorpDashFeed";
import CorporateDashWelcome from "../../Components/corporate/Dash/CorporateDashWelcome";

class CorpDashboard extends React.Component {
  static propTypes = {
    role: string.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    user: object.isRequired,
  };

  renderContainer = content => (
    <div className="flex items-center justify-center">
      <div className="font-normal">{content}</div>
    </div>
  );

  render() {
    const { match, isHeadOfEvents, isHeadOfProcurement, isNonEventSpecialist, isEventSpecialist } = this.props;
    return this.renderContainer(
      <Fragment>
        {match.path.includes(CORPORATE_ROUTES.FRONTEND.DASHBOARD.VIEW) && (
          <div className="m-0 xxl:py-16 xxl:px-16 p-8 corporate-admin__dashboard__hero" style={{
            backgroundImage: "url(/img/corp-edge-reports-bg-blue-darker.jpg)",
            backgroundPosition: "0 -80px",
            backgroundSize: "cover",
            width: "100vw",
            position: "relative",
            left: "50%",
            right: "50%",
            marginLeft: "-50vw",
            marginRight: "-50vw",
            borderBottom: "8px solid var(--primary-light)",
          }}>
            <div className={"container"}>
              <div className={"row"}>
                {(isHeadOfProcurement || isHeadOfEvents) ?
                  <CorporateDashWelcome
                    user={this.props?.user}
                    firstName={this.props?.user?.first_name}
                    firstName1={this.props?.user.first_name}
                    canViewQuickSearch={isHeadOfEvents || isHeadOfProcurement}
                  />
                  : null}
                <div className={"col lg:col-w-1/2"}>
                  {(!isHeadOfProcurement && !isHeadOfEvents) ? <SearchBannerCorporate user={this.props?.user} /> : null}
                  {(isHeadOfProcurement || isHeadOfEvents) ? <div id="corpDashSnapshotContainer" style={{
                    "backgroundColor": "rgba(255,255,255,0.9)",
                    "boxShadow": "0 0 15px rgba(0,0,0,0.35)",
                    "borderRadius": "5px",
                    "borderTop": "10px solid var(--primary-light)",
                  }}>
                    <ReportsDashboardContainer
                      ownReport={isNonEventSpecialist || isEventSpecialist ? true : false}
                    />
                  </div> : null}
                </div>
                <div className={"col lg:col-w-1/2 mt-4 lg:mt-0"}>
                  <CorpDashFeed />
                  {/*{(isHeadOfProcurement || isHeadOfEvents) ? <CorpDashFeed/> : <div id="corpDashSnapshotContainer" style={{*/}
                  {/*    'backgroundColor': 'rgba(255,255,255,0.9)',*/}
                  {/*    'boxShadow': '0 0 15px rgba(0,0,0,0.35)',*/}
                  {/*    'borderRadius': '5px',*/}
                  {/*    'borderTop': '10px solid var(--primary-light)'*/}
                  {/*}}>*/}
                  {/*    <ReportsDashboardContainer*/}
                  {/*        ownReport={isNonEventSpecialist || isEventSpecialist ? true : false}/>*/}
                  {/*</div>}*/}
                </div>
              </div>

            </div>
          </div>
        )}

        <Dashboard />
      </Fragment>,
    );
  }
}

export default withRouter(
  connect(state => ({
    role: role(state),
    user: state?.auth?.user,
    isHeadOfProcurement: isHeadOfProcurement(state.auth),
    isHeadOfEvents: isHeadOfEvents(state.auth),
    isEventSpecialist: isEventSpecialist(state.auth),
    isNonEventSpecialist: isNonEventSpecialist(state.auth),
  }))(CorpDashboard),
);
