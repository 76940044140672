import React, {useEffect} from "react";
import Modal from "../Modal";
import {ButtonPrimary, ButtonSpan} from "../Button";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {
  canChaseEvent,
  canManageEventCompletion, canManageEventPreData,
  isAgencyAdmin,
  isCompanyAdmin,
  isCompanyUser, isEventSpecialist, isNonEventSpecialist,
  isRoleEdgeAdmin
} from "../../modules/permissions";
import BookedEventsDownloadButton from "../events/BookedEventsDownloadButton";
import ReactTooltip from 'react-tooltip';
import {downloadButton} from "../../utils";
import {eventsSingleUnload, fetchSingleBookedEvent, fetchSingleBookedEventForCalendar} from "../../redux/actions";
import {isAgency, isVenue, token} from "../../modules/auth";
import EventsListDownloadProposalConfirmationActionButtons
  from "../../pages/admin/corporate/events/EventsListDownloadProposalConfirmationActionButtons";
import Loading from "../Loading";

const Wrapper = ({children, onClose}) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 600,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3"/>
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

const renderDownloadButtons = (bookedEvent, isCompanyAccount) => {
  if (!bookedEvent ) {
    return
  }
  let contractFileUrl = bookedEvent.contract_file_url && typeof bookedEvent.contract_file_url !== 'undefined' ? bookedEvent.contract_file_url : null;
  let delegateFileUrl = bookedEvent.delegate_file_url && typeof bookedEvent.delegate_file_url !== 'undefined' ? bookedEvent.delegate_file_url : null;
  let invoiceFileUrl = bookedEvent.invoice_file_url && typeof bookedEvent.invoice_file_url !== 'undefined' ? bookedEvent.invoice_file_url : null;

  let preAddUrl = (link) => {
    let testForHttp = /^((http|https|ftp):\/\/)/;

    if (link && !testForHttp.test(link)) {
      return "http://" + link;
    }
  }
  return (
    <>
      <div className="flex items-center content-center">
        <div className={'w-40'}>
          {downloadButton(contractFileUrl, 'fa-file-contract', 'Download Contract')}
          {downloadButton(delegateFileUrl ? delegateFileUrl : preAddUrl(bookedEvent.delegate_text), 'fa-users', 'Download Delegate List')}
          {downloadButton(invoiceFileUrl, 'fa-hotel', 'Download Client/Venue Invoice')}
          {isCompanyAccount ? null : <BookedEventsDownloadButton type={'invoice'}
                                      text={bookedEvent.event_status.code >= 40 ? 'Download Invoice' : 'Generate Invoice'}
                                      event={bookedEvent} style={'link'} disabled={bookedEvent.event_status.code < 40} />}
          <ReactTooltip/>
        </div>
      </div>
    </>
  )
}

const ViewBookedEvent = ({
                           onClose,
                           event,
                           bookedEvent,
                           fetchSingleBookedEvent,
                           token,
                           eventsSingleUnload,
                           canManageEventPreData,
                           canManageEventCompletion,
                           canChaseEvent,
                           isCompanyUser,
                           isCompanyAdmin,
                           isNonEventSpecialist,
                           isEventSpecialist,
}) => {
  useEffect(() => {
    fetchSingleBookedEvent(event.id, token)
    return () => {
      eventsSingleUnload()
    }
  }, []);
  let confirmedData = bookedEvent ? bookedEvent.enquiry_venue_datas : null;
  let isCompanyAccount = isCompanyAdmin || isCompanyUser;
  let isRfpXpress = bookedEvent?.enquiry?.rfp_xpress_service;

  if (!bookedEvent)
  {
    return (
    <Wrapper onClose={onClose}>
      <div className={`${event.status.toLowerCase()}-event-modal`}>
        <h2>Event Details</h2>
        <Loading />
      </div>
    </Wrapper>
    );
  }

  let actionButtons = () => {

    if(isNonEventSpecialist || isEventSpecialist)
    {
      return null;
    }
    return (
        <>
          {/* Show buttons/actions if confirmed and not cancelled */}
          {bookedEvent && (bookedEvent.event_status && (bookedEvent.event_status.code === 10 || (bookedEvent.event_status.code >= 20 && bookedEvent.event_status.code !== 50))) && (
              <tr>
                <td>Proposal/Confirmation</td>
                <td>
                  <EventsListDownloadProposalConfirmationActionButtons
                      bookedEvent={bookedEvent}
                      confirmedData={confirmedData}
                      canManageEventPreData={canManageEventPreData}
                      canManageEventCompletion={canManageEventCompletion}
                      isCompanyAccount={(isCompanyAdmin || isCompanyUser)}
                      canChaseEvent={ canChaseEvent}
                      token={token}
                  />
                </td>
              </tr>
          )}
          {bookedEvent && (bookedEvent.event_status && (bookedEvent.event_status.code === 10 || (bookedEvent.event_status.code >= 20 ))) && !isRfpXpress && (
              <tr>
                <td>Downloads</td>
                <td>{renderDownloadButtons(bookedEvent, isCompanyAccount)}</td>
              </tr>
          )}
          {(canManageEventPreData || canManageEventCompletion) && ( isCompanyAccount && bookedEvent && bookedEvent.event_status.code < 30 || !isCompanyAccount ) &&  bookedEvent.event_status.code > 10 && (
              <>
                {/* May not be correct if cancelled */}

                <tr>
                  <td>Manage</td>
                  <td>
                    <>
                      <ButtonPrimary
                          classes={"m-2 w-full"}
                          Element={Link}
                          to={"/admin/events/" + bookedEvent.id}
                      >
                        {isCompanyAccount ? 'Delegates' : 'Manage'}
                      </ButtonPrimary>
                    </>
                  </td>
                </tr>
              </>)}
        </>
    )
  }

  return (
    <Wrapper onClose={onClose}>
      <div className={`${event.status.toLowerCase()}-event-modal`}>
        <h2>Event Details</h2>

        <div className="mt-3">
          <div className="event-details text-lg my-6">
            <table className="edge-table">
              <tbody className="divide-y divide-gray-200">
              <tr>
                {/*Calendar event uses title (with ref_id) so need to use different var name*/}
                <td>Ref / Title</td>
                <td>
                  <strong>{event.ref_id}: {event.description}</strong>
                  {event.isConfidential ? (
                    <>
                      <br/><span className="inline-block bg-red text-white p-1 mt-2 text-xs mr-1">Confidential</span>
                    </>
                    ) : null}
                </td>
              </tr>
              {bookedEvent.enquiry && bookedEvent.enquiry.company_branch ? (
                <tr>
                  <td>Branch</td>
                  <td>{bookedEvent.enquiry.company_branch.name}</td>
                </tr>
              ) : null}
              <tr>
                {/*Calendar event uses title (with ref_id) so need to use different var name*/}
                <td>Client Name</td>
                <td>{event.client_name}</td>
              </tr>
              <tr>
                <td>Venue</td>
                <td>{event.venue}</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>{event.location}</td>
              </tr>
              <tr>
                <td>Start</td>
                <td>{new Date(event.start).toDateString()}</td>
              </tr>
              <tr>
                <td>End</td>
                <td>{new Date(event.end).toDateString()}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{event.status}</td>
              </tr>
              {actionButtons()}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    token: token(state),
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    isCompanyAdmin: isCompanyAdmin(state.auth),
    isCompanyUser: isCompanyUser(state.auth),
    canManageEventPreData: canManageEventPreData(state.auth),
    canManageEventCompletion: canManageEventCompletion(state.auth),
    canChaseEvent: canChaseEvent(state.auth),
    isAgencyAdmin: isAgencyAdmin(state.auth),
    isNonEventSpecialist: isNonEventSpecialist(state.auth),
    isEventSpecialist: isEventSpecialist(state.auth),
    ...state.bookedEvent
  }
}

const mapDispatchToProps = {
  fetchSingleBookedEvent,
  eventsSingleUnload
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBookedEvent)
