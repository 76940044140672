import React, { useState } from "react";
import TrapezeHeader from "Components/TrapezeHeader";
import { FaRegMinusSquare, FaRegPlusSquare, FaRegSave } from "react-icons/fa";
import SavedSearchesDisplay from "Components/SavedSearches/Display";
import SaveSearchButton from "Components/SavedSearches/SaveButton";

const YourSearches = ({ searchForm, results, search, smallTitle }) => {
  const [expanded, setExpanded] = useState(true);
  const toggleExpand = () => setExpanded(previousValue => !previousValue);

  return (
    <div>
      <div
        className={`flex flex-wrap justify-between items-center bg-white pointer pr-3`}
        onClick={toggleExpand}
      >
        <TrapezeHeader
          bgColor="grey-darker"
          bigText
          textLeft
          title="Your Searches"
          icon={<FaRegSave className="mr-2 text-white text-lg" />}
        />
        {expanded ? (
          <FaRegMinusSquare className="pointer" />
        ) : (
          <FaRegPlusSquare className="pointer" />
        )}
      </div>
      {expanded && (
        <div className="bg-white p-3 pt-5">
          <SaveSearchButton
            searchForm={searchForm}
            results={results}
            outline
            noStar
          />
          <div className="mt-5">
            <SavedSearchesDisplay search={search} smallTitle />
          </div>
        </div>
      )}
    </div>
  );
};

export default YourSearches;
