import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchVenuePriceBandingData} from "../../services/priceBandingService/fetchPriceBandings";
import {token} from "../../modules/auth";
import {useSelector, connect} from "react-redux";
import PriceBandingReduced from "../PriceBanding/PriceBandingReduced";
import {getFormValues } from "redux-form";
import ExclusionDatesWarning from "../../pages/admin/EditVenue/ConferencePricing/PriceBanding/ExclusionDatesWarning";

const mapDispatchToProps = {};

const mapStateToProps = state => ({
    token: token(state),
});

const VenueCard = ({venue, removeVenue, canRemove = false, token}) => {
  const values = useSelector(getFormValues('rfp_enquiry')) || {};
  let [venuePriceBandings, setVenuePriceBandings] = useState(false)

    useEffect(() => {
        fetchVenuePriceBandingData(token, venue.slug).then((response) => {
            if(Object.keys(response).length !== 0) {
                setVenuePriceBandings(response)
            }
        })
    }, []);
  let address = venue.town;
  if (address && venue.county) {
    address += `, ${venue.county}`
  } else if (venue.county) {
    address = venue.county;
  }
  const image = venue.image || venue.featured_image_id || (venue.images && venue.images[0]) || {}.id;
  const bgImage = image ? `${window.API}/venues/${venue.slug}/images/${image}` : null; // bg-[url(${bgImage})] latest tailwind class

  return (
    <div key={venue.id} className="flex flex-wrap flex-col md:flex-row bg-white" style={{cursor: "grab"}}>
      <div className="item h-48 md:w-1/2 flex-auto">
        {canRemove ? (
          <div className="block absolute mt-2 ml-2">
            <i
              onClick={() => removeVenue(venue.id)}
              className="fas text-2lg fa-minus-circle hover:text-red pointer"
              title="Remove venue from enquiry"
            />
          </div>
        ) : null}
        <div className="bg-blue-light h-full" >
          <img src={`${bgImage}`} alt={venue.name}
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%"
               }}
          />
        </div>

      </div>
      <div className="item p-3 flex h-24 md:h-48 md:w-1/2 flex-auto flex-col justify-between">
        <h3 className="uppercase font-bold text-lg">
          <Link to={`/venue/${venue.slug}`} className="text-black hover:text-brand" title="View profile">
            {venue.name}
          </Link>
          </h3>
          <div className={'flex'}>
              <div className={'text-xs'}>
                  {venuePriceBandings && venuePriceBandings['show_24hr_on_profile'] && venuePriceBandings['twenty_four_hr_price_banding_rate'] ? <React.Fragment><PriceBandingReduced type={'24hr'} venuePriceBindings={venuePriceBandings}/><br/></React.Fragment> : null}
                  {venuePriceBandings && !venuePriceBandings['show_24hr_on_profile'] && venuePriceBandings['bb_price_banding_rate'] ?  <React.Fragment><PriceBandingReduced type={'bb'} venuePriceBindings={venuePriceBandings}/><br/></React.Fragment> : null}
                  {venuePriceBandings && venuePriceBandings['ddr_price_banding_rate'] ? <React.Fragment><PriceBandingReduced type={'ddr'} venuePriceBindings={venuePriceBandings}/><br/></React.Fragment> : null}
              </div>
              <div className={'self-end'}>
                  <ExclusionDatesWarning fromDate={values.date_from} toDate={values.date_to} venueSlug={venue.slug}/>

              </div>
          </div>


        <p className="font-normal mb-0 text-sm">{address}</p>
      </div>

        {venue.capacity || venue.bedrooms || venue.meeting_rooms ? (
          <div className="item flex flex-row flex-auto font-normal text-lg py-2 justify-between">
            {venue.capacity ? (
              <div className="mx-2" title="Capacity">
                <i className="mr-2 fas fa-users"/>{venue.capacity}
              </div>
            ) : null}
            {venue.bedrooms ? (
              <div className="mx-2" title="Bedrooms">
                <i className="mr-2 fas fa-bed"/>{venue.bedrooms}
              </div>
            ) : null}
            {venue.meeting_rooms ? (
              <div className="mx-2" title="Meeting Rooms">
                <i className="mr-2 fas fa-handshake"/>{venue.meeting_rooms}
              </div>
            ) : null}
          </div>
        ) :
            <div className="item flex flex-row flex-auto font-normal text-lg bg-white justify-between p-2">Venue data not provided</div>
        }

    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueCard);