import { string, bool, func, object, arrayOf } from "prop-types";

export default {
  // user
  form: string.isRequired,
  url: string.isRequired,
  render: func.isRequired,
  fields: arrayOf(string),
  fieldsWithText: arrayOf(string), // for multi selects on live searches (see ./parseResponse.js)
  initializeEmptyRow: arrayOf(string), // for fieldArrays (see ./parseResponse.js)
  parseResponse: func,
  ignore: arrayOf(string),
  handleResponse: func,
  create: bool,
  data: object,
  sendTheLot: bool,
  appendIdToPatch: bool,

  // stp
  token: string.isRequired,
  spanshot: object.isRequired,

  // react router
  history: object.isRequired,
  location: object.isRequired,

  // redux form
  handleSubmit: func.isRequired,
  initialize: func.isRequired,
  initialized: bool.isRequired,
  submitSucceeded: bool.isRequired,
  submitFailed: bool.isRequired,
  submitting: bool.isRequired,
};
