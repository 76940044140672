import React, { useEffect, useState } from "react";
import axios from "axios";
import { WhitePanelWithHeader } from "ui";
import Label from "../Form/Label";
import { ReactComponent as ToggleOn } from "../../svg/toggle-on.svg";
import { ReactComponent as ToggleOff } from "../../svg/toggle-off.svg";

const ShowAllToggle = ({ showAllNotifications, setShowAllNotifications }) => {
  return <div className={"my-2 flex-grow"}>
    <Label text={"Show all notifications?"} />
    <div className="col col-w-full">
        <span onClick={() => {
          const newValue = showAllNotifications ? 0 : 1;
          setShowAllNotifications(newValue);
        }} className="pointer text-3xl">
          {Boolean(showAllNotifications) === true && (
            <ToggleOn
              style={{ transform: "scale(2)" }}
              className="fill-current text-green"
            />
          )}
          {Boolean(showAllNotifications) === false && (
            <ToggleOff
              style={{ transform: "scale(2)" }}
              className="fill-current text-dark-grey"
            />
          )}
        </span>
    </div>
  </div>;
};


const NotificationTracking = ({ expanded, enquiryId, token, enquiryVenueDataId }) => {
  const [emailEvents, setEmailEvents] = useState();
  const [panelOpen, setPanelOpen] = useState(false);
  const [showAllNotifications, setShowAllNotifications] = useState();

  useEffect(() => {
    return () => setEmailEvents();
  }, []);

  useEffect(() => {
    if (panelOpen) {
      getEmailEvents();
    } else {
      setEmailEvents();
    }
  }, [panelOpen]);

  useEffect(() => {
    if (panelOpen) {
      getEmailEvents();
    }
  }, [enquiryVenueDataId]);

  const togglePanel = () => {
    setPanelOpen(!panelOpen);
  };

  const getEmailEvents = async () => {
    await axios.get(`${window.API}/email-events/${enquiryVenueDataId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(({ data }) => setEmailEvents(data))
      .catch(error => console.error(error));
  };

  const filterNotificationStatuses = (emailEvent) => {
    if (!showAllNotifications) {
      return emailEvent.status.code === "delivered";
    }
    return true;
  };

  return <WhitePanelWithHeader
    title="Notification Tracking"
    collapsible
    onToggle={togglePanel}
    expanded={expanded}
  >
    <ShowAllToggle showAllNotifications={showAllNotifications} setShowAllNotifications={setShowAllNotifications} />
    {emailEvents && emailEvents.data && emailEvents.data.length > 0 ? (
      <table className={"border border-grey-md edge-table"}>
        <thead>
        <tr className="pb-5 font-bold">
          <td className={"p-3 text-left"}>Subject</td>
          <td className={"p-3 text-left"}>Recipient</td>
          <td className={"p-3 text-left"}>Status</td>
          <td className={"p-3 text-left"}>Event Time</td>
        </tr>
        </thead>
        {emailEvents.data.filter(filterNotificationStatuses).map((emailEvent, i) => {
          let date = new Date(emailEvent.timestamp * 1000);
          let options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          };
          let eventTime = date.toLocaleDateString("en-GB", options);
          let subject = "No subject line available";
          let title;
          if (emailEvent) {
            if (emailEvent.subject) {
              subject = emailEvent.subject;
            } else if (emailEvent.notification_meta && emailEvent.notification_meta.extra_data && emailEvent.notification_meta.extra_data.subject) {
              subject = emailEvent.notification_meta.extra_data.subject;
            }
            if (emailEvent.notification_meta && emailEvent.notification_meta.notification_type && emailEvent.notification_meta.notification_type.name) {
              title = emailEvent.notification_meta.notification_type.name;
            }
          }
          return <tr key={emailEvent.id} className={i % 2 === 0 ? "bg-off-white" : ""} title={title}>
            <td className={"p-3 text-left"}>
              {subject}
            </td>
            <td className={"p-3 text-left"}>{emailEvent?.notification_meta?.to_email_address}</td>
            <td className={"p-3 text-left"}>{emailEvent?.status?.name}</td>
            <td className={"p-3 text-left"}>{eventTime}</td>
          </tr>;
        })}
      </table>
    ) : <p>
      No Notification Tracking Available
    </p>}
  </WhitePanelWithHeader>;
};

export default NotificationTracking;
