import React, {useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import {token} from "../../modules/auth";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Loading from "../Loading";

import {getExclusionDates} from "../../services/priceBandingService/manageExclusionDates";

import moment from 'moment';

const mapDispatchToProps = {};

const mapStateToProps = state => ({
    token: token(state),
});


let ExclusionDatesBanner = ({token, venue, title = 'Excluded Dates', listView = false}) => {
    let [exclusionDates, setExclusionDates] = useState(false)

    useEffect(() => {
        getExclusionDates(token, venue).then((response) => {
            setExclusionDates(response)
        })
    }, []);


    if (!exclusionDates || exclusionDates.length === 0) {
        return <></>
    }

    if (listView) {
        let iterator = 0;
        return <React.Fragment
        >
            {exclusionDates.map((dates) => {
                iterator++;
                return <span>Excluded Dates: {moment(dates.start_date).format('DD/MM/YYYY')} - {moment(dates.end_date).format('DD/MM/YYYY')}{iterator !== exclusionDates.length ? ', ' : null}</span>
            })}
        </React.Fragment>
    }

    return (
        <React.Fragment
        >
          <span
              data-tip
              data-for={'exclusion-dates'}
              data-event="click"
              className="flex items-center pointer text-black  font-normal"
          >
            {title} <span className={'pl-2'} style={{lineHeight: '2px'}}><FaInfoCircle /></span>
          </span>
            <ReactTooltip
                id={'exclusion-dates'}
                effect="solid"
                type="light"
                clickable={true}
                className="tooltip-shadow"
                globalEventOff="click"
            >
                {!exclusionDates && <Loading/>}
                <div className="flex text-transform-none flex-col">
                    <p className={'m-0'}><strong className={'leading-3'}>Pricing exempt on</strong></p>
                    {exclusionDates.map((dates) => {
                        return <p>{moment(dates.start_date).format('DD/MM/YYYY')} - {moment(dates.end_date).format('DD/MM/YYYY')}</p>
                    })}
                </div>
            </ReactTooltip>
        </React.Fragment>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExclusionDatesBanner));