import React from "react";
import { connect } from "react-redux";
import { string, object, oneOfType, bool } from "prop-types";
import moment from "moment";
import { Field, getFormValues, change } from "redux-form";
import Date from "./Date";

class DateRange extends React.Component {
  static propTypes = {
    formId: string.isRequired,
    label: string,
    startName: string.isRequired,
    endName: string.isRequired,
    startDate: oneOfType([string, object]),
    endDate: oneOfType([string, object]),
    preventReverseRange: bool,
  };

  setStart = startDate => {
    const {
      endDate,
      endName,
      formId,
      change,
      preventReverseRange,
    } = this.props;
    if (!preventReverseRange) return;

    const startMoment = moment(startDate);

    if (startMoment.isAfter(endDate)) {
      change(formId, endName, startMoment.clone());
    }
  };

  setEnd = endDate => {
    const {
      startDate,
      startName,
      formId,
      change,
      preventReverseRange,
    } = this.props;
    if (!preventReverseRange) return;

    const endMoment = moment(endDate);

    if (endMoment.isBefore(startDate)) {
      change(formId, startName, endMoment.clone());
    }
  };

  render() {
    const {
      startName,
      endName,
      label,
      startDate,
      endDate,
      fromLabel,
      toLabel,
      noLabels,
      noMargin,
    } = this.props;

    return (
      <React.Fragment>
        {label && <h3 className="uppercase mb-2">{label}</h3>}
        <div className="flex">
          <Field
            name={startName}
            selectsStart
            selected={startDate ? moment(startDate) : undefined}
            startDate={startDate ? moment(startDate) : undefined}
            endDate={endDate ? moment(endDate) : undefined}
            label={fromLabel || "From"}
            labelClassName={noLabels ? "sr-only" : null}
            wrapperClassName={noMargin ? "w-32" : null}
            size="sm"
            component={Date}
            onChange={this.setStart}
          />
          <Field
            name={endName}
            selectsStart
            selected={endDate ? moment(endDate) : undefined}
            startDate={startDate ? moment(startDate) : undefined}
            endDate={endDate ? moment(endDate) : undefined}
            label={toLabel || "To"}
            labelClassName={noLabels ? "sr-only" : null}
            wrapperClassName={noMargin ? "w-32" : null}
            size="sm"
            component={Date}
            onChange={this.setEnd}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  (state, props) => {
    const values = getFormValues(props.formId)(state) || {};

    return {
      startDate: values[props.startName],
      endDate: values[props.endName],
    };
  },
  { change }
)(DateRange);
