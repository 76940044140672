import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";

const VenuesList = ({ items, onRemove }) => {
  return (
    <>
      <p className="font-small uppercase font-bold text-grey-md mb-3">
        Selected venues
      </p>
      <table className="shortlist-table border border-grey-md">
        <tbody>
          {items.map((item, i) => {
            return (
              <tr className="text-sm border-b border-grey-md" key={i}>
                <td className="p-2 w-4/5">{item.name}</td>
                <td className="p-2 w-1/5 text-right">
                  <FaRegTrashAlt
                    className="text-red pointer"
                    onClick={() => onRemove(i)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default VenuesList;
