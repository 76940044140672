import React, {Fragment, useEffect, useState} from 'react';
import {differenceInMonths} from "date-fns";
import {shortMonthNameArray} from "../../../utils";

const SnapshotReportTable = props => {
  const {data = []} = props;
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (data && data[0]) {
      setReportData(data[0])
    }
  }, [data])

  const dateDifference = (firstDate, secondDate) => {
    return differenceInMonths(secondDate, firstDate);
  }

  const dateRangeHeader = (key, dateRange) => {
    let firstDate = new Date(dateRange[0])
    let secondDate = new Date(dateRange[1])
    if (key === 'currentMonth') {
      return (
        <>
          <span>
            <strong>Current Month</strong><br/>
            {firstDate.toLocaleString("en-GB", { month: "long" })}
          </span>
        </>
      );
    }
    if (secondDate > new Date()) {
      return (
        <>
          <span>
            <strong>Current Financial Year</strong><br/>
            {shortMonthNameArray[firstDate.getMonth()] + " " + firstDate.getFullYear() + " To " + shortMonthNameArray[secondDate.getMonth()] + " " + secondDate.getFullYear()}
          </span>
        </>
      );
    }
    if (secondDate < new Date()) {
      return (
        <>
          <span>
            <strong>Previous Financial Year</strong><br/>
            {shortMonthNameArray[firstDate.getMonth()] + " " + firstDate.getFullYear() + " To " + shortMonthNameArray[secondDate.getMonth()] + " " + secondDate.getFullYear()}
          </span>
        </>
      );
    }
    let difference = dateDifference(firstDate, secondDate);
    if (difference > 1 && difference < 12) {
      return shortMonthNameArray[firstDate.getMonth()] + " " + firstDate.getFullYear() + " To " + shortMonthNameArray[secondDate.getMonth()] + " " + secondDate.getFullYear();
    } else if (firstDate && secondDate) {
      return `${shortMonthNameArray[firstDate.getMonth()]} ${firstDate.getUTCFullYear()} To ${shortMonthNameArray[secondDate.getMonth()]} ${secondDate.getUTCFullYear()}`;
    }
    return '';
  }

  const formatValue = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value)
  }

  return (
    <Fragment>
      <table className={"edge-table"}>
        <thead>
        <tr>
          <td>&nbsp;</td>
          {reportData && reportData.length > 0 && reportData.map((report, index) => {
            if (report.date_range) {
              return <td key={index} style={{textAlign: "right"}}>{dateRangeHeader(report.key, report.date_range)}</td>
            }
          })}
        </tr>
        </thead>
        <tbody className={"divide-y divide-gray-200"}>
        {reportData && reportData.length > 0 && (
          <>
            <tr>
              <td>No. of Enquiries</td>
              <td style={{textAlign: "right"}}><strong>{reportData[0].number_of_enquiries}</strong></td>
              <td style={{textAlign: "right"}}><strong>{reportData[1].number_of_enquiries}</strong></td>
              <td style={{textAlign: "right"}}><strong>{reportData[2].number_of_enquiries}</strong></td>
            </tr>
            <tr>
              <td>No. of Confirmed Bookings</td>
              <td style={{textAlign: "right"}}><strong>{reportData[0].number_of_confirmed_bookings}</strong></td>
              <td style={{textAlign: "right"}}><strong>{reportData[1].number_of_confirmed_bookings}</strong></td>
              <td style={{textAlign: "right"}}><strong>{reportData[2].number_of_confirmed_bookings}</strong></td>
            </tr>
            <tr>
              <td>Net Value of Confirmed Bookings</td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[0].value_of_confirmed_bookings)}</strong></td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[1].value_of_confirmed_bookings)}</strong></td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[2].value_of_confirmed_bookings)}</strong></td>
            </tr>
            <tr>
              <td>No. of Provisional Bookings</td>
              <td style={{textAlign: "right"}}><strong>{reportData[0].number_of_provisional_bookings}</strong></td>
              <td style={{textAlign: "right"}}><strong>{reportData[1].number_of_provisional_bookings}</strong></td>
              <td style={{textAlign: "right"}}><strong>{reportData[2].number_of_provisional_bookings}</strong></td>
            </tr>
            <tr>
              <td>Net Value of Provisional Bookings</td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[0].value_of_provisional_bookings)}</strong></td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[1].value_of_provisional_bookings)}</strong></td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[2].value_of_provisional_bookings)}</strong></td>
            </tr>
            <tr>
              <td>No. of Cancelled Bookings</td>
              <td style={{textAlign: "right"}}><strong>{reportData[0].number_of_cancelled_bookings}</strong></td>
              <td style={{textAlign: "right"}}><strong>{reportData[1].number_of_cancelled_bookings}</strong></td>
              <td style={{textAlign: "right"}}><strong>{reportData[2].number_of_cancelled_bookings}</strong></td>
            </tr>
            <tr>
              <td>Net Value of Cancelled Bookings</td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[0].value_of_cancelled_bookings)}</strong></td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[1].value_of_cancelled_bookings)}</strong></td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[2].value_of_cancelled_bookings)}</strong></td>
            </tr>
            <tr>
              <td>Total Net Cost Savings</td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[0].total_cost_savings_value)}</strong></td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[1].total_cost_savings_value)}</strong></td>
              <td style={{textAlign: "right"}}><strong>{formatValue(reportData[2].total_cost_savings_value)}</strong></td>
            </tr>
            <tr>
              <td>Total Percentage Savings</td>
              <td><strong className="inline-block rounded-100 h-12 w-12 flex items-center justify-center bg-brand text-black float-right">{reportData[0].total_percentage_savings}%</strong></td>
              <td><strong className="inline-block rounded-100 h-12 w-12 flex items-center justify-center bg-brand text-black float-right">{reportData[1].total_percentage_savings}%</strong></td>
              <td><strong className="inline-block rounded-100 h-12 w-12 flex items-center justify-center bg-brand text-black float-right">{reportData[2].total_percentage_savings}%</strong></td>
            </tr>
          </>
        )}
        </tbody>
      </table>
    </Fragment>
  )
}

export default SnapshotReportTable
