import { currencyFormatter } from "../../utils";

export default (total, currency = {currency_code: "GBP"}) => {
  let savingPrice = "";
  if (parseFloat(total.percentage_saving)) {
    savingPrice = (
      parseFloat(total.reduced_from_exc_vat) - parseFloat(total.total_exc_vat)
    ).toFixed(2);
  }
  return `${total.percentage_saving}%${
    savingPrice ? "   (" + currencyFormatter(currency.currency_code).format(savingPrice) + ")" : ""
  }`;
};
