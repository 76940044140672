import React from 'react'
import {connect} from "react-redux";
import {Route, withRouter} from 'react-router-dom'
import {isLoggedIn} from "../modules/auth";
import {RedirectToLogin} from "../Components/login-redirect";

const PrivateRoute = ({component: Component, loggedIn, history, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <RedirectToLogin/>
        )
      }
    />
  )
}

export default withRouter(
  connect(state => ({
    loggedIn: isLoggedIn(state),
  }))(PrivateRoute)
);
