
import React  from 'react';

import {
    string,
    object,
    arrayOf,
    oneOfType,
    shape,
    number,
    func,
    bool,
} from "prop-types";
import Label from "./Label";
import Error from "./Error";
import Select from 'react-select';

export default class SmartSelect extends React.Component {
    static propTypes = {
        options: arrayOf(
            oneOfType([
                string.isRequired,
                shape({
                    label: string.isRequired,
                    value: oneOfType([string, number]).isRequired,
                }).isRequired,
            ])
        ),
        input: object.isRequired,
        meta: object.isRequired,
        label: oneOfType([string, object]).isRequired,
        type: string,
        placeholder: string,
        icon: string,
        wrapperClassName: string,
        labelClassName: string,
        selectClassName: string,
        right: func,
        white: bool,
        noPlaceholder: bool,
    };

    render() {
        const {
            meta: { touched, error, warning },
            input,
            isMulti = false,
            options,
            label,
            wrapperClassName,
            labelClassName,
            right,
        } = this.props;
        return (
            <div className={wrapperClassName || "mb-5"}>
                <Label name={input.name} className={labelClassName} text={label} />
                <div className="flex">
                    <div className="bg-white relative w-full">
                        <i className="fal fa-angle-down absolute-center-y pin-r text-2xl mr-2 text-dark-grey" />
                        <Select
                            {...input}
                            value={input.value}
                            onChange={(value) => { return input.onChange(value) }}
                            onBlur={() => input.onBlur(input.value)}
                            options={options}
                            isMulti={isMulti}
                        />
                    </div>
                    {right && right()}
                </div>
                <Error touched={touched} warning={warning} error={error} />
            </div>
        );
    }
}
