import React from "react";
import { arrayOf, bool, number, shape, string } from "prop-types";
import { Field } from "redux-form";
import { File, Input, Venues, Wysiwyg } from "Components/Form";
import { maxLength, maxLengthSub1k, required } from "modules/validation";

const v = {
  title: [required(), maxLength(100)],
  content: [required(), maxLengthSub1k(6000)],
};

export default class ArticleFields extends React.Component {
  static propTypes = {
    formId: string.isRequired,
    paid: bool,
    premium: bool,
    venueOptions: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
      }),
    ),
  };

  render() {
    return (
      <React.Fragment>
        <Field
          name="title"
          label="Title"
          component={Input}
          validate={v.title}
        />
        <Venues
          paid={this.props.paid}
          premium={this.props.premium}
          venueOptions={this.props.venueOptions}
        />
        <Field
          name="content"
          label="Content"
          component={Wysiwyg}
          validate={v.content}
        />
        <File
          type="image"
          path={`${window.API}/cms/images`}
          objectName="image"
          idName="image_id"
          formId={this.props.formId}
        />
        <File
          label="Attachments"
          type="document"
          array
          path={`${window.API}/cms/files`}
          readFrom="files"
          formId={this.props.formId}
        />
      </React.Fragment>
    );
  }
}
