export default {
  userType: [
    {
      text: "Corporate",
      value: 1,
    },
    {
      text: "Agency",
      value: 2,
    },
    {
      text: "Individual",
      value: 3,
    },
    {
      text: "Association/Charity",
      value: 4,
    },
    {
      text: "Venue",
      value: 6,
    },
    {
      text: "Other",
      value: 5,
    },
  ],
  userTypeOther: [
    {
      text: "Individual",
      value: 3,
    },
    {
      text: "Association/Charity",
      value: 4,
    },
    {
      text: "Other",
      value: 5,
    },
  ],
  industrySector: [
    "Services, construction, agriculture, mining",
    "Charity, government, utilities",
    "Retail",
    "Entertainment, media, food & drink, travel",
    "Pharma, health",
    "Communications, media",
    "Finance",
    "Other",
  ],
};
