import React from "react";
import { connect } from "react-redux";
import { withScriptjs } from "react-google-maps";
import { Field, change } from "redux-form";
import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";
import { Input } from "Components/Form";
import { GOOGLE_MAPS_API_KEY } from "../../constants";

// this is a drop in google search input
// it sets lat, lng & props.name in the form specified by props.formId
// props.onPlaceFound is a callback for any additional needs upon location selection

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.setRef = {
      searchBox: e => (this.searchBox = e),
    };
  }

  onFieldChange = (event, newValue, previousValue, name) => {
    this.props.rest.onPlaceChange && this.props.rest.onPlaceChange(newValue);
  }

  onPlacesChanged = () => {
    const props = this.props.rest;
    const places = this.searchBox.getPlaces();

    const placeRaw = Array.isArray(places) && places[0];

    if (!placeRaw) return;

    // Quick way to flatten all the google object's getter functions in to a
    // plain object
    const place = JSON.parse(JSON.stringify(placeRaw));

    const name = place.formatted_address;
    const lat = place.geometry.location.lat;
    const lng = place.geometry.location.lng;
    const addressType = place.types[0];

    const placeArea = place.geometry.viewport;

    this.props.change(props.formId, "lat", lat);
    this.props.change(props.formId, "lng", lng);
    this.props.change(props.formId, props.name, name);
    this.props.change(props.formId, "placeArea", placeArea);
    this.props.change(props.formId, "addressType", addressType);

    props.onPlaceFound &&
      props.onPlaceFound(lat, lng, name, { placeArea }, addressType);
  };

  render() {
    // console.log({ props: this.props})
    return (
      <React.Fragment>
        <StandaloneSearchBox
          ref={this.setRef.searchBox}
          onPlacesChanged={this.onPlacesChanged}
        >
          <Field component={Input}  onChange={this.onFieldChange} tall={this.props.tall} {...this.props.rest}/>
        </StandaloneSearchBox>
      </React.Fragment>
    );
  }
}

const LocationWithScript = withScriptjs(
  connect(
    undefined,
    { change }
  )(Location)
);

const LocationToExport = props => (
  <LocationWithScript
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `400px` }} />}
    googleMapURL={
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`
    }
    rest={props}
  />
);
export default LocationToExport;
