import React from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { loadedArticles, loadingArticles } from "modules/articles";
import { connect } from "react-redux";
import Loading from "Components/Loading";
import Breadcrumbs from "Components/Breadcrumbs";
import { getVenueImageFromArticle, offerStyles } from "modules/article-utils";
import { capitalize } from "modules/string-tools";
import { tidyWysiwygContent } from "../../modules/string-tools";
import { venueInteraction } from "../../modules/analytics";
import { isLoggedIn } from "../../modules/auth";

const articleBySlug = (articles, slug) =>
  articles.find(article => article.slug === slug);

class Article extends React.Component {
  state = {
    showEmail: false,
    showPhone: false,
  };

  loadArticles(articleSlug) {
    const urls = ["/cms/articles", "/cms/offers"];
    const { loadingArticles, loadedArticles } = this.props;

    loadingArticles();

    Promise.all(urls.map(url => axios.get(window.API + url + "?slug=" + articleSlug))).then(
      ([articles, offers]) => {
        loadedArticles({
          articles: articles.data,
          offers: offers.data,
        });
      },
    );
  }

  componentDidMount() {
    const { match } = this.props;
    const { articleSlug } = match.params;
    this.loadArticles(articleSlug);
  }

  showEmail = () => {
    this.setState({ showEmail: true });
    const article = articleBySlug(this.props.articles, this.props.match.params.articleSlug);
    article.venues.map(venue => {
      this.handleVenueInteraction(venue, this.getInteractionType(article, "email"));
    });
  };

  handleVenueInteraction = (venue, interactionType) => {
    if (venue) {
      this.props.venueInteraction({
        type: interactionType,
        id: venue.id,
        data_score: venue.data_score,
        impression_id: venue.impression_id,
      });
    }
  };

  showPhone = () => {
    this.setState({ showPhone: true });
    const article = articleBySlug(this.props.articles, this.props.match.params.articleSlug);
    article.venues.map(venue => {
      this.handleVenueInteraction(venue, this.getInteractionType(article, "phone"));
    });
  };

  getInteractionType = (article, type) => {
    let interactionType;
    if (article.type.name === "news") {
      interactionType = "venue_content_news_" + type + "_click";
    } else if (article.type.name === "offers") {
      interactionType = "venue_content_offers_" + type + "_click";
    } else if (article.type.name === "renovation-news") {
      interactionType = "venue_content_renovation_" + type + "_click";
    } else if (article.type.name === "famtrip") {
      interactionType = "venue_content_famtrip_" + type + "_click";
    } else if (article.type.name === "movers-and-shakers") {
      interactionType = "venue_content_moversshakers_" + type + "_click";
    }
    return interactionType;
  };


  render() {
    const { match, articles, loading } = this.props;
    const { articleSlug } = match.params;

    if (loading)
      return (
        <div
          className="container flex flex-col justify-center"
          style={{
            minHeight: "50vh",
          }}
        >
          <Loading large />
        </div>
      );

    const article = articleBySlug(articles, articleSlug);

    if (!article) {
      return (
        <div className="container bg-white p-6 text-center">
          <p>Not Found</p>
        </div>
      );
    }

    const articleStyle = offerStyles[article.type.name] || {
      color: "202,17,61",
      title: article.type.name,
    };

    const Icon = ({ name }) => (
      <i
        className={`fas ${name} mr-2`}
        style={{ color: `rgb(${articleStyle.color})` }}
      />
    );

    const headerImage = getVenueImageFromArticle(article);
    const headerBackground = headerImage
      ? `url(${headerImage.url.replace("stage-api", "api").replace("localapi", "api")}?raw=1) no-repeat center center / cover`
      : "linear-gradient(100deg, #284044 0%,#6c777c 52%,#1e2833 100%)";

    const venue = article.venues && article.venues.length === 1 ? article.venues[0] : undefined;

    const detailItemStyle = "w-full sm:w-1/2 xl:w-1/3 mb-4";

    let parentLink = "/edge-venue-news";
    if (article && article.type && article.type.name) {
      switch (article.type.name) {
        case "blog":
          parentLink = "/edge-venue-blog";
          break;
        case "news":
          parentLink = "/edge-venue-news";
          break;
        case "renovation-news":
          parentLink = "/renovation-news";
          break;
        case "movers-and-shakers":
          parentLink = "/movers-and-shakers";
          break;
        default:
          parentLink = "/edge-venue-news";
      }
    }

    return (
      <React.Fragment>
        <div
          className="container h-72 p-0 relative bg-center bg-cover overflow-hidden"
          style={{
            background: headerBackground,
          }}
        >
          <div
            className="h-full w-64 absolute"
            style={{
              backgroundColor: `rgba(${articleStyle.color},0.8)`,
              transform: "skew(-15deg)",
              left: "-45px",
              zIndex: 0,
            }}
          />

          <div className="absolute w-full">
            <h1 className="text-white uppercase">{articleStyle.title}</h1>
          </div>

          <div className="absolute w-full pin-b pin-r m-3">
            {venue && (
              <h2
                className="text-white text-2xl float-right"
                style={{
                  textShadow: "2px 2px 4px rgba(0,0,0,0.8)",
                }}
              >
                <Link className="text-white" to={`/venue/${venue.slug}`}>
                  {venue.name}
                </Link>
              </h2>
            )}
          </div>
        </div>

        <div className="container">
          <div
            className="bg-white m-1 mt-4 mb-4 p-3"
            style={{
              minHeight: "50vh",
            }}
          >
            {venue && (
              <>
                {this.props.isLoggedIn ? (
                  <Link
                    className="float-right inline-block tracking-loose font-semibold font-heading px-4 border uppercase no-underline text-center mb-5 text-white border-brand bg-brand"
                    to={`/venue/${venue.slug}`}
                    onClick={() => {
                      this.handleVenueInteraction(venue, this.getInteractionType(article, "profile"));
                    }}
                  >
                    Go to Venue
                  </Link>
                ) : (
                  <Link
                    className="float-right inline-block tracking-loose font-semibold font-heading px-4 border uppercase no-underline text-center mb-5 text-white border-brand bg-brand"
                    onClick={() => {
                      this.props.history.push(
                        `/login?return=${encodeURI(
                          this.props.location.pathname,
                        )}&logInToProceed=1`,
                      );
                    }}
                  >
                    Register to view Venue Profile
                  </Link>
                )}
              </>
            )}
            <Breadcrumbs
              items={[
                { name: "Home", to: "/" },
                { name: capitalize(article.type.name) || "News", to: parentLink },
                { name: capitalize(article.title) },
              ]}
            />

            <header className="mb-5">
              <h1 className="text-3x1">{article.title}</h1>
            </header>
            {article.content &&
              <div
                className="wysiwyg-content"
                dangerouslySetInnerHTML={{
                  __html: tidyWysiwygContent(article.content),
                }}
              />
            }

            <div className="flex flex-wrap my-6">
              <div className="w-full md:w-1/2 px-4">
                {venue && (
                  <div className={detailItemStyle}>
                    {this.props.isLoggedIn ? (
                      <span>
                      <div onClick={() =>
                        this.handleVenueInteraction(venue, this.getInteractionType(article, "profile"))
                      }>
                        <a href={`/venue/${venue.slug}`}>
                          <Icon name="fa-link" /> Venue Profile
                        </a>
                      </div>
                    </span>
                    ) : (
                      <span>
                        <div onClick={() =>
                          this.handleVenueInteraction(venue, this.getInteractionType(article, "profile"))
                        }>
                          <div onClick={() => {
                            this.props.history.push(
                              `/login?return=${encodeURI(
                                this.props.location.pathname,
                              )}&logInToProceed=1`,
                            );
                          }} className="cursor-pointer">
                            <Icon name="fa-link" /> Register to view venue profile
                          </div>
                      </div>
                      </span>
                    )}
                  </div>
                )}

                {(venue && venue.phone) && (
                  <div className={detailItemStyle}>
                    {!this.props.isLoggedIn ? (
                      <div onClick={() => {
                        this.props.history.push(
                          `/login?return=${encodeURI(
                            this.props.location.pathname,
                          )}&logInToProceed=1`,
                        );
                      }} className="cursor-pointer">
                        <Icon name="fa-phone" /> Call
                      </div>
                    ) : this.state.showPhone ? (
                      <span>
                        <Icon name="fa-phone" />{" "}
                        <a href={`tel:${venue.phone}`}>{venue.phone}</a>
                      </span>
                    ) : (
                      <span className="cursor-pointer" onClick={this.showPhone}>
                        <Icon name="fa-phone" /> Call
                      </span>
                    )}
                  </div>
                )}

                {(venue && venue.email) && (
                  <div className={detailItemStyle}>
                    {!this.props.isLoggedIn ? (
                      <div onClick={() => {
                        this.props.history.push(
                          `/login?return=${encodeURI(
                            this.props.location.pathname,
                          )}&logInToProceed=1`,
                        );
                      }} className="cursor-pointer">
                        <Icon name="fa-envelope" /> Email
                      </div>
                    ) : this.state.showEmail ? (
                      <span>
                        <Icon name="fa-envelope" />{" "}
                        <a href={`mailto:${venue.email}`}>{venue.email}</a>
                      </span>
                    ) : (
                      <span className="cursor-pointer" onClick={this.showEmail}>
                        <Icon name="fa-envelope" />
                        Email
                      </span>
                    )}
                  </div>
                )}
              </div>
              {article && article.image && article.image.url ? (
                <div className="w-full md:w-1/2 p-2">
                  <img src={`${article.image.url.replace("stage-api", "api").replace("localapi", "api")}?raw=1`}
                       alt="" />
                </div>
              ) : null}
            </div>

            <div className="flex flex-wrap">
              {article.files &&
                !!article.files.length &&
                article.files.map(file => (
                  <div className={detailItemStyle}>
                    <a href={file.url}>
                      <Icon name="fa-arrow-alt-circle-down" />
                      <span className="text-bold">{file.name}</span>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(
  state => ({
    articles: state.articles.articles,
    loading: state.articles.loading,
    loaded: state.articles.loaded,
    isLoggedIn: isLoggedIn(state),
  }),
  { loadingArticles, loadedArticles, venueInteraction },
)(Article));