import moment from "moment";
import {ButtonPrimary, ButtonRed} from "../Button";
import Loading from "../Loading";
import {connect, useSelector} from "react-redux";
import * as ROUTES from "../../Router/corporate_routes";
import {saveData} from "../../services/downloadFile";
import React, {useState} from "react";
import axios from "axios";
import {token as tokenSelector} from "../../modules/auth"

let BookedEventsDownloadButton = ({event, type, onClick, disabled, text = "Download", style = "button", isRed = false}) => {
  const [submitting, setSubmitting] = useState(false);
  const token = useSelector(tokenSelector);
  const handleExport = (eventId) => async () => {
    if (submitting) {
      return;
    }

    if (onClick) {
      await onClick();
    }

    let url = window.API + ROUTES.API.BOOKED_EVENTS.EXPORT.replace(':slug', eventId).replace(':type', type)
    setSubmitting(true);
    try {
      const response = await axios.get(
        url,
        {
          responseType: "blob",
          headers: {Authorization: `Bearer ${token}`},
        }
      );
      let filename = type === 'invoice' ?
        `${event.enquiry.event_name.replace(/\s/g, '-')}-${moment().format('DD-MM-YYYY')}-invoice-${eventId}.pdf` :
        `${event.enquiry.event_name.replace(/\s/g, '-')}-${moment().format('DD-MM-YYYY')}-xero-${eventId}.csv`

      saveData(
        false,
        response.data,
        filename
      );
      setSubmitting(false);

    } catch (err) {
      setSubmitting(false);
      alert(`Sorry there was a problem fetching the pdf`);
    }
  };

  if (style === 'link') {
    if (disabled) {
      return <i
        className={`fas fa-1x text-lg font-medium text-dark-grey opacity-25 px-1 ${type === 'xero' ? ' fa-file-csv' : 'fa-file-invoice'}`}></i>
    }
    return (
      <a className={'text-lg font-medium px-1 cursor-pointer'} onClick={handleExport(event.id)}
         data-tip={`Download EDGE ${type}`}>
        <i className={`fas fa-1x ${type === 'xero' ? ' fa-file-csv' : 'fa-file-invoice'} ${isRed ? 'text-red' : ''}`}></i>
      </a>
    )
  }
  if(isRed) {
    return (
        <ButtonRed classes={`mr-2`} onClick={handleExport(event.id)} disabled={disabled}  target={"_blank"}>
          {submitting ? <Loading inline/> : text}
        </ButtonRed>
    )
  }
  return (
    <ButtonPrimary classes={`mr-2`} onClick={handleExport(event.id)} disabled={disabled}  target={"_blank"}>
      {submitting ? <Loading inline/> : text}
    </ButtonPrimary>
  )
}

export default connect(null, null)(BookedEventsDownloadButton);
