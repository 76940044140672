import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchMonthlyEventValuesReport, monthlyEventValuesUnload } from "../../../../redux/actions";
import { token } from "../../../../modules/auth";
import { corporateAccountObject } from "../../../../modules/permissions";
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from "recharts";
import Loading from "../../../../Components/Loading";

const MonthlyEventsReportContainer = ({
                                        monthlyEventValuesReport,
                                        fetchMonthlyEventValuesReport,
                                        corporateAccount,
                                        token,
                                        monthlyEventValuesUnload,
                                        height = 300,
                                        width = 500,
                                        aspect = 1,
                                        title = "Monthly Report",
                                        dashboardReport = true,
                                      }) => {
  const [monthlyEventsReportData, setMonthlyEventsReportData] = useState([]);
  useEffect(() => {
    if (corporateAccount && Object.entries(corporateAccount).length > 0) {
      fetchMonthlyEventValuesReport(corporateAccount.id, token);
    }
    return () => {
      monthlyEventValuesUnload();
    };
  }, []);

  useEffect(() => {
    if (dashboardReport && corporateAccount && Object.entries(corporateAccount).length > 0) {
      fetchMonthlyEventValuesReport(corporateAccount.id, token);
    }
  }, [dashboardReport]);

  useEffect(() => {
    let array = [];
    monthlyEventValuesReport && Object.entries(monthlyEventValuesReport).length > 0 && Object.entries(monthlyEventValuesReport).map(data => {
      if (data && data[0] === "monthlyValues") {
        Object.entries(data[1]).length > 0 && Object.entries(data[1]).map(month => {
          array.push({
            "name": month[0],
            "Enquiry Net Amount": month[1].enquiry,
            "Event Net Amount": month[1].event,
          });
        });
      }
    });
    setMonthlyEventsReportData(array);
  }, [monthlyEventValuesReport]);

  if (monthlyEventsReportData.length < 1) {
    return <div className={'text-center'}><Loading classes="py-4" /><span
      className="text-sm italic text-grey-darker p-2 ">Loading report data</span></div>;
  }

  return (
    <div
      // className={`flex flex-col lg:flex-wrap lg:flex-row align-bottom p-3 mb-3 lg:items-end ${!dashboardReport ? " bg-white" : ""}`}>
      className={`flex flex-col lg:flex-wrap lg:flex-row align-bottom  lg:items-end ${!dashboardReport ? " bg-white" : ""} p-4`}>
      {!dashboardReport ? (
        <h1 className="my-4">
          {title}
        </h1>
      ) : null}
      {/*<div className="flex flex-col lg:flex-wrap lg:flex-row align-bottom p-3 mb-3 lg:items-end">*/}
      <div style={{ width: '100%', height: 500 }}>
        <ResponsiveContainer>
          <AreaChart
              // width={300}
              // height={400}
              data={monthlyEventsReportData}
              margin={{ top: 50, right: 60, left: 30, bottom: 30 }}
          >
            <defs>
              <linearGradient id="colorEvent" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#9c3" stopOpacity={0.95} />
                <stop offset="95%" stopColor="#9c3" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="colorEnquiry" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#222" stopOpacity={0.95} />
                <stop offset="95%" stopColor="222" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis label={{ value: "Net (£)", angle: -90, position: 'insideBottomLeft', offset: 15 }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="Event Net Amount" stroke="#9c3" fill="url(#colorEvent)"
                  fillOpacity={1} />
            <Area type="monotone" dataKey="Enquiry Net Amount" stroke="#555" fill="url(#colorEnquiry)"
                  fillOpacity={1} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
      // <div className="flex flex-col lg:flex-wrap lg:flex-row align-bottom lg:items-end">
      //   <div className="">
      //     <ResponsiveContainer
      //       aspect={aspect}
      //       height={"100%"}
      //       // width={"100%"}
      //       minWidth={"50%"}
      //     >
      //       <AreaChart
      //           // width={300}
      //           // height={400}
      //         data={monthlyEventsReportData}
      //         // margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      //       >
      //         <defs>
      //           <linearGradient id="colorEvent" x1="0" y1="0" x2="0" y2="1">
      //             <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
      //             <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
      //           </linearGradient>
      //           <linearGradient id="colorEnquiry" x1="0" y1="0" x2="0" y2="1">
      //             <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
      //             <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
      //           </linearGradient>
      //         </defs>
      //         <XAxis dataKey="name" />
      //         <YAxis label={{ value: "Net Amount (£)", angle: -90 }} />
      //         <CartesianGrid strokeDasharray="3 3" />
      //         <Tooltip />
      //         <Area type="monotone" dataKey="Event Net Amount" stroke="#8884d8" fill="url(#colorEvent)"
      //               fillOpacity={1} />
      //         <Area type="monotone" dataKey="Enquiry Net Amount" stroke="#82ca9d" fill="url(#colorEnquiry)"
      //               fillOpacity={1} />
      //       </AreaChart>
      //     </ResponsiveContainer>
      //   </div>
      // </div>
    // </div>
  );
};

const mapStateToProps = state => {
  let corporateAccount = Object.entries(corporateAccountObject(state.auth)).length > 0 ? corporateAccountObject(state.auth) : state.corporateAccount?.corporateAccount;
  return {
    ...state.monthlyEventValuesReport,
    token: token(state),
    corporateAccount: corporateAccount,
  };
};

const mapDispatchToProps = {
  fetchMonthlyEventValuesReport,
  monthlyEventValuesUnload,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyEventsReportContainer);
