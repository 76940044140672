import { connect } from "react-redux";
import { shape, number, string } from "prop-types";
import { loadArticles } from "../../modules/articles";

export const connectOffers = Component =>
  connect(
    ({ articles }) => ({
      loading: articles.loading,
      offers: articles.offers,
    }),
    { loadArticles }
  )(Component);

export const offerPropType = shape({
  id: number.isRequired,
  title: string.isRequired,
  content: string.isRequired,
  type: shape({ name: string.isRequired }).isRequired,
});
