import convertPriceToSourceCurrencyValue from "helpers/convertPriceToSourceCurrencyValue";

const getTerms = v => {
  switch (v) {
    case 1:
      return "allocation";
    case 2:
      return "contract";
    default:
      return "";
  }
};

const mapAccommodationFromProposalData = (accommodation = [], dateFromOverride, isSourceCurrency = false) => {
  return accommodation.map(item => {
    let mappedAccommodation = {
      ...item,
      price_net_gbp: item.price_net,
      price_vat_gbp: item.price_vat,
      price_vat: convertPriceToSourceCurrencyValue(isSourceCurrency, item, "price_vat"),
      price_per_day_net: convertPriceToSourceCurrencyValue(isSourceCurrency, item, "price_per_day_net"),
      price_reduced_from_net: convertPriceToSourceCurrencyValue(isSourceCurrency, item, "price_reduced_from_net"),
      price_reduced_from: Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, "price_reduced_from_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, "price_reduced_from_vat")),
      cost: Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, "price_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, "price_vat")),
      price_net: convertPriceToSourceCurrencyValue(isSourceCurrency, item, "price_net"),
      breakfast_cost: Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, "breakfast_price_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item, "breakfast_price_vat")),
      breakfast_price_net: convertPriceToSourceCurrencyValue(isSourceCurrency, item, "breakfast_price_net"),
      breakfast_price_vat: convertPriceToSourceCurrencyValue(isSourceCurrency, item, "breakfast_price_vat"),
      breakfast_price_reduced_from_net: convertPriceToSourceCurrencyValue(isSourceCurrency, item, "breakfast_price_reduced_from_net"),
      breakfast_price_net_gbp: item.breakfast_price_net,
      breakfast_price_vat_gbp: item.breakfast_price_vat
    };
    if (item.term_type_id) {
      mappedAccommodation["terms"] = getTerms(item.term_type_id);
    }

    if (item.cancellation_policy) {
      mappedAccommodation["cancellation_policy"] = item.cancellation_policy;
    }
    return mappedAccommodation;
  });
};

export default mapAccommodationFromProposalData;
