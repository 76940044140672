import React from "react";

const CompanyBranchView = ({ companyBranch }) => {
  return (
    <div className={"row"}>
      <div className={`col col-w-full mb-0 md:col-w-1/2`}>
        <h3 className="mb-4 uppercase">{companyBranch.name} has been deleted</h3>
        <ul className="list-reset mb-3">
          {companyBranch?.invoice_email_address && <li className="mb-1">{companyBranch.invoice_email_address}</li>}
          {companyBranch?.address?.address1 && <li className="mb-1">{companyBranch.address.address1}</li>}
          {companyBranch?.address?.address2 && <li className="mb-1">{companyBranch.address.address2}</li>}
          {companyBranch?.address?.address3 && <li className="mb-1">{companyBranch.address.address1}</li>}
          {companyBranch?.address?.city && <li className="mb-1">{companyBranch.address.city}</li>}
          {companyBranch?.address?.county && <li className="mb-1">{companyBranch.address.county}</li>}
          {companyBranch?.address?.postcode && <li className="mb-1">{companyBranch.address.postcode}</li>}
          {companyBranch?.address?.country?.name && <li className="mb-1">{companyBranch.address.country.name}</li>}
        </ul>
      </div>
    </div>
  );
};

export default CompanyBranchView;
