import axios from 'axios';
import queryString from 'query-string';
import throttle from 'lodash/throttle';

const initState = {
  loaded: false,
  loading: false,
  loadingArticles: false,
  loadedArticles: true,
  loadingOffers: false,
  loadedOffers: true,
  articles: [],
  offers: [],
};

const articlesUrl = '/cms/articles';
const offersUrl = '/cms/offers';
const urls = [articlesUrl,offersUrl];


const updateArray = (oldDataArray, newDataArray, matchAttr, type = false) => {


  let filteredOldData = oldDataArray.filter(oldItem => !newDataArray.some(newItem => newItem[matchAttr] === oldItem[matchAttr]));
  if(type && newDataArray.length === 0) {
    filteredOldData = oldDataArray.filter(oldItem => {
      return oldItem.type.name !== type
    })
  }

  return filteredOldData.concat(newDataArray);
}

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOADING_ARTICLES':
      return {
        ...state,
        loading: true,
        loadingArticles: true,
        loadingOffers: true,
      };

    case 'LOADED_ARTICLES':

      return {
        ...state,
        loading: false,
        loadingArticles: false,
        loadingOffers: false,
        loaded: true,
        loadedArticles: true,
        loadedOffers: true,
        articles: updateArray(state.articles,action.payload.articles,'slug'),
        offers: updateArray(state.offers,action.payload.offers,'id'),
      };

    case 'ERROR_LOADING_ARTICLES':
      return {
        ...state,
        loading: false,
        loadingArticles: false,
        loadingOffers: false,
        loaded: false,
        loadedArticles: false,
        loadedOffers: false,
      };

    case 'LOADING_ARTICLES_ONLY':
      return {
        ...state,
        loadingArticles: true,
      };

    case 'LOADED_ARTICLES_ONLY':
      return {
        ...state,
        loadingArticles: false,
        loadedArticles: true,
        articles: updateArray(state.articles,action.payload.articles,'id'),
      };

    case 'ERROR_LOADING_ARTICLES_ONLY':
      return {
        ...state,
        loadingArticles: false,
        loadedArticles: false,
      };

    case 'LOADING_OFFERS_ONLY':
      return {
        ...state,
        loadingOffers: true,
      };

    case 'LOADED_OFFERS_ONLY':
      return {
        ...state,
        loadingOffers: false,
        loadedOffers: true,
        offers: updateArray(state.offers,action.payload.offers,'id', action.payload.type),
      };

    case 'ERROR_LOADING_OFFERS_ONLY':
      return {
        ...state,
        loadingOffers: false,
        loadedOffers: false,
      };
    default:
      return state;
  }
};

const loadArticlesThrottled = throttle((dispatch, queryParams = {}) => {
  dispatch(loadingArticles());

  try {
    let querystring = queryString.stringify(queryParams);
    querystring = querystring.length > 0 ? '?' + querystring : '';
    Promise.all(urls.map(url => axios.get(window.API + url + querystring))).then(
        ([articles, offers]) => {
          dispatch(
              loadedArticles({
                articles: articles ? articles.data : [],
                offers: offers ? offers.data : [],
                loadTime: Date.now(),
              }),
          );
        },
    );
  } catch (err) {
    dispatch(errorLoadingArticles());
  }
}, 30000);



const loadArticlesOnlyUnthrottled = (dispatch, queryParams = {}) => {
  dispatch(loadingArticlesOnly());

  try {

    let querystring = queryString.stringify(queryParams);
    querystring = querystring.length > 0 ? '?' + querystring : '';
    Promise.all([axios.get(window.API + articlesUrl + querystring)]).then(
        ([articles, offers]) => {
          dispatch(
              loadedArticlesOnly({
                articles:articles.data,
                loadTime: Date.now(),
              }),
          );
        },
    );
  } catch (err) {
    dispatch(errorLoadingArticlesOnly());
  }
};


const loadOffersOnlyUnthrottled = (dispatch, queryParams = {}) => {
  dispatch(loadingOffersOnly());

  try {
    let querystring = queryString.stringify(queryParams);
    querystring = querystring.length > 0 ? '?' + querystring : '';
    Promise.all([axios.get(window.API + offersUrl + querystring)]).then(
        ([offers]) => {

          dispatch(
              loadedOffersOnly({
                offers: offers.data,
                type: queryParams.type,
                loadTime: Date.now(),
              }),
          );
        },
    );
  } catch (err) {
    dispatch(errorLoadingOffersOnly());
  }
};

export const loadArticles = ({ force, queryParams = {} } = {}) => dispatch =>
    loadArticlesThrottled(dispatch, queryParams);


export const loadingArticles = () => ({
  type: 'LOADING_ARTICLES',
  payload: {},
});

export const loadedArticles = ({ articles, offers }) => ({
  type: 'LOADED_ARTICLES',
  payload: { articles, offers },
});

export const errorLoadingArticles = () => ({
  type: 'ERROR_LOADING_ARTICLES',
});




export const loadArticlesOnly = ({ force,  queryParams = {} } = {}) => dispatch =>
    loadArticlesOnlyUnthrottled(dispatch, queryParams);

export const loadingArticlesOnly = () => ({
  type: 'LOADING_ARTICLES_ONLY',
  payload: {},
});

export const loadedArticlesOnly = ({ articles }) => ({
  type: 'LOADED_ARTICLES_ONLY',
  payload: { articles },
});

export const errorLoadingArticlesOnly = () => ({
  type: 'ERROR_LOADING_ARTICLES_ONLY',
});



export const loadOffersOnly = ({ force, queryParams = {} } = {}) => dispatch =>
    loadOffersOnlyUnthrottled(dispatch, queryParams);

export const loadingOffersOnly = () => ({
  type: 'LOADING_OFFERS_ONLY',
  payload: {},
});

export const loadedOffersOnly = ({ offers, type }) => ({
  type: 'LOADED_OFFERS_ONLY',
  payload: { offers, type },
});

export const errorLoadingOffersOnly = () => ({
  type: 'ERROR_LOADING_OFFERS_ONLY',
});
