import React, { useState, useEffect } from "react";
import { getAuthedSiteVisitReports } from "services/siteVisitService";
import Loading from "Components/Loading";
import { token } from "modules/auth";
import { connect } from "react-redux";
import { ButtonBrand } from "Components/Button";
import {getSiteVisitReportPage} from "../../../services/siteVisitService/getSiteVisitReports";
import LaravelPaginatorWrapper from "../../../Components/Pagination/LaravelPaginatorWrapper";

const MySiteVisits = props => {
  const [siteVisits, setSiteVisits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);

    getAuthedSiteVisitReports(props.token)
      .then(response => {
        setSiteVisits(response.data);
      })
      .catch(e => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.token]);

  const siteVisitStatusColour = siteVisit => {
    switch (siteVisit.status) {
      case "Published":
        return "text-success";

      case "Awaiting approval":
        return "text-orange";

      case "Rejected":
        return "text-red";

      default:
        return "text-orange";
    }
  };

  const getPage = ({path, page}) => {
    getSiteVisitReportPage(path, page, props.token)
        .then(response => {
          setSiteVisits(response.data)
        })
        .catch(e => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
  };


  const renderItems = ({items}) => (

      items.map((report, index) =>
          <div className="bg-white mb-3 p-5" key={report.id}>
            <div className="flex justify-between items-center">
              <div>
                <h3>{report.visited.display_name}</h3>
                <small className="block py-2">
                  Created: {report.created_at}
                </small>
                <span className={siteVisitStatusColour(report)}>
                    {report.status}
                  </span>
              </div>

              {Number(report.admin_approved) === Number(1) && (
                  <ButtonBrand
                      Element="a"
                      href={`/site-visits/${report.id}`}
                  >
                    View
                  </ButtonBrand>
              )}
            </div>
          </div>
      )
  );

  return (
    <div className="container">
      <h1 className="my-5">My Site Visit Reports</h1>
      {loading && <Loading />}
      {error && (
        <div className="bg-white p-5">
          <h4>
            Oops, something went wrong fetching the site visits. Please try
            again.
          </h4>
        </div>
      )}

      {siteVisits  &&
        <LaravelPaginatorWrapper {...siteVisits} render={renderItems} getPage={getPage} />
      }

      {!loading && siteVisits.length <= 0 && (
        <div className="bg-white p-5">
          <h3>You have not yet submitted any site visit reports.</h3>
        </div>
      )}
    </div>
  );
};

export default connect(state => ({
  token: token(state),
}))(MySiteVisits);
