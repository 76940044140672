import axios from 'axios';
import handleErrors from './utils';
import {
    CORPORATE_ACCOUNT_DOCUMENT_REQUEST,
    CORPORATE_ACCOUNT_DOCUMENT_RECEIVED_ERROR,
    CORPORATE_ACCOUNT_DOCUMENT_RECEIVED,
    CORPORATE_ACCOUNT_DOCUMENT_UNLOAD,
    CREATE_CORPORATE_ACCOUNT_DOCUMENT,
    CREATE_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
    EDIT_CORPORATE_ACCOUNT_DOCUMENT,
    EDIT_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
    DELETE_CORPORATE_ACCOUNT_DOCUMENT,
    DELETE_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
    CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED,
    CORPORATE_ACCOUNT_DOCUMENT_LIST_REQUEST,
    CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED_ERROR,
    CORPORATE_ACCOUNT_DOCUMENT_LIST_UNLOAD,
    CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED,
    CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_REQUEST,
    CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED_ERROR,
    CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_UNLOAD,
    CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY,
    CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_ERROR
} from '../reducers/types';

import * as ROUTES from '../../Router/corporate_routes';
import {corporateAccountRequest} from "./corporate";
import {reset} from 'redux-form'

export const corporateAccountDocumentsRequest = () => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_LIST_REQUEST,
});
export const corporateAccountDocumentsReceivedRequest = (data) => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED,
    data,
});
export const corporateAccountDocumentsReceivedError = (error) => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED_ERROR,
    error,
});

export const unloadCorporateAccountDocuments = () => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_LIST_UNLOAD
});



export const corporateAccountDocumentCategoriesRequest = () => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_REQUEST,
});
export const corporateAccountDocumentCategoriesReceivedRequest = (data) => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED,
    data,
});
export const corporateAccountDocumentCategoriesReceivedError = (error) => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED_ERROR,
    error,
});

export const unloadCorporateAccountDocumentCategories = () => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_UNLOAD
});



export const corporateAccountDocumentRequest = () => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_REQUEST,
});
export const corporateAccountDocumentReceivedRequest = (data) => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_RECEIVED,
    data,
});
export const corporateAccountDocumentReceivedError = (error) => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_RECEIVED_ERROR,
    error,
});

export const unloadCorporateAccountDocument = () => ({
    type: CORPORATE_ACCOUNT_DOCUMENT_UNLOAD
});


export const createCorporateAccountDocument = (data) => ({
    type: CREATE_CORPORATE_ACCOUNT_DOCUMENT,
    data
});

export const createCorporateAccountDocumentError = (error) => {
    return {
    type: CREATE_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
    error
}};

export const editCorporateAccountDocument = (data) => ({
    type: EDIT_CORPORATE_ACCOUNT_DOCUMENT,
    data
});
export const editCorporateAccountDocumentError = (error) => ({
    type: EDIT_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
    error
});

export const deleteCorporateAccountDocument = (data) => ({
    type: DELETE_CORPORATE_ACCOUNT_DOCUMENT,
    data
});
export const deleteCorporateAccountDocumentError = (error) => ({
    type: DELETE_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
    error
});

export const corporateAccountReceivedRequest = (data) => ({
    type: DELETE_CORPORATE_ACCOUNT_DOCUMENT,
    data,
});
export const corporateAccountReceivedError = (error) => ({
    type: DELETE_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
    error,
});

export const createCorporateAccountDocumentCategory = (data) => ({
    type: CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY,
    data,
});

export const createCorporateAccountDocumentCategoryError = (error) => ({
    type: CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_ERROR,
    error
});

export const fetchCorporateAccountDocuments = (slug, id, token) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentsRequest());
        return axios.get(window.API + ROUTES.API.CORPORATE.DOCUMENTS.LIST.replace(':slug', slug).replace(':id', id), {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(corporateAccountDocumentsReceivedRequest(response.data)))
            .catch(error => {
                dispatch(corporateAccountDocumentsReceivedError(error));
            });
    };
};

export const fetchCorporateAccountDocumentsByCategory = (slug, id, token) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentsRequest());
        return axios.get(window.API + ROUTES.API.CORPORATE.DOCUMENTS.CATEGORY.BY.replace(':slug', slug).replace(':category', id), {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(corporateAccountDocumentsReceivedRequest(response.data)))
            .catch(error => {
                dispatch(corporateAccountDocumentsReceivedError(error));
            });
    };
};

export const fetchCorporateAccountDocumentCategories = (slug, token) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentCategoriesRequest());
        return axios.get(window.API + ROUTES.API.CORPORATE.DOCUMENTS.CATEGORY.ALL.replace(':slug', slug), {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(corporateAccountDocumentCategoriesReceivedRequest(response.data)))
            .catch(error => {
                dispatch(corporateAccountDocumentCategoriesReceivedError(error));
            });
    };
};



export const fetchCorporateAccountDocument = (slug, id, token) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentRequest());
        return axios.get(window.API + ROUTES.API.CORPORATE.DOCUMENTS.SINGLE.replace(':slug', slug).replace(':id', id), {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(corporateAccountDocumentReceivedRequest(response.data)))
            .catch(error => {
                dispatch(corporateAccountDocumentReceivedError(error));
            });
    };
};
export const createDocument = (slug, token, formValues) => {
    return (dispatch) => {
        dispatch(corporateAccountRequest());
        return axios.post(window.API + ROUTES.API.CORPORATE.DOCUMENTS.CREATE.replace(':slug', slug), formValues, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(createCorporateAccountDocument(response)))
            .catch(error => {
                dispatch(createCorporateAccountDocumentError(error));

            });
    };
};

export const resetDocumentForm = () => {
    return (dispatch) => {
        dispatch(reset('corporateAccountDocumentForm'))
    }
};

export const createDocumentCategory = (slug, token, formValues) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentRequest());
        return axios.post(window.API + ROUTES.API.CORPORATE.DOCUMENTS.CATEGORY.CREATE.replace(':slug', slug), formValues, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => {
            dispatch(createCorporateAccountDocumentCategory(response))
            return Promise.resolve();
        })
            .catch(error => {
                dispatch(createCorporateAccountDocumentCategoryError(error));
                handleErrors(error);
                return Promise.reject(error);
            });
    };
};

export const deleteDocumentCategory = (slug,id, token, formValues) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentRequest());
        return axios.delete(window.API + ROUTES.API.CORPORATE.DOCUMENTS.CATEGORY.DELETE.replace(':slug', slug).replace(':category', id), {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => {
            dispatch(createCorporateAccountDocumentCategory(response))
            return Promise.resolve();
        })
            .catch(error => {
                dispatch(createCorporateAccountDocumentCategoryError(error));
                handleErrors(error);
                return Promise.reject(error);
            });
    };
};

export const editDocument = (slug, id,  token, formValues,) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentRequest());
        return axios.post(window.API + ROUTES.API.CORPORATE.DOCUMENTS.EDIT.replace(':slug', slug).replace(':id', id), formValues,  {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(editCorporateAccountDocument(response)))
            .catch(error => {
                dispatch(editCorporateAccountDocumentError(error));
                handleErrors(error);
            });
    };
};

export const deleteDocument = (slug, id, token) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentRequest());
        return axios.delete(window.API + ROUTES.API.CORPORATE.DOCUMENTS.DELETE.replace(':slug', slug).replace(':id', id), {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => {
            dispatch(deleteCorporateAccountDocument(response));
        })
            .catch(error => dispatch(deleteCorporateAccountDocumentError(error)));
    };
};

export const deleteDocumentFile = (slug, id, token) => {
    return (dispatch) => {
        dispatch(corporateAccountDocumentRequest());
        return axios.delete(window.API + ROUTES.API.CORPORATE.DOCUMENTS.DELETEFILE.replace(':slug', slug).replace(':id', id), {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => {
            dispatch(deleteCorporateAccountDocument(response));
        })
            .catch(error => dispatch(deleteCorporateAccountDocumentError(error)));
    };
};
