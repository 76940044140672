import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import RfpProposals from "../../Components/RfpProposals/RfpProposals";
import Loading from "../../Components/Loading";
import BriefSummary from "../../Components/RfpBriefSection/BriefSummary";
import { ButtonPrimary } from "../../Components/Button";
import useService from "../../hooks/useService";
import { getEnquiryByShareId } from "../../services/rfpService/enquiries";
import { user } from "../../modules/auth";
import ExportRfpProposalButtons from "../../Components/RfpProposals/ExportRfpProposalButtons";

const mapStateToProps = state => {
  return {
    user: user(state),
  };
};

const shareEmailBody = (proposal, sharedByEmail, user) => {
  const sharedBy = sharedByEmail;
  const url = window.location.origin + window.location.pathname;
  const from = user && user.first_name ? `${user.first_name} ${user.surname}` : "";

  const content = `Please provide feedback to ${sharedBy} regarding the proposal for ${proposal.brief.event_name} (${proposal.ref_id}).

Available to review at:
${url}
   
${from}`;
  return encodeURIComponent(content);
};

const ProposalShare = ({ user }) => {
  let { shareId } = useParams();
  let params = (new URL(window.location)).searchParams;
  let sharedByEmail = params.get("sharer");
  const { loading, data, error } = useService({
    service: getEnquiryByShareId,
    trigger: shareId,
    params: shareId,
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className={"container"}>
        <div className={"bg-white p-5 mt-10"}>
          <h2 className={"mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center uppercase"}>
            You don't have permissions to view this proposal
          </h2>
        </div>
      </div>
    );
  }
  if (Object.entries(data).length === 0 && data.constructor === Object) {
    return (
      <div>Proposal not found</div>
    );
  }
  return (
    <div className={"row"}>
      <div className={`col col-w-full px-2 mb-0 bg-white`}>
        <div className="px-2 container pt-4">
          <h1 className="mb-4 uppercase">{data.brief.event_name}</h1>
          <p className="my-4">Ref: {data.ref_id}</p>
          <p>Please review the suggested venues below for this via the button below</p>
          <div className="flex my-5">
            <a
              className="flex items-end text-black uppercase font-heading"
              href={`mailto:${sharedByEmail}?subject=Feedback on proposal ${new Date().toLocaleDateString("en-GB")}&body=${shareEmailBody(data, sharedByEmail, user)}`}
            >
              <ButtonPrimary
                classes={`px-6 py-2 flex items-center`}
                style={{ height: "auto" }}
              >
                Share feedback on this proposal{" "}
                <i className="hidden sm:inline ml-5 fal fa-chevron-circle-right text-3xl" />
              </ButtonPrimary>

            </a>
            <ExportRfpProposalButtons  data={data}/>
          </div>
        </div>
        <div className="py-5 container">
          <BriefSummary {...data.brief} briefPreferences={data.brief_preferences} />
          <RfpProposals data={data} publicView={true} />
        </div>
      </div>
    </div>

  );
};

export default connect(mapStateToProps, null)(ProposalShare);
