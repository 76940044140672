import Loading from "../Loading";
import PriceBandingInformation
    from "../../pages/admin/EditVenue/ConferencePricing/PriceBanding/PriceBandingInformation";
import React from "react";
import ReactTooltip from "react-tooltip";
import { FaPlus } from "react-icons/fa";

let bgColor = "bg-dark-green";

let PriceBandingBannerInner = ({packageBanding, priceBandings, venuePriceBandings, type, title}) => {

    return (
        <div
            className={`rounded-sm ${bgColor} py-1 px-2 uppercase text-white text-sm font-normal mt-2 `}
            style={{width: 'fit-content'}}
        >
          <span
              data-tip
              data-for={type}
              data-event="click"
              className="flex items-center pointer"
          >
            {title ? title : (packageBanding ? packageBanding.band_title : 'Package Banding')} <FaPlus className="ml-2" />
          </span>
            <ReactTooltip
                id={type}
                effect="solid"
                type="light"
                clickable={true}
                className="tooltip-shadow price-banding-tooltip-opacity"
                globalEventOff="click"
            >
                {!priceBandings && !venuePriceBandings && <Loading />}
                <div className="flex text-transform-none" style={{zIndex : '999999'}}>
                    <PriceBandingInformation priceBandings={priceBandings ? priceBandings[type !== 'twenty_four_hr' ? type.toUpperCase() : '24hr'] : []} isInternational={venuePriceBandings.is_international}/>
                </div>
            </ReactTooltip>
        </div>
    )
}

export default PriceBandingBannerInner