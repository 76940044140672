import React from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { industryTypes } from "../../../../modules/analytics";
import { PieChartWithButton } from "./PieChart";

const IndustryPieChart = ({ data, ...props }) => (
  <PieChartWithButton
    {...props}
    data={data.map(item => ({ ...item, name: item.label }))}
    buttonText="View Audience"
    target="Audience"
  />
);

export default connect((state, props) => {
  const v = getFormValues("analytics")(state);

  return {
    data: industryTypes(
      v.venue_id,
      v.start_date,
      v.end_date,
      undefined,
      props.typeFilter
    )(state),
  };
})(IndustryPieChart);
