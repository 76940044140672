// useMeasure hook taken from
// https://codesandbox.io/embed/lp80n9z7v9
// https://codesandbox.io/embed/26mjowzpr

import { useLayoutEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

/**
 * Example
 * const [bind, { width }] = useMeasure();
 *
 * <div {...bind}>
 *  This div is {width} pixels wide.
 * </div>
 */

const defaultInitBounds = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
};

export default function useMeasure(initBounds = defaultInitBounds) {
  const elementRef = useRef();
  const [bounds, setBounds] = useState(initBounds);

  useLayoutEffect(() => {
    const element = elementRef.current;

    const resizeObserver = new ResizeObserver(([entry]) =>
      setBounds(entry.contentRect)
    );

    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element);
  }, []);

  return [{ ref: elementRef }, bounds];
}
