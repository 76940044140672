import axios from 'axios';
import * as ROUTES from '../../Router/corporate_routes';
import {
  CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED,
  CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED,
  CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_DEPARTMENT_REQUEST,
  CORPORATE_ACCOUNT_DEPARTMENT_UNLOAD,
  CORPORATE_ACCOUNT_DEPARTMENTS_LIST_ERROR,
  CORPORATE_ACCOUNT_DEPARTMENTS_LIST_RECEIVED,
  CORPORATE_ACCOUNT_DEPARTMENTS_LIST_REQUEST,
  CORPORATE_ACCOUNT_DEPARTMENTS_UNLOAD,
  CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED_ERROR,
  CORPORATE_ACCOUNT_DEPARTMENT_CREATED,
  CORPORATE_ACCOUNT_DEPARTMENT_CREATED_ERROR
} from '../reducers/types';
import handleErrors from "./utils";

export const corporateDepartmentsListRequest = () => ({
  type: CORPORATE_ACCOUNT_DEPARTMENTS_LIST_REQUEST
});

export const corporateDepartmentsListReceived = data => ({
  type: CORPORATE_ACCOUNT_DEPARTMENTS_LIST_RECEIVED,
  data
});

export const corporateDepartmentsListError = error => ({
  type: CORPORATE_ACCOUNT_DEPARTMENTS_LIST_ERROR,
  error
});

export const corporateDepartmentsUnload = () => ({
  type: CORPORATE_ACCOUNT_DEPARTMENTS_UNLOAD
});

export const corporateDepartmentRequest = () => ({
  type: CORPORATE_ACCOUNT_DEPARTMENT_REQUEST
});

export const corporateDepartmentReceived = data => ({
  type: CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED,
  data
});

export const corporateDepartmentReceivedError = error => ({
  type: CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED_ERROR,
  error
});

export const corporateDepartmentUnload = () => ({
  type: CORPORATE_ACCOUNT_DEPARTMENT_UNLOAD
});

export const corporateDepartmentDeactivated = data => ({
  type: CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED,
  data
});

export const corporateDepartmentDeactivatedError = error => ({
  type: CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED_ERROR,
  error
});

export const corporateDepartmentCreated = data => ({
  type: CORPORATE_ACCOUNT_DEPARTMENT_CREATED,
  data
});

export const corporateDepartmentCreatedError = error => ({
  type: CORPORATE_ACCOUNT_DEPARTMENT_CREATED_ERROR,
  error
});

export const fetchCorporateDepartments = (slug, token) => {
  return (dispatch) => {
    dispatch(corporateDepartmentsListRequest())
    return axios.get(window.API + ROUTES.API.CORPORATE.DEPARTMENTS.LIST.replace(':slug', slug), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(corporateDepartmentsListReceived(response.data)))
      .catch(error => dispatch(corporateDepartmentsListError(error)))
  }
}

export const fetchCorporateDepartment = (slug, id, token) => {
  return (dispatch) => {
    dispatch(corporateDepartmentRequest())
    return axios.get(window.API + ROUTES.API.CORPORATE.DEPARTMENTS.SINGLE.replace(':slug', slug).replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(corporateDepartmentReceived(response.data)))
      .catch(error => dispatch(corporateDepartmentReceivedError(error)))
  }
}

export const createCorporateDepartment = (slug, formValues, token) => {
  return (dispatch) => {
    dispatch(corporateDepartmentRequest())
    return axios.post(window.API + ROUTES.API.CORPORATE.DEPARTMENTS.CREATE.replace(':slug', slug), formValues, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(corporateDepartmentCreated(response.data)))
      .catch(error => {
        dispatch(corporateDepartmentCreatedError(error))
        handleErrors(error)
      })
  }
}

export const deactivateDepartment = (slug, id, token) => {
  const formData = new FormData();
  formData.set('_method', "PATCH");
  formData.set('is_active', "false");
  return (dispatch) => {
    dispatch(corporateDepartmentRequest())
    return axios.post(window.API + ROUTES.API.CORPORATE.DEPARTMENTS.SINGLE.replace(':slug', slug).replace(':id', id), formData, {
      headers: {Authorization: `Bearer ${token}`},
      "Content-Type": "application/json"
    }).then(response => dispatch(corporateDepartmentDeactivated(response.data)))
      .catch(error => dispatch(corporateDepartmentDeactivatedError(error)))
  }
}
