import React from 'react';
import TrapezeHeader from 'Components/TrapezeHeader';
import {FaQuestion} from 'react-icons/fa';
import {ButtonBrand} from 'Components/Button';
import {connect, useSelector} from 'react-redux';
import {getWidth} from 'redux-window';
import {generalInteraction} from "../../modules/analytics";

const BecomeALicensedVenue = ({
                                headerText = 'Want to promote your venue?',
                                mainMessage = <span>Become a licenced venue</span>,
                                linkToURL = '/upgrade-venue',
                                buttonText = 'Contact us to upgrade',
                                generalInteraction
                              }) => {
  const mobile = useSelector(state => getWidth(state) < 768);
  return (
    <div className="bg-white mt-6 flex flex-wrap">
      <div className="w-full md:w-2/5">
        <TrapezeHeader
          bgColor="grey-darker"
          title={headerText}
          bigText={!mobile}
          icon={<FaQuestion className="text-white mr-2"/>}
        />
      </div>
      <div className="w-full md:w-3/5 flex items-center justify-between pl-4 md:pl-0 pr-4 mt-2 md:mt-0">
        {mainMessage}
        <ButtonBrand
          outlined
          Element="a"
          href={linkToURL}
          classes="border-3 rounded-sm w-full md:w-auto"
          onClick={() => {
            generalInteraction({type: "general_upgrade_click"})
          }}
        >
          {buttonText}
        </ButtonBrand>
      </div>
    </div>
  );
};

export default connect(null, {generalInteraction})(BecomeALicensedVenue);
