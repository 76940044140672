import React from "react";
import { Cell, Pie, PieChart as PieChartComponent, Tooltip } from "recharts";
import { ButtonPrimary } from "../../../../Components/Button";
import { Column, Row } from "../../../../Components/grid";
import { ResizeContainer } from "./ResizeContainer";

export const PieChart = ({ data, height }) => (
  <Row noBreak>
    <Column w="3/5" className="xl:w-full xxl:w-3/5">
      <ResizeContainer height={height || 300}>
        {resizeProps => (
          <PieChartComponent {...resizeProps}>
            <Tooltip />
            <Pie data={data} dataKey="value" labelLine={false} nameKey="name">
              {data.map(entry => (
                <Cell key={entry.name} fill={entry.colour} label={entry.name} />
              ))}
            </Pie>
          </PieChartComponent>
        )}
      </ResizeContainer>
    </Column>
    <Column w="2/5" className="pt-12 xl:pt-0 xxl:pt-12  xl:w-full xxl:w-2/5">
      <table className="-ml-5">
        <tbody>
          {data.map(entry => (
            <tr
              key={entry.name}
              className="mb-1 font-normal text-right"
              style={{
                color: entry.colour,
              }}
            >
              <td>{entry.label || entry.name}</td>
              <td className="text-left font-bold pl-1">{entry.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Column>
  </Row>
);

export const PieChartButton = ({ goTo, target, buttonText }) => (
  <div className="text-right">
    {goTo && (
      <ButtonPrimary onClick={() => goTo(target)}>{buttonText}</ButtonPrimary>
    )}
  </div>
);

export const PieChartWithButton = ({
  data,
  width,
  height,
  goTo,
  target,
  buttonText,
}) => (
  <div>
    <PieChart data={data} width={width} height={height} />
    <PieChartButton goTo={goTo} target={target} buttonText={buttonText} />
  </div>
);
