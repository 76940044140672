import React from "react";
import {Field, FieldArray, getFormValues, clearFields} from "redux-form";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Input, Radio, Select, Checkbox} from "Components/Form";
import {optional, int, min, required} from "modules/validation";
import {ButtonBrand} from "Components/Button";
import SearchStepFooter from "./SearchStepFooter";
import RoomStylesField from "./RoomStylesField";
import {fetchPriceBandingData} from "../../services/priceBandingService/fetchPriceBandings";
import {token} from "../../modules/auth";

const v = {
  int: optional(int),
  required: required(),
  requiredInt: int,
};

class ExtraDetails extends React.Component {

  state = {
    bands: false,
  };

 componentDidUpdate(prevProps, prevState, snapshot) {
   if(this.state.bands === false && this.props.values.package_type )
   {
     fetchPriceBandingData(this.props.token).then((response) => {
       this.setState({
         bands: response[this.props.values.package_type]
       })
     })
   }
 }

  searchOnBlur = () => {
    this.props.search();
  };

  searchOnChange = (e, v, pv, name) => {
    this.props.search({
      [name]: v,
    });
  };

  packageOnChange = (event,v, pv, name) => {
     if(!!event.target.value) {
         fetchPriceBandingData(this.props.token).then((response) => {
             this.setState({
                 bands: response[event.target.value]
             })
         })
     }else{
         this.setState({
             bands: false
         })
         this.searchOnChange(event,v,pv,name)
     }
  }

  renderMultipleMeetingRooms = ({fields, meta: {error, submitFailed}}) => {

    return (
      <React.Fragment>
        <div className="flex flex-wrap w-full">
          {fields.map((member, index) => {
            return (
                <div key={index} className="flex col-w-full">
                  <div className="col col-w-2/5">
                    <RoomStylesField
                        searchOnChange={this.searchOnChange}
                        wrapperClassName="mb-2"
                        labelClassName="mb-1 block font-normal font-heading text-sm uppercase"
                        name={`${member}.type`}
                        label="Choose a layout"
                        placeholder="Select"
                    />

                  </div>
                  <div className="col col-w-2/5">
                    <Field
                        wrapperClassName="mb-2"
                        additionalInputClass="max-w-xxs"
                        labelClassName="mb-1 block font-normal font-heading text-sm uppercase"
                        name={`${member}.capacity`}
                        component={Input}
                        label="Capacity"
                        onBlur={this.searchOnBlur}
                    />
                  </div>
                  <div className="col col-w-1/5 flex items-center">
                <span
                    className="text-right text-warning pointer"
                    onClick={() => {
                      fields.remove(index);
                    }}
                >
                  Delete
                  <i className="fas fa-trash-alt ml-3"/>
                </span>
                  </div>
                </div>
            )
          })}
          <div className="col col-w-full">
            <ButtonBrand
              classes="mb-5"
              onClick={() => fields.push({})}
            >
              <i className="fal fa-plus"/>
            </ButtonBrand>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.props.sectionHeader("Delegates", "delegates")}
        <div className={this.props.open.delegates ? "" : "hidden"}>
          <div className="md:flex md:flex-wrap p-3">
            <div className="flex w-full">
              <Field
                name="capacity"
                label="No. of delegates"
                size="sm"
                component={Input}
                validate={v.int}
                onBlur={this.searchOnBlur}
              />
              {this.props.hasCapacity && (
                <React.Fragment>
                 <RoomStylesField
                     searchOnChange={this.searchOnChange}
                     name={"layout"}
                     label={"Layout"}
                     wrapperClassName={'mb-5 w-32 mr-5'}

                 />
                  <Field
                    name="back_projection"
                    label="Do you require back projection?"
                    component={Radio}
                    wrapperClassName="mb-5 mr-5"
                    onChange={this.searchOnChange}
                  />
                </React.Fragment>
              )}
            </div>
            <div className="w-full">
              <Field
                name="total_bedrooms"
                label="Are bedrooms needed? If yes, tell us how many."
                size="lg"
                component={Input}
                validate={v.int}
                onBlur={this.searchOnBlur}
              />
            </div>
            <Field
              name="exhibition"
              label="Do you require exhibition space?"
              component={Radio}
              wrapperClassName="mb-5 mr-5"
            />
            {this.props.isExhibition && (
              <Field
                name="floor_space"
                label={
                  <span>
                    Floor space <sup className="lowercase">m2</sup>
                  </span>
                }
                component={Input}
                size="sm"
                validate={v.int}
                onBlur={this.searchOnBlur}
              />
            )}
          </div>
          <SearchStepFooter
            onNext={() => this.props.stateSetter.tab("type", true)}
            scrollTo="type"
            scrollBack="location"
          />
        </div>
        {this.props.sectionHeader("Type of venue", "type")}
        <div className={this.props.open.type ? "" : "hidden"}>
          <div className="p-3">
            <Field
              name="types"
              label="Select all that apply"
              component={Checkbox}
              options={this.props.categories}
              onChange={this.searchOnChange}
              withSelectAll
              selectAllLabel="All Venue Types"
            />
            <div className={'flex'}>
              <Field
                  name="package_type"
                  label="Pricing Type"
                  wrapperClassName="w-48 pr-5"
                  component={Select}
                  placeholder="Please choose"
                  options={[{ value: "24hr", text: "24 Hour Rate" }, { value: "DDR", text: "Day Rate" }, { value: "BB", text: "Bed & Breakfast Rate" }]}
                  onChange={this.packageOnChange}
              />
              {
                this.state.bands ?
                    <Field
                        name="band"
                        label="Price"
                        wrapperClassName="w-64 pr-5"
                        component={Select}
                        placeholder="Any"
                        options={this.state.bands ? this.state.bands.map((band) => {
                          return {
                            text: `${band.band_title}: ${band.min_price === '0.00' ? 'Under' :  '£' + band.min_price} - ${!band.max_price ? 'plus' :  '£' +band.max_price}`,
                            value: band.id
                          }
                        }) : []}
                        onChange={this.searchOnChange}
                    />
                    : null
              }
              {/*<Field*/}
              {/*    name="star_rating"*/}
              {/*    label="Star rating"*/}
              {/*    wrapperClassName="w-48"*/}
              {/*    component={Select}*/}
              {/*    validate={v.int}*/}
              {/*    onChange={this.searchOnChange}*/}
              {/*    options={["3", "4", {value: "5", text: "5+"}]}*/}
              {/*/>*/}
            </div>
          </div>
          <SearchStepFooter
            onNext={() => this.props.stateSetter.tab("rooms", true)}
            scrollTo="rooms"
            scrollBack="delegates"
          />
        </div>
        {this.props.sectionHeader("Meeting rooms", "rooms")}
        <div className={this.props.open.rooms ? "" : "hidden"}>
          <div className="p-3 flex flex-wrap">
            <div className="col-w-full md:col-w-1/4">
              <Field
                name="total_meeting_rooms"
                label="Total meeting rooms"
                size="md"
                component={Input}
                validate={v.int}
                onBlur={this.searchOnBlur}
              />
            </div>
            {this.props.needsMultipleMeetingRooms && (
              <div className="col-w-full md:col-w-3/4 flex flex-wrap">
                <FieldArray
                  name="layouts"
                  component={this.renderMultipleMeetingRooms}
                />
              </div>
            )}
          </div>
          {/* <Field
            name="notes"
            label="Notes"
            rows={4}
            size="lg"
            component={Textarea}
          /> */}
          <SearchStepFooter last/>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const v = getFormValues("advanced-search")(state);

      return {
        isExhibition: v && !!parseInt(v.exhibition, 10),
        hasCapacity: v && typeof int(v.capacity) === "undefined",
        token: token(state),
        values: v,
        needsMultipleMeetingRooms:
          v && typeof min(2)(v.total_meeting_rooms) === "undefined",
      };
    },
    {clearFields}
  )(ExtraDetails)
);
