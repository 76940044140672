import React, { Component } from "react";
import { connect } from "react-redux";

import { loadPosts } from "./loadPosts";
import { userType, isInternal } from "../auth";
import { filterPostsByUserType } from "./utils";

export const createWithEdgeInsights = WrappedComponent =>
  class EdgeInsightsConnector extends Component {
    state = {
      loading: true,
      error: false,
      posts: [],
    };

    _isMounted = false;

    async componentDidMount() {
      this._isMounted = true;
      this.setState({ loading: true });

      try {
        const { posts } = await loadPosts({
          params: {
            per_page: 5,
          },
        });

        if (!this._isMounted) return;

        this.setState({ posts, loading: false });
      } catch (error) {
        if (!this._isMounted) return;

        this.setState({ error: true, loading: false });
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      const { loading, error, posts } = this.state;
      const { userType, ...props } = this.props;

      return (
        <WrappedComponent
          loading={loading}
          error={error}
          posts={filterPostsByUserType({ posts, userType, isInternal })}
          {...props}
        />
      );
    }
  };

export const withEdgeInsights = WrappedComponent =>
  connect(state => ({
    userType: userType(state),
    isInternal: isInternal(state),
  }))(createWithEdgeInsights(WrappedComponent));
