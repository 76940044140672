import React, {useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {token as tokenSelector} from "modules/auth";
import {ButtonPrimary} from "Components/Button";
import Loading from "Components/Loading";


const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.cssText = "display: none";
    return function (isCsv, data, fileName) {
        const blob = new Blob([data], {
            type: isCsv
                ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
                : "application/pdf",
        });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.setAttribute("download", fileName);
        a.click();
        window.URL.revokeObjectURL(url);
    };
})();

const ExportRfpProposalButtons = ({onClose, data, enquiryData, loggedInUserId}) => {
    const [submitting, setSubmitting] = useState("");
    const [isRfpXpress, setRfpXpress] = useState(Boolean(data?.brief?.rfp_xpress_service));
    const id = data.id;
    const token = useSelector(tokenSelector);

    const handleExport = (type, disableCommission = false, submittingType, includeVat) => async () => {
        if (submitting) {
            return;
        }
        setSubmitting(submittingType);
        let url = `${window.API}/rfp/enquiry/${id}/export/${type}`
        let queryParams = new URLSearchParams();
        if (disableCommission) {
            queryParams.set('disable_commission', 1)
        }
        if (includeVat) {
            queryParams.set('includeVat', 1); // if includeVat return gross
        }
        url += "?" + queryParams.toString();
        try {
            const response = await axios.get(
                url,
                {
                    responseType: "blob",
                    headers: {Authorization: `Bearer ${token}`},
                }
            );
            saveData(
                type === "csv",
                response.data,
                `your-enquiry-${id}.${type === "csv" ? "xlsx" : type}`
            );
            setSubmitting("");
        } catch (err) {
            setSubmitting("");
            alert(`Sorry there was a problem fetching your ${type}`);
        }
    };

    return (
        <>
            <>
                <ButtonPrimary
                    classes={`px-6 py-2 mx-2 flex items-center`} style={{height: '46px'}}
                    onClick={handleExport("pdf", true, 'pdfNetWithout', false)}
                >
                    {submitting === "pdfNetWithout" ? <Loading/> : "Export Proposal (Net)"}{" "}
                </ButtonPrimary>
                <ButtonPrimary
                    classes={`px-6 py-2 mx-2 flex items-center`} style={{height: '46px'}}
                    onClick={handleExport("pdf", true, 'pdfGrossWithout', true)}
                >
                    {submitting === "pdfGrossWithout" ? <Loading/> : "Export Proposal (Gross)"}{" "}
                </ButtonPrimary>
            </>
        </>
    );
};

export default ExportRfpProposalButtons;
