export default (numVenues, analytics, snapshot, venues) => {
  const data = {
    ...mergeAnalytics(numVenues, analytics),
    snapshot: {
      ...mergeAnalytics(numVenues, snapshot),
    },
    venueData: {
      twitter_link: venues.map(venue => venue.data.twitter_link).join(""),
      tripadvisor_link: venues
        .map(venue => venue.data.tripadvisor_link)
        .join(""),
      instagram_link: venues.map(venue => venue.data.instagram_link).join(""),
      articles: Array.prototype.concat(
        ...venues.map(venue => venue.data.articles)
      ),
      offers: Array.prototype.concat(...venues.map(venue => venue.data.offers)),
      data_score: average(
        numVenues,
        venues.map(venue => venue.data.data_score)
      ),
    },
  };

  return data;
};

function mergeAnalytics(numVenues, analytics) {
  const data = {};

  data.current_data_score = average(
    numVenues,
    analytics.map(item => item.data.current_data_score)
  );

  data.search = {
    impressions: Array.prototype.concat(
      ...analytics.map(item => item.data.search.impressions)
    ),
    clicks: Array.prototype.concat(
      ...analytics.map(item => item.data.search.clicks)
    ),
  };

  data.venue = {
    impressions: Array.prototype.concat(
      ...analytics.map(item => item.data.venue.impressions)
    ),
    interactions: Array.prototype.concat(
      ...analytics.map(item => item.data.venue.interactions)
    ),
  };

  data.other_venues_viewed = Array.prototype.concat(
    ...analytics.map(item => item.data.other_venues_viewed)
  );

  data.opportunities = {
    records: Array.prototype.concat(
      ...analytics
        .filter(item => item.data.opportunities)
        .map(item => item.data.opportunities.records)
    ),
    meta: mergeMeta(
      numVenues,
      analytics
        .filter(item => item.data.opportunities)
        .map(item => item.data.opportunities.meta)
    ),
  };

  return data;
}

function mergeMeta(total, meta) {
  return {
    venueId: meta.map(item => item.venueId).join(","),
    venuId: meta.map(item => item.venuId).join(","),
    totalRecords: totalCount(meta.map(item => item.totalRecords)),
    enquiries: totalCount(meta.map(item => item.enquiries)),
    proposals: totalCount(meta.map(item => item.proposals)),
    proposalValue: totalCount(meta.map(item => item.proposalValue)),
    sales: totalCount(meta.map(item => item.sales)),
    saleValue: totalCount(meta.map(item => item.saleValue)),
    enquiryVsProposalRate: average(
      total,
      meta.map(item => item.enquiryVsProposalRate)
    ),
    rfpSuccessRate: average(total, meta.map(item => item.rfpSuccessRate)),
    denials: totalCount(meta.map(item => item.denials)),
    avgResponseDays: average(total, meta.map(item => item.avgResponseDays)),
    avgResponseHours: average(total, meta.map(item => item.avgResponseHours)),
    avgResponseMinutes: average(
      total,
      meta.map(item => item.avgResponseMinutes)
    ),
    avgResponseSeconds: average(
      total,
      meta.map(item => item.avgResponseSeconds)
    ),
    newVsRepeat: {
      totalSales: totalCount(meta.map(item => item.newVsRepeat.totalSales)),
      newBizSales: totalCount(meta.map(item => item.newVsRepeat.newBizSales)),
      repeatBizSales: totalCount(
        meta.map(item => item.newVsRepeat.repeatBizSales)
      ),
      newBizRate: average(total, meta.map(item => item.newVsRepeat.newBizRate)),
      repeatBizRate: average(
        total,
        meta.map(item => item.newVsRepeat.repeatBizRate)
      ),
    },
    financial: mergeFinancial(
      Array.prototype.concat(...meta.map(item => item.financial))
    ),
    industryTypes: mergeIndustryTypes(meta.map(item => item.industryTypes)),
  };
}

function mergeFinancial(financialData) {
  const data = [];
  financialData.forEach(item => {
    const existing = data.find(entry => entry.year === item.year);
    if (existing) {
      const index = data.indexOf(entry => entry.year === item.year);
      data[index] = {
        year: item.year,
        saleValue: item.saleValue + existing.saleValue,
      };
    } else {
      data.push(item);
    }
  });
  return data.sort((a, b) => a.year - b.year);
}

function mergeIndustryTypes(types) {
  const all = Array.prototype.concat(...types);
  const data = [];
  types[0].forEach(type => {
    data.push({
      id: type.id,
      text: type.text,
      records: totalCount(
        all.filter(item => item.id === type.id).map(item => item.records)
      ),
    });
  });
  return data;
}

function totalCount(arr) {
  let total = 0;
  arr.forEach(item => {
    if (typeof item === "number" || typeof item === "string") {
      total = total + parseFloat(item);
    }
  });
  return Math.round(total);
}

function average(total, arr) {
  let value = 0;
  let isNull = true;
  arr.forEach(item => {
    if (typeof value === "number") {
      value = value + item;
      isNull = false;
    }
    if (typeof value === "string") {
      value = value + parseFloat(item);
      isNull = false;
    }
  });
  return isNull ? null : Math.round(value / total);
}
