import React, { memo } from "react";
import { connect } from "react-redux";
import { ButtonPrimary } from "./Button";
import { isLoggedIn } from "../modules/auth";
import { useHistory, useLocation } from "react-router-dom";

const blurStyle = {
  filter: "blur(10px)",
  pointerEvents: "none",
  userSelect: "none",
};

function RegisterBlurPanel({
                             children,
                             isLoggedIn,
                             showContent = false,
                             contentText,
                             buttonText,
                           }) {
  const history = useHistory();
  const location = useLocation();

  if (isLoggedIn) {
    return children;
  }
  const handleClick = () => {
    history.push(
      `/login?return=${encodeURIComponent(location.pathname)}&logInToProceed=1`,
    );
  };

  return <div className={`${showContent ? "h-full relative" : ""}`}>
    {showContent && (
      <div className={"flex items-center absolute z-30 justify-center w-full h-full"}>
        <div className={`items-center justify-center text-center inline-block ${contentText ? "bg-white p-12" : ""}`}>
          {contentText && (
            <div className="my-6 text-dark-grey">
              <p className={"text-xl font-normal leading-tight"}>{contentText}</p>
            </div>
          )}
          <ButtonPrimary classes={"mx-auto"} onClick={handleClick}>
            {buttonText || "Login/Register"}
          </ButtonPrimary>
        </div>
      </div>
    )}
    <div style={blurStyle}>
      {children}
    </div>
  </div>;
}

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps)(memo(RegisterBlurPanel));
