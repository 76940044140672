import React from "react";
import { string, oneOfType, object, node } from "prop-types";

class Label extends React.Component {
  static propTypes = {
    text: oneOfType([string, object, node]).isRequired,
    name: string,
    className: string,
  };

  render() {
    const { name, text, className, ...rest } = this.props;
    return (
      <label
        htmlFor={this.props.name}
        className={
          className || "mb-1 block font-normal font-heading text-sm uppercase"
        }
        {...rest}
      >
        {typeof text === "object" && text.top ? (
          <React.Fragment>
            <span>{text.top}</span>
            <span className="font-light font-body block normal-case mt-1">
              {text.bottom}
            </span>
          </React.Fragment>
        ) : (
          this.props.text
        )}
      </label>
    );
  }
}

export default Label;
