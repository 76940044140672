import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { arrayOf, shape, number, string } from "prop-types";
import { isAgency, isInternal } from "../../../modules/auth";
import TrapezeHeader from "Components/TrapezeHeader";
import { FaChevronRight } from "react-icons/fa";
import moment from "moment";

const agencyTypes = ["commission-offers", "agency-rates", "fam-trips"];

//const filterItems = (items, isAgency) =>
//  isAgency
//    ? items
//    : items.filter(item => !agencyTypes.includes(item.type.name));

const renderOffer = ({ id, title, valid_to }, index, array) => {
  const valid = moment(valid_to).format("DD/MM/YYYY");
  return (
    <li
      key={id}
      className={`py-5 px-3 ${index < array.length - 1 &&
        "border-b-2 border-light-blue-grey"}`}
    >
      <Link to={`/offer/${id}`} className="text-black flex">
        <div className="w-4/5">
          <h3 className="mb-2">{title}</h3>
          <p className="text-dark-red uppercase text-sm font-semibold mb-0">
            ENDS {valid}
          </p>
        </div>
        <div className="w-1/5 flex justify-end items-center">
          <FaChevronRight className="text-light-blue-grey text-2xl" />
        </div>
      </Link>
    </li>
  );
};

const Offers = ({ items, isAgency, anchor }) => {
  const _items = items.filter(item => agencyTypes.includes(item.type.name));
  if (_items.length === 0) return null;

  return (
    <div className="bg-white shadow mb-6" id="offers">
       <span className="anchor" id={anchor}>
          Offers section
        </span>
      {isAgency && (
        <>
          <TrapezeHeader
            title={`Agency Offers / Rates`}
            textLeft
            bgColor="dark-red"
            bigText
          />
          <ul className="list-reset">{_items.slice(0, 3).map(renderOffer)}</ul>
        </>
      )}
    </div>
  );
};

Offers.propTypes = {
  items: arrayOf(
    shape({
      id: number.isRequired,
      title: string.isRequired,
      content: string.isRequired,
    })
  ).isRequired,
};

export default connect(state => ({
  isAgency: isAgency(state) || isInternal(state),
}))(Offers);
