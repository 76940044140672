import axios from "axios";

export default (props, setState, id) => {
  axios
    .delete(`${window.API}${props.url}/${id}`, {
      headers: { Authorization: `Bearer ${props.token}` },
    })
    .then(response => {
      setState.complete(response.data);
    });
};
