import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "Components/Modal";
import { Field, reduxForm } from "redux-form";
import { ButtonPrimary, ButtonSpan } from "../Button";
import { renderFormInput } from "../../modules/formTools";
import { checkUserExists } from "../../redux/actions";
import { isCompanyAdmin } from "../../modules/permissions";
import { sendProposalInvite } from "../../redux/actions/proposal";
import { company, isSuperAgent, token, userEmail } from "../../modules/auth";
import { sendCompanyUserInvite } from "../../redux/actions/corporateInvites";
import { Toggle } from "../Form";

const mapDispatchToProps = {
  checkUserExists,
  sendProposalInvite,
  sendCompanyUserInvite,
};

const mapStateToProps = (state, props) => {
  let companyObject = company(state);
  if (isSuperAgent(state)) {
    companyObject = props.rfp?.company_details?.name;
  }
  return {
    token: token(state),
    isCompanyAdmin: isCompanyAdmin(state.auth),
    isSuperAgent: isSuperAgent(state),
    userEmail: userEmail(state),
    company: companyObject,
  };
};

const form = "shareProposalForm";

const Wrapper = ({ children, onClose }) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 600,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3" />
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

let ShareProposalModal = ({
                            onClose,
                            handleSubmit,
                            checkUserExists,
                            token,
                            isCompanyAdmin,
                            isSuperAgent,
                            rfp,
                            sendProposalInvite,
                            userEmail,
                            change,
                            sendCompanyUserInvite,
                            company,
                            setShowInviteSuccessful,
                            values,
                          }) => {
  const [clientName, setClientName] = useState(null);
  const [userExists, setUserExists] = useState(null);
  const [inviteToCompany, setInviteToCompany] = useState(false);
  const [showSendButton, setShowSendButton] = useState(false);
  const [joinCompany, setJoinCompany] = useState(false);
  const [belongsToMyCompany, setBelongsToMyCompany] = useState(false);
  const [giveCorporateAccess, setGiveCorporateAccess] = useState(false);
  const [isSsoUser, setIsSsoUser] = useState(false);
  useEffect(() => {
    change("sharerEmail", userEmail);
    return () => {
      setClientName(null);
      setUserExists(false);
      setInviteToCompany(false);
      setShowSendButton(false);
      setIsSsoUser(false);
    };
  }, []);

  useEffect(() => {
    if (inviteToCompany) {
      change("inviteToCompany", true);
    }
  }, [inviteToCompany]);

  const checkEmailExists = () => {
    if (clientName) {
      checkUserExists(clientName, token).then(response => {
        if (response && response.data) {
          if (response.data.belongsToCompany === false && response.data.belongsToAgency === false) {
            setInviteToCompany(true);
          }
          setUserExists(true);
          if (company && response.data.company_id === company.id) {
            setBelongsToMyCompany(true);
          }
        } else {
          setInviteToCompany(true);
        }
        if (response.error) {
          setUserExists(false);
        }
        setShowSendButton(true);
      });
    }
  };
  const sendCompanyInvite = () => {
    if (company && clientName) {
      const formData = new FormData();
      formData.append("email", clientName);
      if (giveCorporateAccess) {
        formData.append("give_corporate_access", 1);
      }
      return sendCompanyUserInvite(company.id, formData, token).catch(() => {
        // if invite has already been sent don't send another
      });
    }
  };
  const onSubmit = (formValues) => {
    delete formValues.shareProposalForm;
    if (!joinCompany) {
      delete formValues.inviteToCompany;
    }
    return sendProposalInvite(rfp.id, formValues, token).then(() => {
      setShowInviteSuccessful(true);
      onClose();
    });
  };
  return (
    <Wrapper onClose={onClose}>
      <form className={"border-none"} onSubmit={handleSubmit(onSubmit)}>
        <h3>Share this proposal</h3>
        <div className="w-full mb-8 mt-8">
          <div>
            <Field
              label={"Enter Email address"}
              name={"invitedEmail"}
              type={"email"}
              value={clientName}
              onKeyPress={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              onChange={(e) => {
                if (e.target.value === "" || e.target.value.length <= 1) {
                  setUserExists(false);
                  setInviteToCompany(false);
                  setShowSendButton(false);
                }
                setClientName(e.target.value);
              }}
              component={renderFormInput}
            />
            <ButtonPrimary classes={"ml-auto"} onClick={() => checkEmailExists()}>
              Share
            </ButtonPrimary>
            {showSendButton && company && company.sso_method !== null ? (
              <div className="w-1/2">
                <p>Send them a share link and ask them to sign in</p>
                <ButtonPrimary type="submit">
                  Send them a link
                </ButtonPrimary>
              </div>
            ) : (
              <>
                {userExists && showSendButton && inviteToCompany ?
                  <p className={"font-bold"}>This user already has an account on EDGE</p>
                  : null}
                {userExists && showSendButton && !inviteToCompany ?
                  <p
                    className={"font-bold"}> {belongsToMyCompany ? "This user is already linked to this company on EDGE" : "This user already belongs to a company on EDGE"}</p>
                  : null}
                {!userExists && showSendButton &&
                  <p className={"font-bold"}>This user doesn't have an account on EDGE</p>}
              </>
            )}
          </div>

          <div className="flex flex-wrap mt-6">
            {company && company.sso_method === null ? (
              <>
                {(isCompanyAdmin || isSuperAgent) && showSendButton ? (
                  <>
                    {inviteToCompany && company && (
                      <div className={"w-1/2"}>
                        <p>Do you want to invite this User to join your Company?</p>
                        {company.corporate_account_id ?
                          <Field
                            name="give_corporate_access"
                            label="Give Corporate EDGE Access"
                            component={Toggle}
                            wrapperClassName="mb-8 flex-grow"
                            defaultValue={1}
                            onChange={(value) => {
                              setGiveCorporateAccess(value);
                            }}
                          />
                          : null}
                        <ButtonPrimary type="submit" onClick={() => {
                          setJoinCompany(true);
                          sendCompanyInvite();
                        }}>
                          Invite and share
                        </ButtonPrimary>
                      </div>
                    )}
                    <div className="w-1/2">
                      <p>Send them a share link and ask them to sign in or register independently</p>
                      <ButtonPrimary type="submit">
                        Send them a link
                      </ButtonPrimary>
                    </div>
                  </>
                ) : null}
                {(!isCompanyAdmin && !isSuperAgent) && showSendButton && (
                  <div className="w-1/2 mt-1">
                    {!userExists &&
                      <p>This user isn't registered - we'll ask them to sign up when they access the link.</p>}
                    <ButtonPrimary type="submit">
                      Send them a link
                    </ButtonPrimary>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </form>
    </Wrapper>
  );
};
ShareProposalModal = reduxForm({
  form,
  enableReinitialize: true,
})(ShareProposalModal);

export default connect(mapStateToProps, mapDispatchToProps)(ShareProposalModal);
