import { useEffect, useState } from "react";
import useAxios from "hooks/useAxios";

// helper functions
const getArticleType = (articles, type) =>
  articles
    .filter(a => a.type.name === type && a.image)
    .map(a => ({
      title: a.title,
      content: a.content,
      img: a.image ? a.image.url : null,
      link: `/article/${a.slug}`,
      type
    }));

const getOfferType = (offers, type) =>
  offers
    .filter(a => a.type.name === type && a.image)
    .map(a => ({
      title: a.title,
      content: a.content,
      img: a.image ? a.image.url : null,
      link: `/offer/${a.id}`,
      type
    }));

const useCmsContent = (limit = false) => {
  // this is the data we return
  const [agencyRates, setAgencyRates] = useState([]);
  const [famTrips, setFamTrips] = useState([]);
  const [blog, setBlog] = useState([]);
  const [commissionOffers, setCommissionOffers] = useState([]);
  const [moversAndShakers, setMoversAndShakers] = useState([]);
  const [news, setNews] = useState([]);
  const [renovationNews, setRenovationNews] = useState([]);
  const [specialOffers, setSpecialOffers] = useState([]);
  // --------------------------

  // TODO - INVESTIGATE STORAGE - TOO BIG FOR SESSION
  // check browser storage - don't use localStorage, it'll never expire
  // const savedArticles = JSON.parse(sessionStorage.getItem("articles"));
  // const savedOffers = JSON.parse(sessionStorage.getItem("offers"));

  // set data from session if exists
  const [articles, setArticles] = useState([]);
  const [offers, setOffers] = useState([]);
  // const [articles, setArticles] = useState(savedArticles || []);
  // const [offers, setOffers] = useState(savedOffers || []);

  // get data if needed (only fetches if trigger is true)
  const { data: articleData } = useAxios(
    {
      url: window.API + "/cms/articles",
    },
      true,
      limit === false ? 12 : false,
      limit
    // !articles.length
  );
  const { data: offerData } = useAxios(
    {
      url: window.API + "/cms/offers",
    },
    true,
      false,
      limit
    // !offers.length
  );

  // set data if it arrives
  useEffect(() => {
    if (articleData) {
      setArticles(articleData);
      // sessionStorage.setItem("articles", JSON.stringify(articleData));
    }
  }, [articleData]);
  useEffect(() => {
    if (offerData) {
      setOffers(offerData);
      // sessionStorage.setItem("offers", JSON.stringify(offerData));
    }
  }, [offerData]);

  // split articles into sections
  useEffect(() => {
    if (!articles.length) {
      return;
    }
    setBlog(getArticleType(articles, "blog"));
    setMoversAndShakers(getArticleType(articles, "movers-and-shakers"));
    setNews(getArticleType(articles, "news"));
    setRenovationNews(getArticleType(articles, "renovation-news"));
  }, [articles]);

  // now offers
  useEffect(() => {
    if (!offers.length) {
      return;
    }
    setSpecialOffers(getOfferType(offers, "special-offers"));
    setFamTrips(getOfferType(offers, "fam-trips"));
    setAgencyRates(getOfferType(offers, "agency-rates"));
    setCommissionOffers(getOfferType(offers, "commission-offers"));
  }, [offers]);

  return {
    agencyRates,
    famTrips,
    blog,
    commissionOffers,
    moversAndShakers,
    news,
    renovationNews,
    specialOffers,
  };
};

export default useCmsContent;
