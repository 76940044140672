import React from "react";
import AdminList from "../../../../Components/AdminList";
import { isAgency } from "../../../../modules/auth";
import { isRoleEdgeAdmin } from "../../../../modules/permissions";
import AdminListActionButton from "../../../../Components/AdminListActionButton";
import * as ROUTES from "../../../../Router/corporate_routes";
import Loading from "../../../../Components/Loading";
import { Link } from "react-router-dom";
import moment from "moment";
import { handleDownload } from "../../../../services/downloadFile";
import { preAddUrl } from "../../../../utils";

let CorporateAccountDocumentContainer = ({ isFetching, documents, accountSlug, token }) => {
  if (isFetching) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  let createAction = () => {
    if (isAgency || isRoleEdgeAdmin) {
      return (
        <AdminListActionButton
          to={ROUTES.FRONTEND.CORPORATE.DOCUMENTS.CREATE.replace(":slug", accountSlug).replace(":section", "manage-documents")}
          text={`Add New`} />
      );
    }
    return null;
  };

  let headings = () => {
    return (
      <tr>
        <th>Actions</th>
        <th>Title</th>
        <th>Location/Category</th>
        <th>Last Updated</th>
        <th>Download/Link</th>
      </tr>
    );
  };

  let corporateDocumentList = () => {
    if (typeof documents.data === "undefined" || (Object.entries(documents.data).length === 0 && documents.data.constructor === Object)) {
      return (
        <tr>
          <td>
            <div>No documents available for this corporate account</div>
          </td>
        </tr>
      );
    }

    if (documents.data.length === 0 || isFetching) {
      return null;
    }

    return documents.data.sort((a, b) => new moment(b.updated_at).format("YYYYMMDD") - new moment(a.updated_at).format("YYYYMMDD")).map((document) => {
      if (document.edge_document_id === null && document.external_link === null) {
        return;
      }
      return (
        <tr key={document.id}>
          <td className="px-6 py-3 whitespace-nowrap">
            <div className="flex items-center">
              <div className="text-sm font-medium text-gray-900">
                <Link
                  to={ROUTES.FRONTEND.CORPORATE.DOCUMENTS.EDIT.replace(":slug", accountSlug).replace(":id", document.id).replace(":section", "manage-documents")}
                  className={"text-black"}>
                  <i className="fal fa-edit px-3" />
                </Link>
              </div>
            </div>
          </td>
          <td>
            <div className="flex items-center">
              <div>
                {document.name}
              </div>
            </div>
          </td>
          <td>
            <div className="flex items-center">
              <div>
                {document.categories.map((category, index) => {
                  if (index + 1 === document.categories.length) {
                    return (
                      category.name
                    );
                  } else {
                    return (
                      category.name + ","
                    );
                  }
                })}
              </div>

            </div>
          </td>
          <td>
            <div className="flex items-center content-center">
              <div className={""}>
                {moment(document.updated_at).format("DD/MM/YYYY")}
              </div>
            </div>
          </td>
          <td>
            <div className="flex items-center content-center">
              <div className={""}>
                {typeof document.file !== "undefined" && document.file ?
                  <a className={"text-sm font-medium"} href="#" onClick={() => handleDownload(document.file?.name, document.file?.url, document.file?.extension, token)}>
                    <i className="fal fa-file-download fa-1x" />
                  </a>
                  : (
                    <a className={"text-sm font-medium"} href={preAddUrl(document.external_link)} target={"_blank"}>
                      <i className="fal fa-external-link fa-1x" />
                    </a>
                  )}
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <AdminList
      title={"Documents"}
      actions={
        <React.Fragment>
          {createAction()}
        </React.Fragment>
      }
      tableHeadings={headings()}
      tableList={corporateDocumentList()}
    />
  );

};
export default CorporateAccountDocumentContainer;