import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { string, bool } from "prop-types";
import { isLoggedIn } from "modules/auth";
import { venueInteraction } from "modules/analytics";
import { pushEvent } from "../modules/google-tag-manager";
import { ButtonLink } from "./Button";

class LinkedButton extends React.Component {
  static propTypes = {
    iconClassName: string.isRequired,
    link: string,
    className: string,
    phone: bool,
    email: bool,
    newWindow: bool,
    auth: bool,
    clickType: string,
  };

  state = {
    show: false,
  };

  handleClick = e => {
    if (this.props.auth && !this.props.isLoggedIn) {
      e.preventDefault();
      this.props.history.push(
        `/login?return=${encodeURI(
          this.props.location.pathname,
        )}&logInToProceed=1`,
      );
      return;
    }
    if (this.props.venueInteraction) {
      this.props.track(this.props.venueInteraction);
    }

    if (this.props.googleTagEvent) {
      pushEvent(this.props.googleTagEvent);
    }

    if (this.props.phone) {
      e.preventDefault();
      this.setState({
        show: !this.state.show,
      });
    }
  };

  render() {
    const {
      newWindow,
      link,
      phone,
      email,
      textOnShow,
      text,
    } = this.props;
    let prefix = "";
    if (phone) {
      prefix += "tel:";
    } else if (email) {
      prefix += "mailto:";
    }

    if (this.state.show) {
      return (
        <ButtonLink
          Element="a"
          href={`tel:${textOnShow}`}
          classes="text-edge-blue text-sm font-normal text-base">
          {textOnShow}
        </ButtonLink>
      );
    } else {
      return (
        <a
          href={this.props.isLoggedIn ? prefix + link : "#"}
          target={newWindow && "_blank"}
          rel={newWindow && "noopener noreferer"}
          className={"text-edge-blue text-sm font-normal text-base"}
          onClick={this.handleClick}
        >
          {text}
        </a>
      );
    }
  }
}

export default withRouter(
  connect(
    state => ({
      isLoggedIn: isLoggedIn(state),
    }),
    { track: venueInteraction },
  )(LinkedButton),
);
