import React from "react";
import { connect } from "react-redux";

import { isAgency } from "../../modules/auth";
import Breadcrumbs from "../../Components/Breadcrumbs";

import TrinityNews from "./TrinityNews";
import { CATEGORY_GROUP_IDS } from "modules/cms";

const Community = ({ isAgency }) => (
  <div className="container bg-white mt-4 p-5">
    <Breadcrumbs
      items={[
        { name: "Home", to: "/" },
        { name: (isAgency ? "Agency " : "") + "Community" },
      ]}
    />
    <TrinityNews categories={CATEGORY_GROUP_IDS.community} />
  </div>
);

export default connect(state => ({ isAgency: isAgency(state) }))(Community);
