import {
  AGENCY_USER_INVITE_RECEIVED_REQUEST, DELETE_INVITE_RECEIVED_REQUEST,
  USER_INVITE_RECEIVED_ERROR,
  USER_INVITE_RECEIVED_REQUEST,
  USER_INVITE_REQUEST,
  USER_INVITE_UNLOAD,
  USER_INVITES_RECEIVED_ERROR,
  USER_INVITES_RECEIVED_REQUEST,
  USER_INVITES_REQUEST, USER_INVITES_UNLOAD
} from "./types";

export const userInvites = (state = {
  userInvites: [],
  isFetchingUserInvites: false,
  error: false,
}, action) => {
  switch (action.type) {
    case USER_INVITES_REQUEST:
      return {
        ...state,
        isFetchingUserInvites: true
      }
    case USER_INVITES_RECEIVED_REQUEST:
      return {
        ...state,
        isFetchingUserInvites: false,
        userInvites: action.data
      }
    case USER_INVITES_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingUserInvites: false,
        userInvites: []
      }
    case AGENCY_USER_INVITE_RECEIVED_REQUEST:
      let userInvites = state.userInvites
      userInvites.push(action.data)
      return {
        ...state,
        isFetchingUserInvites: false,
        userInvites: userInvites
      }
    case USER_INVITES_UNLOAD:
      return {
        ...state,
        userInvites: []
      }
    default:
      return state;
  }
}

export const userInvite = (state = {
  userInvite: null,
  isFetchingUserInvite: false,
  error: false
}, action) => {
  switch (action.type) {
    case USER_INVITE_REQUEST:
      return {
        ...state,
        isFetchingUserInvite: true
      }
    case USER_INVITE_RECEIVED_REQUEST:
      return {
        ...state,
        isFetchingUserInvite: false,
        error: false,
        userInvite: action.data.data
      }
    case DELETE_INVITE_RECEIVED_REQUEST:
      return {
        ...state,
        error: false,
        isFetchingUserInvite: false,
        userInvite: null
      }
    case USER_INVITE_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingUserInvite: false,
        error: true
      }
    case USER_INVITE_UNLOAD:
      return {
        ...state,
        isFetchingUserInvite: false,
        userInvite: null
      }
    default:
      return state;
  }
}
