import React from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux';
import {processFormValuesPost, renderFormInput} from "../../modules/formTools";
import {Input} from "../Form";
import {ButtonBlack} from "../Button";
import Loading from "../Loading";
import {minLength, required} from "../../modules/validation";

const mapStateToProps = (props) => {
  return {
    initialValues: {
      ...props.userInvite.userInvite,
      user_type_id: props.userInvite.userInvite.agency ? 2 : 1
    }
  }
}

const v = {
  firstName: required("Please enter your first name"),
  surname: required("Please enter your surname"),
  email: required("Please enter your email address"),
  password: required("Please enter your password"),
  min8: minLength(8),
};

const UserInvitedForm = (props) => {
  const onSubmit = formValues => {
    return processFormValuesPost(props, formValues, props.initialValues);
  }
  return (
    <div className={'bg-off-white p-5 mb-5'}>
      <div>
        <form onSubmit={props.handleSubmit(onSubmit)}>
          <div className="bg-off-white p-5 mb-5">
            <Field
              name={"email"}
              disabled={true}
              label={"Email"}
              component={renderFormInput}
            />
            {!props.userInvite.is_existing_user ?
                <>
                  <Field
                      name={"first_name"}
                      label={"First Name"}
                      component={renderFormInput}
                  />
                  <Field
                      name={"surname"}
                      label={"Surname"}
                      component={renderFormInput}
                  />
                  <div className="hidden">
                    <Field name={"user_type_id"} component={renderFormInput}/>
                  </div>
                  <Field
                      name="password"
                      type="password"
                      validate={(v.password, v.min8)}
                      label="Password"
                      component={Input}
                  />
                </>
                : null}

            <ButtonBlack type="submit">
              {props.submitting ? <Loading inline/> : props.userInvite.is_existing_user  ? "Join Company" : "Register"}
            </ButtonBlack>
          </div>
        </form>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, null)(reduxForm({
  form: 'userInvitedForm',
  enableReinitialize: true
})(UserInvitedForm));
