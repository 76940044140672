import React from "react";
import classNames from "classnames";
import { string, object, array, oneOfType } from "prop-types";
import Label from "./Label";
import Error from "./Error";

export default class Radio extends React.Component {
  static propTypes = {
    options: array,
    input: object.isRequired,
    meta: object.isRequired,
    label: oneOfType([string, object]).isRequired,
    wrapperClassName: string,
    labelClassName: string,
    inputClassName: string,
  };

  static defaultProps = {
    options: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
  };

  render() {
    const {
      meta: { touched, error, warning },
      input,
      label,
      wrapperClassName,
      labelClassName,
      options,
    } = this.props;
    return (
      <div className={wrapperClassName || "mb-5"}>
        <Label name={input.name} className={labelClassName} text={label} />
        <div className="flex flex-wrap">
          {options.map(option => {
            const value = typeof option === "string" ? option : option.value;
            const text = typeof option === "string" ? option : option.text;
            return (
              <label
                key={value}
                role="button"
                tabIndex="0"
                className="mr-5 flex items-center inline-block outline-none border border-transparent focus:border-primary"
                onClick={() => {
                  input.onChange(value);
                }}
                onKeyPress={() => {
                  input.onChange(value);
                }}
                style={{
                  height: 41,
                }}
              >
                <i
                  className={classNames("far mr-2", {
                    "fa-dot-circle text-primary": value === input.value,
                    "fa-circle": value !== input.value,
                  })}
                />
                {text}
              </label>
            );
          })}
        </div>
        <Error touched={touched} warning={warning} error={error} />
      </div>
    );
  }
}
