import React, {useEffect} from "react";
import {connect} from "react-redux";
import {ButtonSpan} from "Components/Button";
import Modal from "Components/Modal";
import {companyUnload, fetchCountries} from "../../redux/actions";
import CompanyForm from "./CompanyForm";

const mapStateToProps = state => ({
  ...state.countries
});

const mapDispatchToProps = {
  companyUnload,
  fetchCountries
}

const Wrapper = ({children, onClose}) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 600,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3"/>
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

const CompanyFormModal = (props) => {
  useEffect(() => {
    props.fetchCountries(props.token);
    props.companyUnload();
  }, []);

  return (
    <Wrapper onClose={props.onClose}>
        <h1 className="mb-8">Create New Company</h1>
      {props.isEdgeEnquiry ? (
        <div className="mb-2 py-3 px-4 text-white bg-warning font-bold mb-5">
          EDGE Venues - you are creating a company for EDGE not Trinity!
        </div>
      ) : (
        <></>
      )}
      <CompanyForm
        company={{}}
        onSubmit={props.onSubmit}
        countries={props.countries ? props.countries : []}
        industries={props.industries}
        agencyCode={props.agencyCode ? props.agencyCode : null}
        fullWidth
      />
    </Wrapper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyFormModal);
