import axios from "axios";
import { createHandleAxiosError } from "modules/api-tools";

export default (files, props, setState) => {
  const file = files[0];

  if (!file) return;

  if (file.size > 10 * 1024 * 1024) {
    setState.error("Files must be 10MB or smaller.");
    return;
  }

  let formData = new FormData();
  // add the file
  formData.append(props.type === "image" ? "image" : "file", file);

  if (props.type === "document") {
    // add the type
    if (props.params) {
      Object.keys(props.params).forEach(name => {
        formData.append(name, props.params[name]);
      });
    }
    // add the name
    formData.append("name", file.name);
  }

  const { history, location } = props;
  const handleAxiosError = createHandleAxiosError({ history, location });

  axios
    .post(props.path, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${props.token}`,
      },
    })
    .then(response => {
      if (props.type === "document") {
        let values = Array.isArray(props.files) ? [...props.files] : [];
        values.push(response.data);
        props.change(props.formId, props.readFrom, values);
      } else {
        // images will be as above eventually...
        props.change(props.formId, props.idName, response.data.id);
        props.change(props.formId, props.objectName, response.data);
      }
      setState.done();
    })
    .catch(
      handleAxiosError(err => {
        let error = "Sorry, there was an error uploading the file.";
        if (
          err.response &&
          err.response.data &&
          err.response.data.errors &&
          Array.isArray(
            err.response.data.errors[props.type === "image" ? "image" : "file"]
          )
        ) {
          error =
            err.response.data.errors[
              props.type === "image" ? "image" : "file"
            ][0];
        }
        setState.error(error);
      })
    );
};
