import React from "react";
import Stage1 from "./Stage1";
import Stage2 from "./Stage2";
import Stage3 from "./Stage3";

export const getPages = (
  form,
  nextPage,
  prevPage,
  errors,
  handleSubmitEnquiry,
  attachments,
  setAttachments,
  roomStyleOptions = null,
  hasVenues = false,
  initialValues,
  editEnquiry = false,
  setSubmitToAgent,
  setEnquiryToSubmitted,
  isAgency,
  isSuperAgent,
  isVenueUser,
  setSubmitToDraft,
  enquiry,
  setShowDateWarningMessage,
  maxVenues,
  company,
  setCompany,
  offerRfpXpress,
  requiresAuthorising,
  setRequiresAuthorising,
  disableMaxDelegates = false
) => [
  {
    error: errors.some(field =>
      [
        "event_name",
        "number_of_delegates",
        "date_from",
        "date_to",
        "date_flexible",
        "date_flexible_notes",
        "room_style_id",
        "additional_notes",
      ].includes(field)
    ),
    name: "Get started",
    Component: <Stage1
      form={form}
      onSubmit={nextPage}
      roomStyleOptions={roomStyleOptions}
      hasVenues={hasVenues}
      initialValues={initialValues}
      editEnquiry={editEnquiry}
      isAgency={isAgency}
      isSuperAgent={isSuperAgent}
      isVenueUser={isVenueUser}
      setSubmitToDraft={setSubmitToDraft}
      enquiry={enquiry}
      briefPreferencesRelevant={enquiry ? !!enquiry.brief_preferences_relevant : false}
      setShowDateWarningMessage={setShowDateWarningMessage}
      requiresAuthorising={requiresAuthorising}
      setRequiresAuthorising={setRequiresAuthorising}
      disableMaxDelegates={disableMaxDelegates}
    />,
  },
  {
    error: errors.some(field =>
      ["bedrooms_required", "bedroom_dates"].includes(field)
    ),
    name: "Bedrooms",
    Component: <Stage2
      form={form}
      onSubmit={nextPage}
      prevPage={prevPage}
      hasVenues={hasVenues}
      initialValues={initialValues}
      setSubmitToDraft={setSubmitToDraft}
      requiresAuthorising={requiresAuthorising}
      setRequiresAuthorising={setRequiresAuthorising}
    />,
  },
  {
    error: errors.some(field =>
      [
        "back_projection",
        "syndicate_room",
        "syndicate_room_notes",
        "exhibition_space",
        "exhibition_space_notes",
        "private_dining",
        "private_dining_notes",
        "date_decision_estimate",
      ].includes(field)
    ),
    name: "The last step",
    Component: (
      <Stage3
        form={form}
        attachments={attachments}
        setAttachments={setAttachments}
        onSubmit={handleSubmitEnquiry}
        prevPage={prevPage}
        hasVenues={hasVenues}
        initialValues={initialValues}
        editEnquiry={editEnquiry}
        setSubmitToAgent={setSubmitToAgent}
        setEnquiryToSubmitted={setEnquiryToSubmitted}
        setSubmitToDraft={setSubmitToDraft}
        enquiry={enquiry}
        company={company}
        setCompany={setCompany}
        offerRfpXpress={offerRfpXpress}
        requiresAuthorising={requiresAuthorising}
        setRequiresAuthorising={setRequiresAuthorising}
      />
    ),
  },
];