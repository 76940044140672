import axios from "axios";

const getEquipment = async (_, token) => {
  try {
    const response = await axios.get(`${window.API}/rfp/equipment`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.data.data) {
      return {
        result: [],
      };
    }

    return {
      result: response.data.data.map(({ equipment_id, equipment_name }) => ({
        text: equipment_name,
        value: equipment_id,
      })),
    };
  } catch (error) {
    return { error };
  }
};

export { getEquipment };
