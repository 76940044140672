import { SubmissionError } from 'redux-form';
import parseErrors from '../../modules/parse-errors';

export default function handleErrors(error) {
  const errors = parseErrors(error);
  if (errors) {
    throw new SubmissionError({
      ...errors,
    });
  }
}
