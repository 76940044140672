import React from "react";
import Slider from "Components/Slider";
import VenueCard from "Components/VenueCard";

const VenueSlider = ({ venues }) => {
  const slides = venues.map(venue => () => {
    return <VenueCard venue={venue} />;
  });
  return (
    <div>
      <Slider slides={slides} />
    </div>
  );
};

export default VenueSlider;
