import React, { Component } from "react";
import { connect } from "react-redux";
import { isSuperAgent, token } from "../../../../modules/auth";
import { eventsSingleUnload, fetchSingleBookedEvent } from "../../../../redux/actions";
import { getEnquiryById } from "../../../../services/rfpService";
import Loading from "../../../../Components/Loading";
import RfpBriefSection from "../../../../Components/RfpBriefSection";
import { ENQUIRY_VENUE_DATA_STATUS, getStatusText } from "../../../../modules/rfp/enquiryVenueDataStatus";
import BookedEventsForm from "../../../../Components/events/BookedEventsForm";
import {
  canCancelEvent,
  canManageEventCompletion,
  canManageEventPreData,
  isCompanyAdmin, isCompanyUser,
} from "../../../../modules/permissions";
import { isAgency } from "../../../../modules/auth";
import { ButtonPrimary } from "../../../../Components/Button";
import { Link } from "react-router-dom";
import DateOverrideNotification from "../../../../Components/Rfp/DateOverrideNotification";
import { BOOKED_EVENT_STATUS, ENQUIRY_STATUS } from "../../../../constants";
import RFPCancelMessage from "../../../../Components/Rfp/RFPCancelMessage";

const mapStateToProps = state => {
  return {
    ...state.bookedEvent,
    token: token(state),
    canManageEventCompletion: canManageEventCompletion(state.auth),
    canManageEventPreData: canManageEventPreData(state.auth),
    canCancelEvent: canCancelEvent(state.auth),
    isCompanyAccount: isCompanyAdmin(state.auth) || isCompanyUser(state.auth),
    isAgency: isAgency(state),
    isSuperAgent: isSuperAgent(state),
  };
};

const mapDispatchToProps = {
  fetchSingleBookedEvent,
  eventsSingleUnload,
};

class EventSingleContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiry: null,
    };
  }

  componentDidMount() {
    this.props.fetchSingleBookedEvent(this.props.match.params.slug, this.props.token).then((response) => {
      getEnquiryById(response.data.data.enquiry_id, this.props.token).then((response) => {
        this.setState({
          enquiry: response.result,
        });
      });
    });
  }

  componentWillUnmount() {
    this.props.eventsSingleUnload();
  }

  render() {
    const { enquiry } = this.state;
    const {
      isFetchingBookedEvent,
      bookedEvent,
      canManageEventCompletion,
      canManageEventPreData,
      canCancelEvent,
      isCompanyAccount,
      isAgency,
      isSuperAgent,
    } = this.props;

    if (isFetchingBookedEvent || !enquiry) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    let confirmedData = enquiry.enquiry_venue_datas.find(datas => datas.status === 70);
    return (
      <div className="py-5">
        <h1 className="text-md font-normal font-body mb-3">
          {enquiry.brief.event_name}
        </h1>
        {enquiry.brief?.is_highly_confidential ? (
          <div className='bg-warning px-3 py-6 my-5 block font-bold text-white'>
            <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
              This enquiry is highly confidential.
            </p>
          </div>
        ) : null}
        {isSuperAgent && (confirmedData && confirmedData.venue_confirmation && confirmedData.venue_confirmation.date_venue_confirmed === null) ? (
          <div className="bg-warning px-3 py-6 my-5 block font-bold text-white">
            <p className={"mb-0"}><i className="fas text-2lg fa-exclamation-triangle mr-3" />
              This event has not been confirmed by all parties - please check prior to invoicing.
            </p>
          </div>
        ) : null}
        <div>
          <span
            className="p-1 mb-4 bg-brand inline-block">{isCompanyAccount && bookedEvent?.event_status && parseInt(bookedEvent?.event_status.code) === BOOKED_EVENT_STATUS.PENDING_INVOICE ? "Completed" : bookedEvent?.event_status && bookedEvent?.event_status.name}</span>
          <p className="text-xl">Ref: {enquiry.client_details.ref_id}, Version: {
            confirmedData && confirmedData?.proposal_data?.amendment_requests ?
              `${confirmedData.proposal_data.amendment_requests.length + 1}` : 1}
            {confirmedData?.status && parseInt(confirmedData.status) === ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DRAFTED ?
              <strong>(Draft)</strong> : null}
            {enquiry.managing_trinity_user ?
              <span className={"ftd-rfp-managed ml-2"}>Managed</span>
              : null}
          </p>
          {bookedEvent.invoice_number ? <p className="text-xl">Invoice Number: {bookedEvent.invoice_number}</p> : null}
        </div>
        {isAgency && enquiry.brief.enquiry_status.code !== ENQUIRY_STATUS.CANCELLED ?
          <div className="flex mb-4">
            <ButtonPrimary
              Element={Link}
              to={`/proposal/edit/${enquiry.brief.share_id}`}
            >
              Edit Brief
            </ButtonPrimary>
          </div> : null
        }
        {enquiry.brief.enquiry_status.code === ENQUIRY_STATUS.CANCELLED ?
          <RFPCancelMessage enquiry={enquiry} chargesApply={bookedEvent.cancellation_charges_apply} /> : null
        }

        <DateOverrideNotification data={enquiry} enquiryVenueData={confirmedData} />
        <RfpBriefSection
          brief={enquiry.brief}
          clientDetails={enquiry.client_details}
          agencySpecificData={enquiry.agency_specific_data}
          managingTrinityUser={enquiry.managing_trinity_user}
          rfpId={enquiry.id}
          event={bookedEvent}
          enquiryVenueData={confirmedData}
        />
        <BookedEventsForm
          eventId={this.props.match.params.slug}
          event={this.props.bookedEvent}
          canManageEventPreData={canManageEventPreData}
          canManageEventCompletion={canManageEventCompletion}
          canCancelEvent={canCancelEvent}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSingleContainer);
