import React from "react";
import classNames from "classnames";
import {
  string,
  object,
  func,
  bool,
  oneOf,
  oneOfType,
  number,
} from "prop-types";
import Label from "./Label";
import Error from "./Error";

export const inputStyles = {
  className: classNames([
//    "px-2",
    "px-4",
    "py-0",
    "border",
    "w-full",
    "outline-none",
    "font-normal",
    "text-black",
    "text-sm",
    "focus:border-primary",
    "bg-white",
    "border-grey-md",
  ]),
  style: {
    lineHeight: "37px",
    height: "64px",
//    height: "37px",
  },
};

export default class Input extends React.Component {
  static propTypes = {
    input: object.isRequired,
    meta: object.isRequired,
    label: oneOfType([string, object]),
    id: string,
    type: string,
    placeholder: string,
    icon: string,
    iconRight: string,
    wrapperClassName: string,
    labelClassName: string,
    inputClassName: string,
    right: func,
    white: bool,
    size: oneOf(["sm", "md", "lg"]),
    autoComplete: string,
    additionalInputClass: string,
    min: number,
    max: number,
    step: number,
    readOnly: bool,
    disabled: bool
  };


  render() {
    const {
      id,
      meta: { touched, error, warning },
      input,
      label,
      type,
      placeholder,
      icon,
      iconRight,
      size,
      wrapperClassName,
      labelClassName,
      inputClassName,
      additionalInputClass,
      right,
      white,
      autoComplete,
      min,
      max,
      step,
      autoFocus,
      tall,
      readOnly,
      readOnlyBold,
      preValue = "",
        disabled = false,
    } = this.props;
    let style = {
      lineHeight: tall ? "64px" : "37px",
      height: tall ? "64px" : "37px",
    };
    if (icon) {
      style = {
        ...style,
        backgroundImage: `url(${icon})`,
        paddingLeft: 40,
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "20px 20px",
      };
    }
    if (iconRight) {
      style = {
        ...style,
        backgroundImage: `url(${iconRight})`,
        paddingRight: 35,
        backgroundPosition: "right 5px center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "25px 25px",
      };
    }
    let wrapperClass = "max-w-full mb-5";
    if (size) {
      wrapperClass += " mr-6 ";
      switch (size) {
        case "sm":
          wrapperClass += "w-32";
          break;
        case "md":
          wrapperClass += "w-64";
          break;
        case "lg":
          wrapperClass += "w-80";
          break;
        default:
          wrapperClass += "";
      }
    }
    return (
      <div className={wrapperClassName || wrapperClass}>
        {label && (
        <Label name={input.name} className={labelClassName} text={label} />
        )}
        {readOnly ? (
          <p className={classNames("mb-0", { "font-bold": readOnlyBold })}>
            {input.value ? preValue : ""}
            {input.value}
          </p>
        ) : (
          <div className="flex">
        <input
          {...input}
          id={id}
          placeholder={placeholder}
          autoComplete={autoComplete}
          type={type}
          style={style}
          min={min}
          max={max}
          step={step}
          autoFocus={autoFocus}
          disabled={disabled}
          className={
            inputClassName ||
            classNames(
              [
                    type === "number" ? "pl-2" : "px-2",
                "py-0",
                "border",
                "w-full",
                "outline-none",
                "font-normal",
                disabled ? "text-grey-darker": "text-black",
                    "text-sm",
                "focus:border-primary",
                disabled ?"bg-grey" : "bg-white",
                "rounded-none",
                "appearance-none",
                disabled ? "cursor-not-allowed": "",
              ],
              {
                "border-r-0": right,
                "border-grey-md": !white,
                "border-white": white,
              },
              additionalInputClass
            )
          }
        />
        {right && right()}
          </div>
        )}
        <Error touched={touched} warning={warning} error={error} />
      </div>
    );
  }
}
