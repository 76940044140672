import axios from "axios";
import * as ROUTES from "../../Router/corporate_routes";
import handleErrors from "./utils";
import {
  COMPANY_BRANCH_CREATED,
  COMPANY_BRANCH_LIST_RECEIVED_ERROR,
  COMPANY_BRANCH_LIST_RECEIVED_REQUEST,
  COMPANY_BRANCH_LIST_REQUEST,
  COMPANY_BRANCH_REQUEST,
  COMPANY_BRANCH_REQUEST_RECEIVED,
  COMPANY_BRANCH_REQUEST_RECEIVED_ERROR,
  COMPANY_BRANCHES_UNLOAD,
  COMPANY_BRANCH_UNLOAD,
} from "../reducers/types";

export const companyBranchesListRequest = () => ({
  type: COMPANY_BRANCH_LIST_REQUEST,
});

export const companyBranchesListReceivedRequest = data => ({
  type: COMPANY_BRANCH_LIST_RECEIVED_REQUEST,
  data,
});

export const companyBranchesListReceivedError = error => ({
  type: COMPANY_BRANCH_LIST_RECEIVED_ERROR,
  error,
});

export const companyBranchRequest = () => ({
  type: COMPANY_BRANCH_REQUEST,
});

export const companyBranchCreated = data => ({
  type: COMPANY_BRANCH_CREATED,
  data,
});

export const companyBranchRequestReceived = data => ({
  type: COMPANY_BRANCH_REQUEST_RECEIVED,
  data,
});

export const companyBranchRequestReceivedError = error => ({
  type: COMPANY_BRANCH_REQUEST_RECEIVED_ERROR,
  error,
});

export const unloadCompanyBranches = () => ({
  type: COMPANY_BRANCHES_UNLOAD,
});

export const unloadCompanyBranch = () => ({
  type: COMPANY_BRANCH_UNLOAD,
});

export const fetchCompanyBranches = (id, token) => {
  return (dispatch) => {
    dispatch(companyBranchesListRequest());
    return axios.get(window.API + ROUTES.API.COMPANY.BRANCHES.LIST.replace(":companyId", id), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(companyBranchesListReceivedRequest(response.data));
    }).catch(error => {
      dispatch(companyBranchesListReceivedError(error));
    });
  };
};

export const createCompanyBranch = (id, formValues, token) => {
  return (dispatch) => {
    dispatch(companyBranchRequest());
    return axios.post(window.API + ROUTES.API.COMPANY.BRANCHES.CREATE.replace(":companyId", id), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(companyBranchCreated(response));
      window.location.reload();
    }).catch(error => {
      companyBranchRequestReceivedError(error);
      handleErrors(error);
    });
  };
};

export const editCompanyBranch = (id, companyId, formValues, token) => {
  return (dispatch) => {
    dispatch(companyBranchRequest());
    return axios.post(window.API + ROUTES.API.COMPANY.BRANCHES.SINGLE.replace(":companyId", companyId).replace(":id", id), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(companyBranchRequestReceived(response.data));
    }).catch(error => {
      companyBranchRequestReceivedError(error);
      handleErrors(error);
    });
  };
};

export const deleteCompanyBranch = (id, companyId, token) => {
  return (dispatch) => {
    dispatch(companyBranchRequest());
    return axios.delete(window.API + ROUTES.API.COMPANY.BRANCHES.SINGLE.replace(":companyId", companyId).replace(":id", id), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(companyBranchRequestReceived(response.data));
      window.location.reload();
    }).catch(error => {
      companyBranchRequestReceivedError(error);
      handleErrors(error);
    });
  };
};
