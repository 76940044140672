import React from "react";

export const mapBookedEventToCalendarEvent = event => {
  return {
    'id': event.id,
    'ref_id': event.ref_id,
    'enquiry_id': event.enquiry_id,
    'enquiry_venue_data_id': event.enquiry_venue_data_id,
    'bookedEvent': true,
    'status': event.status,
    'title': event.ref_id + " - " + event.title,
    'description': event.title,
    'location': event.location,
    'start': new Date(event.start_date),
    'end': new Date(event.end_date),
    'venue': event.venue,
    'type': event.status ? event.status.toLowerCase() : '',
    'isOwnEvent': event.is_own_event,
    'client_name': event.client_name,
    'isConfidential': event.is_confidential
  }
}

export const mapEventToCalendarEvent = (event, type) => {
  return {
    'id': event.id,
    'title': event.title,
    'description': event.description,
    'location': event.location,
    'start': new Date(event.start_date),
    'end': new Date(event.end_date),
    'country_id': event.country_id,
    'type': type
  }
}

export const statusKeyDiv = (show = true) => {
  if (show) {
    return (
      <div className="flex flex-wrap -mt-4 pb-8 text-center">
        <div className="my-2 mr-2 flex-auto bg-blue rounded p-3 confirmed-event">Confirmed Events</div>
        <div className="my-2 mr-2 flex-auto bg-blue rounded p-3 provisional-event">Provisional Events</div>
        <div className="my-2 mr-2 flex-auto bg-blue rounded p-3 new-enquiry-event">New Event Enquiry</div>
        <div className="my-2 flex-auto bg-blue rounded p-3 cancelled-event">Cancelled Events</div>
      </div>
    )
  }
  return <></>
}