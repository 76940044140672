import {
  COMPANY_LIST_ERROR, COMPANY_LIST_EVENTS_ERROR, COMPANY_LIST_EVENTS_RECEIVED, COMPANY_LIST_EVENTS_REQUEST,
  COMPANY_LIST_RECEIVED,
  COMPANY_LIST_REQUEST,
  COMPANY_LIST_UNLOAD,
  COMPANY_RECEIVED,
  COMPANY_RECEIVED_ERROR,
  COMPANY_REQUEST, COMPANY_ROLES_UPDATE_RECEIVED_REQUEST,
  COMPANY_UNLOAD, COMPANY_USER_LIST_REQUEST, COMPANY_USER_LIST_REQUEST_RECEIVED, COMPANY_USER_LIST_UNLOAD,
  COMPANY_USER_RECEIVED,
  COMPANY_USER_RECEIVED_ERROR,
  COMPANY_USER_REQUEST,
  CREATE_COMPANY,
  CREATE_COMPANY_ERROR,
  DELETE_COMPANY_USER,
  EDIT_COMPANY,
  EDIT_COMPANY_ERROR,
  EDIT_COMPANY_USER,
  EDIT_COMPANY_USER_ERROR
} from "./types";

export const companyList = (state = {
  companies: {},
  companiesForEvents: {},
  isFetchingCompanies: false,
  companiesPage: null,
  companiesLastPage: null,
}, action) => {
  switch (action.type) {
    case COMPANY_LIST_REQUEST:
    case COMPANY_LIST_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingCompanies: true,
      }
    case COMPANY_LIST_RECEIVED:
      return {
        ...state,
        isFetchingCompanies: false,
        companies: action.data.data,
        companiesPage: action.data?.meta?.current_page,
        companiesLastPage: action.data?.meta?.last_page,
        companiesForEvents: {},
      }
    case COMPANY_LIST_EVENTS_RECEIVED:
      return {
        ...state,
        isFetchingCompanies: false,
        companiesForEvents: action.data
      }
    case COMPANY_LIST_ERROR:
    case COMPANY_LIST_EVENTS_ERROR:
      return {
        ...state,
        isFetchingCompanies: false,
      }
    case COMPANY_LIST_UNLOAD:
      return {
        companies: {},
        companiesForEvents: {},
        isFetchingCompanies: false,
        error: false,
        companiesPage: null,
        companiesLastPage: null,
      }
    default:
      return state;
  }
}

export const company = (state = {
  company: {},
  isFetchingCompany: false,
  isUpdatingCompany: false,
  error: false,
  accessDenied: false,
}, action) => {
  switch (action.type) {
    case COMPANY_REQUEST:
      return {
        ...state,
        isFetchingCompany: true,
      }
    case COMPANY_RECEIVED:
      return {
        ...state,
        isFetchingCompany: false,
        company: action.data.data,
        accessDenied: false,
      }
    case COMPANY_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingCompany: false,
        company: {},
        accessDenied: action.error.response.status === 403
      }
    case CREATE_COMPANY:
      return {
        ...state,
        isFetchingCompany: false,
        company: action.data.data,
        error: false,
        accessDenied: false,
      }
    case CREATE_COMPANY_ERROR:
      return {
        ...state,
        error: true,
        isFetchingCompany: false,
        errorData: action.error,
        accessDenied: false,
      }
    case EDIT_COMPANY:
      return {
        ...state,
        error: false,
        isUpdatingCompany: false,
        isFetchingCompany: false,
        accessDenied: false,
        company: action.data.data
      }
    case EDIT_COMPANY_ERROR:
      return {
        ...state,
        isUpdatingCompany: false,
        isFetchingCompany: false,
        error: true,
        errorData: action.error,
        accessDenied: false,
      }
    case COMPANY_UNLOAD:
      return {
        company: {},
        isUpdatingCompany: false,
        isFetchingCompany: false,
        error: false,
      }
    case EDIT_COMPANY_USER:
      let users = ([...state.company.users.push(action.data.data)]);
      state.company.users = users;
      return {
        ...state,
        isFetchingCompany: false,
        ...state.company,
      }
    case EDIT_COMPANY_USER_ERROR:
      return {
        ...state,
        isFetchingCompany: false,
      }
    case DELETE_COMPANY_USER:
      let companyUsers = ([...state.company.users.filter(obj => {
        return obj.id !== action.data.data.id
      })]);
      state.company.users = companyUsers;
      return {
        ...state,
        isUpdatingCompany: false,
        isFetchingCompany: false,
        error: false,
        ...state.company
      }
    case COMPANY_ROLES_UPDATE_RECEIVED_REQUEST:
      let rolesUsers = ([...state.company.users.map(user => {
        if (user.id === action.data.data[0].id) {
          return user = action.data.data[0];
        }
        return user
      })]);
      state.company.users = rolesUsers;
      return {
        ...state,
        error: false,
        isUpdatingCompany: false,
        isFetchingCompany: false,
        ...state.company
      }
    default:
      return state;
  }
}


export const companyUser = (state = {
  user: {},
  isFetchingCompany: false,
}, action) => {
  switch (action.type) {
    case COMPANY_USER_REQUEST:
      return {
        ...state,
        isFetchingCompany: true
      }
    case COMPANY_USER_RECEIVED:
      return {
        ...state,
        isFetchingCompany: false,
        user: action.data
      }
    case COMPANY_USER_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingCompany: false
      }
    default:
      return state;
  }
}

export const companyUsers = (state = {
  companyUsers: [],
  isFetchingCompanyUsers: false,
}, action) => {
  switch (action.type) {
    case COMPANY_USER_LIST_REQUEST:
      return {
        ...state,
        isFetchingCompanyUsers: true
      }
    case COMPANY_USER_LIST_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingCompanyUsers: false,
        companyUsers: action.data
      }
    case COMPANY_USER_LIST_UNLOAD:
      return {
        ...state,
        companyUsers: [],
        isFetchingCompanyUsers: false,
      }
    default: return state;
  }
}