import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { getWidth } from 'redux-window';
import Header from 'Components/Layout/HeaderHome';
import { isAdmin, isAgency, isAgencyApproved, isLoggedIn, isVenue, isVenueAdmin, token } from "modules/auth";
import SearchBannerNew from 'Components/SearchBannerNew';
import useAxios from 'hooks/useAxios';
import FindPerfectSpace from 'Components/FindPerfectSpace';
import LocationsCarousel from './LocationsCarousel';
import VenueTypes from './VenueTypes';
import InsightCategory from './InsightCategory';
import InsightsCarousel from './InsightsCarousel';
import { ButtonLink, ButtonBrand } from 'Components/Button';
import { Link, useLocation } from 'react-router-dom';
import Loading from 'Components/Loading';
import RegistrationCTARow from './RegistrationCTARow';
import NewsAndOffers from './NewsAndOffers';
import LazyLoad from 'react-lazyload';
import CharityAuction from "./CharityAuction/CharityAuction";
import {fetchAirTableData} from "../../services/airtableService/fetchAirTableData";
import CharityAuctionEnd from "./CharityAuction/CharityAuctionEnd";
import VenueUpgradeBanner from "../../Components/venue/VenueUpgradeBanner";
export const DISPLAY_AUCTION = process.env.REACT_APP_DISPLAY_AUCTION_END ? !!parseInt(process.env.REACT_APP_DISPLAY_AUCTION_END)  :  false;
function useHookWithRefCallback() {
    const ref = useRef(null)
    const location = useLocation()
    const setRef = useCallback(node => {
        if (ref.current) {
            // Make sure to cleanup any events/references added to the last instance
        }

        if (node) {
            if(location.hash && location.hash === '#agency-offers') {
                node.scrollIntoView()
            }
        }

        // Save a reference to the node
        ref.current = node
    }, [])

    return [setRef]
}


function Home({ mobile, showAgency, loggedIn, token, isVenue }) {
  const [, setInsights] = useState([]);
  const [agencyOffersRef] =  useHookWithRefCallback();
  let [airTableData, setAirTableData] = useState([])

    useEffect(() => {
        fetchAirTableData(token).then((response) => {
            setAirTableData(response)
        })

    }, []);
  const { data: insightsData } = useAxios({
    url: 'https://cms.edgevenues.com/wp-json/wp/v2/posts?per_page=5&_embed=1',
  });

  const { loading, data: venueOfTheWeek } = useAxios({
    url: `${window.API}/venue-of-the-week`,
  });

  useEffect(() => {
    insightsData ?
    setInsights(
       insightsData.map(a => ({
              title: a.title.rendered,
              content: a.content,
            excerpt: a.excerpt,
              img:
                  a._embedded &&
                  a._embedded['wp:featuredmedia'] &&
                  a._embedded['wp:featuredmedia'][0]
                      ? a._embedded['wp:featuredmedia'][0].source_url
                      : null,
              top_image: (a.acf && a.acf.top_image) || null,
              link: `/community/${a.slug}`,
            }))

    ) : setInsights([]);
  }, [insightsData]);

  //   const min = 1;
  //   const max = 6;
  //   const n = Math.floor(Math.random() * (+max - +min)) + +min;

  const featureImg = useRef(`url('/img/home-banner6.webp')`);

  if (loading) return <Loading/>;

  return (
      <div>
        <Header/>
        <div
            className="bg-cover bg-no-repeat bg-top"
            style={{
              backgroundImage: featureImg.current,
            }}
        >
          <div className="bg-black-transparent" id="search">
            <div className="container flex justify-center pt-6 pb-4 md:pt-16 md:pb-10">
              <div className="col col-w-full xxl:px-10 flex-grow relative">
                <SearchBannerNew venueOfTheWeek={venueOfTheWeek}/>
              </div>
            </div>
          </div>
        </div>

          {airTableData === false ? null :  <div className="bg-light-off-blue border-b-12">
              <CharityAuction airTableData={airTableData}/>
              </div> }


          {
              DISPLAY_AUCTION ?  <CharityAuctionEnd /> : null
          }


        <div className="bg-off-white border-b-12">
          <div className={!mobile ? 'container' : 'pl-3'}>
            {isVenue ? (
              <VenueUpgradeBanner />
            ) : null}
            <LocationsCarousel
                slides={4}
                url={`${window.API}/destinations-list?uk=1&featured=1`}
            />
          </div>
        </div>
        {!loggedIn && <RegistrationCTARow/>}
        <NewsAndOffers isAgency={showAgency} venueOfTheWeek={venueOfTheWeek} agencyOffersRef={agencyOffersRef} />
        <VenueTypes mobile={mobile}/>
        {/*{(!loggedIn || !showAgency) ? <ExclusiveOffers/> : <VenueOfTheWeek mobile={mobile} venue={venueOfTheWeek}/>}*/}
        <div className="container pt-16 md:pt-30">
          <div className="flex flex-wrap">
            <div className="col w-full lg:col-w-2/3">
              <div className="row">
                <div className="col w-full lg:col-w-1/3">
                  <h1 className="m-0 text-5xl lg:text-4xl xxl:text-5xl">
                    Insights.
                  </h1>
                  <h1 className="m-0 text-5xl lg:text-4xl xxl:text-5xl">
                    Ideas.
                  </h1>
                  <h1 className="m-0 text-5xl lg:text-4xl xxl:text-5xl mb-16">
                    Inspiration.
                  </h1>
                  <p className="font-normal text-2xl leading-tight md:pr-6">
                    We have a wide variety of insights and knowledge available from our community of professional venue finding agents.
                    Help us keep you and your team up to date. Be fully prepared for your event with us.
                  </p>
                </div>
                <div className="col w-full lg:col-w-2/3">
                  <div className="row mb-0 lg:mb-4">
                    <div className="col w-full py-8 lg:py-0 md:col-w-1/2">
                      <InsightCategory
                          img="chat.svg"
                          title="Peer & Industry"
                          content={
                            <div className="text-lg font-normal">
                              <p className="leading-tight">
                                Advice from your peers and colleagues, sharing tips
                                and offering support.{' '}
                              </p>
                              <ButtonLink href="/community">
                                What industry leaders on trending topics say
                              </ButtonLink>
                            </div>
                          }
                      />
                    </div>
                    <div className="col w-full py-8 lg:py-0 md:col-w-1/2">
                      <InsightCategory
                          img="idea.svg"
                          title="Inspiration"
                          content={
                            <div className="text-lg font-normal">
                              <p className="leading-tight">
                                Analysis, case studies and insights to give your events innovation and stimulate creativity{' '}
                              </p>
                              <ButtonLink href="/inspiration">
                                Inspiration to keep you fresh for your next event
                              </ButtonLink>
                            </div>
                          }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col w-full py-8 lg:py-0 md:col-w-1/2">
                      <InsightCategory
                          img="insight.svg"
                          title="Insights & News"
                          content={
                            <div className="text-lg font-normal">
                              <p className="leading-tight">
                                Hear from our experts with their finger on the pulse{' '}
                              </p>
                              <ButtonLink href="/the-word">
                                Catch up on News & Insights
                              </ButtonLink>
                            </div>
                          }
                      />
                    </div>
                    <div className="col w-full py-8 lg:py-0 md:col-w-1/2">
                      <InsightCategory
                          img="microphone.svg"
                          title="Voice"
                          content={
                            <div className="text-lg font-normal">
                              <p className="leading-tight">
                                What’s new, trends, site visits and market information, keeping up to date with industry trends{' '}
                              </p>
                              <ButtonLink href="/voice">Browse Voice</ButtonLink>
                            </div>
                          }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mt-8 md:mt-0 w-full lg:col-w-1/3">
              <InsightsCarousel insights={insightsData} mobile={mobile}/>
            </div>
          </div>
        </div>
        {/*<VenueOfTheWeek mobile={mobile} venue={venueOfTheWeek} />*/}
        <FindPerfectSpace/>
      </div>
  );
}

export default connect(state => ({
  showAgency: (isAgency(state) && isAgencyApproved(state)) || isAdmin(state),
  loggedIn: isLoggedIn(state),
  isVenue: isVenueAdmin(state),
  mobile: getWidth(state) < 768,
  token: token(state),
}))(Home);
