import React, {useEffect, useRef, useState} from "react";
import SiteVisitPreview from "pages/SiteVisits/SingleVenueSiteVisitPreview";
import {FaRegMinusSquare, FaRegPlusSquare,} from "react-icons/fa";
import Loading from "../../Components/Loading";
import LaravelPaginatorWrapper from "../../Components/Pagination/LaravelPaginatorWrapper";
import {token, user} from "../../modules/auth";
import {connect} from "react-redux";
import {getSiteVisitForVenue, getSiteVisitPageForVenue,} from "../../services/siteVisitService/getSiteVisitReports";
import {venueInteraction} from "../../modules/analytics";
import {useOnScreen} from "../../hooks/useOnScreen";
import {VENUE_TAGS} from "../../modules/analytics/tags";

const SiteVisitReports = ({ loading, reports, anchor, venue, mobile, token, venueInteraction }) => {
  const [latestReports, setLatestReports] = useState([]);
  const [reportLoading, setReportLoading] = useState(true);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const ref = useRef();
  const onScreen = useOnScreen(ref, "-100px");

  if (onScreen) {
    venueInteraction({
      type: VENUE_TAGS.VIEW_SITE_VISITS,
      dataScore: venue.data_score,
      id: venue.id,
      impression_id: venue.impression_id
    })
  }

  useEffect(() => {
    // if there's a token the user is authed
    if (token) {
        getSiteVisitForVenue(3, venue.id, token)
            .then(response => {
                setLatestReports(response.data);
            })
            .catch(e => {
                setError(true);
            })
            .finally(() => {
                setReportLoading(false);
            });
    }
  }, [token]);

  const getPage = ({path, page}) => {
      getSiteVisitPageForVenue(3, path, page, venue.id, token)
        .then(response => {
            setLatestReports(response.data)
        })
        .catch(e => {
            setError(true);
        })
        .finally(() => {
            setReportLoading(false);
        });
  };

  const toggle = () => {
    setExpanded(!expanded);
  };

    const renderItems = ({items}) => (
        items.map((report, index) =>
            <SiteVisitPreview key={report.id} {...report} visited={venue} />
        )
    );

  return (
      <div ref={ref}>
        <span className="anchor sr-only" id={anchor}>
            SITE VISIT INSIGHTS
          </span>
        <div className="bg-white mb-4 shadow single-venue__site-visits" id="site-visits">
          <h2
              className={`py-4 ${
                  mobile
                      ? "px-3 text-left w-full flex items-center justify-between"
                      : "px-3 text-left"
              } text-xl ${(expanded || !mobile) &&
              "border-b border-light-blue-grey"}`}
              onClick={toggle}
          >
              SITE VISIT INSIGHTS
            {mobile && !expanded && <FaRegPlusSquare className="text-base" />}
            {mobile && expanded && <FaRegMinusSquare className="text-base" />}
          </h2>
          {(!mobile || (mobile && expanded)) &&
          (loading ? (
              <Loading />
          ) : (
              <div className={'pb-5 pr-5'}>
                  <LaravelPaginatorWrapper {...latestReports} render={renderItems} getPage={getPage}/>
              </div>
          ))}

      </div>
      </div>
  );
};

export default connect(state => ({
    token: token(state),
}), {venueInteraction})(SiteVisitReports);