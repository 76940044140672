import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
//import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {  Checkbox, Textarea } from "Components/Form";
import { ButtonPrimary } from "Components/Button";
import Loading from "Components/Loading";
import {  required } from "modules/validation";
import useService from "hooks/useService";
import { confirmVenueUser } from "services/rfpService";
import RfpProposalActionModal from "./RfpProposalActionModal";

const v = {
  required: required(),
};

const form = "proposal-confirm-venue";

const ConfirmVenueModal = ({ onClose, onConfirm, venue }) => {
//  const { review } = useSelector(getFormValues(form)) || {};
  const [submitData, setSubmitData] = useState(null);
  const { id: rfpId } = useParams();
  const { loading: submitting, data: complete, error } = useService({
    initialLoading: false,
    service: confirmVenueUser,
    trigger: submitData,
    params: submitData,
  });

  const handleConfirm = values => {
    const confirm = false;
    setSubmitData({
      venueId: venue.id,
      rfpId,
      confirm,
      review_items: confirm ? undefined : values.review_items,
      message: confirm ? undefined : values.message,
    });
  };

  useEffect(() => {
    if (complete) {
      onConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  return (
    <RfpProposalActionModal
      form={form}
      title="I'm Interested but..."
      onConfirm={handleConfirm}
      onClose={onClose}
      venue={venue}
      content={
        <div className="relative h-full pb-16">
          {complete ? (
            <p className="text-xl mb-5">
              Thank you.
              <br />
              Your request has been submitted.
            </p>
          ) : (
            <>


                <>
                  <Field
                      name="review_items"
                      label="I would like to review"
                      component={Checkbox}
                      options={[
                        "Price/costs",
                        "The terms and conditions",
                        "The space",
                        "Other",
                      ]}
                      validate={v.required}
                  />
                  <Field
                    label="Comments/Message"
                    name="message"
                    rows={7}
                    component={Textarea}
                    validate={v.required}
                  />
                </>

              {error && (
                <p className="my-5 text-warning">
                  Sorry, there was a problem submitting your request
                </p>
              )}
              <div className="absolute pin-b pin-r">
                <ButtonPrimary
                  type="submit"
                  classes="px-5"
                  style={{ height: 57, lineHeight: "57px" }}
                  disabled={submitting}
                >
                  {submitting ? <Loading inline /> : "Send to venue"}
                </ButtonPrimary>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default ConfirmVenueModal;
