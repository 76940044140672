import axios from "axios";

const setVenueContact = async ({ venueId, venueContactId, rfpId }, token) => {
  try {
    await axios.post(
      `${window.API}/rfp/set-venue-contact`,
      {
        venue_contact_id: venueContactId,
        venue_id: venueId,
        enquiry_id: rfpId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return {
      result: true,
    };
  } catch (error) {
    return { error, result: false };
  }
};

export { setVenueContact };
