import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Date as DateField, LabelledText, WhitePanelWithHeader } from 'ui';
import { getFormValues, reduxForm } from 'redux-form';
import { token } from '../../modules/auth';
import moment from 'moment';
import { ButtonPrimary } from '../Button';
import AgentsNotesModal from './AgentNotesModal';
import { createNote, updateNote } from '../../redux/actions/notes';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { Field } from 'redux-form';

const form = 'rfp-agents-notes-reminder';

const mapStateToProps = (state) => {
  return {
    ...state.notes,
    token: token(state),
  };
};

const mapDispatchToProps = {
  createNote,
  updateNote,
};


const RfpAgencyNotes = ({ expanded, notes, token, createNote, rfpId, event, updateNote }) => {
  const [newNoteModal, setNewNoteModal] = useState(false);
  const [showReminderFields, setShowReminderFields] = useState(false);
  const [reminderDateTime, setReminderDateTime] = useState(moment());
  const values = useSelector(getFormValues(form));

  const onNewNote = formValues => {
    return createNote(rfpId, formValues, token).then(setNewNoteModal(false));
  };
  return (
      <WhitePanelWithHeader
          title={'Agent Notes'}
          collapsible
          defaultOpen={expanded}
      >
        <div>
          {Object.keys(notes).length > 0 && notes.map(note => {

            const startDatetime = reminderDateTime.set('hours', 9);
            const endDatetime = startDatetime.clone().set('hours', 17);
            const duration = moment.duration(endDatetime.diff(startDatetime)).asHours();
            const calendarEvent =  {
              description: note.note,
              startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
              duration,
              endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
              title: 'Chaser for RFP ID: ' + note.rfp_enquiry_id,
            };


            const setReminder = () => {
              let formData = new FormData();
              formData.append("_method", "patch");
              formData.append('calendar_reminder_datetime', startDatetime.format('YYYY-MM-DD HH:mm:ss'));
              updateNote(note.rfp_enquiry_id,  note.id, formData, token);
            };

            const ATCDropdown = (args) => (
                <ul className="list-reset bg-white border-t border-l border-r border-primary w-48 text-gray-900 text-sm font-medium" onClick={setReminder}>
                  {args.children.map((link, i) => (
                      <li key={i}>{link}</li>
                  ))}
                </ul>
            );

            const ATCWrapper = (args) => (
                <ButtonPrimary onClick={args.onClick}>
                  {args.children}
                </ButtonPrimary>
            );


            const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);

            return (
                <div key={note.id}>
                  <LabelledText
                      label={`${note.created_by}`}
                      div
                      text={
                        <div className={'flex mb-5 border-dashed border-b border-black'}>
                          <div className="w-3/4">
                            <p className={'p-2 whitespace-pre-line'}>
                              {note.note}
                            </p>
                          </div>
                          <div className="w-1/4">
                            <LabelledText
                                label="Created At"
                                text={
                                  <>
                                    <span>{moment(note.created_at).format('ddd Do MMM YYYY HH:mm')}</span>
                                  </>
                                }
                            />
                            <div className={'mb-5'}>{note.user ?
                                <p className="mr-2">Reminder set for {note.user.first_name} {note.user.surname} on {moment(note.calendar_reminder_datetime).format('DD/MM/YYYY')}</p>
                                :
                                (
                                    !showReminderFields ?
                                        <ButtonPrimary onClick={() => setShowReminderFields(true)}>
                                          Set Reminder
                                        </ButtonPrimary>
                                        :
                                        <>
                                          <Field
                                              label="Reminder Date"
                                              name={`calendar_reminder_datetime_${note.id}`}
                                              component={DateField}
                                              onChange={v => {
                                                setReminderDateTime(v);
                                              }}
                                          />
                                          <AddToCalendarDropdown
                                              linkProps={{
                                                className: 'block px-4 py-2 border-b border-primary w-full text-primary font-bold cursor-pointer hover:bg-primary hover:text-white',
                                              }}
                                              note={note}
                                              event={calendarEvent}
                                          />
                                        </>
                                )
                            }</div>
                          </div>


                        </div>
                      }
                  />
                </div>
            );
          })}
          <ButtonPrimary onClick={() => setNewNoteModal(true)}>
            Add Note
          </ButtonPrimary>
          {newNoteModal && (
              <>
                <AgentsNotesModal
                    onSubmit={onNewNote}
                    rfpId={rfpId}
                    onClose={() => setNewNoteModal(false)}
                />
              </>
          )}
        </div>
      </WhitePanelWithHeader>
  );
};

export default reduxForm({
  form,
})(connect(mapStateToProps, mapDispatchToProps)(RfpAgencyNotes));
