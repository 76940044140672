import React, {memo} from 'react';
import {connect, useSelector} from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {format} from 'date-fns';
import {getWidth} from 'redux-window';
import {ReactComponent as SliderPrev} from 'svg/slider-prev.svg';
import BackgroundImage from '../../Components/General/BackgroundImage';
import LazyLoad from 'react-lazyload';
import {generalInteraction} from "../../modules/analytics";

const PrevArrow = ({onClick}) => {
  const mobile = useSelector(state => getWidth(state) < 768);
  return (
    <SliderPrev
      className="absolute-center pointer z-10 hover:text-green transition"
      style={{left: mobile ? '10px' : '-25px'}}
      onClick={onClick}
    />
  );
};

const NextArrow = ({onClick}) => {
  const mobile = useSelector(state => getWidth(state) < 768);
  return (
    <SliderPrev
      className="absolute-center-y rotate-180-center-y pointer z-10 hover:text-green transition"
      style={{right: mobile ? '10px' : '-25px'}}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow/>,
  prevArrow: <PrevArrow/>,
  // responsive: [
  //     {
  //         breakpoint: 992,
  //         settings: {
  //             arrows: false,
  //         }
  //     }
  // ]
};

const Link = ({text, link}) => {
  return (
    <p className="link-more">
      <a href={link}
         className="more-link">
        Continue reading: <span className="screen-reader-text" dangerouslySetInnerHTML={{__html: text}}/></a>
    </p>
  );
};

const InsightCarouselItem = ({item, clickLinkInteraction}) => {
  const mobile = useSelector(state => getWidth(state) < 768);
  return (
    <div className="px-8 md:px-4">
      {item && item.acf && item.acf.top_image && mobile &&
        <LazyLoad height={150} once offset={100}>
          <BackgroundImage className={'bg-cover h-24 bg-center w-24 mr-4 flex-no-shrink'}
                           imagePath={item.acf.top_image.replace(/.*\/\/[^\/]*/, '').substr(1)}
                           edits={{
                             'resize': {
                               'width': 440,
                               'height': 150,
                               'fit': 'cover',
                             },
                           }}
                           nonDiv={true}
                           alt={item.title.rendered}
                           width={440}
                           height={150}/>
        </LazyLoad>
      }
      {item && item.acf && item.acf.top_image && !mobile &&
        <LazyLoad height={150} once offset={300}>
          <BackgroundImage className={'bg-cover h-24 bg-center w-24 mr-4 flex-no-shrink'}
                           imagePath={item.acf.top_image.replace(/.*\/\/[^\/]*/, '').substr(1)}
                           edits={{
                             'resize': {
                               'width': 650,
                               'height': 150,
                               'fit': 'cover',
                             },
                           }}
                           nonDiv={true}
                           width={650}
                           height={150}
                           alt={item.title.rendered}/>
        </LazyLoad>
      }

      <p className="font-semibold text-light-text text-base mt-2 mb-3">
        {format(new Date(item.date), 'DD MMM YYYY').toUpperCase()}
      </p>
      <h2 className="text-2rem mb-4"
          dangerouslySetInnerHTML={{__html: item.title.rendered}}/>
      <div
        className="text-lg font-normal"
        dangerouslySetInnerHTML={{__html: item.excerpt}}
      />
      <div className={'text-lg font-normal pt-5'} onClick={() => clickLinkInteraction(item.title.rendered, item.link)}>
        <Link
          link={item.link}
          text={item.title.rendered}
        />
      </div>
    </div>
  );
};

const InsightsCarousel = ({insights, generalInteraction}) => {
  if (insights) {
    insights = insights.map(a => {
      let excerpt = a.excerpt.rendered.match('<p>(.*?)</p>');
      excerpt = excerpt && excerpt.length > 1 ? excerpt[1] : null;
      let link = `/community/${a.slug}`;
      return {
        ...a,
        excerpt,
        link,
      };
    });
  }

  const clickLinkInteraction = (title, url) => {
    generalInteraction({
      type: "general_home_insights_carousel_article_click",
      data: url
    })
  }

  return (
    <Slider {...settings} className="location-carousel">
      {insights &&
        insights.map(item => <InsightCarouselItem item={item} key={item.id}
                                                  clickLinkInteraction={clickLinkInteraction}/>)}
    </Slider>
  );
};

export default connect(null, {generalInteraction})(memo(InsightsCarousel));
