import React from "react";
import { LabelledText, WhitePanelWithHeader } from "ui";

const ClientDetails = ({
                         name,
                         email,
                         phone,
                         clientName = false,
                         label = "Client Details",
                         original_client = false,
                       }) => {
  return (
    <WhitePanelWithHeader title={label} icon="far fa-user">
      {clientName && (
        <LabelledText label="Client Name" text={clientName} />
      )}
      <LabelledText label="Name" text={name} />
      {original_client && (
        <LabelledText label="Original Client" text={original_client} />
      )}
      <LabelledText label="Email" text={email} />
      <LabelledText label="Phone" text={phone} />
    </WhitePanelWithHeader>
  );
};

export default ClientDetails;
