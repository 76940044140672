import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form";
import { Input } from "Components/Form";
import Validate from "../Validation/NewSiteVisitReportValidation";
import { ButtonBrand } from "Components/Button";
import NavButtons from "../Components/NavButtons";

const renderContacts = ({ fields, meta: { touched, error } }) => {
  return (
    <>
      {fields.map((contact, index) => (
        <div
          className={`flex row py-4 mb-5 ${index % 2 !== 0 && "bg-off-white"}`}
          key={index}
        >
          <div className="col col-w-full md:col-w-1/2 lg:col-w-1/5 ">
            <Field
              name={`${contact}.name`}
              type="text"
              component={Input}
              label="Name"
              validate={Validate.contact_name}
              wrapperClassName=""
            />
          </div>
          <div className="col col-w-full md:col-w-1/2 lg:col-w-1/5 ">
            <Field
              name={`${contact}.position`}
              type="text"
              component={Input}
              label="Position/Title"
              validate={Validate.contact_position}
            />
          </div>
          <div className="col col-w-full md:col-w-1/2 lg:col-w-1/5 ">
            <Field
              name={`${contact}.telephone`}
              type="text"
              component={Input}
              label="Telephone Number"
              validate={Validate.contact_telephone}
            />
          </div>
          <div className="col col-w-full md:col-w-1/2 lg:col-w-1/5 ">
            <Field
              name={`${contact}.email`}
              type="text"
              component={Input}
              label="Email"
              validate={Validate.contact_email}
            />
          </div>
          <div className="col col-w-full md:col-w-1/2 lg:col-w-1/5 mb-5 flex justify-center">
            <button
              type="button"
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this contact?"
                  )
                ) {
                  fields.remove(index);
                }
              }}
              className="text-warning"
            >
              Delete
              <i className="ml-3 fas fa-trash-alt" />
            </button>
          </div>
        </div>
      ))}
      <div className="text-center">
        <ButtonBrand onClick={() => fields.push({})}>Add Contact</ButtonBrand>
      </div>
    </>
  );
};

const AddContact = props => {
  return (
    <div className={`bg-white ${props.className}`}>
      <h2 className="flex justify-between mb-6">
        <span>Add Contacts</span>
      </h2>

      <form onSubmit={props.handleSubmit}>
        <FieldArray name="contacts" component={renderContacts} />

        <NavButtons {...props} />
      </form>
    </div>
  );
};

export default reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(AddContact);
