import { required, maxLength, email } from "modules/validation";

export default {
  visited_type: required(),
  visit_date_from: required(),
  visit_date_to: required(),
  reason_for_visit: required(),
  impressions: [required(), maxLength(2000)],
  considerations: [required(), maxLength(2000)],
  contact_name: [required(), maxLength(100)],
  contact_position: [required(), maxLength(50)],
  contact_telephone: [required(), maxLength(20)],
  contact_email: [required(), maxLength(255), email],
  selected_venue: required(),
  media_link_name: required(),
  media_link_path: required(),
};
