import React from "react";
import { shape, number, string, func, arrayOf } from "prop-types";
import Modal from "Components/Modal";
import { ButtonSpan, ButtonBrand } from "Components/Button";
import { Label } from "Components/Form";
import TrueFalseList from "Components/TrueFalseList";
import ExclusionDatesBanner from "../../Components/PriceBanding/ExclusionDatesBanner";

const options = [
  {
    text: "Arrival Tea & Coffee",
    value: 1,
  },
  {
    text: "Mid Morning Break",
    value: 2,
  },
  {
    text: "Lunch",
    value: 3,
  },
  {
    text: "Afternoon Break",
    value: 4,
  },
  {
    text: "Dinner",
    value: 5,
  },
  {
    text: "Accommodation",
    value: 6,
  },
];
export default class PricingPackageModal extends React.Component {
  static propTypes = {
    closeModal: func.isRequired,
    enquire: func.isRequired,
    package: shape({
      package_name: string.isRequired,
      venue_package_id: number.isRequired,
      price_per_delegate_inc_vat: string.isRequired,
      minimum_numbers: number,
      includes_flipchart: number,
      includes_free_wifi: number,
      includes_main_room_hire: number,
      includes_paper_and_pencils: number,
      includes_other: string,
      additional_notes: string,
      includes: arrayOf(
        shape({
          is_included: number,
          include_name: string,
          include_details: string,
          additional_info: string,
        })
      ).isRequired,
    }).isRequired,
  };

  render() {
    const {
      package_name,
      // venue_package_id,
      price_per_delegate_ex_vat,
      minimum_numbers,
      includes_flipchart,
      includes_free_wifi,
      includes_main_room_hire,
      includes_paper_and_pencils,
      includes_other,
      includes,
      additional_notes,
      updated_at
    } = this.props.package;

    const { venue, title} = this.props;
    return (
      <Modal>
        <div className="modal">
          <div className="modal-inner" style={{ width: "75%" }}>
            <div className="flex flex-col justify-between relative">
              <div className="flex justify-end">
                <ButtonSpan
                  className="pin-t pin-r"
                  onClick={this.props.closeModal}
                >
                  Close
                  <i className="fas fa-times ml-3" />
                </ButtonSpan>
              </div>
              <div>
                <p className="font-heading font-normal">
                  Venue: <span className="font-bold">{venue}</span>
                </p>
                <h1 className="flex flex-wrap mb-2 bg-light-grey border-b-4 border-green text-edge-blue p-4 justify-between">
                  {title}
                  {price_per_delegate_ex_vat ? (
                      <div className={'flex'}>
                          <span className="text-black mb-1 ml-2 md:mb-0">
                            {this.props.priceBanding &&  !this.props.venuePriceBranding.is_international ?
                                    <div>
                                      {this.props.priceBanding &&  this.props.priceBanding.min_price ? <span>From </span> : <></>}
                                      <span dangerouslySetInnerHTML={{__html: this.props.currency}}/>{this.props.priceBanding && (<span>{ this.props.priceBanding.min_price}</span>)} {this.props.priceBanding &&  this.props.priceBanding.max_price ? <span>to</span> : <></>} {this.props.priceBanding && this.props.priceBanding.max_price && (<span><span dangerouslySetInnerHTML={{__html: this.props.currency}}/>{this.props.priceBanding.max_price}</span>)}
                                      {!this.props.priceBanding &&  <span>POA</span>}
                                    </div>
                                :
                                <div>
                                  <span>POA</span>
                                </div>
                            }

                          </span>
                        <ButtonBrand
                            small
                            classes="px-4 text-lg mt-2 md:mt-0 md:ml-2"
                            onClick={this.props.enquire}
                        >
                          Enquire Now
                        </ButtonBrand>
                      </div>

                  ) : null}
                </h1>
                <p className="text-xs mb-5 font-normal uppercase">
                  including VAT &amp; subject to availability <br/> <ExclusionDatesBanner venue={this.props.venueSlug} listView={true} /> {this.props.priceBandingExclusionText ?  <span><br/> plus {this.props.priceBandingExclusionText}</span> : null}
                </p>
                <p className="text-xs mb-5 font-normal uppercase">
                </p>
                <div className="md:row">
                  <div className="md:col  col-w-full md:col-w-1/3">
                    <h3 className="mb-4">Details</h3>
                    <div className="mb-3 border-t border-grey pt-4">
                      <Label text="Minimum numbers:" />
                      <p className="mb-1">{minimum_numbers}</p>
                    </div>
                    <div className="mb-3 border-t border-grey pt-4">
                      <Label text="Included equipment:" />
                      <TrueFalseList
                        items={[
                          {
                            name: "Flipchart",
                            value: !!includes_flipchart,
                          },
                          {
                            name: "Free wifi",
                            value: !!includes_free_wifi,
                          },
                          {
                            name: "Main room hire",
                            value: !!includes_main_room_hire,
                          },
                          {
                            name: "Stationery",
                            value: !!includes_paper_and_pencils,
                          },
                        ]}
                      />
                    </div>
                    {includes_other ? (
                      <div className="mb-3 border-t border-grey pt-4">
                        <Label text="Other included equipment:" />
                        <p>{includes_other}</p>
                      </div>
                    ) : null}
                    {additional_notes ? (
                      <div className="mb-3 border-t border-grey pt-4">
                        <Label text="Additional notes" />
                        <p>{additional_notes}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="md:col col-w-full md:col-w-2/3">
                    <div className="md:pl-3">
                      <h3 className="mb-4">Included Hospitality</h3>
                      {includes.map(item => {
                        if (!item.is_included) {
                          return <div key={item.package_include_type_id} />;
                        }
                        const option = options.find(
                          o => o.value === item.package_include_type_id
                        );
                        const name = option ? option.text : "Unknown";
                        return (
                          <div
                            key={item.package_include_type_id}
                            className="flex flex-wrap mb-3 border-t border-grey pt-4"
                          >
                            <div className="md:col col-w-full md:col-w-1/3">
                              <Label text={name} />
                            </div>
                            <div className="md:col col-w-full md:col-w-2/3">
                              <div>
                                <p>{item.include_details}</p>
                                <p>{item.additional_info}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
