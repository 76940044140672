import moment from 'moment';
import React from "react";
import {isAgency, isInternal} from "../../modules/auth";
import {connect} from 'react-redux';

const mapStateToProps = (state, props) => {
    return {
        isInternal: isInternal(state),
        isAgency: isAgency(state),
    };
};

let RFPCancelMessage = ({enquiry, chargesApply = false, isAgency, isInternal}) => {


    if(!enquiry.brief.cancellation_agency_note && !enquiry.brief.cancellation_date && !enquiry.brief.cancelled_by && !enquiry.brief.cancellation_reason )
    {
        return null;
    }

    return (
        <React.Fragment>
            <div className='bg-warning px-3 py-6 my-5 block font-bold text-white'>
                <p className={'mb-0'}><i className="fas text-2lg fa-exclamation-triangle mr-3"/>
                    {`This enquiry was cancelled at ${moment(enquiry.brief.cancellation_date).format('DD/MM/YYYY')} by ${enquiry.brief.cancelled_by.first_name} ${enquiry.brief.cancelled_by.surname} for the following reason: ${enquiry.brief.cancellation_reason}`}
                </p>
                {chargesApply ? <p className={'mt-2 mb-0'}>Please note: <strong>Cancellation Charges May Apply</strong></p> : null}
                {enquiry.brief.cancellation_agency_note && (isAgency || isInternal)?

                        <p className={'mt-2 mb-0'}><i className="fas text-2lg fa-edit mr-3"/> Internal Agency Note: {enquiry.brief.cancellation_agency_note }</p>
                     : null
                }
            </div>
        </React.Fragment>
    )

}
export default connect(mapStateToProps, null)(RFPCancelMessage);
