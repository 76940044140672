import pick from "lodash/pick";
import isPlainObject from "lodash/isPlainObject";

// NEEDS UNIT TEST NOW!!!

export default (
  data,
  fields = [],
  fieldsWithText = [],
  initializeEmptyRow = [],
  pluckTheIds = [],
  pluckTheEquipmentIds = []
) => {
  const raw = {
    ...pick(data, fields),
    ...pick(data, fieldsWithText),
    ...pick(data, initializeEmptyRow),
  };
  const values = {};
  Object.keys(raw).forEach(key => {
    // set IDs from objects (checkboxes, selects)
    if (!!~pluckTheIds.indexOf(key)) {
      values[key] = Array.isArray(raw[key]) ? raw[key].map(v => v.id) : [];

      // fuckin ell
    } else if (!!~pluckTheEquipmentIds.indexOf(key)) {
      values[key] = Array.isArray(raw[key])
        ? raw[key].map(v => v.equipment_id)
        : [];

      // for live search but maybe a bit risky
    } else if (isPlainObject(raw[key]) && !raw[key].mime_type) {
      values[key] = raw[key].name;
      values[`${key}_id`] = raw[key].id;

      // specifically for multi live searches
    } else if (!!~fieldsWithText.indexOf(key)) {
      values[key] = raw[key].map(item => item.id);
      values[`${key}_text`] = raw[key].map(item => item.name);

      // show first field row
    } else if (
      !!~initializeEmptyRow.indexOf(key) &&
      Array.isArray(raw[key]) &&
      !raw[key].length
    ) {
      values[key] = [{}];

      // default
    } else {
      values[key] = raw[key];
    }
    if (values[key] === null) delete values[key];
  });
  return values;
};
