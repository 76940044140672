import axios from "axios";

export const denyRFP = async (
    { declineReasonId, declineReasonText, rfpId, isDelete },
    token
) => {
    if (declineReasonId) {
        try {
            const response = await axios.post(
                `${window.API}/rfp/${rfpId}/deny`,
                {
                    decline_reason_id: declineReasonId,
                    decline_reason_text: declineReasonText ? declineReasonText : null ,
                    rfpId: rfpId,
                    is_delete: isDelete
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return { result: response.data };
        } catch (error) {
            return { error };
        }
    }
};

