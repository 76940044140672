import React from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { getWidth } from "redux-window";
import { withRouter } from "react-router-dom";
import { isLoggedIn } from "modules/auth";
import CreateShortlist from "Components/Shortlists/Create";
import FlagAnIssue from "./FlagAnIssue";
import { venueInteraction, generalInteraction } from "../../modules/analytics";
import YourSearches from "Components/Shortlists/YourSearches";

class SearchWidgets extends React.Component {
  render() {
    const { searchForm, isLoggedIn, results, search, desktop } = this.props;
    return (
      <>
        <div
          className={`pin-b pin-l lg:static pin-r z-50${
            searchForm === "advanced-search" ? " lg:py-3 lg:px-0 lg:mb-0" : ""
          }`}
        >
          <CreateShortlist form={this.props.form} searchForm={searchForm} />
        </div>
        {desktop && (
          <div>
            {isLoggedIn && (
              <YourSearches
                searchForm={searchForm}
                results={results}
                search={search}
                smallTitle={this.props.smallTitle}
              />
            )}
            <div className="mt-4">
              <div onClick={() => {
                this.props.generalInteraction({type: "general_feedback_click"})
              }}>
                <FlagAnIssue />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => ({
      desktop: getWidth(state) > 991,
      active: getFormValues(props.form)(state),
      isLoggedIn: isLoggedIn(state),
    }),
    { venueInteraction, generalInteraction }
  )(SearchWidgets)
);
