import convertPriceToSourceCurrencyValue from "../../../helpers/convertPriceToSourceCurrencyValue";

const mapSummaryItemsFromProposalData = (summary, isSourceCurrency = false) => {
  const sections = [
    { name: "_conference_packages", step_id: 2 },
    { name: "_meeting_rooms", step_id: 3 },
    { name: "_equipment", step_id: 4 },
    { name: "_catering", step_id: 5 },
    { name: "_accommodation", step_id: 6 },
    { name: "", step_id: 9 },
  ];

  sections.forEach(section => {
    const summary_item = summary && summary.summary_items.find(item => item.step_id === section.step_id);
    if (summary_item) {
      summary_item.total_exc_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, summary_item, "total_exc_vat");
      summary_item.reduced_from_exc_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, summary_item, "reduced_from_exc_vat");
      summary_item.total_inc_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, summary_item, "total_inc_vat");
      summary_item.reduced_from_inc_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, summary_item, "reduced_from_inc_vat");
    }
  });

  return summary;
};

export default mapSummaryItemsFromProposalData;
