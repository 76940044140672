import React from "react";
import { Link } from "react-router-dom";
import Loading from "../../../../Components/Loading";
import ItemList from "../../../../Components/Layout/ItemList";
import { preAddUrl } from "../../../../utils";

let CorporateAccountDocumentByCategoryList = ({
                                                isFetching,
                                                documents,
                                                userRoles,
                                                accountId,
                                                category,
                                                withoutSidebar,
                                                token,
                                                isCorp = false,
                                              }) => {
  if (isFetching) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  let back = () => {
    if (!withoutSidebar) {
      return null;
    }
    return <Link to={`/admin/corporate-accounts/${accountId}/view-documents`}><p
      className={"text-lg font-normal text-black pt-5"}><i className="fas fa-arrow-left"></i> Back To Documents</p>
    </Link>;
  };
  if (typeof documents === "undefined" || (Object.entries(documents).length === 0)) {
    return (
      <>
        {back()}
        <h1 className="my-5">Documents: {category ? category.name : ""}</h1>
        <div className="p-3 bg-lighter-blue w-full my-6">
          <p className={"mb-0"}><i className="fas text-2lg fa-info-circle mr-3" />
            There are currently no documents in this category available to view
          </p>
        </div>
      </>
    );
  }

  return (
    <div>
      {back()}
      <h1 className="my-5">Documents: {category ? category.name : ""}</h1>
      <div className="flex flex-row flex-wrap -mx-2">
        {documents.map(document => {
          let documentLink = document.file ? document.file.url : preAddUrl(document.external_link);
          let rolesThatCanView = document.roles_that_can_view ? JSON.parse(document.roles_that_can_view) : "noRoles";
          if (rolesThatCanView && rolesThatCanView !== "noRoles") {
            rolesThatCanView.push("agency_administrator");
            rolesThatCanView.push("agency_user");
            rolesThatCanView.push("edge-admin");
            const found = userRoles.some(r => rolesThatCanView.includes(r));
            if (!found) {
              return null;
            }
          }
          return (
            <ItemList
              corporateStyle={isCorp}
              key={document.id}
              title={document.name}
              link={documentLink}
              externalLink={document.external_link}
              download={!document.external_link}
              buttonText={`${document.edge_document_id ? "Download File" : "View Link"}`}
              icon={`${document.edge_document_id ? "fal fa-file-download" : "fal fa-external-link"}`}
              newTab={true}
              token={token}
              type={document.file ? document.file.extension : null}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CorporateAccountDocumentByCategoryList;