import axios from "axios";
import { SubmissionError } from "redux-form";

const agencyCompanyDomains = async ( token) => {
    try {
        const response =   await axios.get(
            `${window.API}/company/company-domains`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return { result: response.data };
    } catch (error) {

    }
};

export { agencyCompanyDomains };
