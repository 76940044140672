import React from "react";
import classNames from "classnames";
import { string, object, bool, oneOf, oneOfType, number } from "prop-types";
import Label from "./Label";
import Error from "./Error";

export const inputStyles = {
  className: classNames([
    "px-2",
    "py-0",
    "border",
    "w-full",
    "outline-none",
    "font-normal",
    "text-black",
    "text-sm",
    "focus:border-primary",
    "bg-white",
    "border-grey-md",
  ]),
  style: {
    lineHeight: "28px",
    height: "28px",
  },
};

const Input = ({
  id,
  meta: { touched, error, warning },
  input,
  label,
  type,
  placeholder,
  size,
  wrapperClassName,
  labelClassName,
  inputClassName,
  additionalInputClass,
  additionalReadonlyClass,
  autoComplete,
  min,
  max,
  step,
  autoFocus,
  readOnly,
  readOnlyBold,
  readOnlyNoClasses,
  preValue = "",
  hidden = false,
}) => {
  let style = {
    lineHeight: "28px",
    height: "28px",
  };
  let wrapperClass = "max-w-full mb-5";
  if (size) {
    wrapperClass += " mr-6 ";
    switch (size) {
      case "xs":
        wrapperClass += "w-16";
        break;
      case "sm":
        wrapperClass += "w-32";
        break;
      case "md":
        wrapperClass += "w-64";
        break;
      case "lg":
        wrapperClass += "w-80";
        break;
      default:
        wrapperClass += "";
    }
  }
  return (
    <div className={wrapperClassName || wrapperClass}>
      {label && <Label name={input.name} text={label} />}
      {readOnly ? (
        <p
          className={classNames(
              readOnlyNoClasses ? "mb-0 text-xs h-6 px-2" : "mb-0 bg-white border border-grey-md text-xs h-6 px-2",
            {
              "font-bold": readOnlyBold,
            },
            additionalReadonlyClass
          )}
        >
          {input.value ? preValue : ""}
          {input.value}
        </p>
      ) : (
        <div className="flex">
          <input
            {...input}
            hidden={hidden}
            id={id}
            placeholder={placeholder}
            autoComplete={autoComplete}
            type={type}
            style={style}
            min={min}
            max={max}
            step={step}
            autoFocus={autoFocus}
            className={
              inputClassName ||
              classNames(
                [
                  type === "number" ? "pl-2" : "px-2",
                  "py-0",
                  "border",
                  "border-grey-md",
                  "w-full",
                  "outline-none",
                  "font-normal",
                  "text-black",
                  "text-xs",
                  "focus:border-primary",
                  "bg-white",
                ],
                additionalInputClass
              )
            }
          />
        </div>
      )}
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
};

Input.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]),
  id: string,
  type: string,
  placeholder: string,
  wrapperClassName: string,
  labelClassName: string,
  inputClassName: string,
  size: oneOf(["xs", "sm", "md", "lg"]),
  autoComplete: string,
  additionalInputClass: string,
  min: number,
  max: number,
  step: number,
  readOnly: bool,
  hidden: bool,
};

export default Input;
