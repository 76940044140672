import React from "react";
import { reduxForm } from "redux-form";
import Modal from "Components/Modal";
import { ButtonSpan } from "Components/Button";

const RfpProposalActionModal = ({
  content,
  onClose,
  onConfirm,
  title,
  venue,
  handleSubmit,
}) => {
  return (
    <Modal>
      <div className="modal">
        <div
          className="modal-inner"
          style={{
            width: 1200,
            maxWidth: "90%",
          }}
        >
          <ButtonSpan
            className="m-3 absolute pin-t pin-r z-10"
            onClick={onClose}
          >
            <i className="fas fa-times-circle fa-2x ml-3" />
          </ButtonSpan>
          <form onSubmit={handleSubmit ? handleSubmit(onConfirm) : onConfirm}>
            <h2 className="mb-3 text-3xl">{title}</h2>
            <div className="md:flex">
              <div className="md:w-1/2 lg:w-1/3 md:pr-8 lg:pr-16">
                <div className="bg-off-white p-3">
                  <div
                    className="re-16/9 w-full bg-no-repeat bg-cover bg-center mb-3"
                    style={
                      venue.image
                        ? {
                            backgroundImage: `url(${window.API}/venues/${venue.slug}/images/${venue.image})`,
                          }
                        : null
                    }
                  />
                  <h3 className="mb-3 uppercase">{venue.name}</h3>
                  <p>{[venue.town, venue.county].filter(Boolean).join(", ")}</p>
                </div>
              </div>
              <div className="md:w-1/2 lg:w-2/3 mt-5">{content}</div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

const WithForm = reduxForm()(RfpProposalActionModal);
const ExportedComponent = props => {
  return props.form ? (
    <WithForm {...props} />
  ) : (
    <RfpProposalActionModal {...props} />
  );
};

export default ExportedComponent;
