import React, {useState, useEffect} from 'react'
import {getExclusionDates} from "../../../../../services/priceBandingService/manageExclusionDates";
import {token} from "../../../../../modules/auth";
import {connect} from "react-redux";
import moment from 'moment';
import {FaExclamationTriangle} from "react-icons/fa";

const mapDispatchToProps = {};

const mapStateToProps = state => ({
    token: token(state),
});

let ExclusionDatesWarning = ({fromDate, toDate, venueSlug, token, padding = false}) => {
    const [exclusionDates, setExclusionDates] = useState([]);
    const [shouldWarn, setShouldWarn] = useState(false)


    useEffect(() => {
        getExclusionDates(token, venueSlug).then((response) => {
            setExclusionDates(response)
        })


    }, []);
    useEffect(() => {
        let matchingDates = exclusionDates.filter((date) => {
            let startDateFormatted = moment(date.start_date, "YYYY/MM/DD").set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
            let endDateFormatted =moment(date.end_date, "YYYY/MM/DD").set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
            if(fromDate) {
                let fromDateFormatted;
                if(!fromDate.isValid){

                    fromDateFormatted = moment(fromDate, 'YYYY-MM-DD').set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                }else{
                    fromDateFormatted = fromDate.set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                }
                return fromDateFormatted.isBetween(startDateFormatted, endDateFormatted, null, [])
            }
            return false;
        })
        if (!matchingDates || matchingDates.length === 0) {
            matchingDates = exclusionDates.filter((date) => {
                let startDateFormatted = moment(date.start_date, "YYYY/MM/DD").set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                let endDateFormatted =moment(date.end_date, "YYYY/MM/DD").set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                if(toDate) {
                    let toDateFormatted ;
                    if(!toDate.isValid){
                        toDateFormatted = moment(toDate, 'YYYY-MM-DD').set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                    } else{
                        toDateFormatted = toDate.set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                    }
                    return toDateFormatted.isBetween(startDateFormatted, endDateFormatted, null, [])
                }
                return false;
            })
        }
        if(!matchingDates || matchingDates.length === 0) {
            matchingDates = exclusionDates.filter((date) => {
                let startDateFormatted = moment(date.start_date, "YYYY/MM/DD").set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                let endDateFormatted =moment(date.end_date, "YYYY/MM/DD").set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})

                if(fromDate && toDate)
                {

                            let fromDateFormatted;
                            let toDateFormatted;
                            if(!fromDate.isValid){

                                fromDateFormatted = moment(fromDate, 'YYYY-MM-DD').set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                            }else{
                                fromDateFormatted = fromDate.set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                            }

                            if(!toDate.isValid){
                                toDateFormatted = moment(toDate, 'YYYY-MM-DD').set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                            }else{
                                toDateFormatted = toDate.set({"hour": 0, "minute": 0, "second":0, 'millisecond': 0})
                            }


                    let match = startDateFormatted.isBetween(fromDateFormatted, toDateFormatted, null, [])
                    if(!match) {
                       match =  endDateFormatted.isBetween(fromDateFormatted, toDateFormatted, null, [])
                    }
                    return match
                }
                return false;
            })
        }

        if (matchingDates.length > 0) {
            setShouldWarn(true);
        } else {
            setShouldWarn(false);
        }
    }, [fromDate, toDate, exclusionDates]);

    if (!fromDate) {
        return <></>
    }

    if (!shouldWarn) {
        return <></>
    }
    return (
        <span className={`text-red ${padding? padding : null}`} title={'May not apply due to chosen dates'}><FaExclamationTriangle/></span>
    )


}

export default connect(mapStateToProps, mapDispatchToProps)(ExclusionDatesWarning);
