import {connect} from "react-redux";
import {token} from "../../../../modules/auth";
import {
    fetchSalesSummaryItems, fetchSnapShotFiguresItems,
    salesSummaryItemsUnload, snapShotFiguresItemsUnload,
} from "../../../../redux/actions";
import {getFormValues} from "redux-form";
import {useEffect} from "react";
import React from "react";
import {Cell, GroupHeader, RowHeader, TR} from "../clean-table";
import {detailedEngagements} from "../../../../modules/analytics";
import {createRangeYearToDate} from "../date-utils";
import getDatesArray from "../../../../modules/analytics/get-dates-array";
import {ButtonBrand} from "../../../../Components/Button";

const mapStateToProps = (state, props) => {
    const v = getFormValues("analytics")(state) || {};
    let dates = getDatesArray(createRangeYearToDate().start, createRangeYearToDate().end);
    return {
        token: token(state),
        salesSummaryItems: state.reportingFigures.salesSummaryItems,
        isFetching: state.reportingFigures.isFetching,
        stats: detailedEngagements(v.venue_id, createRangeYearToDate().start, createRangeYearToDate().end)(state),
        venues: v.venues,
        startDate: typeof props.startDate !== 'undefined' ? props.startDate : v.start_date,
        endDate:  typeof props.endDate !== 'undefined' ? props.endDate :v.end_date,
    }
}

const mapDispatchToProps = {
    fetchSalesSummaryItems,
    salesSummaryItemsUnload,
}

const CorporateViewers = (props) => {
    const PAGE_SIZE = 20;
    const [showAll, setShowAll] = React.useState(false);
    useEffect(() => {
        props.fetchSalesSummaryItems(props.venues, props.startDate, props.endDate, props.type, props.token)
    }, []);
    const groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );
    if(props.engagements.length === 0 && (!props.salesSummaryItems || props.salesSummaryItems.length === 0)) {
        return <div>No Corporate Engagements</div>
    }

    let companyGroups = [];
    if(props.engagements.length !== 0) {
        let usersGroups = groupBy(props.engagements, 'user_type');

        if (usersGroups && usersGroups['Corporate']) {

            companyGroups = groupBy(usersGroups['Corporate'], 'user_company');

        }
    }

    let companiesAdded = [];

    if(props.salesSummaryItems && props.salesSummaryItems.length > 0) {
        for (const [key, value] of Object.entries(groupBy(props.salesSummaryItems, 'company'))) {
            let engagementCount = 0;
            if (companyGroups && companyGroups[key]) {
                engagementCount += companyGroups[key].length;
            }
            if(key !== 'null' && key !== '') {
                companiesAdded.push({
                    key: key,
                    count: value.length + engagementCount,
                });
            }
        }
    }

    if(props.engagements.length !== 0) {
        for (const [key, value] of Object.entries(companyGroups)) {
            if (!companiesAdded.find((company) => company.key === key) && (key !== 'null' && key !== '')) {
                companiesAdded.push({
                    key: key,
                    count: value.length
                });
            }
            if (!companiesAdded.find((company) => company.key === key) && key === 'null') {
                let other = companiesAdded.find((company) => company.key === 'Other');
                if (other?.length > 0) {
                    other.count += value.length;

                }else{
                    companiesAdded.push({
                        key: 'Other',
                        count: value.length
                    });
                }

            }

            if (!companiesAdded.find((company) => company.key === key) && key === '') {
                let other = companiesAdded.find((company) => company.key === '');
                if (other?.length > 0) {
                    other.count += value.length;

                }else{
                    companiesAdded.push({
                        key: 'Other',
                        count: value.length
                    });
                }
            }
        }
    }

    if(companiesAdded.length === 0){
        return <p>No Corporate Views</p>
    }


    return (
        <table>
            <thead>
            <tr>

                <GroupHeader colSpan={2} className="w-1/4">
                    Corporate
                </GroupHeader>
                <GroupHeader colSpan={2} className="w-1/4">
                    Views
                </GroupHeader>
            </tr>

            </thead>
            <tbody className={'text-xl'}>
            <>
                {companiesAdded.sort((a, b) => b.count - a.count).map((company, index) => {
                    if (showAll || index < PAGE_SIZE) {
                        return <TR key={company.key}>
                            <Cell colSpan={2}>{company.key}</Cell>
                            <Cell colSpan={2}>{company.count}</Cell>

                        </TR>
                    }

                })}
            </>
            {companiesAdded.length > PAGE_SIZE ? <div className={'pt-5'}>
                <ButtonBrand onClick={() => setShowAll(!showAll)}>{showAll ? 'Hide All' : 'Show All'}</ButtonBrand>

            </div> : <></>}

            </tbody>
        </table>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateViewers)
