import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {ReactComponent as ToggleOn} from "../../../../../svg/toggle-on.svg";
import {ReactComponent as ToggleOff} from "../../../../../svg/toggle-off.svg";
import Label from "../../../../../Components/Form/Label";


let ShowInProfileToggle = ({showInProfile, toggleShowInProfile}) => {
    const [value, setValue] = useState(showInProfile);

    useEffect(() => {
        setValue(showInProfile)
    }, [showInProfile]);


    return (
        <div className={" flex items-center"}>
            <span className="mb-1 font-normal font-heading text-sm uppercase mr-2">Providing 24HR packages?</span>
        <span onClick={() => {
            const newValue = value ? 0 : 1;
            setValue(newValue);
            toggleShowInProfile(newValue)
        }} className="pointer text-3xl">

          {Boolean(value) === true && (
              <ToggleOn
                  style={{transform: "scale(2)", marginLeft: '10px'}}
                  className="fill-current text-green"
              />
          )}
            {Boolean(value) === false && (
                <ToggleOff
                    style={{transform: "scale(2)", marginLeft: '10px'}}
                    className="fill-current text-dark-grey"
                />
            )}
        </span>


        </div>

    )
}

export default connect(null, null)(ShowInProfileToggle);