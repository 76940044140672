import React, { useState, useEffect } from "react";
import { Field, reduxForm, getFormValues } from "redux-form";
import { Select } from "Components/Form";
import { connect } from "react-redux";
import SearchVenues from "./SearchVenues";
import SearchDestinations from "./SearchDestinations";
import Validate from "../Validation/NewSiteVisitReportValidation.js";
import NavButtons from "../Components/NavButtons";
import VisitedSummary from "../Components/VisitedSummary";
import _get from "lodash/get";

let SelectVisitedType = props => {
  const { formData } = props;

  // Workaround for not using redux form validation
  // Dont allow user to move on without a visited entity selected
  const [visitedSelected, setVisitedSelected] = useState(false);

  useEffect(() => {
    if (!formData) {
      setVisitedSelected(false);
      return;
    }

    if (formData.visited_type === "venue" && formData.selected_venue) {
      setVisitedSelected(true);
    } else if (
      formData.visited_type === "destination" &&
      formData.selected_destination
    ) {
      setVisitedSelected(true);
    } else {
      setVisitedSelected(false);
    }
  }, [formData]);

  return (
    <div className={`bg-white ${props.className}`}>
      <div className="flex flex-wrap">
        <div className="col-w-full md:col-w-1/2 ">
          <h2 className="flex justify-between">
            <span>What did you visit?</span>
          </h2>
          <form onSubmit={props.handleSubmit} id="visited_type_form">
            <div className={`mt-6`}>
              <Field
                label=""
                name="visited_type"
                component={Select}
                noPlaceholder={false}
                options={[
                  { text: "Venue", value: "venue" },
                  // { text: "Destination", value: "destination" },
                ]}
                wrapperClassName="col-w-full md:mr-5 mb-5"
                validate={Validate.visited_type}
              />
            </div>
          </form>
        </div>
        <div className="col-w-full">
          {formData && formData.visited_type === "venue" && (
            <>
              <SearchVenues />
              {formData.selected_venue && (
                <VisitedSummary
                  name={formData.selected_venue.name}
                  id={formData.selected_venue.id}
                  image={_get(
                    formData,
                    "selected_venue.featured_image.url",
                    undefined
                  )}
                  address1={formData.selected_venue.address1}
                  address2={formData.selected_venue.address2}
                  website={formData.selected_venue.website}
                  description={formData.selected_venue.description}
                  link={`/venue/${formData.selected_venue.slug}`}
                />
              )}
            </>
          )}
          {props.visitedType === "destination" && <SearchDestinations />}
        </div>
      </div>
      {visitedSelected && (
        <NavButtons targetForm="visited_type_form" {...props} />
      )}
    </div>
  );
};

SelectVisitedType = reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
})(SelectVisitedType);

export default connect(state => {
  return {
    formData: getFormValues("CreateSiteVisitForm")(state),
  };
})(SelectVisitedType);
