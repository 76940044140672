import React, {useEffect, useState} from "react";
import EventsCalendar from "./EventsCalendar";
import {connect} from 'react-redux';
import {token} from "../../../../modules/auth";
import {
  createIndustryEvent,
  deleteIndustryEvent,
  editIndustryEvent,
  fetchCorporateEvents,
  fetchCountries,
  fetchIndustryEvents,
  fetchPublicEvents,
  fetchOwnEvents,
  unloadIndustryEvents,
  unloadPublicEvents,
  unloadOwnEvents,
  unloadBookedEvents, deleteEvent,
} from "../../../../redux/actions";
import {mapBookedEventToCalendarEvent, mapEventToCalendarEvent} from "./mapEvents";

const EventsContainer = (props) => {
  useEffect(() => {
    const {token, company_id} = props;
    props.fetchPublicEvents(token)
    if (company_id) {
      props.fetchIndustryEvents(token, company_id)
      props.fetchCorporateEvents(token, company_id)
    }
    props.fetchCountries(token);

    return () => {
      unloadEvents()
    }
  }, [])
  const {
    industryEvents,
    publicEvents,
    ownEvents,
    bookedEvents,
    countries,
    token,
    createIndustryEvent,
    editIndustryEvent,
    company_id,
    fetchPublicEvents,
    fetchIndustryEvents,
    fetchOwnEvents,
    unloadPublicEvents,
    unloadIndustryEvents,
    unloadBookedEvents,
    unloadOwnEvents,
    fetchCorporateEvents,
    deleteEvent
  } = props;
  const [event, setEvent] = useState({})

  const newEventHandler = formValues => {
    return createIndustryEvent(formValues, token)
  }

  const unloadEvents = () => {
    unloadIndustryEvents()
    unloadBookedEvents()
    unloadPublicEvents()
    unloadOwnEvents()
  }

  const editEventHandler = formValues => {
    const {id} = event;
    return editIndustryEvent(id, formValues, token)
  }

  const deleteEventHandler = () => {
    const {id} = event;
    return deleteEvent(id, token);
  }

  const fetchPublicEventsHandler = () => {
    unloadEvents()
    return fetchPublicEvents(token)
  }

  const fetchIndustryEventsHandler = () => {
    unloadEvents()
    if (company_id) {
      return fetchIndustryEvents(token, company_id);
    }
  }

  const fetchOwnEventsHandler = () => {
    unloadEvents();
    return fetchOwnEvents(token)
  }

  const fetchCorporateEventsHandler = () => {
    unloadEvents()
    if (company_id) {
      return fetchCorporateEvents(token, company_id)
    }
  }

  let events = [];
  let bookedEventsArray = [];
  let publicEventsArray = [];
  let ownEventsArray = [];

  if (Object.keys(industryEvents).length > 0) {
    events = industryEvents.map(industryEvent => {
      return mapEventToCalendarEvent(industryEvent, 'industry')
    })
  }
  if (Object.keys(publicEvents).length > 0) {
    publicEventsArray = publicEvents.map(publicEvent => {
      return mapEventToCalendarEvent(publicEvent, 'public')
    })
  }
  if (Object.keys(bookedEvents).length > 0) {
    bookedEventsArray = bookedEvents.map(bookedEvent => {
      return mapBookedEventToCalendarEvent(bookedEvent)
    })
  }
  if (ownEvents && Object.keys(ownEvents).length > 0) {
    ownEventsArray = ownEvents.map(ownEvent => {
      return mapBookedEventToCalendarEvent(ownEvent)
    })
  }

  Array.prototype.push.apply(events, bookedEventsArray);
  Array.prototype.push.apply(events, ownEventsArray);
  Array.prototype.push.apply(events, publicEventsArray);

  return (
    <EventsCalendar
      calendarEvents={events}
      countries={countries}
      onNewEvent={newEventHandler}
      onEditEvent={editEventHandler}
      handleDeleteEvent={deleteEventHandler}
      company_id={company_id}
      token={token}
      setEvent={setEvent}
      fetchPublicEvents={fetchPublicEventsHandler}
      fetchCorporateEvents={fetchCorporateEventsHandler}
      fetchIndustryEvents={fetchIndustryEventsHandler}
      fetchOwnEvents={fetchOwnEventsHandler}
    />
  )
}

const mapStateToProps = (state, props) => ({
  ...state.industryEvents,
  ...state.publicEvents,
  ...state.countries,
  ...state.events,
  ...state.bookedEvents,
  ...state.ownEvents,
  token: token(state),
  // company_id:  props.account && props.account.company ? props.account.company.id : '',
  company_id:  props.account && props.account.company ? props.account.company.id : state?.auth?.user?.company_id,
})

const mapDispatchToProps = {
  fetchIndustryEvents,
  fetchPublicEvents,
  deleteIndustryEvent,
  fetchCountries,
  createIndustryEvent,
  editIndustryEvent,
  unloadIndustryEvents,
  unloadPublicEvents,
  fetchCorporateEvents,
  unloadBookedEvents,
  fetchOwnEvents,
  unloadOwnEvents,
  deleteEvent
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsContainer)
