import React from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { logout } from "modules/auth";

class Logout extends React.Component {
  static propTypes = {
    logout: func.isRequired,
  };

  componentDidMount() {
    this.props.logout();
  }

  render() {
    return (
      <div className="container py-8 lg:py-16 text-xl">You are logged out.</div>
    );
  }
}

export default connect(
  undefined,
  { logout }
)(Logout);
