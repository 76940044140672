import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../../Components/Loading";
import { replaceCommonEncodings } from "../../../modules/string-tools";
import {
  FaChevronRight,
  FaRegPlusSquare,
  FaRegMinusSquare,
} from "react-icons/fa";
import TrapezeHeader from "../../../Components/TrapezeHeader";

const renderItem = (offer, index, array) => {
  const { id, title } = offer;

  return (
    <li
      key={id}
      className={`py-5 px-3 ${index < array.length - 1 &&
        "border-b border-light-blue-grey"}`}
    >
      <Link to={`/offer/${id}`} className="text-black flex">
        <div className="w-4/5">
          <h3 className="mb-0">{replaceCommonEncodings(title)}</h3>
        </div>{" "}
        <div className="w-1/5 flex justify-end items-center">
          <FaChevronRight className="text-light-blue-grey text-2xl" />{" "}
        </div>
      </Link>
    </li>
  );
};

const SpecialOffers = ({ loading, offers, mobile, anchor }) => {
  const [expanded, setExpanded] = useState(false);

  const _offers = offers.filter(offer => offer.type.name === "special-offers");

  const toggle = () => {
    setExpanded(!expanded);
  };

  if (_offers.length === 0) return null;

  return (
    <div className="bg-white mb-3 shadow text-center" id="insights">
        <span className="anchor" id={anchor}>
          Offers section
        </span>
      <div
          className={`flex flex-wrap justify-between items-center bg-white pointer pr-3 lg:cursor-default`}
          onClick={toggle}
      >
      <TrapezeHeader
          title={`Special Offers`}
          textLeft
          bgColor="dark-red"
          bigText
      />
        {expanded ? (
            <FaRegMinusSquare className="pointer lh:hidden" />
        ) : (
            <FaRegPlusSquare className="pointer lg:hidden" />
        )}
      </div>
      {/*<h2*/}
      {/*  className={`mb-2 py-4 ${*/}
      {/*    mobile*/}
      {/*      ? "px-3 text-left w-full flex items-center justify-between"*/}
      {/*      : "text-center"*/}
      {/*  } text-xl ${(expanded || !mobile) &&*/}
      {/*    "border-b border-light-blue-grey"}`}*/}
      {/*  onClick={toggle}*/}
      {/*>*/}
      {/*  Special Offers*/}
      {/*  {mobile && !expanded && <FaRegPlusSquare className="text-base" />}*/}
      {/*  {mobile && expanded && <FaRegMinusSquare className="text-base" />}*/}
      {/*</h2>*/}
      {(!mobile || (mobile && expanded)) &&
        (loading ? (
          <Loading />
        ) : (
          <ul className="list-reset text-left">
            {_offers.slice(0, 5).map(renderItem)}
          </ul>
        ))}
    </div>
  );
};

export default SpecialOffers;
