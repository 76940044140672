import groupBy from "lodash/groupBy";
import {
  createDateRange,
  dateRangeFilter,
} from "../../../pages/admin/Analytics/date-utils";
import { venueEnquiries } from "./by-day";
import { uniqByUuid, venuePageViews } from "./utils";
import { distanceInWordsStrict } from "date-fns";

export default (id, start, end) => state => {
  const dateRange = createDateRange(start, end);

  const all = dateRangeFilter(venuePageViews(id)(state), dateRange, "day");

  const unique = uniqByUuid(all);
  const new_visitors = all.filter(item => item.new).length;

  // ropey way of marking sessions
  const byHour = groupBy(all, item => {
    return item.hour;
  });
  let sessions = 0;
  Object.keys(byHour).forEach(hour => {
    sessions = sessions + uniqByUuid(byHour[hour]).length;
  });

  let seconds = 0;
  let bounces = 0;
  all.forEach(view => {
    if (view.seconds) {
      seconds = seconds + view.seconds;
    } else {
      bounces++;
    }
  });

  return {
    visitors: unique.length,
    new_visitors,
    returning_visitors: unique.length - new_visitors,
    page_views: all.length,
    average_time_on_page: distanceInWordsStrict(
      0,
      (seconds / all.length) * 1000
    ),
    web_enquiries: dateRangeFilter(venueEnquiries(id)(state), dateRange, "day")
      .length,
    bounce_rate: `${Math.round(bounces / all.length) * 100}%`,
  };
};
