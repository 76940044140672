import ChecksheetBreakdown from "./ChecksheetBreakdown";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Field, formValueSelector } from "redux-form";
import { getHiddenRows } from "../Helpers/getFormState";
import { isAgent, isVenueAdmin } from "../../../../../modules/auth";
import {
  calculateCommissionFromUnit,
  gatherTotals, getVatRateById,
} from "../Helpers/calculateTotals";
import { flagChanges } from "../Helpers/formHelper";
import { ENQUIRY_VENUE_DATA_STATUS } from "../../../../../modules/rfp/enquiryVenueDataStatus";
import { formattedApplicableDates } from "../../../../../utils";

function getMeetingRoomDelegatesKey(id) {
  return `delegates[meeting_${id}]`;
}

function getMeetingRoomRemoveKey(id) {
  return `remove[meeting_${id}]`;
}

let MeetingRoomBreakdown = (props) => {
  let currency = props.enquiryVenueData.currency;
  const { vatRates } = props;
  let totals = {};
  let commissionTotals = {};
  let commissionTotalsGBP = {};
  const [grandTotal, setGrandTotal] = useState(0);
  const [, setGrandCommissionTotal] = useState(0);
  const [, setGrandCommissionTotalGBP] = useState(0);
  useEffect(() => {
    gatherTotals(totals, setGrandTotal, props.setMeetingRoomsTotal);
    gatherTotals(commissionTotals, setGrandCommissionTotal, props.setMeetingRoomsCommissionTotal);
    gatherTotals(commissionTotalsGBP, setGrandCommissionTotalGBP, props.setMeetingRoomsCommissionTotalGBP);
  }, [props.values, totals]);

  const onChange = (event, newValue, previousValue, name) => {
    flagChanges(props.initialValues, null, props.hiddenRows, newValue, name, props.setMeetingRoomChanged);
  };
  return (
    <ChecksheetBreakdown
      currency={currency}
      headers={(
        <React.Fragment>
          <th className={"p-2 pl-0 text-left uppercase text-xs min-14 w-auto"}>Room</th>
          <th></th>
          <th></th>
        </React.Fragment>
      )}
      spaces={3}
      totalAmount={props.currencyFormatter.format(grandTotal)}
      showCommission={props.showCommission}
    >
      {props.meetingRooms.map(
        ({
           id,
           room,
           location_or_floor,
           display_price_including_vat,
           price_total_net,
           price_total_vat,
           price_total_net_gbp,
           price_total_vat_gbp,
           price_vat_rate_id,
           room_style,
           room_id,
           room_purpose,
           overnight_room_data,
           setup_data,
           breakdown_data,
           no_of_days,
           applicable_days,
         }) => {
          let vatRate = getVatRateById(vatRates, price_vat_rate_id).number;
          let finalTotal = Number(price_total_net) + Number(price_total_vat);
          let vatTotal = Number(price_total_vat);
          if (typeof props.hiddenRows[getMeetingRoomRemoveKey(id)] === "undefined") {
            totals[`${id}`] = parseFloat(finalTotal);
          }

          let commission = props.commissionItems ? props.commissionItems.find(item => parseInt(item.step_id) === 3) : 0;
          let commissionRate = commission ? Number(commission.rate) : 0;
          let commissionAppliedOn = commission ? commission.applied_on : 0;
          let commissionIncludeVat = commission ? commission.include_vat : 0;
          let commissionTotal = 0;
          let commissionTotalGBP = 0;
          if (typeof props.hiddenRows[getMeetingRoomRemoveKey(id)] === "undefined") {
            const multiplier = 1;
            commissionTotal = commission ?
              calculateCommissionFromUnit(commissionIncludeVat, Number(price_total_net), Number(price_total_vat), props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getMeetingRoomDelegatesKey(id), commissionTotals) :
              0;
            commissionTotalGBP = commission ?
              calculateCommissionFromUnit(commissionIncludeVat, Number(price_total_net_gbp), Number(price_total_vat_gbp), props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getMeetingRoomDelegatesKey(id), commissionTotalsGBP) :
              0;
          }

          let overnightTotal = 0;
          let commissionOvernightHoldTotal = 0;
          let commissionOvernightHoldTotalGBP = 0;
          const show_overnight = !!overnight_room_data;
          if (show_overnight) {
            overnightTotal = Number(overnight_room_data.overnight_price_net) + Number(overnight_room_data.overnight_price_vat);
            if (typeof props.hiddenRows[getMeetingRoomRemoveKey(id)] === "undefined") {
              const multiplier = 1;
              commissionOvernightHoldTotal = commission ?
                calculateCommissionFromUnit(commissionIncludeVat, Number(overnight_room_data.overnight_price_net), Number(overnight_room_data.overnight_price_vat), props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn,
                  getMeetingRoomDelegatesKey(id), commissionTotals) :
                0;
              commissionOvernightHoldTotalGBP = commission ?
                calculateCommissionFromUnit(commissionIncludeVat, Number(overnight_room_data.overnight_price_net_gbp), Number(overnight_room_data.overnight_price_vat_gbp), props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn,
                  getMeetingRoomDelegatesKey(id), commissionTotalsGBP) :
                0;

              /* SPECIFICALLY OVERRIDDEN HERE IN THIS CASE */
            }
          }
          let setupTotal = 0;
          let commissionSetupTotal = 0;
          let commissionSetupTotalGBP = 0;
          let showSetup = !!setup_data;
          if (showSetup) {
            setupTotal = Number(setup_data.setup_price_net) + Number(setup_data.setup_price_vat);
            if (typeof props.hiddenRows[getMeetingRoomRemoveKey(id)] === "undefined") {
              const multiplier = 1;
              commissionSetupTotal = commission ?
                calculateCommissionFromUnit(commissionIncludeVat, Number(setup_data.setup_price_net), Number(setup_data.setup_price_vat), props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn,
                  getMeetingRoomDelegatesKey(id), commissionTotals) :
                0;
              commissionSetupTotalGBP = commission ?
                calculateCommissionFromUnit(commissionIncludeVat, Number(setup_data.setup_price_net_gbp), Number(setup_data.setup_price_vat_gbp), props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn,
                  getMeetingRoomDelegatesKey(id), commissionTotalsGBP) :
                0;
            }
          }
          let breakdownTotal = 0;
          let commissionBreakupTotal = 0;
          let commissionBreakupTotalGBP = 0;
          let showBreakdown = !!breakdown_data;
          if (showBreakdown) {
            breakdownTotal = Number(breakdown_data.breakdown_price_net) + Number(breakdown_data.breakdown_price_vat);
            if (typeof props.hiddenRows[getMeetingRoomRemoveKey(id)] === "undefined") {
              const multiplier = 1;
              commissionBreakupTotal = commission ?
                calculateCommissionFromUnit(commissionIncludeVat, Number(breakdown_data.breakdown_price_net), Number(breakdown_data.breakdown_price_vat), props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn,
                  getMeetingRoomDelegatesKey(id), commissionTotals) :
                0;
              commissionBreakupTotalGBP = commission ?
                calculateCommissionFromUnit(commissionIncludeVat, Number(breakdown_data.breakdown_price_net_gbp), Number(breakdown_data.breakdown_price_vat_gbp), props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn,
                  getMeetingRoomDelegatesKey(id), commissionTotalsGBP) :
                0;
            }
          }
          totals[`${id}`] = parseFloat(finalTotal) + Number(overnightTotal) + Number(setupTotal) + Number(breakdownTotal);
          commissionTotals[getMeetingRoomDelegatesKey(id)] = Number(commissionTotal) + Number(commissionOvernightHoldTotal) + Number(commissionSetupTotal) + Number(commissionBreakupTotal);
          commissionTotalsGBP[getMeetingRoomDelegatesKey(id)] = Number(commissionTotalGBP) + Number(commissionOvernightHoldTotalGBP) + Number(commissionSetupTotalGBP) + Number(commissionBreakupTotalGBP);

          return (
            <React.Fragment key={`meeting_rooms_${id}_container`}>
              <tr key={`meeting_rooms_${id}`}
                  className={` ${props.hiddenRows[`${getMeetingRoomRemoveKey(id)}`] !== "undefined" && props.hiddenRows[`${getMeetingRoomRemoveKey(id)}`] === true ? "row-fadeout" : ""} ${show_overnight && " border-b-0 border-none"}`}>
                <td className={"p-2 pl-0 text-left pt-4"}>
                  <p className={"pb-0 mb-0"}>{room.name} ({location_or_floor} - {room_style.name})</p>
                  {room_purpose.id === 1 && <small className={"my-0"}>(Main Meeting Room)</small>}
                  {room_purpose.id === 1 && !price_total_net && <p className={"mb-0"}>(included in package)</p>}
                  {applicable_days && (<small>Dates Room
                    Available: {formattedApplicableDates((props.enquiryVenueData.date_from_override ? props.enquiryVenueData.date_from_override : props.brief.date_from), (props.enquiryVenueData.date_to_override ? props.enquiryVenueData.date_to_override : props.brief.date_to), applicable_days, "DD/MM/YY").join(", ")}</small>)}
                </td>
                <td></td>
                <td></td>
                <td
                  className={"p-2 text-right"}>{price_total_net ? props.currencyFormatter.format(price_total_net) : "N/A"}</td>
                <td
                  className={"p-2 text-right"}>{room_purpose.id === 1 && !price_total_net ? "N/A" : `${vatRate}%`}</td>
                {/*if room is included output n/a rather than 0 value */}
                <td
                  className={"p-2 text-right"}>{!price_total_net ? "N/A" : props.currencyFormatter.format(vatTotal)}</td>
                {props.showCommission ?
                  <td className={"p-2 text-right"}>
                    {/*if room is included output n/a rather than 0 value */}
                    {room_purpose.id === 1 && !price_total_net ? "N/A" : `${commissionRate}%`}
                  </td>
                  :
                  null
                }
                {props.showCommission ?
                  <td className={"p-2 text-right"}>
                    {/*if room is included output n/a rather than 0 value */}
                    {room_purpose.id === 1 && !price_total_net ? "N/A" : props.currencyFormatter.format(commissionTotal)}
                  </td>
                  :
                  null
                }
                <td
                  className={"p-2 text-right"}>{!price_total_net ? "N/A" : props.currencyFormatter.format(finalTotal)}</td>
                {(room_purpose.id !== 1) ?
                  <td className={"p-2 text-left w-8"}>
                    {Number(props.status) === Number(ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS) || Number(props.status) > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS ?
                      null : <Field
                        name={getMeetingRoomRemoveKey(id)}
                        component={props.renderCheckBox}
                        onChange={onChange}
                      />}
                  </td>
                  : <td className={"p-2 text-left w-8"} />}
              </tr>
              {show_overnight &&
                <tr key={`meeting_rooms_${id}_overnight`}
                    className={props.hiddenRows[`${getMeetingRoomRemoveKey(id)}`] !== "undefined" && props.hiddenRows[`${getMeetingRoomRemoveKey(id)}`] === true ? "row-fadeout" : ""}>
                  <td className={"p-2 pl-0 text-left py-0"}>
                    <p>Overnight Hold</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td
                    className={"p-2 text-right"}>{overnight_room_data.overnight_price_net ? props.currencyFormatter.format(overnight_room_data.overnight_price_net) : "N/A"}</td>
                  <td className={"p-2 text-right"}>{`${vatRate}%`}</td>
                  <td
                    className={"p-2 text-right"}>{!overnight_room_data.overnight_price_vat ? "N/A" : props.currencyFormatter.format(overnight_room_data.overnight_price_vat)}</td>
                  {props.showCommission ?
                    <td className={"p-2 text-right"}>
                      {`${commissionRate}%`}
                    </td>
                    :
                    null
                  }
                  {props.showCommission ?
                    <td className={"p-2 text-right"}>
                      {props.currencyFormatter.format(commissionOvernightHoldTotal)}
                    </td>
                    :
                    null
                  }
                  <td
                    className={"p-2 text-right"}>{!overnight_room_data.overnight_price_net ? "N/A" : props.currencyFormatter.format(overnightTotal)}</td>
                  <td className={"p-2 text-left w-8"} />
                </tr>
              }
              {showSetup &&
                <tr key={`meeting_rooms_${id}_setup`}
                    className={props.hiddenRows[`${getMeetingRoomRemoveKey(id)}`] !== "undefined" && props.hiddenRows[`${getMeetingRoomRemoveKey(id)}`] === true ? "row-fadeout" : ""}>
                  <td className={"p-2 pl-0 text-left py-0"}>
                    <p>Setup Cost</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td
                    className={"p-2 text-right"}>{setup_data.setup_price_net ? props.currencyFormatter.format(setup_data.setup_price_net) : "N/A"}</td>
                  <td className={"p-2 text-right"}>{`${vatRate}%`}</td>
                  <td
                    className={"p-2 text-right"}>{!setup_data.setup_price_vat ? "N/A" : props.currencyFormatter.format(setup_data.setup_price_vat)}</td>
                  {props.showCommission ?
                    <td className={"p-2 text-right"}>
                      {`${commissionRate}%`}
                    </td>
                    :
                    null
                  }
                  {props.showCommission ?
                    <td className={"p-2 text-right"}>
                      {props.currencyFormatter.format(commissionSetupTotal)}
                    </td>
                    :
                    null
                  }
                  <td
                    className={"p-2 text-right"}>{!setup_data.setup_price_net ? "N/A" : props.currencyFormatter.format(setupTotal)}</td>
                  <td className={"p-2 text-left w-8"} />
                </tr>
              }
              {showBreakdown &&
                <tr key={`meeting_rooms_${id}_breakdown`}
                    className={props.hiddenRows[`${getMeetingRoomRemoveKey(id)}`] !== "undefined" && props.hiddenRows[`${getMeetingRoomRemoveKey(id)}`] === true ? "row-fadeout" : ""}>
                  <td className={"p-2 pl-0 text-left py-0"}>
                    <p>Breakdown Cost</p>
                  </td>
                  <td></td>
                  <td></td>
                  <td
                    className={"p-2 text-right"}>{breakdown_data.breakdown_price_net ? props.currencyFormatter.format(breakdown_data.breakdown_price_net) : "N/A"}</td>
                  <td className={"p-2 text-right"}>{`${vatRate}%`}</td>
                  <td
                    className={"p-2 text-right"}>{!breakdown_data.breakdown_price_vat ? "N/A" : props.currencyFormatter.format(breakdown_data.breakdown_price_vat)}</td>
                  {props.showCommission ?
                    <td className={"p-2 text-right"}>
                      {`${commissionRate}%`}
                    </td>
                    :
                    null
                  }
                  {props.showCommission ?
                    <td className={"p-2 text-right"}>
                      {props.currencyFormatter.format(commissionSetupTotal)}
                    </td>
                    :
                    null
                  }
                  <td
                    className={"p-2 text-right"}>{!breakdown_data.breakdown_price_net ? "N/A" : props.currencyFormatter.format(breakdownTotal)}</td>
                  <td className={"p-2 text-left w-8"} />
                </tr>
              }
            </React.Fragment>
          );
        },
      )}
    </ChecksheetBreakdown>
  );

};
const selector = formValueSelector("confirmBooking");

export default withRouter(
  connect((state, props) => ({
      hiddenRows: getHiddenRows(props.meetingRooms, getMeetingRoomRemoveKey, state, selector),
      isVenue: isVenueAdmin(state),
      isAgency: isAgent(state),
    }),
  )(MeetingRoomBreakdown),
);
