import React from 'react';
import EnquiryStatus from "./EnquiryStatus";

const VenueCard = (props) => {

        return(
            <div className="mb-5">
                <h2
                    className="text-white bg-black font-normal font-body p-3 uppercase text-xl"
                    style={{ minHeight: "5rem" }}
                >
                    {props.venue.name}
                </h2>
                <p className="bg-brand text-white font-normal uppercase text-lg flex items-center p-3"
                style={{ minHeight: "5rem" }}
                >
                <EnquiryStatus status={props.status} />
                </p>
                <div
                    className="re-16/9 w-full bg-no-repeat bg-cover bg-center"
                    style={
                        props.venue.image
                            ? {
                                backgroundImage: `url(${window.API}/venues/${props.venue.slug}/images/${props.venue.image})`,
                            }
                            : null
                    }
                />
            </div>
        )

}

export default VenueCard
