import classnames from "classnames";
import { any, bool, number, oneOf } from "prop-types";
import React, { createContext, useContext } from "react";

/*
Usage:

By default Columns stack on top of each other on a tablet size screen.

<Row>
  <Column w="1/2">Blar</Col>
  <Column w="1/4">Blar</Col>
  <Column w="1/4">Blar</Col>
</Row>


Use a breakpoint prop on the Row to change the screen size the Columns stack at.

<Row breakpoint="md">
  <Column w="1/2">Side by side on desktop</Col>
  <Column w="1/2">Stacks on mobile</Col>
</Row>


Only the breakpoint from the closest parent Row is used.

<Row breakpoint="md">
  <Column w="1/2">Stacks on mobile</Col>
  <Column w="1/2">
    <Row>
      <Column w="1/2">Stacks on default (tablet)</Col>
      <Column w="1/2">Stacks on default (tablet)</Col>
    </Row>
  </Col>
</Row>

*/

const X_GAP = 2;
const Y_GAP = 4;
const BREAKPOINT = "xl";

// Context used to pass options from the Row to the child Columns
const GridContext = createContext();

export const Row = ({
  className,
  noWrap,
  reverse,
  breakpoint = BREAKPOINT,
  noBreak,
  gapless,
  xGap = X_GAP,
  yGap = Y_GAP,
  children,
}) => (
  <div
    className={classnames(
      `flex`,
      {
        "flex-wrap": !noWrap,
        "flex-row-reverse": reverse,
        [`-mx-${xGap}`]: !gapless,
      },
      className
    )}
  >
    <GridContext.Provider value={{ breakpoint, gapless, xGap, yGap, noBreak }}>
      {children}
    </GridContext.Provider>
  </div>
);

Row.propTypes = {
  breakpoint: oneOf(["xs", "sm", "md", "xl", "xxl"]),
  gapless: bool,
  reverse: bool,
  xGap: number,
  yGap: number,
  className: any,
};

export const Column = ({ className, w, ...rest }) => {
  const gridOptions = useContext(GridContext) || {};

  const {
    breakpoint = BREAKPOINT,
    gapless,
    noBreak,
    xGap = X_GAP,
    yGap = Y_GAP,
  } = gridOptions;

  const prefix = noBreak ? "" : `${breakpoint}:`;

  return (
    <div
      className={classnames(
        `mb-${yGap}`,
        { [`px-${xGap}`]: !gapless, "w-full": !noBreak },
        w ? `${prefix}w-${w}` : `${prefix}w-auto ${prefix}flex-1`,
        className
      )}
      {...rest}
    />
  );
};

Column.propTypes = {
  w: oneOf([
    "1/2",
    "1/3",
    "2/3",
    "1/4",
    "2/4",
    "3/4",
    "1/5",
    "2/5",
    "3/5",
    "4/5",
    "1/6",
    "2/6",
    "3/6",
    "4/6",
    "5/6",
    "full",
  ]),
  className: any,
};
