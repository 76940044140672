import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { getWidth } from "redux-window";
import { object, string } from "prop-types";
import { token, isAgency, isInternal, isAgencyApproved, isVenue, venues } from "modules/auth";
import { venueImpression, venueInteraction } from "modules/analytics";
import Loading from "Components/Loading";
import NotFound from "pages/NotFound";
import { pushEcommerce } from "modules/google-tag-manager";
import Banner from "./Banner";
import MobileBanner from "./MobileBanner";
import Details from "./Details";
import HealthAndSafety from "./HealthAndSafety";
import MeetingRooms from "./MeetingRooms";
import SiteVisitReports from "./SiteVisitReports";
import Pricing from "./Pricing";
import Offers from "./sidebar/Offers";
import News from "./sidebar/News";
import EdgeInsights from "./sidebar/EdgeInsights";
import FacilitiesAndTechnical from "./FacilitiesAndTechnical";
import { createHandleAxiosError } from "../../modules/api-tools";
import { FaFlag } from "react-icons/fa";
import { ButtonBrand, ButtonPrimary } from "Components/Button";
import KeyInformation from "./KeyInformation";
import CreateShortlist from "Components/Shortlists/Create";
import ShortlistUnlogged from "Components/Shortlists/ShortlistUnlogged";
import VenueNote from "./VenueNote";
import { isLoggedIn } from "modules/auth";
import IsThisYourVenue from "Components/IsThisYourVenue";
import MeetingRoomsAndEvents from "./sidebar/MeetingRoomsAndEvents";
import SpecialOffers from "./sidebar/SpecialOffers";
import { stateToReferCode } from "../../modules/brand";
import { VENUE_TAGS } from "../../modules/analytics/tags";
import CharityAuctionSingle from "../Home/CharityAuction/CharityAuctionSingle";
import RegisterBlurPanel from "../../Components/RegisterBlurPanel";
import RegisterToViewPanel from "../RegisterToViewPanel";
import VenueUpgradeBanner from "../../Components/venue/VenueUpgradeBanner";

class Venue extends Component {
  static propTypes = {
    match: object.isRequired,
    location: object.isRequired,
    token: string,
  };

  static defaultProps = {};

  state = {
    loading: true,
    venue: null,
    impression_id: null,
    error: false,
    facilitiesAndTechnicalActiveTab: "catering"
  };

  getVenue(venue) {
    const { history, location } = this.props;
    const handleAxiosError = createHandleAxiosError({ history, location });

    axios
      .get(`${window.API}/venues/${venue}`)
      .then(async response => {
        this.setState({
          loading: false,
          error: false,
          venue: response.data,
        });
        pushEcommerce("venueDetail", {
          id: response.data.id,
          name: response.data.name,
          location: response.data.town,
        });
        try {
          const impression_id = await this.props.venueImpression({
            id: response.data.id,
            data_score: response.data.data_score,
          });
          this.setState({
            impression_id,
          });
        } catch (e) {
          // nothing
        }
      })
      .catch(
        handleAxiosError(() => {
          this.setState({
            loading: false,
            error: true,
          });
        }),
      );
  }

  componentDidMount() {
    const venue = this.props.match.params.venue;
    this.getVenue(venue);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { venue },
      },
    } = this.props;

    const {
      match: {
        params: { venue: prevVenue },
      },
    } = prevProps;
    if (venue !== prevVenue) {
      this.setState({ loading: true });
      this.getVenue(venue);
    }
  }

  hasOffers(venue) {
    const { isAgency } = this.props;

    let hasSpecialOffers = venue.offers.filter(item => ["special-offers"].includes(item.type.name)).length > 0;
    let hasAgencyOffers = venue.offers.filter(item => ["commission-offers", "agency-rates", "fam-trips"].includes(item.type.name)).length > 0;

    if (!isAgency) {
      return hasSpecialOffers;
    }

    return hasSpecialOffers || hasAgencyOffers;
  }

  componentWillUnmount() {
    if (this.state.venue) {
      this.props.venueInteraction({
        type: VENUE_TAGS.EXIT,
        id: this.state.venue.id,
        data_score: this.state.venue.data_score,
        impression_id: this.state.impression_id,
      });
    }
  }

  shareEmailBody(venueName) {
    const { referCode, user, brand } = this.props;

    const sharedBy =
      user && user.first_name ? ` by ${user.first_name} ${user.surname}` : "";

    const href = window.location.href;

    const url =
      href +
      (referCode
        ? (href.includes("?") ? "&" : "?") + `referrer=${referCode}`
        : "");

    const from =
      brand && brand.company_name
        ? `${brand.company_name}, powered by EDGE Venues`
        : "EDGE Venues";

    const content = `Venue ${venueName} has been shared with you${sharedBy}.

  ${url}

${from}`;

    return encodeURIComponent(content);
  }

  address = () => {
    const { town, county, country, postcode } = this.state.venue;

    return [town, county, country && country.name, postcode]
      .filter(Boolean)
      .join(", ");
  };

  keyInformation = venue => {
    return (
      <KeyInformation
        capacity={venue.capacity}
        meetingRooms={venue.cap_meeting_rooms}
        bedrooms={venue.cap_bedrooms}
        travelTime={venue.travel_time_from_nearest_airport}
        leisure={!!venue.notes_facilities_technical}
        wifi={!!venue.wifi_link}
        twitterLink={venue.twitter_link}
        instagramLink={venue.instagram_link}
        tripadvisorLink={venue.tripadvisor_link}
        venueId={venue.id}
        venueName={venue.name}
        shareEmailBody={() => this.shareEmailBody(venue.name)}
        isLoggedIn={this.props.isLoggedIn}
      />
    );
  };

  flagAProblem = () => {

    const { venue } = this.state;
    return (
      <div className="mt-2 lg:flex items-center text-center">
        <FaFlag className="hidden lg:block text-dark-red mr-4" />
        <span>
        Have you spotted a duplication, a data error or an issue with this
        listing?
      </span>
        <ButtonBrand
          outlined
          classes="lg:ml-4 border rounded-sm mt-4 lg:mt-0"
          Element="a"
          href={`mailto:info@edgevenues.com?subject=Report an issue&body=Hi EDGE Team, I'd like to report an issue on your new website on the venue: ${venue.name} - ${window.location.href}`}
        >
          Flag a problem
        </ButtonBrand>
      </div>
    );
  };

  handleFacilitiesAndTechnicalTab = (tab) => {
    this.setState({facilitiesAndTechnicalActiveTab: tab})
  }

  render() {
    if (this.state.error) {
      return (
        <NotFound>
          <p className="text-xl">
            Sorry the venue couldn't be found. Please check the link is correct,
            or go back to the <Link to="/search">search page</Link> and try
            again.
          </p>
        </NotFound>
      );
    }
    if (this.state.loading) {
      return <Loading large classes="py-8" />;
    }

    const { impression_id, venue, facilitiesAndTechnicalActiveTab } = this.state;

    const hasDestination = venue.destination;
    const isLicensed = venue.temp_permission !== "free";
    const mobile = this.props.mobile;
    const { isAgency, isAgencyApproved, isVenue, userVenues } = this.props;
    const agencyOffers = venue.offers.filter(
      offer => offer.type === "agency-rates",
    );

    const hasOffers = Boolean(this.hasOffers(venue));

    let showSiteVisits = venue.published_site_visits.length > 0;
    if (isVenue) {
      showSiteVisits = userVenues.some(userVenue => userVenue.id === venue.id);
    }

    return (
      <div className="bg-grey md:bg-white">
        {mobile ? (
          <>
            <MobileBanner
              venueId={venue.id}
              data_score={venue.data_score}
              impression_id={impression_id}
              name={venue.name}
              featuredImg={venue.featured_image}
              images={venue.images}
              anchors={{
                details: "#details",
                healthAndSafety: "#health-and-safety",
                contacts: "#contacts",
                siteVisits: "#site-visit-reports",
                meetingRooms: "#meeting-rooms",
                facilitiesAndTechnical: "#facilities-and-technical",
                location: "#location",
                destination: "#destination",
              }}
              hasMeetingRooms={!!venue.cap_meeting_rooms}
              hasHealthAndSafety={!!venue.files_health_and_safety.length}
              hasContacts={!!this.state.venue.public_contacts.length}
              hasSiteVisitReports={
                !!this.state.venue.published_site_visits.length
              }
              hasDestination={hasDestination}
              hasOffers={hasOffers}
              hasArticles={venue.articles.length > 0}
              maxMeetingRoomCapacity={parseInt(venue.capacity, 10)}
              bedrooms={venue.cap_bedrooms}
              airportDistance={venue.travel_distance_from_nearest_airport}
              airportTime={venue.travel_time_from_nearest_airport}
              meetingRooms={venue.cap_meeting_rooms}
              hasMeetingRoomFor10={!!venue.buy_it_now_price_exc_vat}
              isLicensed={venue.temp_permission !== "free"}
              virtualTours={this.state.venue.virtual_tours || ""}
              facilitiesAndTechnical={venue.notes_facilities_technical}
              covid={venue.covid_is_open}
              renderPricing={() => (
                <React.Fragment>
                  {this.keyInformation(venue)}
                  <div className="col">
                    <CharityAuctionSingle venueId={venue.id} />
                  </div>

                  <div className="col">
                    <div className="mb-5">
                      {this.props.isLoggedIn ? (
                        <CreateShortlist
                          form="venue-shortlist"
                          searchForm="advanced-search"
                          mobile
                          isSingleVenueView
                          venue={venue}
                        />
                      ) : (
                        <ShortlistUnlogged />
                      )}
                    </div>
                    <VenueNote venueId={venue.id} />
                    {/**
                     *  EP-142 Full hide Instant Book (comment out for now)
                     */}
                    {/*<Pricing*/}
                    {/*    showNotes={false}*/}
                    {/*    meetingRoomFor10={venue.buy_it_now_price_exc_vat}*/}
                    {/*    packages={venue.packages}*/}
                    {/*    venueId={venue.id}*/}
                    {/*    data_score={venue.data_score}*/}
                    {/*    impression_id={impression_id}*/}
                    {/*    venueName={venue.name}*/}
                    {/*    location={venue.town}*/}
                    {/*    venueSlug={venue.slug}*/}
                    {/*    venuePriceBanding={venue.price_banding}*/}
                    {/*    currency={*/}
                    {/*      venue.currency ? venue.currency.html_code : undefined*/}
                    {/*    }*/}
                    {/*/>*/}

                            {(isAgency && isAgencyApproved) && <Offers items={agencyOffers} anchor={'offers'}/>}
                          </div>
                        </React.Fragment>
                    )}
                />
              </>
          ) : (
              <Banner
                  venueId={venue.id}
                  venuePermission={venue.temp_permission}
                  data_score={venue.data_score}
                  impression_id={impression_id}
                  name={venue.name}
                  featuredImg={venue.featured_image}
                  images={venue.images}
                  slug={venue.slug}
                  covid={venue.covid_is_open}
                  anchors={{
                    details: 'details',
                    healthAndSafety: '#health-and-safety',
                    contacts: 'contacts',
                    siteVisits: 'site-visit-reports',
                    meetingRooms: 'meeting-rooms',
                    features: 'features',
                    facilitiesAndTechnical: 'facilities-and-technical',
                    esg: 'csr',
                    location: 'location',
                    destination: 'destination',
                    offers: 'offers',
                    news: 'news',
                  }}
                  handleFacilitiesAndTechnicalTab={this.handleFacilitiesAndTechnicalTab}
                  isLicensed={venue.temp_permission !== 'free'}
                  hasMeetingRooms={!!venue.cap_meeting_rooms}
                  hasHealthAndSafety={!!venue.files_health_and_safety.length}
                  hasContacts={!!this.state.venue.public_contacts.length}
                  hasSiteVisitReports={
                    !!this.state.venue.published_site_visits.length
                  }
                  videoLink={venue.video_link}
                  hasDestination={hasDestination}
                  hasOffers={hasOffers}
                  hasArticles={venue.articles.length > 0}
                  virtualTours={this.state.venue.virtual_tours || ''}
                  facilitiesAndTechnical={venue.notes_facilities_technical}
                  hotelBrand={venue.hotel_brand}
                  hotelGroup={venue.hotel_group}
              />
          )}
          {/* MAIN & SIDEBAR */}
          <div className="container pb-8 bg-grey md:pt-3 lg:pt-0">
            {isVenue ? (
              <VenueUpgradeBanner currentVenue={venue} />
            ) : null}
            {!mobile && this.keyInformation(venue)}
            <div className="flex flex-wrap">
              <div className="col col-w-full xl:col-w-3/4">

              <Details
                anchor="details"
                address={this.address()}
                mobile={mobile}
                info={
                  (venue.hotel_brand && venue.hotel_brand.description) ||
                  venue.hotel_brand_description || venue.hotel_group_description
                }
                location={venue.location}
                description={venue.description}
                venue={venue}
                impression_id={impression_id}
                contacts={this.state.venue.public_contacts}
                isCommitted={venue.temp_permission !== "free"}
                twitterLink={venue.twitter_link}
                instagramLink={venue.instagram_link}
                tripadvisorLink={venue.tripadvisor_link}
              />
              <RegisterToViewPanel
                registerText={"To view meeting room specifications, venue news, detailed information about facilities, as well as ESG and site visit reports, please login or register."}
              />

              {/* {hasDestination ? (
                <section
                  id="destination"
                  className="bg-white mb-6 shadow overflow-hidden"
                >
                  <DestinationProfile destination={venue.destination} />
                </section>
              ) : null} */}

              {venue.meeting_rooms.length > 0 ? (
                <RegisterBlurPanel>

                  <MeetingRooms
                    anchor="meeting-rooms"
                    venueId={venue.id}
                    data_score={venue.data_score}
                    impression_id={impression_id}
                    venueName={venue.name}
                    venueSlug={venue.slug}
                    featuredImg={venue.meetingRoomsImg}
                    totalBedrooms={venue.cap_bedrooms}
                    totalMeetingRooms={venue.cap_meeting_rooms}
                    meetingRooms={venue.meeting_rooms}
                    technicalSpec={venue.files_technical_spec}
                    floorplan={venue.floorplan}
                  />

                </RegisterBlurPanel>
              ) : null}
              {venue.files_health_and_safety.length > 0 || venue.covid_accreditation ? (
                <RegisterBlurPanel>
                  <HealthAndSafety
                    documents={venue.files_health_and_safety}
                    mobile={mobile}
                    anchor="health-and-safety"
                    accreditation={venue.covid_accreditation}
                    policyLink={venue.covid_policy_link}
                    venueId={venue.id}
                    dataScore={venue.data_score}
                    impressionId={venue.impression_id}
                  />
                </RegisterBlurPanel>
              ) : null}

              <RegisterBlurPanel>
                <FacilitiesAndTechnical
                  venueId={venue.id}
                  data_score={venue.data_score}
                  impression_id={impression_id}
                  anchor="features"
                  mobile={mobile}
                  activeTab={facilitiesAndTechnicalActiveTab}
                  data={{
                    parkingSpaces: venue.car_parking_number_spaces,
                    freeOnSiteParking: !!venue.has_free_on_site_parking,
                    onSiteEvCharging: !!venue.has_on_site_ev_charging,
                    nearbyParking: !!venue.has_nearby_parking,
                    wheelchairAccessible: !!venue.is_wheelchair_accessible,
                    isDdaCompliant: !!venue.is_dda_compliant,
                    outsideSpace: !!venue.has_outside_space,
                    wifiLink: venue.wifi_link,
                    csrLink: venue.csr_link,
                    csrText: venue.csr_text,
                    leisure: {
                      notes: venue.additional_value_notes,
                      onSite: venue.on_site_leisure_facilities,
                      offSite: venue.off_site_leisure_facilities,
                    },
                    catering: {
                      internal: !!venue.has_own_catering,
                      external: !!venue.has_outside_catering,
                      totalRestaurants: venue.number_of_restaurants,
                      dinnerLunchCapacity: venue.catering_capacity_dinner_lunch,
                      receptionCapacity: venue.reception_catering_capacity,
                    },
                    placeOfInterest: venue.place_of_interest,
                    porterageCharge: venue.porterage,
                    notes: venue.notes_facilities_technical,
                    mobile,
                    usefulDocs: venue.files_overview,
                  }}
                />
              </RegisterBlurPanel>

              {this.props.isLoggedIn && showSiteVisits && (
                <SiteVisitReports
                  reports={venue.published_site_visits}
                  venue={venue}
                  anchor="site-visit-reports"
                  mobile={mobile}
                />
              )}

              {this.props.desktop && this.flagAProblem()}
              {/* <Location
                anchor="location"
                address={this.address()}
                lat={venue.lat}
                lng={venue.long}
              /> */}
              {/* {venue.files_health_and_safety.length > 0 && (
                <HealthAndSafety documents={venue.files_health_and_safety} />
              )} */}
            </div>
            <div className="col col-w-full xl:col-w-1/4">

              {!mobile && (
                <>
                  <CharityAuctionSingle venueId={venue.id} />

                  {!this.props.isLoggedIn && !isLicensed && (
                    <MeetingRoomsAndEvents venue={venue} />
                  )}
                  {this.props.isLoggedIn ? (
                    <CreateShortlist
                      form="venue-shortlist"
                      searchForm="advanced-search"
                      mobile
                      isSingleVenueView
                      venue={venue}
                    />
                  ) : (
                    <ShortlistUnlogged />
                  )}
                  {/*{!isLicensed && <IsThisYourVenue loggedIn={this.props.isLoggedIn}/>}*/}
                  <VenueNote venueId={venue.id} />

                  {/**
                   *  EP-142 Full hide Instant Book (comment out for now)
                   */}
                  {/*<Pricing*/}
                  {/*    meetingRoomFor10={venue.buy_it_now_price_exc_vat}*/}
                  {/*    packages={venue.packages}*/}
                  {/*    venueId={venue.id}*/}
                  {/*    data_score={venue.data_score}*/}
                  {/*    venueSlug={venue.slug}*/}
                  {/*    impression_id={impression_id}*/}
                  {/*    venueName={venue.name}*/}
                  {/*    venuePriceBanding={venue.price_banding}*/}
                  {/*    currency={*/}
                  {/*      venue.currency ? venue.currency.html_code : undefined*/}
                  {/*    }*/}
                  {/*/>*/}
                </>
              )}
              {!mobile && isAgency && isAgencyApproved && <Offers items={venue.offers} anchor={"offers"} />}
              {venue.offers && venue.offers.length > 0 && (
                <RegisterBlurPanel>
                  <SpecialOffers mobile={mobile} offers={venue.offers} anchor={"offers"} />
                </RegisterBlurPanel>
              )}
              {venue.articles.length > 0 && (
                <RegisterBlurPanel>
                  <News items={venue.articles} mobile={mobile} />
                </RegisterBlurPanel>
              )}
              <RegisterBlurPanel>
                <EdgeInsights mobile={mobile} />
              </RegisterBlurPanel>
              {mobile && this.flagAProblem()}
              <RegisterToViewPanel/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      token: token(state),
      mobile: getWidth(state) < 992,
      desktop: getWidth(state) > 1199,
      isLoggedIn: isLoggedIn(state),
      referCode: stateToReferCode(state),
      isAgency: isAgency(state),
      isAgencyApproved: isAgencyApproved(state),
      isInternal: isInternal(state),
      userVenues: venues(state),
      isVenue: isVenue(state),
    }),
    { venueImpression, venueInteraction },
  )(Venue),
);
