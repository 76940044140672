import axios from 'axios';
import * as ROUTES from '../../Router/corporate_routes';
import handleErrors from "./utils";
import {
  NOTE_CREATED,
  NOTE_CREATED_ERROR,
  NOTE_REQUEST,
  NOTES_LIST_REQUEST,
  NOTES_RECEIVED_ERROR,
  NOTES_RECEIVED_REQUEST,
  UNLOAD_NOTE,
  NOTE_UPDATED
} from "../reducers/types";

export const unloadNote = () => ({
  type: UNLOAD_NOTE
});

export const noteRequest = () => ({
  type: NOTE_REQUEST
});

export const noteCreated = (data) => ({
  type: NOTE_CREATED,
  data
});

export const noteUpdated = (data) => ({
  type: NOTE_UPDATED,
  data
});

export const noteCreatedError = (error) => ({
  type: NOTE_CREATED_ERROR,
  error
});

export const notesListRequest = () => ({
  type: NOTES_LIST_REQUEST
});

export const notesReceivedRequest = (data) => ({
  type: NOTES_RECEIVED_REQUEST,
  data
});

export const notesListError = (error) => ({
  type: NOTES_RECEIVED_ERROR,
  error
});

export const getNotesForRfp = (rfpId, token) => {
  return (dispatch) => {
    dispatch(notesListRequest());
    return axios.get(window.API + ROUTES.API.NOTES.LIST.replace(':rfpId', rfpId), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(notesReceivedRequest(response.data))
    }).catch(error => {
      dispatch(notesListError(error));
      handleErrors(error);
    })
  }
};

export const createNote = (rfpId, formValues, token) => {
  return (dispatch) => {
    dispatch(noteRequest());
    return axios.post(window.API + ROUTES.API.NOTES.CREATE.replace(':rfpId', rfpId), formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(noteCreated(response.data))
    })
      .catch(error => {
        dispatch(noteCreatedError(error));
        handleErrors(error);
      });
  };
};

export const updateNote = (rfpId, noteId, formValues, token) => {
  return (dispatch) => {
    dispatch(noteRequest());
    return axios.post(window.API + ROUTES.API.NOTES.EDIT.replace(':rfpId', rfpId).replace(':noteId', noteId), formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(noteUpdated(response.data))
    })
      .catch(error => {
        dispatch(noteCreatedError(error));
        handleErrors(error);
      });
  };
};
