import axios from 'axios';
import * as ROUTES from '../../Router/corporate_routes';
import {
  CORPORATE_ACCOUNT_LOCATION_CREATE,
  CORPORATE_ACCOUNT_LOCATION_CREATE_ERROR,
  CORPORATE_ACCOUNT_LOCATION_RECEIVED,
  CORPORATE_ACCOUNT_LOCATION_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_LOCATION_REQUEST,
  CORPORATE_ACCOUNT_LOCATION_UNLOAD,
  CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED,
  CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_LOCATIONS_LIST_REQUEST,
  CORPORATE_ACCOUNT_LOCATIONS_LIST_UNLOAD,
  CREATE_CORPORATE_ACCOUNT_REQUEST,
  EDIT_CORPORATE_ACCOUNT_LOCATION_ERROR,
  EDIT_CORPORATE_ACCOUNT_LOCATION_REQUEST,
  EDIT_CORPORATE_ACCOUNT_LOCATION_RESPONSE
} from '../reducers/types';
import handleErrors from "./utils";
import {reset} from "redux-form";

export const corporateLocationsListRequest = () => ({
  type: CORPORATE_ACCOUNT_LOCATIONS_LIST_REQUEST
});

export const corporateLocationsListReceived = data => ({
  type: CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED,
  data
});

export const corporateLocationsListReceivedError = error => ({
  type: CORPORATE_ACCOUNT_LOCATIONS_LIST_RECEIVED_ERROR,
  error
});

export const unloadCorporateAccountLocationsList = () => ({
  type: CORPORATE_ACCOUNT_LOCATIONS_LIST_UNLOAD
});

export const corporateAccountLocationRequest = () => ({
  type: CORPORATE_ACCOUNT_LOCATION_REQUEST
});

export const corporateAccountLocationReceived = data => ({
  type: CORPORATE_ACCOUNT_LOCATION_RECEIVED,
  data
});

export const corporateAccountLocationReceivedError = error => ({
  type: CORPORATE_ACCOUNT_LOCATION_RECEIVED_ERROR,
  error
});

export const unloadCorporateAccountLocation = () => ({
  type: CORPORATE_ACCOUNT_LOCATION_UNLOAD
});

export const corporateAccountLocationCreate = data => ({
  type: CORPORATE_ACCOUNT_LOCATION_CREATE,
  data
});

export const corporateAccountLocationCreateError = error => ({
  type: CORPORATE_ACCOUNT_LOCATION_CREATE_ERROR,
  error
});

export const corporateAccountLocationCreateRequest = () => ({
  type: CREATE_CORPORATE_ACCOUNT_REQUEST
});

export const corporateAccountLocationEditRequest = () => ({
  type: EDIT_CORPORATE_ACCOUNT_LOCATION_REQUEST
});

export const corporateAccountLocationEditResponse = data => ({
  type: EDIT_CORPORATE_ACCOUNT_LOCATION_RESPONSE,
  data
});

export const corporateAccountLocationEditError = error => ({
  type: EDIT_CORPORATE_ACCOUNT_LOCATION_ERROR,
  error
});

export const resetCreateForm = () => {
  return (dispatch) => {
    dispatch(reset('corporateAccountLocationForm'));
  };
};

export const fetchCorporateAccountLocations = (slug, token) => {
  return (dispatch) => {
    dispatch(corporateLocationsListRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.LOCATIONS.LIST.replace(':slug', slug), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(corporateLocationsListReceived(response.data)))
      .catch(error => corporateLocationsListReceivedError(error))
  }
};

export const fetchCorporateAccountLocation = (slug, id, token) => {
  return (dispatch) => {
    dispatch(corporateAccountLocationRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.LOCATIONS.SINGLE.replace(':slug', slug).replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(corporateAccountLocationReceived(response.data.data)))
      .catch(error => corporateAccountLocationReceivedError(error))
  }
};

export const createCorporateAccountLocation = (slug, token, formValues) => {
  let formData = JSON.stringify(Object.fromEntries(formValues))
  return (dispatch) => {
    dispatch(corporateAccountLocationCreateRequest());
    return axios.post(window.API + ROUTES.API.CORPORATE.LOCATIONS.CREATE.replace(':slug', slug), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
    }).then(response => dispatch(corporateAccountLocationCreate(response.data.data)))
      .catch(error => {
        corporateAccountLocationCreateError(error)
        handleErrors(error)
      });
  }
};

export const editCorporateAccountLocation = (slug, id, token, formValues) => {
  let formData = JSON.stringify(Object.fromEntries(formValues))
  return (dispatch) => {
    dispatch(corporateAccountLocationEditRequest());
    return axios.post(window.API + ROUTES.API.CORPORATE.LOCATIONS.EDIT.replace(':slug', slug).replace(':id', id), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    }).then(response => corporateAccountLocationEditResponse(response.data))
      .catch(error => {
        corporateAccountLocationEditError(error)
        handleErrors(error)
      });
  }
};