import React from "react";
import {Cell, Pie, PieChart as PieChartComponent, Tooltip} from "recharts";
import {Column, Row} from "../../grid";
import {ResizeContainer} from "../../../pages/admin/Analytics/Components/ResizeContainer";

export const PieChart = ({data, height}) => (
  <Row noBreak>
    <Column w="1/2" className="lg:w-1/2">
      <ResizeContainer height={height || 300}>
        {resizeProps => (
          <PieChartComponent {...resizeProps}>
            <Tooltip/>
            <Pie data={data} dataKey="value" labelLine={false} nameKey="name">
              {data.map(entry => (
                <Cell key={entry.name} fill={entry.color} label={entry.name}/>
              ))}
            </Pie>
          </PieChartComponent>
        )}
      </ResizeContainer>
    </Column>
    <Column w="1/2" className="p-12 lg:w-1/2">
      <table>
        <tbody>
        {data.sort((a, b) => {
          return b.value - a.value
        }).map(entry => (
          <tr
            key={entry.name}
            className="mb-1 font-normal text-sm right"
            style={{
              color: entry.color,
            }}
          >
            <td className="text-left">{entry.label || entry.name}</td>
            <td className="text-right font-bold pl-3">{entry.currency ? entry.currency : ''} {entry.value}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </Column>
  </Row>
);
