import axios from "axios";

const getAvailabilityTypes = async (_, token) => {
  try {
    const response = await axios.get(`${window.API}/rfp/availability-types`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.data.data) {
      return {
        result: {
          options: [],
          data: [],
        },
      };
    }

    const availabilityTypes = response.data.data.map(type => type.data);

    return {
      result: availabilityTypes.map(type => ({
        text: type.description,
        value: type.id,
        positive_availability: type.positive_availability,
        children: (type.child_venue_availability_types || []).map(item => ({
          text: item.description,
          value: item.id,
          requires_date: item.requires_dates, // this is a hack - I need to know if notes or date for field type, currently I know only 1 needs a date so this works
          requires_notes: item.requires_notes, // this is a hack - I need to know if notes or date for field type, currently I know only 1 needs a date so this works
        })),
      })),
    };
  } catch (error) {
    return { error };
  }
};

export { getAvailabilityTypes };
