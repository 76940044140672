import React from "react";
import CmsPage from "Components/CmsPage";
import Breadcrumbs from "Components/Breadcrumbs";

export default class RefineVenueSearch extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="bg-white m-1 mt-4 p-5">
          <Breadcrumbs
            items={[{ name: "Home", to: "/" }, { name: "Refine Venue Search" }]}
          />
          <CmsPage id={565} />
        </div>
      </div>
    );
  }
}
