import React from "react";

class Disclaimer extends React.Component {
  render() {
    return (
      <div className="container bg-white">
        <h1 className="pt-8">Disclaimer</h1>
        <p className="text pt-4 pb-8">
          Whilst every effort has been made to ensure the accuracy of the data
          held on edgevenues.com, the data has been supplied by the venues and
          Trinity Event Solutions are unable to take any responsibility for any
          inaccuracies.
        </p>
      </div>
    );
  }
}

export default Disclaimer;
