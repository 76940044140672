import throttle from "lodash/throttle";

export const loadState = version => {
  if (window.location.search === "?c") {
    window.localStorage.setItem("edge", null);
  }
  try {
    const serializedState = window.localStorage.getItem("edge");
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);
    if (state.version !== version) {
      return undefined;
    }
    return state.state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (store, version, stateToSave) => {
  store.subscribe(
    throttle(() => {
      try {
        window.localStorage.setItem(
          "edge",
          JSON.stringify({
            state: stateToSave(store.getState()),
            version,
          })
        );
      } catch (err) {
        // Ignore for now
      }
    }, 100)
  );
};
