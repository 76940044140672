import React from "react";
import Breadcrumbs from "Components/Breadcrumbs";
import TrinityNews from "./TrinityNews";
import {CATEGORY_GROUP_IDS} from "../../modules/cms";

const TheWord = () => (
  <div className="container bg-white mt-4 p-5">
    <Breadcrumbs items={[{ name: "Home", to: "/" }, { name: "The Word" }]} />
    <TrinityNews categories={CATEGORY_GROUP_IDS.theWord}/>
  </div>
);

export default TheWord;
