import React, { Component } from "react";
import { arrayOf, bool } from "prop-types";
import { Link } from "react-router-dom";

import sortBy from "lodash/sortBy";

import PaginationWrapper from "../../Components/PaginationWrapper";
import Breadcrumbs from "../../Components/Breadcrumbs";

import { offerPropType, connectOffers } from "./common";
import { htmlExcerpt } from "../../modules/string-tools";
import { LoadingHalfPage } from "../../Components/Loading";

const findArticleImage = article => {
  if (article.image) return article.image;

  if (article.venues && article.venues.length > 0) {
    const venueWithFeatureImage = article.venues.find(
      venue => venue.featured_image && venue.featured_image.url
    );

    if (venueWithFeatureImage) return venueWithFeatureImage.featured_image;

    const venueWithImages = article.venues.find(
      venue => venue.images.length > 0
    );

    if (venueWithImages) return venueWithImages.images[0];
  }
};

const renderArticleImage = (article, isAgencyType) => {
  const articleImage = findArticleImage(article);

  const agencyOverlayImage =
    "https://cms.edgevenues.com/wp-content/uploads/2018/11/agency_edge_overlay.png";

  return articleImage ? (
    <div
      style={{
        height: 94,
        width: 94,
        backgroundImage: `url(${articleImage.url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {isAgencyType && (
        <div
          style={{
            height: 94,
            width: 94,
            backgroundImage: `url(${agencyOverlayImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      )}
    </div>
  ) : isAgencyType ? (
    <div
      style={{
        height: 94,
        width: 94,
        background: `#22292f url(${agencyOverlayImage}) no-repeat center`,
      }}
    />
  ) : (
    <div
      style={{
        height: 94,
        width: 94,
        background: `url(/img/logo.png) no-repeat center`,
        backgroundSize: "contain",
      }}
    />
  );
};

const renderOffer = offer => {
  const linkTo = `/offer/${offer.id}`;
  const excerpt = htmlExcerpt(offer.content, 150);

  return (
    <Link to={linkTo} key={offer.id}>
      <div
        className="flex flex-col sm:flex-row mt-1 mb-1 pr-2 text-black"
        style={{
          backgroundColor: "#f5f5f5",
        }}
      >
        <div className="mr-2">{renderArticleImage(offer, false)}</div>
        <div>
          <h4 className="mt-1 mb-1 text-sm">
            {offer.title}
            {offer.venues.length === 1 && ` - ${offer.venues[0].name}`}
          </h4>
          <div className="text-xs">
            <span dangerouslySetInnerHTML={{ __html: excerpt }} />{" "}
            <span
              className="font-heading font-bold uppercase text-xs text-brand"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Read more
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Layout = ({ children }) => (
  <div className="container bg-white mt-4 p-5">
    <Breadcrumbs
      items={[{ name: "Home", to: "/" }, { name: "Special Offers" }]}
    />
    <h1 className="text-3x1 mb-3">Special Offers</h1>
    {children}
  </div>
);

class List extends Component {
  componentDidMount() {
    const { loadArticles } = this.props;
    loadArticles();
  }

  render() {
    const { offers, loading, offerType = "special-offers" } = this.props;

    if (offers.length === 0 && loading) {
      return (
        <Layout>
          <LoadingHalfPage />
        </Layout>
      );
    }

    const offersFiltered = sortBy(
      offers.filter(offer => offer.type.name === offerType),
      article => article.updated_at
    ).reverse();

    return (
      <Layout>
        <PaginationWrapper
          items={offersFiltered}
          itemsPerPage={12}
          render={({ items }) => (
            <div style={{ minHeight: "50vh" }}>
              <div className="flex flex-wrap -mx-4">
                {items.map(offer => (
                  <div key={offer.id} className="w-full lg:w-1/2 xl:w-1/3 px-4">
                    {renderOffer(offer)}
                  </div>
                ))}
              </div>
            </div>
          )}
        />
      </Layout>
    );
  }
}

List.propTypes = {
  offers: arrayOf(offerPropType).isRequired,
  loading: bool.isRequired,
};

export default connectOffers(List);
