import { format } from "date-fns";
import groupBy from "lodash/groupBy";
import {
  createDateRange,
  dateRangeFilter,
} from "../../../pages/admin/Analytics/date-utils";
import getDatesArray from "../get-dates-array";
import groupByDay from "../group-by-day";
import groupByDayHighest from "../group-by-day-highest";
import {
  allTypes,
  searchClicks,
  searchImpressions,
  uniqByUuid,
  venueInteractions,
  venuePageData,
  venuePageViews,
} from "./utils";
import {VENUE_TAGS} from "../tags";
import groupByMonth from "../group-by-month";

export const venueEnquiries = id => state =>
  venuePageData(id)(state)
    ? venuePageData(id)(state).interactions.filter(interaction =>
        interaction.type.startsWith("rfq_")
      )
    : null;

export const searchImpressionsByDay = (id, start, end) => state => {
  return groupByDay(searchImpressions(id)(state), start, end);
};

export const searchClicksByDay = (id, start, end) => state => {
  return groupByDay(searchClicks(id)(state), start, end);
};

export const venuePageViewsByDay = (id, start, end) => state => {
  return groupByDay(venuePageViews(id)(state), start, end);
};

export const engagementsByDay = (id, start, end) => state => {
  const venueInteractionsList = venueInteractions(id)(state);

  return groupByDay(venueInteractionsList, start, end);
};

export const enquiriesByDay = (id, start, end) => state => {
  return groupByDay(venueEnquiries(id)(state), start, end);
};

export const dataScoreByDay = (id, start, end) => state => {
  const all = allTypes(id)(state) || [];
  return groupByDayHighest(all, start, end, "data_score");
};

export const searchRankByDay = (id, start, end) => state => {
  const data = searchImpressions(id)(state);
  if (!data) return null;
  const byDay = groupBy(data, item => {
    return item.day;
  });
  const dates = getDatesArray(start, end);
  if (!Array.isArray(dates)) {
    return null;
  }
  return dates.map(date => {
    let rank = 0;
    let total = 0;
    if (Array.isArray(byDay[date])) {
      byDay[date].forEach(day => {
        total++;
        rank = rank + day.rank;
      });
    }
    return {
      day: format(date, "ddd Do"),
      total: parseInt(rank / total, 10),
    };
  });
};

export const audienceByDay = (id, start, end) => state => {
  const all = allTypes(id)(state) || [];

  const dateRange = createDateRange(start, end);

  const filtered = dateRangeFilter(all, dateRange, "day");
  const grouped = groupBy(filtered, item => item.user_type);

  const getUniqTotal = items => uniqByUuid(items).length;

  return {
    "All Visitors": groupByDay(filtered, start, end, getUniqTotal),
    Agency: groupByDay(grouped.Agency || [], start, end, getUniqTotal),
    Corporate: groupByDay(grouped.Corporate || [], start, end, getUniqTotal),
    Hotel: groupByDay(grouped.Hotel || [], start, end, getUniqTotal),
    Other: groupByDay(
      [
        ...(grouped.Other || []),
        ...(grouped.Unknown || []),
        ...(grouped.null || []),
      ],
      start,
      end,
      getUniqTotal
    ),
    Individual: groupByDay(grouped.Individual || [], start, end, getUniqTotal),
    "Association/Charity": groupByDay(
      grouped.Charity || [],
      start,
      end,
      getUniqTotal
    ),
  };
};

export const venuePageByDay = (id, start, end) => state => {
  const all = venuePageViews(id)(state) || [];

  const dateRange = createDateRange(start, end);

  const pageViews = dateRangeFilter(all, dateRange, "day");

  return {
    "Page Views": groupByDay(pageViews, start, end),
    Visitors: groupByDay(pageViews, start, end, arr => {
      return uniqByUuid(arr).length;
    }),
    "New Visitors": groupByDay(pageViews, start, end, arr => {
      return arr.filter(item => item.new).length;
    }),
    Engagements: engagementsByDay(id, start, end)(state),
  };
};

export const engagementSplitByDay = (id, start, end) => state => {
  const all = venueInteractions(id)(state) || [];
  const dateRange = createDateRange(start, end);
  const engagements = dateRangeFilter(all, dateRange, "day");

  return {
    Telephone: groupByDay(
      engagements.filter(e =>
        [
          VENUE_TAGS.TELEPHONE.CLICK_CONTACT_PHONE,
          VENUE_TAGS.TELEPHONE.VIEW_PHONE
        ].includes(e.type)
      ),
      start,
      end
    ),
    Email: groupByDay(
      engagements.filter(e =>
        [
          VENUE_TAGS.EMAIL.CLICK_CONTACT_EMAIL,
          VENUE_TAGS.EMAIL.VIEW_EMAIL
        ].includes(e.type)
      ),
      start,
      end
    ),
    Website: groupByDay(
      engagements.filter(e => e.type === VENUE_TAGS.WEBSITE.VIEW_WEBSITE),
      start,
      end
    ),
    "Image Views": groupByDay(
      engagements.filter(e => e.type === "view_gallery_image"),
      start,
      end
    ),
    "Web Enquiries": groupByDay(
      engagements.filter(
        e => e.type.startsWith("rfq_") || e.type === VENUE_TAGS.WEB_ENQUIRIES.BUY_IT_NOW
      ),
      start,
      end
    ),
  };
};

export const engagementSplitByMonth = (id, start, end) => state => {
  const all = venueInteractions(id)(state) || [];
  const dateRange = createDateRange(start, end);
  const engagements = dateRangeFilter(all, dateRange, "day");
  return {
    Telephone: groupByMonth(
        engagements.filter(e =>
            [
              VENUE_TAGS.TELEPHONE.CLICK_CONTACT_PHONE,
              VENUE_TAGS.TELEPHONE.CLICK_CALL_VENUE,
              VENUE_TAGS.TELEPHONE.VIEW_PHONE,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_NEWS_PHONE_CLICK,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_SPECIAL_OFFERS_PHONE_CLICK,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_FAMTRIP_PHONE_CLICK,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_RENOVATION_PHONE_CLICK,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_COMMISSION_OFFERS_PHONE_CLICK
            ].includes(e.type)
        ),
        start,
        end
    ),
    Email: groupByMonth(
        engagements.filter(e =>
            [
              VENUE_TAGS.EMAIL.CLICK_CONTACT_EMAIL,
              VENUE_TAGS.EMAIL.VIEW_EMAIL,
              VENUE_TAGS.EMAIL.CLICK_EMAIL_VENUE,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_NEWS_EMAIL_CLICK,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_SPECIAL_OFFERS_EMAIL_CLICK,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_FAMTRIP_EMAIL_CLICK,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_RENOVATION_EMAIL_CLICK,
              VENUE_TAGS.VENUE_CONTENT_FOOTER_CLICKS.VENUE_CONTENT_COMMISSION_OFFERS_EMAIL_CLICK
            ].includes(e.type)
        ),
        start,
        end
    ),
    Website: groupByMonth(
        engagements.filter(e => e.type === VENUE_TAGS.WEBSITE.VIEW_WEBSITE),
        start,
        end
    ),
    "Image Views": groupByMonth(
        engagements.filter(e => e.type === "view_gallery_image"),
        start,
        end
    ),
    "Web Enquiries": groupByMonth(
        engagements.filter(
            e => e.type.startsWith("rfq_") || e.type === VENUE_TAGS.WEB_ENQUIRIES.BUY_IT_NOW
        ),
        start,
        end
    ),
  };
};
