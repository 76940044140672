import React from "react";
import { oneOfType, string, arrayOf, node } from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

ContentFeature.propTypes = {
  classes: oneOfType([string, arrayOf(string)]),
  title: oneOfType([string, node]),
  children: node.isRequired,
  link: string,
  linkText: string,
  H: string,
};

export default function ContentFeature({
  classes,
  title,
  children,
  link,
  linkText,
  H = "h3",
}) {
  return (
    <article className={classNames("overflow-hidden", classes)}>
      <H className="text-xl mb-2">{title}</H>
      {children}
      {link && (
        <Link
          to={link}
          className="font-heading font-bold tracking-loose uppercase"
        >
          {linkText}
        </Link>
      )}
    </article>
  );
}
