import React from "react";
import classNames from "classnames";
import { string, object, array, oneOfType } from "prop-types";
import Label from "./Label";
import Error from "./Error";

const isSelected = (inputValue, optionValue, onChange = null) => {
  if (typeof inputValue === "boolean" && typeof optionValue === "number") {
    return !!optionValue === inputValue;
  }
  if(onChange)
  {
    onChange(optionValue === inputValue)
  }
  return optionValue === inputValue;
};

const Radio = ({
  meta: { touched, error, warning },
  input,
  label,
  wrapperClassName,
  labelClassName = false,
  options,
  onChange = null,
  block,
}) => {
  return (
    <div className={wrapperClassName || "mb-5"}>
      {label && <Label name={input.name} text={label} />}
      <div className={block ? "mb-3" : "flex flex-wrap"}>
        {options.map(option => {
          const value = typeof option === "string" ? option : option.value;
          const text = typeof option === "string" ? option : option.text;
          return (
            <label
              key={value}
              role="button"
              tabIndex="0"
              className={labelClassName ? labelClassName : "text-sm mr-5 flex items-center inline-block outline-none border border-transparent focus:border-primary " + labelClassName}
              onClick={() => {
                input.onChange(value, onChange);
              }}
              onKeyPress={() => {
                input.onChange(value, onChange);
              }}
              style={{
                height: 28,
              }}
            >
              <i
                className={classNames("far mr-2", {
                  "fa-dot-circle": isSelected(input.value, value,onChange),
                  "fa-circle": !isSelected(input.value, value,onChange),
                })}
              />
              {text}
            </label>
          );
        })}
      </div>
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
};

Radio.propTypes = {
  options: array,
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]),
  wrapperClassName: string,
  inputClassName: string,
};

Radio.defaultProps = {
  options: [{ text: "Yes", value: 1 }, { text: "No", value: 0 }],
};

export default Radio;
