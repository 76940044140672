import React from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { required, phone } from "modules/validation";
import Input from "Components/Form/Input";
import { ButtonPrimary } from "Components/Button";
import { ApiForm, Succeeded } from "Components/Form";
import Loading from "Components/Loading";
import UserImageProfileForm from "../../Components/Form/Profile/UserImageProfileForm";
import CorporateUserFields from "../../Components/Form/Profile/CorporateUserFields";
import {isCorporate} from "../../modules/auth";

const v = {
  first_name: required(),
  surname: required(),
  phone,
};

const mapStateToProps = state => ({
  isCorporate: isCorporate(state),
})

class Profile extends React.Component {
  render() {
    return (
      <div className="py-5">
        <h1 className="mb-5 uppercase">Edit your profile</h1>
        <ApiForm
          form="profile"
          fields={[
            "first_name",
            "surname",
            "job_title",
            "email",
            "phone",
            "mobile",
            "address1",
            "address2",
            "address3",
            "town",
            "county",
            "country",
            "corporate_telephone",
            "corporate_email",
            "corporate_job_title",
            "corporate_department_id"
            // "postcode"
          ]}
          handleResponse={() => {
            // nothing
          }}
          url="/users/me/profile"
          render={(submitting, failed, succeeded) => (
            <React.Fragment>
              <div className="md:flex">
                <Field
                  component={Input}
                  name="first_name"
                  label="First name"
                  size="md"
                  validate={v.first_name}
                />
                <Field
                  component={Input}
                  name="surname"
                  label="Surname"
                  size="md"
                  validate={v.surname}
                />
                <Field
                  component={Input}
                  name="job_title"
                  label="Job title"
                  size="md"
                />
                <Field
                  name="email"
                  label="Email"
                  component={input => <p>{input.value}</p>}
                />
                <Field
                  component={Input}
                  name="phone"
                  label="Phone"
                  size="sm"
                  validate={v.phone}
                />
                <Field
                  component={Input}
                  name="mobile"
                  label="Mobile"
                  size="sm"
                  validate={v.phone}
                />
              </div>
              <div className="md:flex">
                <Field
                  component={Input}
                  name="address1"
                  size="lg"
                  label="Address Line 1"
                />
                <Field
                  component={Input}
                  name="address2"
                  size="lg"
                  label="Address Line 2"
                />
                <Field
                  component={Input}
                  name="address3"
                  size="lg"
                  label="Address Line 3"
                />
              </div>
              <div className="md:flex">
                <Field component={Input} name="town" size="md" label="town" />
                <Field
                  component={Input}
                  name="county"
                  size="md"
                  label="county"
                />
                <Field
                  component={Input}
                  name="country"
                  size="md"
                  label="country"
                />
                {/* <Field
                  component={Input}
                  name="postcode"
                  size="sm"
                  label="Postcode"
                /> */}
              </div>
              <UserImageProfileForm />
              {this.props.isCorporate && (
                <CorporateUserFields />
              )}
              <div className="md:flex">
                <ButtonPrimary type="submit" classes="self-end mb-5">
                  {submitting ? <Loading inline/> : "Save"}
                </ButtonPrimary>
              </div>
              {succeeded ? <Succeeded/> : null}
            </React.Fragment>
          )}
        />

      </div>
    );
  }
}

export default connect(mapStateToProps, null)(Profile);
