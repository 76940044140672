import {calculateCommissionAs} from "../../../rfp/Confirmation/Helpers/calculateTotals";
import {func} from "prop-types";
import {getCurrencySymbol} from "../../../../../utils";
let commissionFormItem = (
    lineItem,
    quantity,
    rate,
    noOfNights,
    value,
    value_vat,
    rateIncVat,
    finalValue,
    vat,
    commission,
    applied_on,
    commissionValues,
    type,
    id,
    formValues,
) => {
    let key = type + '_' + id;

    formValues[`id_${key}`] = id
    formValues[`type_${key}`] = type
    formValues[`line_item_${key}`] = lineItem
    formValues[`value_${key}`] = parseFloat(value).toFixed(2)
    formValues[`value_netval_${key}`] = applied_on == "Gross" ? Number(parseFloat(value).toFixed(2)) - Number(parseFloat(value_vat).toFixed(2)) : parseFloat(value).toFixed(2)
    formValues[`value_vat_${key}`] =  parseFloat(value_vat).toFixed(2)
    formValues[`final_value_${key}`] =  parseFloat(finalValue).toFixed(2)
    formValues[`price_includes_vat_${key}`] = rateIncVat
    formValues[`vat_${key}`] = vat
    formValues[`commission_${key}`] = commission
    formValues[`applied_on_${key}`] = applied_on
    formValues[`commission_value_${key}`] = commissionValues.total;
    formValues[`commission_value_vat_${key}`] = commissionValues.vat;
}

function createCommissionFormItemFromCommission(commissionItem, formValues, totals, commissionTotals, includesVat, currency)
{
    return  commissionFormItem(
        commissionItem.description,
        commissionItem.quantity,
        commissionItem.per_person_price_net,
        commissionItem.no_of_nights,
        commissionItem.commission_applied_on === "Gross" ? Number(parseFloat((currency && commissionItem.price_net_src_cur ? commissionItem.price_net_src_cur : commissionItem.price_net)).toFixed(2))+ Number(parseFloat((currency && commissionItem.price_vat_src_cur ? commissionItem.price_vat_src_cur : commissionItem.price_vat)).toFixed(2)) : parseFloat((currency && commissionItem.price_net_src_cur ? commissionItem.price_net_src_cur : commissionItem.price_net)).toFixed(2),
        totals.vat,
        includesVat ? Boolean(commissionItem.price_includes_vat) : includesVat,
        totals.total,
        commissionItem.vat_rate_id,
        commissionItem.commission_percentage,
        commissionItem.commission_applied_on,
        includesVat ? commissionTotals : {total: commissionTotals.total, vat: 0},
        commissionItem.type,
        commissionItem.type_identifier,
        formValues
    );
}

function getDescriptionValues(quantity, rate, priceNet,  noOfNights, currency, noDelegateText = false)
{
    return ` - (${quantity ? quantity + ' ' + (noDelegateText ? '' : 'delegates') : 1 + ' qty'} @ ${currency && currency.currency_code ? getCurrencySymbol({currency_code: currency.currency_code}) : '£'}${rate ? rate : priceNet}${noOfNights && noOfNights > 1 ? ' x ' + noOfNights + ' days': ''})`
}
function getCommissionItemsForStep(enquiryVenueData, step)
{
    return enquiryVenueData.proposal_data.commission_items.find(item => parseInt(item.step_id) === step) ?
        enquiryVenueData.proposal_data.commission_items.find(item => parseInt(item.step_id) === step) :
      {
          include_vat: false,
          rate: 0,
          applied_on: "Net"
      };
}

export
{
    commissionFormItem,
    getDescriptionValues,
    getCommissionItemsForStep,
    createCommissionFormItemFromCommission
};
