import React, {Fragment, useEffect, useRef, useState} from "react";
import {GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs} from "react-google-maps"
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import {FaInfoCircle} from "react-icons/fa";
import {withRouter} from "react-router-dom";
import {ButtonPrimary} from "../../../../Components/Button";

const FavouriteLocationsMap = (props) => {
  const map = useRef();
  const [locationId, setLocationId] = useState(null);
  const bounds = new window.google.maps.LatLngBounds()
  const center = props.defaultCenter;

  useEffect(() => {
    if (props.locations.length > 0) {
      let LatLngList = props.locations.map((location) => {
        const {lat, long} = location.venue;
        return new window.google.maps.LatLng(lat, long);
      })
      LatLngList.push(new window.google.maps.LatLng(center.lat, center.lng))
      for (let i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
        bounds.extend(LatLngList[i]);
      }
      try {
        map.current.fitBounds(bounds);
      } catch (error) {
        console.log('Map reference not available')
      }
    }
  }, []);

  const openInfoMarker = (locationId) => {
    setLocationId(locationId)
  }

  const closeWindow = () => {
    setLocationId(null)
  }

  return (
    <GoogleMap
      ref={map}
      defaultZoom={10}
      defaultCenter={!locationId && center}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
      }}
    >
      {props.locations.map(location => {
          return (
            <MarkerWithLabel
              position={{
                lat: parseFloat(location.venue.lat),
                lng: parseFloat(location.venue.long)
              }}
              key={location.id}
              labelAnchor={new window.google.maps.Point(0, 30)}
              icon="/img/hotel-marker.svg"
              clickable
              labelClass={`text-white ${
                locationId && locationId === location.id
                  ? "bg-primary"
                  : "bg-black"
              } font-heading text-base rounded p-2 marker-label`}
              onClick={() => openInfoMarker(location.id)}
            >
              <Fragment>
                <div
                  className="marker-indicator"
                  style={{
                    width: 15,
                    height: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FaInfoCircle/>
                  {locationId === location.id && (
                    <InfoWindow
                      onCloseClick={() => closeWindow()}
                      position={{
                        lat: parseFloat(location.venue.lat),
                        lng: parseFloat(location.venue.long),
                      }}
                    >
                      <Fragment>
                        <div
                          className="w-full p-3 h-32 bg-cover bg-center bg-no-repeat"
                          style={{
                            backgroundImage: `url(${
                              location.venue.image
                                ? `${window.API}/venues/${location.venue.slug}/images/${location.venue.image}`
                                : "/img/venue-placeholder.jpg"
                            })`,
                          }}
                        >
                        </div>
                        <div className="py-3">
                          <div className="font-normal text-base mb-4">
                            <a className={'text-black'} href={`/venue/${location.venue.slug}`}>{location.venue.name}</a>
                          </div>
                          <div className="min-w-48">
                            <div className="mb-2">
                              <div className="flex">
                                <div className="w-24">
                                  <div className="font-normal text-base">
                                    <ButtonPrimary
                                      onClick={() => window.location.href = `/request-for-proposal?venues=${location.venue.id}`}
                                    >
                                      Enquire
                                    </ButtonPrimary>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    </InfoWindow>
                  )}
                </div>
              </Fragment>
            </MarkerWithLabel>)
        }
      )}
      <Marker position={props.defaultCenter}/>
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap((withRouter(FavouriteLocationsMap))))
