import React from "react";
import VenueSearch from "../Components/VenueSearch";

const SearchVenues = props => {
  return (
    <div className={`${props.className}`}>
      <h2 className="mb-6">Search venues</h2>
      <VenueSearch />
    </div>
  );
};

export default SearchVenues;
