import { createContext } from "react";

const ChecksheetContext = createContext({
  additionalItems: [],
  cateringTypes: [],
  cateringLocations: [],
  contacts: [],
  equipment: [],
  form: "",
  nextPage: () => {},
  onChange: () => {},
  onUpdateAdditionalItems: () => {},
  onUpdateRoomPurposes: () => {},
  onUpdateCateringLocations: () => {},
  onUpdateSupplementaryCateringType: () => {},
  onUpdateVenue: () => {},
  previousPage: () => {},
  rfp: null,
  roomPurposes: [],
  roomStyles: [],
  supplementaryCateringTypes: [],
  token: "",
  values: {},
  venue: null,
  vatRates: {}
});

export default ChecksheetContext;
