import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { loadState, saveState } from "./modules/session";
import reducer from "./reducer";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import { setUserType } from "modules/google-tag-manager";
const VERSION = "0.1";

const initialState = loadState(VERSION);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const store = createStore(
  (state, action) => {
    if (action.type === "LOGOUT") {
      state = undefined;
    }

    const newState = reducer(state, action);

    setUserType(newState);

    return newState;
  },
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

store.dispatch({ type: "@@global/INIT" });

// Remove analytics for now
const pickStoredState = state => {
  state = omit(state, ["analytics", "form.analytics"]);

  state.form = omitBy(state.form, (_, key) => key.includes("NO_STORE"));

  return state;
};

saveState(store, VERSION, pickStoredState);

export default store;
