import React from "react";
import { ButtonBrand, ButtonOutlined } from "Components/Button";
import { useHistory, useLocation } from "react-router-dom";

const MeetingRoomsAndEvents = ({ venue }) => {
  const { name } = venue;

  const shareEmailBody = () => {
    const href = window.location.href;

    const from = "EDGE Venues";

    const content = `Venue ${name} has been shared with you.

    ${href}

${from}`;

    return encodeURIComponent(content);
  };

  const history = useHistory();
  const location = useLocation();

  return (
    <div className="bg-white p-4 mb-5 text-center">
      <h3 className="font-heading mb-4">Meeting Rooms and Events</h3>
      <ButtonBrand
        classes="w-full mb-2"
        onClick={() =>
          history.push(
            `/login?return=${encodeURI(location.pathname)}&logInToProceed=1`
          )
        }
      >
        Enquire now
      </ButtonBrand>
      <a href={`mailto:?subject=${name}&body=${shareEmailBody()}`}>
        <ButtonOutlined classes="border-2 border-green text-green w-full">
          Share venue via email
        </ButtonOutlined>
      </a>
    </div>
  );
};

export default MeetingRoomsAndEvents;
