import React, {Fragment, useEffect, useState} from "react";
import EventWrapper from "./EventWrapper";
import moment from 'moment';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import {
  fetchCorporateEvents,
  fetchCountries,
  fetchIndustryEvents,
  fetchOwnEvents,
  fetchPublicEvents,
  unloadBookedEvents,
  unloadIndustryEvents,
  unloadOwnEvents,
  unloadPublicEvents,
  deleteEvent
} from "../../../../redux/actions";
import {connect} from 'react-redux';
import {mapBookedEventToCalendarEvent, mapEventToCalendarEvent, statusKeyDiv} from "./mapEvents";
import {token, isCorporate} from "../../../../modules/auth";
import ViewEventModal from "../../../../Components/corporate/ViewEventModal";
import {countriesOptions} from "../../../../modules/countries-options";
import ViewBookedEvent from "../../../../Components/corporate/ViewBookedEvent";
import {CalendarToolbar} from "./CalendarToolbar";
import {companyObject, isEventSpecialist, isRoleEdgeAdmin} from "../../../../modules/permissions";
import NotFound from "../../../NotFound";

const CalendarView = (props) => {
  const [showEventModal, setShowEventModal] = useState(false)
  const [showBookedEventModal, setShowBookedEventModal] = useState(false)
  const [event, setEvent] = useState({})
  const [activeFilter, setActiveFilter] = useState("all");
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    const {token, company_id} = props;
    props.fetchCountries(token);
    props.fetchPublicEvents(token);
    if (company_id) {
      props.fetchIndustryEvents(token, company_id);
      props.fetchCorporateEvents(token, company_id);
    }
    return () => {
      props.unloadIndustryEvents();
      props.unloadBookedEvents();
      props.unloadPublicEvents();
    }
  }, [])

  const eventFiltersHandler = filterName => {
    setActiveFilter(filterName)
    if (filterName === "all") {
      showAllEvents()
    } else if (filterName === "ownEvents") {
      fetchOwnEventsHandler()
    } else if (filterName === "companyEvents") {
      fetchCorporateEventsHandler()
    } else if (filterName === "industryEvents") {
      fetchIndustryEventsHandler()
    } else if (filterName === "publicEvents") {
      fetchPublicEventsHandler()
    }
  }

  const unloadEvents = () => {
    props.unloadIndustryEvents()
    props.unloadBookedEvents()
    props.unloadPublicEvents()
    props.unloadOwnEvents()
  }

  const fetchPublicEventsHandler = () => {
    unloadEvents()
    return props.fetchPublicEvents(props.token)
  }

  const fetchIndustryEventsHandler = () => {
    unloadEvents()
    if (props.company_id) {
      return props.fetchIndustryEvents(props.token, props.company_id);
    }
  }

  const handleDeleteEvent = (event, token) => {
    return props.deleteEvent(event, token);
  }

  const showAllEvents = () => {
    unloadEvents();
    fetchPublicEventsHandler();
    fetchIndustryEventsHandler();
    fetchCorporateEventsHandler();
  }

  const fetchCorporateEventsHandler = () => {
    unloadEvents()
    if (props.company_id) {
      return props.fetchCorporateEvents(props.token, props.company_id)
    }
  }

  const fetchOwnEventsHandler = () => {
    unloadEvents()
    if (!props.isRoleEdgeAdmin) {
      return props.fetchOwnEvents(props.token)
    }
  }

  const {
    publicEvents,
    industryEvents,
    bookedEvents,
    ownEvents,
    countries,
    isRoleEdgeAdmin,
    corporateAccountId,
    isCorporate
  } = props;
  let eventsArray = [];
  let publicEventArray = publicEvents.map(publicEvent => {
    return mapEventToCalendarEvent(publicEvent, 'public')
  });
  let industryEventArray = industryEvents.map(industryEvent => {
    return mapEventToCalendarEvent(industryEvent, 'industry')
  });
  let bookedEventArray = bookedEvents.map(bookedEvent => {
    return mapBookedEventToCalendarEvent(bookedEvent)
  });
  let ownEventsArray = ownEvents.map(ownEvent => {
    return mapBookedEventToCalendarEvent(ownEvent)
  });
  Array.prototype.push.apply(eventsArray, industryEventArray);
  Array.prototype.push.apply(eventsArray, bookedEventArray);
  Array.prototype.push.apply(eventsArray, publicEventArray);
  Array.prototype.push.apply(eventsArray, ownEventsArray);

  const handleSelectEvent = (event) => {
    if (event.bookedEvent) {
      setShowBookedEventModal(true)
    } else {
      setShowEventModal(true)
    }
    setEvent(event);
  }

  const handleEventModalClose = () => {
    setShowBookedEventModal(false)
    setShowEventModal(false)
  }

  const accountId = isCorporate ? corporateAccountId : props.match.params.slug;

  if (accountId != corporateAccountId && !isRoleEdgeAdmin) {
    return <NotFound/>
  }

  return (
    <Fragment>
      <div className="min-w-full">
        <h1 className="my-4">
          Company Events Calendar
        </h1>
        <div className={'bg-white p-5'}>
          {statusKeyDiv()}
          <div className="row">
            <div className="col">
              <Calendar
                style={{
                  height: 750,
                }}
                views={['month']}
                popup
                events={eventsArray}
                onClick={{}}
                onSelectEvent={event => handleSelectEvent(event)}
                step={60}
                showMultiDayTimes
                defaultDate={new Date()}
                localizer={localizer}
                components={{
                  event: EventWrapper,
                  toolbar: props => {
                    return (
                      <CalendarToolbar
                        {...props}
                        showAllEvents={showAllEvents}
                        filterOwnEvents={fetchOwnEventsHandler}
                        filterPublicEvents={fetchPublicEventsHandler}
                        filterIndustryEvents={fetchIndustryEventsHandler}
                        filterCorporateEvents={fetchCorporateEventsHandler}
                        isEdgeAdmin={isRoleEdgeAdmin}
                        eventFiltersHandler={eventFiltersHandler}
                        activeFilter={activeFilter}
                      />
                    )
                  },
                }}
              />
              {showEventModal && (
                <ViewEventModal
                  event={event}
                  onClose={handleEventModalClose}
                  countries={countriesOptions(countries)}
                  deleteEvent={handleDeleteEvent}
                />
              )}
              {showBookedEventModal && (
                <ViewBookedEvent
                  event={event}
                  onClose={handleEventModalClose}
                  countries={countriesOptions(countries)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state, props) => {
  let company;
  let isCorporateAccount = isCorporate(state);
  if (isCorporateAccount) {
    company = companyObject(state.auth)
  }
  return {
    ...state.publicEvents,
    ...state.industryEvents,
    ...state.bookedEvents,
    ...state.countries,
    token: token(state),
    company_id: company ? company.id : props.account && props.account.company ? props.account.company.id : 0,
    corporateAccountId: props.account && props.account.id,
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    isEventSpecialist: isEventSpecialist(state.auth),
    isCorporate: isCorporateAccount
  };
};


const mapDispatchToProps = {
  fetchIndustryEvents,
  fetchPublicEvents,
  fetchCorporateEvents,
  fetchOwnEvents,
  fetchCountries,
  unloadIndustryEvents,
  unloadPublicEvents,
  unloadOwnEvents,
  unloadBookedEvents,
  deleteEvent
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView)