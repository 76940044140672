import {
  PROPOSAL_INVITE_REQUEST,
  PROPOSAL_INVITE_RECEIVED,
  PROPOSAL_INVITE_RECEIVED_ERROR,
  PROPOSAL_UNLOAD
} from "./types";

export const proposal = (state = {
  proposal: {},
  isFetchingProposal: false,
  error: false,
  accessDenied: false
}, action) => {
  switch (action.type) {
    case PROPOSAL_INVITE_REQUEST:
      return {
        ...state,
        isFetchingProposal: true
      }
    case PROPOSAL_INVITE_RECEIVED:
      return {
        ...state,
        isFetchingProposal: false,
        proposal: action.data.data,
        accessDenied: false
      }
    case PROPOSAL_INVITE_RECEIVED_ERROR:
      return {
        ...state,
        error: true,
        isFetchingProposal: false,
      }
    case PROPOSAL_UNLOAD:
      return {
        ...state,
        proposal: {},
        isFetchingProposal: false,
        error: false
      }
    default:
      return state;
  }
}