import * as ROUTES from "../Router/corporate_routes";
import {Link} from "react-router-dom";
import React from "react";

let AdminListActionButton = ({to, text}) => {


    return (
        <Link
            className="h-13 inline-block tracking-loose font-semibold font-heading px-6 py-4 w-40
                            uppercase no-underline flex text-center justify-center items-center text-white
                            border-brand bg-green hover:bg-dark-green hover:text-white visited:bg-dark-green hover:shadow-md"
            style={{transition: "color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s"}}
            to={to}>{text}</Link>
    )
}

export default AdminListActionButton