import axios from "axios";
import { SubmissionError } from "redux-form";
import moment from "moment";
import parseErrors from "modules/parse-errors";
import defaultParseResponse from "./parseResponse";
import { createHandleAxiosError } from "../../../modules/api-tools";

export default (values, props) => {
  const {
    url, // user
    fields, // user
    fieldsWithText, // user
    initializeEmptyRow, //user
    sendTheLot, //user
    params, //user
    pluckTheIds,
    pluckTheEquipmentIds,
    handleResponse, // user (optional callback)
    parseResponse, // user (optional data parser) -> not sure we'll ever need this?
    token, // store
    spanshot, // store/redux-form
    initialize, // redux-form action
    history, // react router
    location, // react router
  } = props;

  const data = { ...values, ...params };
  Object.keys(data).forEach(key => {
    // ignore null & pristine values
    if (!sendTheLot && (data[key] === null || data[key] === spanshot[key])) {
      delete data[key];
    }
    // intentionally emptied values utilise nullable
    if (data[key] === "") {
      data[key] = null;
    }

    if (moment.isMoment(data[key])) {
      data[key] = data[key].format("YYYY-MM-DD hh:mm:ss");
    }

    if (pluckTheEquipmentIds && !!~pluckTheEquipmentIds.indexOf(key)) {
      data[key] = data[key].map(v => ({
        equipment_id: v,
      }));
    }
  });

  const handleAxiosError = createHandleAxiosError({ history, location });

  return axios
    .post(window.API + url, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      // if server fails to save the new data it'll be stripped from the form
      initialize(
        parseResponse
          ? parseResponse(
              response.data,
              fields,
              fieldsWithText,
              initializeEmptyRow
            )
          : defaultParseResponse(
              response.data,
              fields,
              fieldsWithText,
              initializeEmptyRow,
              pluckTheIds,
              pluckTheEquipmentIds
            )
      );

      // custom response handler (e.g. good for updating the slug)
      handleResponse && handleResponse(response.data);
    })
    .catch(
      handleAxiosError(err => {
        throw new SubmissionError({
          ...parseErrors(err),
          _error: "Sorry, there was an error submitting your data",
        });
      })
    );
};
