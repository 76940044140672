import React from "react";
import { LabelledText, WhitePanelWithHeader } from "ui";

const AgencySpecific = ({
                          client_name,
                          company_name,
                          company_branch,
                          industry_sector,
                          share_with_venues,
                          estimated_value,
                          estimated_commission,
                          label,
                          original_client,
                          client_email,
                          client_phone,
                        }) => {
  return (
    <WhitePanelWithHeader title={label ? label : "Agency"} icon="far fa-user">
      <LabelledText
        label="Client Name"
        text={(client_name && company_name) ? client_name + ` (${company_name})` : "Not Provided"}
      />
      {client_email ? (
        <LabelledText label="Client Email" text={client_email} />
      ) : null}
      {client_phone ? (
        <LabelledText label="Client Phone" text={client_phone} />
      ) : null}
      {company_branch ? (
        <LabelledText label="Branch Name" text={company_branch} />
      ) : null}
      {original_client && <LabelledText label="Original Client" text={original_client} />}
      <LabelledText label="Share client name with venues" text={share_with_venues ? "Yes" : "No"} />
      {industry_sector && (
        <LabelledText label="Industry Type" text={industry_sector.name ? industry_sector.name : "Not Provided"} />
      )}
      <LabelledText label="Estimated Value" text={estimated_value ? estimated_value : "Not Provided"} />
      <LabelledText label="Estimated Commission" text={estimated_commission ? estimated_commission : "Not Provided"} />
    </WhitePanelWithHeader>
  );
};

export default AgencySpecific;
