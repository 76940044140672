import {
    calculateCommissionAs,
    calculateCommissionForCalculator,
    calculateCommissionFromUnit,
    calculatePricePerNightPerPerson, calculateTotalAs
} from "../../../rfp/Confirmation/Helpers/calculateTotals";
import {
    commissionFormItem,
    getCommissionItemsForStep, getDescriptionValues
} from "./proposalDataGatherer";
import {COMMISSION_VALUE, PRICE_NET, PRICE_TOTAL, PRICE_VAT} from "../components/FinalCommissionValuesModal";


function createConferencePackageCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat, currency)
{
    let conferencePackageCommissionData = getCommissionItemsForStep(enquiryVenueData, 2);

    return  enquiryVenueData.proposal_data.conference_packages.forEach(cp => {
        let hasSrcCurVals = !!cp[`price_per_delegate_net_src_cur`];
        let totals =  calculateTotalAs('Net',vatRates, cp.vat_rate.id,  parseFloat(calculatePricePerNightPerPerson(cp.no_of_delegates, cp[`price_per_delegate_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0, cp.no_of_days)).toFixed(2));
        let commissionTotals =  calculateCommissionAs( includesVat, vatRates, includesVatRate, includesVat,conferencePackageCommissionData ? calculateCommissionForCalculator(
                false,
                calculatePricePerNightPerPerson(cp.no_of_delegates, cp[`price_per_delegate_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0, cp.no_of_days),
                cp.vat_rate.rate,
                conferencePackageCommissionData.rate,
                'Net',
            )
            : {total: 0, vat: 0})
        commissionFormItem(
            cp.description +
            getDescriptionValues(
                cp.no_of_delegates,
                cp.price_per_delegate_net,
                calculatePricePerNightPerPerson(cp.no_of_delegates, cp.price_per_delegate_net, 0, cp.no_of_days),
                cp.no_of_days,
                currency
            ),
            cp.no_of_delegates,
            cp['price_per_delegate_net' + currency ? '_src_cur' : ''],
            cp.no_of_days,
            calculatePricePerNightPerPerson(cp.no_of_delegates, cp[`price_per_delegate_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0, cp.no_of_days),
            totals.vat,
            includesVat,
            totals.total,
            cp.price_vat_rate_id ? cp.price_vat_rate_id : 41,
            conferencePackageCommissionData ? conferencePackageCommissionData.rate : 0,
            'Net',
            commissionTotals,
            'conference_package',
            cp.id,
            formValues
        );
    });

}

function calculateConferencePackageCommission(cp, enquiryVenueData)
{
    let conferencePackageCommissionData = getCommissionItemsForStep(enquiryVenueData, 2);

    return parseFloat(calculateCommissionFromUnit(
        conferencePackageCommissionData.include_vat,
        cp.price_per_delegate_net,
        cp.price_per_delegate_vat,
        20,
        Number(cp.no_of_delegates) * Number(cp.no_of_days),
        parseFloat(conferencePackageCommissionData.rate).toFixed(2),
        conferencePackageCommissionData.applied_on,
        null,
        0)).toFixed(2)
}

function buildInitialConferencePackageItems(enquiryVenueData, initialCommissionItems)
{
    enquiryVenueData.proposal_data.conference_packages.map(cp => {
        initialCommissionItems.push({id: cp.id, type: 'conference_package', readonly: [ PRICE_VAT,COMMISSION_VALUE, PRICE_TOTAL]})
    })
}

function handleConferencePackageFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent,value, id)
{
    if(editedItem.includes('conference') && editedItem.includes('package'))
    {
        let cp = enquiryVenueData.proposal_data.conference_packages.find(cp => parseInt(cp.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'conference_package_' + id,
            id,
            value,
            cp.price_total_net,
            Number(cp.vat_rate.rate)
        )
    }
}

export
{
    createConferencePackageCommissionItems,
    buildInitialConferencePackageItems,
    handleConferencePackageFieldUpdate

};