import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { string, bool } from "prop-types";
import { isLoggedIn } from "modules/auth";
import { venueInteraction } from "modules/analytics";
import { pushEvent } from "../modules/google-tag-manager";

class LinkedIcon extends React.Component {
  static propTypes = {
    iconClassName: string.isRequired,
    link: string,
    className: string,
    phone: bool,
    email: bool,
    newWindow: bool,
    auth: bool,
    clickType: string,
  };

  state = {
    show: false,
  };

  handleClick = e => {
    if (this.props.auth && !this.props.isLoggedIn) {
      e.preventDefault();
      this.props.history.push(
        `/login?return=${encodeURI(
          this.props.location.pathname
        )}&logInToProceed=1`
      );
      return;
    }
    if (this.props.venueInteraction) {
      this.props.track(this.props.venueInteraction);
    }

    if (this.props.googleTagEvent) {
      pushEvent(this.props.googleTagEvent);
    }

    if (this.props.phone) {
      e.preventDefault();
      this.setState({
        show: !this.state.show,
      });
    }
  };

  render() {
    const {
      newWindow,
      link,
      iconClassName,
      className,
      phone,
      email,
    } = this.props;
    if (!link) return null;
    let prefix = "";
    if (phone) {
      prefix += "tel:";
    } else if (email) {
      prefix += "mailto:";
    }
    return (
      <a
        href={prefix + link}
        target={newWindow && "_blank"}
        rel={newWindow && "noopener noreferer"}
        className={className}
        onClick={this.handleClick}
      >
        {this.state.show ? (
          <span className="mr-2 font-normal">{link}</span>
        ) : null}
        <i className={iconClassName} />
      </a>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      isLoggedIn: isLoggedIn(state),
    }),
    { track: venueInteraction }
  )(LinkedIcon)
);
