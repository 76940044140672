import React, {useEffect, useState} from 'react';
import {PieChart} from "./PieChart";

const getRandomColor = () => {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const SpendReportCharts = ({report}) => {
  const [costSavingsData, setCostSavingsData] = useState([]);
  const [marketShareData, setMarketShareData] = useState([]);

  const colours = [
    '#00B871',
    '#f95d6a',
    '#003f5c',
    '#ff7c43',
    '#665191',
    '#ffa600',
    '#9FAFB7',
    '#d45087',
    '#2f4b7c',
    '#a05195'
  ];

  useEffect(() => {
    let costSavingsDataArray = [];
    let marketShareDataArray = [];
    report && Object.entries(report).length > 0 && Object.entries(report).map((groups) => {
      if (groups[0] !== 'totals') {
        let venueName = groups[0];
        delete (groups[0])
        groups.map(group => {
          costSavingsDataArray.push({
            name: venueName,
            value: parseFloat(Number(group.groupTotals[0].costSavings).toFixed(0)),
            currency: '£'
          })
          marketShareDataArray.push({
            name: venueName,
            value: parseInt(Number(group.groupTotals[0].marketShare).toFixed(0)),
          })
        })
        costSavingsDataArray.sort((a, b) => {
          return b.value - a.value
        }).forEach((val, key) => {
          val.color = colours[key]
        })
        marketShareDataArray.sort((a, b) => {
          return b.value - a.value
        }).forEach((val, key) => {
          val.color = colours[key]
        })
        setCostSavingsData(costSavingsDataArray)
        setMarketShareData(marketShareDataArray)
      }
    })
  }, []);

  return (
    <div className="flex flex-col lg:flex-row max-w-full pt-2 pb-8">
      {costSavingsData.length > 0 ? (
        <div className="col-w-full lg:col-w-1/2 text-center">
          <h2>Net Cost Savings £</h2>
          <PieChart data={costSavingsData}/>
        </div>
      ) : null}
      {marketShareData.length > 0 ? (
        <div className="col-w-full lg:col-w-1/2 text-center">
          <h2>Market Share %</h2>
          <PieChart data={marketShareData}/>
        </div>
      ) : null}
    </div>
  )
}

export default SpendReportCharts;
