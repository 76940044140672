import {
  VENUES_LIST_RECEIVED_ERROR,
  VENUES_LIST_RECEIVED_REQUEST,
  VENUES_LIST_REQUEST,
  VENUES_LIST_UNLOAD
} from "./types";

export const venues = (state = {
  venues: {},
  isFetchingVenues: false,
  fetchVenuesError: false,
  fetchVenuesErrorData: {}
}, action) => {
  switch (action.type) {
    case VENUES_LIST_REQUEST:
      return {
        ...state,
        isFetchingVenues: true,
      }
    case VENUES_LIST_RECEIVED_REQUEST:
      return {
        ...state,
        isFetchingVenues: false,
        venues: action.data
      }
    case VENUES_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingVenues: false,
        fetchVenuesErrorData: action.error
      }
    case VENUES_LIST_UNLOAD:
      return {
        ...state,
        isFetchingVenues: false,
        venues: {},
        fetchVenuesError: false,
        fetchVenuesErrorData: {}
      }
    default:
      return state;
  }
}