import React, {Component, useState, useEffect} from "react";
import DropzoneArea from "../../Form/File/DropzoneArea";
import {ButtonSpan} from "../../Button";
import {token, user} from "../../../modules/auth";
import {connect} from "react-redux";
import {
    deleteUserProfileImage,
    unloadCorporateAccountDocument, unloadUser, uploadUserProfileImage,
} from "../../../redux/actions";
import {singleUser} from "../../../redux/reducers/user";
import {refreshUser} from "../../../modules/auth-thunks";
import BackgroundImage from "../../General/BackgroundImage";

const mapStateToProps = state => {
    return {
        token: token(state),
        user: user(state),
    }
}

const mapDispatchToProps = {
    uploadUserProfileImage,
    deleteUserProfileImage,
}

let UserImageProfileForm = ({uploadUserProfileImage, deleteUserProfileImage, user, token}) => {
    useEffect(() => {
    }, [user]);

    const [uploading, setUploading] = useState();



    let uploadProfileImage = (file) => {
        setUploading(true)
        let formData = new FormData();
        formData.append("image", file[0]);
        uploadUserProfileImage(token, formData).then((response) => {
            setUploading(false)

        })
    }

    let deleteProfileImage = () => {
        setUploading(true)
        deleteUserProfileImage(token).then((response) => {
            setUploading(false)

        })
    }

        return (
            <div className={'container mb-auto'}>
                <hr/>
                <h3 className={'pb-5'}>Profile Picture (Optional)</h3>
                <div className={'row flex-row'}>
                    {user.profileImagePath && (
                        <BackgroundImage
                            imagePath={user.profileImagePath}
                            className="bg-cover bg-center h-40 w-40 rounded-t-lg bg-light-grey"
                            edits={{
                                "resize": {
                                    "width": 150,
                                    "height": 150,
                                    "fit": "cover"
                                }}} />
                    )}
                    <div className={'col col-w-full md:col-w-1/2 mb-6'}>
                        <DropzoneArea
                            type="image"
                            minWidth={'200px'}
                            minHeight={'100px'}
                            onDrop={uploadProfileImage}
                            uploading={uploading}
                        />

                        {user.profileImagePath && (
                            <div className={'col col-w-full mb-6'}>
                                <a className={'font-bold'} href={user.profileImageUrl}>Download Profile Image</a>
                                <React.Fragment>
                                    <ButtonSpan
                                        className="pointer text-warning"
                                        onClick={deleteProfileImage}
                                    >
                                        <i className="ml-3 fas fa-trash-alt" />
                                    </ButtonSpan>
                                </React.Fragment>

                            </div>
                        )}
                    </div>
                </div>

                </div>



        )

}

export default connect(mapStateToProps, mapDispatchToProps)(UserImageProfileForm)
