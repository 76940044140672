import React from 'react';
import {ButtonSpan} from "../Button";
import classNames from 'classnames';

export const renderBookingDesks = (bookingDesks, setBookingDesk, bookingDesk) => {
  return (
    <div className="flex flex-col flex-wrap lg:flex-row justify-around align-bottom p-3 bg-light-blue-grey border-t border-light-off-blue -mt-3 mb-3 lg:items-end">
      <ButtonSpan
        className={classNames(
          "uppercase inline-block flex items-center py-2 px-3 border border-grey text-sm font-bold",
          {
            // "opacity-50": bookingDesk !== "",
            "bg-brand": bookingDesk === "",
            "bg-white": bookingDesk !== ""
          }
        )}
        onClick={() => setBookingDesk("")}
      >
        All enquiries
      </ButtonSpan>
      {bookingDesks.map(desk => {
        return (
          <div key={desk.id} className="flex flex-col">
            <ButtonSpan
              className={classNames(
                "uppercase inline-block flex items-center py-2 px-3 border border-grey text-sm font-bold",
                {
                  // "opacity-50": Number(bookingDesk) !== Number(desk.id),
                  "bg-white": Number(bookingDesk) !== Number(desk.id),
                  "bg-brand": Number(bookingDesk) === Number(desk.id),
                }
              )}
              onClick={() => setBookingDesk(desk.id)}
            >
              {desk.name} Desk
            </ButtonSpan>
          </div>
        )}
      )}
    </div>
  )
}