import React, { useState } from "react";
import axios from "axios";

const EmailVerification = ({ user, token }) => {
  const [banner, setBanner] = useState("show");

  const hide = () => {
    setBanner("hide");
  };

  const sendNewVerificationEmail = () => {
    setBanner("loading");
    axios
      .post(`${window.API}/users/me/new-verification`, null, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(data => {
        setBanner("complete");
      })
      .catch(data => {
        setBanner("error");
      });
  };

  if (!user || banner === "hide" || user.email_verified) {
    return null;
  }

  const displayClass =
    "bg-black text-white items-center md:block flex flex-col text-black text-sm py-2 px-4 text-center mb-0";

  switch (banner) {
    case "show":
      return (
        <p className={displayClass}>
          Please ensure that you <strong>verify your email address</strong> as
          soon as possible!{" "}
          <span
            onClick={sendNewVerificationEmail}
            className="md:text-white text-brand-i"
          >
            (Request new email)
          </span>
        </p>
      );
    case "complete":
      return (
        <p className={displayClass}>
          Verification email sent!{" "}
          <i className="fas fa-times text-brand-i m:text-white" onClick={hide}>
            &nbsp;
          </i>
        </p>
      );
    case "loading":
      return (
        <p className={displayClass}>
          <i className="fas fa-cog fa-spin">&nbsp;</i>
        </p>
      );
    case "error":
      return (
        <p className={displayClass}>
          Something went wrong. Contact us for further information
        </p>
      );
    default:
      return null;
  }
};

export default EmailVerification;
