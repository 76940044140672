import React from "react";
import Loading from "../../../Components/Loading";
import LaravelPaginatorWrapper from "../../../Components/Pagination/LaravelPaginatorWrapper";

const LatestReports = ({ latestReports, renderItems, getPage, error, loading }) => {

    const gridStyles = {
        display: "grid",
        gridGap: "20px",
        gridTemplateColumns: "repeat( auto-fill, minmax(400px, 1fr) )",
        gridTemplateRows: "auto",
    };

    return (
      <div>
          <div className="container mx-auto bg-white my-4 p-4 flex flex-row justify-between items-center">
              <h1 className="">Latest Reports </h1>
              {loading && <Loading/>}
          </div>

          <div
              className="container mx-auto my-4 pb-4"
              style={{paddingLeft: "0", paddingRight: "0"}}
          >
              <div className="" style={gridStyles}>
                  {error && (
                      <p>
                          Oops, something went wrong fetching the latest site visit reports.
                      </p>
                  )}
                  <LaravelPaginatorWrapper {...latestReports} render={renderItems} getPage={getPage}/>
              </div>
          </div>
      </div>
    );
};

export default LatestReports;
