import React, {useEffect, useState} from "react";
import Loading from "../Loading";

const CompanyView = ({company, industries, fullWidth = false}) => {
  const [industryName, setIndustryName] = useState("");

  useEffect(() => {
    if (company && Object.entries(company).length > 0) {
      let industry = industries.filter(industry => Number(industry.data.id) === Number(company.industry_id))
      if (industry && industry[0]?.data) {
        setIndustryName(industry[0].data.name)
      }
    }
  }, []);

  if (company && Object.entries(company).length > 0) {
    return (

          <div className={'row'}>
            <div className={`col col-w-full mb-0 ${fullWidth ? '' : 'md:col-w-1/2'}`}>
              <h3 className="mb-4 uppercase">Company details</h3>
              <ul className="list-reset mb-3">
                <li className="mb-1">{company.name}</li>
                {company?.address?.address1 && <li className="mb-1">{company.address.address1}</li> }
                {company?.address?.address2 && <li className="mb-1">{company.address.address2}</li> }
                {company?.address?.address3 && <li className="mb-1">{company.address.address1}</li> }
                {company?.address?.city && <li className="mb-1">{company.address.city}</li> }
                {company?.address?.county && <li className="mb-1">{company.address.county}</li> }
                {company?.address?.postcode && <li className="mb-1">{company.address.postcode}</li> }
                {company?.address?.country?.name && <li className="mb-1">{company.address.country.name}</li> }
              </ul>
              <p>
                To edit this part of your profile, please
                contact {company.agency.name} via {company.agency.email_address} / {company.agency.phone_number}
              </p>
            </div>
          </div>
    )
  } else {
    return <Loading />
  }
}

export default CompanyView;
