import React from "react";
import { ButtonSpan } from "Components/Button";

export default class FileListItem extends React.Component {
  render() {
    return (
      <li className="mb-2">
        <a
          href={this.props.url}
          target="_blank"
          rel="noopener noreferrer"
          className="font-normal"
        >
          <i className="fas fa-file-pdf mr-2" />
          {this.props.name}
        </a>
        <ButtonSpan
          className="text-warning ml-3"
          onClick={() => this.props.remove(this.props.i)}
        >
          <i className="fas fa-trash-alt" />
        </ButtonSpan>
      </li>
    );
  }
}
