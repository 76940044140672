import React, { useState } from "react";
import {  useParams} from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Textarea } from "Components/Form";
import { ButtonPrimary, ButtonSpan} from "Components/Button";
import Loading from "Components/Loading";
import Modal from "Components/Modal";
import useService from "hooks/useService";
import { required } from "modules/validation";
import { confirmVenueUser } from "services/rfpService";

const form = "proposal-respond-to-venue";

const v = {
  required: required(),
};

const Wrapper = ({ children, onClose }) => (
  <Modal>
    <div className="modal">
      <div
        className="modal-inner"
        style={{
          width: 1200,
          maxWidth: "90%",
        }}
      >
        <ButtonSpan className="m-3 absolute pin-t pin-r z-10" onClick={onClose}>
          <i className="fas fa-times-circle fa-2x ml-3" />
        </ButtonSpan>
        {children}
      </div>
    </div>
  </Modal>
);

const ReplyToVenueModal = ({ handleSubmit, onClose, venueId}) => {
  const [submitData, setSubmitData] = useState(null);
  const { id: rfpId } = useParams();
  const { loading: submitting, data: complete, error } = useService({
    initialLoading: false,
    service: confirmVenueUser,
    trigger: submitData,
    params: submitData,
  });

  const handleConfirm = values => {
    setSubmitData({
      venueId: venueId,
      rfpId,
      confirm: false,
      message: values.message
    });
  };

  if (complete) {
    return (
      <Wrapper onClose={onClose}>
        <p className="text-xl mb-5">
          Thank you.
          <br />
          Your reply has been submitted.
        </p>
      </Wrapper>
    );
  }

  if (submitting) {
    return (
      <Wrapper onClose={onClose}>
        <Loading />
      </Wrapper>
    );
  }

  return (
    <Wrapper onClose={onClose}>
      <form className="relative pb-16" onSubmit={handleSubmit(handleConfirm)}>
        <h1 className="mb-8">Send Venue A Message</h1>
        {complete ? (
          <p className="text-xl mb-5">
            Thank you.
            <br />
            Your request has been submitted.
          </p>
        ) : (
          <>

              <Field
                label="Please enter your message"
                name="message"
                rows={7}
                component={Textarea}
                validate={v.required}
              />

            {error && (
              <p className="my-5 text-warning">
                Sorry, there was a problem submitting your request
              </p>
            )}

              <div className="absolute pin-b pin-r">
                <ButtonPrimary
                  key={1}
                  type="submit"
                  classes="px-5"
                  style={{ height: 57, lineHeight: "57px" }}
                  disabled={submitting}
                >
                  {submitting ? <Loading inline /> : "Send to venue"}
                </ButtonPrimary>
              </div>

          </>
        )}
      </form>
    </Wrapper>
  );
};

export default reduxForm({ form })(ReplyToVenueModal);
