import React, { useEffect, useState } from "react";
import { fetchBookedEventsList } from "../../../redux/actions";
import { connect } from "react-redux";
import { BOOKED_EVENT_STATUS } from "../../../constants";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import * as CORPORATE_ROUTES from "../../../Router/corporate_routes";
import { FRONTEND } from "../../../Router/corporate_routes";
import { ButtonBrand } from "Components/Button";

const CorpDashUpcomingEvents = ({ token, fetchBookedEventsList, bookedEvents }) => {
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    useEffect(() => {
      const queryParams = {
        eventDateFrom: format(new Date(), "YYYY-MM-DD 00:00:00"),
        eventStatus: `${BOOKED_EVENT_STATUS.CONFIRMED}`,
      };
      fetchBookedEventsList(token, 1, queryParams);
    }, []);

    useEffect(() => {
      let today = new Date();
      if (bookedEvents && bookedEvents.length > 0) {
        let sortedBookedEvents = bookedEvents.filter(bookedEvent => {
          if (bookedEvent && bookedEvent.data && bookedEvent.data.event_status) {
            return bookedEvent.data.event_status?.code === BOOKED_EVENT_STATUS.CONFIRMED && bookedEvent.data.event_date_from ? (new Date(bookedEvent.data.event_date_from).getTime() >= today.getTime()) : false;
          }
          return false;
        }).sort((a, b) => {
          if (
            (a.data.event_date_from && b.data.event_date_from)
          ) {
            return new Date(a.data.event_date_from) - new Date(b.data.event_date_from);
          }
        });
        setUpcomingEvents(sortedBookedEvents);
      }
    }, [bookedEvents]);

    return (
      <div className={"p-5 mt-2 lg:mt-0 lg:mx-2 rounded-lg text-white min-h-full corporate-admin__feed"}>
        <div className="flex flex-row items-center mt-1 mb-3">
          <span className="text-2xl mr-4"><i className="fal fa-calendar-alt" /></span>
          <h2 className="mb-0 text-lg xl:text-xl font-normal">
            <Link
              className="text-white hover:text-brand"
              title="View all events"
              to={CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.ALL + `?eventDateFrom=` + format(new Date(), "YYYY-MM-DD 00:00:00") + `&eventStatus=${BOOKED_EVENT_STATUS.CONFIRMED},${BOOKED_EVENT_STATUS.COMPLETED}`}>
              Forthcoming Events
            </Link>
          </h2>
        </div>
        {upcomingEvents.length > 0 ? upcomingEvents.map(bookedEvent => {
            let confirmedData = bookedEvent.data.enquiry_venue_datas;
            return (
              <div className="flex flex-row items-center border-b border-dark-grey py-1">
                <div className="block w-full">
                  <div className="flex flex-col mb-1 p-2 text-black h-full text-white">
                    <h4 className="mt-1 mb-1 text-xl">
                      <Link to={FRONTEND.CORPORATE_USER.BOOKED_EVENTS.SINGLE.replace(":slug", bookedEvent.data.id)}>
                      <span className={"text-white"} title="Manage event">
                        {bookedEvent.data.enquiry.event_name}
                      </span>
                      </Link>
                    </h4>
                    <p
                      className="mt-1 mb-1 opacity-75">{format(bookedEvent.data.event_date_from, "DD/MM/YYYY")} - {confirmedData && confirmedData.minimal_venue ? `${confirmedData.minimal_venue.name}, ${confirmedData.minimal_venue.town}, ${confirmedData.minimal_venue.country ? `${confirmedData.minimal_venue.country.name}` : ""}` : ""}</p>
                  </div>
                </div>
              </div>
            );
          }) :
          <div className={"text-center p-4 border-dark-grey border mt-8 mx-4 h-full"}>
            <p className={"opacity-75 mt-4"}>You currently have no confirmed events...</p>
            <p className={"mb-4 text-center"}>
              <ButtonBrand
                style={{ "width": "inherit" }}
                classes="text-md font-bold m-auto"
              >
                <Link to="/corporate/request-for-proposal" className={"text-white"}>Start a new enquiry</Link>
              </ButtonBrand>
            </p>

          </div>
        }
      </div>);
  }
;

const mapStateToProps = state => {
  return {
    ...state.bookedEvents,
  };
};

const mapDispatchToProps = {
  fetchBookedEventsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CorpDashUpcomingEvents);
