import React from "react";
import { func, object, string, number, oneOfType } from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "Components/Modal";
import { ApiForm, Succeeded, Failed } from "Components/Form";
import Loading from "Components/Loading";
import { ButtonSpan, ButtonPrimary } from "Components/Button";

class FormModal extends React.Component {
  static propTypes = {
    data: object,
    closeModal: func.isRequired,
    name: string.isRequired,
    width: oneOfType([string, number]),
    height: oneOfType([string, number]),
    childProps: object,
  };

  form = () => `v-${this.props.name}s:${this.props.match.params.venue}`;

  render() {
    const Fields = this.props.renderFields;
    return (
      <Modal>
        <div className="modal">
          <div
            className="modal-inner"
            style={{
              width: this.props.width || 1200,
              maxWidth: "90%",
            }}
          >
            <ButtonSpan
              className="m-3 absolute pin-t pin-r"
              onClick={this.props.closeModal}
            >
              Close
              <i className="fas fa-times ml-3" />
            </ButtonSpan>
            <ApiForm
              form={this.form()}
              create={!this.props.data}
              data={this.props.data}
              url={`/venues/${
                this.props.match.params.venue
              }/${this.props.name.replace("_", "-")}/${
                this.props.data ? this.props.data.id : ""
              }`}
              fields={this.props.fields}
              pluckTheEquipmentIds={this.props.pluckTheEquipmentIds}
              handleResponse={this.props.closeModalRefresh}
              render={(submitting, failed, succeeded) => (
                <React.Fragment>
                  <Fields {...this.props.childProps} />
                  <div className="text-right">
                    <ButtonPrimary type="submit">
                      {submitting ? <Loading inline /> : "Save"}
                    </ButtonPrimary>
                  </div>
                  {succeeded ? <Succeeded /> : null}
                  {failed ? <Failed /> : null}
                </React.Fragment>
              )}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(FormModal);
