import ChecksheetBreakdown from './ChecksheetBreakdown';
import { Input } from '../../../../../Components/Form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Field, formValueSelector, getFormSyncWarnings } from 'redux-form';
import {
  calculateCommissionFromUnit,
  calculateAndSetPricePerNightPerPersonFinalTotalFromNetAndVatUnits,
  calculatePricePerNightPerPersonTotalFromUnit,
  calculateSupplementaryItemMeetingRoomTotal,
  gatherTotals,
  getVatRateById,
} from '../Helpers/calculateTotals';
import { getFormValues, getHiddenRows } from '../Helpers/getFormState';
import { isAgent, isVenueAdmin } from '../../../../../modules/auth';
import { flagChanges } from '../Helpers/formHelper';
import { ENQUIRY_VENUE_DATA_STATUS } from '../../../../../modules/rfp/enquiryVenueDataStatus';

//let supplementalItemNoOfDelegates = 'supplemental_item_no_of_delegates';
//let supplementalItemRemoveRow = 'supplemental_item_remove_row';

function getSupplementaryItemDelegatesKey(id) {
  return `delegates[catering_${id}]`;
}

function getSupplementaryItemMinDelegatesKey(id) {
  return `minDelegates[catering_${id}]`;
}

function getSupplementaryItemRemoveKey(id) {
  return `remove[catering_${id}]`;
}

let SupplementalItemBreakdown = (props) => {
  let { currency = {currency_code: "GBP"} } = props;
  let totals = {};
  let commissionTotals = {};
  let commissionTotalsGBP = {};
  const [grandTotal, setGrandTotal] = useState(0);
  const [, setGrandCommissionTotal] = useState(0);
  const [grandTotalGBP, setGrandTotalGBP] = useState(0);
  const [, setGrandCommissionTotalGBP] = useState(0);
  const [valueChanged] = useState(false);
  useEffect(() => {
    gatherTotals(totals, setGrandTotal, props.setSupplementaryItemTotal);
    gatherTotals(commissionTotals, setGrandCommissionTotal, props.setSupplementaryItemCommissionTotal);
    gatherTotals(commissionTotalsGBP, setGrandCommissionTotalGBP, props.setSupplementaryItemCommissionTotalGBP);
  }, [props.values, totals]);

  const onChange = (event, newValue, previousValue, name) => {
    flagChanges(props.initialValues, props.values, props.hiddenRows, newValue, name, props.setCateringValueChanged);
  };

  return (
      <ChecksheetBreakdown
          currency={currency}
          headers={(
              <React.Fragment>
                <th className={'p-2 pl-0 text-left uppercase text-xs min-14 w-auto'}>Description</th>
                <th className={'p-2 text-left uppercase text-xs w-20'}>No. people</th>
                <th className={'p-2 text-right uppercase text-xs w-20'}>Net Price/Person</th>
              </React.Fragment>
          )}
          spaces={3}
          totalAmount={props.currencyFormatter.format(grandTotal)}
          showCommission={props.showCommission}
      >
        {props.supplementalItems.map(
            ({
               id,
               meeting_room,
               display_price_including_vat,
               price_per_person_net,
               price_per_person_vat,
               price_per_person_net_gbp,
               price_per_person_vat_gbp,
               price_vat_rate_id,
               supplement_type,
             }, index) => {
              let vatRate = getVatRateById(props.vatRates, price_vat_rate_id).number;
//                  console.log({price_per_person_net})
              let isRemoved = typeof props.hiddenRows[getSupplementaryItemRemoveKey(id)] !== 'undefined';
              let netTotal = isRemoved ? 0 : calculatePricePerNightPerPersonTotalFromUnit(props.values, getSupplementaryItemDelegatesKey(id), price_per_person_net, 1);
              let vatTotal = isRemoved ? 0 : calculatePricePerNightPerPersonTotalFromUnit(props.values, getSupplementaryItemDelegatesKey(id), price_per_person_vat, 1);
              let finalTotal = isRemoved ? 0 : calculateAndSetPricePerNightPerPersonFinalTotalFromNetAndVatUnits(props.values, getSupplementaryItemDelegatesKey(id), price_per_person_net, price_per_person_vat, 1, false, totals);
              let meetingRoomNetTotal = 0;
              let meetingRoomVatTotal = 0;
              let meetingRoomTotal = 0;
              let meetingRoomVatRate = null;
              if(meeting_room) {
                   meetingRoomVatRate = getVatRateById(props.vatRates, meeting_room.price_vat_rate_id).number;
                   meetingRoomNetTotal = isRemoved ? 0 : Number(meeting_room.price_total_net);
                   meetingRoomVatTotal = isRemoved ? 0 : Number(meeting_room.price_total_vat);
                   meetingRoomTotal = isRemoved ?
                      0 :
                      calculateSupplementaryItemMeetingRoomTotal(true, (Number(meeting_room.price_total_net) + Number(meeting_room.price_total_vat)), meeting_room.id, totals, meetingRoomVatRate);

              }

              let commission = props.commissionItems ? props.commissionItems.find(item => parseInt(item.step_id) === 5) : 0;
              let commissionRate = commission ? commission.rate : 0;
              let commissionAppliedOn = commission ? commission.applied_on : 0;
              let commissionIncludeVat = commission ? commission.include_vat : 0;
              let commissionTotal = 0;
              let commissionTotalMeetingRoom = 0;
              let commissionTotalGBP = 0;
              let commissionTotalMeetingRoomGBP = 0;
              if (!isRemoved) {
//                        commissionTotal = commission ? calculateCommission(commissionIncludeVat, netTotal, vatTotal, vatRate, commissionRate, commissionAppliedOn, getSupplementaryItemDelegatesKey(id), commissionTotals ) : 0;
                const multiplier = Number(props.values[`${getSupplementaryItemDelegatesKey(id)}`]);
                commissionTotal = commission ?
                    calculateCommissionFromUnit(commissionIncludeVat, price_per_person_net, price_per_person_vat, props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getSupplementaryItemDelegatesKey(id), commissionTotals) :
                    0;
                commissionTotalMeetingRoom = commission && meeting_room ?
                    calculateCommissionFromUnit(commissionIncludeVat, meeting_room.price_total_net, meeting_room.price_total_vat, props.currentVatRateForCommission, 1, commissionRate, commissionAppliedOn, getSupplementaryItemDelegatesKey(id), commissionTotals) :
                    0;
                commissionTotalGBP = commission ?
                    calculateCommissionFromUnit(commissionIncludeVat, price_per_person_net_gbp, price_per_person_vat_gbp, props.currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getSupplementaryItemDelegatesKey(id), commissionTotalsGBP) :
                    0;
                commissionTotalMeetingRoomGBP = commission && meeting_room  ?
                    calculateCommissionFromUnit(commissionIncludeVat, meeting_room.price_total_net_gbp, meeting_room.price_total_vat_gbp, props.currentVatRateForCommission, 1, commissionRate, commissionAppliedOn, getSupplementaryItemDelegatesKey(id), commissionTotalsGBP) :
                    0;

                /* SPECIFICALLY OVERRIDDEN HERE IN THIS CASE */
                commissionTotals[getSupplementaryItemDelegatesKey(id)] = Number(commissionTotal) + Number(commissionTotalMeetingRoom);
                commissionTotalsGBP[getSupplementaryItemDelegatesKey(id)] = Number(commissionTotalGBP) + Number(commissionTotalMeetingRoomGBP);
              }

              return (
                  <React.Fragment key={`supplementary_item_${id}`}>
                    <tr
                        className={`${props.hiddenRows[`${getSupplementaryItemRemoveKey(id)}`] !== 'undefined' && props.hiddenRows[`${getSupplementaryItemRemoveKey(id)}`] === true ?
                            'row-fadeout' :
                            ''} border-b-0 ${props.supplementalItems.count === (index + 1) ? 'border-none' : ''}`} key={`supplementary_item_${id}`}>
                      <td className={'p-2 pl-0 text-left'}>{supplement_type.type}</td>
                      <td className={'p-2 text-left'}>
                        <Field
                            name={getSupplementaryItemDelegatesKey(id)}
                            wrapperClassName="w-full mr-2"
                            component={Input}
                            type={'number'}
                            min={0}
                            step={1}
                            readOnly={Number(props.status) === Number(ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS) || Number(props.status) > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS}
                            normalize={val => (val || '').replace(/[^\d]/g, '')}
                            onChange={onChange}
                        />

                        <Field
                            component={Input}
                            name={getSupplementaryItemMinDelegatesKey(id)}
                            type="hidden"
                            style={{ height: 0, margin: 0 }}
                            wrapperClassName="w-full mb-0"
                        /></td>
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(Number(price_per_person_net))}</td>
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(netTotal)}</td>
                      <td className={'p-2 text-right'}>{`${vatRate}%`}</td>
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(vatTotal)}</td>

                      {props.showCommission ?
                          <td className={'p-2 text-right'}>
                            {`${commissionRate}%`}
                          </td>
                          :
                          null
                      }
                      {props.showCommission ?
                          <td className={'p-2 text-right'}>
                            {props.currencyFormatter.format(commissionTotal)}
                          </td>
                          :
                          null
                      }
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(finalTotal)}</td>
                      <td className={'p-2 text-left w-8'}>
                        {
                          props.status == ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS || props.status > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS ?
                              null : <Field
                                  name={getSupplementaryItemRemoveKey(id)}
                                  component={props.renderCheckBox}
                                  onChange={onChange}
                              />

                        }

                      </td>
                    </tr>
                      {
                          meeting_room ?
                              <tr key={`supplementary_item_room_${id}`}
                                  className={` ${props.hiddenRows[`${getSupplementaryItemRemoveKey(id)}`] !== 'undefined' && props.hiddenRows[`${getSupplementaryItemRemoveKey(id)}`] === true ?
                                      'row-fadeout' :
                                      ''} border-b-0 ${props.supplementalItems.count === (index + 1) ? 'border-none' : ''}`}>
                                  <td className={'p-2 pl-0'}>{supplement_type.type} - Room Cost</td>
                                  <td className={'p-2'}></td>
                                  <td className={'p-2'}></td>
                                  <td className={'p-2 text-right'}><p className={'mb-0'}>{props.currencyFormatter.format(meetingRoomNetTotal)}</p></td>
                                  <td className={'p-2 text-right'}>{`${meetingRoomVatRate}%`}</td>
                                  <td className={'p-2 text-right'}>{props.currencyFormatter.format(meetingRoomVatTotal)}</td>

                                  {props.showCommission ? <td className={'p-2 text-right'}>{`${commissionRate}%`}</td> : null}
                                  {props.showCommission ? <td className={'p-2 text-right'}>{props.currencyFormatter.format(commissionTotalMeetingRoom)}</td> : null}

                                  <td className={'p-2 text-right'}>{props.currencyFormatter.format(meetingRoomTotal)}</td>
                              </tr>
                              : null
                      }
                    <tr key={`supplementary_item_${id}_error`} className={'border-none'}>
                      <td colSpan={10}> {typeof props.submitWarnings['catering_' + id] !== 'undefined' ?
                          <span className=" table-cell w-100 border-0 text-warning font-normal py-2"> {props.submitWarnings['catering_' + id]} </span> :
                          null}</td>
                    </tr>

                  </React.Fragment>
              );
            },
        )}
        {valueChanged ?
            <p>As you have adjusted the number required from your original brief, the venue may need to requote this
              element </p>
            : null}
      </ChecksheetBreakdown>
  );
};

const selector = formValueSelector('confirmBooking');

export default withRouter(
    connect((state, props) => ({
          hiddenRows: getHiddenRows(props.supplementalItems, getSupplementaryItemRemoveKey, state, selector),
          values: getFormValues(props.supplementalItems, getSupplementaryItemDelegatesKey, state, selector),
          submitWarnings: getFormSyncWarnings('confirmBooking')(state),
          isVenue: isVenueAdmin(state),
          isAgency: isAgent(state),
        }),
    )(SupplementalItemBreakdown),
);
