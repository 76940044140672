import React from "react";
import { connect } from "react-redux";
import {ButtonBrand} from "Components/Button";
import {LabelledText, WhitePanelWithHeader} from "ui";
import axios from "axios";
import {token} from "../../modules/auth";

const saveData = (function() {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.cssText = "display: none";
    return function(type, data, fileName) {
        const blob = new Blob([data], {
            type,
        });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.setAttribute("download", fileName);
        a.click();
        window.URL.revokeObjectURL(url);
    };
})();

class EnquiryFiles extends React.Component {


    constructor() {
        super();

        this.handleDownload = this.handleDownload.bind(this)
    }

    handleDownload = async (fileName, url, type = "application/pdf") => {
        try {
            const response = await axios.get(url, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                },
            });
            saveData(type, response.data, fileName);
        } catch (err) {
            alert("Sorry there was a problem fetching the file");
        }
    };

    render(){
        if(!this.props.attachments || this.props.attachments.length === 0) {
            return null;
        }
        return (
            <WhitePanelWithHeader icon="far fa-clipboard" title="Supporting Documents">
                <div className="md:flex flex-wrap">
                    { this.props.attachments.map(file => (

                        <div className="md:w-1/2 lg:w-1/4 pr-1">

                                    <LabelledText label={'Name'} text={file.name}/>


                                        <ButtonBrand
                                            classes="mr-4"
                                            onClick={() =>
                                                this.handleDownload(
                                                    file.name,
                                                    file.attachment_url,
                                                    file.content_type
                                                )
                                            }
                                        >
                                            Download
                                        </ButtonBrand>



                            </div>
                        )
                    )}
                </div>
            </WhitePanelWithHeader>
        );
    }

};

export default connect(state => ({ token: token(state) }))(EnquiryFiles)
