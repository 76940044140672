import axios from "axios";

const requestSiteVisit = async (
  { venue_id, enquiry_id, date_requested },
  token
) => {
  try {
    await axios.post(
      `${window.API}/rfp/request-site-visit`,
      {
        venue_id,
        enquiry_id,
        date_requested,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { result: true };
  } catch (error) {
    return { error };
  }
};

export { requestSiteVisit };
