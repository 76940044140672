import {
  SITE_VISITS_LIST_ERROR,
  SITE_VISITS_LIST_REQUEST,
  SITE_VISITS_LIST_REQUEST_RECEIVED,
  SITE_VISITS_LIST_UNLOAD,
} from "./types";

export const siteVisits = (state = {
  siteVisits: {},
  isFetchingSiteVisits: false,
  siteVisitsPage: null,
  siteVisitsLastPage: null,
}, action) => {
  switch (action.type) {
    case SITE_VISITS_LIST_REQUEST:
      return {
        ...state,
        isFetchingSiteVisits: true,
      };
    case SITE_VISITS_LIST_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingSiteVisits: false,
        siteVisits: action.data.data,
        siteVisitsPage: action.data.current_page,
        siteVisitsLastPage: action.data.last_page,
      };
    case SITE_VISITS_LIST_ERROR:
      return {
        ...state,
        isFetchingSiteVisits: false,
        siteVisits: {},
      };
    case SITE_VISITS_LIST_UNLOAD:
      return {
        ...state,
        isFetchingSiteVisits: false,
        siteVisits: {},
        siteVisitsPage: null,
        siteVisitsLastPage: null,
      };
    default:
      return state;
  }
};