import axios from "axios";
import {saveData} from "../downloadFile";
import moment from 'moment'

const downloadConfirmationWithoutCommission = async (id,evdId ,name, token) => {
    let url =   `${window.API}/rfp/${id}/confirm/${evdId}/export?disable_commission=1`

    try {
        const response = await axios.get(
            url,
            {
                responseType: "blob",
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        saveData(
            false,
            response.data,
            `${name.replace(/\s/g, '-')}-${moment().format('DD-MM-YYYY')}-proposal-ref-${evdId}-confirmation-without-commission.pdf`
        );

    } catch (err) {
        alert(`Sorry there was a problem fetching the pdf`);
    }
};

export { downloadConfirmationWithoutCommission };
