import React from "react";
import RegistrationLandingPage from "Components/RegistrationLandingPage";

const Other = () => {
  return (
    <RegistrationLandingPage
      img="/img/landing/conference-banner.jpg"
      title="Thousands of venue options to find perfect meetings and events
      spaces"
      subtitle="Why EDGE Venues is the perfect solution for global venue sourcing
      for meetings and events."
      bannerFeatures={[
        {
          icon: "globe-americas",
          text: "19,000 venues",
        },
        {
          icon: "search",
          text: "Simple search",
        },
        {
          icon: (
            <div className="block mb-4">
              <i className="fal fa-building"></i>
              <i className="fal fa-hotel"></i>
            </div>
          ),
          text: "Venue comparison",
        },
        {
          icon: "heart",
          text: "Shortlist, save and share",
        },
        {
          icon: "envelope-open-text",
          text: "Fast enquiry process",
        },
        {
          icon: "receipt",
          text: "Buy it now",
        },
      ]}
      type="other"
      successUrl="/registration-complete"
      formColor="#847b68"
      bodyTitle="Why use EDGE for your venue?"
      bodyFeatures={[
        {
          icon: "globe-americas",
          title: "19,000 superb venues at your fingertips",
          content: (
            <div>
              <p>
                Are you looking for the perfect venue for your next meeting and
                event and don't know where to start?
              </p>
              <p>
                Don't worry – EDGE Venues has everything you need. With
                thousands of global venues on our database, we can help you make
                fast and easy decisions that are within budget. Whether it's
                unique and quirky, historic, exhibition space, auditorium and
                screening or a hotel, you'll find the venue that meets your
                requirements.
              </p>
            </div>
          ),
        },
        {
          icon: "search",
          title: "Keeping searches simple",
          content: (
            <div>
              <p>
                For those of you who are time poor, we know that speed and ease
                are key.
              </p>
              <p>
                Our 'simple' and 'advanced' search functions can quickly and
                easily help you to find the venue you're looking for in the
                destination your searching.
              </p>
            </div>
          ),
        },
        {
          icon: (
            <div className="block mb-4">
              <i className="fal fa-building"></i>
              <i className="fal fa-hotel"></i>
            </div>
          ),
          title: "Compare your favourite venues",
          content: (
            <div>
              <p>With so much choice how do you decide what work will best?</p>
              <p>
                Simple - compare the size of meeting rooms, number of bedrooms
                and distance from airport in our side-by-side comparison tool –
                all helping you make informed decisions for your meetings and
                events.
              </p>
            </div>
          ),
        },
        {
          icon: "heart",
          title: "Shortlist, save and share",
          content: (
            <div>
              <p>
                By subscribing to EDGE Venues for FREE, you can make your
                shortlist, save your searches and share your venue options with
                colleagues, ensuring you continue to make informed decisions
                before booking.
              </p>
              <p>
                If you're not ready to decide, come back at any time and pick up
                right where you left off.
              </p>
            </div>
          ),
        },
        {
          icon: "envelope-open-text",
          title: "Fast enquiry process",
          content: (
            <div>
              <p>
                At EDGE, we don't just stop at the 'search' stage, and once
                you've found that perfect venue(s), you now need to know if it's
                available.
              </p>
              <p>
                Our enquiry process allows you to express your interest in
                multiple venues at a few clicks of a button. The chosen venues
                will then come back to you directly, share availability and
                confirm costings to allow you to continue your planning. It
                really couldn't be easier.
              </p>
            </div>
          ),
        },
        {
          icon: "receipt",
          title: "Buy it now",
          content: (
            <div>
              <p>In a hurry and need a meeting space for under 10 delegates?</p>
              <p>
                Check out the 'Buy It Now' feature for published prices on
                selected venues. Complete the simple enquiry form and book
                direct.
              </p>
            </div>
          ),
        },
      ]}
      splitFeatureTitle="Subscribe to EDGE Venues for free and take advantage of these benefits to help you find the perfect meetings and events space"
      splitFeatureList={[
        "Growing global database of hotels and venues",
        "Making quick and informed decisions",
        "Saving you time venue finding",
      ]}
    />
  );
};

export default Other;
