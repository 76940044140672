import ChecksheetBreakdown from './ChecksheetBreakdown';
import { Input } from '../../../../../Components/Form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Field, formValueSelector, getFormSyncWarnings } from 'redux-form';
import { getHiddenRows, getFormValues } from '../Helpers/getFormState';
import {
  gatherTotals, getVatRateById, calculatePricePerNightPerPersonTotalFromUnit, calculateAndSetPricePerNightPerPersonFinalTotalFromNetAndVatUnits, calculateCommissionFromUnit,
} from '../Helpers/calculateTotals';
import { isAgent, isVenueAdmin } from '../../../../../modules/auth';
import { flagChanges } from '../Helpers/formHelper';
import { ENQUIRY_VENUE_DATA_STATUS } from '../../../../../modules/rfp/enquiryVenueDataStatus';
import {formattedApplicableDates} from "../../../../../utils";

function getConferenceDelegatesKey(id) {
  return `delegates[conference_${id}]`;
}

function getConferenceMinDelegatesKey(id) {
  return `minDelegates[conference_${id}]`;
}

function getConferenceRemoveKey(id) {
  return `remove[conference_${id}]`;
}

let ConferencePackageBreakdown = (props) => {
  const { vatRates, currentVatRateForCommission } = props;
  let totals = {};
  let commissionTotals = {};
  let commissionTotalsGBP = {};
  const [grandTotal, setGrandTotal] = useState(0);
  const [, setGrandCommissionTotal] = useState(0);
  const [, setGrandCommissionTotalGBP] = useState(0);
  let currency = props.enquiryVenueData.currency;

  useEffect(() => {
    gatherTotals(totals, setGrandTotal, props.setConferencePackagingTotal);
    gatherTotals(commissionTotals, setGrandCommissionTotal, props.setConferencePackagingCommissionTotal);
    gatherTotals(commissionTotalsGBP, setGrandCommissionTotalGBP, props.setConferencePackagingCommissionTotalGBP);
  }, [props.values, totals, commissionTotals]);

  const onChange = (event, newValue, previousValue, name) => {
    flagChanges(props.initialValues, props.values, props.hiddenRows, newValue, name, props.setConferenceValueChanged, props.setAnyValueChanged);
  };

  return (
      <ChecksheetBreakdown
        currency={currency}
          headers={(
              <React.Fragment>
                <th className={'p-2 pl-0 text-left uppercase text-xs min-14 w-auto'}>Description</th>
                <th className={'p-2 text-left uppercase text-xs w-20'}>No. Delegates</th>
                {/*<th className={'p-2 text-left uppercase text-xs w-20'}>Minimum No. Delegates</th>*/}
                <th className={'p-2 text-right uppercase text-xs w-20'}>Net/ Delegate</th>
              </React.Fragment>
          )}
          spaces={3}
          totalAmount={props.currencyFormatter.format(grandTotal)}
          showCommission={props.showCommission}
      >
        {props.conferencePackages.map(
            ({
               id,
               description,
               price_per_delegate_net,
               price_per_delegate_vat,
               price_vat_rate_id,
               price_total_net,
               price_total_vat,
               no_of_days,
               min_number_of_delegates_for_qualify,
               display_price_including_vat,
               applicable_days,
               price_per_delegate_net_gbp,
               price_per_delegate_vat_gbp
             }, index) => {

              let vatRateForPackage = getVatRateById(vatRates, price_vat_rate_id).number;
              let isRemoved = typeof props.hiddenRows[getConferenceRemoveKey(id)] !== 'undefined';

              let netTotal = !isRemoved ? calculatePricePerNightPerPersonTotalFromUnit(props.values, getConferenceDelegatesKey(id), price_per_delegate_net, no_of_days) : 0;

              let vatTotal = !isRemoved ? calculatePricePerNightPerPersonTotalFromUnit(props.values, getConferenceDelegatesKey(id), price_per_delegate_vat, no_of_days) : 0;

              let finalTotal = !isRemoved ? calculateAndSetPricePerNightPerPersonFinalTotalFromNetAndVatUnits(props.values, getConferenceDelegatesKey(id), price_per_delegate_net, price_per_delegate_vat, no_of_days, false, totals) : 0;
//                  calculatePricePerNightPerPersonFinalTotal(props.values, getConferenceDelegatesKey(id), price_per_delegate_net, no_of_days, vatRateForPackage, false, false, totals) : 0;
              let price_total_gross = finalTotal;

              let commission = props.commissionItems ? props.commissionItems.find(item => parseInt(item.step_id) === 2) : 0;
              let commissionRate = commission ? Number(commission.rate) : 0;
              let commissionAppliedOn = commission ? commission.applied_on : 0;
              let commissionIncludeVat = commission ? commission.include_vat : 0;
              let commissionTotal = 0;
              let commissionTotalGBP = 0;
              if (typeof props.hiddenRows[getConferenceRemoveKey(id)] === 'undefined') {

                const multiplier = Number(props.values[`${getConferenceDelegatesKey(id)}`]) * no_of_days;
                commissionTotal = commission ?
                    calculateCommissionFromUnit(commissionIncludeVat, price_per_delegate_net, price_per_delegate_vat, currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getConferenceDelegatesKey(id), commissionTotals) :
                    0;
                if (currency.currency_code !== 'GBP') {
                  commissionTotalGBP = commission ?
                    calculateCommissionFromUnit(commissionIncludeVat, price_per_delegate_net_gbp, price_per_delegate_vat_gbp, currentVatRateForCommission, multiplier, commissionRate, commissionAppliedOn, getConferenceDelegatesKey(id), commissionTotalsGBP) :
                    0;
                }
              }
              return (
                  <React.Fragment key={`conference_package_${id}_container`}>
                    <tr key={`conference_package_${id}`}
                        className={`${props.hiddenRows[`${getConferenceRemoveKey(id)}`] !== 'undefined' && props.hiddenRows[`${getConferenceRemoveKey(id)}`] === true ? 'row-fadeout' : ''} ${props.conferencePackages.length === (index + 1) ?
                            'border-none' :
                            ''}`}>
                        <td className={'p-2 pl-0 text-left'}>{description}<br/><small>Applies to Dates: {formattedApplicableDates((props.enquiryVenueData.date_from_override ? props.enquiryVenueData.date_from_override : props.brief.date_from), (props.enquiryVenueData.date_to_override ? props.enquiryVenueData.date_to_override : props.brief.date_to), applicable_days, 'DD/MM/YY').join(', ')}</small></td>
                      <td className={'p-2 text-left'}>
                        <Field
                            name={getConferenceDelegatesKey(id)}
                            wrapperClassName="w-full mr-2"
                            component={Input}
                            type={'number'}
                            min={0}
                            step={1}
                            readOnly={Number(props.status) === Number(ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS) || Number(props.status) > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS}
                            normalize={val => (val || '').replace(/[^\d]/g, '')}
                            onChange={onChange}
                        />
                        <Field
                            component={Input}
                            name={getConferenceMinDelegatesKey(id)}
                            type="hidden"
                            style={{ height: 0, margin: 0 }}
                            wrapperClassName="w-full mb-0"
                        />
                      </td>
                      {/*<td className={'p-2 text-left'}>{min_number_of_delegates_for_qualify}</td>*/}
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(Number(price_per_delegate_net))}</td>
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(netTotal)}</td>
                      <td className={'p-2 text-right'}>{vatRateForPackage}%</td>
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(vatTotal)}</td>


                      {props.showCommission ?
                          <td className={'p-2 text-right'}>
                            {`${commissionRate}%`}
                          </td>
                          :
                          null
                      }
                      {props.showCommission ?
                          <td className={'p-2 text-right'}>
                            {props.currencyFormatter.format(commissionTotal)}
                          </td>
                          :
                          null
                      }
                      <td className={'p-2 text-right'}>{props.currencyFormatter.format(price_total_gross)}</td>
                      <td className={'p-2 text-left w-8'}>
                        {
                          Number(props.status) === Number(ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS) || Number(props.status) > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS?
                              null
                              : <Field
                                  name={getConferenceRemoveKey(id)}
                                  component={props.renderCheckBox}
                                  onChange={onChange}
                              />
                        }

                      </td>
                    </tr>
                    <tr key={`conference_package_${id}_error`} className={'border-none '}>
                      <td colSpan={10}> {typeof props.submitWarnings['conference_' + id] !== 'undefined' ?
                          <span className=" table-cell w-100 border-0 text-warning font-normal py-2"> {props.submitWarnings['conference_' + id]} </span> :
                          null}</td>
                    </tr>
                  </React.Fragment>

              );
            },
        )}


      </ChecksheetBreakdown>
  );

};

const selector = formValueSelector('confirmBooking');

export default withRouter(
    connect((state, props) => ({
          hiddenRows: getHiddenRows(props.conferencePackages, getConferenceRemoveKey, state, selector),
          submitWarnings: getFormSyncWarnings('confirmBooking')(state),
          values: getFormValues(props.conferencePackages, getConferenceDelegatesKey, state, selector),
          isVenue: isVenueAdmin(state),
          isAgency: isAgent(state),

        }),
    )(ConferencePackageBreakdown),
);
