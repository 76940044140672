import axios from "axios";
import defaultParseResponse from "./parseResponse";
import {
  createHandleAxiosError,
  getErrorStatus,
} from "../../../modules/api-tools";

/**
 * Just gets the data and initializes the form.
 * On auth error it logs you out.
 * On other error it tells Component it went wrong.
 */
export default (props, setState) => {
  setState.loading();

  const {
    url,
    token,
    initialize,
    parseResponse,
    fields,
    fieldsWithText,
    pluckTheIds,
    pluckTheEquipmentIds,
    initializeEmptyRow,
    history,
    location,
    data,
  } = props;
  if (data) {
    setState.complete();
    initialize(
      parseResponse
        ? parseResponse(data, fields, fieldsWithText, initializeEmptyRow)
        : defaultParseResponse(
            data,
            fields,
            fieldsWithText,
            initializeEmptyRow,
            pluckTheIds,
            pluckTheEquipmentIds
          )
    );
    return;
  }

  const handleAxiosError = createHandleAxiosError({ history, location });

  axios
    .get(window.API + url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      setState.complete();
      initialize(
        parseResponse
          ? parseResponse(
              response.data,
              fields,
              fieldsWithText,
              initializeEmptyRow
            )
          : defaultParseResponse(
              response.data,
              fields,
              fieldsWithText,
              initializeEmptyRow,
              pluckTheIds,
              pluckTheEquipmentIds
            )
      );
    })
    .catch(
      handleAxiosError(err => {
        setState.error(getErrorStatus(err) || true);
      })
    );
};
