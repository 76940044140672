import React from "react";
import { string } from "prop-types";

class Succeeded extends React.Component {
  static propTypes = {
    text: string,
  };

  render() {
    return (
      <div className="text-right">
        <div className="inline-block text-lg mt-3 mb-5 py-3 px-5 text-white bg-success font-normal">
          {this.props.text || "Your changes have been saved"}
        </div>
      </div>
    );
  }
}

export default Succeeded;
