export const FRONTEND = {
  ENQUIRY: {
    NEW: "/request-for-proposal",
  },
  REGISTER: {
    CORPORATE: "/agencies/:agency"
  }
};

export const API = {
  INDUSTRY_SECTOR: {
    LIST: "/industry-sectors",
  },
};