import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, getFormValues, change } from "redux-form";
import { Select } from "Components/Form";

class SortBy extends React.Component {
  updateSortBy = () => {
    const { hasLocation, change, sortBy } = this.props;

    if (!hasLocation && sortBy === "Distance") {
      change("advanced-search", "sortBy", "Capacity");
    }
  };

  UNSAFE_componentWillMount() {
    this.updateSortBy();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hasLocation !== this.props.hasLocation) this.updateSortBy();
  }

  options = () => {
    const { hasLocation, hasDestinations } = this.props;

      return [
        {
          text: "Sort by Default",
          value: "ContentUpdate"
        },
        {
          text: "Sort by Capacity",
          value: "Capacity",
        },
        {
          text: "Sort by Bedrooms",
          value: "Bedrooms",
        },
        {
          text: "Sort by Meeting Rooms",
          value: "Meeting rooms",
        },
        {
          text: "Sort by Nearest Airport",
          value: "Nearest airport",
        },
        hasDestinations
          ? { text: "Sort by Destination", value: "Destination" }
          : null,
        hasLocation
          ? { text: "Sort by Distance", value: "Distance" }
          : null,
      ].filter(Boolean);

    // return [
    //   "ContentUpdate",
    //   hasLocation && "Distance",
    //   "Capacity",
    //   hasDestinations && "Destination",
    //   "Bedrooms",
    //   "Meeting rooms",
    //   "Nearest airport",
    // ].filter(Boolean);
  };

  render() {
    return (
      <div>
        <Field
          name="sortBy"
          component={Select}
          wrapperClassName="mb-0"
          label="Sort by"
          options={this.options()}
          onChange={(e, v, pv, name) => {
            this.props.search( {
              [name]: v,
            });
          }}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const v = getFormValues("advanced-search")(state) || {};
      return {
        hasLocation: !!(v.lat && v.lng),
        sortBy: v.sortBy,
      };
    },
    {  change }
  )(SortBy)
);
