import React from 'react'
import LazyLoad from 'react-lazyload';
import {generalInteraction} from "../../modules/analytics";
import {connect} from 'react-redux'

const InsightCategory = ({img, title, content, generalInteraction}) => {
  return (
    <div className="md:mb-10 xxl:px-12" onClick={() => {
      generalInteraction({
        type: "general_home_insights_link_click",
        data: content.props.children[content.props.children.length - 1].props.children
      })
    }}>
      <div className="mb-3">
        <LazyLoad height={40} once offset={250}>
          <img src={`/img/${img}`} alt="Insight" style={{height: 40, width: 40}}/>
        </LazyLoad>

      </div>
      <div>
        <h3 className="font-heading text-2xl font-normal mb-1 md:mb-4">{title}</h3>
      </div>
      <div>
        {content}
      </div>
    </div>
  )
}

export default connect(null, {generalInteraction})(InsightCategory);
