import {
    UPLOAD_USER_PROFILE_IMAGE_REQUEST,
    UPLOAD_USER_PROFILE_IMAGE_ERROR,
    UPLOAD_USER_PROFILE_IMAGE_REQUEST_RECEIVED,
    UNLOAD_USER, DELETE_USER_PROFILE_IMAGE_ERROR, DELETE_USER_PROFILE_IMAGE_REQUEST
} from './types';

export const singleUser = (state = {
    user: {},
    isFetching: false,
}, action) => {
    switch (action.type) {
        case UPLOAD_USER_PROFILE_IMAGE_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case DELETE_USER_PROFILE_IMAGE_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case UPLOAD_USER_PROFILE_IMAGE_REQUEST_RECEIVED:
            return {
                ...state,
                isFetching: false,
                user: action,
                error: true,
            }
        case UPLOAD_USER_PROFILE_IMAGE_ERROR:
            return {
                ...state,
                isFetching: false,
            }
        case DELETE_USER_PROFILE_IMAGE_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
            }
        case UNLOAD_USER:
            return {
                ...state,
                isFetching: false,
                user: {},
            }
        default:
            return state;
    }
}
