import React from "react";
import { number, func } from "prop-types";

export default class Pagination extends React.Component {
  static propTypes = {
    total: number.isRequired,
    from: number.isRequired,
    to: number.isRequired,
    current_page: number.isRequired,
    last_page: number.isRequired,
    goToPage: func.isRequired,
  };

  pages = () => {
    const { current_page, last_page, goToPage } = this.props;
    const innerPages = [
      current_page - 1,
      current_page,
      current_page + 1,
    ].filter(page => page > 1 && page < last_page);

    return (
      <span className="font-bold">
        {current_page > 1 ? (
          <button
            className="mx-3 cursor-pointer font-bold"
            onClick={() => {
              goToPage(current_page - 1);
            }}
          >
            PREV
          </button>
        ) : (
          <span className="mx-3 text-grey">PREV</span>
        )}

        {this.page(1)}
        {current_page > 3 ? <span className="mr-2">...</span> : null}

        {innerPages.map(page => this.page(page))}

        {last_page - current_page > 2 ? (
          <span className="mr-2">...</span>
        ) : null}
        {last_page !== 0 ? this.page(last_page) : null}

        {current_page < last_page ? (
          <button
            className="font-bold ml-3 cursor-pointer"
            onClick={() => {
              goToPage(current_page + 1);
            }}
          >
            NEXT
          </button>
        ) : (
          <span className="font-bold ml-3 text-grey">NEXT</span>
        )}
      </span>
    );
  };

  page = page => {
    const isCurrent = this.props.current_page === page;
    return isCurrent ? (
      <span
          key={page}
        aria-label={"Current Page, Page " + page}
        aria-current
        className="mr-2 font-bold underline"
      >
        {page}
      </span>
    ) : (
      <button
          key={page}
        aria-label={"Goto Page " + page}
        onClick={() => this.props.goToPage(page)}
        className="mr-2 cursor-pointer"
      >
        {page}
      </button>
    );
  };

  render() {
    const { total, from, to, last_page } = this.props;
    const multiplePages = to - from + 1 < total;
    return (
      <nav aria-label="Pagination Navigation">
        <span className="font-normal mr-3">
          Displaying {multiplePages ? `${from} to ${to} of ` : ""}
          {total} {total !== 1 ? "results." : "result."}
        </span>
        {last_page > 1 && this.pages()}
      </nav>
    );
  }
}
