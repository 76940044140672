import React from "react";

const BannerImg = ({ size, slug, name }) => (
  <div
    className="relative bg-cover bg-center bg-primary"
    style={{
      backgroundImage: `url(${window.BASE_URL}/location-imgs/${slug}-${size}.jpg)`,
    }}
  >
    <div
      style={{
        paddingBottom: size === "lg" ? "22%" : "50%",
      }}
    />
    <h1
      className="absolute p-3 pin-b pin-l w-full text-4xl xl:text-5xl text-white text-shadow capitalize"
      style={{
        backgroundImage: "linear-gradient(to top, rgba(0,0,0,.3), transparent)",
      }}
    >
      Venues in {name}
    </h1>
  </div>
);

export default BannerImg;
