import React from "react";

const LetsConnect = () => {
  return (
    <div className="my-5 p-2">
      <div className="flex flex-wrap p-10 bg-white">
        <div className="w-full lg:w-1/3 xl:ml-20">
          <h2 className="mb-5">Let's connect</h2>
          <p>
            Stay up-to-date by following us across social media. You'll be able
            to catch-up with all the latest news from EDGE and find out about
            the amazing events we have coming up over the year.
          </p>
        </div>
        <div className="flex w-full lg:w-1/3 text-black pt-5 lg:pt-12 lg:pl-20">
          <span className="text-center lg:text-5xl px-3 lg:px-5">
            <a
              href="https://www.facebook.com/EDGEvenues"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-facebook-f block"></i>
            </a>
          </span>

          <span className="text-center lg:text-5xl px-3 lg:px-5">
            <a
              href="https://twitter.com/EDGEvenues"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-twitter block"></i>
            </a>
          </span>

          <span className="text-center lg:text-5xl px-3 lg:px-5">
            <a
              href="https://www.linkedin.com/company/edge-venues/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-linkedin block"></i>
            </a>
          </span>

          <span className="text-center lg:text-5xl px-3 lg:px-5">
            <a
              href="https://www.instagram.com/edgevenues/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-instagram block"></i>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LetsConnect;
