import React, {useState, useEffect} from 'react'
import Modal from "../Modal";
import {ButtonPrimary, ButtonSpan} from "../Button";
import {token} from "../../modules/auth";
import {fetchCarbonImpact} from "../../services/venueService/carbonImpactService";
import { connect } from "react-redux";
import moment from 'moment'
const mapStateToProps = state => ({
    token: token(state),
});

const CarbonImpactModal = ({
                                    carbonImpact,
                                    venue,
                                    accreditations,
                                    offsetStatement,
                                    onClose,
                                    token,

                                }) => {

    if (typeof carbonImpact === 'undefined' || !carbonImpact) {
        return <></>;
    }

    let headerStyle = {
        backgroundColor:  "#bbbbbb" ,
    }

    let primaryRoleStyle = {
        backgroundColor:  "#f9f9f9" ,
    }

    let secondaryRowStyle = {
        backgroundColor:  "#eaeaea" ,
    }

    let innerBorderClass = "border-r-2 border-black"

    return (
        <Modal>
            <div className="modal">
                <div
                    className="modal-inner"
                    style={{
                        width: 1200,
                        maxWidth: "90%",
                    }}
                >
                    <ButtonSpan
                        className="m-3 absolute pin-t pin-r z-10"
                        onClick={onClose}
                    >
                        <i className="fas fa-times-circle fa-2x ml-3" />
                    </ButtonSpan>
                    <div>
                        <h2 className="mb-3 text-3xl">Carbon Impact</h2>
                        <table className="edge-table border-2 border-black">
                            <thead className="bg-grey text-black">
                            <tr style={headerStyle}>
                                <th className={`w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm ${innerBorderClass}`}>Section</th>
                                <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm" style={{textAlign: 'right'}}>Estimated C02e tonnes</th>
                            </tr>
                            </thead>
                            <tbody className="">
                                <tr style={primaryRoleStyle}>
                                    <td className={`"w-1/3 text-left py-3 px-4 ${innerBorderClass}`}>Conference Packages</td>
                                    <td className="w-1/3 text-left py-3 px-4 text-right"  style={{textAlign: 'right'}}>{carbonImpact.carbon_impact_value_conference_packages}</td>
                                </tr>
                                <tr style={secondaryRowStyle}>
                                    <td className={`"w-1/3 text-left py-3 px-4 ${innerBorderClass}`}>Event Spaces</td>
                                    <td className="w-1/3 text-left py-3 px-4 text-right"  style={{textAlign: 'right'}}>{carbonImpact.carbon_impact_value_meeting_rooms}</td>
                                </tr>
                                <tr style={primaryRoleStyle}>
                                    <td className={`"w-1/3 text-left py-3 px-4 ${innerBorderClass}`}>Equipment</td>
                                    <td className="w-1/3 text-left py-3 px-4 text-right"  style={{textAlign: 'right'}}>Not Available</td>
                                </tr>
                                <tr style={secondaryRowStyle}>
                                    <td className={`"w-1/3 text-left py-3 px-4 ${innerBorderClass}`}>Catering</td>
                                    <td className="w-1/3 text-left py-3 px-4 text-right"  style={{textAlign: 'right'}}>{carbonImpact.carbon_impact_value_additional_requirements}</td>
                                </tr>
                                <tr style={primaryRoleStyle}>
                                    <td className={`"w-1/3 text-left py-3 px-4 ${innerBorderClass}`}>Accommodation</td>
                                    <td className="w-1/3 text-left py-3 px-4 text-right"  style={{textAlign: 'right'}}>{carbonImpact.carbon_impact_value_accommodation}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h2 className={'text-right pt-5'}>Total Estimated CO2e tonnes <span className={'pl-5'}>{carbonImpact.carbon_impact_value_total}</span></h2>
                    </div>
                    <div>
                        <h2>How did we calculate these figures?</h2>
                        <p>Average calculations based on data provided by the venue collected from {moment(carbonImpact.applicable_date_from).format('DD/MM/YYYY')} to {moment(carbonImpact.applicable_date_to).format('DD/MM/YYYY')}  delivered by {carbonImpact.name_of_tool} - <a href={carbonImpact.tool_link}> more information</a></p>
                    </div>
                    <div>
                        <h2>What is this venue doing to offset?</h2>
                        <p>{offsetStatement}</p>
                    </div>
                    <div className={'pb-5'}>
                        <h2>Credentials and accreditation</h2>
                        <div className={'flex'}>
                            {accreditations.map((acc) => {
                                return <div className={'pr-5 pt-5'}>
                                    {acc.image_url ?
                                        <img src={acc.image_url} alt={acc.name} width="200px" height="200px"/> :
                                        <img src="/img/accreditation-placeholder.svg" alt={acc.name} width="200px" height="200px"/>}

                                        <ButtonPrimary
                                            Element="a"
                                            href={acc.link ? acc.link : '#'}
                                            target="_blank"
                                            rel="noopener noreferer"
                                        >
                                            More Information
                                        </ButtonPrimary>
                                </div>
                            })}
                        </div>

                    </div>

                </div>
            </div>
        </Modal>
    );
};

export default connect(mapStateToProps, null)(CarbonImpactModal);