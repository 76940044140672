import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ items }) => {
  const path = items.slice(0, -1);
  const current = items[items.length - 1];
  return (
    <nav className="p-1 font-sans w-full m-4 ml-0">
      <ol className="list-reset flex text-grey-dark">
        {path.map(pathItem => (
          <React.Fragment key={pathItem.to}>
            <li>
              <Link to={pathItem.to} className="text-brand font-bold">
                {pathItem.name}
              </Link>
            </li>
            <li>
              <span className="mx-2">/</span>
            </li>
          </React.Fragment>
        ))}
        <li>{current.name}</li>
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
