import React, { useState} from 'react';
import Modal from 'Components/Modal';
import {ButtonSpan} from 'Components/Button';
import {LabelledText} from 'ui';
import useService from 'hooks/useService';
import {getAdditionalItems, getEquipment} from 'services/rfpService';
import {getRoomStyles} from 'services/roomStyleService';
import Additional from './Additional';
import getSaving from './get-saving';
import { currencyFormatter, formattedApplicableDates, getCurrencySymbol } from "utils";
import ToggleRevealer from './Components/ToggleRevealer';
import BreakdownTable from './Components/BreakdownTable';
import {getVatRateById} from '../../pages/admin/rfp/Confirmation/Helpers/calculateTotals';
import MeetingRoomsModal from "../../pages/Venue/MeetingRoomsModal";
import getSingleSaving from "./get-single-saving";

const AdditionalCostsPriceBreakdownModal = ({
                                      onClose,
                                      total = {},
                                      additionalCosts,
                                      venueId,
                                      vatRates = [],
                                      title = 'Additional Costs',
                                      currency = {currency_code: "GBP"}
                                  }) => {
    const {data: additionalItems} = useService({
        initialData: [],
        params: venueId,
        service: getAdditionalItems,
        trigger: venueId,
    });
    // const {data: roomStyles} = useService({
    //     initialData: [],
    //     service: getRoomStyles,
    //     trigger: true,
    // });

    const [modelOpen, setModelOpen] = useState(false);

    if (vatRates.length < 1) {
        return null;
    }
    let getAdditionalItemType = (itemTypeId) => {
        return  additionalItems.find(
            ai => ai.value === itemTypeId,
        ) || {}
    }

    return (
        <Modal>
            <div className="modal">
                <div
                    className="modal-inner"
                    style={{
                        width: 1200,
                        maxWidth: '90%',
                    }}
                >
                    <ButtonSpan
                        className="m-3 absolute pin-t pin-r z-10"
                        onClick={onClose}
                    >
                        <i className="fas fa-times-circle fa-2x ml-3"/>
                    </ButtonSpan>
                    <div >
                        <h2 className="mb-3 text-3xl">{title}</h2>
                        <div className="mb-5 pt-3 border-t border-black">
                            <table>
                                <tbody>
                                {additionalCosts.map(({
                                                          id,
                                                          quantity,
                                                          unit_price,
                                                          is_commissionable,
                                                          is_in_proposal_costs,
                                                          unit_price_reduced_from,
                                                          price_net,
                                                          price_net_src_cur,
                                                          display_price_including_vat,
                                                          price_vat_rate_id,
                                                          price_vat,
                                                          price_vat_src_cur,
                                                          price_reduced_from_net,
                                                          price_reduced_from_net_src_cur,
                                                          price_reduced_from_vat,
                                                          price_reduced_from_vat_src_cur,
                                                          price_percentage_saving,
                                                          created_at,
                                                          updated_at,
                                                          proposal_data_id,
                                                          additional_item_type_id,
                                                          type_name
                                                      }, i) =>
                                    (
                                        <>
                                            <tr key={id}>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8"
                                                        label="Type"
                                                        hideLabel={!!i}
                                                        text={
                                                            type_name
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8"
                                                        label="Quantity"
                                                        hideLabel={!!i}
                                                        text={
                                                            Number(quantity)
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Unit Price Reduced From"}
                                                        hideLabel={!!i}
                                                        text={currencyFormatter(currency.currency_code).format(unit_price_reduced_from ? unit_price_reduced_from : price_reduced_from_net)}
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Unit Price"}
                                                        hideLabel={!!i}
                                                        text={currencyFormatter(currency.currency_code).format(unit_price ? unit_price : price_net)}
                                                    />
                                                </td>

                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                                                        hideLabel={!!i}
                                                        text={currencyFormatter(currency.currency_code).format(price_reduced_from_net)}
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Cost (" + getCurrencySymbol(currency) + ")"}
                                                        hideLabel={!!i}
                                                        text={currencyFormatter(currency.currency_code).format(price_net)}
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label="Cost saving (%)"
                                                        hideLabel={!!i}
                                                        text={`${price_percentage_saving}%`}
                                                    />


                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                                        hideLabel={!!i}
                                                        text={getSingleSaving(price_reduced_from_net, price_net, currency)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="5">
                                                    <ToggleRevealer toggleText="Show Tax Breakdown">
                                                        <BreakdownTable
                                                            headings={['Reduced from', 'Cost']}
                                                            vatRate={getVatRateById(vatRates, price_vat_rate_id).number}
                                                            currency={currency}
                                                            rows={[
                                                                [
                                                                    currencyFormatter(currency.currency_code).format(price_reduced_from_net),
                                                                    currencyFormatter(currency.currency_code).format(price_net),
                                                                ],
                                                                [
                                                                    currencyFormatter(currency.currency_code).format(price_reduced_from_vat),
                                                                    currencyFormatter(currency.currency_code).format(price_vat),
                                                                ],

                                                                [
                                                                    currencyFormatter(currency.currency_code).format(Number(price_reduced_from_net) + Number(price_reduced_from_vat)),
                                                                    currencyFormatter(currency.currency_code).format(Number(price_net) + Number(price_vat)),
                                                                ],
                                                            ]}/>
                                                    </ToggleRevealer>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mb-5 pt-3 border-t border-black">
                            <h3 className="mb-3">Total</h3>
                            <div className="flex flex-wrap">
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label={currency.currency_code === 'GBP' ? `Total Including VAT (${getCurrencySymbol(currency)})` : `Total including Tax (${getCurrencySymbol(currency)})`}
                                    text={currencyFormatter(currency.currency_code).format(total.total_inc_vat)}
                                />
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label={currency.currency_code === 'GBP' ? `Total Excluding VAT (${getCurrencySymbol(currency)})` : `Total Excluding Tax (${getCurrencySymbol(currency)})`}
                                    text={currencyFormatter(currency.currency_code).format(total.total_exc_vat)}
                                />
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label={"Reduced From (" + getCurrencySymbol(currency) + ")"}
                                    text={currencyFormatter(currency.currency_code).format(total.reduced_from_exc_vat)}
                                />
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label="Cost Saving (%)"
                                    text={getSaving(total, currency)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Modal>
    );
};

export default AdditionalCostsPriceBreakdownModal;
