import {COUNTRY_LIST_RECEIVED, COUNTRY_LIST_RECEIVED_ERROR, COUNTRY_LIST_REQUEST} from "./types";

export const countries = (state = {
  countries: {},
  isFetchingCountries: false,
}, action) => {
  switch (action.type) {
    case COUNTRY_LIST_REQUEST:
      return {
        ...state,
        isFetchingCountries: true,
      }
    case COUNTRY_LIST_RECEIVED:
      return {
        ...state,
        isFetchingCountries: false,
        countries: action.data,
      }
    case COUNTRY_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingCountries: false,
      }
    default:
      return state;
  }
}
