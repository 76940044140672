import {saveData} from "./downloadFile";

import axios from "axios";

export const downloadEdgeDocument = async (
    documentId,
    fileName = 'invoice',
    token
) => {
        try {
            const response = await axios.get(
                `${window.API}/documents/${documentId}/`,
                {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            saveData(
                false,
                response.data,
                `${fileName.replace(/\s/g, "-")}`,
            );
            return { result: response.data };
        } catch (error) {
            return { error };
        }

};

