export const VENUE_TAGS = {
  EXIT: "exit",
  TELEPHONE: {
    CLICK_CONTACT_PHONE: "click_contact_phone",
    VIEW_PHONE: "view_phone",
    CLICK_CALL_VENUE: "click_call_venue"
  },
  VIEW_CSR_LINK: "view_csr_link",
  EMAIL: {
    CLICK_CONTACT_EMAIL: "click_contact_email",
    VIEW_EMAIL: "view_email",
    CLICK_EMAIL_VENUE: "click_email_venue"
  },
  WEBSITE: {
    VIEW_WEBSITE: "view_website"
  },
  VIEW_SITE_VISITS: "view_site_visits",
  VIEW_GET_DIRECTIONS: "view_get_directions",
  VIEW_HEALTH_AND_SAFETY: "view_health_and_safety",
  VIEW_FACILITIES: "view_facilities_and_technical",
  VIEW_VIRTUAL_TOUR: "view_virtual_tour",
  VIEW_DETAILS: "view_details",
  VIEW_FEATURES: "view_features",
  QUICK_VIEW_GALLERY_IMAGE: "quick_view_gallery_image",
  QUICK_VIEW_CLICKED: "quick_view_clicked",
  SOCIALS: {
    VIEW_TRIPADVISOR: "view_tripadvisor",
    VIEW_INSTAGRAM: "view_instagram",
    VIEW_TWITTER: "view_twitter"
  },
  WEB_ENQUIRIES: {
    BUY_IT_NOW: "buy_it_now",
    ENQUIRE_SIDEBAR: "enquire_sidebar",
    ENQUIRE_VIA_MEETING_ROOMS: "enquire_via_meeting_rooms"
  },
  VENUE_SHORTLISTED: {
    ADD_TO_SHORTLIST: "add_to_shortlist",
  },
  VIEW_MEETING_ROOMS: {
    VIEW_MEETING_ROOMS: "view_meeting_rooms",
    SHOW_ALL_MEETING_ROOMS: "show_all_meeting_rooms",
    VIEW_MEETING_ROOM: "view_meeting_room"
  },
  VIEW_CATERING: {
    VIEW_CATERING: "view_catering",
    CATERING_EXPANDED:"catering_expanded"
  },
  VIEW_PARKING: {
    VIEW_PARKING: "view_parking",
    PARKING_EXPANDED: "parking_expanded"
  },
  VIEW_DISABLED_ACCESS: "view_disabled_access",
  VIEW_LEISURE: {
    VIEW_LEISURE: "view_leisure",
    LEISURE_EXPANDED: "leisure_expanded"
  },
  VENUE_SHARED: {
    SHARE_INDIVIDUAL_VENUE: "share_individual_venue",
    VENUE_USER_NOTE_ADDED: "venue_user_note_added",
    SHARE_COMPARISON: "share_comparison"
  },
  COMPARE: "compare",
  COMPARE_FEEDBACK: "compare_feedback",
  VIEW_MISC: {
    MISC_EXPANDED: "misc_expanded"
  },
  VENUE_COVID_SECURE_LINK_CLICK: "venue_covid_secure_link_click",
  VENUE_CONTENT_VIEWED: {
    VENUE_CONTENT_PROFILE_CLICK: "venue_content_profile_click",
    VENUE_CONTENT_NEWS_CLICK: "venue_content_news_click",
    VENUE_CONTENT_OFFERS_CLICK: "venue_content_offers_click",
    VENUE_CONTENT_RENOVATION_CLICK: "venue_content_renovation_click",
    VENUE_CONTENT_FAMTRIP_CLICK: "venue_content_famtrip_click",
    VENUE_CONTENT_MOVERSSHAKERS_CLICK: "venue_content_moversshakers_click"
  },
  VENUE_CONTENT_FOOTER_CLICKS: {
    VENUE_CONTENT_SPECIAL_OFFERS_PHONE_CLICK: "venue_content_special_offers_phone_click",
    VENUE_CONTENT_SPECIAL_OFFERS_EMAIL_CLICK: "venue_content_special_offers_email_click",
    VENUE_CONTENT_NEWS_EMAIL_CLICK: "venue_content_news_email_click",
    VENUE_CONTENT_OFFERS_EMAIL_CLICK: "venue_content_offers_email_click",
    VENUE_CONTENT_RENOVATION_EMAIL_CLICK: "venue_content_renovation_email_click",
    VENUE_CONTENT_FAMTRIP_EMAIL_CLICK: "venue_content_famtrip_email_click",
    VENUE_CONTENT_NEWS_PHONE_CLICK: "venue_content_news_phone_click",
    VENUE_CONTENT_OFFERS_PHONE_CLICK: "venue_content_offers_phone_click",
    VENUE_CONTENT_RENOVATION_PHONE_CLICK: "venue_content_renovation_phone_click",
    VENUE_CONTENT_FAMTRIP_PHONE_CLICK: "venue_content_famtrip_phone_click",
    VENUE_CONTENT_COMMISSION_OFFERS_EMAIL_CLICK: "venue_content_commission_offers_email_click",
    VENUE_CONTENT_COMMISSION_OFFERS_PHONE_CLICK: "venue_content_commission_offers_phone_click",
  }
}

export const GENERAL_TAGS = {
  GENERAL_LOGIN_SUCCESS: 'general_login_success'
}