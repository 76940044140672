import * as ROUTES from '../../Router/corporate_routes';
import axios from 'axios';
import moment from 'moment'
import {
  BOOKED_EVENTS_LIST_RECEIVED,
  BOOKED_EVENTS_UNLOAD,
  CREATE_INDUSTRY_EVENT,
  CREATE_INDUSTRY_EVENT_ERROR,
  CREATE_PUBLIC_EVENT,
  CREATE_PUBLIC_EVENT_ERROR,
  DELETE_EVENT_REQUEST,
  EVENT_DELETED_ERROR,
  EVENT_DELETED_REQUEST,
  EVENT_RECEIVED_ERROR,
  EVENT_RECEIVED_REQUEST,
  EVENT_REQUEST,
  EVENT_UNLOAD, EVENTS_CHASED_RECEIVED,
  EVENTS_LIST_RECEIVED,
  EVENTS_LIST_RECEIVED_ERROR,
  EVENTS_LIST_REQUEST,
  EVENTS_LIST_UNLOAD,
  EVENTS_SINGLE_CONFIRMATION_DELETE_FILE,
  EVENTS_SINGLE_CONFIRMATION_UPLOAD,
  EVENTS_SINGLE_FINAL_COMMISSION_VALUES,
  EVENTS_SINGLE_FINAL_COMMISSION_VALUES_ERROR,
  EVENTS_SINGLE_INVOICED_RECEIVED,
  EVENTS_SINGLE_INVOICED_RECEIVED_ERROR,
  EVENTS_SINGLE_RECEIVED,
  EVENTS_SINGLE_RECEIVED_ERROR,
  EVENTS_SINGLE_REQUEST,
  EVENTS_SINGLE_UNLOAD,
  INDUSTRY_EVENT_REQUEST,
  INDUSTRY_EVENTS_LIST_ERROR,
  INDUSTRY_EVENTS_LIST_RECEIVED,
  INDUSTRY_EVENTS_LIST_REQUEST,
  INDUSTRY_EVENTS_LIST_UNLOAD,
  OWN_EVENTS_LIST_RECEIVED,
  OWN_EVENTS_UNLOAD,
  PUBLIC_EVENT_REQUEST,
  PUBLIC_EVENTS_LIST_ERROR,
  PUBLIC_EVENTS_LIST_RECEIVED,
  PUBLIC_EVENTS_LIST_REQUEST,
  PUBLIC_EVENTS_LIST_UNLOAD,
  EVENT_STATUS_LIST,
  EVENT_STATUS_LIST_RECEIVED,
  EVENT_STATUS_LIST_RECEIVED_ERROR,
  EVENT_STATUS_LIST_UNLOAD
} from "../reducers/types";
import {removeEmptySearchParams} from "../../utils";
import handleErrors from "./utils";

export const eventsListRequest = () => ({
  type: EVENTS_LIST_REQUEST
});

export const eventsListReceived = (data) => ({
  type: EVENTS_LIST_RECEIVED,
  data
});

export const ownEventsListReceived = (data) => ({
  type: OWN_EVENTS_LIST_RECEIVED,
  data
});

export const bookedEventsListReceived = (data) => ({
  type: BOOKED_EVENTS_LIST_RECEIVED,
  data
});

export const unloadOwnEvents = () => ({
  type: OWN_EVENTS_UNLOAD
})

export const bookedEventsUnload = () => ({
  type: BOOKED_EVENTS_UNLOAD,
});

export const eventsListReceivedError = (error) => ({
  type: EVENTS_LIST_RECEIVED_ERROR,
  error
});

export const eventsListUnload = () => ({
  type: EVENTS_LIST_UNLOAD
});

export const eventsSingleRequest = () => ({
  type: EVENTS_SINGLE_REQUEST
});

export const eventsSingleReceived = (data) => ({
  type: EVENTS_SINGLE_RECEIVED,
  data
});

export const eventsSingleReceivedError = (error) => ({
  type: EVENTS_SINGLE_RECEIVED_ERROR,
  error
});

export const eventsSingleUnload = () => ({
  type: EVENTS_SINGLE_UNLOAD
});

export const uploadSingleConfirmation = (data) => ({
  type: EVENTS_SINGLE_CONFIRMATION_UPLOAD,
  data
});

export const deleteSingleConfirmationFile = (data) => ({
  type: EVENTS_SINGLE_CONFIRMATION_DELETE_FILE,
  data
});

export const eventRequest = () => ({
  type: EVENT_REQUEST
});
export const eventDeleteRequest = () => ({
  type: DELETE_EVENT_REQUEST
});
export const eventDeletedRequest = (data) => ({
  type: EVENT_DELETED_REQUEST,
  data
});
export const eventDeletedError = (error) => ({
  type: EVENT_DELETED_ERROR,
  error
});
export const unloadEvent = () => ({
  type: EVENT_UNLOAD
});
export const unloadIndustryEvents = () => ({
  type: INDUSTRY_EVENTS_LIST_UNLOAD
});
export const unloadPublicEvents = () => ({
  type: PUBLIC_EVENTS_LIST_UNLOAD
});
export const unloadBookedEvents = () => ({
  type: BOOKED_EVENTS_UNLOAD
});
export const eventReceivedRequest = (data) => ({
  type: EVENT_RECEIVED_REQUEST,
  data
});
export const eventReceivedError = (error) => ({
  type: EVENT_RECEIVED_ERROR,
  error
});
export const publicEventCreated = (data) => ({
  type: CREATE_PUBLIC_EVENT,
  data,
});
export const publicEventCreatedError = (error) => ({
  type: CREATE_PUBLIC_EVENT_ERROR,
  error,
});
export const publicEventRequest = () => ({
  type: PUBLIC_EVENT_REQUEST
});
export const publicEventsListRequest = () => ({
  type: PUBLIC_EVENTS_LIST_REQUEST,
});
export const publicEventsListReceivedRequest = (data) => ({
  type: PUBLIC_EVENTS_LIST_RECEIVED,
  data,
});
export const publicEventsListReceivedError = (error) => ({
  type: PUBLIC_EVENTS_LIST_ERROR,
  error
});
export const industryEventRequest = () => ({
  type: INDUSTRY_EVENT_REQUEST
});
export const industryEventCreated = (data) => ({
  type: CREATE_INDUSTRY_EVENT,
  data
});
export const industryEventCreatedError = (error) => ({
  type: CREATE_INDUSTRY_EVENT_ERROR,
  error
});
export const industryEventsListRequest = () => ({
  type: INDUSTRY_EVENTS_LIST_REQUEST,
});
export const industryEventsListReceivedRequest = (data) => ({
  type: INDUSTRY_EVENTS_LIST_RECEIVED,
  data,
});
export const industryEventsListReceivedError = (error) => ({
  type: INDUSTRY_EVENTS_LIST_ERROR,
  error
});

export const finalCommissionValuesReceived = (data) => ({
  type: EVENTS_SINGLE_FINAL_COMMISSION_VALUES,
  data
});
export const finalCommissionValuesReceivedError = (error) => ({
  type: EVENTS_SINGLE_FINAL_COMMISSION_VALUES_ERROR,
  error
});

export const bookedEventInvoicedReceived = (data) => ({
  type: EVENTS_SINGLE_INVOICED_RECEIVED,
  data
});
export const bookedEventInvoicedReceivedError = (error) => ({
  type: EVENTS_SINGLE_INVOICED_RECEIVED_ERROR,
  error
});

export const bookedEventChaseReceived = (data) => ({
  type: EVENTS_CHASED_RECEIVED,
  data
});
export const eventListStatus = () => ({
  type: EVENT_STATUS_LIST,
});
export const eventStatusListReceivedRequest = data => ({
  type: EVENT_STATUS_LIST_RECEIVED,
  data
});
export const eventStatusListReceivedError = error => ({
  type: EVENT_STATUS_LIST_RECEIVED_ERROR,
  error
});
export const eventStatusListUnload = () => ({
  type: EVENT_STATUS_LIST_UNLOAD
});

export const fetchPublicEvents = (token) => {
  return (dispatch) => {
    dispatch(publicEventsListRequest());
    return axios.get(window.API + ROUTES.API.EVENTS.PUBLIC.LIST, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(publicEventsListReceivedRequest(response.data)))
      .catch(error => dispatch(publicEventsListReceivedError(error)));
  };
};

export const deleteIndustryEvent = (id, token) => {
  return (dispatch) => {
    dispatch(eventRequest());
    return axios.delete(window.API + ROUTES.API.EVENTS.INDUSTRY.SINGLE.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(eventReceivedRequest(response))
      window.location.reload();
    })
      .catch(error => dispatch(eventReceivedError(error)))
  }
};

export const deletePublicEvent = (id, token) => {
  return (dispatch) => {
    dispatch(eventRequest());
    return axios.delete(window.API + ROUTES.API.EVENTS.PUBLIC.SINGLE.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(eventReceivedRequest(response))
      window.location.reload()
    })
      .catch(error => dispatch(eventReceivedError(error)))
  }
};

export const deleteEvent = (id, token) => {
  return (dispatch) => {
    dispatch(eventDeleteRequest());
    return axios.delete(window.API + ROUTES.API.EVENTS.SINGLE.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(eventDeletedRequest(response))
      window.location.reload()
    })
      .catch(error => dispatch(eventDeletedError(error)))
  }
};

export const fetchEvent = (id, token) => {
  return (dispatch) => {
    dispatch(eventRequest());
    return axios.get(window.API + ROUTES.API.EVENTS.SINGLE.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(eventReceivedRequest(response)))
      .catch(error => dispatch(eventReceivedError(error)))
  }
};

export const createPublicEvent = (formValues, token) => {
  let startDate = formValues.get('start_date');
  let endDate = formValues.get('end_date');
  formValues.set('start_date', moment(startDate).format('Y-MM-DD'));
  formValues.set('end_date', moment(endDate).format('Y-MM-DD'));
  return (dispatch) => {
    dispatch(publicEventRequest());
    return axios.post(window.API + ROUTES.API.EVENTS.PUBLIC.CREATE, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(publicEventCreated(response.data))
      window.location.reload()
    })
      .catch(error => {
        dispatch(publicEventCreatedError(error));
        handleErrors(error);
      });
  };
};

export const editPublicEvent = (id, formValues, token) => {
  return (dispatch) => {
    dispatch(publicEventRequest());
    return axios.post(window.API + ROUTES.API.EVENTS.PUBLIC.EDIT.replace(':id', id), formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(publicEventCreated(response))
      window.location.reload()
    })
      .catch(error => {
        dispatch(publicEventCreatedError(error));
        handleErrors(error);
      });
  };
};

export const fetchIndustryEvents = (token, company_id = null) => {
  return (dispatch) => {
    dispatch(industryEventsListRequest());
    return axios.get(window.API + ROUTES.API.EVENTS.INDUSTRY.LIST + "?company_id=" + company_id, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(industryEventsListReceivedRequest(response.data)))
      .catch(error => dispatch(industryEventsListReceivedError(error)));
  };
};

export const fetchEventStatusList = token => {
  return (dispatch) => {
    dispatch(eventListStatus());
    return axios.get(window.API + ROUTES.API.EVENTS.STATUS.LIST, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(eventStatusListReceivedRequest(response.data)))
      .catch(error => dispatch(eventStatusListReceivedError(error)));
  }
}

export const createIndustryEvent = (formValues, token) => {
  let startDate = formValues.get('start_date');
  let endDate = formValues.get('end_date');
  formValues.set('start_date', moment(startDate).format('Y-MM-DD'));
  formValues.set('end_date', moment(endDate).format('Y-MM-DD'));
  return (dispatch) => {
    dispatch(industryEventRequest());
    return axios.post(window.API + ROUTES.API.EVENTS.INDUSTRY.CREATE, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(industryEventCreated(response))
      window.location.reload()
    })
      .catch(error => {
        dispatch(industryEventCreatedError(error));
        handleErrors(error);
      });
  };
};

export const editIndustryEvent = (id, formValues, token) => {
  return (dispatch) => {
    dispatch(industryEventRequest());
    return axios.post(window.API + ROUTES.API.EVENTS.INDUSTRY.EDIT.replace(':id', id), formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(industryEventCreated(response))
      window.location.reload()
    })
      .catch(error => {
        dispatch(industryEventCreatedError(error));
        handleErrors(error);
      });
  };
};

export const fetchCorporateEvents = (token, company_id) => {
  return (dispatch) => {
    return axios.get(window.API + ROUTES.API.BOOKED_EVENTS.CALENDAR + "?company_id=" + company_id, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(bookedEventsListReceived(response.data))
    }).catch(error => {
      dispatch(eventsListReceivedError(error))
      handleErrors(error)
    })
  }
};

export const fetchOwnEvents = (token) => {
  return (dispatch) => {
    return axios.get(window.API + ROUTES.API.BOOKED_EVENTS.CALENDAR + "?own_events", {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(ownEventsListReceived(response.data))
    }).catch(error => {
      dispatch(eventsListReceivedError(error))
      handleErrors(error)
    })
  }
}

let fetchBookedEventsListRequest = null;
export const fetchBookedEventsList = (token, page = 1, queryParams = {}) => {
  // cancel any running request
  if (fetchBookedEventsListRequest) {
    fetchBookedEventsListRequest.cancel();
  }
  fetchBookedEventsListRequest = axios.CancelToken.source();

  if (!queryParams.page) {
    queryParams.page = page;
  }
  var customFilters = encodeURIComponent(JSON.stringify(queryParams.customFields));
  queryParams = new URLSearchParams(queryParams);
  removeEmptySearchParams(queryParams);
  let queryString = queryParams.toString() + (customFilters !== 'undefined' ? '&custom=' + customFilters : '');
  return (dispatch) => {
    dispatch(eventsListRequest());
    return axios.get(window.API + ROUTES.API.BOOKED_EVENTS.ALL + "?" + queryString, {
      cancelToken: fetchBookedEventsListRequest.token,
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(eventsListReceived(response.data)))
      .catch(error => {
        if (!axios.isCancel(error)) {
          dispatch(eventsListReceivedError(error));
          handleErrors(error);
        }
      });
  }
}

export const fetchSingleBookedEvent = (slug, token) => {
  return (dispatch) => {
    dispatch(eventsSingleRequest());
    return axios.get(window.API + ROUTES.API.BOOKED_EVENTS.SINGLE.replace(':slug', slug), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(eventsSingleReceived(response.data)))
        .catch(error => {
          dispatch(eventsSingleReceivedError(error));
          handleErrors(error);
        });
  }
}

export const storeSingleBookedEventConfirmation = (slug, token, formData) => {
  return (dispatch) => {
    return axios.post(window.API + ROUTES.API.BOOKED_EVENTS.CONFIRMATION.replace(':slug', slug), formData, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(uploadSingleConfirmation(response)))
        .catch(error => {
          dispatch(eventsSingleReceivedError(error));
          return Promise.reject(error)
        });
  };
}

export const deleteSingleEventConfirmDocument = (slug, id, token) => {
  return (dispatch) => {
    dispatch(eventsSingleRequest());
    return axios.delete(window.API + ROUTES.API.BOOKED_EVENTS.DELETE_FILE.replace(':slug', slug).replace(':id', id), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(deleteSingleConfirmationFile(response));
    })
        .catch(error => dispatch(eventsSingleReceivedError(error)));
  };
}

export const storeFinalCommissionValues = (slug, token, values) => {
  return (dispatch) => {
    dispatch(eventsSingleRequest());
    return axios.post(window.API + ROUTES.API.BOOKED_EVENTS.FINAL_COMMISSION_VALUE.replace(':slug', slug), values, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(finalCommissionValuesReceived(response));
    })
        .catch(error => dispatch(finalCommissionValuesReceivedError(error)));
  };
}

export const invoiceEvent = (slug, token) => {
  return (dispatch) => {
    return axios.post(window.API + ROUTES.API.BOOKED_EVENTS.INVOICE.replace(':slug', slug), null,  {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(bookedEventInvoicedReceived(response));
    })
        .catch(error => dispatch(bookedEventInvoicedReceivedError(error)));
  };
}

export const chaseEvent = (slug, chaseContract, token, values) => {
  return (dispatch) => {
    return axios.post(window.API + ROUTES.API.BOOKED_EVENTS.CHASE.replace(':slug', slug).replace(':chase', chaseContract? 'chase-contract' : 'chase-invoice'), values,  {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(bookedEventChaseReceived(response));
      Promise.resolve();
    })
    .catch(error => {
      // console.log(error);
      dispatch(bookedEventInvoicedReceivedError(error))
      Promise.reject(error);
    });
  };
}

export const completeEvent = (slug, token) => {
  return (dispatch) => {
    return axios.post(window.API + ROUTES.API.BOOKED_EVENTS.COMPLETE.replace(':slug', slug), null,  {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => {
      dispatch(bookedEventInvoicedReceived(response));
    })
        .catch(error => dispatch(bookedEventInvoicedReceivedError(error)));
  };
}