import React, {useState, useEffect} from'react';
import {getRemovedVenues, reinstateVenue} from "../../services/rfpService/restoreVenue";
import { reduxForm, reset,Field, getFormValues } from "redux-form";
import {
    token as tokenSelector,
} from "../../modules/auth";
import { connect, useSelector } from "react-redux";
import {Select} from "../../ui";
import {getStatusOptions} from "../../modules/rfp/enquiryVenueDataStatus";
import {ButtonBrand, ButtonPrimary} from "../Button";
import moment from 'moment';

let RestoreVenue = ({rfpId, handleSubmit, }) => {

    const [removedVenues, setRemovedVenues] = useState(false);
    const [removedBy, setRemoveBy] = useState(false);
    const [removedAt, setRemovedAt] = useState(false);
    const token = useSelector(tokenSelector);
    const values = useSelector(getFormValues('reinstateVenue'));

    useEffect(() => {
        getRemovedVenues(rfpId, token).then(response => {
            setRemovedVenues(response.result)
            if(values && values.removedVenue )
            {
                let evd = getRemoveEVD(values.removedVenue, response.result);
                setRemovedBy(evd)
            }

        })

    }, [])

    const handleReinstateVenue = (values) => {
        reinstateVenue(rfpId, values.removedVenue, token).then(response => {
            window.location.reload();
        })
    }

    function getRemoveEVD(e, venues) {
        let evd = venues.find(evd => {
            return parseInt(evd.id) === parseInt(e)
        });
        return evd;
    }

    function setRemovedBy(evd) {
        if (evd) {
            if (evd?.removed_by?.first_name && evd?.removed_by?.surname) {
                setRemoveBy(evd?.removed_by?.first_name + ' ' + evd?.removed_by?.surname)
                setRemovedAt(evd?.removed_at)
            }else{
                setRemoveBy(null)
                setRemovedAt(null)
            }
        }
    }

    const onRemoveVenueChange = (e) => {
        let evd = getRemoveEVD(e.target.value, removedVenues);
        setRemovedBy(evd);
    }

    if(!removedVenues || removedVenues.length === 0 )
    {
        return <p>No venues to restore</p>
    }

    return (
        <div>
            <form onSubmit={handleSubmit(handleReinstateVenue)}>
                <Field
                    name="removedVenue"
                    label="Removed Venues"
                    component={Select}
                    options={removedVenues.map(evd => ({
                        text: evd.minimal_venue.name,
                        value: evd.id,
                    }))}
                    onChange={onRemoveVenueChange}
                />
                {removedBy ? <strong >Removed By: {removedBy} at {moment(removedAt).format('DD/MM/YYYY HH:mm:ss')}</strong> : null}
                <ButtonPrimary
                    classes="my-5"
                    submit={true}
                >
                   Restore
                </ButtonPrimary>
            </form>
        </div>
)
}

export default reduxForm({
    form: "reinstateVenue",
})(
    connect(state => ({

    }), {
        getRemovedVenues
    })(RestoreVenue),
);