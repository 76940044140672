import Axios from "axios";
import throttle from "lodash/throttle";
import {
  token as tokenFromState,
  updateUser,
  refreshUserDispatch,
  updateUserCompany,
  updateUserVenues,
  logout
} from "./auth";

const REFRESH_TIMEOUT = 5 * 60 * 1000;

const getAxiosOptions = token => ({
  headers: { Authorization: `Bearer ${token}` },
});

async function refreshUserThunk(dispatch, getState) {
  const token = tokenFromState(getState());
  dispatch(refreshUserDispatch());

  try {
    const { data: user } = await Axios.get(
      `${window.API}/users/me`,
      getAxiosOptions(token)
    );

    dispatch(updateUser({ user }));
  } catch (error) {
    dispatch(logout());
  }
}

const refreshUserThunkThrottled = throttle(refreshUserThunk, REFRESH_TIMEOUT, {
  trailing: false,
});

export function refreshUser(force = false) {

  if (force) {
    refreshUserThunkThrottled.cancel();
  }

  return refreshUserThunkThrottled;
}

async function refreshUserVenuesThunk(dispatch, getState) {
  const token = tokenFromState(getState());

  try {
    const { data: venues } = await Axios.get(
      `${window.API}/users/me/venues`,
      getAxiosOptions(token)
    );

    dispatch(updateUserVenues({ venues }));
  } catch (error) {
  }
}

const refreshUserVenuesThunkThrottled = throttle(
  refreshUserVenuesThunk,
  REFRESH_TIMEOUT,
  { trailing: false }
);

export function refreshUserVenues(force = false) {
  return (dispatch, getState) => {
    if (force) {
      refreshUserVenuesThunkThrottled.cancel();
      refreshUserVenuesThunk(dispatch, getState);
    } else {
      refreshUserVenuesThunkThrottled(dispatch, getState);
    }
  };
}

// export function refreshUserVenues(force = false) {
//   if (force) {
//     refreshUserVenuesThunkThrottled.cancel();
//   }
//
//   return refreshUserVenuesThunkThrottled;
// }

async function refreshUserCompanyThunk(dispatch, getState) {
  const token = tokenFromState(getState());

  try {
    const { data: company } = await Axios.get(
        `${window.API}/users/me/company`,
        getAxiosOptions(token)
    );

    dispatch(updateUserCompany({ company }));
  } catch (error) {
  }
}

const refreshUserCompanyThunkThrottled = throttle(
    refreshUserCompanyThunk,
    REFRESH_TIMEOUT,
    { trailing: false }
);

export function refreshUserCompany(force = false) {
  if (force) {
    refreshUserCompanyThunkThrottled.cancel();
  }

  return refreshUserCompanyThunkThrottled;
}
