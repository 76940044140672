import {
  CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED,
  CORPORATE_ACCOUNT_DEPARTMENT_REQUEST,
  CORPORATE_ACCOUNT_DEPARTMENTS_LIST_RECEIVED,
  CORPORATE_ACCOUNT_DEPARTMENTS_LIST_REQUEST,
  CORPORATE_ACCOUNT_DEPARTMENTS_UNLOAD,
  CORPORATE_ACCOUNT_DEPARTMENT_UNLOAD,
  CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED,
  CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED_ERROR,
  CORPORATE_ACCOUNT_DEPARTMENT_CREATED, CORPORATE_ACCOUNT_DEPARTMENT_CREATED_ERROR
} from "./types";

export const corporateDepartments = (state = {
  corporateDepartments: [],
  isFetchingDepartments: false,
  error: false,
  errorData: []
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_DEPARTMENTS_LIST_REQUEST:
      return {
        ...state,
        isFetchingDepartments: true
      }
    case CORPORATE_ACCOUNT_DEPARTMENTS_LIST_RECEIVED:
      return {
        ...state,
        isFetchingDepartments: false,
        corporateDepartments: action.data.data
      }
    case CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED:
      return {
        ...state,
        corporateDepartments: [...state.corporateDepartments.filter(obj => {
          return obj.id !== action.data.data.id
        })],
        isFetchingDepartment: false,
        error: false
      }
    case CORPORATE_ACCOUNT_DEPARTMENT_DEACTIVATED_ERROR:
      return {
        ...state,
        isFetchingDepartments: false,
        error: true,
        errorData: action.data
      }
    case CORPORATE_ACCOUNT_DEPARTMENT_CREATED:
      return {
        ...state,
        isFetchingDepartments: false,
        error: false,
        corporateDepartments: [...state.corporateDepartments.push(action.data.data)]
      }
    case CORPORATE_ACCOUNT_DEPARTMENT_CREATED_ERROR:
      return {
        ...state,
        isFetchingDepartments: false,
        error: true,
        errorData: action.data
      }
    case CORPORATE_ACCOUNT_DEPARTMENTS_UNLOAD:
      return {
        ...state,
        isFetchingDepartments: false,
        corporateDepartments: []
      }
    default: return state;
  }
}

export const corporateDepartment = (state = {
  corporateDepartment: {},
  isFetchingDepartment: false,
  error: false,
  errorData: []
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_DEPARTMENT_REQUEST:
      return {
        ...state,
        isFetchingDepartment: true
      }
    case CORPORATE_ACCOUNT_DEPARTMENT_RECEIVED:
      return {
        ...state,
        isFetchingDepartment: false,
        corporateDepartment: action.data.data
      }
    case CORPORATE_ACCOUNT_DEPARTMENT_UNLOAD:
      return {
        ...state,
        corporateDepartment: {}
      }
    default: return state;
  }
}