import React from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import { venues, paidVenues, premiumVenues } from "modules/auth";
import Checkbox from "./Checkbox";
import { bool, arrayOf, number, string, shape } from "prop-types";

const validateVenueRequired = venues => {
  if (!Array.isArray(venues) || venues.length === 0) {
    return "At least one venue is required";
  }
};

const VenuesDisplay = ({ venues, isRequired }) => (
  <Field
    name="venues"
    label="Venues"
    component={Checkbox}
    options={venues.map(item => ({
      text: item.name,
      value: item.id,
    }))}
    validate={isRequired ? validateVenueRequired : undefined}
  />
);

VenuesDisplay.propTypes = {
  venues: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
  isRequired: bool,
};

const VenuesFromState = connect((state, { paid, premium }) => {
  if (!paid && !premium) return { venues: venues(state) };

  const _paidVenues = paid ? paidVenues(state) : [];
  const _premiumVenues = premium ? premiumVenues(state) : [];

  return { venues: _paidVenues.concat(_premiumVenues) };
})(VenuesDisplay);

// If venueOptions prop is provided then use that as the venues list
// otherwise get the venues list from the user's state
const Venues = ({
  venueOptions = undefined,
  isRequired = undefined,
  ...rest
}) =>
  venueOptions ? (
    <VenuesDisplay venues={venueOptions} isRequired={isRequired} {...rest} />
  ) : (
    <VenuesFromState isRequired={isRequired} {...rest} />
  );

export default Venues;
