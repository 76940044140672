import uniq from "lodash/uniq";
import { array, object, oneOfType, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Error from "./Error";
import Label from "./Label";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";

const getOptionValue = option =>
  typeof option === "string" ? option : option.value;
const getOptionText = option =>
  typeof option === "string" ? option : option.text;

// This is to fix an issue where the same value could appear multiple times
// The toggleOption code has been changed so it will not happen again
// This is here to fix old duplicated data
const deDuplicateValues = ({ value: values, onChange }) => {
  if (!Array.isArray(values)) return;

  const uniqValues = uniq(values);

  if (values.length !== uniqValues.length) {
    onChange(uniqValues);
  }
};

export default function Radio({
  meta: { touched, error, warning },
  input,
  label,
  wrapperClassName,
  labelClassName,
  options,
  withSelectAll,
  selectAllLabel,
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => deDuplicateValues(input), [input.value]);
  const [toggleAll, setToggleAll] = useState(false);
  const valueArray = Array.isArray(input.value) ? input.value : [];
  const toggleOption = (optionValue, isSelected) => {
    input.onChange(
      isSelected
        ? valueArray.filter(item => item !== optionValue)
        : [...valueArray, optionValue]
    );
  };

  useEffect(() => {
    if (valueArray.length === options.length) setToggleAll(true);
    else setToggleAll(false);
  }, [options.length, toggleAll, valueArray.length]);

  const toggleAllOptions = () => {
    if (valueArray.length === options.length) input.onChange([]);
    else input.onChange([...options.map(option => option.value)]);
  };

  // const toggleAll = () => {
  //   if (valueArray.length < options.length) {
  //     const toAdd = options.map(item => item.value);
  //     input.onChange([...toAdd]);
  //   } else {
  //     input.onChange([]);
  //   }
  // };

  return (
    <div className={wrapperClassName || "w-full mb-5"}>
      <Label name={input.name} className={labelClassName} text={label} />
      <div className="flex flex-wrap w-full">
        {withSelectAll && (
          <label
            onClick={toggleAllOptions}
            role="button"
            className="mr-5 text-sm flex items-center inline-block outline-none border border-transparent"
          >
            {toggleAll && <FaCheckSquare className="text-primary mr-2" />}
            {!toggleAll && <FaRegSquare className="mr-2" />}
            {selectAllLabel}
          </label>
        )}
        {options.map(option => {
          const value = getOptionValue(option);
          const text = getOptionText(option);
          const isSelected = valueArray.includes(value);

          const toggle = () => toggleOption(value, isSelected);

          return (
            <label
              key={value}
              role="button"
              tabIndex="0"
              className="mr-5 text-sm flex items-center inline-block outline-none border border-transparent"
              onClick={toggle}
              onKeyPress={toggle}
              style={{
                height: 41,
              }}
            >
              {isSelected && <FaCheckSquare className="text-primary mr-2" />}
              {!isSelected && <FaRegSquare className="mr-2" />}
              {/* <i
                className={classNames("far mr-2", {
                  "fa-check-square text-primary": isSelected,
                  "fa-square": !isSelected,
                })}
              /> */}
              {text}
            </label>
          );
        })}
      </div>
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
}

Radio.propTypes = {
  options: array,
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]).isRequired,
  wrapperClassName: string,
  labelClassName: string,
  inputClassName: string,
};

Radio.defaultProps = {
  options: [],
};
