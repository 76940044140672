import React, { useEffect } from "react";
import { Field } from "redux-form";
import { ButtonSpan, ButtonTransparent } from "Components/Button";
import { stage1 as v } from "../../pages/Rfp/validate";
import { Panel } from "../../ui";
import { Input, Select } from "../Form";
import Loading from "../Loading";

const RepeatableSyndicateRoom = ({ fields = [], roomStyleOptions = {}, roomStylesLoading, values }) => {
  useEffect(() => {
    fields.length < 1 && fields.push({});
  }, []);
  if (roomStylesLoading) {
    return <Loading />;
  }
  let syndicateRoomValues = values.syndicate_rooms;
  return (
    <div className="row mt-2">
      {!fields.length && (
        <Panel>
          <ButtonTransparent onClick={() => fields.push({})}>
            <i className="fal fa-plus" />
          </ButtonTransparent>
        </Panel>
      )}
      {fields.map((member, index) => {
        return (
          <div className="row px-4 lg:p-0" key={index}>
            <div className="flex ml-4 pl-4 justify-center items-center">
              <ButtonSpan onClick={() => fields.remove(index)}>
                <i className="mr-2 fal fa-minus-circle"></i>
              </ButtonSpan>
            </div>
            <div className="col lg:col-w-1/4">
              <Field
                name={`${member}.room_style_id`}
                label="Room layout"
                component={Select}
                options={roomStyleOptions}
                wrapperClassName="mb-3"
                size="lg"
                validate={v.room_style_id}
              />
            </div>
            <div className="col lg:col-w-1/4">
              <Field
                name={`${member}.number_of_delegates`}
                label="Number of delegates"
                size="lg"
                component={Input}
              />
            </div>
            <div className="col lg:col-w-1/3">
              <Field
                name={`${member}.quantity`}
                label="Quantity of Syndicate Rooms"
                component={Input}
                size="lg"
                wrapperClassName="mb-5 mr-5"
              />
            </div>
            {(index === fields.length - 1) ? (
              <div className="flex justify-center items-center">
                <ButtonTransparent onClick={() => fields.push({})}>
                  <i className="fal fa-plus" />
                </ButtonTransparent>
              </div>
            ) : null}
            {values && values.syndicate_rooms[index] && values.syndicate_rooms[index].room_style_id ? (
              <div
                className="re-16/9 mb-8 bg-cover"
                style={{
                  maxWidth: 300,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url('/img/assets/mr-${roomStyleOptions
                    .find(
                      option => option.value === parseInt(values.syndicate_rooms[index].room_style_id),
                    )
                    .text.toLowerCase()
                    .replace("-", "")}.jpg')`,
                }}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default RepeatableSyndicateRoom;
