import axios from "axios";

const reassignAndAuthoriseProposal = async (id, client, token) => {
  try {
    await axios.post(
      `${window.API}/rfp/enquiry/reassign/authorise`, {
        id,
        client_id: client,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return { result: true };
  } catch (error) {
    return { error };
  }
};

export { reassignAndAuthoriseProposal };
