import uuidv4 from "uuid-browser/v4";
import {
  venueImpression,
  venueInteraction,
  searchClickThrough,
  searchImpressions,
  generalInteraction
} from "./actions/tracking";
import { fetchVenueData, fetchAnalyticsImage } from "./actions/dashboard";
import { authenticate } from "./actions/authenticate";
import reducer from "./reducer";
import {
  searchImpressionsByDay,
  venuePageViewsByDay,
  engagementsByDay,
  searchClicksByDay,
  venuePageByDay,
  searchRankByDay,
  engagementSplitByDay,
  audienceByDay,
  enquiriesByDay,
  dataScoreByDay,
  venueDataMeta,
  venues,
  keyEngagements,
  detailedEngagements,
  userTypes,
  industryTypes,
  devices,
  hourlySearchActivity,
  hourlyVenueActivity,
  socialEngagements,
  hourlyEngagementActivity,
  audienceMeta,
  audienceMetaByType,
  audienceTownCity,
  authenticating,
  otherVenuesViewed,
  error,
  token,
  venuePageMeta,
  searches,
  currentDataScore,
} from "./selectors";
import getDatesArray from "./get-dates-array";

const init = () => {
  if (process.env.NODE_ENV === "development") return;

  if (!process.env.REACT_APP_ANALYTICS) {
    console.error("Set REACT_APP_ANALYTICS in .env");
    return;
  }

  window.uuid = localStorage.getItem("uuid") || uuidv4();

  // Try/Catch to prevent safari mobile issue
  try {
    localStorage.setItem("uuid", window.uuid);
  } catch (err) {
    // do nothing
  }
};

export {
  init,
  venueImpression,
  venueInteraction,
  generalInteraction,
  searchClickThrough,
  searchImpressions,
  keyEngagements,
  detailedEngagements,
  fetchVenueData,
  fetchAnalyticsImage,
  otherVenuesViewed,
  industryTypes,
  searchImpressionsByDay,
  venuePageViewsByDay,
  engagementSplitByDay,
  venuePageByDay,
  dataScoreByDay,
  audienceByDay,
  audienceTownCity,
  searchRankByDay,
  engagementsByDay,
  searchClicksByDay,
  enquiriesByDay,
  venueDataMeta,
  venues,
  userTypes,
  devices,
  socialEngagements,
  hourlyVenueActivity,
  hourlyEngagementActivity,
  hourlySearchActivity,
  searches,
  getDatesArray,
  audienceMeta,
  audienceMetaByType,
  venuePageMeta,
  currentDataScore,
  authenticating,
  error,
  token,
  reducer,
  authenticate,
};
