import axios from 'axios';
import { COUNTRY_LIST_RECEIVED, COUNTRY_LIST_RECEIVED_ERROR, COUNTRY_LIST_REQUEST } from '../reducers/types';
import * as ROUTES from '../../Router/corporate_routes';

/** Countries */
const countryListReceivedRequest = (data) => ({
  type: COUNTRY_LIST_RECEIVED,
  data
});

export const countryListReceivedError = (error) => ({
  type: COUNTRY_LIST_RECEIVED_ERROR,
  error
});

export const countryListRequest = () => ({
  type: COUNTRY_LIST_REQUEST
});

/** Countries */
export const fetchCountries = (token) => {
  return (dispatch) => {
    dispatch(countryListRequest());
    return axios.get(window.API + ROUTES.API.COUNTRIES.LIST, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(countryListReceivedRequest(response.data)))
        .catch(error => dispatch(countryListReceivedError(error)));
  }
}
