import React, { Component, Fragment } from "react";
import { arrayOf, number, any, func } from "prop-types";
import PaginationNav from "./Pagination";

export default class PaginationWrapper extends Component {
  static propTypes = {
    items: arrayOf(any).isRequired,
    itemsPerPage: number.isRequired,
    render: func.isRequired,
    itemCount: number,
  };

  state = {
    page: 1,
  };

  setPage = page => {
    this.setState({
      page: Math.min(Math.max(1, page), this.pageCount()),
    });
  };

  itemCount = () =>
    this.props.itemCount !== undefined
      ? this.props.itemCount
      : this.props.items.length;

  pageCount = () => Math.ceil(this.itemCount() / this.props.itemsPerPage);

  currentPageItems = () => {
    const { items, itemsPerPage } = this.props;
    const { page } = this.state;

    const firstItem = (page - 1) * itemsPerPage;
    const lastItem = firstItem + itemsPerPage;
    return items.slice(firstItem, lastItem);
  };

  render() {
    const { itemsPerPage } = this.props;
    const { page } = this.state;
    const items = this.currentPageItems();
    const itemCount = this.itemCount();
    const pageCount = this.pageCount();

    const firstItem = itemCount === 0 ? 0 : (page - 1) * itemsPerPage + 1;
    const lastItem = Math.min(firstItem + itemsPerPage - 1, itemCount);

    return (
      <Fragment>
        {this.props.render({ items })}
        <div className="my-2 flex flex-row justify-end">
          <PaginationNav
            total={itemCount}
            current_page={page}
            from={firstItem}
            to={lastItem}
            last_page={pageCount}
            goToPage={page => this.setPage(page)}
          />
        </div>
      </Fragment>
    );
  }
}
