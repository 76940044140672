import { format } from "date-fns";
import groupBy from "lodash/groupBy";
import getDatesArray from "./get-dates-array";
import getMonthsArray from "./get-months-array";
import moment from 'moment';

const DAILY_DATE_FORMAT = "MMM Do, YYYY";

export default (data, start, end, getTotal) => {
  if (!data) return null;

  const dates = getMonthsArray(start, end);
  if (!Array.isArray(dates)) {
    return null;
  }

  return dates.map(date => {


    let count  = data.filter((record) => {
      return moment(record['day']).month() ===  moment(date).month()
    })

    if (getTotal) {
      return {
        day: format(date, DAILY_DATE_FORMAT),
        total: getTotal(count || []),
      };
    }
    return {
      day: moment(date).format('MMM'),
      total: count ? count.length : 0,
    };
  });
};
