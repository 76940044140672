import React from "react";
import classNames from "classnames";

const Pagination = ({ current, last, onClick = () => {} }) => {
  if (last <= 1) {
    return null;
  }

  const pages = [];

  for (let i = 1; i <= last; i++) {
    pages.push(i);
  }

  return (
    <div className="flex justify-end flex-wrap">
      {pages.map(page => {
        return (
          <div
            key={page}
            className={classNames("flex items-center justify-center h-8 w-8 mb-2 ml-2", {
              "": page !== last,
              "border border-black pointer": page !== current,
              "text-white bg-primary": page === current,
            })}
            onClick={page !== current ? () => onClick(page) : null}
          >
            {page}
          </div>
        );
      })}
    </div>
  );
};

export default Pagination;
