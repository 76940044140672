import React from "react";

export default function NotFound({ children }) {
  return (
    <div className="py-8 container">
      <h1 className="text-3xl mb-5">404</h1>
      {children}
    </div>
  );
}
