import React from "react";
import _get from "lodash/get";
import _truncate from "lodash/truncate";
import { ButtonBrand } from "Components/Button";
import moment from "moment";
import BackgroundImage from "../../Components/General/BackgroundImage";

const SiteVisitPreview = props => {
  return (
    <div className={props.className}>
      {props.type === "venue" && <RenderVenueSiteVisitPreview {...props} />}
      {props.type === "destination" && (
        <RenderDestinationSiteVisitPreview {...props} />
      )}
    </div>
  );
};

const RenderVenueSiteVisitPreview = props => {
  _get(
    props,
    "visited.featured_image.url",
    "/img/venue-placeholder.jpg"
  );
  let visit_date = `${moment(props.visit_date_from).format("MMMM Do YYYY")}`;

  // Add second date only if the visit was more than 1 day
  if (props.visit_date_from !== props.visit_date_to) {
    visit_date = `${visit_date} - ${moment(props.visit_date_to).format(
      "MMMM Do YYYY"
    )}`;
  }

  return (
    <>
      <div className="row p-4">
        <div className="col col-w-full">
          <h3 className="mr-4">
            {visit_date}:<br/>
            {props.author_visible && props.author && typeof props.author !== 'undefined'
              ? `${props.author.first_name} ${props.author.surname} (${props?.user_type === 'Agency' ? 'Agent' : props?.user_type || 'Agent'})`
              : `Anonymous (${props?.user_type === 'Agency' ? 'Agent' : props?.user_type || 'Agent Insight'})`}
          </h3>
        </div>
      </div>
      <div className="row px-4 pb-4 ">
        <div className="col col-w-full">
          <div className={'flex justify-between items-center'}>
            {props.is_legacy ?
                <p style={{maxWidth: '80%'}} dangerouslySetInnerHTML={{
                  __html: _truncate(props.impressions, { length: 250 })
                }}/>
                :
                <p  style={{maxWidth: '80%'}} >{_truncate(props.impressions, { length: 250 })}</p>
            }
            {
              props.author_image_Visible && props.author && props.author.profile_image_path  ?
                  <BackgroundImage
                      imagePath={props.author ? props.author.profile_image_path : ''}
                      className="bg-cover bg-center rounded-full h-24 w-24 flex items-center justify-center"
                      edits={{
                        "roundCrop": true,
                        "resize": {
                          "width": 150,
                          "height": 150,
                          "fit": "cover"
                        }
                      }} />
                  : null
            }
          </div>

          <div className="flex justify-start">
          <ButtonBrand Element="a" href={`/site-visits/${props.id}`}>
            View full report
          </ButtonBrand>
          </div>
        </div>
      </div>
    </>
  );
};

// To be built with destination functionality
const RenderDestinationSiteVisitPreview = props => {
  return (
    <>
      <div className="w-full lg:w-1/2">
        <h3>{props.visited.display_name}</h3>
      </div>
      <div className="w-full lg:w-1/2"></div>
    </>
  );
};

export default SiteVisitPreview;
