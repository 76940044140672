import {
    LAST_SIMPLE_SEARCH,
} from "./types";

export const search = (state = {
    lastSimpleSearch: {},
}, action) => {
    switch (action.type) {
        case LAST_SIMPLE_SEARCH:
            return {
               lastSimpleSearch: action.data
            }
        default:
            return state;
    }
}