const ValidateImage = (file, options) => {
  for (const [ruleName, ruleValue] of Object.entries(options)) {
    validateRule(file, ruleName, ruleValue);
  }
};

const validateRule = (file, ruleName, ruleValue) => {
  switch (ruleName) {
    case "maxSize":
      if (file.size > ruleValue.size) {
        // Need dynamic version of this error message
//        console.log(file.size);
        throw `Image must be ${ruleValue.message} or smaller.`;
      }
      break;
    case "extensionIn":
      let passed = false;
      for (let i = 0; i < ruleValue.length; i++) {
        if (file.name.toLowerCase().endsWith(ruleValue[i])) {
          passed = true;
        }
      }

      if (!passed) {
        throw `The image must be a file of type: ${ruleValue.join(", ")}.`;
      }
      break;
    default:
      break;
  }
};

export { ValidateImage };
