import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { GBPFormatter } from "utils";
import { isLoggedIn, isAgent, isInternal } from "modules/auth";
import { ButtonBlack, ButtonPrimary, ButtonSpan } from "Components/Button";
import EnquiryStatus from "../../pages/admin/rfp/Confirmation/Components/EnquiryStatus";
import { formatDate } from "../../modules/dates";

const Row = ({ dark, title, value }) => {
  return (
    <div
      className="p-2 flex justify-between items-center"
      style={{
        backgroundColor: dark ? "#eaeaea" : "#f9f9f9",
      }}
    >
      <span>{title}</span>
      <span className="font-bold">{value}</span>
    </div>
  );
};

const PreparingSlide = ({ onChaseVenue, onRemove, venue, isManaged, data, draft = false, rfpId }) => {
  const agent = useSelector(isAgent);
  const internal = useSelector(isInternal);
  const loggedIn = useSelector(isLoggedIn);

  return (
    <div className="mb-5">
      <h2
        className="text-white bg-black font-normal font-body p-3 uppercase"
        style={{ minHeight: "6.7rem" }}
      >
        {venue.name}
      </h2>
      {!draft && (
        <p className="bg-primary text-white font-normal uppercase text-lg flex items-center p-3"
           style={{ minHeight: "5rem" }}
        >
          <span className="">{venue.time}</span>
          <EnquiryStatus status={!data.is_visible_to_client ?  0 : data.status} />
      </p>
      )}
      <div
        className="re-16/9 w-full bg-no-repeat bg-cover bg-center"
        style={
          venue.image
            ? {
                backgroundImage: `url(${window.API}/venues/${venue.slug}/images/${venue.image})`,
              }
            : null
        }
      />
      <div className="flex justify-between p-3 font-normal text-xl mb-2">
        <div>
          <i className="mr-2 fas fa-users" />
          {venue.capacity}
        </div>
        <div>
          <i className="mr-2 fas fa-bed" />
          {venue.bedrooms}
        </div>
        <div>
          <i className="mr-2 fas fa-handshake" />
          {venue.meeting_rooms}
        </div>
      </div>
      {loggedIn && (
        <React.Fragment>
        {venue.holding_until_date ?
          <Row
            title="Will Hold Until"
            value={formatDate(venue.holding_until_date,'ddd Do MMM YYYY')}
          /> : null
        }
        {venue.hold_for ?
            <Row
                title="Will hold for "
                value={venue.hold_for + " day(s)" }
                dark
            />
        : null }
          <Row
            title="Special Offers"
            value={venue.specialOffer ? "Yes" : "No"}
          />
        </React.Fragment>
      )}
      <Row title="Star Rating" value={venue.star_rating} dark />
      {(agent || internal) && (
        <React.Fragment>
          <Row title="Agency Rates" value={venue.agencyRates ? "Yes" : "No"} />
          <Row
            title="Commission Deals"
            value={venue.commission ? "Yes" : "No"}
            dark
          />
          <Row title="Fam Trips" value={venue.famTrips ? "Yes" : "No"} />
        </React.Fragment>
      )}
      <ButtonBlack
        classes="mt-5 p-3 flex items-center justify-center w-full mb-2"
        Element={Link}
        target="_blank"
        to={`/venue/${venue.slug}`}
        rel="noopener noreferrer"
        style={{ height: "auto", lineHeight: 1 }}
      >
        View venue
      </ButtonBlack>
      {isManaged && !agent ?
        null
        :
        (!draft) && <ButtonPrimary
          classes="p-3 w-full mb-2"
          onClick={() => onChaseVenue(venue)}
          style={{ height: "auto", lineHeight: 1 }}
        >
          Chase quote
      </ButtonPrimary>}
      {isManaged && !agent ?
        null
        :
        <ButtonSpan
          className="p-3 text-center block w-full mb-2"
          onClick={() => onRemove(venue, rfpId, draft)}
          style={{ height: "auto", lineHeight: 1 }}
        >
          <i className="mr-3 fal fa-minus-circle"></i>
          Remove Venue
        </ButtonSpan>
      }
    </div>
  );
};

export default PreparingSlide;
