import React from "react";
import { ButtonBrand, ButtonWhite } from "Components/Button";
import { Link as ScrollLink } from "react-scroll";

const SearchStepFooter = ({ last, onNext, onBack, scrollTo, scrollBack }) => {
  return (
    <div className="bg-light-grey py-4 px-2 flex">
      {last ? (
        <ScrollLink smooth className="pointer" to="results">
          <ButtonBrand classes="text-lg">See search results</ButtonBrand>
        </ScrollLink>
      ) : (
        <>
          <ScrollLink smooth className="pointer" to={`${scrollTo}`}>
            <ButtonBrand classes="mr-3 text-lg" onClick={onNext}>
              Next
            </ButtonBrand>
          </ScrollLink>
          <ScrollLink
            smooth
            className="hidden sm:block pointer"
            to={`${scrollBack}`}
          >
            <ButtonWhite classes="mr-12 text-lg text-black" onClick={onBack}>
              Back
            </ButtonWhite>
          </ScrollLink>
          <ScrollLink smooth className="pointer" to="results">
            <ButtonWhite classes="text-lg">See search results</ButtonWhite>
          </ScrollLink>
        </>
      )}
    </div>
  );
};

export default SearchStepFooter;
