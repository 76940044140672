import React, {Fragment, useEffect} from "react";
import {Field} from "redux-form";
import Input from "../Input";
import {token, user} from "../../../modules/auth";
import {corporateDepartmentsUnload, fetchCorporateDepartments} from "../../../redux/actions";
import { connect } from "react-redux";
import {Select} from "../index";

const mapStateToProps = (state, props) => {
  return ({
    ...state.corporateDepartments,
    token: token(state),
    corporate_account: props.corporate_account ? props.corporate_account : user(state).corporate_account
  })
}

const mapDispatchToProps = {
  fetchCorporateDepartments,
  corporateDepartmentsUnload
}

const CorporateUserFields = (props) => {
  useEffect(() => {
    if (props.corporate_account) {
      props.fetchCorporateDepartments(props.corporate_account.id, props.token)
    }
    return () => {
      props.corporateDepartmentsUnload()
    }
  }, [])

  const getDepartmentsOptions = () => {
    let options = [];
    (props.corporateDepartments) && props.corporateDepartments.map(department => {
      options.push({
        text: department.name,
        value: department.id
      })
    });
    return options;
  }

  return (
    <Fragment>
      <h3>Corporate EDGE Profile</h3>
      <div className="row mt-4">
        <div className="col md:col-w-1/4">
          <Field
            name={'corporate_telephone'}
            component={Input}
            label={'Contact Telephone'}
          />
        </div>
        <div className="col md:col-w-1/4">
          <Field
            name={'corporate_email'}
            component={Input}
            label={'Contact Email'}
          />
        </div>
        <div className="col md:col-w-1/4">
          <Field
            name={'corporate_job_title'}
            component={Input}
            label={'Job Title'}
          />
        </div>
        {getDepartmentsOptions().length > 0 && (
          <div className="col md:col-w-1/4">
            <Field
              name={'corporate_department_id'}
              component={Select}
              options={getDepartmentsOptions()}
              label={'Department'}
            />
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateUserFields)