import {
  AGENCY_LIST_ERROR,
  AGENCY_LIST_RECEIVED,
  AGENCY_LIST_REQUEST,
  AGENCY_LIST_UNLOAD,
  AGENCY_RECEIVED,
  AGENCY_RECEIVED_ERROR,
  AGENCY_REQUEST,
  AGENCY_ROLES_UPDATE_RECEIVED_REQUEST,
  AGENCY_UNLOAD,
  AGENCY_USER_RECEIVED,
  AGENCY_USER_RECEIVED_ERROR,
  AGENCY_USER_REQUEST,
  CREATE_AGENCY_ERROR,
  DELETE_AGENCY_USER,
  EDIT_AGENCY,
  EDIT_AGENCY_ERROR, SUPER_AGENCIES_RECEIVED_ERROR, SUPER_AGENCIES_RECEIVED_REQUEST, SUPER_AGENCIES_REQUEST,
} from "./types";

export const agencyList = (state = {
  agencies: {},
  isFetchingAgencies: false,
  agenciesPage: null,
  agenciesLastPage: null,
}, action) => {
  switch (action.type) {
    case AGENCY_LIST_REQUEST:
      return {
        ...state,
        isFetchingAgencies: true,
      }
    case AGENCY_LIST_RECEIVED:
      return {
        ...state,
        isFetchingAgencies: false,
        agencies: action.data,
        agenciesPage: action.data?.meta?.current_page,
        agenciesLastPage: action.data?.meta?.last_page,
      }
    case AGENCY_LIST_ERROR:
      return {
        ...state,
        isFetchingAgencies: false,
      }
    case AGENCY_LIST_UNLOAD:
      return {
        ...state,
        isFetchingAgencies: false,
        agencies: {},
        agenciesPage: null,
        agenciesLastPage: null
      }
    default:
      return state;
  }
}

export const agencyUser = (state = {
  user: {},
  isFetchingAgencyUser: false,
}, action) => {
  switch (action.type) {
    case AGENCY_USER_REQUEST:
      return {
        ...state,
        isFetchingAgencyUser: true,
      }
    case AGENCY_USER_RECEIVED:
      return {
        ...state,
        isFetchingAgencyUser: false,
        user: action.data
      }
    case AGENCY_USER_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingAgencyUser: false,
      }
    default:
      return state;
  }
}

export const agency = (state = {
  agency: {},
  isFetchingAgency: false,
  isUpdatingAgency: false,
  error: false,
  accessDenied: false,
}, action) => {
  switch (action.type) {
    case AGENCY_REQUEST:
      return {
        ...state,
        isFetchingAgency: true,
      }
    case AGENCY_RECEIVED:
      return {
        ...state,
        isFetchingAgency: false,
        agency: action.data,
        accessDenied: false,
      }
    case AGENCY_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingAgency: false,
        error: true,
        accessDenied: action.data.response.status === 401
      }
    case EDIT_AGENCY:
      return {
        ...state,
        isUpdatingAgency: false,
        isFetchingAgency: false,
        error: false,
        agency: action.data.data,
        accessDenied: false,
      }
    case EDIT_AGENCY_ERROR:
      return {
        ...state,
        isUpdatingAgency: false,
        isFetchingAgency: false,
        error: true,
        errorData: action.error
      }
    case CREATE_AGENCY_ERROR:
      return {
        ...state,
        isUpdatingAgency: false,
        isFetchingAgency: false,
        error: true,
        errorData: action.error
      }
    case AGENCY_ROLES_UPDATE_RECEIVED_REQUEST:
      let users = ([...state.agency.users.map(user => {
        if (user.id === action.data.data[0].id) {
          return user = action.data.data[0];
        }
        return user
      })]);
      state.agency.users = users;
      return {
        ...state,
        error: false,
        ...state.agency
      }
    case DELETE_AGENCY_USER:
      let agencyUsers = ([...state.agency.users.filter(obj => {
        return obj.id !== action.data.data.id
      })]);
      state.agency.users = agencyUsers;
      return {
        ...state,
        isFetchingAgency: false,
        error: false,
        ...state.agency
      }
    case AGENCY_UNLOAD:
      return {
        agency: {},
        isFetchingAgency: false,
        error: false,
      }
    default:
      return state;
  }
}

export const superAgencies = (state = {
  superAgencies: {},
  isFetchingSuperAgencies: false
}, action) => {
  switch (action.type) {
    case SUPER_AGENCIES_REQUEST:
      return {
        ...state,
        isFetchingSuperAgencies: true,
      }
    case SUPER_AGENCIES_RECEIVED_REQUEST:
      return {
        ...state,
        superAgencies: action.data,
        isFetchingSuperAgencies: false,
      }
    case SUPER_AGENCIES_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingSuperAgencies: false,
        error: true,
      }
    default: return state;
  }
}
