import React from "react";
import CmsContent from "../../Components/CmsContent";
import TrinityNews from "../trinity-news/TrinityNews";
import {CATEGORY_GROUP_IDS} from "../../modules/cms";

export default class CorporateNews extends React.Component {
  render() {
    return (
      <div className="container">
        <CmsContent title="Venue News" dataKey="news" perPage={10} showSearch={false} />
      </div>
    );
  }
}
