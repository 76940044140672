import convertPriceToSourceCurrencyValue from "helpers/convertPriceToSourceCurrencyValue";

export default function mapAdditionalItemsFromProposalData(additional_items, isSourceCurrency = false) {
  additional_items.map(value => {
    value.price_net_gbp = value.price_net;
    value.price_vat_gbp = value.price_vat;
    value.price_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_vat");
    value.price_net = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_net");
    value.price_per_person_net = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_per_person_net");
    value.price_per_person_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_per_person_vat");
    value.price_reduced_from_net = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_reduced_from_net");
    value.price_reduced_from_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, value, "price_reduced_from_vat");

  });
  return [
    ...additional_items,
  ];
};

