import React from "react";
import { connect } from "react-redux";
import { Circle, GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { change, getFormValues } from "redux-form";
import prettyRadius from "modules/pretty-radius";
import MarkerWithVenuePopup from "../../Components/Map/MarkerWithVenuePopup";
import {
  fetchPriceBandingData,
  fetchVenuePriceBandingData,
} from "../../services/priceBandingService/fetchPriceBandings";

const style = {
  strokeColor: "rgb(0, 153, 255)",
  strokeOpacity: 0.4,
  strokeWeight: 2,
  fillColor: "rgb(0, 153, 255)",
  fillOpacity: 0.2,
  zIndex: 1,
};

class Map extends React.Component {
  state = {
    markers: [],
    selectedId: undefined,
    updateOnMove: false,
    isSelectedToShortlist: true,
    venuePriceBandings: null,
    priceBandings: null,
  };

  constructor(props) {
    super(props);
    this.setRef = {
      map: e => (this.map = e),
      circle: e => (this.circle = e),
      searchBox: e => (this.searchBox = e),
    };
  }

  zoom = () => {
    if (!this.props.lat && !this.props.lng) {
      return 6;
    }
    switch (prettyRadius(this.props.radius)) {
      case 0.25:
        return 15;
      case 0.5:
        return 14;
      case 1:
      case 2:
        return 13;
      case 3:
      case 4:
        return 12;
      case 5:
      case 6:
      case 7:
        return 11;
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        return 10;
      case 17:
      case 18:
      case 19:
      case 20:
        return 9;
      default:
        return 13;
    }
  };

  showData = venue => {
    const isSelected = this.venueOnList(venue.id);

    fetchPriceBandingData(this.props.token).then((response) => {
      this.setState({
        priceBandings: response,
      });
    });
    fetchVenuePriceBandingData(this.props.token, venue.slug).then((response) => {
      if (Object.keys(response).length !== 0) {
        this.setState({
          venuePriceBandings: response,
        });
      } else {
        this.setState({
          venuePriceBandings: null,
        });
      }
    });

    this.setState({ selectedId: venue.id, isSelectedToShortlist: isSelected });
  };

  closeWindow = () => {
    this.setState({ selectedId: undefined });
  };

  shortlistedVenues = () =>
    this.props.activeShortlist &&
    Array.isArray(this.props.activeShortlist.venues)
      ? this.props.activeShortlist.venues
      : [];

  venueOnList = id => !!this.shortlistedVenues().find(venue => venue.id === id);

  shortListForm = () => `${this.props.type}-search-shortlist`;

  toggleShortlist = (toggleVenue, isAdding) => {
    const venues = this.shortlistedVenues();
    const newVenuesShortList = isAdding
      ? [...venues, toggleVenue]
      : venues.filter(venue => venue.id !== toggleVenue.id);

    this.props.change(this.shortListForm(), "venues", newVenuesShortList);
    this.setState({ isSelectedToShortlist: !this.state.isSelectedToShortlist });
  };

  hasPrice = (result) => {
    return (result.ddr_price_ex_vat > 0 ||
      result["24hr_dr_price_ex_vat"] > 0 ||
      result.buy_it_now_price_exc_vat > 0
    );
  };

  render() {
    return (
      <GoogleMap
        ref={this.setRef.map}
        zoom={this.zoom()}
        center={
          new window.google.maps.LatLng(
            parseFloat(this.props.lat) || 52.6445885,
            parseFloat(this.props.lng) || -0.5006198,
          )
        }
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        {this.props.lat && this.props.lng ? (
          <Marker
            position={{
              lat: parseFloat(this.props.lat),
              lng: parseFloat(this.props.lng),
            }}
          />
        ) : null}
        {this.props.results.slice(0, 100).map(result => {
          return (
            <MarkerWithVenuePopup
              key={result.id}
              result={result}
              selectedId={this.state.selectedId}
              hasPrice={this.hasPrice(result)}
              onClick={() => this.showData(result)}
              onCloseClick={() => this.closeWindow()}
              checked={this.state.isSelectedToShortlist}
              toggleShortlist={() => {
                this.toggleShortlist(result, !this.state.isSelectedToShortlist);
              }}
              priceBandings={this.state.priceBandings}
              venuePriceBandings={this.state.venuePriceBandings}
            />
          );
        })}
        {this.props.lat && this.props.lng ? (
          <Circle
            center={
              new window.google.maps.LatLng(
                parseFloat(this.props.lat) || 52.6445885,
                parseFloat(this.props.lng) || -0.5006198,
              )
            }
            radius={parseFloat(this.props.radius)}
            defaultEditable={false}
            ref={this.setRef.circle}
            options={style}
          />
        ) : null}
      </GoogleMap>
    );
  }
}

const mapStateToProps = (state, props) => {
  const v = getFormValues("location-search")(state) || {};
  return {
    activeShortlist: getFormValues(`${props.type}-search-shortlist`)(
      state,
    ),
    radius: v.radius,
    lat: v.lat,
    lng: v.lng,
  };
};

export default withGoogleMap(
  connect(
    mapStateToProps,
    { change },
  )(Map),
);
