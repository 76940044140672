import React from "react";
import {connect} from "react-redux";
import {token} from "modules/auth";
import Loading from "../../../../Components/Loading";
import AdminList from "../../../../Components/AdminList";
import {Link} from "react-router-dom";
import * as ROUTES from "../../../../Router/corporate_routes";
import AdminListActionButton from "../../../../Components/AdminListActionButton";
import {isCompanyAdmin, isRoleEdgeAdmin} from "../../../../modules/permissions";

const CorporateAccountLocationList = ({isFetching, locations, accountId}) => {
  if (isFetching) {
    return (
      <div>
        <Loading/>
      </div>
    )
  }
  if (Object.entries(locations).length === 0 && locations.constructor === Object) {
    return (
      <div>No corporate account locations available</div>
    )
  }
  const createAction = () => {
    if (isRoleEdgeAdmin || isCompanyAdmin) {
      return (
        <AdminListActionButton
          to={ROUTES.FRONTEND.CORPORATE.LOCATIONS.CREATE.replace(':slug', accountId)}
          text={`Add New`}
        />
      )
    }
    return null;
  }

  const headings = () => {
    return (
      <tr>
        <th>Actions</th>
        <th>Location Name</th>
      </tr>
    )
  }

  const corporateList = () => {
    return locations.data.sort((a, b) => a.name.localeCompare(b.name)).map((location) => {
      return (
        <tr key={location.id}>
          <td className="px-6 py-3 whitespace-nowrap">
            <div className="flex items-center">
              <div className="text-sm font-medium text-gray-900">
                <Link
                  to={ROUTES.FRONTEND.CORPORATE.LOCATIONS.EDIT.replace(':slug', location.corporate_account_id).replace(':id', location.id)}
                  className={'text-black'}>
                  <i className="fal fa-edit px-3"/>
                </Link>
              </div>
            </div>
          </td>

          <td>
            <div className="flex items-center">
              <Link
                to={ROUTES.FRONTEND.CORPORATE.LOCATIONS.EDIT.replace(':slug', location.corporate_account_id).replace(':id', location.id)}
                className={'text-black'}>
                {location.name}
              </Link>
            </div>
          </td>
        </tr>
      )
    });
  }

  return (
    <AdminList
      title={'Corporate Locations'}
      actions={
        <React.Fragment>
          {createAction()}
        </React.Fragment>
      }
      tableHeadings={headings()}
      tableList={corporateList()}
    />
  )

}

export default connect(state => ({
  token: token(state),
}))(CorporateAccountLocationList);
