// import { createDateRange } from "../../../pages/admin/Analytics/date-utils";
import { venueData } from "./utils";

export default (id, start, end) => state => {
  const data = venueData(id)(state);
  if (!data) {
    return [];
  }

  // const dateRange = createDateRange(start, end);

  return data.data.other_venues_viewed.slice(0, 10);
  // return dateRangeFilter(data.data.other_venues_viewed, dateRange, "day");
};
