function getHiddenRows(items, removeRowKey, state, selector)
{
    let hiddenRows = [];
    let removed = []
    items.forEach((item) => {
        let removeRow = selector(state, removeRowKey(item.id));
        if(removeRow) {
            hiddenRows[`${removeRowKey(item.id)}`] = removeRow;
        }
    });

    return removed['remove'] = hiddenRows;
}

function getFormValues(items, delegatesKey, state, selector)
{
    let values = [];
    let delegates = [];

    items.forEach((item) => {

            values[`${delegatesKey(item.id)}`] = parseInt(selector(state, delegatesKey(item.id)));

    });

    return delegates['delegates'] = values;
}

export  {getHiddenRows, getFormValues}