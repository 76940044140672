import {  calculateCommissionFromUnit, gatherTotals, getVatRateById } from '../Helpers/calculateTotals';
import { Field,  formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import {  getHiddenRows } from '../Helpers/getFormState';
import { isAgent, isVenueAdmin } from '../../../../../modules/auth';
import { connect } from 'react-redux';
import { flagChanges } from '../Helpers/formHelper';
import React, { useEffect, useState } from 'react';
import ChecksheetBreakdown from './ChecksheetBreakdown';
import { ENQUIRY_VENUE_DATA_STATUS } from '../../../../../modules/rfp/enquiryVenueDataStatus';

function getAdditionalCostsRemoveKey(id) {
    return `remove[additional_costings_${id}]`;
}

let AdditionalCostingsBreakdown = (
    {
        currentVatRateForCommission,
        vatRates,
        additionalCostings,
        commissionItems,
        currentVat,
        status,
        renderCheckBox,
        setAdditionalCostingsTotal,
        setAdditionalCostingsCommissionTotal,
        setAdditionalCostingsCommissionTotalGBP,
        currencyFormatter,
        showCommission,
        setAdditionalCostingsValueChanged,
        initialValues,
        hiddenRows,
        currency = {currency_code: "GBP"}
    }) => {
    let totals = {};
    let commissionTotals = {};
    let commissionTotalsGBP = {};

    const [grandTotal, setGrandTotal] = useState(0);
    const [, setGrandCommissionTotal] = useState(0);
    const [grandTotalGBP, setGrandTotalGBP] = useState(0);
    const [, setGrandCommissionTotalGBP] = useState(0);

    useEffect(() => {
        gatherTotals(totals, setGrandTotal, setAdditionalCostingsTotal);
        gatherTotals(commissionTotals, setGrandCommissionTotal, setAdditionalCostingsCommissionTotal);
        gatherTotals(commissionTotalsGBP, setGrandCommissionTotalGBP, setAdditionalCostingsCommissionTotalGBP);
    }, [totals]);

    const onChange = (event, newValue, previousValue, name) => {
        flagChanges(initialValues, null, hiddenRows, newValue, name, setAdditionalCostingsValueChanged);

    };
    if (!additionalCostings) {
        return null;
    }

    return (
        <ChecksheetBreakdown
            currency={currency}
            headers={(
                <React.Fragment>
                    <th className={'p-2 pl-0 text-left uppercase text-xs min-14 w-auto'}>Description</th>
                    <th className={'p-2 pl-0 text-left uppercase text-xs min-2 w-auto'}>Quantity</th>
                    <th className={'p-2 pl-0 text-left uppercase text-xs min-4 w-auto'}>Unit Price</th>
                    <th/>
                </React.Fragment>
            )}
            spaces={4}
            totalAmount={currencyFormatter.format(grandTotal)}
            showCommission={showCommission}
        >
            {additionalCostings.map(({
                                         id,
                                         quantity,
                                         unit_price,
                                         type_name,
                                         is_commissionable,
                                         is_in_proposal_costs,
                                         unit_price_reduced_from,
                                         price_net,
                                         price_net_src_cur,
                                         display_price_including_vat,
                                         price_vat_rate_id,
                                         price_vat,
                                         price_vat_src_cur,
                                         price_reduced_from_net,
                                         price_reduced_from_net_src_cur,
                                         price_reduced_from_vat,
                                         price_reduced_from_vat_src_cur,
                                         price_percentage_saving,
                                         created_at,
                                         updated_at,
                                         proposal_data_id,
                                         additional_item_type_id,
                                        price_net_gbp,
                                        price_vat_gbp,
                            }) => {
                    let vatRate = getVatRateById(vatRates,price_vat_rate_id).number;
                    let finalTotal = Number(price_net) + Number(price_vat);
                    let vatTotal = price_vat;
                    if (typeof hiddenRows[getAdditionalCostsRemoveKey(id)] === 'undefined') {
                        totals[`equip_${id}`] = parseFloat(finalTotal);
                    }
                    let commission = commissionItems ? commissionItems.find(item => parseInt(item.step_id) === 10) : 0;

                    let commissionRate = commission ? commission.rate : 0;
                    let commissionAppliedOn = commission ? commission.applied_on : 0;
                    let commissionIncludeVat = commission ? commission.include_vat : 0;
                    let commissionTotal = 0;
                    let commissionTotalGBP = 0;
                    if (typeof hiddenRows[getAdditionalCostsRemoveKey(id)] === 'undefined' && is_commissionable) {
                        commissionTotal = commission ? calculateCommissionFromUnit(commissionIncludeVat, Number(price_net), Number(price_vat), currentVatRateForCommission, 1, commissionRate, commissionAppliedOn, `equip_${id}`, commissionTotals) : 0;
                        commissionTotalGBP = commission ? calculateCommissionFromUnit(commissionIncludeVat, Number(price_net_gbp), Number(price_net_gbp), currentVatRateForCommission, 1, commissionRate, commissionAppliedOn, `equip_${id}`, commissionTotalsGBP) : 0;
                    }
                    return (
                        <tr
                            key={`equipment_${id}`}
                            className={hiddenRows[`${getAdditionalCostsRemoveKey(id)}`] !== 'undefined' && hiddenRows[`${getAdditionalCostsRemoveKey(id)}`] === true ? 'row-fadeout' : ''}>
                            <td>{type_name}</td>
                            <td>{quantity}</td>
                            <td>{unit_price ? currencyFormatter.format(unit_price) :  currencyFormatter.format(price_net)}</td>
                            <td/>
                            <td className={'p-2 text-right'}>{price_net ? currencyFormatter.format(price_net) : 'N/A'}</td>
                            <td className={'p-2 text-right'}>{`${vatRate}%`}</td>
                            <td className={'p-2 text-right'}>{!price_net ? 'N/A' : currencyFormatter.format(vatTotal)}</td>
                            {showCommission ?
                                <td className={'p-2 text-right'}>
                                    {`${commissionRate}%`}
                                </td>
                                :
                                null
                            }
                            {showCommission ?
                                <td className={'p-2 text-right'}>
                                    {currencyFormatter.format(commissionTotal)}
                                </td>
                                :
                                null
                            }
                            <td className={'p-2 text-right'}>{!price_net ? 'N/A' : currencyFormatter.format(finalTotal)}</td>

                            <td className={'p-2 text-left w-8'}>
                                {price_net && (Number(status) === Number(ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS) || status > ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS) ?
                                    null : <Field
                                        name={getAdditionalCostsRemoveKey(id)}
                                        component={renderCheckBox}
                                        onChange={onChange}
                                    />}
                            </td>
                        </tr>

                    );
                },
            )}
        </ChecksheetBreakdown>
    );
};

const selector = formValueSelector('confirmBooking');

export default withRouter(
    connect((state, props) => ({
            hiddenRows: getHiddenRows(props.additionalCostings, getAdditionalCostsRemoveKey, state, selector),
            isVenue: isVenueAdmin(state),
            isAgency: isAgent(state),
        }),
    )(AdditionalCostingsBreakdown),
);

