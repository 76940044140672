import React, {useEffect} from "react";
import {fetchUserInvite, registerInvitedUser, userInviteUnload} from "../../redux/actions/corporateInvites";
import {connect} from 'react-redux'
import Loading from "../../Components/Loading";
import UserInvitedForm from "../../Components/corporate/UserInvitedForm";
import {isLoggedIn, token} from "../../modules/auth";
import NotFound from "../NotFound";
import {Redirect, useHistory} from "react-router-dom";

const mapStateToProps = (state) => {
  return ({
    ...state.userInvite,
    ...state.isFetchingUserInvite,
    isLoggedIn: isLoggedIn(state),
    token: token(state)
  })
}

const mapDispatchToProps = {
  fetchUserInvite,
  userInviteUnload,
  registerInvitedUser
}

const AgencyLandingPage = ({
                             match,
                             fetchUserInvite,
                             isFetchingUserInvite,
                             userInvite,
                             userInviteUnload,
                             isLoggedIn,
                             registerInvitedUser,
                             token
                           }) => {
  const history = useHistory();
  useEffect(() => {
    if (isLoggedIn) {
      return <NotFound>
        Sorry, the page your are looking for has not been found.
        Please check the URL and try again.
      </NotFound>
    }
    fetchUserInvite(match.params.invite_code)
    return () => {
      userInviteUnload()
    }
  }, []);
  if (isFetchingUserInvite) {
    return <Loading/>
  }
  if (userInvite === null && !isFetchingUserInvite) {
    return (
      <NotFound>
        Sorry, the page your are looking for has not been found.
        Please check the URL and try again.
      </NotFound>
    )
  }

  const onSubmit = formValues => {
    return registerInvitedUser(userInvite.invite_code, userInvite.is_existing_user, token, formValues).then(() => {
      history.push({
        pathname: '/login',
        state: {
          message: "You have successfully registered. Please login."
        }
      });
    })
  }
  if (userInvite !== null) {
    return (
      <div className="flex justify-center md:py-16">
        <div className="bg-white max-w-full" style={{width: 900}}>
          <div className="branding text-center">
            <h2 className="mt-0 mb-8 md:mb-8 text-2rem">You are invited to create an account
              with {userInvite && userInvite.agency.name}</h2>
            {/*<img src={window.API + "/brands/images/" + userInvite.agency.id} alt={userInvite.agency.name}/>*/}
          </div>
          <UserInvitedForm
            userInvite={userInvite}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    )
  }
  return (
    <div></div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AgencyLandingPage)
