//Named Locations Types
export const FETCH_NAMED_LOCATION_TYPE_PENDING = 'FETCH_NAMED_LOCATION_TYPE_PENDING';
export const FETCH_NAMED_LOCATION_TYPE_SUCCESS = 'FETCH_NAMED_LOCATION_TYPE_SUCCESS';
export const FETCH_NAMED_LOCATION_TYPE_ERROR = 'FETCH_NAMED_LOCATION_TYPE_ERROR';
//Named Sub Groups
export const FETCH_NAMED_LOCATION_SUB_GROUPS_PENDING = 'FETCH_NAMED_LOCATION_SUB_GROUPS_PENDING';
export const FETCH_NAMED_LOCATION_SUB_GROUPS_SUCCESS = 'FETCH_NAMED_LOCATION_SUB_GROUPS_SUCCESS';
export const FETCH_NAMED_LOCATION_SUB_GROUPS_ERROR = 'FETCH_NAMED_LOCATION_SUB_GROUPS_ERROR';

//Named Locations
export const FETCH_NAMED_LOCATIONS_PENDING = 'FETCH_NAMED_LOCATIONS_PENDING';
export const FETCH_NAMED_LOCATIONS_SUCCESS = 'FETCH_NAMED_LOCATIONS_SUCCESS';
export const FETCH_NAMED_LOCATIONS_ERROR = 'FETCH_NAMED_LOCATIONS_ERROR';

export const RESET_NAMED_LOCATIONS = 'RESET_NAMED_LOCATIONS';

export function resetNamedLocations() {
    return {
        type: RESET_NAMED_LOCATIONS
    }
}

//Named Locations
export function fetchNamedLocationTypesPending() {
    return {
        type: FETCH_NAMED_LOCATION_TYPE_PENDING
    }
}

export function fetchNamedLocationTypesSuccess(res) {
    return {
        type: FETCH_NAMED_LOCATION_TYPE_SUCCESS,
        payload: res
    }
}

export function fetchNamedLocationTypesError(error) {
    return {
        type: FETCH_NAMED_LOCATION_TYPE_ERROR,
        error: error
    }
}

//Named Sub Groups
export function fetchNamedLocationSubGroupsPending() {
    return {
        type: FETCH_NAMED_LOCATION_SUB_GROUPS_PENDING
    }
}

export function fetchNamedLocationSubGroupsSuccess(res) {
    return {
        type: FETCH_NAMED_LOCATION_SUB_GROUPS_SUCCESS,
        payload: res
    }
}

export function fetchNamedLocationSubGroupsError(error) {
    return {
        type: FETCH_NAMED_LOCATION_SUB_GROUPS_ERROR,
        error: error
    }
}

//Named Locations
export function fetchNamedLocationsPending() {
    return {
        type: FETCH_NAMED_LOCATIONS_PENDING
    }
}

export function fetchNamedLocationsSuccess(res) {
    return {
        type: FETCH_NAMED_LOCATIONS_SUCCESS,
        payload: res
    }
}

export function fetchNamedLocationsError(error) {
    return {
        type: FETCH_NAMED_LOCATIONS_ERROR,
        error: error
    }
}



const initialState = {
    pending: false,
    namedLocationsType: [],
    namedLocationsSubGroups: [],
    namedLocations: [],
    error: null
}

export function reducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_NAMED_LOCATION_TYPE_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_NAMED_LOCATION_TYPE_SUCCESS:
            return {
                ...state,
                pending: false,
                namedLocationsType: action.payload
            }
        case FETCH_NAMED_LOCATION_TYPE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FETCH_NAMED_LOCATION_SUB_GROUPS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_NAMED_LOCATION_SUB_GROUPS_SUCCESS:
            return {
                ...state,
                pending: false,
                namedLocationsSubGroups: action.payload
            }
        case FETCH_NAMED_LOCATION_SUB_GROUPS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case FETCH_NAMED_LOCATIONS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_NAMED_LOCATIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                namedLocations: action.payload
            }
        case FETCH_NAMED_LOCATIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case RESET_NAMED_LOCATIONS:
            return {
                ...state,
                pending: false,
                error: false,
                namedLocations: [],
                namedLocationsSubGroups: [],
                namedLocationsType: []
            }
        default:
            return state;
    }
}

export const getNamedLocationTypes = state => state.namedLocations.namedLocationsType ;
export const getNamedLocationTypesPending = state => state.namedLocations.pending;
export const getNamedLocationTypesError = state => state.namedLocations.error;

export const getNamedLocationSubGroups = state => state.namedLocations.namedLocationsSubGroups ;
export const getNamedLocationSubGroupsPending = state => state.namedLocations.pending;
export const getNamedLocationSubGroupsError = state => state.namedLocations.error;

export const getNamedLocations = state => state.namedLocations.namedLocations;
export const getNamedLocationsPending = state => state.namedLocations.pending;
export const getNamedLocationsError = state => state.namedLocations.error;