import {
    COMMISSION_VALUE,
    PER_PERSON,
    PRICE_NET,
    PRICE_TOTAL,
    PRICE_VAT,
    QUANTITY
} from "../components/FinalCommissionValuesModal";

function buildInitialCustomItems(event, initialCommissionItems)
{
    if(event.event_commission_items)
    {
        event.event_commission_items.map((commissionItem) => {
            if(commissionItem.type === 'custom')
            {
                initialCommissionItems.push({
                    id: commissionItem.type_identifier,
                    type: commissionItem.type,
                    disabled: [],
                    readonly: [PRICE_VAT, COMMISSION_VALUE, PRICE_TOTAL]
                })

            }
        })
    }
}

function handleCustomFieldUpdate(
    enquiryVenueData,
    editedItem,
    updateFieldBasedOnKey,
    fieldEvent,
    value,
    id,
)
{
    if(editedItem.includes('custom'))
    {
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'custom_' + id,
            id,
            value,
        )
    }
}

export  {
    buildInitialCustomItems,
    handleCustomFieldUpdate
}
