import React, {Component} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../../styles/components/events-calendar.css';
import EventWrapper from "./EventWrapper";
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import NewEventModal from "./NewEventModal";
import {countriesOptions} from "../../../../modules/countries-options";
import EditEventModal from "./EditEventModal";
import {CalendarToolbar} from "./CalendarToolbar";
import {isInteger} from "lodash";
import ViewBookedEvent from "../../../../Components/corporate/ViewBookedEvent";
import ViewEventModal from "../../../../Components/corporate/ViewEventModal";
import {
  isAgencyUser,
  isCompanyAdmin,
  isEventSpecialist, isHeadOfEvents,
  isHeadOfProcurement,
  isRoleEdgeAdmin,
} from "../../../../modules/permissions";
import {connect} from 'react-redux';
import {statusKeyDiv} from "./mapEvents";

class EventsCalendar extends Component {
  constructor(props) {
    super(props);
    this.newEvent = false;
    this.editEvent = false;
    this.showBookedEventModal = false;
    this.viewEvent = false;
    this.state = {
      newEvent: props.newEvent,
      editEvent: props.editEvent,
      activeFilter: props.isRoleEdgeAdmin ? "all" : "ownEvents"
    }
  }

  handleNewEventClose = () => {
    this.setState({newEvent: false})
  }

  handleEditEventClose = () => {
    this.setState({editEvent: false})
  }

  handleBookedEventClose = () => {
    this.setState({showBookedEventModal: false})
  }

  handleViewEventClose = () => {
    this.setState({viewEvent: false})
  }

  handleSelectSlot = ({start, end}) => {
    let event = {
      'start': start,
      'end': end
    };
    this.setState({newEvent: true, event})
  }

  handleSelectEvent = (event) => {
    if (event.bookedEvent) {
      this.setState({showBookedEventModal: true, event})
    } else if (event.type === 'public' || (event.type === 'industry' && !this.props.isCompanyAdmin)) {
      this.setState({viewEvent: true, event})
    } else {
      this.setState({editEvent: true, event})
    }
  }

  render() {
    const {
      calendarEvents,
      countries,
      onNewEvent,
      onEditEvent,
      handleDeleteEvent,
      fetchPublicEvents,
      fetchIndustryEvents,
      fetchCorporateEvents,
      fetchOwnEvents,
      company_id,
      isRoleEdgeAdmin,
      showKey,
      isAgencyUser,
      isCompanyAdmin,
      isHeadOfProcurement,
      isEventSpecialist,
      isHeadOfEvents,
    } = this.props;
    const localizer = momentLocalizer(moment);

    const filterPublicEvents = () => {
      if (fetchPublicEvents) {
        fetchPublicEvents(this.props.token);
      }
    }

    const filterIndustryEvents = () => {
      if (fetchIndustryEvents) {
        fetchIndustryEvents(this.props.token, company_id)
      }
    }

    // Couldn't think of a better name, this fetches all events for a specific corporate
    const filterCorporateEvents = () => {
      if (fetchCorporateEvents) {
        fetchCorporateEvents(this.props.token)
      }
    }

    // Same as above, this fetches all company events which are being handled by the current user.
    const filterOwnEvents = () => {
      if (fetchOwnEvents) {
        fetchOwnEvents(this.props.token);
      }
    }

    const showAllEvents = () => {
      filterIndustryEvents()
      filterPublicEvents()
      filterCorporateEvents()
    }

    const eventFiltersHandler = filterName => {
      this.setState({activeFilter: filterName})
      if (filterName === "all") {
        showAllEvents()
      } else if (filterName === "ownEvents") {
        filterOwnEvents()
      } else if (filterName === "companyEvents") {
        filterCorporateEvents()
      } else if (filterName === "industryEvents") {
        filterIndustryEvents()
      } else if (filterName === "publicEvents") {
        filterPublicEvents()
      }
    }

    return (
      <div className="min-w-full">
        <h1 className="my-4">
          Manage Events Calendar
        </h1>
        {isAgencyUser || (isCompanyAdmin && (isHeadOfProcurement || isEventSpecialist || isHeadOfEvents)) ? (
          <p>Click to add a new Industry event, visible to all your company users</p>
        ) : null}
        <div className={'bg-white p-5'}>
          {statusKeyDiv(showKey)}
          <div className="row">
            <div className="col">
              <Calendar
                style={{
                  height: 750,
                }}
                views={['month']}
                popup
                selectable
                onSelectEvent={event => this.handleSelectEvent(event)}
                onSelectSlot={this.handleSelectSlot}
                events={(calendarEvents.length > 0) ? calendarEvents : []}
                step={60}
                showMultiDayTimes
                defaultDate={new Date()}
                localizer={localizer}
                components={{
                  event: EventWrapper,
                  toolbar: props => (
                    <CalendarToolbar
                      {...props}
                      showAllEvents={showAllEvents}
                      filterPublicEvents={filterPublicEvents}
                      filterIndustryEvents={filterIndustryEvents}
                      filterOwnEvents={filterOwnEvents}
                      filterCorporateEvents={filterCorporateEvents}
                      isEdgeAdmin={isRoleEdgeAdmin}
                      eventFiltersHandler={eventFiltersHandler}
                      activeFilter={this.state.activeFilter}
                    />
                  ),
                }}
              />
              {(this.state.newEvent) && (
                <NewEventModal
                  onSubmit={onNewEvent}
                  onClose={this.handleNewEventClose}
                  event={this.state.event}
                  countries={countriesOptions(countries)}
                  type={isInteger(company_id) ? 'industry' : 'public'}
                  companyId={company_id}
                />
              )}
              {(this.state.showBookedEventModal) && (
                <ViewBookedEvent
                  event={this.state.event}
                  onClose={this.handleBookedEventClose}
                />
              )}
              {this.state.viewEvent && (
                <>
                  <ViewEventModal
                    onClose={this.handleViewEventClose}
                    event={this.state.event}
                    countries={countriesOptions(countries)}
                    deleteEvent={handleDeleteEvent}
                  />
                </>
              )}
              {this.state.editEvent && (
                <>
                  <EditEventModal
                    onSubmit={onEditEvent}
                    onClose={this.handleEditEventClose}
                    onDelete={handleDeleteEvent}
                    event={this.state.event}
                    countries={countriesOptions(countries)}
                    setEvent={this.props.setEvent}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
    isAgencyUser: isAgencyUser(state.auth),
    isCompanyAdmin: isCompanyAdmin(state.auth),
    isHeadOfProcurement: isHeadOfProcurement(state.auth),
    isEventSpecialist: isEventSpecialist(state.auth),
    isHeadOfEvents: isHeadOfEvents(state.auth)
  };
};

export default connect(mapStateToProps, null)(EventsCalendar)