export const countriesOptions = (countries) => {
  const options = [];
  if (countries && Object.entries(countries).length > 0) {
    countries.sort((a, b) => a.name.localeCompare(b.name))
    countries.map((country) => {
      options.push({
        text: country.name,
        value: country.id
      })
    })
    options.forEach(function (country, i) {
      if (country.text === "United Kingdom") {
        options.splice(i, 1);
        options.unshift(country);
      }
    });
  }
  return options;
}