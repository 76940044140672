import { currencyFormatter } from "../../utils";

export default (reduce_from, total, currency = {currency_code: "GBP"}) => {
    let savingPrice = "";
        savingPrice = (
            parseFloat(reduce_from) - parseFloat(total)
        ).toFixed(2);
    return currencyFormatter(currency.currency_code).format(savingPrice);
};


