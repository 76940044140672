import React from "react";
import LazyLoad from 'react-lazyload';

const EdgeInfoColumn = ({ img, title, content }) => {
  return (
    <div className="lg:px-8 xl:px-16 xxl:px-20 mb-8 md:mb-8 lg:mb-0 col-w-full md:col-w-1/2 lg:col-w-1/4">
      <div className="font-normal">
          <LazyLoad height={78} once offset={300}>
             <img src={img} alt={title} style={{ width: 78 }} width={78} height={81} />
          </LazyLoad>
        <h3 className="font-bold text-2xl mt-8 mb-5">{title}</h3>
        {content}
      </div>
    </div>
  );
};

export default EdgeInfoColumn;
