import axios from "axios";

const replySiteVisit = async (
  { venue_id, enquiry_id, date_alternative_suggested, visit_agreed, details },
  token
) => {
  try {
    await axios.post(
      `${window.API}/rfp/venue-respond-to-site-visit`,
      {
        venue_id,
        enquiry_id,
        visit_agreed,
        date_alternative_suggested,
        details,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return { result: true };
  } catch (error) {
    return { error };
  }
};

export { replySiteVisit };
