import React, { Component, useState, useEffect } from "react";
import { Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  processFormValuesPost,
  renderFormInput,
} from "../../modules/formTools";

import SmartSelect from "../Form/SmartSelect";
import { ButtonPrimary, ButtonPrimaryRed } from "../Button";
import Loading from "../Loading";
import moment from "moment";
import { Toggle } from "../../ui";
import { Checkbox, Input } from "../Form";
import {email, maxLength, optional, required} from "../../modules/validation";
import DropzoneArea from "../Form/File/DropzoneArea";
import _, { isArray } from "lodash";
import { uploadCorporateAccountLogoImage } from "../../redux/actions";
import NumberField from "../../pages/admin/rfp/Confirmation/Components/NumberField";

const v = {
  required: required(),
  email: [optional(email)]
};

const colourSchemeOptions = [
  {
    label: "Purple",
    value: "corporate-admin--purple",
  },
  {
    label: "Red",
    value: "corporate-admin--red",
  },
  {
    label: "Green",
    value: "corporate-admin--green",
  },
];

const mapStateToProps = (state, props) => {
  let reviewItems = [];
  let placeHolderDate = null;
  let bookingDeskIvOptions = [];
  let optionalPermissionsIvOptions = [];
  if (typeof props.corporateAccount !== "undefined") {
    if (props.corporateAccount.financial_year_start_month) {
      placeHolderDate = moment().startOf("year").set("month", props.corporateAccount.financial_year_start_month - 1);
    } else {
      placeHolderDate = moment().startOf("year");
    }
    if (props.corporateAccount.requires_abpi) {
      reviewItems.push("requires_abpi");
    }
    if (props.corporateAccount.internal_external_split) {
      reviewItems.push("internal_external_split");
    }
    if (typeof props.corporateAccount.booking_desks !== "undefined") {
      props.corporateAccount.booking_desks.map(desk => {
        bookingDeskIvOptions.push({
          label: desk.name,
          value: desk.booking_desk_id,
        });
      });
    }
    if (typeof props.corporateAccount.optional_permissions !== "undefined") {
      props.corporateAccount.optional_permissions.map(permission => {
        optionalPermissionsIvOptions.push({
          label: permission.display_name,
          value: permission.permission
        })
      })
    }
  }

  return {
    token: state.auth.token,
    formValues: getFormValues("corporateAccountForm")(state) || {},
    initialValues: props.corporateAccount ? {
      company: props.corporateAccount && props.corporateAccount.company ? {
        label: props.corporateAccount.company.name,
        value: props.corporateAccount.company.id,
      } : null,
      account_name: props.corporateAccount && props.corporateAccount.account_name,
      authorising_email: props.corporateAccount && props.corporateAccount.authorising_email,
      booking_desks: bookingDeskIvOptions,
      corporate_account_permissions: optionalPermissionsIvOptions,
      max_delegates_triggers_authorisation_workflow: props.corporateAccount && props.corporateAccount.max_delegates_triggers_authorisation_workflow,
      show_internal_external_on_brief: props.corporateAccount && props.corporateAccount.show_internal_external_on_brief,
      is_pharmaceutical: props.corporateAccount && props.corporateAccount.is_pharmaceutical,
      export_net: props.corporateAccount && props.corporateAccount.export_net,
      email_notification_override: props.corporateAccount && props.corporateAccount.email_notification_override,
      client_facing_email_notification_override: props.corporateAccount && props.corporateAccount.client_facing_email_notification_override,
      venue_facing_email_notification_override: props.corporateAccount && props.corporateAccount.venue_facing_email_notification_override,
      review_items: reviewItems,
      financial_year_start_month: placeHolderDate ? {
        label: placeHolderDate.format("MMMM"),
        value: placeHolderDate.format("M"),
      } : null,
      colour_scheme_classname: (props?.corporateAccount && props?.corporateAccount?.colour_scheme_classname ? colourSchemeOptions.filter(opt => opt.value === props.corporateAccount.colour_scheme_classname) : colourSchemeOptions.filter(opt => opt.value === "")),
    } : null,
  };
};

let getFinancialMonths = () => {
  let selectValues = [];

  for (let month = 0; month < 12; month++) {
    let placeHolderDate = moment().startOf("year").set("month", month);
    selectValues.push({
      name: placeHolderDate.format("MMMM"),
      id: placeHolderDate.format("M"),
    });
  }

  return selectValues;
};

let CorporateAccountForm = ({
                              handleSubmit,
                              corporateAccount,
                              corporateAccountUsers,
                              companies,
                              submitting,
                              buttonText,
                              formValues,
                              token,
                              slug = false,
                              uploadCorporateAccountLogoImage,
                              corporatePermissions,
                              ...props
                            }) => {
  const [logoFile, setLogoFile] = useState(null);
  const [printLogoFile, setPrintLogoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadingPrintLogo, setUploadingPrintLogo] = useState(false);

  const handleRemoveBookingDesks = (e) => {
    if ((e && props.initialValues.booking_desks) && (e.length < props.initialValues.booking_desks.length)) {
      props.change("removeBookingDesks", _.differenceWith(props.initialValues.booking_desks, e, _.isEqual));
    }
    if (e === null) {
      props.change("removeBookingDesks", props.initialValues.booking_desks);
    }
  };

  let submitForm = formValues => {
    let formValuesForPost = {};
    let formatFormValues = (key, valueKey, isObject = false, objectKey = "") => {
      if (typeof formValues[key] !== "undefined") {
        let value = isObject && formValues[valueKey] ? formValues[valueKey][objectKey] : formValues[valueKey];
        if (typeof value !== 'undefined' && value !== null) {
          formValuesForPost[key] = isObject && formValues[valueKey] ? formValues[valueKey][objectKey] : formValues[valueKey];
        }
      }
    };
    let formatFormValuesArray = (key, valueKey, includesKey) => {
      if (typeof formValues[key] !== "undefined") {
        formValuesForPost[valueKey] = formValues[key].includes(includesKey) ? 1 : 0;
      }
    };
    let formatFormValuesFromArray = (key, valueKey) => {
      if (typeof formValues[key] !== "undefined") {
        formValuesForPost[valueKey] = typeof formValues[key] === "string" ? {} : formValues[key].map(arr => {
          return {
            value: arr.value,
          };
        });
      }
    };
    formatFormValues("account_name", "account_name");
    formatFormValues("colour_scheme_classname", "colour_scheme_classname", true, "value");
    formatFormValues("company", "company", true, "value");
    formatFormValues("logo_filename", "logo_file");
    formatFormValues("financial_year_start_month", "financial_year_start_month", true, "value");
    formatFormValues("is_pharmaceutical", "is_pharmaceutical");
    formatFormValues("export_net", "export_net");
    formatFormValues("show_internal_external_on_brief", "show_internal_external_on_brief");
    formatFormValues("authorising_email", "authorising_email");
    formatFormValues("email_notification_override", "email_notification_override");
    formatFormValues("client_facing_email_notification_override", "client_facing_email_notification_override");
    formatFormValues("venue_facing_email_notification_override", "venue_facing_email_notification_override");

    if (typeof formValues["max_delegates_triggers_authorisation_workflow"] !== "undefined" && formValues["max_delegates_triggers_authorisation_workflow"]) {
      formatFormValues("max_delegates_triggers_authorisation_workflow", "max_delegates_triggers_authorisation_workflow");
    }
    formatFormValuesArray("review_items", "requires_abpi", "requires_abpi");
    formatFormValuesArray("review_items", "internal_external_split", "internal_external_split");
    formatFormValuesFromArray("corporate_account_permissions", "permission");
    formatFormValuesFromArray("booking_desks", "booking_desks");
    let fileToUpload = logoFile;
    setLogoFile(null);
    return processFormValuesPost(props, formValuesForPost, props.initialValues, fileToUpload, "logo_file");
  };

  let uploadFile = (file, isPrint = false) => {
    if (file) {
      if (isPrint) {
        setUploadingPrintLogo(true);
      } else {
        setUploading(true);
      }
      let formData = new FormData();
      if (isPrint) {
        formData.append("print_image", file[0]);
        formData.append("is_print", 1);
      } else {
        formData.append("image", file[0]);
      }
      uploadCorporateAccountLogoImage(slug, token, formData)
        .then(() => {
          setUploadingPrintLogo(false);
          setUploading(false);
        });
      if (isPrint) {
        props.change("print_logo_file", file[0]);
      } else {
        props.change("logo_file", file[0]);
      }
    } else {
      setUploading(false);
      setUploadingPrintLogo(false);
    }
  };

  const getBookingDeskOptions = () => {
    let options = [];
    if (props.bookingDesks && isArray(props.bookingDesks)) {
      props.bookingDesks.map(bookingDesk => {
        options.push({
          label: bookingDesk.name,
          value: bookingDesk.id,
        });
      });
    }
    return options;
  };

  const getPermissionsOptions = () => {
    let options = [];
    if (corporatePermissions && isArray(corporatePermissions)) {
      corporatePermissions.map(permission => {
        options.push({
          label: permission.display_name,
          value: permission.permission,
        });
      });
    }
    return options;
  }

  return (
    <div>
      <div className=" border-gray-200 sm:rounded-lg">
        <form className="border-none pb-2" onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className={"col col-w-full md:col-w-1/2"}>
              <Field
                name="account_name"
                label="Name of Corporate Account"
                component={renderFormInput}
              />
            </div>
            <div className={"col col-w-full md:col-w-1/2"}>
              <Field
                name="company"
                label="Linked Company"
                white
                component={SmartSelect}
                options={companies.length > 0 && companies
                  .filter(company => company.corporate_account_id === null)
                  .map(item => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
              />
            </div>
          </div>
          <hr className="my-6" />
          <div className={"row"}>
            <div className={"col col-w-full mb-6"}>
              <h3 className={"mb-4 md:flex md:flex-row md:justify-between md:items-center uppercase"}>
                Configuration
              </h3>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col col-w-full md:col-w-1/2 mb-6"}>
              <Field
                name="financial_year_start_month"
                label="Financial Year Start Month"
                white
                component={SmartSelect}
                options={getFinancialMonths().length > 0 && getFinancialMonths().map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
              <Field
                name="colour_scheme_classname"
                label="Colour Highlight for Corporate EDGE"
                white
                component={SmartSelect}
                options={colourSchemeOptions}
              />


              <Field
                label={"Booking Desks"}
                name={"booking_desks"}
                isMulti={true}
                component={SmartSelect}
                onChange={(e) => handleRemoveBookingDesks(e)}
                options={getBookingDeskOptions()}
              />

              <Field
                label={"Permissions"}
                name={"corporate_account_permissions"}
                isMulti={true}
                component={SmartSelect}
                options={getPermissionsOptions()}
              />

              <Field
                name="is_pharmaceutical"
                label="Is Pharmaceutical"
                component={Toggle}
              />

              <Field
                name="export_net"
                label="Export as Net?"
                component={Toggle}
              />

              {false ? (
                <>
                  {formValues.is_pharmaceutical ?
                    <>
                      <p className={"text-sm"}> Please configure the following fields specifically for Pharmaceutical
                        companies </p>

                      <Field
                        name="show_internal_external_on_brief"
                        label="Brief asks whether meeting is internal or external"
                        component={Toggle}
                      />
                      <Field
                        name="review_items"
                        label={""}
                        component={Checkbox}
                        options={[
                          { text: "Requires ABPI compliance?", value: "requires_abpi" },
                          {
                            text: "RFP delegate count is split into internal + external",
                            value: "internal_external_split",
                          },
                        ]}
                        validate={v.required}
                      />
                    </>
                    : null}
                </>
              ) : null}

            </div>
            <div className={"col col-w-full md:col-w-1/2 mb-6"}>
              <Field
                  name="email_notification_override"
                  label="Agency Email Notification Override - instead of default distribution list (info@ / enquiries@)"
                  component={renderFormInput}
                  validate={v.email}
              />
              <Field
                  name="client_facing_email_notification_override"
                  label="Client Facing Email Notification Override"
                  component={renderFormInput}
                  validate={v.email}
              />
              <Field
                  name="venue_facing_email_notification_override"
                  label="Venue Facing Email Notification Override"
                  component={renderFormInput}
                  validate={v.email}
              />
            </div>
          </div>
          <div className="row">
            {slug ?
              <>
                <div className={"col col-w-full md:col-w-1/2 mb-6"}>
                  <label className="mb-1 block font-normal font-heading text-sm uppercase">Logo Upload</label>
                  <DropzoneArea
                    type="image"
                    minWidth={"200px"}
                    minHeight={"100px"}
                    onDrop={(file) => uploadFile(file, false)}
                    uploading={uploading}
                  />
                  <div className="hidden">
                    <Field
                      name="logo_filename"
                      label="File Name"
                      component={renderFormInput}
                    />
                  </div>
                  {logoFile ? (
                    <div className={"col col-w-full md:col-w-1/2 mb-6"}>
                      <img src={window.URL.createObjectURL(logoFile)} alt="logo" />
                    </div>
                  ) : null}
                  {!logoFile && (corporateAccount && corporateAccount?.logo_link && corporateAccount?.cdn_path) ? (
                    <img src={corporateAccount.cdn_path} alt={corporateAccount ? corporateAccount.account_name : "Logo"} />
                  ) : null}
                  <p className={"font-small"}>
                    The logo is used on the website header when users are logged in to the Corporate account. Please ensure it is a transparent/white PNG file 200px x 100px
                  </p>
                </div>
                <div className={"col col-w-full md:col-w-1/2 mb-6"}>
                  <label className="mb-1 block font-normal font-heading text-sm uppercase">Print Logo Upload</label>
                  <DropzoneArea
                    type="print_logo"
                    minWidth={"200px"}
                    minHeight={"100px"}
                    onDrop={(file) => uploadFile(file, true)}
                    uploading={uploadingPrintLogo}
                  />
                  <div className="hidden">
                    <Field
                      name="print_logo_filename"
                      label="File Name"
                      component={renderFormInput}
                    />
                  </div>
                  {printLogoFile ? (
                    <div className={"col col-w-full md:col-w-1/2 mb-6"}>
                      <img src={window.URL.createObjectURL(printLogoFile)} alt="logo" />
                    </div>
                  ) : null}
                  {!printLogoFile && (corporateAccount && corporateAccount?.print_logo_link && corporateAccount?.cdn_path_print) ? (
                    <img src={corporateAccount.cdn_path_print} alt={corporateAccount ? corporateAccount.account_name : "Print Logo"} />
                  ) : null}
                  <p className={"font-small"}>
                    The logo is used on PDF exports associated with this corporate account. Please ensure it is a transparent/white PNG file 200px x 100px
                  </p>
                </div>
              </>
              : null}
          </div>
          <hr />
          <div className={"row"}>
            <div className={"col col-w-full md:col-w-1/2 mb-6"}>
              <h3 className={"mb-4 md:flex md:flex-row md:justify-between md:items-center uppercase"}>
                Authorisation
              </h3>
              <Field
                name={"max_delegates_triggers_authorisation_workflow"}
                label="Max delegates to trigger authorisation workflow"
                wrapperClassName="w-full mr-2"
                component={Input}
                type={"number"}
                min={0}
                step={1}
                normalize={val => (val || "").replace(/[^\d]/g, "")}
              />
              <br />
              <Field
                name="authorising_email"
                label="Authorising Email"
                component={renderFormInput}
              />
            </div>
          </div>

          <div className="row min-w-full">
            <div className={"col col-w-full "}>
              <div className={"corp-form-buttons"}>
                <ButtonPrimary type="submit">
                  {submitting ? <Loading inline /> : (buttonText ? buttonText : "Create Corporate Account")}
                </ButtonPrimary>
              </div>
            </div>
          </div>

        </form>
        <hr className="my-6" />
      </div>
    </div>
  );
};

CorporateAccountForm = reduxForm({
  form: "corporateAccountForm",
  enableReinitialize: true,
})(CorporateAccountForm);

export default connect(mapStateToProps, { uploadCorporateAccountLogoImage })(CorporateAccountForm);
