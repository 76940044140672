import axios from "axios";

const fetchPriceBandingData = async (token) => {
    try {
        const response = await axios.get(
            `${window.API}/price-bandings`,
        );
        return response.data;
    } catch (error) {

    }
};

const fetchVenuePriceBandingData = async (token, venue) => {
    try {
        const response = await axios.get(
            `${window.API}/venues/${venue}/price-banding`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        return response.data;
    } catch (error) {

    }
};

export {fetchPriceBandingData, fetchVenuePriceBandingData}