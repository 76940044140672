import axios from "axios";


export default (values, props, pristine, { onlyValidate }) => {
    let enquiryId = props.match.params.id;
    let enquiryVenueDataId = props.match.params.enquiryVenueDataId;
    const url = `${window.API}/rfp/${enquiryId}/confirm/${enquiryVenueDataId}/user`;

    const confirmText = pristine ? 'Confirm these details with the Venue?': 'Confirm these amendments with the venue?'
    values['pristine'] = pristine;
    if (window.confirm(confirmText)) {
        return axios
            .post(url, values, {
                headers: { Authorization: `Bearer ${props.token}` },
            })
            .then((result) => {
                return result;
            })
            .catch(

            );
    }else {
        return new Promise((resolve, reject) => {

            reject(new Error("Not Confirmed"));
        });
    }

};
