import React, { useState } from "react";

//difference between this component and ExpandableText: hide "Read more" if string is shorter
const ReadMoreText = ({ children, maxLength = 150 }) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <p className="font-normal text-base">
        {children.length < maxLength || expanded
          ? children
          : `${children.substr(0, maxLength).trim()}...`}
      </p>
      {children.length >= maxLength && (
        <div className="mb-3">
          <span
            className="text-edge-blue uppercase text-sm pointer font-bold"
            onClick={toggle}
          >
            Read {expanded ? "less" : "more"}
          </span>
        </div>
      )}
    </div>
  );
};

export default ReadMoreText;
