import React from "react";
import EdgeInfoColumn from "Components/EdgeInfoColumn";
import { ButtonLink } from "Components/Button";
import { getWidth } from "redux-window";
import { useSelector } from "react-redux";
import { Link as ScrollLink } from "react-scroll";

const FindPerfectSpace = () => {
  const mobile = useSelector(state => getWidth(state) < 1300);

  return (
    <div className="bg-light-blue pt-16 pb-14 px-0">
      <div className="container">
        <div className="col col-w-full">
          <h1 className="text-2rem md:text-5xl mb-7">
            EDGE will help you locate the perfect space.
          </h1>
          <p className="font-normal text-lg md:text-2xl leading-tight mb-0">
            Are you searching for the perfect venue for a conference, meeting, training or event? Find the ideal location, whether it’s a hotel with meeting rooms or an unusual venue to hire.  There are more than 19,000 to choose from, in the UK and overseas.
          </p>
        </div>
        <div
          className="col flex flex-wrap mt-12"
          style={
            !mobile
              ? {
                  width: "calc(100% + 10rem)",
                  marginLeft: "-5rem",
                  marginRight: "-5rem",
                }
              : undefined
          }
        >
          <EdgeInfoColumn
            img={"img/search.svg"}
            title="Quick and easy searching"
            content={
              <div className="text-lg">
                <p className="leading-tight">
                  The fastest way to find and book a venue.  Match your event requirements using the comprehensive database, select, shortlist and contact the venues directly
                </p>
                <ScrollLink
                  to="search"
                  offset={-100}
                  smooth
                  className="pointer"
                >
                  <ButtonLink>Start a search</ButtonLink>
                </ScrollLink>
              </div>
            }
          />
          <EdgeInfoColumn
            img={"img/support.svg"}
            title="Designed for you"
            content={
              <div className="text-lg">
                <p className="leading-tight">
                  Use advanced searches to make well-informed decisions, whether you’re an event organiser or agent, and if the group is fewer than 10 people you can book immediately online
                </p>
                <ButtonLink href="/agents">
                  Learn all about our specialised support
                </ButtonLink>
              </div>
            }
          />
          <EdgeInfoColumn
            img={"img/offers.svg"}
            title="Registration benefits"
            content={
              <div className="text-lg">
                <p className="leading-tight">
                  See the information that you need – latest news and offers from venues, information about familiarisation trips, renovation news and industry insights
                </p>
                <ButtonLink href="/register">
                  Register for an account
                </ButtonLink>
              </div>
            }
          />
          <EdgeInfoColumn
            img={"img/venues.svg"}
            title="Features"
            content={
              <div className="text-lg">
                <p className="leading-tight">
                    All the information you need to make a booking - extensive photo gallery, virtual reality tours, daily and 24-hour rates, meeting room sizes and capacities, CSR statements, catering, location and parking
                </p>

                <ScrollLink
                  to="search"
                  offset={-100}
                  smooth
                  className="pointer"
                >
                  <ButtonLink>Browse our venues</ButtonLink>
                </ScrollLink>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FindPerfectSpace;
