import {connect} from "react-redux";
import {token} from "../../../../modules/auth";
import {getFormValues} from "redux-form";
import React, {useEffect} from "react";
import {Cell, GroupHeader, RowHeader, TR} from "../clean-table";
import {createRangeYearToDate} from "../date-utils";
import getDatesArray from "../../../../modules/analytics/get-dates-array";
import {detailedEngagements} from "../../../../modules/analytics";
import {
    agentRfpItemsUnload,
    fetchAgentRfpItems,
} from "../../../../redux/actions";
import {ButtonBrand} from "../../../../Components/Button";

const PAGE_SIZE = 20;
const mapStateToProps = (state, props) => {
    const v = getFormValues("analytics")(state) || {};
    let dates = getDatesArray(createRangeYearToDate().start, createRangeYearToDate().end);
    return {
        token: token(state),
        agentRfps: state.reportingFigures.agentRfps,
        isFetching: state.reportingFigures.isFetching,
        stats: detailedEngagements(v.venue_id, createRangeYearToDate().start, createRangeYearToDate().end)(state),
        venues: v.venues,
        startDate: typeof props.startDate !== 'undefined' ? props.startDate : v.start_date,
        endDate:  typeof props.endDate !== 'undefined' ? props.endDate :v.end_date,
    }
}

const mapDispatchToProps = {
    fetchAgentRfpItems,
    agentRfpItemsUnload,
}

const AgencyViews = (props) => {

    const [showAll, setShowAll] = React.useState(false);
    useEffect(() => {
        props.fetchAgentRfpItems(props.venues, props.startDate, props.endDate, props.type, props.token)
    }, []);

    const groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );
    let agencyGroups = [];
    if(props.engagements.length === 0 && (!props.agentRfps || props.agentRfps.length === 0)) {
        return <div>No Agency Engagements</div>
    }

    if(props.engagements.length !== 0) {
        let usersGroups = groupBy(props.engagements, 'user_type');
        if (usersGroups && usersGroups['Agency']) {
            agencyGroups = groupBy(usersGroups['Agency'], 'user_company');
        }
    }
    let agenciesAdded = [];
    if(props.agentRfps && props.agentRfps.length > 0) {
        for (const [key, value] of Object.entries(groupBy(props.agentRfps, 'company'))) {
            let engagementCount = 0;
            if (agencyGroups && agencyGroups[key]) {
                engagementCount += agencyGroups[key].length;
            }
            if (key !== 'null' && key !== '') {
                agenciesAdded.push({
                    key: key,
                    count: value.length + engagementCount,
                });
            }
        }
    }
    if(props.engagements.length !== 0) {
        for (const [key, value] of Object.entries(agencyGroups)) {
            if (!agenciesAdded.find((company) => company.key === key) && key !== 'null' && key !== '') {
                agenciesAdded.push({
                    key: key,
                    count: value.length
                });
            }
            if (!agenciesAdded.find((company) => company.key === key) && key === 'null') {
                let other = agenciesAdded.find((company) => company.key === 'Other');
                if (other?.length > 0) {
                    other.count += value.length;

                }else{
                    agenciesAdded.push({
                        key: 'Other',
                        count: value.length
                    });
                }

            }

            if (!agenciesAdded.find((company) => company.key === key) && key === '') {
                let other = agenciesAdded.find((company) => company.key === '');
                if (other?.length > 0) {
                    other.count += value.length;

                }else{
                    agenciesAdded.push({
                        key: 'Other',
                        count: value.length
                    });
                }
            }
        }
    }
    if(agenciesAdded.length === 0){
        return <div>No Agency Engagements</div>
    }

    return (
        <table>
            <thead>
            <tr>

                <GroupHeader colSpan={2} className="w-1/4">
                    Agency
                </GroupHeader>
                <GroupHeader colSpan={2} className="w-1/4">
                    Views
                </GroupHeader>
            </tr>

            </thead>
            <tbody className={'text-xl'}>
            <>
                {agenciesAdded.sort((a, b) => b.count - a.count).map((company, index) => {
                    if(showAll || index < PAGE_SIZE ) {
                        return <TR key={company.key}>
                            <Cell colSpan={2}>{company.key}</Cell>
                            <Cell colSpan={2}>{company.count}</Cell>

                        </TR>
                    }

                })}
            </>
            {agenciesAdded.length > PAGE_SIZE ? <div className={'pt-5'}>
                <ButtonBrand onClick={() => setShowAll(!showAll)}>{showAll ? 'Hide All' : 'Show All'}</ButtonBrand>

            </div> : <></>}
            </tbody>
        </table>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyViews)
