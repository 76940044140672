import React, {Fragment, useEffect, useState} from 'react';
import {ButtonPrimary, ButtonSpan, ButtonGrey} from "../../../../Components/Button";
import {Date, Select} from "../../../../ui";
import classnames from "classnames";
import {format} from "date-fns";
import {Input} from "../../../../Components/Form";
import RoundedCheckbox from "../../../../Components/RoundedCheckbox";
import MultiSelect from "../../../../ui/MultiSelect";
import {LiveSearchSimple} from "../../../../Components/Form/LiveSearch/LiveSearchSimple";
import {BOOKED_EVENT_STATUS} from "../../../../constants";
import { MultiValue, ValueContainer, Option } from "../../../../Components/corporate/reports/MultiValueComponents";
import {useLocation} from "react-router-dom";

function EventFilters({
                        filterBookedEvents,
                        companies,
                        currentPage,
                        eventStatus,
                        isCompanyAccount,
                        corporateAccount,
                        getBookingDesks,
                        getDepartments,
                        corporateDepartments,
                        isAgencyAdmin,
                        isEdgeAdmin,
                        getVenuesForHotelGroup,
                        venues,
                        getCompany,
                        companyObject,
                        agencyBookingDesks,
                        isCorporate,
                        getMeetingHosts,
                        corporateAccountUsers = [],
                        canViewCommissionValue,
                        companyBranches,
                        getBranches
                      }) {
  const [dateTo, setDateTo] = useState("");
  const [company, setCompany] = useState("");
  const [companiesFilter, setCompaniesFilter] = useState([]);
  const [questions, setQuestions] = useState(corporateAccount && corporateAccount.custom_field_questions ? JSON.parse(corporateAccount.custom_field_questions) : []);
  const [bookingDesk, setBookingDesk] = useState("");
  const [branch, setBranch] = useState("");
  const [meetingHost, setMeetingHost] = useState("");
  const [bookingDesks, setBookingDesks] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [hotelGroup, setHotelGroup] = useState("");
  const [venue, setVenue] = useState("");
  const [shouldClearLiveSearch, setShouldClearLiveSearch] = useState(false);
  const [status, setStatus] = useState("");
  const [term, setTerm] = useState("");
  const [dateFilter, setDateFilter] = useState(true);
  const [requiresInvoicing, setRequiresInvoicing] = useState(true);
  const [queryParams, setQueryParams] = useState({});
  const [customFilterQuestions, setCustomFilterQuestions] = useState({});
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [independentVenues, setIndependentVenues] = useState(false);
  const search = useLocation()?.search;
  let eventDateFromQueryParam = new URLSearchParams(search).get('eventDateFrom');
  let eventStatusQueryParam = new URLSearchParams(search).get('eventStatus');

  useEffect(() => {
    if (corporateAccount && corporateAccount.booking_desks && Object.entries(corporateAccount.booking_desks).length > 0) {
      setBookingDesks(corporateAccount.booking_desks)
    }
    statusParams = window.localStorage.getItem('statusParams') !== null ? window.localStorage.getItem('statusParams') : '';
    if (isCompanyAccount) {
      filterBookedEvents({
        'eventStatus': statusParams
      })
    } else {
      filterBookedEvents({
        'requiresInvoicing': true,
        'eventStatus': statusParams
      })
    }
    if (eventDateFromQueryParam) {
      setDateFrom(eventDateFromQueryParam);
      setStatus(eventStatusQueryParam);
      window.localStorage.setItem('statusParams', eventStatusQueryParam)
      filterBookedEvents({
        eventDateFrom: eventDateFromQueryParam,
        eventStatus: eventStatusQueryParam
      })
    }
  }, []);

  let statusParams = status;
  useEffect(() => {
    if (hotelGroup !== '') {
      getVenuesForHotelGroup(hotelGroup);
    }
  }, [hotelGroup]);

  useEffect(() => {
    if (shouldClearLiveSearch) {
      setShouldClearLiveSearch(false)
    }
  }, [shouldClearLiveSearch]);

  useEffect(() => {
    if (company && corporateDepartments && Object.entries(corporateDepartments).length > 0) {
      setDepartments(corporateDepartments)
    } else {
      setDepartments([])
    }
  }, [corporateDepartments]);

  useEffect(() => {
    if (agencyBookingDesks && Object.entries(agencyBookingDesks).length > 0) {
      setBookingDesks(agencyBookingDesks)
    } else {
      setBookingDesks([])
    }
  }, [agencyBookingDesks]);

  useEffect(() => {
    if (company) {
      getCompany(company)
    } else {
      setDepartments([])
      if (Object.keys(corporateAccount).length === 0) {
        setQuestions([])
      }
    }
  }, [company]);

  useEffect(() => {
    if (companyObject && company) {
      if (Object.keys(corporateAccount).length === 0) {
        let companyObj = companies.find((cmp) => {
          return parseInt(cmp.id) === parseInt(company)
        });
        getMeetingHosts()
        getDepartments()
        getBranches()
        let companyQuestions = companyObj && companyObj.custom_field_questions ? JSON.parse(companyObj.custom_field_questions) : null
        setQuestions(companyQuestions)
      }
    }
  }, [companyObject])

  useEffect(() => {
    if (independentVenues) {
      setHotelGroup(null)
    }
  }, [independentVenues]);

  useEffect(() => {
    let dateRange = (dateTo && dateFrom) ? dateFrom + "," + dateTo : '';
    statusParams = window.localStorage.getItem('statusParams') !== null ? window.localStorage.getItem('statusParams') : ''
    setQueryParams({
      'eventDate': dateFilter ? dateRange : '',
      'enquiryDate': !dateFilter ? dateRange : '',
      'eventDateFrom': dateFilter ? dateFrom : '',
      'enquiryDateFrom': !dateFilter ? dateFrom : '',
      'eventStatus': statusParams,
      'company': company || companiesFilter,
      'branch': branch,
      'customFields': customFilterQuestions,
      'hotelGroup': hotelGroup,
      'venue': venue,
      'bookingDesk': bookingDesk,
      'meetingHost': meetingHost,
      'department': department,
      'search': term,
      'requiresInvoicing': requiresInvoicing && !isCompanyAccount ? true : '',
      'page': 1,
    })
  }, [dateTo, dateFrom, status, term, company, requiresInvoicing, customFilterQuestions, bookingDesk, department, hotelGroup, venue, meetingHost, companiesFilter, branch]);

  const resetFilters = () => {
    setDateTo('')
    setDateFrom('')
    setTerm('')
    setCompany('')
    setCompaniesFilter([])
    setHotelGroup('')
    setVenue('')
    setBookingDesk('')
    setDepartment('')
    setMeetingHost('')
    setQueryParams({})
    setCustomFilterQuestions({})
    setRequiresInvoicing(true)
    setShouldClearLiveSearch(true)
    setShowAdvancedFilters(false)
    setIndependentVenues(false)
    filterBookedEvents()
  }

  const toggleDate = () => {
    setDateFilter(!dateFilter)
  };

  const toggleRequiresInvoicing = () => {
    setRequiresInvoicing(!requiresInvoicing)
  };

  const handleStatusChange = (e) => {
    setStatus(e)
    let params = [];
    if (e) {
      e.map(stat => {
        params.push(stat.value)
      })
    }
    window.localStorage.setItem('statusParams', params)
  }

  const getStatusOptions = () => {
    let eventStatusOptions = [];
    if (eventStatus && eventStatus.data) {
      eventStatus.data.filter((status) => {
        if (isCompanyAccount) {
          if (status.code === BOOKED_EVENT_STATUS.PENDING_INVOICE) {
            return false;
          }
        }
        return true;
      }).map(status => {
        eventStatusOptions.push(
          {label: status.name, value: status.code}
        )
      });
    }
    return eventStatusOptions;
  };

  const getBookingDeskOptions = () => {
    let options = [];
    if (bookingDesks && Object.keys(bookingDesks).length > 0) {
      bookingDesks.sort((a, b) => a.name.localeCompare(b.name)).map(bookingDesk => {
        options.push({
          text: bookingDesk.name,
          value: bookingDesk.id,
          label: bookingDesk.name
        })
      })
    }
    return options;
  }

  const getCompanyBranchesOptions = () => {
    let options = [];
    if (companyBranches && Object.keys(companyBranches).length > 0) {
      companyBranches.sort((a, b) => a.name.localeCompare(b.name)).map(companyBranch => {
        options.push({
          text: companyBranch.name,
          value: companyBranch.id
        })
      })
    }
    return options;
  }

  const getDepartmentsOptions = () => {
    let options = [];
    if (corporateDepartments && Object.keys(corporateDepartments).length > 0) {
      corporateDepartments.sort((a, b) => a.name.localeCompare(b.name)).map(department => {
        options.push({
          text: department.name,
          value: department.id,
          label: department.name,
          name: department.name,
          id: department.id
        })
      })
    }
    return options;
  }

  const getCompaniesOptions = () => {
    let options = [];
    if (companies && Object.entries(companies).length > 0) {
      companies.sort((a, b) => a.name.localeCompare(b.name))
      companies.map((company) => {
        options.push({
          label: company.name,
          value: company.id
        })
      })
    }
    return options;
  };

  const getVenuesOptions = () => {
    let options = [];
    if (Object.entries(venues).length > 0) {
      venues.sort((a, b) => a.name.localeCompare(b.name))
      venues.map((venue) => {
        options.push({
          text: venue.name,
          value: venue.id
        })
      })
    }
    return options;
  };

  const handleDepartmentSelect = (e) => {
    let params = [];
    if (e) {
      e.map(dept => {
        params.push(dept.value)
      })
    }
    setDepartment(params)
  }

  const handleBookingDeskSelect = (e) => {
    let params = [];
    if (e) {
      e.map(desk => {
        params.push(desk.value)
      })
    }
    setBookingDesk(params)
  }

  const handleCompanySelect = (e) => {
    let params = [];
    setBookingDesk("")
    if (e) {
      e.map(company => {
        params.push(company.value)
      })
    }
    if (params.length > 1) {
      setCompany("")
      setCompaniesFilter(params)
    } else {
      setCompaniesFilter([])
      setCompany(params)
    }
  }

  const getMeetingHostsOptions = () => {
    let options = [];
    if (corporateAccountUsers && corporateAccountUsers.users && corporateAccountUsers.users.length > 0) {
      corporateAccountUsers.users.map(user => {
        options.push({
          text: user.first_name + " " + user.surname + " (" + user.email + ")",
          value: user.id
        })
      })
    }
    return options;
  }

  let customFieldFilterClass = 'mb-2 xxl:mb-0 w-full lg:w-48 mr-2'

  return (
    <>
      <div
        className="flex flex-col lg:flex-wrap lg:flex-row justify-between align-bottom p-3 bg-white mb-0 lg:items-end">
        <div className="mb-2 xxl:mb-0">
          <div className="flex text-xs min-h-9.5">
            <ButtonSpan
              className={classnames(
                "inline-block flex items-center px-3 border border-grey-md",
                {
                  "opacity-50": dateFilter,
                  "bg-brand": !dateFilter,
                }
              )}
              onClick={() => toggleDate(0)}
            >
              Enquiry Date
            </ButtonSpan>
            <ButtonSpan
              className={classnames(
                "inline-block flex items-center px-3 border border-grey-md border-r-0",
                {
                  "opacity-50": !dateFilter,
                  "bg-brand": dateFilter,
                }
              )}
              onClick={() => toggleDate(1)}
            >
              Event Date
            </ButtonSpan>
          </div>
        </div>
        <div className="mb-2 xxl:mb-0">
          <Date
            name="date_from"
            label={dateFilter ? "Event Date From" : "Enquiry Date From"}
            meta={{}}
            wrapperClassName="mb-0"
            classes={'min-h-9.5'}
            datePickerClass=""
            input={{
              onChange: e => {
                if (e) {
                  setDateFrom(format(e, "YYYY-MM-DD 00:00:00"));
                } else {
                  setDateFrom(null)
                }
              },
              value: dateFrom
            }}
          />
        </div>
        <div className="mb-2 xxl:mb-0">
          <Date
            name="date_to"
            label={dateFilter ? "Event Date To" : "Enquiry Date To"}
            meta={{}}
            wrapperClassName="mb-0"
            classes={'min-h-9.5'}
            datePickerClass=""
            input={{
              onChange: e => {
                if (e) {
                  setDateTo(format(e, "YYYY-MM-DD 23:59:59"));
                } else {
                  setDateTo(null);
                }
              },
              value: dateTo
            }}
          />
        </div>
        <div className="mb-2 xxl:mb-0">
          <MultiSelect
            label={"Filter by status"}
            cache={true}
            cacheKey={'statusParams'}
            options={getStatusOptions()}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option,
              MultiValue,
              ValueContainer
            }}
            onChange={(e) => handleStatusChange(e)}
            allowSelectAll={true}
            value={statusParams}
          />
        </div>
        <div className="mb-2 xxl:mb-0">
          <Input
            input={{
              onChange: e => setTerm(e.target.value),
              value: term,
            }}
            meta={{}}
            name="search"
            label="Search Term"
            labelClassName="block uppercase font-heading text-xs mb-1"
            wrapperClassName="mb-0"
          />
        </div>
        {canViewCommissionValue ?
          <div className="mb-2 lg:mb-0 self-start">
            <label className="block uppercase font-heading text-xs mb-1 text-center">Commission?</label>
            <div className="flex justify-center self-end">
              <RoundedCheckbox
                title={'Has Commission?'}
                size={24}
                checked={requiresInvoicing}
                onClick={() => toggleRequiresInvoicing()}
              />
            </div>
          </div>
          : null}

        <div className="mb-2 xxl:mb-0">
          <ButtonPrimary classes="mb-0" onClick={() => filterBookedEvents(queryParams)}>
            Filter
          </ButtonPrimary>
        </div>
        <div className="mb-2 xxl:mb-0">
          {/*{(queryParams) && (*/}
          <ButtonGrey classes="mb-0" onClick={resetFilters} title="Reset filters">
            <i className="fal fa-redo"></i>
          </ButtonGrey>
          {/*)}*/}
        </div>
      </div>
      <div className={'flex flex-col text-xs mb-3 pt-0 px-3 pb-3 bg-white'} onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}>
        <span className={'pointer hover:text-brand'}>Advanced Filters</span>
      </div>
      <div
        className={showAdvancedFilters ? `flex-col lg:flex-wrap lg:flex-row justify-start align-bottom p-3 bg-white -mt-3 mb-3 lg:items-end flex` : `hidden`}>
        {!isCorporate && companyObject ? (
            <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
              <MultiSelect
                name="booking_desk"
                label="Filter by Booking Desk"
                isMulti
                options={getBookingDeskOptions()}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                wrapperClassName="mb-0"
                onChange={e => handleBookingDeskSelect(e)}
                autoComplete={'off'}
                components={{
                  Option,
                  MultiValue,
                  ValueContainer
                }}
                style={{
                  searchBox: {
                    "border-radius": "0px"
                  }
                }}
              />


              {/*<Select*/}
              {/*    name="booking_desk"*/}
              {/*    label="Filter by Booking Desk"*/}
              {/*    component={Select}*/}
              {/*    options={getBookingDeskOptions()}*/}
              {/*    wrapperClassName="mb-0"*/}
              {/*    input={{*/}
              {/*      onChange: e => setBookingDesk(e.target.value),*/}
              {/*      value: bookingDesk*/}
              {/*    }}*/}
              {/*    meta={{}}*/}
              {/*/>*/}
            </div>
        ) : null}
        {!isCompanyAccount ?
          <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
            <MultiSelect
              name="company"
              label="Filter by Company"
              isMulti
              options={getCompaniesOptions()}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              wrapperClassName="mb-0"
              onChange={e => handleCompanySelect(e)}
              autoComplete={'off'}
              components={{
                Option,
                MultiValue,
                ValueContainer
              }}
              style={{
                searchBox: {
                  "border-radius": "0px"
                }
              }}
            />
            {/*<Select*/}
            {/*  name="company"*/}
            {/*  label="Filter by Company"*/}
            {/*  component={Select}*/}
            {/*  options={getCompaniesOptions()}*/}
            {/*  wrapperClassName="mb-0"*/}
            {/*  input={{*/}
            {/*    onChange: e => {*/}
            {/*      setBookingDesk("")*/}
            {/*      setCompany(e.target.value)*/}
            {/*    },*/}
            {/*    value: company*/}
            {/*  }}*/}
            {/*  meta={{}}*/}
            {/*/>*/}
          </div>
          : null}
        {((companyObject && Object.entries(companyObject).length > 0) && companiesFilter.length < 1) ? (
          <>
            {companyBranches && Object.entries(companyBranches).length > 0 ? (
              <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
                <Select
                  name="branch"
                  label="Filter by Branch"
                  component={Select}
                  options={getCompanyBranchesOptions()}
                  wrapperClassName="mb-0"
                  input={{
                    onChange: e => setBranch(e.target.value),
                    value: branch
                  }}
                  meta={{}}
                />
              </div>
            ) : null}
            {getMeetingHostsOptions().length > 0 ? (
              <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
                <Select
                  name="meeting_host"
                  label="Filter by Meeting Host"
                  component={Select}
                  options={getMeetingHostsOptions()}
                  wrapperClassName="mb-0"
                  input={{
                    onChange: e => setMeetingHost(e.target.value),
                    value: meetingHost
                  }}
                  meta={{}}
                />
              </div>
            ) : null}
            <div className="mb-2 xxl:mb-0 w-full lg:w-48 mr-2">
              <MultiSelect
                name="department"
                label="Filter by Department"
                isMulti
                options={getDepartmentsOptions()}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                wrapperClassName="mb-0"
                onChange={e => handleDepartmentSelect(e)}
                autoComplete={'off'}
                components={{
                  Option,
                  MultiValue,
                  ValueContainer
                }}
                style={{
                  searchBox: {
                    "border-radius": "0px"
                  }
                }}
              />
            </div>
          </>
        ) : null}
        {(isAgencyAdmin || isEdgeAdmin) && !independentVenues ? (
          <Fragment>
            <LiveSearchSimple
              fieldClassName="mb-2 xxl:mb-0 mr-2 w-full lg:w-48"
              name="hotel_group"
              label="Filter By Hotel Group"
              labelClassName="block uppercase font-heading mb-1 text-xs "
              auth
              url={`${window.API}/search/hotel-groups?addIndependent=true`}
              onChange={(group) => setHotelGroup(group.id)}
              shouldClearSearches={shouldClearLiveSearch}
              onClear={() => {
                setHotelGroup("")
                setVenue("")
              }}
            />
            {independentVenues || hotelGroup ? (
              <LiveSearchSimple
                fieldClassName="mb-2 xxl:mb-0 mr-2 w-full lg:w-48"
                name="venue"
                label="Filter By Venue"
                labelClassName="block uppercase font-heading mb-1 text-xs "
                auth
                url={`${window.API}/hotel-group/venues?hotelGroup=${hotelGroup}`}
                onChange={v => setVenue(v.id)}
                shouldClearSearches={shouldClearLiveSearch}
              />
            ) : null}
          </Fragment>
        ) : null}
        {questions ?
          questions.map((question) => {
            if (question.canBeFiltered) {
              switch (question.fieldType) {
                case 'dropdown':
                  return <div className={customFieldFilterClass}><Select
                    key={question.fieldIdentifier}
                    name={question.fieldIdentifier}
                    label={question.label}
                    component={Select}
                    options={question.options.sort((a, b) => a.sort < b.sort).map((option) => {
                      return {
                        text: option.label,
                        value: option.value
                      }
                    })}
                    wrapperClassName="mb-0"
                    input={{
                      onChange: e => {
                        let update = [];
                        update[question.fieldIdentifier] = e.target.value;
                        setCustomFilterQuestions({...customFilterQuestions, ...update})
                      },
                      value: typeof customFilterQuestions[question.fieldIdentifier] !== 'undefined' ? customFilterQuestions[question.fieldIdentifier] : ''
                    }}
                    meta={{}}
                  /></div>
                case 'date':
                  return <div className={customFieldFilterClass}><Date
                    key={question.fieldIdentifier}
                    name={question.fieldIdentifier}
                    label={question.label}
                    meta={{}}
                    wrapperClassName="mb-0"
                    classes={'min-h-9.5'}
                    datePickerClass=""
                    input={{
                      onChange: e => {
                        let update = [];
                        update[question.fieldIdentifier] = format(e, 'YYYY-MM-DD 00:00:00');
                        setCustomFilterQuestions({...customFilterQuestions, ...update})

                      },
                      value: typeof customFilterQuestions[question.fieldIdentifier] !== 'undefined' ? customFilterQuestions[question.fieldIdentifier] : null
                    }}
                  /></div>
                case 'text':
                case 'numeric':
                  return <div className={customFieldFilterClass}><Input
                    key={question.fieldIdentifier}
                    input={{
                      onChange: e => {
                        let update = [];
                        update[question.fieldIdentifier] = question.fieldType === 'numeric' ? parseInt(e.target.value) : e.target.value;
                        setCustomFilterQuestions({...customFilterQuestions, ...update})
                      },
                      value: typeof customFilterQuestions[question.fieldIdentifier] !== 'undefined' && customFilterQuestions[question.fieldIdentifier] ? question.fieldType === 'numeric' ? parseInt(customFilterQuestions[question.fieldIdentifier]) : customFilterQuestions[question.fieldIdentifier] : '',
                    }}
                    meta={{}}
                    name={question.fieldIdentifier}
                    label={question.label}
                    labelClassName="block uppercase font-heading text-xs mb-1"
                    wrapperClassName="mb-0"
                  /></div>
                case 'checkbox':
                  return <div className={customFieldFilterClass}>
                    <Select
                      key={question.fieldIdentifier}
                      name={question.fieldIdentifier}
                      label={question.label}
                      component={Select}
                      options={[{
                        text: 'Yes',
                        value: 1
                      },
                        {
                          text: 'No',
                          value: 0
                        }
                      ]}
                      wrapperClassName="mb-0"
                      input={{
                        onChange: e => {
                          let update = [];
                          update[question.fieldIdentifier] = e.target.value;
                          setCustomFilterQuestions({...customFilterQuestions, ...update})
                        },
                        value: typeof customFilterQuestions[question.fieldIdentifier] !== 'undefined' ? customFilterQuestions[question.fieldIdentifier] : ''
                      }}
                      meta={{}}
                    />
                  </div>
              }
            }
          })
          : null}
      </div>

    </>
  );
}

export default EventFilters;
