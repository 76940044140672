import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { WhitePanelWithHeader } from "../../ui";
import TableWithCollapsibleRows from "../Table/TableWithCollapsibleRows";
import { formatDate } from "../../modules/dates";
import { ButtonPrimary } from "../Button";

const LinkedEnquiries = ({
                           label,
                           expanded,
                           rfpId,
                           token,
                         }) => {
  const [linkedEnquiries, setLinkedEnquiries] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    return () => setLinkedEnquiries([]);
  }, []);

  useEffect(() => {
    if (panelOpen) {
      getLinkedEnquiries();
    } else {
      setLinkedEnquiries([]);
    }
  }, [panelOpen]);

  const togglePanel = () => {
    setPanelOpen(!panelOpen);
  };

  const getLinkedEnquiries = async () => {
    await axios.get(`${window.API}/rfp/enquiry/${rfpId}/linked-enquiries`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => setLinkedEnquiries(data))
      .catch(error => console.error(error));
  };

  return <WhitePanelWithHeader
    collapsible
    expanded={expanded}
    open={panelOpen}
    onToggle={togglePanel}
    title={label ? label : "Linked Enquiries"}
    icon="far fa-user">
    {linkedEnquiries.length > 0 ? (
      <TableWithCollapsibleRows
        admin
        headers={headers}
        data={linkedEnquiries.map(linkedEnquiry => {
          return {
            ...linkedEnquiry,
            ref_id: () => (
              <span className="flex">
                  <Link to={`${location.pathname}/${linkedEnquiry.id}`} className={"text-black"}>
                    {linkedEnquiry.ref_id}
                  </Link>
                {linkedEnquiry.status && (!linkedEnquiry.estimated_value || !linkedEnquiry.estimated_commission) ? (
                  <span
                    className="inline-block ml-1 rounded-100 h-2 w-2 flex items-center justify-center bg-red self-center"
                    title="Missing Estimated Values" />
                ) : linkedEnquiry.status && (linkedEnquiry.estimated_value && linkedEnquiry.estimated_commission) ? (
                  <span
                    className="inline-block ml-1 rounded-100 h-2 w-2 flex items-center justify-center bg-green self-center"
                    title="Has Estimated Values" />
                ) : null}
                </span>
            ),
            event_name: () => (
              <span>{linkedEnquiry.event_name}</span>
            ),
            event_date: () => (
              <span>
                {formatDate(linkedEnquiry.date_from, "ddd Do MMM YYYY")}
              </span>
            ),
            ":view": (
              <ButtonPrimary
                classes="w-full"
                Element={Link}
                to={`/admin/rfp/${linkedEnquiry.id}`}
              >
                View
              </ButtonPrimary>
            ),
          };
        })}
      />
    ) : (
      <Fragment>
        <p>There are no enquiries linked to this enquiry.</p>
      </Fragment>
    )}
  </WhitePanelWithHeader>;
};

const headers = [
  {
    key: "ref_id",
    display: "Ref ID",
  },
  {
    key: "event_name",
    display: "Event Name",
    class: "min-w-64",
  },
  {
    key: "event_date",
    display: "Event Date",
  },
  ":view",
];

export default LinkedEnquiries;
