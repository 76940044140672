import React from "react";
import {LabelledText, WhitePanelWithHeader} from "../../ui";
import {ButtonPrimary} from "../Button";
import {Link} from "react-router-dom";
import moment from 'moment';

let EditInitialResponse = ({initialResponse, rfpId, venueSlug}) => {

    if(!initialResponse || typeof initialResponse === 'undefined')
    {
        return null;
    }
    return (
        <div className="xl:mr-5 xl:w-2/5">
            <WhitePanelWithHeader title={ "Initial Response"} icon="far fa-edit">
                <LabelledText label="Option" text={initialResponse.venue_availability_type.description}/>
                {initialResponse && initialResponse.availability_note && initialResponse.venue_availability_type.requires_notes ?
                    <LabelledText label="Note" text={initialResponse.availability_note}/>
                : null}
                {initialResponse && initialResponse.availability_note && initialResponse.venue_availability_type.requires_dates ?
                    <>
                        <LabelledText label="Date From" text={moment(initialResponse.availability_note.split(',')[0]).format('DD/MM/YYYY')}/>
                        <LabelledText label="Date To" text={moment(initialResponse.availability_note.split(',')[1]).format('DD/MM/YYYY')}/>
                    </>
                    : null}
                <ButtonPrimary
                    Element={Link}
                    to={`/admin/rfp/initial-response?venue=${venueSlug}&rfp=${rfpId}&enquiry=${initialResponse.enquiry_venue_data_id}&redo=true`}
                >
                    Change Initial Response
                </ButtonPrimary>

            </WhitePanelWithHeader>
        </div>
    )
}

export default EditInitialResponse