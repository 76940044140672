import {
  HOTEL_GROUP_SINGLE_ERROR,
  HOTEL_GROUP_SINGLE_REQUEST,
  HOTEL_GROUP_SINGLE_REQUEST_RECEIVED,
  HOTEL_GROUP_SINGLE_UNLOAD,
  HOTEL_GROUP_TYPES_ERROR,
  HOTEL_GROUP_TYPES_REQUEST,
  HOTEL_GROUP_TYPES_REQUEST_RECEIVED,
  HOTEL_GROUP_TYPES_UNLOAD,
  HOTEL_GROUP_VENUES_ERROR,
  HOTEL_GROUP_VENUES_REQUEST,
  HOTEL_GROUP_VENUES_REQUEST_RECEIVED,
  HOTEL_GROUP_VENUES_UNLOAD,
  HOTEL_GROUPS_LIST_ERROR,
  HOTEL_GROUPS_LIST_REQUEST,
  HOTEL_GROUPS_LIST_REQUEST_RECEIVED,
  HOTEL_GROUPS_UNLOAD, VENUE_SINGLE_ERROR,
  VENUE_SINGLE_REQUEST_RECEIVED,
} from "./types";

export const hotelGroups = (state = {
  hotelGroups: {},
  hotelGroupsPage: 1,
  hotelGroupsTotalPages: null,
  hotelGroupsTotalResults: 0,
  hotelGroupPerPageLimit: 15,
  hotelGroupsLastPage: null,
  isFetchingHotelGroups: false,
}, action) => {
  switch (action.type) {
    case HOTEL_GROUPS_LIST_REQUEST:
      return {
        ...state,
        isFetchingHotelGroups: true,
      };
    case HOTEL_GROUPS_LIST_REQUEST_RECEIVED:
      return {
        ...state,
        hotelGroups: action.data?.data,
        hotelGroupsPage: action.data?.meta?.current_page,
        hotelGroupsLastPage: action.data?.meta?.last_page,
        hotelGroupsTotalPages: action.data?.meta?.last_page,
        hotelGroupsTotalResults: action.data?.meta?.total,
        isFetchingHotelGroups: false,
      };
    case HOTEL_GROUPS_LIST_ERROR:
      return {
        ...state,
        hotelGroups: {},
        isFetchingHotelGroups: false,
      };
    case HOTEL_GROUPS_UNLOAD:
      return {
        ...state,
        hotelGroups: {},
        isFetchingHotelGroups: false,
      };
    default:
      return state;
  }
};

export const hotelGroup = (state = {
  hotelGroup: null,
  isFetchingHotelGroup: false,
  hotelGroupError: false,
  hotelGroupErrorMessage: null,
}, action) => {
  switch (action.type) {
    case HOTEL_GROUP_SINGLE_REQUEST:
      return {
        ...state,
        isFetchingHotelGroup: true,
        hotelGroupErrorMessage: null,
        hotelGroupError: false,
      };
    case HOTEL_GROUP_SINGLE_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingHotelGroup: false,
        hotelGroup: action.data.data,
        hotelGroupErrorMessage: null,
        hotelGroupError: false,
      };
    case HOTEL_GROUP_SINGLE_UNLOAD:
      return {
        ...state,
        isFetchingHotelGroup: false,
        hotelGroup: null,
        hotelGroupErrorMessage: null,
        hotelGroupError: false,
      };
    case HOTEL_GROUP_SINGLE_ERROR:
      let errorMessage = action?.error?.response?.data?.message || "Failed";
      return {
        ...state,
        isFetchingHotelGroup: false,
        hotelGroupError: true,
        hotelGroupErrorMessage: errorMessage,
      };
    default:
      return state;
  }
};

export const hotelGroupVenues = (state = {
  hotelGroupVenues: {},
  isFetchingHotelGroupVenues: false,
  hotelGroupVenuesError: false,
  hotelGroupVenueError: null,
}, action) => {
  switch (action.type) {
    case HOTEL_GROUP_VENUES_REQUEST:
      return {
        ...state,
        isFetchingHotelGroupVenues: true,
        hotelGroupVenuesError: false
      };
    case VENUE_SINGLE_REQUEST_RECEIVED:
      let hotelGroupVenues = state.hotelGroupVenues.slice(); // Create a shallow copy of hotelGroupVenues
      if (action.data.hotel_group_id === 0 || action.data.hotel_group_id === null) {
        // If hotel_group_id is 0 or null, filter out the venue with matching id
        hotelGroupVenues = hotelGroupVenues.filter(venue => {
          return venue.id !== action.data.id;
        });
      } else {
        // Check if the venue with the same id already exists
        const existingVenueIndex = hotelGroupVenues.findIndex(venue => venue.id === action.data.id);
        if (existingVenueIndex === -1) {
          // If the venue with the same id does not exist, add it to the list
          hotelGroupVenues.push(action.data);
        } else {
          // If the venue with the same id already exists, replace it with the new data
          hotelGroupVenues[existingVenueIndex] = action.data;
        }
      }
      return {
        ...state,
        hotelGroupVenues: hotelGroupVenues,
        hotelGroupVenuesError: false
      };
    case VENUE_SINGLE_ERROR:
      return {
        ...state,
        hotelGroupVenueError: action?.error?.response?.data?.errors
      }
    case HOTEL_GROUP_VENUES_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingHotelGroupVenues: false,
        hotelGroupVenues: action.data.data,
        hotelGroupVenuesError: false
      };
    case HOTEL_GROUP_VENUES_ERROR:
      return {
        ...state,
        isFetchingHotelGroupVenues: false,
        hotelGroupVenues: {},
      };
    case HOTEL_GROUP_VENUES_UNLOAD:
      return {
        ...state,
        hotelGroupVenues: {},
        isFetchingHotelGroupVenues: false,
        hotelGroupVenuesError: false,
        hotelGroupVenueError: null
      };
    default:
      return state;
  }
};

export const hotelGroupTypes = (state = {
  hotelGroupTypes: {},
  isFetchingHotelGroupTypes: false,
  hotelGroupTypesError: null,
}, action) => {
  switch (action.type) {
    case HOTEL_GROUP_TYPES_REQUEST:
      return {
        ...state,
        isFetchingHotelGroupTypes: true,
      };
    case HOTEL_GROUP_TYPES_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingHotelGroupTypes: false,
        hotelGroupTypes: action?.data?.data,
      };
    case HOTEL_GROUP_TYPES_ERROR:
      return {
        ...state,
        isFetchingHotelGroupTypes: false,
        hotelGroupTypes: {},
      };
    case HOTEL_GROUP_TYPES_UNLOAD:
      return {
        ...state,
        isFetchingHotelGroupTypes: false,
        hotelGroupTypes: {},
      };
    default:
      return state;
  }
};
