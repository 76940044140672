import React from "react";
import { Link } from "react-router-dom";
import { ButtonPrimary } from "Components/Button";
import BackgroundImage from "../Components/General/BackgroundImage";
import * as ROUTES from "../Router/public_routes";

const types = [
  {
    name: "Venues",
    img: "img/landing/venue-feature.jpg",
    imgAlt:
      "Crisp, fresh venue. With white tables and chairs and large projectors",
    text: "Maximise the exposure of your venue through EDGE.",
  },
  {
    name: "Corporate",
    slug: ROUTES.FRONTEND.REGISTER.CORPORATE.replace(':agency', 'edge'),
    img: "img/landing/corporate-feature.jpg",
    imgAlt: "Crowd watching a corporate event",
    text: "Find the perfect venue for your next meeting or corporate event.",
  },
  {
    name: "Agents",
    img: "img/landing/agency-feature.jpg",
    imgAlt: "Globe within an agehcy office",
    text: "Find the perfect venue or meeting space for your client.",
  },
  // {
  //   name: "Other",
  //   img: "img/landing/other-feature.jpg",
  //   imgAlt: "Crowd of attendees at an exhibition",
  //   text: "Individuals, Associations, Charities etc.",
  // },
];

const Register = () => (
  <div className="container">
    <h1 className="my-8">Register as a new user on EDGE</h1>
    <div className="row mb-8">
      {types.map(({ name, img, imgAlt, text, slug }) => (
        <div
          className="col col-w-full md:col-w-1/2 lg:col-w-1/3 mb-6"
          key={name}
        >
          <div className="bg-white h-full text-center flex flex-col justify-between">
            <BackgroundImage className={'bg-center bg-cover re-1/2 relative'} imagePath={img} edits={{
              "resize": {
                "width": 516,
                "height": 271.578,
                "fit": "cover"
              }}}
            alt={imgAlt}
            nonDiv={true}
            width={516}
            height={271.578}
            >
            </BackgroundImage>
            {/*<img src={img} className="w-full" alt={imgAlt} />*/}
            <h2 className="px-3 pt-6">{name}</h2>
            <p className="px-3 py-6">{text}</p>
            <div className="px-3 pb-3">
              <ButtonPrimary
                Element={Link}
                to={slug || `/${name.toLowerCase()}`}
                classes="w-full"
              >
                Join today
              </ButtonPrimary>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Register;
