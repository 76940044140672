import axios from "axios";
import { SubmissionError } from "redux-form";

const getRemovedVenues = async (rfpId,token) => {
    try {
        const response = await axios.get(
            `${window.API}/rfp/${rfpId}/removed-venues`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return { result: response.data.data };
    } catch (error) {

    }
};

const reinstateVenue = async (rfpId,evdId, token) => {
    try {
        const response = await axios.post(
            `${window.API}/rfp/${rfpId}/reinstate-venue`,
            {
                evd: evdId
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return { result: response.data.data };
    } catch (error) {

    }
};

export { getRemovedVenues, reinstateVenue };
