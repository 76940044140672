import React from "react";
import Modal from "../../../../../Components/Modal";
import DownloadPDFButton from "./DownloadPDFButton";
import { ButtonSpan } from "../../../../../Components/Button";

let ConfirmationExportsModal = (props) => {
  return (
    <>
      <Modal>
        <div className="modal">
          <div
            className="modal-inner"
            style={{
              width: 600,
              maxWidth: "90%",
            }}
          >
            <ButtonSpan
              className="m-3 absolute pin-t pin-r z-10"
              onClick={props.closeModal}
            >
              <i className="fas fa-times-circle fa-2x ml-3" />
            </ButtonSpan>
            <h2 className="mb-3 text-3xl">Export Confirmation</h2>
            <div className="w-full mb-8 mt-8">
              <div className={"flex flex-col"}>
                <div className={"mt-5"}>
                  <DownloadPDFButton
                    enquiry={props.enquiry}
                    enquiryVenueData={props.enquiryVenueData}
                    dark={!!props.exportNet}
                    includeVat={false}
                    highlight={props.exportNet}
                    text={"Download Net Confirmation (PDF)"}
                  />
                </div>
                <div className="mt-5">
                  <DownloadPDFButton
                    enquiry={props.enquiry}
                    enquiryVenueData={props.enquiryVenueData}
                    dark={!props.exportNet}
                    highlight={!props.exportNet}
                    includeVat={false}
                    text={"Download Gross Confirmation (PDF)"}
                  />
                </div>
                {(props.agency || props.hasAgency || props.hasInternal) && props.enquiryVenueData && props.proposalData.commission_items.length > 0 ?
                  <>
                    <div className={"mt-5"}>
                      <DownloadPDFButton
                        enquiry={props.enquiry}
                        enquiryVenueData={props.enquiryVenueData}
                        dark={true}
                        disableCommission={true}
                        includeVat={false}
                        highlight={props.exportNet}
                        text={"Download Net Confirmation Without Commission (PDF)"}
                      />
                    </div>
                    <div className="mt-5">
                      <DownloadPDFButton
                        enquiry={props.enquiry}
                        enquiryVenueData={props.enquiryVenueData}
                        dark={true}
                        disableCommission={true}
                        includeVat={true}
                        highlight={!props.exportNet}
                        text={"Download Gross Confirmation Without Commission (PDF)"}
                      />
                    </div>
                  </>
                  : null}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationExportsModal;

