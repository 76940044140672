import classNames from "classnames";
import uniq from "lodash/uniq";
import { array, object, oneOfType, string } from "prop-types";
import React, { useEffect } from "react";
import Error from "./Error";
import Label from "./Label";

const getOptionValue = option =>
  typeof option === "string" ? option : option.value;
const getOptionText = option =>
  typeof option === "string" ? option : option.text;

// This is to fix an issue where the same value could appear multiple times
// The toggleOption code has been changed so it will not happen again
// This is here to fix old duplicated data
const deDuplicateValues = ({ value: values, onChange }) => {
  if (!Array.isArray(values)) return;

  const uniqValues = uniq(values);

  if (values.length !== uniqValues.length) {
    onChange(uniqValues);
  }
};

const Checkbox = ({
  meta: { touched, error, warning },
  input,
  label,
  wrapperClassName,
  options,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => deDuplicateValues(input), [input.value]);

  const valueArray = Array.isArray(input.value) ? input.value : [];

  const toggleOption = (optionValue, isSelected) => {
    input.onChange(
      isSelected
        ? valueArray.filter(item => item !== optionValue)
        : [...valueArray, optionValue]
    );
  };

  return (
    <div className={wrapperClassName || "w-full mb-5"}>
      <Label name={input.name} text={label} />
      <div className="flex flex-wrap w-full">
        {options.map(option => {
          const value = getOptionValue(option);
          const text = getOptionText(option);
          const isSelected = valueArray.includes(value);

          const toggle = () => toggleOption(value, isSelected);

          return (
            <label
              key={value}
              role="button"
              tabIndex="0"
              className="text-xs mr-5 flex items-center inline-block outline-none border border-transparent focus:border-primary"
              onClick={toggle}
              onKeyPress={toggle}
              style={{
                height: 28,
              }}
            >
              <i
                className={classNames("far mr-2", {
                  "fa-check-square": isSelected,
                  "fa-square": !isSelected,
                })}
              />
              {text}
            </label>
          );
        })}
      </div>
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
};

Checkbox.propTypes = {
  options: array,
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]).isRequired,
  wrapperClassName: string,
  inputClassName: string,
};

Checkbox.defaultProps = {
  options: [],
};

export default Checkbox;
