// import React from "react";
import * as Sentry from "@sentry/browser";
import { connect } from "react-redux";
import { user } from "../modules/auth";

const SentryAppendDetails = ({ user }) => {
  const sentryUser = user
    ? {
        id: user.id,
        username: `${user.first_name} ${user.surname}`,
        email: user.email,
        industry: user.industry,
        userType: user.user_type && user.user_type.name,
      }
    : {};

  Sentry.configureScope(scope => scope.setUser(sentryUser));

  return null;
};

export default connect(state => ({ user: user(state) }))(SentryAppendDetails);
