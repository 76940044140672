import axios from 'axios';
import {
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_REQUEST,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_UNLOAD,
  CORPORATE_ACCOUNT_BOOKING_DESK_RECEIVED,
  CORPORATE_ACCOUNT_BOOKING_DESK_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_BOOKING_DESK_REQUEST,
  CORPORATE_ACCOUNT_BOOKING_DESK_UPDATED,
  CORPORATE_ACCOUNT_BOOKING_DESK_ERROR,
  CORPORATE_ACCOUNT_BOOKING_DESK_UNLOAD,
  CORPORATE_ACCOUNT_BOOKING_DESK_CREATED,
  CORPORATE_ACCOUNT_BOOKING_DESK_CREATED_ERROR,
  CORPORATE_ACCOUNT_BOOKING_DESK_DELETED,
  AGENCY_BOOKING_DESKS_LIST_REQUEST,
  AGENCY_BOOKING_DESKS_LIST_RECEIVED,
  AGENCY_BOOKING_DESKS_LIST_RECEIVED_ERROR,
  AGENCY_BOOKING_DESK_LIST_UNLOAD,
  CORPORATE_BOOKING_DESK_UPDATED,
  CORPORATE_BOOKING_DESK_UPDATED_ERROR,
  AGENCY_BOOKING_DESK_CREATED,
  AGENCY_BOOKING_DESK_CREATED_ERROR, AGENCY_BOOKING_DESK_ERROR, AGENCY_BOOKING_DESK_DELETED,
} from "../reducers/types";
import * as ROUTES from '../../Router/corporate_routes';
import handleErrors from "./utils";


export const agencyBookingDesksListRequest = () => ({
    type: AGENCY_BOOKING_DESKS_LIST_REQUEST
});

export const agencyBookingDesksListReceived = data => ({
  type: AGENCY_BOOKING_DESKS_LIST_RECEIVED,
  data
});

export const agencyBookingDesksListReceivedError = error => ({
  type: AGENCY_BOOKING_DESKS_LIST_RECEIVED_ERROR,
  error
});

export const unloadAgencyBookingDesks = () => ({
  type: AGENCY_BOOKING_DESK_LIST_UNLOAD
})

export const corporateBookingDesksListRequest = () => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_LIST_REQUEST
});

export const corporateBookingDesksListReceived = data => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED,
  data
});

export const corporateBookingDesksListReceivedError = error => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED_ERROR,
  error
});

export const corporateAccountBookingDesksUnload = () => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_LIST_UNLOAD
});

export const corporateAccountBookingDeskUnload = () => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_UNLOAD
});

export const corporateBookingDeskRequest = () => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_REQUEST
});

export const corporateBookingDeskReceived = data => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_RECEIVED,
  data
});

export const corporateBookingDeskReceivedError = error => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_RECEIVED_ERROR,
  error
});

export const corporateAccountBookingDeskUpdated = data => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_UPDATED,
  data
});

export const corporateAccountBookingDeskError = error => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_ERROR,
  error
});

export const corporateAccountBookingDeskCreated = data => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_CREATED,
  data
});

export const corporateAccountBookingDeskCreatedError = error => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_CREATED_ERROR,
  error
});

export const corporateAccountBookingDeskDeleted = data => ({
  type: CORPORATE_ACCOUNT_BOOKING_DESK_DELETED,
  data
});

export const agencyBookingDeskCreated = data => ({
  type: AGENCY_BOOKING_DESK_CREATED,
  data
});

export const agencyBookingDeskCreatedError = error => ({
  type: AGENCY_BOOKING_DESK_CREATED_ERROR,
  error
});

export const agencyBookingDeskDeleted = data => ({
  type: AGENCY_BOOKING_DESK_DELETED,
  data
});

export const agencyBookingDeskError = error => ({
  type: AGENCY_BOOKING_DESK_ERROR,
  error
});

export const corporateBookingDeskUpdated = data => ({
  type: CORPORATE_BOOKING_DESK_UPDATED,
  data
});
export const corporateBookingDeskError = error => ({
  type: CORPORATE_BOOKING_DESK_UPDATED_ERROR,
  error
});


export const fetchBookingDesks = (slug, token) => {
  return (dispatch) => {
    dispatch(agencyBookingDesksListRequest());
    return axios.get(window.API + ROUTES.API.AGENCY.BOOKING_DESKS.LIST.replace(':slug', slug), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(agencyBookingDesksListReceived(response.data)))
      .catch(error => dispatch(agencyBookingDesksListReceivedError(error)))
  }
}

export const fetchCorporateBookingDesks = (slug, token) => {
  return (dispatch) => {
    dispatch(corporateBookingDesksListRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.BOOKING_DESKS.LIST_CORPORATE.replace(':slug', slug), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(corporateBookingDesksListReceived(response.data)))
      .catch(error => dispatch(corporateBookingDesksListReceivedError(error)))
  }
}

export const fetchBookingDesk = (slug, id, token) => {
  return (dispatch) => {
    dispatch(corporateBookingDeskRequest());
    return axios.get(window.API + ROUTES.API.CORPORATE.BOOKING_DESKS.SINGLE.replace(':slug', slug).replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(corporateBookingDeskReceived(response.data)))
      .catch(error => dispatch(corporateBookingDeskReceivedError(error)));
  }
}

export const editBookingDesk = (slug, id, formValues, token) => {
  return (dispatch) => {
    dispatch(corporateBookingDeskRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.BOOKING_DESKS.SINGLE.replace(':slug', slug).replace(':id', id), formValues, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(corporateAccountBookingDeskUpdated(response.data))
      window.location.reload()
    })
      .catch(error => {
        dispatch(corporateAccountBookingDeskError(error))
        handleErrors(error)
      })
  }
}

export const editCorporateBookingDesk = (slug, id, formValues, token) => {
  return (dispatch) => {
    dispatch(corporateBookingDeskRequest());
    return axios.post(window.API + ROUTES.API.CORPORATE.BOOKING_DESKS.EDIT_CORPORATE.replace(':slug', slug).replace(':id', id), formValues, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(corporateBookingDeskUpdated(response.data))
      window.location.reload();
    }).catch(error => {
      dispatch(corporateBookingDeskError(error))
      handleErrors(error)
    })
  }
}

export const deleteBookingDesk = (slug, id, formValues, token) => {
  return (dispatch) => {
    dispatch(corporateBookingDeskRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.BOOKING_DESKS.SINGLE.replace(':slug', slug).replace(':id', id), formValues, {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => dispatch(agencyBookingDeskDeleted(response)))
      .catch(error => {
        dispatch(agencyBookingDeskError(error))
      })
  }
}

export const createBookingDesk = (slug, formValues, token) => {
  return (dispatch) => {
    dispatch(corporateBookingDeskRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.BOOKING_DESKS.CREATE.replace(':slug', slug), formValues, {
      headers: {Authorization: `Bearer ${token}`},
      "Content-Type": "application/json"
    }).then(response => dispatch(agencyBookingDeskCreated(response.data)))
      .catch(error => {
        dispatch(agencyBookingDeskCreatedError(error))
        handleErrors(error)
      })
  }
}