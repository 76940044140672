import React from "react";

let AdminList = ({title, tableHeadings, tableList, actions}) =>{

    return (
        <div className="pt-10 max-w-full" >
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white p-5">
                <div className="mb-3 md:flex md:flex-row md:justify-between md:items-center">
                    <h1 className="mb-5">{title}</h1>
                    {actions}
                </div>
                <table className="edge-table">
                    <thead>
                    {tableHeadings}
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {tableList}
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default AdminList