import React from "react";
import { Link } from "react-router-dom";
import { ButtonBrand } from "Components/Button";

const VenueCard = ({ venue }) => {
  let address = venue.town;
  if (address && venue.county) {
    address += `, ${venue.county}`;
  } else if (venue.county) {
    address = venue.county;
  }

  const image =
      venue.image ||
      venue.featured_image_id ||
      (venue.images && venue.images[0]) ||
      {}.id;
  return (
      <div key={venue.id} className="mb-8" style={{ maxWidth: 455 }}>
        <h3 className="bg-black text-white p-3 uppercase font-normal font-body pb-8 mb-2 h-24">
          {venue.name}
        </h3>
        <div
            className="re-16/9 w-full bg-no-repeat bg-cover bg-center"
            style={
              image
                  ? {
                    backgroundImage: `url(${window.API}/venues/${venue.slug}/images/${image})`,
                  }
                  : null
            }
        />
        <div className="flex justify-between p-3 font-normal text-xl mb-2">
          <div>
            <i className="mr-2 fas fa-users" />
            {venue.capacity}
          </div>
          <div>
            <i className="mr-2 fas fa-bed" />
            {venue.bedrooms}
          </div>
          <div>
            <i className="mr-2 fas fa-handshake" />
            {venue.meeting_rooms}
          </div>
        </div>
        {address && <p className="font-normal">{address}</p>}
        <ButtonBrand
            Element={Link}
            to={`/venue/${venue.slug}`}
            target="_blank"
            classes="w-full"
        >
          VIEW
        </ButtonBrand>
      </div>
  );
};

export default VenueCard;
