import React from "react";
import { func, array, string } from "prop-types";
import classNames from "classnames";
import { orderBy } from "lodash/fp";
import { Link } from "react-router-dom";

const getHighlights = (text, query) => {
  const testText = text.toLowerCase();
  const queryWords = query
    .split(/\W+/)
    .filter(Boolean)
    .map(word => word.toLowerCase());

  const highlights = [];
  queryWords.forEach(word => {
    let start = 0;
    let matchIndex;
    while ((matchIndex = testText.indexOf(word, start)) !== -1) {
      highlights.push([matchIndex, matchIndex + word.length]);
      start = matchIndex + word.length;
    }
  });

  const orderedHighlights = orderBy(["0", "1"], ["asc", "asc"], highlights);

  const mergedHighlights = [];
  let last;
  orderedHighlights.forEach(highlight => {
    if (!last || highlight[0] > last[1]) {
      mergedHighlights.push((last = highlight));
    } else if (highlight[1] > last[1]) {
      last[1] = highlight[1];
    }
  });

  return mergedHighlights;
};

class LiveSearchResponse extends React.Component {
  static propTypes = {
    results: array,
    message: string,
    getText: func,
    query: string,
    select: func,
  };

  resultText = text => {
    const { query = "" } = this.props;
    const highlights = getHighlights(text, query);

    let lastEnd = 0;
    const result = [];

    highlights.forEach(highlight => {
      const [start, end] = highlight;
      result.push({ text: text.slice(lastEnd, start), highlight: false });
      result.push({ text: text.slice(start, end), highlight: true });
      lastEnd = end;
    });
    result.push({ text: text.slice(lastEnd), highlight: false });

    return result;
  };

  render() {
    const { results, message, getText, getLinkTo, select } = this.props;
    if (!results && !message) return null;

    const ItemType = getLinkTo ? Link : "div";
    const _getLinkTo = getLinkTo ? getLinkTo : () => {};

    return (
      <div
        className="z-30 absolute pin-l w-full bg-white border border-grey shadow px-2"
        style={{ top: "100%" }}
      >
        {message ? (
          <div className="text-light-text font-normal text-sm hover:text-brand border-grey py-2">
            {message}
          </div>
        ) : (
          <div>
            {results.map((result, i) => (
              <ItemType
                to={_getLinkTo(result)}
                key={getText(result)}
                // dangerouslySetInnerHTML={this.resultText2(getText(result))}
                className={classNames(
                  "pointer live-search-result block text-light-text font-normal text-sm hover:text-brand border-grey py-2 flex",
                  {
                    "border-b": i < results.length - 1,
                  }
                )}
                onClick={() => {
                  select(result);
                }}
              >
                <div>
                  {this.resultText(getText(result)).map((part, i) => (
                    <span
                      key={i}
                      className={part.highlight ? "font-bold text-black" : ""}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>

                {result.hidden_at && (
                  <div className="text-right flex-1">
                    <i className="fal fa-eye-slash text-warning" />
                  </div>
                )}
              </ItemType>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default LiveSearchResponse;
