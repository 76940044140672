/**
 
Wordpress category IDs

Inspiration: [4, 6, 7, 8]
  Analysis 4
  Case studies 6
  Expert insights 7
  Interviews 8


Community: [10, 11]
  Blog 10
  Video 11


Voice: [2, 12, 13, 14, 15, 16, 17]
  Trends 12
  Events 13
  Destinations 2
  Rate comparison 14
  New hotels 15
  New groups 16
  Site visits 17
*/

import get from "lodash/fp/get";

export const CATEGORY_GROUPS = [
  { name: "inspiration", ids: [4, 6, 7, 8] },
  { name: "community", ids: [10, 11] },
  { name: "voice", ids: [2, 12, 13, 14, 15, 16, 17] },
  { name: "corporateTeamUpdates", ids: [1092] },
];

export const CATEGORY_GROUP_IDS = {
  theWord: [4, 10, 11, 6, 2, 13, 7, 8, 16, 15, 14, 17, 12 ],
  inspiration: [4, 6, 7, 8],
  community: [10, 11],
  voice: [2, 12, 13, 14, 15, 16, 17],
  expertInsights: [7],
  corporateTeamUpdates: [1092]
};

export const onlyEdgeInsightsFilter = posts =>
  posts.filter(post => !!findCategoryGroup(post));

// Should be moved to a more general place or replaced with lodash sortBy
export const sortBy = (items, key, desc = false) =>
  desc
    ? [...items].sort((a, b) => (a[key] > b[key] ? -1 : 1))
    : [...items].sort((a, b) => (a[key] < b[key] ? -1 : 1));

export const findCategoryGroup = post =>
  CATEGORY_GROUPS.find(group =>
    post.categories.some(catId => group.ids.includes(catId))
  );

export const group = posts => {
  const groups = {};

  Object.keys(CATEGORY_GROUP_IDS).forEach(groupKey => {
    const ids = CATEGORY_GROUP_IDS[groupKey];
    groups[groupKey] = posts.filter(post =>
      post.categories.some(catId => ids.includes(catId))
    );
  });

  return groups;
};

const regSrc = /src=(?:"([^"]+)"|'([^']+)')/i;

// Find the first <img> tag in an HTML string and return the url from src=""
export const imageUrlFromHtml = html => {
  const imgStart = html.indexOf("<img");
  if (imgStart === -1) return;
  const imgEnd = html.indexOf("/>", imgStart);
  if (imgEnd === -1) return;

  const src = regSrc.exec(html.slice(imgStart, imgEnd));

  if (!src) return;

  return src[1] || src[2];
};

export const getFeaturedMedia = get(["_embedded", "wp:featuredmedia", "0"]);
const getThumbnailUrl = get([
  "media_details",
  "sizes",
  "thumbnail",
  "source_url",
]);
const getSourceUrl = get("source_url");

export const getPostImg = post => {
  const featuredMedia = getFeaturedMedia(post);

  if (featuredMedia) {
    const sourceUrl =
      getThumbnailUrl(featuredMedia) || getSourceUrl(featuredMedia);
    if (sourceUrl) return sourceUrl;
  }

  const content = post.content.rendered;
  return imageUrlFromHtml(content);
};

export const getPostDetails = post => {
  const categoryGroup = findCategoryGroup(post);

  return {
    id: post.id,
    categoryGroup,
    imageUrl: getPostImg(post),
    excerpt: post.acf.a_strapline,
    title: post.title.rendered,
    content: post.content.rendered,
    linkTo: `/${categoryGroup ? categoryGroup.name + "/" : ""}${post.slug}`,
  };
};

/*
User Types in edge

Corporate
Agency
Individual
Association/charity
Hotel
Other

User Types in cms

Corporate
Association
Agency
Hotels & Venues
Public
Trinity

*/

const toWpUserType = {
  Corporate: "Corporate",
  Agency: "Agency",
  "Association/charity": "Association",
  Hotel: "Hotels & Venues",
};

export const canViewPost = (post, userType, isInternal = false) => {
  const visibilityIndustryTypes =
    (post.acf && post.acf.visibilityindustry_type) || false;

  if (!visibilityIndustryTypes) return true;

  if (isInternal && visibilityIndustryTypes.includes("Trinity")) return true;

  const cmsUserType = toWpUserType[userType] || "Public";
  return visibilityIndustryTypes.includes(cmsUserType);
};

export const filterPostsByUserType = ({ posts, userType, isInternal }) => {
  return posts.filter(post => canViewPost(post, userType, isInternal));
};
