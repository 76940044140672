import { set } from "lodash";

const mapServerErrors = (errors, message = "Submit failed") => {
  const response = {
    _error: message,
  };

  try {
    Object.keys(errors.response.data.errors).forEach(field => {
      const errorMessage = errors.response.data.errors[field]
        .join(" ")
        .replace(/(\.[0-9]+\.)/g, " ")
        .replace(/_/g, " ");
      set(response, field, errorMessage);
    });
  } catch {
    // it wasn't a validation error
  }

  return response;
};

export default mapServerErrors;
