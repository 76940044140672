import React from "react";
import classNames from "classnames";
import { string, object, number, oneOfType } from "prop-types";
import Label from "./Label";
import Error from "./Error";

const Textarea = ({
  meta: { touched, error, warning },
  input,
  label,
  type,
  rows,
  placeholder,
  wrapperClassName,
  textareaClassName,
}) => {
  return (
    <div className={wrapperClassName || "mb-5"}>
      {label && <Label name={input.name} text={label} />}
      <textarea
        {...input}
        placeholder={placeholder}
        type={type}
        rows={rows}
        className={
          textareaClassName ||
          classNames([
            "leading-none",
            "p-2",
            "w-full",
            "outline-none",
            "font-normal",
            "text-black",
            "text-xs",
            "border",
            "border-grey-md",
            "w-full",
            "focus:border-primary",
            "bg-white",
            "border-black",
          ])
        }
      />
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
};

Textarea.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]),
  type: string,
  placeholder: string,
  wrapperClassName: string,
  textareaClassName: string,
  rows: number,
};

export default Textarea;
