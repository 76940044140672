import React from "react";
import moment from "moment";
import Modal from "Components/Modal";
import { ButtonSpan } from "Components/Button";
import Table from "Components/Table";
import { currencyFormatter } from "../../utils";

const sections = [
  { name: "Conference Packages", step_id: 2 },
  { name: "Event Spaces", step_id: 3 },
  { name: "Equipment", step_id: 4 },
  { name: "Catering", step_id: 5 },
  { name: "Accommodation", step_id: 6 },
  { name: "Total", step_id: 9 },
];

const title = type => {
  switch (type) {
    case "availability":
      return "Availability";
    case "price":
      return "Price Breakdown";
    default:
      return "";
  }
};

const content = ({ type, data, availability, availabilityNote }) => {
  let currency = data.currency;
  let taxHeader = `Total Exc ${currency.currency_code === 'GBP' ? 'VAT' : 'Tax'}`
  switch (type) {
    case "availability":
      return (
        <div>
          <p>{availability.text}</p>
          {availabilityNote && (
            <p>
              Alternative date:{" "}
              {moment(availabilityNote).format("dddd, MMMM Do YYYY")}
            </p>
          )}
        </div>
      );
    case "price":
      return (
        <div>
          <Table
            headers={[
              "Section",
              taxHeader,
              "Reduced From",
              "Cost Saving",
            ]}
            tableClass={'rfp-price-alignment'}
            data={data.proposal_data.summary.summary_items
              .filter(item =>
                sections.map(item => item.step_id).includes(item.step_id)
              )
              .sort((a, b) => a.step_id - b.step_id)
              .map(item => {
                let savingPrice = "";
                if (parseFloat(item.percentage_saving)) {
                  savingPrice = (
                    parseFloat(item.reduced_from_exc_vat) -
                    parseFloat(item.total_exc_vat)
                  ).toFixed(2);
                }
                return {
                  Section: sections.find(
                    section => section.step_id === item.step_id
                  ).name,
                  [taxHeader]: `${currencyFormatter(currency.currency_code).format(item.total_exc_vat)}`,
                  "Reduced From": `${currencyFormatter(currency.currency_code).format(item.reduced_from_exc_vat)}`,
                  "Cost Saving": `${item.percentage_saving}%${
                    savingPrice ? "   (" + currencyFormatter(currency.currency_code).format(savingPrice) + ")" : ""
                  }`,
                };
              })}
          />
          <p className="text-right mt-3">
            <b>
              Total excluding {currency.currency_code === 'GBP' ? `VAT` : `Tax`}:
              {" "}{
                currencyFormatter(currency.currency_code).format(data.proposal_data.summary.summary_items.find(
                  item => item.step_id === 9
                ).total_exc_vat)
              }
            </b>
          </p>
          <p className="text-right mt-3">
            <b>
              Total including {currency.currency_code === 'GBP' ? `VAT` : `Tax`}:
              {" "}{
                currencyFormatter(currency.currency_code).format(data.proposal_data.summary.summary_items.find(
                  item => item.step_id === 9
                ).total_inc_vat)
              }
            </b>
          </p>
        </div>
      );
    default:
      return "";
  }
};

const RfpProposalDetailModal = ({
  availability,
  availabilityNote,
  type,
  data,
  onClose,
  venue,
}) => {
  return (
    <Modal>
      <div className="modal">
        <div
          className="modal-inner"
          style={{
            width: 1200,
            maxWidth: "90%",
          }}
        >
          <ButtonSpan
            className="m-3 absolute pin-t pin-r z-10"
            onClick={onClose}
          >
            <i className="fas fa-times-circle fa-2x ml-3" />
          </ButtonSpan>
          <div>
            <h2 className="mb-3 text-3xl">{title(type)}</h2>
            {content({ type, data, availability, availabilityNote })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RfpProposalDetailModal;
