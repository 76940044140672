import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Field, change } from "redux-form";
import { useParams } from "react-router-dom";
import { Date } from "Components/Form";
import { ButtonPrimary } from "Components/Button";
import Loading from "Components/Loading";
import { required, isDate, notInPast, isBefore } from "modules/validation";
import useService from "hooks/useService";
import { requestSiteVisit } from "services/rfpService";
import RfpProposalActionModal from "./RfpProposalActionModal";

const v = {
  date: [required(), isDate, notInPast, isBefore("event_date")],
};

const RequestSiteVisitModal = ({ eventDate, onClose, venue }) => {
  const dispatch = useDispatch();
  const [submitData, setSubmitData] = useState(null);
  const { id: rfpId } = useParams();
  const { loading: submitting, data: complete, error } = useService({
    initialLoading: false,
    service: requestSiteVisit,
    trigger: submitData,
    params: submitData,
  });

  const handleConfirm = values => {
    setSubmitData({
      venue_id: venue.id,
      date_requested: values.date_requested,
      enquiry_id: rfpId,
    });
  };

  useEffect(() => {
    dispatch(change("proposal-request-site-visit", "event_date", eventDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RfpProposalActionModal
      form="proposal-request-site-visit"
      title="Request Site Visit"
      onConfirm={handleConfirm}
      onClose={onClose}
      venue={venue}
      content={
        <div className="relative h-full pb-16">
          {complete ? (
            <p className="text-xl mb-5">
              Thank you.
              <br />
              Your request has been submitted.
            </p>
          ) : (
            <>
              <p className="text-xl mb-5">
                Please let us know when you would like to visit:
              </p>
              <div className="flex">
                <Field
                  label="Date"
                  name="date_requested"
                  size="lg"
                  component={Date}
                  showTimeSelect
                  validate={v.date}
                />
              </div>
              {error && (
                <p className="my-5 text-warning">
                  Sorry, there was a problem submitting your request
                </p>
              )}
              <div className="absolute pin-b pin-r">
                <ButtonPrimary
                  type="submit"
                  classes="px-5"
                  style={{ height: 57, lineHeight: "57px" }}
                >
                  {submitting ? <Loading inline /> : "Request Site Visit"}
                </ButtonPrimary>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default RequestSiteVisitModal;
