import { format } from "date-fns";
import { countBy, max, values } from "lodash";
import {
  createDateRange,
  dateRangeFilter,
} from "../../../pages/admin/Analytics/date-utils";
import { searchImpressions, venueInteractions, venuePageViews } from "./utils";

const toActivityHours = (records, start, end) => {
  const recordsInDateRange = dateRangeFilter(
    records,
    createDateRange(start, end),
    "day"
  );

  const countByHour = countBy(
    recordsInDateRange,
    ({ day, hour }) => `${format(day, "ddd")} ${hour}:00`
  );

  return { countByHour, max: max(values(countByHour)) };
};

export const hourlyVenueActivity = (id, start, end) => state => {
  const pageViews = venuePageViews(id)(state) || [];
  const interactions = venueInteractions(id)(state) || [];

  const activities = [...pageViews, ...interactions];

  return toActivityHours(activities, start, end);
};

export const hourlySearchActivity = (id, start, end) => state => {
  const impressions = searchImpressions(id)(state) || [];

  return toActivityHours(impressions, start, end);
};

export const hourlyEngagementActivity = (id, start, end) => state => {
  const interactions = venueInteractions(id)(state) || [];

  return toActivityHours(interactions, start, end);
};
