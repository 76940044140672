import React from "react";
import {
  string,
  func,
  object,
  array,
  bool,
  arrayOf,
  shape,
  number,
} from "prop-types";
import { ButtonSpan, ButtonPrimary } from "Components/Button";
import { ApiForm, Succeeded, Failed } from "Components/Form";
import Loading from "Components/Loading";
import Modal from "Components/Modal";
import ArticleFields from "./ArticleFields";

class Form extends React.Component {
  static propTypes = {
    formId: string.isRequired,
    url: string.isRequired,
    closeModal: func.isRequired,
    closeModalRefresh: func.isRequired,
    renderAdditionalFields: func,
    additionalFields: array,
    data: object,
    params: object,
    paid: bool,
    premium: bool,
    venueOptions: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
      })
    ),
  };

  fields = () => {
    const fields = [
      "title",
      "venues",
      "content",
      "image",
      "image_id",
      "id",
      "files",
    ];
    return this.props.additionalFields
      ? this.props.additionalFields.concat(fields)
      : fields;
  };

  render() {
    return (
      <Modal>
        <div className="modal">
          <div
            className="modal-inner"
            style={{
              width: this.props.width || 1200,
              maxWidth: "90%",
            }}
          >
            <ButtonSpan
              className="m-3 absolute pin-t pin-r"
              onClick={this.props.closeModal}
            >
              Close
              <i className="fas fa-times ml-3" />
            </ButtonSpan>
            <ApiForm
              create={!this.props.data}
              data={this.props.data}
              form={this.props.formId}
              url={this.props.url}
              params={this.props.params}
              fields={this.fields()}
              pluckTheIds={["venues"]}
              appendIdToPatch
              handleResponse={this.props.closeModalRefresh}
              render={(submitting, failed, succeeded) => (
                <React.Fragment>
                  <ArticleFields
                    formId={this.props.formId}
                    paid={this.props.paid}
                    premium={this.props.premium}
                    venueOptions={this.props.venueOptions}
                  />
                  {this.props.renderAdditionalFields &&
                    this.props.renderAdditionalFields()}
                  <div className="text-right">
                    <ButtonPrimary type="submit">
                      {submitting ? <Loading inline /> : "Save"}
                    </ButtonPrimary>
                  </div>
                  {succeeded ? <Succeeded /> : null}
                  {failed ? <Failed /> : null}
                </React.Fragment>
              )}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default Form;
