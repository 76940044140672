import React from "react";
import { connect } from "react-redux";
import Breadcrumbs from "Components/Breadcrumbs";
import TrinityNews from "./TrinityNews";
import { CATEGORY_GROUP_IDS } from "modules/cms";
import { isAgency } from "../../modules/auth";

const Inspiration = ({ isAgency }) => (
  <div className="container bg-white mt-4 p-5">
    <Breadcrumbs
      items={[
        { name: "Home", to: "/" },
        { name: (isAgency ? "Agency " : "") + "Inspiration" },
      ]}
    />
    <TrinityNews categories={CATEGORY_GROUP_IDS.inspiration} />
  </div>
);

export default connect(state => ({ isAgency: isAgency(state) }))(Inspiration);
