import {
    CORPORATE_ACCOUNT_LIST_RECEIVED,
    CORPORATE_ACCOUNT_LIST_REQUEST,
    CORPORATE_ACCOUNT_LIST_RECEIVED_ERROR,
    CORPORATE_ACCOUNT_LIST_UNLOAD,
    CREATE_CORPORATE_ACCOUNT,
    CREATE_CORPORATE_ACCOUNT_ERROR,
    EDIT_CORPORATE_ACCOUNT,
    EDIT_CORPORATE_ACCOUNT_ERROR,
    CORPORATE_ACCOUNT_REQUEST,
    CORPORATE_ACCOUNT_RECEIVED_ERROR,
    CORPORATE_ACCOUNT_RECEIVED,
    CORPORATE_ACCOUNT_UNLOAD,
    CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY,
    CREATE_CORPORATE_ACCOUNT_DOCUMENT,
    CREATE_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
    CORPORATE_ACCOUNT_LIST_USERS_REQUEST,
    CORPORATE_ACCOUNT_LIST_USERS_RECEIVED,
    CORPORATE_ACCOUNT_LIST_USERS_ERROR,
    UNLOAD_CORPORATE_ACCOUNT_USERS,
    CORPORATE_ACCOUNT_PERMISSIONS_REQUEST,
    CORPORATE_ACCOUNT_PERMISSIONS_RECEIVED,
    CORPORATE_ACCOUNT_PERMISSIONS_RECEIVED_ERROR,
    UNLOAD_CORPORATE_ACCOUNT_PERMISSIONS,
} from "./types";
import { unloadCorporateAccountPermission } from "../actions";

export const corporateAccountList = (state = {
    corporateAccounts: {},
    isFetching: false,
}, action) => {
    switch (action.type) {
        case CORPORATE_ACCOUNT_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case CORPORATE_ACCOUNT_LIST_RECEIVED:
            return {
                ...state,
                isFetching: false,
                corporateAccounts: action
            }
        case CORPORATE_ACCOUNT_LIST_RECEIVED_ERROR:
            return {
                ...state,
                isFetching: false,
            }
        case CORPORATE_ACCOUNT_LIST_UNLOAD:
            return {
                ...state,
                isFetching: false,
                corporateAccounts: {},
            }
        default:
            return state;
    }
}

export const corporateAccountUsers = (state = {
    corporateAccountUsers: [],
    isFetchingUsers: false,
    error: false
}, action) => {
    switch (action.type) {
        case CORPORATE_ACCOUNT_LIST_USERS_REQUEST:
            return {
                ...state,
                isFetchingUsers: true
            }
        case CORPORATE_ACCOUNT_LIST_USERS_RECEIVED:
            return {
                ...state,
                isFetchingUsers: false,
                corporateAccountUsers: action.data.data
            }
        case CORPORATE_ACCOUNT_LIST_USERS_ERROR:
            return {
                ...state,
                isFetchingUsers: false,
                corporateAccountUsers: [],
                error: true,
            }
        case UNLOAD_CORPORATE_ACCOUNT_USERS:
            return {
                ...state,
                corporateAccountUsers: [],
                error: false,
                isFetchingUsers: false,
            }
        default: return state;
    }
}

export const corporateAccount = (state = {
    corporateAccount: {},
    isFetching: false,
    error: false,
    accessDenied: false,
}, action) => {
    switch (action.type) {
        case CORPORATE_ACCOUNT_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case CORPORATE_ACCOUNT_RECEIVED:
            return {
                ...state,
                isFetching: false,
                corporateAccount: action.data,
                accessDenied: false,
            }
        case CORPORATE_ACCOUNT_RECEIVED_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
                accessDenied: action.data.response.status === 401
            }
        case CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY:
            return {
                ...state,
                isFetching: false,
                corporateAccount: action.data.data,
                accessDenied: false,
            }
        case CORPORATE_ACCOUNT_UNLOAD:
            return {
                corporateAccount: {},
                isFetching: false,
                error: false,
            }
        case CREATE_CORPORATE_ACCOUNT:
            return {
                ...state,
                isFetching: false,
                error: false,
                corporateAccount: action.data.data,
                errorData: null,
                accessDenied: false,
            }
        case CREATE_CORPORATE_ACCOUNT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorData: action.error
            }
        case EDIT_CORPORATE_ACCOUNT:
            return {
                ...state,
                isFetching: false,
                error: false,
                corporateAccount: action.data.data,
                errorData: null,
                accessDenied: false,
            }
        case EDIT_CORPORATE_ACCOUNT_ERROR:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorData: action.error
            }
        default:
            return state;
    }
}

export const corporateAccountPermissions = (state = {
    corporateAccountPermissions: [],
    isFetchingPermissions: false
}, action) => {
    switch (action.type) {
        case CORPORATE_ACCOUNT_PERMISSIONS_REQUEST:
            return {
                ...state,
                isFetchingPermissions: true,
                corporateAccountPermission: [],
            }
        case CORPORATE_ACCOUNT_PERMISSIONS_RECEIVED:
            return {
                ...state,
                isFetchingPermissions: false,
                corporateAccountPermissions: action.data,
            }
        case CORPORATE_ACCOUNT_PERMISSIONS_RECEIVED_ERROR:
            return {
                ...state,
                isFetchingPermissions: false,
                corporateAccountPermissions: []
            }
        case UNLOAD_CORPORATE_ACCOUNT_PERMISSIONS:
            return {
                ...state,
                isFetchingPermissions: false,
                corporateAccountPermissions: []
            }
        default: return state;
    }
}

