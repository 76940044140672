import axios from "axios";
import { token } from "../selectors/auth";
import { createRangeYearToDate } from "../../../pages/admin/Analytics/date-utils";
import { format } from "date-fns";
import mergeVenues from "../merge-venues";

const dateRangeToParams = ({ start, end }) => ({
  start_date: format(start, "YYYY-MM-DD"),
  end_date: format(end, "YYYY-MM-DDT23:59:59"),
});


const fetchAnalyticsImage = () => async (
    dispatch,
    getState
) => {
    dispatch({
        type: "@@analytics/FETCH_ANALYTICS_IMAGE",
        payload: "fetching",
    });

    try {

        let requestMain;
        requestMain =  axios.get(`${window.API}/cms/analytics-image`, {
            headers: { Authorization: `Bearer ${token(getState())}` },
            })
            .then(response => {
                return response.data
            })
            .catch(

            );

        const [analyticsImageUrl] = await Promise.all([
            requestMain
        ]);
        dispatch({
            type: "@@analytics/FETCH_ANALYTICS_IMAGE",
            payload: analyticsImageUrl
        });
    } catch (error) {

    }
};

const fetchVenueData = ({ venue_id, start_date, end_date }) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: "@@analytics/FETCH_VENUE_DATA",
    payload: {
      venue_id,
    },
  });

  try {
    const snapshotDateRange = createRangeYearToDate();
    const multi = typeof venue_id === "string" && venue_id.includes(",");
    const ids = multi ? venue_id.split(",") : null;

    let requestMain;
    let requestSnapshot;
    let requestVenue;

    requestMain = multi
      ? Promise.all(
          ids.map(id =>
            axios.get(`${process.env.REACT_APP_ANALYTICS}/v1-data/${id}`, {
              headers: { Authorization: `Bearer ${token(getState())}` },
              params: {
                start_date,
                end_date,
              },
            })
          )
        )
      : axios.get(`${process.env.REACT_APP_ANALYTICS}/v1-data/${venue_id}`, {
          headers: { Authorization: `Bearer ${token(getState())}` },
          params: {
            start_date,
            end_date,
          },
        });

    requestSnapshot = multi
      ? Promise.all(
          ids.map(id =>
            axios.get(`${process.env.REACT_APP_ANALYTICS}/v1-data/${id}`, {
              headers: { Authorization: `Bearer ${token(getState())}` },
              params: dateRangeToParams(snapshotDateRange),
            })
          )
        )
      : axios.get(`${process.env.REACT_APP_ANALYTICS}/v1-data/${venue_id}`, {
          headers: { Authorization: `Bearer ${token(getState())}` },
          params: dateRangeToParams(snapshotDateRange),
        });

    requestVenue = multi
      ? Promise.all(
          ids.map(id => axios.get(`${window.API}/venues-by-id/${id}`))
        )
      : axios.get(`${window.API}/venues-by-id/${venue_id}`);

    const [analytics, snapshot, venue] = await Promise.all([
      requestMain,
      requestSnapshot,
      requestVenue,
    ]);

    let data;

    if (multi) {
      data = mergeVenues(ids.length, analytics, snapshot, venue);
    } else {
      data = {
        ...analytics.data,
        snapshot: snapshot.data,
        venueData: {
          twitter_link: venue.data.twitter_link,
          tripadvisor_link: venue.data.tripadvisor_link,
          instagram_link: venue.data.instagram_link,
          data_score: venue.data.data_score,
          articles: venue.data.articles,
          offers: venue.data.offers,
        },
      };
    }

    dispatch({
      type: "@@analytics/SET_VENUE_DATA",
      payload: {
        data,
        venue_id,
        start_date,
        end_date,
      },
    });
  } catch (error) {
    dispatch({
      type: "@@analytics/SET_VENUE_DATA_ERROR",
      payload: {
        venue_id,
      },
    });
  }
};

export { fetchVenueData, fetchAnalyticsImage };
