import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import useAxios from "hooks/useAxios";
import Loading from "Components/Loading";
import FeaturedBox from "Components/FeaturedBox";

const View = ({ id, loading, error, data, title, link, linkText }) => {
  return (
    <section className="pb-12" id={id}>
      {title && link && (
        <Link
          to={link}
          className="uppercase block text-white bg-black p-3 mb-5"
        >
          <h3>{title}</h3>
        </Link>
      )}
      {title && !link && (
        <h3 className="text-white bg-primary p-3 mb-5">{title}</h3>
      )}
      {loading && (
        <div className="py-3">
          <Loading />
        </div>
      )}
      {!loading && error && <p>Sorry, there was a problem fetching the data</p>}
      {!loading && Array.isArray(data) && !!data.length && (
        <div className="row">
          {data.map(destination => {
            const name = destination.city;
            const slug = name.toLowerCase().replace(/ /g, "-");
            const cmsImg = destination && destination.images && destination.images.length > 0 && destination.images.sort((a, b) => a.id > b.id)[0].path ? destination.images.sort((a, b) => a.id > b.id)[0].path : false;
            const imgURL = cmsImg ? cmsImg: `location-imgs/${slug}-md.jpg`;
            return (
              <FeaturedBox
                key={slug}
                href={`/venues-in-${slug}`}
                name={name}
                img={imgURL}
              />
            );
          })}
        </div>
      )}
      {link && linkText && (
        <div className="text-center pb-3">
          <Link className="font-heading uppercase text-xl" to={link}>
            {linkText}
          </Link>
        </div>
      )}
    </section>
  );
};

const FeaturedSection = ({id, url, title, link, linkText, sort, filter, onDataFetch}) => {
  const {loading, error, data} = useAxios({url});
  let outputData = [];

  useEffect(() => {
    if (Array.isArray(data)) {
      if (onDataFetch) {
        onDataFetch(data);
      }
    }
  }, [data, onDataFetch]);

  if (Array.isArray(data)) {
    if (sort && filter) {
      outputData = data.filter(filter).sort(sort);
    } else if (sort) {
      outputData = data.sort(sort);
    } else if (filter) {
      outputData = data.filter(filter)
    } else {
      outputData = data
    }
  }
  return (
    <View
      id={id}
      loading={loading}
      error={error}
      data={outputData}
      title={title}
      link={link}
      linkText={linkText}
    />
  );
};

const FeaturedSectionNoFetch = ({ data, title, link, linkText }) => (
  <View data={data} title={title} link={link} linkText={linkText} />
);

export { FeaturedSectionNoFetch };
export default FeaturedSection;
