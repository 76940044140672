import Axios from "axios";

const StoreImage = async (file, token) => {
  let formData = new FormData();
  formData.append("image", file);

  return await Axios.post(`${window.API}/genimages`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export { StoreImage };
