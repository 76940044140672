import { ButtonBrand } from "../Button";
import React from "react";
import { Link } from "react-router-dom";
import { handleDownload } from "../../services/downloadFile";

let ItemList = ({
                  key,
                  title,
                  externalLink = false,
                  link,
                  buttonText,
                  icon,
                  token,
                  type,
                  download,
                  newTab = false,
                  corporateStyle = false,
                }) => {
  if (corporateStyle) {
    return <div
      className="corporate-document-panel bg-black text-white h-full p-6 flex flex-col rounded-lg justify-between mr-4"
      style={{ backgroundSize: "cover" }}>
      <div className="flex flex-row items-center mb-4">
        {icon && <span className="text-2xl mr-4">
          <i className={icon} />
        </span>}
        <h2 className="mb-0 text-lg xl:text-xl font-normal text-white">{title}</h2>
      </div>
      {download ?
        <ButtonBrand onClick={() => handleDownload(title,
          link,
          type,
          token,
        )} classes="w-full" target={newTab ? "_blank" : ""}>
          {buttonText}
        </ButtonBrand> : null
      }
      {externalLink ? <ButtonBrand Element={"a"} href={link} classes="w-full" target={newTab ? "_blank" : ""}>
        {buttonText}
      </ButtonBrand> : null}
      {!download && !externalLink ?
        <ButtonBrand Element={Link} to={link} classes="w-full" target={newTab ? "_blank" : ""}>
          {buttonText}
        </ButtonBrand> : null}
    </div>;
  }

  return (
    <div
      className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2 mb-3"
      key={key}
    >
      <div className="bg-white h-full p-5 flex flex-col">
        {icon ? <span className="block text-center text-4xl mb-2">
          <i className={icon} />
        </span> : null}

        <h2 className="mb-4 text-center">{title}</h2>

        {download ?
          <ButtonBrand onClick={() => handleDownload(title, link, type, token)} classes="w-full"
                       target={newTab ? "_blank" : ""}>
            {buttonText}
          </ButtonBrand>
          : null}
        {externalLink ?
          <ButtonBrand Element={"a"} href={link} classes="w-full" target={newTab ? "_blank" : ""}>
            {buttonText}
          </ButtonBrand>
          : null}
        {!download && !externalLink ?
          <ButtonBrand Element={Link} to={link} classes="w-full" target={newTab ? "_blank" : ""}>
            {buttonText}
          </ButtonBrand>
          : null}
      </div>
    </div>
  );
};

export default ItemList;
