import moment from 'moment';
import React from "react";
let DateOverrideNotification = ({data, enquiryVenueData}) => {


    if(enquiryVenueData && enquiryVenueData.date_from_override && enquiryVenueData && enquiryVenueData.date_to_override) {
        return (
            <span className='bg-red px-3 py-6 my-5 block font-bold text-white'>Please note the dates for this event differ from the original brief. The event dates are now {`${moment(enquiryVenueData.date_from_override).format('DD/MM/YYYY')} to ${moment(enquiryVenueData.date_to_override).format('DD/MM/YYYY')}`}
                                </span>
        )
    } else{
        return null;
    }

}

export default DateOverrideNotification