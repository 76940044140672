// we're thunking here so we don't have to pass the whole form values state around the entirity of AS
// (avoiding re-renders on every input change)

import axios from 'axios';
import { getFormValues } from 'redux-form';
import queryString from 'query-string';
import { omit } from 'lodash';


const search = (history, localStateSetter, v, cancelToken = false) => (d, getState) => {
  localStateSetter.loading();

  const params = {
    ...getFormValues('advanced-search')(getState()),
    ...v,
  };

  if (params.update_on_move) delete params.update_on_move;

  Object.keys(params).forEach(v => {
    if (params[v] === '') delete params[v];
  });

  if (params.uuid) {
    delete params.uuid;
  }

  const payload = {
    params,
  };

  if (cancelToken !== false) {
    payload.cancelToken = cancelToken;
  }

  axios
      .get(`${window.NODE_AS}/`, payload)
      .then(r => {
        if (history.location.pathname === '/advanced-search' || history.location.pathname === '/corporate/advanced-search') {
          localStateSetter.results(r.data.data, r.data.count);
          history.push(
              history.location.pathname + `?results=${r.data.count}&${queryString.stringify(
                  omit(params, ['placeArea']),
              )}`,
          );
        }
      })
      .catch(err => {
        if (history.location.pathname === '/advanced-search') {
          localStateSetter.error();
        }
      });
};

export { search };
