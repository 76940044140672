import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Field, reduxForm, change } from "redux-form";
import qs from "query-string";
import axios from "axios";
import Input from "Components/Form/Input";
import { ButtonPrimary } from "Components/Button";
import Warning from "Components/Warning";
import Loading from "Components/Loading";
import { email as emailValidator, required } from "modules/validation";
import { login } from "modules/auth";
import {pushEvent} from "modules/google-tag-manager";
import Success from "../Components/Success";
import {generalInteraction} from "../modules/analytics";
import {GENERAL_TAGS} from "../modules/analytics/tags";
import {refreshUser, refreshUserCompany} from "../modules/auth-thunks";
import { hotjar } from 'react-hotjar';

const form = "login";
const requiredEmail = required("Please enter your email address");

const Login = ({
  change,
  login,
  handleSubmit,
  history,
  location,
  submitting,
  generalInteraction,
  refreshUserCompany,
  refreshUser
}) => {
  const [loginError, setLoginError] = useState();
  const [loginMessage, setLoginMessage] = useState();

  const {
    email,
    message,
    return: returnAfterLogin,
    sessionExpired,
    logInToProceed,
    canSeeVenuesAdminArea
  } = qs.parse(location.search);

  const handleLogin = values => {
    return axios
      .post(`${window.API}/auth/login`, values)
      .then(response => {
        const role = response.data["roles-string"];

        login({
          token: response.data.access_token,
          role,
          venues: response.data.venues,
          user: response.data.user,
        });
        let userId = response.data?.user?.id;
        let hotjarUser = {
          user_type: response.data?.user?.user_type?.name
        };
        if (hotjar.initialized()) {
          // this won't work unless hotjar is on a business plan.
          hotjar.identify(userId, hotjarUser)
        }

        if (returnAfterLogin) {
          history.push(decodeURIComponent(returnAfterLogin));
        } else {
          const userHasVenues =
            response.data.venues && response.data.venues.length;
          history.push(userHasVenues && canSeeVenuesAdminArea ? "/admin/venues" : "/");
        }
        generalInteraction({type: GENERAL_TAGS.GENERAL_LOGIN_SUCCESS, data: response.data.user.email})
        pushEvent("user-login-success");
        refreshUserCompany()
        refreshUser()
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          switch (err.response.data.error) {
            case "Account not active":
              setLoginError("Your agency or company is not currently disabled - please contact EDGE Venues to discuss");
              break;
            case "Unauthorized":
              setLoginError("The email and password don't match");
              break;
            default:
              setLoginError("Sorry there was a problem logging in, please check your details and try again");
          }
        } else {
          setLoginError("Sorry there was a problem logging in, please check your details and try again");
        }
        pushEvent("user-login-fail");
      });
  };

  useEffect(() => {
    if (history.location.state && history.location.state.message) {
      setLoginMessage(history.location.state.message)
    }
  }, [])

  useEffect(() => {
    if (email && message === "account_activated") {
      change(form, "email", email);
      pushEvent("user_activated");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, message]);

  return (
    <div className="flex justify-center px-3 py-8 md:py-16">
      <div className="bg-white p-8 max-w-full" style={{width: 900}}>
        <div className="text-center">
          <h1 className="mb-5">Log into your EDGE account</h1>
          <p className="mb-5">
            Please enter your details to log in. Not got an account?{" "}
            <a href="/register">Click here to register</a>
          </p>
        </div>
        <div className="sm:px-8 md:px-16 lg:px-32">
          <form onSubmit={handleSubmit(handleLogin)} noValidate>
            {sessionExpired ? (
              <p className="text-lg mb-5 bg-warning p-3 text-white font-normal">
                Your session has expired
              </p>
            ) : null}
            {logInToProceed ? (
              <p className="text-lg mb-5 bg-primary p-3 text-white font-normal">
                Please login or register an account to proceed
              </p>
            ) : null}
            {message === "account_activated" ? (
              <p className="text-lg mb-5 bg-primary p-3 text-white font-normal">
                Your account is activated, please log in below.
              </p>
            ) : null}
            <div className="bg-off-white p-5 mb-5">
              <Field
                name="email"
                type="email"
                label="Email address"
                component={Input}
                validate={[requiredEmail, emailValidator]}
              />
              <Field
                name="password"
                type="password"
                label="Password"
                component={Input}
              />
            </div>
            <div className="sm:flex justify-between items-center">
              <Link
                to="/reset-password"
                className="text-black block mb-3 sm:mb-0"
              >
                Forgotten password?
              </Link>
              <ButtonPrimary type="submit">
                {submitting ? <Loading inline /> : "Log in"}
              </ButtonPrimary>
            </div>
            <Warning className="mt-3">{loginError}</Warning>
            <Success className="mt-3">{loginMessage}</Success>
            <p className="mt-5">
              <hr/>
              Corporate user with SSO? <a href="/login-via-sso">Login here</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  reduxForm({
    form,
  })(
    connect(
      undefined,
      { login, change, generalInteraction, refreshUserCompany, refreshUser }
    )(Login)
  )
);
