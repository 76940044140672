import React from "react";
import { connect } from "react-redux";
import Modal from "Components/Modal";
import { venueInteraction } from "modules/analytics";
import "styles/external/react-image-gallery.css"; // importing this from node_modules screws up the load order. It's a copy paste from latest module CSS
import { FaVideo } from "react-icons/fa";
import RoundedIcon from "./RoundedIcon";

class Video extends React.Component {
    state = {
        videoOpen: false,
    };
    toggleVideo = () => {
        if (!this.state.videoOpen) {
            this.props.venueInteraction({
                type: "view_video_link",
                id: this.props.venueId,
                impression_id: this.props.impression_id,
                data_score: this.props.data_score,
            });
        }
        this.setState({
            videoOpen: !this.state.videoOpen,
        });
    };

    getVideo = () => {
        const {videoLink}= this.props;

        let a = videoLink.match(/https:\/\/(:?www.)?(\w*)/);
        if(!a || typeof  a[2] === 'undefined') {
             a = videoLink.match(/http:\/\/(:?www.)?(\w*)/);
        }
        if(!a || typeof  a[2] === 'undefined') {
            return null
        }
        //Youtube links can be youtu.be which will present as youtu for the check.
        if (a[2] =="youtube" || a[2] =="youtu")
        {
            let findYoutubeId = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            let youtubeId = (videoLink.match(findYoutubeId)) ? RegExp.$1 : false ;
            if(youtubeId === false) {
                 findYoutubeId = /^(?:http?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
                youtubeId = (videoLink.match(findYoutubeId)) ? RegExp.$1 : false ;
                if(youtubeId === false) {
                    return null;
                }
            }
            // console.log(youtubeId);
            return <iframe width="1280" height="720" className={'re-16/9 relative max-w-full'} src={'https://youtube.com/embed/' + youtubeId} frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
        }
        else if (a[2] =="vimeo")
        {
           let  regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
            let parsedVimeoLink = regExp.exec(videoLink);
           if(!parsedVimeoLink || typeof  parsedVimeoLink[5] === 'undefined') {
               return null
           }
            return <><iframe src={'https://player.vimeo.com/video/' + parsedVimeoLink[5]} width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen/>
                <p><a href="https://vimeo.com/510814675">Nikon | Everything Starts with a Detail</a> from <a href="https://vimeo.com/panteraco">Pantera</a> on <a href="https://vimeo.com">Vimeo</a>.</p></>
        }
    };


    render() {
        return (
            <>
                {this.props.small && (
                    <RoundedIcon
                        name="video"
                        size={24}
                        classes="mr-2 pointer"
                        onClick={this.toggleVideo}
                    />
                )}
                {!this.props.small && (
                    <div
                        onClick={this.toggleVideo}
                        className={this.props.classes}
                        style={
                            this.props.red ? undefined : { width: 64, height: 64, top: -30 }
                        }
                    >
                        <FaVideo
                            className={`${
                                this.props.red ? "text-dark-red" : "text-white"
                            } text-2xl`}
                        />
                        <span
                            className={`font-heading font-bold uppercase ${
                                this.props.red ? "text-dark-red" : "text-white"
                            } text-xs`}
                        >
              Video
            </span>
                    </div>
                )}
                {this.state.videoOpen ? (
                    <Modal>
                        <div className="modal">
                            <div
                                className="center absolute "
                                style={{
                                    maxWidth: "90%",
                                    maxHeight: "90%",
                                }}
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                <i
                                    className="fal fa-times absolute z-50 pin-t pin-r text-white mr-2 mt-2 text-2xl pointer"
                                    onClick={this.toggleVideo}
                                />
                                {this.getVideo()}
                            </div>
                        </div>
                    </Modal>
                ) : null}
            </>
        );
    }
}

export default connect(
    undefined,
    { venueInteraction }
)(Video);
