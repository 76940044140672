import React from 'react'
import { FaCheck } from 'react-icons/fa';

const RoundedCheckbox = ({size, checked, onClick, className, title = 'Click to add to your comparison/shortlist', ...rest}) => {
    return (
        <div
            title={title}
            style={{
                width: size,
                height: size,
                borderStyle: checked ? 'solid' : 'dashed',
                borderWidth: 1,
                borderRadius: '50%',
                padding: 5
            }}
            className={`pointer flex items-center justify-center ${!checked ? 'border-dark-grey' : 'border-green bg-green'} ${className || ''}`}
            onClick={onClick}
            {...rest}
        >
            <FaCheck className={checked ? "text-white" : "text-grey-md"} />
        </div>
    )
}

export default RoundedCheckbox;