import convertPriceToSourceCurrencyValue from "helpers/convertPriceToSourceCurrencyValue";

export default function mapRoomsFromProposalData(rooms = [], isSourceCurrency = false) {
  return rooms.map(room => {
    const values = {
      ...room,
    };
    values.price_total_net_gbp = room.price_total_net;
    values.price_total_vat_gbp = room.price_total_vat;

    values.price_per_day_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_per_day_vat"));
    values.price_total_net = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_total_net"));
    values.price_total_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_total_vat"));
    values.price_reduced_from_total_net = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_reduced_from_total_net"));
    values.price_reduced_from_total_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_reduced_from_total_vat"));
    values.price_per_day_net = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_per_day_net"));
    values.price_per_day_reduced_from = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_per_day_reduced_from_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_per_day_reduced_from_vat"));
    values.price_per_day_reduced_from_net = convertPriceToSourceCurrencyValue(isSourceCurrency, room, "price_per_day_reduced_from_net");
    if (room.overnight_room_data) {
      values.overnight_room_data.overnight_price_net_gbp = room.overnight_room_data.overnight_price_net;
      values.overnight_room_data.overnight_price_vat_gbp = room.overnight_room_data.overnight_price_vat;

      values.overnight_room_data.overnight_price_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_vat"));
      values.overnight_room_data.overnight_hold_cost = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_vat"));
      values.overnight_room_data.overnight_price_net = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_net"));
      values.overnight_room_data.overnight_price_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_vat"));
      values.overnight_room_data.overnight_hold_reduced_from = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_reduced_from_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_reduced_from_vat"));
      values.overnight_room_data.overnight_price_reduced_from_net = convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_reduced_from_net");
      values.overnight_room_data.overnight_price_reduced_from_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, room.overnight_room_data, "overnight_price_reduced_from_vat");
    }
    if (room.setup_data) {
      values.setup_data.setup_price_net_gbp = room.setup_data.setup_price_net;
      values.setup_data.setup_price_vat_gbp = room.setup_data.setup_price_vat;

      values.setup_data.setup_price_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_vat"));
      values.setup_data.setup_cost = Number(Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_vat")));
      values.setup_data.setup_price_net = convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_net");
      values.setup_data.setup_price_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_vat");
      values.setup_data.setup_reduced_from = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_reduced_from_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_reduced_from_vat"));
      values.setup_data.setup_price_reduced_from_net = convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_reduced_from_net");
      values.setup_data.setup_price_reduced_from_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, room.setup_data, "setup_price_reduced_from_vat");
    }
    if (room.breakdown_data) {
      values.breakdown_data.breakdown_price_net_gbp = room.breakdown_data.breakdown_price_net;
      values.breakdown_data.breakdown_price_vat_gbp = room.breakdown_data.breakdown_price_vat;

      values.breakdown_data.breakdown_price_vat = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_vat"));
      values.breakdown_data.breakdown_cost = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_vat"));
      values.breakdown_data.breakdown_price_net = convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_net");
      values.breakdown_data.breakdown_price_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_vat");
      values.breakdown_data.breakdown_reduced_from = Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_reduced_from_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_reduced_from_vat"));
      values.breakdown_data.breakdown_price_reduced_from_net = convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_reduced_from_net");
      values.breakdown_data.breakdown_price_reduced_from_vat = convertPriceToSourceCurrencyValue(isSourceCurrency, room.breakdown_data, "breakdown_price_reduced_from_vat");
    }
    return values;
  });
}