import React from "react";
import CorporateAccountForm from "../../../../Components/corporate/CorporateAccountForm";
import { Failed, Succeeded } from "../../../../Components/Form";
import DepartmentsContainer from "../department/DepartmentsContainer";
import CorporateAccountDocumentCategoryAddContainer from "../documents/CorporateAccountDocumentCategoryAddContainer";
import { ButtonPrimary } from "../../../../Components/Button";
import CustomFieldContainer from "../custom-fields/CustomFieldContainer";
import {corporateAccountUsers} from "../../../../redux/reducers/corporate";
import BookingDesksContainer from "../booking-desks/BookingDesksContainer";

let CorporateAccountOverview = ({
                                  corporateAccount,
                                  onSubmit,
                                  success,
                                  error,
                                  companies,
                                  onAddNewCategory,
                                  toggleActiveAccount,
                                  corporateAccountUsers,
                                  categorySuccess,
                                  bookingDesks,
                                  corporateBookingDesks,
                                  onDeleteCategory,
                                  slug,
                                  token,
                                  onSubmitBookingDesks,
                                  corporatePermissions
                                }) => {
  return (
    <div className={"bg-white p-5 mt-10"}>
      <div className="flex justify-between">
        <h1 className={"mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center"}>
          Overview
        </h1>
        <div className="mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center">
          {(corporateAccount.is_active === 0) && (
            <ButtonPrimary classes={"mr-3"} onClick={() => toggleActiveAccount(true)}>
              <i className="fas fa-check-circle mr-3" /> Activate Account
            </ButtonPrimary>
          )}
          {(corporateAccount.is_active === 1) && (
            <ButtonPrimary classes={"bg-dark-red"} onClick={() => toggleActiveAccount(false)}>
              <i className="fas fa-trash mr-3" /> Deactivate Account
            </ButtonPrimary>
          )}
        </div>
      </div>
      <CorporateAccountForm
        slug={slug}
        corporateAccount={corporateAccount}
        bookingDesks={bookingDesks}
        corporateAccountUsers={corporateAccountUsers}
        companies={companies}
        onSubmit={onSubmit}
        toggleActiveAccount={toggleActiveAccount}
        buttonText={"Save Changes"}
        corporatePermissions={corporatePermissions}
      />
      {(!error && success) && <Succeeded leftAlign />}
      {(!success && error) && <Failed leftAlign />}
      <BookingDesksContainer
        slug={slug}
        bookingDesks={corporateBookingDesks}
        onSubmit={onSubmitBookingDesks}
      />
      <DepartmentsContainer />
      <CorporateAccountDocumentCategoryAddContainer
        corporateAccount={corporateAccount}
        onAddNewCategory={onAddNewCategory}
        onDeleteCategory={onDeleteCategory}
      />
      <CustomFieldContainer corporateAccount={corporateAccount} onAddNewCategory={onAddNewCategory} />
    </div>
  );
};

export default CorporateAccountOverview;
