import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import {
  createDateRange,
  dateRangeFilter,
} from "../../../pages/admin/Analytics/date-utils";
import { venueInteractions, venuePageViews } from "./utils";

export default (id, start, end, filter) => state => {
  let data = [];

  if (!filter) {
    data = venuePageViews(id)(state);
  } else if (filter === "interactions") {
    data = venueInteractions(id)(state);
  }

  const filtered = dateRangeFilter(data, createDateRange(start, end), "day");

  const unique = uniqBy(filtered, "uuid");
  const grouped = groupBy(unique, "user_type");

  return [
    {
      name: "Agency",
      value: grouped.Agency ? grouped.Agency.length : 0,
      colour: "#e65100",
    },
    {
      name: "Corporate",
      value: grouped.Corporate ? grouped.Corporate.length : 0,
      colour: "rgb(0, 153, 255)",
    },
    {
      name: "Venue",
      value: grouped.Venue ? grouped.Venue.length : 0,
      colour: "#9BCB41",
    },
    {
      name: "Guest",
      value:
        (grouped.Other ? grouped.Other.length : 0) +
        (grouped.null ? grouped.null.length : 0),
      colour: "#f28f00",
    },
    {
      name: "Individual",
      value: grouped.Individual ? grouped.Individual.length : 0,
      colour: "#9863c5",
    },
    {
      name: "Charity",
      value: grouped["Association/Charity"]
        ? grouped["Association/Charity"].length
        : 0,
      colour: "#777",
    },
  ];
};
