import axios from "axios";
import { SubmissionError } from "redux-form";

const uploadCompanyUserlist = async ( formData, companyId, token) => {
    try {
        const response =   await axios.post(
            `${window.API}/company/${companyId}/upload-user-list`,
            formData,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return { result: response.data };
    } catch (error) {

    }
};

export { uploadCompanyUserlist };
