import axios from "axios";

const fetchAirTableData = async (token) => {
    try {
        const response = await axios.get(
            `${window.API}/airtable-data`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        return response.data;
    } catch (error) {

    }
};

const fetchAirTableDataSingle = async (venueId, token) => {
    try {
        const response = await axios.get(
            `${window.API}/airtable-data/${venueId}`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        return response.data;
    } catch (error) {

    }
};

export {fetchAirTableData, fetchAirTableDataSingle}