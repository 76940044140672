import React from "react";
import { Field, reduxForm } from "redux-form";
import { Input } from "Components/Form";

const SearchDestinations = props => {
  return (
    <div className={`${props.className}`}>
      <h2 className="mb-6">Search destinations</h2>
      <Field label="visited id" name="visited_id" component={Input} />
    </div>
  );
};

export default reduxForm({
  form: "CreateSiteVisitForm",
  destroyOnUnmount: false,
})(SearchDestinations);
