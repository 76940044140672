import React from "react";
import Loading from "Components/Loading";
import { Input } from "Components/Form";
import { Field, reduxForm } from "redux-form";
import { ButtonBrand, ButtonPrimary } from "Components/Button";

const VenueSearchField = props => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Field component={Input} label="Find venue" name="venue_search_field" />
      <div className="flex justify-between">
        {props.loading ? (
          <Loading inline />
        ) : (
          <ButtonBrand type="submit">Search</ButtonBrand>
        )}

        {props.searchResults.length > 0 && (
          <ButtonPrimary onClick={props.clear}>Clear</ButtonPrimary>
        )}
      </div>
    </form>
  );
};

export default reduxForm({
  form: "SearchVenuesForm",
  destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true,
})(VenueSearchField);
