import React from "react";
import Warning from "Components/Warning";

const SSOError = () => {
    return (
        <div className="flex justify-center px-3 py-8 md:py-16">
            <div className="bg-white p-8 max-w-full" style={{width: 900}}>
                <div className="text-center">
                    <h1 className="mb-5">There was a problem logging into your EDGE account</h1>
                    <Warning className="mt-3">Please contact your internal support</Warning>
                </div>
            </div>
        </div>
    );
};

export default SSOError;
