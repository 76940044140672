import React, { useState, useEffect } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { useSelector, connect, useDispatch } from 'react-redux';
import { isAgent, isInternal, token as tokenSelector } from 'modules/auth';
import {currencyFormatter, GBPFormatter} from 'utils';
import {
    ButtonBlack,
    ButtonSpan,
} from 'Components/Button';
import RfpProposalDetailModal from './RfpProposalDetailModal';
import PackagePriceBreakdownModal from './PackagePriceBreakdownModal';
import RoomsPriceBreakdownModal from './RoomsPriceBreakdownModal';
import CateringPriceBreakdownModal from './CateringPriceBreakdownModal';
import AccommodationPriceBreakdownModal from './AccommodationPriceBreakdownModal';
import CommissionPriceBreakdownModal from './CommissionPriceBreakdownModal';
import { fetchVatRates } from '../../redux/actions';
import { ENQUIRY_VENUE_DATA_STATUS } from '../../modules/rfp/enquiryVenueDataStatus';
import EnquiryStatus from "../../pages/admin/rfp/Confirmation/Components/EnquiryStatus";
import { formatDate } from "../../modules/dates";
const Row = ({ dark, title, value }) => {
    return (
        <div
            className="p-2 flex justify-between items-center"
            style={{
                backgroundColor: dark ? '#eaeaea' : '#f9f9f9',
            }}
        >
            <span>{title}</span>
            <span className="font-bold">{value}</span>
        </div>
    );
};

const ReadOnlySlide = ({
                           brief,
                           venueConfirmed,
                           confirmed,
                           availabilityTypes,
                           data,
                           onConfirmVenue,
                           onExport,
                           onRemove,
                           onRequestSiteVisit,
                           onViewMessages,
                           venue,
                           vatRates = [],
                           isManaged,
                           publicView = false
                       }) => {
    const [modal, setModal] = useState(null);
    const [packageModal, setPackageModal] = useState(null);
    const [roomsModal, setRoomsModal] = useState(null);
    const [equipmentsModal, setEquipmentsModal] = useState(null);
    const [commissionModal, setCommissionModal] = useState(null);
    const [accommodationModal, setAccommodationModal] = useState(null);
    const [cateringModal, setCateringModal] = useState(null);

    const availability = availabilityTypes && data.venue_initial_response && availabilityTypes.find(
        item => item.value === data.venue_initial_response.availability_type_id,
    );
    const availabilityNote =  data.venue_initial_response ? data.venue_initial_response.availability_type_note : null;
    const summaryItems = data.proposal_data?.summary?.summary_items || [];
    const total = summaryItems.find(item => item.step_id === 9) || {};
    const packages = summaryItems.find(item => item.step_id === 2) || {};
    const equipments = summaryItems.find(item => item.step_id === 4) || {};
    const catering = summaryItems.find(item => item.step_id === 5) || {};
    const accommodation = summaryItems.find(item => item.step_id === 6) || {};
    const rooms = summaryItems.find(item => item.step_id === 3) || {};

    const token = useSelector(tokenSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchVatRates(token));
    }, [dispatch]);

    const mappedVatRates = vatRates.length > 0 ? vatRates.map(vat_rate => ({
        value: vat_rate.id,
        text: vat_rate.rate + '%',
        number: vat_rate.rate,
    })) : [];

    const canConfirm = () => {
        if (confirmed.includes(venue.id)) {
            return false;
        }
        if (
            data.venue_confirmation &&
            data.venue_confirmation.date_user_confirmed
        ) {
            return false;
        }
        return true;
    };
    return (
        <>
            <div className="mb-5">
                <h2
                    className="text-white bg-black font-normal font-body p-3 uppercase"
                    style={{ minHeight: '6.7rem' }}
                >
                    {venue.name}
                </h2>
                <p
                    className="bg-red text-white font-normal uppercase text-lg flex items-center p-3"
                    style={{ minHeight: '5rem' }}
                >
                    <EnquiryStatus status={data.status}/>
                </p>
                <div
                    className="re-16/9 w-full bg-no-repeat bg-cover bg-center"
                    style={
                        venue.image
                            ? {
                                backgroundImage: `url(${window.API}/venues/${venue.slug}/images/${venue.image})`,
                            }
                            : null
                    }
                />
                <div className="flex justify-between p-3 font-normal text-xl mb-2">
                    <div>
                        <i className="mr-2 fas fa-users"/>
                        {venue.capacity}
                    </div>
                    <div>
                        <i className="mr-2 fas fa-bed"/>
                        {venue.bedrooms}
                    </div>
                    <div>
                        <i className="mr-2 fas fa-handshake"/>
                        {venue.meeting_rooms}
                    </div>
                </div>
                {
                    data.status === ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED ?
                        <React.Fragment >
                            <div className="p-3 bg-brand  bg-black text-white">
                                <div className={'flex justify-between items-center py-1'}>
                                    <span className="font-normal">PROPOSAL PRICE</span>
                                    <span className="font-bold text-xl">
            {currencyFormatter(data.currency.currency_code).format(total.total_inc_vat)}{" "}
                                        <ButtonSpan
                                            className="text-base font-normal underline ml-2"
                                            onClick={() => setModal("price")}
                                        >
                                          view
                                        </ButtonSpan>
                                        </span>
                                </div>
                                {total.reduced_from_inc_vat > total.total_inc_vat && (total.reduced_from_inc_vat - total.total_inc_vat) > 0 ?
                                    <div>
                                        <hr className={'mt-1 mb-2'}/>
                                        <div className={'flex justify-between items-center mb-1'}>
                                            <span className="font-normal">REDUCED FROM</span>
                                            <span className="font-bold text-xl" style={{paddingRight: '45px'}}>{currencyFormatter(data.currency.currency_code).format(total.reduced_from_inc_vat)}{" "}</span>
                                        </div>
                                        <div className={'flex justify-between items-center mb-1'}>
                                            <span className="font-normal">COST SAVING</span>
                                            <span className="font-bold text-xl" style={{paddingRight: '45px'}}>{currencyFormatter(data.currency.currency_code).format(total.reduced_from_inc_vat - total.total_inc_vat)}{" "}</span>
                                        </div>
                                        <span className="font-normal" style={{fontSize: '14px'}}>Prices include {data.currency.currency_code === 'GBP' ? `VAT` : `Tax`}</span>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="p-3">PRICE BREAKDOWN</div>
                            {data.proposal_data.holding_until_date ?
                              <Row
                                title="Will Hold Until"
                                value={formatDate(data.proposal_data.holding_until_date,'ddd Do MMM YYYY')}
                              /> : null
                            }
                            {data.proposal_data.hold_for ?
                                <Row
                                    title="Will Hold for"
                                    value={data.proposal_data.hold_for + ' day(s)'}
                                /> : null
                            }
                            {data.proposal_data.conference_packages.length > 0 ?
                                <Row
                                    title="Conference Packages"
                                    value={
                                        packages.total_inc_vat ? (
                                            <span className="font-bold text-lg">
                {!!parseInt(packages.total_inc_vat) && GBPFormatter.format(packages.total_inc_vat)}
                                                {!!parseInt(packages.total_inc_vat) && (
                                                    <ButtonSpan
                                                        className="text-base font-normal underline ml-2"
                                                        onClick={() => setPackageModal(true)}
                                                    >
                                                        view
                                                    </ButtonSpan>
                                                )}
              </span>
                                        ) : null
                                    }
                                /> : null}
                            {data.proposal_data.supplementary_items.length > 0 ?
                                <Row
                                    dark
                                    title="Catering"
                                    value={
                                        catering.total_inc_vat ? (
                                            <span className="font-bold text-lg">
                {!!parseInt(catering.total_inc_vat) && GBPFormatter.format(catering.total_inc_vat)}
                                                {!!parseInt(catering.total_inc_vat) && (
                                                    <ButtonSpan
                                                        className="text-base font-normal underline ml-2"
                                                        onClick={() => setCateringModal(true)}
                                                    >
                                                        view
                                                    </ButtonSpan>
                                                )}
              </span>
                                        ) : null
                                    }
                                />

                                : null}
                            {data.proposal_data.accommodations.length > 0 ?

                                <Row
                                    title="Accommodation"
                                    value={
                                        accommodation.total_inc_vat ? (
                                            <span className="font-bold text-lg">
                {!!parseInt(accommodation.total_inc_vat) && GBPFormatter.format(accommodation.total_inc_vat)}
                                                {!!data.proposal_data.accommodations.length && (
                                                    <ButtonSpan
                                                        className="text-base font-normal underline ml-2"
                                                        onClick={() => setAccommodationModal(true)}
                                                    >
                                                        view
                                                    </ButtonSpan>
                                                )}
              </span>
                                        ) : null
                                    }
                                />

                                : null}
                            {data.proposal_data.meeting_rooms.length > 0 ?
                                <Row
                                    dark
                                    title="Event Spaces"
                                    value={
                                        rooms.total_inc_vat ? (
                                            <span className="font-bold text-lg">
                {!!parseInt(rooms.total_inc_vat) && GBPFormatter.format(rooms.total_inc_vat)}
                                                {!!data.proposal_data.meeting_rooms.length && (
                                                    <ButtonSpan
                                                        className="text-base font-normal underline ml-2"
                                                        onClick={() => setRoomsModal(true)}
                                                    >
                                                        view
                                                    </ButtonSpan>
                                                )}
              </span>
                                        ) : null
                                    }
                                />
                                : null}

                            {data.proposal_data.equipment.length > 0 ?
                                <Row

                                    title="Equipment"
                                    value={
                                        equipments.total_inc_vat ? (
                                            <span className="font-bold text-lg">
                {!!parseInt(equipments.total_inc_vat) && GBPFormatter.format(equipments.total_inc_vat)}
                                                {!!data.proposal_data.equipment.length && (
                                                    <ButtonSpan
                                                        className="text-base font-normal underline ml-2"
                                                        onClick={() => setEquipmentsModal(true)}
                                                    >
                                                        view
                                                    </ButtonSpan>
                                                )}
              </span>
                                        ) : null
                                    }
                                /> : null}

                        </React.Fragment>
                        : null

                }

                {!publicView &&  data.status >= ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED && (
                    <ButtonBlack
                        classes="p-3 flex items-center w-full mb-3"
                        onClick={() => onExport(venue)}
                        style={{ height: 'auto', lineHeight: 1 }}
                    >
                        <i className="fas fa-share mr-2"></i>Export
                    </ButtonBlack>
                )}

                <div className="sm:flex sm:flex-wrap">

                        <ButtonBlack
                            Element={Link}
                            classes="p-3 w-full mb-1"
                            target="_blank"
                            to={`/venue/${venue.slug}`}
                            rel="noopener noreferrer"
                            style={{ height: 'auto', lineHeight: 1 }}
                        >
                            View venue
                        </ButtonBlack>
                </div>
            </div>
            {modal && (
                <RfpProposalDetailModal
                    onClose={() => setModal(null)}
                    availability={availability}
                    availabilityNote={availabilityNote}
                    data={data}
                    venue={venue}
                    type={modal}
                />
            )}
            {packageModal && (
                <PackagePriceBreakdownModal
                    brief={brief}
                    vatRates={mappedVatRates}
                    attachments={data.proposal_data.attachments.filter(
                        item => item.attachment_type_id === 1,
                    )}
                    notes={data.proposal_data.packages_notes}
                    rooms={venue.rooms || []}
                    onClose={() => setPackageModal(null)}
                    packages={data.proposal_data.conference_packages}
                    total={data.proposal_data.summary.summary_items.find(
                        item => item.step_id === 2,
                    )}
                    enquiryVenueData={data}
                />
            )}
            {roomsModal && (
                <RoomsPriceBreakdownModal
                    brief={brief}
                    vatRates={mappedVatRates}
                    attachments={data.proposal_data.attachments.filter(
                        item => item.attachment_type_id === 2,
                    )}
                    notes={data.proposal_data.meeting_rooms_notes}
                    rooms={venue.rooms || []}
                    equipment={[]}
                    venueId={venue.id}
                    onClose={() => setRoomsModal(null)}
                    meetingRooms={data.proposal_data.meeting_rooms || []}
                    total={data.proposal_data.summary.summary_items.find(
                        item => item.step_id === 3,
                    )}
                    enquiryVenueData={data}
                />
            )}

            {equipmentsModal && (
                <RoomsPriceBreakdownModal
                    vatRates={mappedVatRates}
                    title={'Equipment'}
                    attachments={[]}
                    notes={[]}
                    rooms={[]}
                    equipment={data.proposal_data.equipment || []}
                    venueId={venue.id}
                    onClose={() => setEquipmentsModal(null)}
                    meetingRooms={[]}
                    total={data.proposal_data.summary.summary_items.find(
                        item => item.step_id === 4,
                    )}
                />
            )}
            {commissionModal && (
                <CommissionPriceBreakdownModal
                    venueId={venue.id}
                    onClose={() => setCommissionModal(null)}
                    commissionItems={data.proposal_data.commission_items || []}
                    summaryItems={summaryItems || []}
                />
            )}
            {cateringModal && (
                <CateringPriceBreakdownModal
                    vatRates={mappedVatRates}
                    attachments={data.proposal_data.attachments.filter(
                        item => item.attachment_type_id === 3,
                    )}
                    notes={data.proposal_data.catering_notes}
                    data={data.proposal_data}
                    rooms={venue.rooms || []}
                    catering={data.proposal_data.supplementary_items || []}
                    additional={data.proposal_data.additional_items || []}
                    venueId={venue.id}
                    onClose={() => setCateringModal(null)}
                    total={data.proposal_data.summary.summary_items.find(
                        item => item.step_id === 5,
                    )}
                />
            )}
            {accommodationModal && (
                <AccommodationPriceBreakdownModal
                    vatRates={mappedVatRates}
                    attachments={data.proposal_data.attachments.filter(
                        item => item.attachment_type_id === 4,
                    )}
                    notes={data.proposal_data.accommodation_notes}
                    data={data.proposal_data}
                    accommodation={data.proposal_data.accommodations || []}
                    onClose={() => setAccommodationModal(null)}
                    total={data.proposal_data.summary.summary_items.find(
                        item => item.step_id === 6,
                    )}
                />
            )}
        </>
    );
};

let ReadOnlySlideWithState = connect((state, props) => {
    return {
        vatRates: state.vatRate.vatRates,
    };

})(ReadOnlySlide);

export default withRouter(ReadOnlySlideWithState);
