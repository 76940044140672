import React, {useEffect, useState} from "react";
import {useSelector, useDispatch, connect} from "react-redux";
import {
    fetchPriceBandingData,
    fetchVenuePriceBandingData
} from "../../../../../services/priceBandingService/fetchPriceBandings";
import {token} from "../../../../../modules/auth";
import {withRouter} from "react-router-dom";
import {generalInteraction} from "../../../../../modules/analytics";
import {reduxForm, Field, getFormValues, change} from "redux-form";
import {Failed, Select, Succeeded, Textarea} from "../../../../../Components/Form";
import ShowInProfileToggle from "./ShowInProfileToggle";
import {ButtonPrimary} from "../../../../../Components/Button";
import Loading from "../../../../../Components/Loading";
import axios from "axios";
import PriceBandingInformation from "./PriceBandingInformation";
import PriceBandingBanner from "../../../../../Components/PriceBanding/PriceBandingBanner";
import {
    prettifyInternationalPriceBandingTitle,
    prettifyPriceBandingTitle
} from "../../../../../helpers/prettifyPriceBandingTitle";

const mapDispatchToProps = {};

let form = 'venue-price-banding'
const mapStateToProps = state => ({
    token: token(state),
});


let PriceBanding = ({
                        token,
                        submitting,
                        handleSubmit,
                        failed,
                        match,
                        values
                    }) => {
    const dispatch = useDispatch();
    let [priceBandings, setPriceBandings] = useState([])
    let [venuePriceBandings, setVenuePriceBandings] = useState([])
    let [isInternational, setIsInternational] = useState(false)
    let [error, setError] = useState(false)
    let [show24hrOnProfile, setShow24hrOnProfile] = useState(true)
    let [loading, setLoading] = useState(true);
    let [succeeded, setSucceeded] = useState(false);

    useEffect(() => {
        fetchPriceBandingData(token).then((response) => {
            setPriceBandings(response)
        })
        fetchVenuePriceBandingData(token, match.params.venue).then((response) => {
            if(Object.keys(response).length !== 0) {
                dispatch(change(form, "twenty_four_hr_price_banding_rate",  response['twenty_four_hr_price_banding_rate'] ? response['twenty_four_hr_price_banding_rate']['id'] : null));
                dispatch(change(form, "twenty_four_hr_exclusion_text", response['twenty_four_hr_exclusion_text']));
                dispatch(change(form, "ddr_price_banding_rate", response['ddr_price_banding_rate'] ? response['ddr_price_banding_rate']['id'] : null));
                dispatch(change(form, "ddr_exclusion_text", response['ddr_exclusion_text']));
                dispatch(change(form, "bb_price_banding_rate", response['bb_price_banding_rate'] ?  response['bb_price_banding_rate']['id'] : null));
                dispatch(change(form, "bb_exclusion_text", response['bb_exclusion_text']));
                setShow24hrOnProfile(response['show_24hr_on_profile'])
                setVenuePriceBandings(response)
                setIsInternational(response['is_international']);
            }else{
                setIsInternational(!!response)
            }
            setLoading(false)
        })
    }, []);

    let toggleShowInProfile = (showInProfile) => {
        setShow24hrOnProfile(showInProfile)
    }
    const handleSave = async data => {
        data.show_in_profile = show24hrOnProfile
        data.twenty_four_hr_price_banding_rate = data.twenty_four_hr_price_banding_rate === 'Unbanded' ? null : data.twenty_four_hr_price_banding_rate
        data.ddr_price_banding_rate = data.ddr_price_banding_rate === 'Unbanded' ? null : data.ddr_price_banding_rate
        data.bb_price_banding_rate = data.bb_price_banding_rate === 'Unbanded' ? null : data.bb_price_banding_rate

        try {
            setError(false);
            await axios.post(
                `${window.API}/venues/${match.params.venue}/price-banding`,
                {...data},
                {
                    headers: {Authorization: `Bearer ${token}`},
                }
            ).then((response) => {
                setSucceeded(true);
            });
        } catch {
            setError(true);
        }
    };

    if (loading || priceBandings.length === 0) {
        return <Loading/>
    }

    return (<div>

        <h1 className="uppercase mb-5">Price Banding</h1>
        <h3 className="uppercase mb-5">Settings</h3>
        <p>Please select the most appropriate price band for your venues 24hr Rate or Day Delegate Rate packages. This will ensure that your Venue is represented in relevant search results, and improves engagement.</p>
        <ShowInProfileToggle
            showInProfile={venuePriceBandings && typeof venuePriceBandings['show_24hr_on_profile'] !== 'undefined' ? venuePriceBandings['show_24hr_on_profile'] : true}
            toggleShowInProfile={toggleShowInProfile}
        />
        <form onSubmit={handleSubmit(handleSave)} className={'pt-2'}>
            <React.Fragment>
                <table className="edge-table">
                    <thead>
                    <tr>
                    </tr>
                    </thead>
                    <tbody>
                    {show24hrOnProfile ? (
                    <tr
                        style={{
                            verticalAlign: "top",
                        }}
                    >
                        <td>
                            <Field
                                wrapperClassName="mb-5 price-band-selector-width"
                                name="twenty_four_hr_price_banding_rate"
                                label="24hr Price Band"
                                component={Select}
                                noPlaceholder={true}
                                options={[
                                    {
                                        text: 'Unbanded',
                                        value: null
                                    },
                                    ...priceBandings['24hr'].map(x => {
                                        return {
                                        text: isInternational ? prettifyInternationalPriceBandingTitle(x) : prettifyPriceBandingTitle(x),
                                        value:x.id.toString(),
                                    }})]}
                            />
                        </td>
                        <td>
                            <Field
                                wrapperClassName="mb-0"
                                rows={4}
                                name={`twenty_four_hr_exclusion_text`}
                                placeholder=""
                                component={Textarea}
                                label="24hr exclusion text"
                            />
                        </td>

                    </tr>
                    ) : null}
                    {!show24hrOnProfile && (
                        <tr
                            style={{
                                verticalAlign: "top",
                            }}
                        >
                            <td>
                                <Field
                                    wrapperClassName="mb-5 price-band-selector-width"
                                    name="bb_price_banding_rate"
                                    label="Bed & Breakfast price band"
                                    component={Select}
                                    noPlaceholder={true}
                                    options={[
                                        {
                                            text: 'Unbanded',
                                            value: null
                                        },
                                        ...priceBandings['BB'].filter( x => {
                                            if(isInternational && (x.band_code === 'F' || x.band_code === 'G'))
                                            {
                                                return false;
                                            }
                                            return true;
                                        }).map(x => {

                                            return {
                                                text: isInternational ? prettifyInternationalPriceBandingTitle(x) : prettifyPriceBandingTitle(x),
                                                value: x.id.toString(),
                                            }})]}
                                />
                            </td>
                            <td>
                                <Field
                                    wrapperClassName="mb-0"
                                    rows={4}
                                    name={`bb_exclusion_text`}
                                    placeholder=""
                                    component={Textarea}
                                    label="B&B exclusion text"
                                />
                            </td>
                        </tr>
                    )}
                    <tr
                        style={{
                            verticalAlign: "top",
                        }}
                    >
                        <td>
                            <Field
                                wrapperClassName="mb-5 price-band-selector-width"
                                name="ddr_price_banding_rate"
                                label="DDR Price Band"
                                component={Select}
                                noPlaceholder={true}
                                options={[
                                    {
                                        text: 'Unbanded',
                                        value: null
                                    },
                                    ...priceBandings['DDR'].map(x => ({
                                        text:  isInternational ? prettifyInternationalPriceBandingTitle(x) : prettifyPriceBandingTitle(x),
                                        value: x.id.toString(),
                                    }))]}
                            />
                        </td>
                        <td>
                            <Field
                                wrapperClassName="mb-0"
                                rows={4}
                                name={`ddr_exclusion_text`}
                                placeholder=""
                                component={Textarea}
                                label="DDR exclusion text"
                            />
                        </td>

                    </tr>

                    </tbody>
                </table>
            </React.Fragment>
            <div className="flex justify-between">
                <div className={'pt-5'}>
                    <ButtonPrimary type="submit">
                        {submitting ? <Loading inline/> : "Save"}
                    </ButtonPrimary>
                </div>

            </div>
            {succeeded ? <Succeeded/> : null}
            {error ? <Failed/> : null}
        </form>
        <hr className="mt-8 mb-5 border-t-4"/>

    </div>)
}


export default withRouter(
    reduxForm({form})(
        connect(
            state => {
                const values = getFormValues(form)(state) || {};
                return {
                    values: values,
                    token: token(state)
                };
            },
            mapDispatchToProps,
        )(PriceBanding),
    ),
);
// export default connect(mapStateToProps, mapDispatchToProps)(PriceBanding);