import React, {Component} from 'react';
import {connect} from "react-redux";
import {isCorporate, token} from "../../../../modules/auth";
import {fetchCorporateAccountLocations, unloadCorporateAccountLocationsList} from "../../../../redux/actions";
import CorporateAccountFavouriteLocations from "./CorporateAccountFavouriteLocations";
import NotFound from "../../../NotFound";
import {corporateAccountId, isRoleEdgeAdmin} from "../../../../modules/permissions";

const mapStateToProps = state => ({
  ...state.corporateAccountLocationsList,
  ...state.isFetchingLocations,
  token: token(state),
  corporateAccountId: corporateAccountId(state.auth),
  isRoleEdgeAdmin: isRoleEdgeAdmin(state.auth),
  isCorporate: isCorporate(state)
})

const mapDispatchToProps = {
  fetchCorporateAccountLocations,
  unloadCorporateAccountLocationsList
}

class CorporateAccountFavouriteLocationsContainer extends Component {
  componentDidMount() {
    this.props.fetchCorporateAccountLocations(this.props.match.params.slug, this.props.token);
  }

  componentWillUnmount() {
    this.props.unloadCorporateAccountLocationsList();
  }

  render() {
    const {corporateAccountLocationsList, isFetchingLocations, corporateAccountId, isRoleEdgeAdmin, isCorporate} = this.props;
    const accountId = isCorporate ? corporateAccountId : this.props.match.params.slug;

    if(accountId != corporateAccountId && !isRoleEdgeAdmin)
    {
      return <NotFound/>
    }
    return <CorporateAccountFavouriteLocations
      locations={corporateAccountLocationsList}
      isFetching={isFetchingLocations}
      accountId={accountId}
    />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountFavouriteLocationsContainer)