import axios from "axios";

const getDeclineReasons = async (_, token) => {
  try {
    const response = await axios.get(`${window.API}/rfp/decline-reasons`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.data.data) {
      return {
        result: {
          options: [],
          data: [],
        },
      };
    }

    const declineReason = response.data.data.map(type => type.data);

    return {
      result: declineReason.map(type => ({
        text: type.description,
        value: type.id,
        requires_text: type.requires_text_input,
      })),
    };
  } catch (error) {
    return { error };
  }
};

export { getDeclineReasons };
