import {
  CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED,
  CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_DOCUMENT_LIST_REQUEST,
  CORPORATE_ACCOUNT_DOCUMENT_LIST_UNLOAD,
  CORPORATE_ACCOUNT_DOCUMENT_RECEIVED,
  CORPORATE_ACCOUNT_DOCUMENT_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_DOCUMENT_REQUEST,
  CORPORATE_ACCOUNT_DOCUMENT_UNLOAD,
  CREATE_CORPORATE_ACCOUNT_DOCUMENT,
  CREATE_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
  DELETE_CORPORATE_ACCOUNT_DOCUMENT,
  EDIT_CORPORATE_ACCOUNT_DOCUMENT,
  EDIT_CORPORATE_ACCOUNT_DOCUMENT_ERROR,
  CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED,
  CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_REQUEST,
  CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_UNLOAD,
  CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_ERROR,
  CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY,
} from "./types";

export const corporateAccountDocumentsList = (state = {
  corporateAccountDocuments: {},
  isFetching: false,
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_DOCUMENT_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED:
      return {
        ...state,
        isFetching: false,
        corporateAccountDocuments: action
      }
    case CORPORATE_ACCOUNT_DOCUMENT_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case CORPORATE_ACCOUNT_DOCUMENT_LIST_UNLOAD:
      return {
        ...state,
        isFetching: false,
        corporateAccountDocuments: {},
      }

    default:
      return state;
  }
}

export const corporateAccountDocumentsCategoryList = (state = {
  corporateAccountCategories: {},
  isFetching: false,
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED:
      return {
        ...state,
        isFetching: false,
        corporateAccountCategories: action.data
      }
    case CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_LIST_UNLOAD:
      return {
        ...state,
        isFetching: false,
        corporateAccountCategories: {},
      }

    default:
      return state;
  }
}


export const corporateAccountDocument = (state = {
  corporateAccountDocument: {},
  isFetching: false,
  error: false,
  errorData: null,
  accessDenied: false,
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_DOCUMENT_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case CORPORATE_ACCOUNT_DOCUMENT_RECEIVED:
      return {
        ...state,
        isFetching: false,
        corporateAccountDocument: action.data,
        accessDenied: false,
      }
    case CORPORATE_ACCOUNT_DOCUMENT_RECEIVED_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
        accessDenied: action.data.response.status === 401
      }
    case EDIT_CORPORATE_ACCOUNT_DOCUMENT:
      return {
        ...state,
        isFetching: false,
        error: false,
        corporateAccountDocument: action.data.data,
        accessDenied: false,
        errorData: null,
      }
    case EDIT_CORPORATE_ACCOUNT_DOCUMENT_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorData: action.error
      }
    case CREATE_CORPORATE_ACCOUNT_DOCUMENT:
    case CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY:
      return {
        ...state,
        isFetching: false,
        error: false,
        corporateAccountDocument: action.data.data,
        errorData: null,
        accessDenied: false,
      }
    case CREATE_CORPORATE_ACCOUNT_DOCUMENT_ERROR:
    case CREATE_CORPORATE_ACCOUNT_DOCUMENT_CATEGORY_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
        errorData: action.error
      }

    case CORPORATE_ACCOUNT_DOCUMENT_UNLOAD:
      return {
        corporateAccountDocument: {},
        isFetching: false,
        error: false,
      }
    case DELETE_CORPORATE_ACCOUNT_DOCUMENT:
      return {
        corporateAccountDocument : action.data.data,
        isFetching: false,
        error: false,
      }
    default:
      return state;
  }
}
