import countBy from "lodash/countBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import {
  createDateRange,
  dateRangeFilter,
} from "../../../pages/admin/Analytics/date-utils";
import { allTypes } from "./utils";

const sortAndLimit = (towns, limit) =>
  orderBy(towns, "count", "desc").slice(0, limit);

export default (id, start, end, limit = 10) => state => {
  const dateRange = createDateRange(start, end);

  const all = dateRangeFilter(allTypes(id)(state), dateRange, "day");

  const townCountMap = countBy(all, item => item.user_town);

  const unknownCount = townCountMap["null"] || 0;
  delete townCountMap["null"];

  // Lodash map converts object props to array values
  const towns = map(townCountMap, (count, town) => ({ count, location: town }));

  return {
    towns: sortAndLimit(towns, limit),
    unknownCount,
  };
};
