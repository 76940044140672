import axios from "axios";
import {saveData} from "../downloadFile";

const downloadProposalWithoutCommission = async (id, token) => {
    let url =   `${window.API}/rfp/enquiry/${id}/export/pdf?disable_commission=1`

    try {
        const response = await axios.get(
            url,
            {
                responseType: "blob",
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        saveData(
            false,
            response.data,
            `your-enquiry-${id}.pdf`
        );

    } catch (err) {
        alert(`Sorry there was a problem fetching your ${'pdf'}`);
    }
};

export { downloadProposalWithoutCommission };
