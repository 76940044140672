import React, {useEffect, useState} from "react";
import {
  FaUsers,
  FaHandshake,
  FaBed,
  FaPlaneDeparture,
  FaUtensils,
  FaParking,
  FaWheelchair,
  FaDumbbell,
  FaWifi
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { getWidth } from "redux-window";
import {ButtonBrand} from "../../Components/Button";
import { withRouter } from 'react-router-dom';

export const Feature = ({ children, classes, ...rest }) => (
  <a
    className={`text-black bg-light-grey flex justify-center items-center mr-2 rounded-50 pointer ${classes ||
      ""}`}
    style={{ width: 28, height: 28 }}
    {...rest}
  >
    {children}
  </a>
);

const KeyInformation = ({
  capacity,
  meetingRooms,
  bedrooms,
  travelTime,
  leisure,
  wifi,
  venueId,
  history,
  venueName,
  shareEmailBody,
  isLoggedIn
}) => {
  const mobile = useSelector(state => getWidth(state) < 992);
  const [emailBody, setEmailBody] = useState("");
  const splitAirportTime = !!travelTime ? travelTime.split(" ") : [];

  useEffect(() => {
    setEmailBody(shareEmailBody({
      venueName: venueName,
    }))
  }, []);

  const handleVenueShare = event => {
    if (!isLoggedIn) {
      event.preventDefault();

      history.push(
        `/login?return=${encodeURI(
          history.location.pathname
        )}&logInToProceed=1`
      );
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="col col-w-full lg:col-w-1/2 mb-6">
        <section className="border-t-3 border-green py-3 px-4 bg-white text-lg shadow h-full">
          <div className="row">
            <div className="col col-w-1/2 lg:col-w-1/4 flex flex-col justify-end">
              <p className="uppercase text-grey-md font-bold text-xs mb-2">
                Max delegates
              </p>
              <div className="flex text-2xl lg:text-sm xxl:text-xl font-bold items-center">
                <FaUsers className="mr-3 text-2xl" />
                <div>
                  <span className="font-heading text-md">
                    {!!capacity ? parseInt(capacity, 10) : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="col col-w-1/2 lg:col-w-1/4 flex flex-col justify-end">
              <p className="uppercase text-grey-md font-bold text-xs mb-2">
                Meeting rooms
              </p>
              <div className="flex text-2xl lg:text-sm xxl:text-xl font-bold items-center">
                <FaHandshake className="mr-3 text-2xl" />
                <div>
                  <span className="font-heading text-md">{meetingRooms}</span>
                </div>
              </div>
            </div>
            <div className="col col-w-1/2 lg:col-w-1/4 flex flex-col justify-end">
              <p className="uppercase text-grey-md font-bold text-xs mb-2">
                Bedrooms
              </p>
              <div className="flex text-2xl lg:text-sm xxl:text-xl font-bold items-center">
                <FaBed className="mr-3 text-2xl" />
                <div>
                  <span className="font-heading text-md">{bedrooms}</span>
                </div>
              </div>
            </div>
            {splitAirportTime.length > 0 && (
              <div className="col col-w-1/2 lg:col-w-1/4 flex flex-col justify-end">
                <p className="uppercase text-grey-md font-bold text-xs mb-2">
                  Nearest airport
                </p>
                <div className="flex text-2xl lg:text-sm xxl:text-xl font-bold items-center">
                  <FaPlaneDeparture className="mr-3 text-2xl" />
                  <div className="text-md">
                    {/* <span className="font-heading text-xl">{travelTime}</span> */}
                    <span className="font-heading">{splitAirportTime[0]}</span>
                    <span className="font-normal">
                      {splitAirportTime[1] === "hour"
                        ? "hr"
                        : splitAirportTime[1]}
                    </span>
                    {splitAirportTime[2] && (
                      <>
                        {" "}
                        <span className="font-heading">
                          {splitAirportTime[2]}
                        </span>
                      </>
                    )}
                    {splitAirportTime[3] && (
                      <span className="font-normal">{splitAirportTime[3]}</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      {!mobile && (
        <div className="col col-w-full lg:col-w-1/4 flex items-stretch mb-6">
          <section className="border-t-3 border-green py-3 px-4 bg-white text-lg shadow w-full h-full">
            <p className="uppercase text-grey-md font-bold text-xs">
              More about the venue features
            </p>
            <div className="flex justify-center lg:justify-start">
              <Feature href="#features">
                <FaUtensils className="text-sm text-black" />
              </Feature>
              <Feature href="#features">
                <FaParking className="text-sm text-black" />
              </Feature>
              <Feature href="#features">
                <FaWheelchair className="text-sm text-black" />
              </Feature>
              {leisure && (
                <Feature href="#features">
                  <FaDumbbell className="text-sm text-black" />
                </Feature>
              )}
              {wifi && (
                <Feature href="#features">
                  <FaWifi className="text-sm text-black" />
                </Feature>
              )}
            </div>
          </section>
        </div>
      )}
      <div className="col col-w-full lg:col-w-1/4 flex items-stretch mb-6">
        <section className="border-t-3 border-green bg-white text-lg shadow w-full h-full">
          <div className="py-3 px-4">
            <ButtonBrand
              classes="w-full mb-2"
              onClick={() => history.push(`/request-for-proposal?venues=${venueId}`)}
            >
              Send Enquiry
            </ButtonBrand>
            <ButtonBrand
              classes="rounded-sm block w-full text-center font-normal border-4 justify-center px-2"
              outlined
              Element="a"
              style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
              href={`mailto:?subject=${
                venueName
              }&body=${emailBody}`}
              onClick={() => handleVenueShare}
            >
              Share this venue
            </ButtonBrand>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withRouter(KeyInformation);
