import {fetchNamedLocationsPending, fetchNamedLocationsSuccess, fetchNamedLocationsError} from 'modules/named-locations.js';

function fetchNamedLocations(city, type, group) {
    return dispatch => {
        dispatch(fetchNamedLocationsPending());
        fetch(`${window.API}/named/locations?city=${city}&type=${type}&group=${group}`)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(fetchNamedLocationsSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchNamedLocationsError(error));
            })
    }
}

export default fetchNamedLocations;