import React from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";
import BackgroundImage from "../../Components/General/BackgroundImage";
import LazyLoad from 'react-lazyload';
import {generalInteraction} from "../../modules/analytics";

const SingleCarouselItem = ({href, name, img, generalInteraction, ...rest}) => {
  return (
    <Link to={href} className="location-carousel-item" onClick={() => {
      generalInteraction({
        type: "general_home_location_click",
        data: name
      })
    }}>
      <LazyLoad height={200}>
        <BackgroundImage
          className={'rounded-t bg-center bg-cover re-4/3 relative mx-3'}
          imagePath={img.replace(/.*\/\/[^\/]*/, '').substr(1)}
          edits={{
            "resize": {
              "width": 320,
              "height": 210,
              "fit": "cover"
            }
          }}>
          <div
            className="rounded-t absolute pin-t pin-b pin-l p-4 md:p-8 lg:p-6 xl:p-8 bg-black-transparent hover:bg-black-transparent-dark transition"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <h1
              className="font-heading font-bold text-white mb-3 text-2xl sm:text-3xl md:text-5/2rem lg:text-3xl xxl:text-5/2rem">
              {name} <FaChevronRight className="font-bold text-lg sm:text-xl"/>
            </h1>
            {/* <Link to="#" className="font-bold font-heading text-2xl text-white block mb-3">
                        Central <FaChevronRight className="text-xs ml-10" />
                    </Link>
                    <Link to="#" className="font-bold font-heading text-2xl text-white block mb-3">
                        West <FaChevronRight className="text-xs ml-10" />
                    </Link>
                    <Link to="#" className="font-bold font-heading text-2xl text-white block mb-3">
                        East <FaChevronRight className="text-xs ml-10" />
                    </Link> */}
          </div>
        </BackgroundImage>

      </LazyLoad>

    </Link>
  );
};

export default connect(null, {generalInteraction})(SingleCarouselItem);
