import {
  COMPANY_BRANCH_LIST_RECEIVED_ERROR,
  COMPANY_BRANCH_LIST_RECEIVED_REQUEST,
  COMPANY_BRANCH_LIST_REQUEST,
  COMPANY_BRANCH_REQUEST,
  COMPANY_BRANCH_REQUEST_RECEIVED,
  COMPANY_BRANCH_REQUEST_RECEIVED_ERROR,
  COMPANY_BRANCH_UNLOAD,
  COMPANY_BRANCHES_UNLOAD,
} from "./types";

export const companyBranches = (state = {
  companyBranches: [],
  isFetchingCompanyBranches: false,
  companyBranchesError: null,
}, action) => {
  switch (action.type) {
    case COMPANY_BRANCH_LIST_REQUEST:
      return {
        ...state,
        isFetchingCompanyBranches: true,
      };
    case COMPANY_BRANCH_LIST_RECEIVED_REQUEST:
      return {
        ...state,
        isFetchingCompanyBranches: false,
        companyBranches: action.data.data,
      };
    case COMPANY_BRANCH_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingCompanyBranches: false,
        companyBranchesError: action.error,
      };
    case COMPANY_BRANCHES_UNLOAD:
      return {
        ...state,
        isFetchingCompanyBranches: false,
        companyBranches: {},
      };
    default:
      return state;
  }
};

export const companyBranch = (state = {
  companyBranch: {},
  isFetchingCompanyBranch: false,
  companyBranchError: null,
}, action) => {
  switch (action.type) {
    case COMPANY_BRANCH_REQUEST:
      return {
        ...state,
        isFetchingCompanyBranch: true,
      };
    case COMPANY_BRANCH_REQUEST_RECEIVED:
      return {
        ...state,
        isFetchingCompanyBranch: false,
        companyBranch: action.data?.data,
        companyBranchError: null,
      };
    case COMPANY_BRANCH_REQUEST_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingCompanyBranch: false,
        companyBranch: {},
        companyBranchError: action.error,
      };
    case COMPANY_BRANCH_UNLOAD:
      return {
        ...state,
        isFetchingCompanyBranch: false,
        companyBranch: {},
        companyBranchError: null,
      };
    default:
      return state;
  }
};
