import React from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { audienceTownCity } from "../../../../modules/analytics";

const AudienceTownCity = ({ towns, unknownCount }) => (
  <div className="p-3">
    <div className="flex mb-3">
      <div className="flex-grow font-bold">Town/City</div>
      <div className="flex-no-shrink w-16" />
    </div>
    {towns.map((item, i) => (
      <div className="flex mb-3" key={i}>
        <div
          className="flex-grow overflow-hidden pr-3 text-sm"
          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {item.location}
        </div>
        <div className="flex-no-shrink w-16">{item.count}</div>
      </div>
    ))}
    {unknownCount > 0 && (
      <div className="flex mb-3 mt-6 italic">
        <div
          className="flex-grow overflow-hidden pr-3 text-sm"
          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          Undisclosed
        </div>
        <div className="flex-no-shrink w-16">{unknownCount}</div>
      </div>
    )}
  </div>
);

export default connect(state => {
  const v = getFormValues("analytics")(state) || {};

  return audienceTownCity(v.venue_id, v.start_date, v.end_date)(state);
})(AudienceTownCity);
