import axios from "axios";

function buildQueryString(params) {
  const queryParts = [];

  if (params.fetchAccommodationOnly) {
    queryParts.push("fetchAccommodation=true");
  }

  if (params.fetchIncludeAdmin) {
    queryParts.push("showInAdminOnly=true");
  }

  if (queryParts.length > 0) {
    return "?" + queryParts.join("&");
  }

  return "";
}

const getRoomStyles = async (params = {}, token) => {
  try {
    const queryString = buildQueryString(params);

    const response = await axios.get(
      `${window.API}/rfp/room-style${queryString}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    if (!response.data.data) {
      return {
        result: [],
      };
    }
    return {
      result: response.data.data.map(style => ({
        text: style.name,
        value: !!params && !params.fetchAccommodationOnly ? style.name : style.id,
        show_in_admin_only: style.show_in_admin_only
      })),
    };
  } catch (error) {
    return { error, result: [] };
  }
};


export { getRoomStyles };
