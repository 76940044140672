import { useEffect, useState, useCallback } from "react";
import qs from "query-string";

const useParams = (searchQuery, arrays) => {
  const [params, setParams] = useState(null);

  const getParams = useCallback(() => {
    const arrayKeys = arrays ? arrays.split(",") : null;
    const queryObject = qs.parse(searchQuery);
    const data = {};
    Object.keys(queryObject).forEach(key => {
      const value = queryObject[key];

      if (value.includes(",") || (arrayKeys && arrayKeys.includes(key))) {
        data[key] = value.split(",");
      } else {
        data[key] = value;
      }
    });

    return data;
  }, [searchQuery, arrays]);

  useEffect(() => {
    setParams(getParams());
  }, [getParams]);

  return params;
};

export default useParams;
