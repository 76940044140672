import React, {Component} from 'react';
import {connect} from "react-redux";
import {token} from "../../../../modules/auth";
import {
  editAccount,
  fetchCompanies,
  fetchCorporateAccount,
  resetCreateForm,
  unloadCorporateAccount,
  fetchBookingDesks,
  fetchCorporateAccountUsers,
  editBookingDesk,
  corporateAccountBookingDesksUnload,
  createBookingDesk,
  unloadCorporateAccountUsers,
  createDocumentCategory,
  deleteDocumentCategory,
  fetchCorporateBookingDesks,
  unloadAgencyBookingDesks,
  fetchCorporateAccountPermissions,
  unloadCorporateAccountPermissions,
} from "../../../../redux/actions";
import CorporateAccountOverview from "./CorporateAccountOverview";
import {ManageFadeOut} from "../../../../services/manageFadeOut";
import Loading from "../../../../Components/Loading";

const mapStateToProps = state => {
  return ({
    corporateAccount: state.corporateAccount.corporateAccount,
    agencyId: state.corporateAccount?.corporateAccount?.company?.agency?.id,
    error: state.corporateAccount.error,
    isFetching: state.corporateAccount.isFetching,
    companies: state.companyList.companies,
    token: token(state),
    ...state.corporateBookingDesks,
    ...state.agencyBookingDesks,
    ...state.corporateAccountUsers,
    ...state.corporateAccountPermissions,
  })
}

const mapDispatchToProps = {
  fetchCorporateAccount,
  unloadCorporateAccount,
  editAccount,
  fetchCompanies,
  fetchBookingDesks,
  resetCreateForm,
  fetchCorporateAccountUsers,
  editBookingDesk,
  createBookingDesk,
  corporateAccountBookingDesksUnload,
  unloadCorporateAccountUsers,
  createDocumentCategory,
  deleteDocumentCategory,
  fetchCorporateBookingDesks,
  unloadAgencyBookingDesks,
  fetchCorporateAccountPermissions,
  unloadCorporateAccountPermissions
}

class CorporateAccountOverviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      categorySuccess: false,
      error: false,
    }
  }

  componentWillUnmount() {
    this.props.corporateAccountBookingDesksUnload()
    this.props.unloadCorporateAccountUsers()
    this.props.unloadAgencyBookingDesks();
    this.props.unloadCorporateAccountPermissions()
  }

  componentDidMount() {
    this.props.fetchCorporateAccountUsers(this.props.match.params.slug, this.props.token, {agency: false})
    this.props.fetchCompanies(this.props.token, '', {paginate: false});
    this.props.fetchCorporateBookingDesks(this.props.match.params.slug, this.props.token);
    this.props.fetchCorporateAccountPermissions(this.props.token);
    this.props.fetchCorporateAccount(this.props.match.params.slug, this.props.token).then(response => {
      if (response.data && response.data.company.agency_id) {
          this.props.fetchBookingDesks(response.data.company.agency_id, this.props.token);
      }
    });
  }

  onSubmit = formValues => {
    return this.props.editAccount(this.props.match.params.slug, this.props.token, formValues).then((response) => {
      this.props.fetchCorporateBookingDesks(this.props.match.params.slug, this.props.token);
      ManageFadeOut('success', this.setState.bind(this));
    }).catch((error) => {
      ManageFadeOut('error', this.setState.bind(this));
    });
  }

  onSubmitBookingDesks = formValues => {
    if (formValues.get('_method') === 'PATCH') {
      const bookingDeskId = formValues.get('id');
      return this.props.editBookingDesk(this.props.match.params.slug, bookingDeskId, formValues, this.props.token).then((response) => {
        ManageFadeOut('success', this.setState.bind(this));
      }).catch((error) => {
        ManageFadeOut('error', this.setState.bind(this));
      });
    } else {
      return this.props.createBookingDesk(this.props.match.params.slug, formValues, this.props.token);
    }
  }

  onAddNewCategory = async (name) => {
    return this.props.createDocumentCategory(this.props.match.params.slug, this.props.token, {name: name}).then((response) => {
      ManageFadeOut('categorySuccess', this.setState.bind(this));
      return Promise.resolve();
    }).catch(error => {
      ManageFadeOut('error', this.setState.bind(this));
    });
  }

  onDeleteCategory = async (category) => {
    if (window.confirm("Are you sure you want to delete the category " + category.name + "?")) {
      return this.props.deleteDocumentCategory(this.props.match.params.slug, category.id,  this.props.token).then((response) => {
        ManageFadeOut('categorySuccess', this.setState.bind(this));
        return Promise.resolve();
      }).catch(error => {
        ManageFadeOut('error', this.setState.bind(this));
      });
    }
  }

  toggleActiveAccount = isActive => {
    if (window.confirm("Are you sure you want to enable this account?")) {
      const formValues = {
        'is_active': isActive
      }
      return this.props.editAccount(this.props.match.params.slug, this.props.token, formValues)
    }
  }

  render() {
    const {companies, corporateAccount, corporateBookingDesks, corporateAccountUsers, isFetching, agencyBookingDesks, corporateAccountPermissions} = this.props;
    if (isFetching) {
      return <Loading/>
    }
    return (
      <CorporateAccountOverview
        slug={this.props.match.params.slug}
        companies={companies}
        corporateAccount={corporateAccount}
        bookingDesks={agencyBookingDesks}
        corporateBookingDesks={corporateBookingDesks}
        corporateAccountUsers={corporateAccountUsers}
        onSubmitBookingDesks={this.onSubmitBookingDesks}
        onAddNewCategory={this.onAddNewCategory}
        onDeleteCategory={this.onDeleteCategory}
        categorySuccess={this.state.categorySuccess}
        toggleActiveAccount={this.toggleActiveAccount}
        onSubmit={this.onSubmit}
        success={this.state.success}
        error={this.state.error}
        corporatePermissions={corporateAccountPermissions}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountOverviewContainer)