import React from "react";
import { string, oneOfType, object, node } from "prop-types";
import {isUndefined} from "lodash";

const Label = ({ name, text, className, labelSize = 'text-xs ',  ...rest }) => {
  let classes = 'block uppercase font-heading mb-1 ';
  classes += labelSize
  if (!isUndefined(className)) {
    classes += className;
  }
  return (
    <label
      htmlFor={name}
      className={classes}
      {...rest}
    >
      {typeof text === "object" && text.top ? (
        <React.Fragment>
          <span>{text.top}</span>
          <span className="font-light font-body block normal-case mt-1">
            {text.bottom}
          </span>
        </React.Fragment>
      ) : (
        text
      )}
    </label>
  );
};

Label.propTypes = {
  text: oneOfType([string, object, node]).isRequired,
  name: string,
};

export default Label;
