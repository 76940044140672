import {
    calculateCommissionAs,
    calculateCommissionForCalculator,
    calculateCommissionFromUnit,
    calculatePricePerNightPerPerson, calculateTotalAs
} from "../../../rfp/Confirmation/Helpers/calculateTotals";
import {
    commissionFormItem,
    getCommissionItemsForStep, getDescriptionValues
} from "./proposalDataGatherer";
import {
    COMMISSION_VALUE,
    PRICE_NET, PRICE_TOTAL,
    PRICE_VAT,
} from "../components/FinalCommissionValuesModal";
import moment from 'moment'

function createAccommodationCommissionItems(enquiryVenueData, formValues, vatRates, includesVatRate, includesVat, currency)
{
    let accommodationCommissionData = getCommissionItemsForStep(enquiryVenueData, 6);
    return  enquiryVenueData.proposal_data.accommodations.forEach( accom => {
        let hasSrcCurVals = !!accom[`price_net_src_cur`];
        let totals =  calculateTotalAs( 'Net',vatRates, accom.vat_rate.id,  parseFloat(calculatePricePerNightPerPerson( Number(accom.no_of_rooms), Number(accom[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]), 0,  Number(accom.no_of_nights))).toFixed(2));
        let commissionTotals =  calculateCommissionAs(
            includesVat,
            vatRates,
            includesVatRate,
            includesVat,
            accommodationCommissionData ?  calculateCommissionForCalculator(
                    false,
                    calculatePricePerNightPerPerson( Number(accom.no_of_rooms), Number(accom[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]), 0,  Number(accom.no_of_nights)),
                    accom.vat_rate.rate,
                    accommodationCommissionData.rate,
                    'Net',
                )
                : {total: 0, vat: 0})
        commissionFormItem(
            `Arriving: ${moment(accom.arrival_date).format('DD/MM/YYYY')}, ` +
            `${accom.no_of_nights} ${accom.no_of_nights > 1 ? 'nights' : 'night'}, ${accom.bedroom_type.venue_bedroom_type_name}, ${accom.occupancy} person occupancy` +
            `${accom.included_in_package ? ' (Included in Package)' : ''}` +
            `${accom.breakfast_included ? ' (Breakfast included)' : ' ' + getDescriptionValues(
                accom.no_of_rooms,
                accom[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`],
                calculatePricePerNightPerPerson( Number(accom.no_of_rooms), Number(accom[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]), 0,  Number(accom.no_of_nights)),
                accom.no_of_nights,
                currency
            )}`,
            accom.no_of_rooms,
            accom[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`],
            accom.no_of_nights,
            calculatePricePerNightPerPerson( Number(accom.no_of_rooms), Number(accom[`price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`]), 0,  Number(accom.no_of_nights)),
            totals.vat,
            includesVat,
            totals.total,
            accom.price_vat_rate_id ? accom.price_vat_rate_id : 41,
            accommodationCommissionData ? accommodationCommissionData.rate : 0,
            'Net',
            commissionTotals,
            'accommodations',
            accom.id,
            formValues
        );
        if(!accom.breakfast_included) {
            let totals =  calculateTotalAs('Net',vatRates, accom.vat_rate.id,  parseFloat(calculatePricePerNightPerPerson( accom.no_of_rooms, accom[`breakfast_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0,  accom.no_of_nights)).toFixed(2));
            let commissionTotals =  calculateCommissionAs(
                includesVat,
                vatRates,
                includesVatRate,
                includesVat,
                accommodationCommissionData ? calculateCommissionForCalculator(
                        false,
                        calculatePricePerNightPerPerson( accom.no_of_rooms, accom[`breakfast_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0,  accom.no_of_nights),
                        accom.vat_rate.rate,
                        accommodationCommissionData.rate,
                        accommodationCommissionData.applied_on,
                    )
                    : {total: 0, vat: 0})
            commissionFormItem(
                `Breakfast Cost ` + getDescriptionValues(
                    Number(accom.no_of_rooms),
                    accom[`breakfast_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`],
                    calculatePricePerNightPerPerson( accom.no_of_rooms, accom[`breakfast_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0,  accom.no_of_nights),
                    accom.no_of_nights,
                    currency
                ),
                null,
                accom[`breakfast_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`],
                accom.no_of_nights,
                calculatePricePerNightPerPerson( accom.no_of_rooms, accom[`breakfast_price_net${currency && hasSrcCurVals ? '_src_cur' : ''}`], 0,  accom.no_of_nights),
                totals.vat,
                includesVat,
                totals.total,
                accom.breakfast_price_vat_rate_id ? accom.breakfast_price_vat_rate_id : 41,
                accommodationCommissionData ? accommodationCommissionData.rate : 0,
                'Net',
                commissionTotals,
                'accommodations',
                accom.id + '_breakfast',
                formValues
            );
        }

    });
}

function calculateAccommodationCommission(accom, enquiryVenueData)
{
    let accommodationCommissionData = getCommissionItemsForStep(enquiryVenueData, 6);
    return calculateCommissionFromUnit(
        accommodationCommissionData.include_vat,
        accom.price_net,
        accom.price_vat,
        20,
        Number(accom.no_of_nights) * Number(accom.no_of_rooms),
        parseFloat(accommodationCommissionData.rate).toFixed(2),
        accommodationCommissionData.applied_on,
        null,
        0)
}

function calculateAccommodationBreakfastCommission(accom, enquiryVenueData)
{
    let accommodationCommissionData = getCommissionItemsForStep(enquiryVenueData, 6);

    return calculateCommissionFromUnit(
        accommodationCommissionData.include_vat,
        accom.breakfast_price_net,
        accom.breakfast_price_vat,
        20,
        Number(accom.no_of_nights) * Number(accom.no_of_rooms),
        accommodationCommissionData.rate,
        accommodationCommissionData.applied_on,
        null,
        0)
}


function buildInitialAccommodationItems(enquiryVenueData, initialCommissionItems)
{
    enquiryVenueData.proposal_data.accommodations.map(accom => {
        let readonly = accom.included_in_package ? [PRICE_NET, PRICE_VAT,COMMISSION_VALUE, PRICE_TOTAL] : [PRICE_VAT,COMMISSION_VALUE, PRICE_TOTAL];
        if (!accom.breakfast_included){
            initialCommissionItems.push({id: accom.id, type: 'accommodations', disabled: [], readonly: readonly})
            initialCommissionItems.push({id: accom.id + '_breakfast', type: 'accommodations',  disabled: [], readonly: [PRICE_VAT,COMMISSION_VALUE, PRICE_TOTAL]})
        }else {
            let readonly = accom.included_in_package ? [PRICE_NET, PRICE_VAT,COMMISSION_VALUE, PRICE_TOTAL] : [PRICE_VAT,COMMISSION_VALUE, PRICE_TOTAL];
            initialCommissionItems.push({id: accom.id, type: 'accommodations', disabled: [], readonly: readonly})

        }
    })
}

function handleAccommodationFieldUpdate(enquiryVenueData, editedItem, updateFieldBasedOnKey, fieldEvent, value, id)
{
    if(editedItem.includes('accommodations') && !editedItem.includes('breakfast') )
    {
        let accom = enquiryVenueData.proposal_data.accommodations.find(accom => parseInt(accom.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'accommodations_' + id,
            id,
            value,
            accom.price_net,
            Number(accom.vat_rate.rate)
        )


    }

    if(editedItem.includes('accommodations') && editedItem.includes('breakfast') )
    {
        let accom = enquiryVenueData.proposal_data.accommodations.find(accom => parseInt(accom.id) === parseInt(id));
        updateFieldBasedOnKey(
            fieldEvent?.target?.name ?  fieldEvent.target.name : fieldEvent,
            'accommodations_' + id + '_breakfast',
            id,
            value,
            accom.breakfast_price_net,
            Number(accom.vat_rate.rate)
        )
    }
}

export
{
    createAccommodationCommissionItems,
    buildInitialAccommodationItems,
    handleAccommodationFieldUpdate

};