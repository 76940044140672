import axios from "axios";
import { SubmissionError } from "redux-form";
import moment from "moment";
import parseErrors from "modules/parse-errors";
import { createHandleAxiosError } from "../../modules/api-tools";
import { pushEvent } from "../../modules/google-tag-manager";

const dateFields = [
  "start_date",
  "end_date",
  "accommodation_start_date",
  "accommodation_end_date",
];

export default (values, props, { onlyValidate }) => {
  const { email, phone, ...params } = values;

  params.contact = [email, phone].filter(value => value).join(", ");

  dateFields.forEach(key => {
    if (moment.isMoment(values[key])) {
      params[key] = values[key].format("YYYY-MM-DD");
    }
  });
  if (Array.isArray(values.catering_requirements)) {
    params["catering_requirements"] = values.catering_requirements.join(", ");
  }
  if (Array.isArray(values.equipment_requirements)) {
    params["equipment_requirements"] = values.equipment_requirements.join(", ");
  }

  const url = `${window.API}/request-for-quote`;

  const { history, location } = props;
  const handleAxiosError = createHandleAxiosError({ history, location });

  return axios
    .post(url, params, {
      headers: { Authorization: `Bearer ${props.token}` },
    })
    .then(() => {
      pushEvent("submit_enquiry_success");
      if (!onlyValidate && Array.isArray(params.venues)) {
        params.venues.forEach(id => {
          props.venueInteraction({
            type: `rfq_${
              params.type ? params.type.toLowerCase().replace(/ /g, "_") : ""
            }`,
            impression_id: props.impression_id,
            data_score: props.data_score,
            id,
          });
        });
      }
    })
    .catch(
      handleAxiosError(err => {
        throw new SubmissionError({
          ...parseErrors(err),
          _error: "Sorry, there was an error submitting your data",
        });
      })
    );
};
