import React, { useEffect } from "react";
import { Field } from "redux-form";
import { ButtonSpan } from "Components/Button";
import { Input } from "../Form";

const RepeatableLinkedEnquiries = ({ fields = [], selectedLinkedEnquiries = [], values }) => {
  useEffect(() => {
    if (selectedLinkedEnquiries) {
      selectedLinkedEnquiries.forEach(linkedEnquiry => {
        let existingEntry = false;
        if (typeof fields.getAll() !== "undefined") {
          existingEntry = fields.getAll().find(field => field.id === linkedEnquiry.id);
        }
        if (!existingEntry) {
          fields.push({
            id: linkedEnquiry.id,
            event_name: linkedEnquiry.event_name,
            ref_id: linkedEnquiry.ref_id,
            ref_id_suffix: linkedEnquiry.ref_id_suffix,
          });
        }
      });
    }
  }, [selectedLinkedEnquiries]);
  return fields.map((member, index) => {
      return (
        <div className="row bg-light-grey pt-4 w-3/4" key={index}>
          <div className="flex ml-4 justify-center items-center">
            <ButtonSpan onClick={() => fields.remove(index)}>
              <i className="mr-2 fal fa-minus-circle"></i>
            </ButtonSpan>
          </div>
          <div className="col col-w-1/6">
            <Field
              name={`${member}.id`}
              label="ID"
              component={Input}
              readOnly={true}
              wrapperClassName="mb-3"
              size="sm"
            />
          </div>
          <div className="col col-w-1/6">
            <Field
              name={`${member}.ref_id`}
              label="Ref ID"
              component={Input}
              readOnly={true}
              wrapperClassName="mb-3"
              size="sm"
            />
          </div>
          <div className="col col-w-1/4">
            <Field
              name={`${member}.event_name`}
              label="Event Name"
              component={Input}
              readOnly={true}
              wrapperClassName="mb-3"
              size="lg"
            />
          </div>
          <div className="col col-w-1/4">
            <Field
              name={`${member}.ref_id_suffix`}
              label="Ref ID Suffix"
              component={Input}
              size="lg"
              wrapperClassName="mb-5 mr-5"
            />
          </div>
        </div>
      );
    },
  );
};

export default RepeatableLinkedEnquiries;