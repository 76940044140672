import convertPriceToSourceCurrencyValue from "helpers/convertPriceToSourceCurrencyValue";

export default function mapEquipmentFromProposalData(equipment, isSourceCurrency) {
  return equipment.map(item => {
    return {
      ...item,
      price_reduced_from: Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, "price_reduced_from_net")) + Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, "price_reduced_from_vat")),
      price_reduced_from_net: convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, "price_reduced_from_net"),
      cost: {
        ...item.cost,
        percentage_saving: item.cost.percentage_saving,
        price_reduced_from_net: convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, "price_reduced_from_net"),
        price_net: Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, "price_net")),
        price_reduced_from_vat: Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, "price_reduced_from_vat")),
        price_vat: Number(convertPriceToSourceCurrencyValue(isSourceCurrency, item.cost, "price_vat")),
        price_vat_rate_id: item.cost ? item.cost.price_vat_rate_id : undefined,
        cost_saving: item.cost ? item.cost.price_reduced_from_net - item.cost.price_net : undefined,
        price_net_gbp: item.cost.price_net,
        price_vat_gbp: item.cost.price_vat,
      },
      include_vat: item.cost ? item.cost.display_price_including_vat : undefined,
    };
  });
}
