import { useLayoutEffect } from "react";
import { createPortal } from "react-dom";

const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const scrollbarWidth = window.outerWidth - window.document.body.offsetWidth;

    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = scrollbarWidth + "px";

    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.paddingRight = 0;
    };
  }, []);
};

const Modal = ({ children }) => {
  useLockBodyScroll();

  const portalElement = document.getElementById("modal-root");

  return createPortal(children, portalElement);
};

export default Modal;
