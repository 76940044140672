import React, { Component } from "react";
import { connect } from "react-redux";
import { token } from "../../../../modules/auth";
import { fetchCorporateAccountDocuments, unloadCorporateAccountDocuments } from "../../../../redux/actions";
import CorporateAccountDocumentList from "./CorporateAccountDocumentList";

const mapStateToProps = state => ({
  ...state.corporateAccountDocumentsList,
  token: token(state),
});

const mapDispatchToProps = {
  fetchCorporateAccountDocuments,
  unloadCorporateAccountDocuments,
};

class CorporateAccountListContainer extends Component {
  componentDidMount() {
    this.props.fetchCorporateAccountDocuments(this.props.match.params.slug, this.props.match.params.id, this.props.token);
  }

  componentWillUnmount() {
    this.props.unloadCorporateAccountDocuments();
  }

  render() {
    const { corporateAccountDocuments, isFetching, token } = this.props;

    return <CorporateAccountDocumentList
      documents={corporateAccountDocuments}
      isFetching={isFetching}
      accountSlug={this.props.match.params.slug}
      token={token}
    />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateAccountListContainer);