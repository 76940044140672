import React, {useEffect, useState} from "react";
import Slider from "Components/Slider";
import {getAvailabilityTypes, removeVenue} from "services/rfpService";
import useService from "hooks/useService";
import ApprovedSlide from "./ApprovedSlide";
import ChaseVenueModal from "./ChaseVenueModal";
import ConfirmVenueModal from "./ConfirmVenueModal";
import ExportRfpProposalsModal from "./ExportRfpProposalsModal";
import NaSlide from "./NaSlide";
import PreparingSlide from "./PreparingSlide";
import RemoveVenueModal from "./RemoveVenueModal";
import RequestSiteVisitModal from "./RequestSiteVisitModal";
import ConfirmationThread from "../RfpMessages/ConfirmationThread";
import {ENQUIRY_VENUE_DATA_STATUS} from '../../modules/rfp/enquiryVenueDataStatus';
import moment from 'moment';
import {userId as userIdSelector, isInternal as isInternalSelector} from "../../modules/auth";
import {useSelector} from 'react-redux';
import ShareProposalModal from "./ShareProposalModal";
import {useLocation} from 'react-router-dom';
import {Succeeded} from "../Form";
import {ButtonBlack, ButtonPrimary} from "../Button";
import {EDGE_ENQUIRIES_EMAIL, ENQUIRY_STATUS} from "../../constants";
import ReadOnlySlide from "./ReadOnlySlide";

const RfpProposals = ({ data, isAgency }) => {
  const [confirmed, setConfirmed] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [activeVenue, setActiveVenue] = useState(null);
  const [confirmedVenue, setConfirmedVenue] = useState(null);
  const [showChaseVenueModal, setShowChaseVenueModal] = useState(false);
  const [showConfirmVenueModal, setShowConfirmVenueModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showRemoveVenueModal, setShowRemoveVenueModal] = useState(false);
  const userId = useSelector(userIdSelector);
  const isInternal = useSelector(isInternalSelector);
  const [submitData, setSubmitData] = useState(null);
  const [showShareProposalButton, setShowShareProposalButton] = useState(false);
  const [showShareProposalModal, setShowShareProposalModal] = useState(false);
  const [publicView, setPublicView] = useState(false);
  const [showInviteSuccessful, setShowInviteSuccessful] = useState(false);
  const location = useLocation()
  useEffect(() => {
    if (location.pathname.includes('proposal/view')) {
      setPublicView(true)
    }
  }, [location])

  useEffect(() => {
    // adding cleanup in case something weird happens
    return () => {
      setConfirmedVenue(null)
    }
  }, [])

  useEffect(() => {
    if (showInviteSuccessful) {
      setTimeout(() => setShowInviteSuccessful(false), 3000);
    }
  }, [showInviteSuccessful])

  const [showRequestSiteVisitModal, setShowRequestSiteVisitModal] = useState(
    false
  );
  const [showMessages, setShowMessages] = useState(activeVenue);
  const { data: availabilityTypes } = useService({
    initialData: [],
    service: getAvailabilityTypes,
    trigger: true,
  });

  const handleChaseVenue = venue => {
    setActiveVenue(venue);
    setShowChaseVenueModal(true);
  };

  const handleCloseModal = () => {
    setActiveVenue(null);
    setShowChaseVenueModal(false);
    setShowConfirmVenueModal(false);
    setShowExportModal(false);
    if(showRemoveVenueModal) {
      setShowRemoveVenueModal(false);
      window.location.reload()
    }
    setShowRequestSiteVisitModal(false);
    setShowShareProposalModal(false);
  };

  const handleConfirmVenue = venue => {
    setActiveVenue(venue);
    setShowConfirmVenueModal(true);
  };


  const handleExport = venue => {
    setActiveVenue(venue);
    setShowExportModal(true);
  };

  const handleRemoveVenue = (venue, rfpId, draft = false) => {
    setActiveVenue(venue);
    if (!draft) {
      setShowRemoveVenueModal(true);
    } else {
      setSubmitData({
        reason: "DRAFT",
        reason_other: '',
        venueId: venue.id,
        rfpId
      })
      return () => {
        setRemoved(prev => [...prev, venue.id]);
      }
    }
  };

  const { loading: submitting, data: complete, error } = useService({
    initialLoading: false,
    service: removeVenue,
    trigger: submitData,
    params: submitData,
  });

  useEffect(() => {
    if (complete) {
      window.location.reload();
    }
  }, [complete])

  const handleRequestSiteVisit = venue => {
    setActiveVenue(venue);
    setShowRequestSiteVisitModal(true);
  };

  const handleViewMessages = venue => {
    setActiveVenue(venue);
    setShowMessages(venue);
  };

  const shareEmailBody = (id, refId) => {
    const url = window.location.origin + (isInternal ? '/admin/rfp/' : '/admin/my-edge/your-rfps/') + id;

    const content = `Please provide feedback on the proposed:

  ${url}
  
Your Feedback:
    `;
    return encodeURIComponent(content);
  };

  const sortByInitialResponse = () => {
    return function(a, b) {
      // equal items sort equally
      if (a === b) {
        return 0;
      }
      // nulls sort after anything else
      else if (
        a === null ||
        a.proposal_data.created_at === null ||
        a.proposal_data.created_at === null
      ) {
        return 1;
      } else if (
        b === null ||
        b.venue_initial_response === null ||
        b.venue_initial_response.id === null
      ) {
        return -1;
      } else {
        return a.venue_initial_response.id < b.venue_initial_response.id
          ? -1
          : 1;
      }
    };
  };

  const sortByChecksheetDataReceived = (a, b) => {
    if (a.status < ENQUIRY_VENUE_DATA_STATUS.ALTERNATIVE_VENUE_BOOKED && b.status < ENQUIRY_VENUE_DATA_STATUS.ALTERNATIVE_VENUE_BOOKED) {
      if (a.status === b.status) {
        let checksheetDateA = moment(a.proposal_data.checksheet_submitted);
        let checksheetDateB = moment(b.proposal_data.checksheet_submitted);
        let checksheetDateAIsValid = checksheetDateA.isValid();
        let checksheetDateBIsValid = checksheetDateB.isValid();
        let bothValid = checksheetDateAIsValid && checksheetDateBIsValid;

        let aFirst = (checksheetDateAIsValid && !checksheetDateBIsValid) || (bothValid && checksheetDateA < checksheetDateB);
        let bFirst = (checksheetDateBIsValid && !checksheetDateAIsValid) || (bothValid && checksheetDateA > checksheetDateB);
        return aFirst ? -1 : (bFirst ? 1 : 0);
      } else {
        return b.status - a.status
      }
    }
    return a.status - b.status;
    }
  let sortedEnquiryVenueDatas = [];
  if ( data && data.enquiry_venue_datas && availabilityTypes.length ) {
    sortedEnquiryVenueDatas = data.enquiry_venue_datas
        .filter(item => !removed.includes(item.venue.id))
        .filter(item => Object.values(ENQUIRY_VENUE_DATA_STATUS).includes(item.status))
        .sort(sortByChecksheetDataReceived);
  }
  const slides = sortedEnquiryVenueDatas.map(enquiry_venue_data => () => {
    if(data.brief.enquiry_status.code === ENQUIRY_STATUS.CANCELLED) {
      return (
          <ReadOnlySlide
              brief={data.brief}
              venueConfirmed={
                  enquiry_venue_data.status === ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED
              }
              confirmed={confirmed}
              data={enquiry_venue_data}
              venue={enquiry_venue_data.venue}
              onConfirmVenue={handleConfirmVenue}
              onExport={handleExport}
              onRemove={handleRemoveVenue}
              onRequestSiteVisit={handleRequestSiteVisit}
              onViewMessages={handleViewMessages}
              availabilityTypes={availabilityTypes}
              isManaged={!!data.managing_trinity_user}
              publicView={publicView}
          />
      )
    }

    if(!enquiry_venue_data.is_visible_to_client && !isAgency) {
      return (
          <PreparingSlide
              venue={enquiry_venue_data.venue}
              rfpId={data.id}
              data={enquiry_venue_data}
              draft={data.brief.enquiry_status.code === "DRAFT"}
              onChaseVenue={handleChaseVenue}
              onRemove={handleRemoveVenue}
              isManaged={!!data.managing_trinity_user}
          />
      );
    }

    switch (enquiry_venue_data.status) {
      case ENQUIRY_VENUE_DATA_STATUS.OPEN:
      case ENQUIRY_VENUE_DATA_STATUS.INITIAL_RESPONSE_RECEIVED:
        return (
          <PreparingSlide
            venue={enquiry_venue_data.venue}
            rfpId={data.id}
            data={enquiry_venue_data}
            draft={data.brief.enquiry_status.code === "DRAFT"}
            onChaseVenue={handleChaseVenue}
            onRemove={handleRemoveVenue}
            isManaged={!!data.managing_trinity_user}
          />
        );
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DRAFTED:
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_AWAITING_VENUE_APPROVAL:
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_VENUE_APPROVED:
        return (
            <PreparingSlide
                venue={enquiry_venue_data.venue}
                data={enquiry_venue_data}
                onChaseVenue={handleChaseVenue}
                onRemove={handleRemoveVenue}
                isManaged={!!data.managing_trinity_user}
            />
        );
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED:
      case ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS:
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS:
      case ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED:
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED:
        if (!publicView && (enquiry_venue_data.status !== ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED)) {
          setShowShareProposalButton(true)
        }
        // using this for the export modal although the exports only need the enquiry id so not sure why the venue is required
        setConfirmedVenue(enquiry_venue_data.venue)
        return (
          <ApprovedSlide
            brief={data.brief}
            venueConfirmed={
              enquiry_venue_data.status === ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED
            }
            enquiry={data}
            confirmed={confirmed}
            data={enquiry_venue_data}
            venue={enquiry_venue_data.venue}
            onConfirmVenue={handleConfirmVenue}
            onExport={handleExport}
            onRemove={handleRemoveVenue}
            onRequestSiteVisit={handleRequestSiteVisit}
            onViewMessages={handleViewMessages}
            availabilityTypes={availabilityTypes}
            isManaged={!!data.managing_trinity_user}
            publicView={publicView}
          />
        );
      case ENQUIRY_VENUE_DATA_STATUS.DISQUALIFIED:
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_OPTED_OUT:
      case ENQUIRY_VENUE_DATA_STATUS.ALTERNATIVE_VENUE_BOOKED:
        return (
          <NaSlide
            data={enquiry_venue_data}
            venue={enquiry_venue_data.venue}
            onRemove={handleRemoveVenue}
          />
        );
      default:
        return null;
    }
  });
  let activeEvd =null;
  if ( activeVenue ) {
     activeEvd = data.enquiry_venue_datas.find(evd => evd.venue_id === activeVenue.id);
  }
  let title = "Venues Contacted";
  if (data.brief.enquiry_status.code === "DRAFT") {
    title = "Venues Suggested - Draft";
  }

  const shareProposal = () => {
    return (
      <div className="flex flex-wrap text-center items-center mb-3 bg-white p-5">
        <div className="w-full">
          <p>
            One or more venue has completed the RFP. Your proposal can be exported below, or you can confirm your booking with a chosen Venue below
          </p>
        </div>
        <div className="flex w-full text-center items-center justify-center">
          {!!data.managing_trinity_user && !isAgency && (
            <>
              <a
                className={'mx-2'}
                href={`mailto:${EDGE_ENQUIRIES_EMAIL}?Subject=Feedback on Proposal ${data.ref_id}&body=${shareEmailBody(data.id, data.ref_id)}`}
              >
                <ButtonPrimary>
                  Provide feedback on the proposal to the agent
                </ButtonPrimary>
              </a>
            </>
          )}
          <ButtonPrimary
            onClick={() => setShowShareProposalModal(!showShareProposalModal)}
          >
            Share Proposal
          </ButtonPrimary>
          <ButtonBlack
            classes="p-3 mx-2"
            onClick={() => handleExport(confirmedVenue)}
            style={{ height: 'auto', lineHeight: 1 }}
          >
            <i className="fas fa-share mr-2"></i>Export
          </ButtonBlack>
        </div>
      </div>
    )
  }
  return (
    <>
      <div>
        <h2 className="mb-3">{title}</h2>
        {showInviteSuccessful && <Succeeded text="Invite sent successfully"/>}
        {showShareProposalButton && shareProposal()}
      </div>

      {!!slides.length && <Slider slides={slides} />}

      {showChaseVenueModal && (
        <ChaseVenueModal onClose={handleCloseModal} venue={activeVenue} />
      )}
      {showShareProposalModal && <ShareProposalModal
        rfp={data}
        company={data?.company_details?.name}
        onClose={handleCloseModal}
        setShowInviteSuccessful={setShowInviteSuccessful}
      />}
      {showConfirmVenueModal && (
        <ConfirmVenueModal
          onClose={handleCloseModal}
          venue={activeVenue}
          onConfirm={() => {
            window.location.reload(false);
          }}
        />
      )}
      {showExportModal && activeVenue && (
        <ExportRfpProposalsModal
            onClose={handleCloseModal}
            data={data}
            enquiryData={activeEvd}
            loggedInUserId={userId}
        />
      )}
      {showRemoveVenueModal && (
        <RemoveVenueModal
          onClose={handleCloseModal}
          venue={activeVenue}
          onRemove={() => {
            setRemoved(prev => [...prev, activeVenue.id]);
          }}
        />
      )}
      {showRequestSiteVisitModal && (
        <RequestSiteVisitModal
          eventDate={data.brief.date_from}
          onClose={handleCloseModal}
          venue={activeVenue}
        />
      )}
      {showMessages && (
        <>
          <h2 className="mb-3">Messages to/from {showMessages.name}</h2>
          <ConfirmationThread
            venueId={showMessages.id}
            data={
              data.enquiry_venue_datas.find(
                item => item.venue_id === showMessages.id
              ).venue_confirmation
            }
          />
        </>
      )}
    </>
  );
};

export default RfpProposals;
