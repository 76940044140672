import axios from "axios";


const saveExclusionDate = async (token, venue, data ) => {
    try {
        const response = await axios.post(
            `${window.API}/venues/${venue}/exclusion-date-range`,
            {
                ...data
            },
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        return response.data;
    } catch (error) {

    }
};

const getExclusionDates = async (token, venue) => {
    try {
        const response = await axios.get(
            `${window.API}/venues/${venue}/exclusion-date-range`,
            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        return response.data;
    } catch (error) {

    }
};

const deleteExclusionDates = async (token, venue, date) => {
    try {
        const response = await axios.delete(
            `${window.API}/venues/${venue}/exclusion-date-range/${date}`,

            {
                headers: {Authorization: `Bearer ${token}`},
            }
        );
        return response.data;
    } catch (error) {

    }
};

export {saveExclusionDate, getExclusionDates, deleteExclusionDates}