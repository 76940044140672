import React from "react";
import { ButtonBrand } from "Components/Button";

const img = "/img/landing/edge-venues-plus/london-city-skyline-40.jpg";
const title =
  "Upgrade to EDGE Venues+ to unlock several fantastic features and benefits to deliver great returns on your business. ";

const bannerFeatures = [
  {
    icon: "/img/landing/edge-venues-plus/engagement-small.png",
    text: "Engagement",
  },
  {
    icon: "/img/landing/edge-venues-plus/search-priority-small.png",
    text: "Priority in Venue Searches",
  },
  {
    icon: "/img/landing/edge-venues-plus/rfp-small.png",
    text: "RFP",
  },
  {
    icon: "/img/landing/edge-venues-plus/analytics-small.png",
    text: "Analytics",
  },
  {
    icon: "/img/landing/edge-venues-plus/social-media-small.png",
    text: "Social Media",
  },
  {
    icon: "/img/landing/edge-venues-plus/industry-trends-small.png",
    text: "Industry Trends",
  },
];

const featureBoxes = [
  {
    icon: "/img/landing/edge-venues-plus/engagement.png",
    title: "Engagement",
    copy:
      "<p>Maximise your sales opportunities by getting your exciting news and offers out to corporates and agents at the touch of the button.</p>",
  },
  {
    icon: "/img/landing/edge-venues-plus/search-priority.png",
    title: "Search Priority",
    copy:
      "<p>Competition is tough – Get your venues prioritised on the search. Remember people rarely scroll to what comes further down a page!</p>",
  },
  {
    icon: "/img/landing/edge-venues-plus/rfp.png",
    title: "RFP",
    copy:
      "<p>Receive direct enquiries and reduce your cost of sale with the simple RFP tool that not only uses your venue profile to ease completion but also updates your profile to save time next time. The RFP tool is a vital tool in helping Agents and Corporates search and decide on venues.</p>",
  },
  {
    icon: "/img/landing/edge-venues-plus/analytics.png",
    title: "Analytics",
    copy:
      "<p>Who is enquiring with you? Who has said your venue is too expensive? Get this information and so much more from your venue analytics. The window to maximising your future business opportunities through EDGE Venues. </p>",
  },
  {
    icon: "/img/landing/edge-venues-plus/social-media.png",
    title: "Social Media",
    copy:
      "<p>Social media advertising is a cost many venues can’t afford! Let us drive traffic to your social media channels and share offers and news. Direct links are available to your own social media channels on your profile. </p>",
  },
  {
    icon: "/img/landing/edge-venues-plus/industry-trends.png",
    title: "Industry Trends",
    copy:
      "<p>Knowledge is King! Use our quarterly industry trends report to keep you abreast of what is happening and using it as a tool to keep ahead of your competitors.</p>",
  },
];

const UpgradeToEdgeVenuesPlus = () => {
  return (
    <div className="container">
      <div
        className="p-3 md:p-8 text-white flex flex-wrap"
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: "#2d2d2d",
        }}
      >
        <div className="w-full md:pr-10 lg:w-3/5">
          <h1 className="mb-10">{title}</h1>
          <div className="row">
            {bannerFeatures.map((feature, i) => (
              <div className="col col-w-full md:col-w-1/3" key={i}>
                <img className="block mx-auto" src={feature.icon} alt="" />
                <h3
                  className="text-center mb-10"
                  dangerouslySetInnerHTML={{ __html: feature.text }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="p-10 w-full lg:w-2/5" style={{ background: "#fff" }}>
          <h2 class="text-center" style={{ color: "#2d2d2d" }}>
            EDGE Venues+
          </h2>
          <p class="text-center text-lg" style={{ color: "#2d2d2d" }}>
            for only &pound;75 <span class="text-sm">Excl. VAT</span>
            <span class="text-sm block">a month</span>
          </p>
          <p style={{ color: "#2d2d2d" }}>
            By upgrading to EDGE Venues+, you’ll benefit from the following
            licence benefits detailed below. We offer all these fantastic
            benefits for just &pound;75 (excl. VAT) per month with the option of
            paying by direct debit.
          </p>
          <ButtonBrand
            className="font-bold text-lg uppercase md:w-3/4 xl:w-1/2 mt-10 text-center mx-auto block text-white bg-brand border-brand h-64"
            Element="a"
            href={"mailto:sales@edgevenues.com"}
          >
            Upgrade today
          </ButtonBrand>
        </div>
      </div>
      <h2 className="block text-center mt-10 mb-5">
        Why should I upgrade my venue?
      </h2>
      <div className="row">
        <div className="p-3">
          <div className="row">
            {featureBoxes.map((feature, i) => (
              <div
                className="col col-w-full sm:col-w-1/2 xl:col-w-1/3 mb-4"
                key={i}
              >
                <div className="bg-white h-full p-5">
                  <span className="text-center text-5xl mt-10">
                    <img
                      className="block w-1/3 mx-auto"
                      src={feature.icon}
                      alt=""
                    />
                  </span>
                  <h3 className="text-center mb-3">{feature.title}</h3>
                  <p
                    className="text-center"
                    dangerouslySetInnerHTML={{ __html: feature.copy }}
                  ></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeToEdgeVenuesPlus;
