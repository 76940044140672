import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import qs from "query-string";
import {  isRoleTrinityEdgeRFP, userAnalyticsGroup, userType, userId } from "./auth";

// Singleton state, sorry
let userState = { userInternal: false, userType: "Guest" };

export const setUserType = state => {
  userState = {
    userInternal: isRoleTrinityEdgeRFP(state),
    userType: userType(state) || "Guest",
    userGroup: userAnalyticsGroup(state),
    userId: userId(state),
  };
};

const getDataLayer = () => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  return window.dataLayer;
};

export const pushEvent = (eventName, data) => {
  const dataLayer = getDataLayer();
//  console.log({ event: eventName, ...userState, ...data });
  dataLayer.push({ event: eventName, ...userState, ...data });
};

export const pushEcommerce = (event, data) => {
  const dataLayer = getDataLayer();
//  console.log({
//    event,
//    ecommerce: {
//      detail: {
//        products: [
//          {
//            ...data,
//          },
//        ],
//      },
//    },
//  });
  dataLayer.push({
    event,
    ecommerce: {
      detail: {
        products: [
          {
            ...data,
          },
        ],
      },
    },
  });
};


export const pushSubmitEnquiry = (enquiryId, data) => {
  const dataLayer = getDataLayer();
  const eventState = {
    event: 'submit_enquiry_success',
    formtype: 'enquiry'
  }
  let venues = data.map(venue => ({
    id: venue,
  }))

  dataLayer.push(
      {
        ...eventState,
        ...userState,
        enquiryId: {
          ecommerce: {
            purchase: {
              actionField: {
                id: enquiryId,
                affiliation: ' '

              },
              products: venues
            }
          }
        }
      }
  );
};

export const pushCheckOutStep = (stepNumber, data, url) => {
  const dataLayer = getDataLayer();
  const eventState = {
    event: 'pageview',
    pageviewUrl: url,
  }
  let venues = data.map(venue => ({
    id: venue,
    quantity: 1,
  }))

  dataLayer.push(
      {
        ...eventState,
        ...userState,
        ecommerce: {
          checkout: {
            actionField : {'step': stepNumber},
            products: venues
          }
        }
      }
  );
};

export const RouteTrackerComponent = ({ location, history }) => {
  // Track all location changes, including the initial page load
  useEffect(() => {
    // When a user follows the verify email link from the registration email
    // they arrive at the home page with ?message=account_activated
    const { message, ...queryStringWithoutMessage } = qs.parse(location.search);

    if (message === "account_activated") {
      pushEvent("user_activated");

      // New query string without "message"
      const queryString = qs.stringify(queryStringWithoutMessage);

      // Update the browser location without the `message` param.
      // Using history.replace instead of history.push so the user can not
      // press back and trigger the event again.
      history.replace(
        `${location.pathname}${queryString ? `?${queryString}` : ""}`
      );

      // Prevent GoogleTag seeing 2 "pageview" events by returning early.
      return;
    }

    // Google Tags Manager "pageview" event
    pushEvent("pageview", {
      pageviewUrl: `${location.pathname}${location.search}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return null;
};

export const RouteTracker = withRouter(RouteTrackerComponent);
