import React from 'react';
import RegistrationCTA from 'Components/RegistrationCTA';
import { ButtonLink } from 'Components/Button';
import { FaAngleRight } from 'react-icons/fa'
import * as ROUTES from "../../Router/public_routes";

const RegistrationCTARow = () => {

  return (
      <div className="border-green bg-light-grey border-b-12">
        <div className="container pt-10 pb-10">
          <h1 className="text-2rem md:text-4xl mb-8 text-center">Register now &ndash; what's in it for you?</h1>
          {/*<p className="font-normal font-heading leading-none leading-tight md:font-semibold text-2xl mt-8 md:mt-3 mb-8">Bringing you all the information you need to find the best venue for your event at the best price</p>*/}
          <div className="flex flex-wrap mt-4">
            <div className="col col-w-full md:col-w-1/3 flex lg:static mb-8 lg:mb-0 justify-center">
              <RegistrationCTA
                  title="If you’re a hotel or venue"
                  img={`img/landing/venue-feature.jpg`}
                  content={
                    <>
                      <ul className="text-md font-normal leading-tight pl-4">
                        <li>Free to register</li>
                        <li>Manage your own data (125 fields)</li>
                        <li>Unlimited photographs</li>
                        <li>Simple and effective RFP process</li>
                        <li>Exposure to corporate buyers and agents</li>
                        <li>Ability to add your 24hr, DDR and buy it now prices</li>
                      </ul>
                      <div className="flex items-center mt-4">
                        <ButtonLink Element="a" href="/venues" classes="text-lg">
                          Register now
                        </ButtonLink>
                        <FaAngleRight className="ml-4 text-black text-3xl" />
                      </div>

                    </>
                  }
              />
            </div>
            <div className="col col-w-full md:col-w-1/3 flex lg:static mb-8 lg:mb-0 justify-center">
              <RegistrationCTA
                  title="If you’re an event organiser with a company or corporate"
                  img={`img/landing/corporate-feature.jpg`}
                  content={
                    <>
                      <ul className="text-md font-normal leading-tight pl-4">
                        <li>Free to register and use</li>
                        <li>19,000 venues at your fingertips</li>
                        <li>Simple and effective search</li>
                        <li>Save, compare and share venue information</li>
                        <li>Fast and effective RFP process</li>
                        <li>Stay up to date with latest industry news, offers and insights</li>
                      </ul>
                      <div className="flex items-center mt-4">
                        <ButtonLink Element="a" href={ROUTES.FRONTEND.REGISTER.CORPORATE.replace(':agency', 'edge')} classes="text-lg">
                          Register now
                        </ButtonLink>
                        <FaAngleRight className="ml-4 text-black text-3xl" />
                      </div>
                    </>
                  }
              />
            </div>
            <div className="col col-w-full md:col-w-1/3 flex lg:static mb-8 lg:mb-0 justify-center">
              <RegistrationCTA
                  title="If you’re an agent"
                  img={`img/landing/agency-feature.jpg`}
                  content={
                    <>
                      <ul className="text-md font-normal leading-tight pl-4">
                        <li>Free to register and use</li>
                        <li>19,000 venues at your fingertips</li>
                        <li>Save, compare and share venue information with your own logo</li>
                        <li>Fast and effective direct RFP process</li>
                        <li>Stay up to date with latest industry news, offers and insights</li>
                        <li>Exclusive commission deals and FAM trips</li>
                      </ul>
                      <div className="flex items-center mt-4">
                        <ButtonLink Element="a" href="/agents" classes="text-lg">
                          Register now
                        </ButtonLink>
                        <FaAngleRight className="ml-4 text-black text-3xl" />
                      </div>

                    </>
                  }
              />
            </div>
            {/*<div className="col col-w-full md:col-w-1/4 flex lg:static mb-8 lg:mb-0 justify-center">*/}
            {/*  <RegistrationCTA*/}
            {/*      title="Association, Charity or Freelancer"*/}
            {/*      img={`/img/landing/other-feature.jpg`}*/}
            {/*      content={*/}
            {/*        <>*/}
            {/*          <ul className="text-md font-normal leading-tight pl-4">*/}
            {/*            <li>Save, compare and share venue information</li>*/}
            {/*            <li>Send enquiries and book directly with venues</li>*/}
            {/*            <li>Receive special offers and invitations to fam trips</li>*/}
            {/*            <li>Be the first to know about new openings, refurbishments and venue news</li>*/}
            {/*            <li>Keep up to date with the latest industry trends and data</li>*/}
            {/*            <li>Access to top tips and help from experts</li>*/}
            {/*            <li>Be the first to know about new openings, refurbishments</li>*/}
            {/*          </ul>*/}
            {/*          <div className="flex items-center mt-4">*/}
            {/*            <ButtonLink Element="a" href="/register" classes="text-lg">*/}
            {/*              Register now*/}
            {/*            </ButtonLink>*/}
            {/*            <FaAngleRight className="ml-4 text-black text-3xl" />*/}
            {/*          </div>*/}

            {/*        </>*/}
            {/*      }*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
  );
};

export default RegistrationCTARow;
