import React, {useState} from 'react';
import Modal from 'Components/Modal';
import {ButtonSpan} from 'Components/Button';
import {LabelledText} from 'ui';
import useService from 'hooks/useService';
import {getEquipment} from 'services/rfpService';
import {getRoomStyles} from 'services/roomStyleService';
import Additional from './Additional';
import getSaving from './get-saving';
import {currencyFormatter, formattedApplicableDates, getCurrencySymbol} from "utils";
import ToggleRevealer from './Components/ToggleRevealer';
import BreakdownTable from './Components/BreakdownTable';
import {getVatRateById} from '../../pages/admin/rfp/Confirmation/Helpers/calculateTotals';
import MeetingRoomsModal from "../../pages/Venue/MeetingRoomsModal";
import getSingleSaving from "./get-single-saving";

const RoomsPriceBreakdownModal = ({
                                      brief,
                                      meetingRooms,
                                      onClose,
                                      total = {},
                                      rooms,
                                      equipment,
                                      venueId,
                                      attachments,
                                      notes,
                                      vatRates = [],
                                      title = 'Meeting Rooms',
                                      enquiryVenueData,
                                      currency = {currency_code: "GBP"}
                                  }) => {
    const {data: equipmentItems} = useService({
        initialData: [],
        params: venueId,
        service: getEquipment,
        trigger: venueId,
    });
    const {data: roomStyles} = useService({
        initialData: [],
        service: getRoomStyles,
        trigger: true,
    });
    const [modelOpen, setModelOpen] = useState(false);

    if (vatRates.length < 1) {
        return null;
    }
    let getEquipmentType = (item) => {
        return equipmentItems.find(
            ei => ei.value === item.equipment_id,
        ) || {}
    }
    return (
        <Modal>
            <div className="modal">
                <div
                    className="modal-inner"
                    style={{
                        width: 1200,
                        maxWidth: '90%',
                    }}
                >
                    <ButtonSpan
                        className="m-3 absolute pin-t pin-r z-10"
                        onClick={onClose}
                    >
                        <i className="fas fa-times-circle fa-2x ml-3"/>
                    </ButtonSpan>
                    <div>
                        <h2 className="mb-3 text-3xl">{title}</h2>
                        {meetingRooms.map(
                            ({
                                 id,
                                 capacity,
                                 square_meterage,
                                 location_or_floor,
                                 aircon,
                                 natural_daylight,
                                 price_total_net,
                                 price_total_vat,
                                 price_vat_rate_id,
                                 price_reduced_from_total_net,
                                 price_reduced_from_total_vat,
                                 price_percentage_saving,
                                 room_style_id,
                                 min_number_of_delegates_for_qualify,
                                 room,
                                 room_purpose,
                                 overnight_room_data,
                                 setup_data,
                                 breakdown_data,
                                 applicable_days
                             }) => (
                                <div className="mb-5 pt-3 border-t border-black" key={id}>
                                    <h3 className="mb-3">{room.name}</h3>
                                    <div className="flex flex-wrap">
                                        <LabelledText
                                            Wrapper="div"
                                            className="mr-8"
                                            label="Room purpose"
                                            text={room_purpose.purpose}
                                        />
                                        <LabelledText
                                            Wrapper="div"
                                            className="mr-8"
                                            label="Room style"
                                            text={
                                                (
                                                    roomStyles.find(
                                                        style => style.value === room_style_id,
                                                    ) || {}
                                                ).text
                                            }
                                        />
                                        <LabelledText
                                            Wrapper="div"
                                            className="mr-8"
                                            label="Capacity"
                                            text={capacity}
                                        />
                                        <LabelledText
                                            Wrapper="div"
                                            className="mr-8"
                                            label="Square Meterage"
                                            text={square_meterage}
                                        />
                                        <LabelledText
                                            Wrapper="div"
                                            className="mr-8"
                                            label="Minimum Number"
                                            text={Number(min_number_of_delegates_for_qualify) > 0 ? min_number_of_delegates_for_qualify : 'N/A'}
                                        />
                                    </div>
                                    {applicable_days !== null ?
                                        <div className="flex flex-wrap">
                                            <LabelledText
                                                Wrapper="div"
                                                className="mr-8"
                                                label="Dates Room Available"
                                                text={formattedApplicableDates((enquiryVenueData.date_from_override ? enquiryVenueData.date_from_override : brief.date_from), (enquiryVenueData.date_to_override ? enquiryVenueData.date_to_override : brief.date_to), applicable_days.split(',').map(d => Number(d))).join(', ')}
                                            />
                                        </div> : null}
                                    <div className="flex flex-wrap">
                                        <LabelledText
                                            Wrapper="div"
                                            className="mr-8"
                                            label="Location/floor"
                                            text={location_or_floor}
                                        />
                                        <LabelledText
                                            Wrapper="div"
                                            className="mr-8"
                                            label="Aircon"
                                            text={aircon ? 'Yes' : 'No'}
                                        />
                                        <LabelledText
                                            Wrapper="div"
                                            className="mr-8"
                                            label="Natural daylight"
                                            text={natural_daylight ? 'Yes' : 'No'}
                                        />
                                        {price_total_net ? (
                                            <>
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                                                    text={currencyFormatter(currency.currency_code).format(price_reduced_from_total_net)}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label={"Total (" + getCurrencySymbol(currency) + ")"}
                                                    text={currencyFormatter(currency.currency_code).format(price_total_net)}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label="Cost saving (%)"
                                                    text={`${price_percentage_saving}%`}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8"
                                                    label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                                    text={getSingleSaving(price_reduced_from_total_net, price_total_net, currency)}
                                                />
                                            </>
                                        ) : (
                                            <LabelledText
                                                Wrapper="div"
                                                className="mr-8"
                                                label="Price"
                                                text="Included in package"
                                            />
                                        )}
                                        {!overnight_room_data && (
                                            <LabelledText
                                                Wrapper="div"
                                                className="mr-8"
                                                label="Overnight Hold"
                                                text="No"
                                            />
                                        )}
                                    </div>
                                    {price_total_net ?
                                        <div className="flex flex-wrap">
                                            <ToggleRevealer toggleText="Show Tax Breakdown">
                                                <BreakdownTable
                                                    headings={['Reduced from', 'Total']}
                                                    vatRate={getVatRateById(vatRates, price_vat_rate_id).number}
                                                    currency={currency}
                                                    rows={[
                                                        [
                                                            currencyFormatter(currency.currency_code).format(price_reduced_from_total_net),
                                                            currencyFormatter(currency.currency_code).format(price_total_net),
                                                        ],
                                                        [
                                                            currencyFormatter(currency.currency_code).format(price_reduced_from_total_vat),
                                                            currencyFormatter(currency.currency_code).format(price_total_vat),
                                                        ],

                                                        [
                                                            currencyFormatter(currency.currency_code).format(Number(price_reduced_from_total_net) + Number(price_reduced_from_total_vat)),
                                                            currencyFormatter(currency.currency_code).format(Number(price_total_net) + Number(price_total_vat)),
                                                        ],
                                                    ]}/>
                                            </ToggleRevealer>
                                        </div>
                                        : null}
                                    {overnight_room_data ? (
                                        <div>
                                            <h4 className="mb-3">Overnight Hold</h4>
                                            <div className="flex flex-wrap">
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8"
                                                    label="Dates/Access time"
                                                    text={overnight_room_data.access_time}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                                                    text={currencyFormatter(currency.currency_code).format(
                                                        Number(overnight_room_data.overnight_price_reduced_from_net),
                                                    )}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label={"Cost (" + getCurrencySymbol(currency) + ")"}
                                                    text={currencyFormatter(currency.currency_code).format(
                                                        overnight_room_data.overnight_price_net,
                                                    )}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label="Cost saving (%)"
                                                    text={`${overnight_room_data.overnight_price_percentage_saving}%`}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8"
                                                    label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                                    text={getSingleSaving(overnight_room_data.overnight_price_reduced_from_net, overnight_room_data.overnight_price_net, currency)}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {overnight_room_data ?
                                        <div className="flex flex-wrap">
                                            <ToggleRevealer toggleText="Show Tax Breakdown">
                                                <BreakdownTable
                                                    headings={['Reduced from', 'Cost']}
                                                    vatRate={getVatRateById(vatRates, overnight_room_data.overnight_price_vat_rate_id).number}
                                                    currency={currency}
                                                    rows={[
                                                        [
                                                            currencyFormatter(currency.currency_code).format(overnight_room_data.overnight_price_reduced_from_net),
                                                            currencyFormatter(currency.currency_code).format(overnight_room_data.overnight_price_net),
                                                        ],
                                                        [
                                                            currencyFormatter(currency.currency_code).format(overnight_room_data.overnight_price_reduced_from_vat),
                                                            currencyFormatter(currency.currency_code).format(overnight_room_data.overnight_price_vat),
                                                        ],

                                                        [
                                                            currencyFormatter(currency.currency_code).format(Number(overnight_room_data.overnight_price_reduced_from_net) + Number(overnight_room_data.overnight_price_reduced_from_vat)),
                                                            currencyFormatter(currency.currency_code).format(Number(overnight_room_data.overnight_price_net) + Number(overnight_room_data.overnight_price_vat)),
                                                        ],
                                                    ]}/>
                                            </ToggleRevealer>
                                        </div>
                                        : null}

                                    {setup_data ? (
                                        <div>
                                            <h4 className="mb-3">Setup Cost</h4>
                                            <div className="flex flex-wrap">
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8"
                                                    label="Access time"
                                                    text={setup_data.access_time}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                                                    text={currencyFormatter(currency.currency_code).format(
                                                        Number(setup_data.setup_price_reduced_from_net),
                                                    )}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label={"Cost (" + getCurrencySymbol(currency) + ")"}
                                                    text={currencyFormatter(currency.currency_code).format(
                                                        setup_data.setup_price_net,
                                                    )}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label="Cost saving (%)"
                                                    text={`${setup_data.setup_price_percentage_saving}%`}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8"
                                                    label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                                    text={getSingleSaving(setup_data.setup_price_reduced_from_net, setup_data.setup_price_net, currency)}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {setup_data ?
                                        <div className="flex flex-wrap">
                                            <ToggleRevealer toggleText="Show Tax Breakdown">
                                                <BreakdownTable
                                                    headings={['Reduced from', 'Cost']}
                                                    vatRate={getVatRateById(vatRates, setup_data.setup_price_vat_rate_id).number}
                                                    currency={currency}
                                                    rows={[
                                                        [
                                                            currencyFormatter(currency.currency_code).format(setup_data.setup_price_reduced_from_net),
                                                            currencyFormatter(currency.currency_code).format(setup_data.setup_price_net),
                                                        ],
                                                        [
                                                            currencyFormatter(currency.currency_code).format(setup_data.setup_price_reduced_from_vat),
                                                            currencyFormatter(currency.currency_code).format(setup_data.setup_price_vat),
                                                        ],

                                                        [
                                                            currencyFormatter(currency.currency_code).format(Number(setup_data.setup_price_reduced_from_net) + Number(setup_data.setup_price_reduced_from_vat)),
                                                            currencyFormatter(currency.currency_code).format(Number(setup_data.setup_price_net) + Number(setup_data.setup_price_vat)),
                                                        ],
                                                    ]}/>
                                            </ToggleRevealer>
                                        </div>
                                        : null}

                                    {breakdown_data ? (
                                        <div>
                                            <h4 className="mb-3">Breakdown Cost</h4>
                                            <div className="flex flex-wrap">
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8"
                                                    label="Dates/Access Time"
                                                    text={breakdown_data.access_time}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                                                    text={currencyFormatter(currency.currency_code).format(
                                                        Number(breakdown_data.breakdown_price_reduced_from_net),
                                                    )}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label={"Cost (" + getCurrencySymbol(currency) + ")"}
                                                    text={currencyFormatter(currency.currency_code).format(
                                                        breakdown_data.breakdown_price_net,
                                                    )}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8 text-right"
                                                    label="Cost saving (%)"
                                                    text={`${breakdown_data.breakdown_price_percentage_saving}%`}
                                                />
                                                <LabelledText
                                                    Wrapper="div"
                                                    className="mr-8"
                                                    label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                                    text={getSingleSaving(breakdown_data.breakdown_price_reduced_from_net, breakdown_data.breakdown_price_net, currency)}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    {breakdown_data ?
                                        <div className="flex flex-wrap">
                                            <ToggleRevealer toggleText="Show Tax Breakdown">
                                                <BreakdownTable
                                                    headings={['Reduced from', 'Cost']}
                                                    vatRate={getVatRateById(vatRates, breakdown_data.breakdown_price_vat_rate_id).number}
                                                    currency={currency}
                                                    rows={[
                                                        [
                                                            currencyFormatter(currency.currency_code).format(breakdown_data.breakdown_price_reduced_from_net),
                                                            currencyFormatter(currency.currency_code).format(breakdown_data.breakdown_price_net),
                                                        ],
                                                        [
                                                            currencyFormatter(currency.currency_code).format(breakdown_data.breakdown_price_reduced_from_vat),
                                                            currencyFormatter(currency.currency_code).format(breakdown_data.breakdown_price_vat),
                                                        ],

                                                        [
                                                            currencyFormatter(currency.currency_code).format(Number(breakdown_data.breakdown_price_reduced_from_net) + Number(breakdown_data.breakdown_price_reduced_from_vat)),
                                                            currencyFormatter(currency.currency_code).format(Number(breakdown_data.breakdown_price_net) + Number(breakdown_data.breakdown_price_vat)),
                                                        ],
                                                    ]}/>
                                            </ToggleRevealer>
                                        </div>
                                        : null}
                                    <div className="flex flex-wrap">
                                        <button
                                            className="text-sm font-heading bg-grey p-1 mb-2 border-0 focus:border-0 hover:border-0 focus:outline-none focus:bg-light-grey"
                                            onClick={() => {
                                                setModelOpen(true);
                                            }}>{'Show Meeting Room Specifications'}</button>
                                        {modelOpen ? <MeetingRoomsModal
                                            closeModal={() => setModelOpen(false)}
                                            room={room}
                                        /> : null}
                                    </div>

                                </div>
                            ),
                        )}
                        {equipment.length > 0 ? (
                            <div className="mb-5 pt-3 border-t border-black">
                                <h3 className="mb-3">Equipment</h3>
                                <table>
                                    <tbody>
                                    {equipment.map((item, i) => (
                                        <>
                                            <tr key={item.id}>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8"
                                                        label="Type"
                                                        hideLabel={!!i}
                                                        text={
                                                            getEquipmentType(item).text === 'Other' ? item.equipment_name : (
                                                                getEquipmentType(item)
                                                            ).text
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8"
                                                        label="Location"
                                                        hideLabel={!!i}
                                                        text={
                                                            item.room.name
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Quantity"}
                                                        hideLabel={!!i}
                                                        text={item.quantity}
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Unit Price Reduced From"}
                                                        hideLabel={!!i}
                                                        text={currencyFormatter(currency.currency_code).format(item.cost.unit_price_reduced_from ? item.cost.unit_price_reduced_from : item.cost.price_reduced_from_net)}
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Unit Price"}
                                                        hideLabel={!!i}
                                                        text={currencyFormatter(currency.currency_code).format(item.cost.unit_price ? item.cost.unit_price : item.cost.price_net)}
                                                    />
                                                </td>

                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Reduced from (" + getCurrencySymbol(currency) + ")"}
                                                        hideLabel={!!i}
                                                        text={currencyFormatter(currency.currency_code).format(item.cost.price_reduced_from_net)}
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Cost (" + getCurrencySymbol(currency) + ")"}
                                                        hideLabel={!!i}
                                                        text={currencyFormatter(currency.currency_code).format(item.cost.price_net)}
                                                    />
                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label="Cost saving (%)"
                                                        hideLabel={!!i}
                                                        text={`${item.cost.percentage_saving}%`}
                                                    />


                                                </td>
                                                <td>
                                                    <LabelledText
                                                        Wrapper="div"
                                                        className="mr-8 text-right"
                                                        label={"Cost saving (" + getCurrencySymbol(currency) + ")"}
                                                        hideLabel={!!i}
                                                        text={getSingleSaving(item.cost.price_reduced_from_net, item.cost.price_net, currency)}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="5">
                                                    <ToggleRevealer toggleText="Show Tax Breakdown">
                                                        <BreakdownTable
                                                            headings={['Reduced from', 'Cost']}
                                                            vatRate={getVatRateById(vatRates, item.cost.price_vat_rate_id).number}
                                                            currency={currency}
                                                            rows={[
                                                                [
                                                                    currencyFormatter(currency.currency_code).format(item.cost.price_reduced_from_net),
                                                                    currencyFormatter(currency.currency_code).format(item.cost.price_net),
                                                                ],
                                                                [
                                                                    currencyFormatter(currency.currency_code).format(item.cost.price_reduced_from_vat),
                                                                    currencyFormatter(currency.currency_code).format(item.cost.price_vat),
                                                                ],

                                                                [
                                                                    currencyFormatter(currency.currency_code).format(Number(item.cost.price_reduced_from_net) + Number(item.cost.price_reduced_from_vat)),
                                                                    currencyFormatter(currency.currency_code).format(Number(item.cost.price_net) + Number(item.cost.price_vat)),
                                                                ],
                                                            ]}/>
                                                    </ToggleRevealer>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : null}
                        <div className="mb-5 pt-3 border-t border-black">
                            <h3 className="mb-3">Total</h3>
                            <div className="flex flex-wrap">
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label={currency.currency_code === 'GBP' ? `Total Including VAT (${getCurrencySymbol(currency)})` : `Total including Tax (${getCurrencySymbol(currency)})`}
                                    text={currencyFormatter(currency.currency_code).format(total.total_inc_vat)}
                                />
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label={currency.currency_code === 'GBP' ? `Total Excluding VAT (${getCurrencySymbol(currency)})` : `Total Excluding Tax (${getCurrencySymbol(currency)})`}
                                    text={currencyFormatter(currency.currency_code).format(total.total_exc_vat)}
                                />
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label={"Reduced From (" + getCurrencySymbol(currency) + ")"}
                                    text={currencyFormatter(currency.currency_code).format(total.reduced_from_exc_vat)}
                                />
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label="Cost Saving (%)"
                                    text={getSaving(total, currency)}
                                />
                            </div>
                        </div>
                        <Additional attachments={attachments} notes={notes}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RoomsPriceBreakdownModal;
