import React, {useEffect, useState} from "react";
import {fetchPriceBandingData, fetchVenuePriceBandingData} from "../../services/priceBandingService/fetchPriceBandings";
import {token} from "../../modules/auth";
import {connect} from "react-redux";
import PriceBandingBannerInner from "./PriceBandingBannerInner";


const mapDispatchToProps = {};

const mapStateToProps = state => ({
    token: token(state),
});



let PriceBandingBanner = ({token, venueSlug, type, title =  false, vPB = false, PB = false}) => {
    let [priceBandings, setPriceBandings] = useState(PB)
    let [venuePriceBandings, setVenuePriceBandings] = useState(vPB)
    let [packageBanding, setPackageBanding] = useState(false)
    useEffect(() => {
        if(!PB) {
            fetchPriceBandingData(token).then((response) => {
                setPriceBandings(response)
            })
        }
        if(!vPB) {
            fetchVenuePriceBandingData(token, venueSlug).then((response) => {
                if(response && Object.keys(response).length !== 0) {
                    setVenuePriceBandings(response)

                    setPackageBanding(response[type + '_price_banding_rate']);

                }
            })
        }else{
            setPackageBanding(venuePriceBandings[type + '_price_banding_rate']);
        }

    }, []);

    if(type === 'twenty_four_hr' && venuePriceBandings && !venuePriceBandings['show_24hr_on_profile'] && !title) {
        return <></>
    }
    if(!packageBanding && !title) {
        return <></>
    }
    return (
        <PriceBandingBannerInner priceBandings={priceBandings} packageBanding={packageBanding} venuePriceBandings={venuePriceBandings} type={type} title={title} />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceBandingBanner);