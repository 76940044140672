import React from 'react'
import {ButtonBrand} from "../../../Components/Button";
import Panel from "../../../ui/Panel";
import audience from "../../admin/Analytics/Audience";

let statusColor = (auction) => {
    switch (auction.status) {
        case 'Starts Soon':
            return 'bg-orange'
        case 'Running':
            return 'bg-dark-green'
        case 'Ended':
            return 'bg-black'
    }
}
function validateText(string) {
    return /(http(s?)):\/\//i.test(string);
}
let venueImage = (auction) => <a href={auction.auctionUrl}><div
    className="re-16/9 w-full bg-no-repeat bg-cover bg-center"
    style={{
        backgroundImage: `url(${auction.imageUrl})`,
    }}
/></a>

let venueDonatedBy = (auction) => <div
    className="bg-edge-blue text-white text-sm font-bold px-2 py-2"
    // style={{padding: '10px', height: '75px'}}
>
    <p className={'mb-0'}><span className={'opacity-50 text-xxs font-normal'}>#{auction.lotId} donated by<br/></span>
        <a href={validateText(auction.venueSlug) ? auction.venueSlug : `/venue/${auction.venueSlug}`} className={'text-white'}>{auction.venueName} </a>
    </p>
</div>

let venueText = (auction) => <div className="py-3 px-2 font-bold">
    <a href={auction.auctionUrl} className={'text-black'}>{auction.text}</a>
</div>

let bidAmount = (auction) => <>
    &pound;{auction.soldPrice}
    </>

let reserveAmount = (auction) => <>
    &pound;{auction.reserve}
</>

let bidButton = (auction, page, generalInteraction) => {
    if(auction.status === 'Ended') {
        return <span><span className={'font-regular text-dark-grey'}>Sold:</span> {bidAmount(auction)} raised!</span>
    }
    return (
        <div className={'mt-auto'}>
            <a href={auction.auctionUrl}  onClick={() => {
                generalInteraction({type: "general_auction_venue_" + page + "_click", data: auction.lotId})
            }}>
                    <span className={'font-regular text-dark-grey'}>Min. Bid:</span> {reserveAmount(auction)}

            <ButtonBrand
                classes="p-3 w-full mt-2"
                style={{height: "auto", lineHeight: 1}}
            >
                Bid / View Details
            </ButtonBrand></a>
        </div>
    )
}

let starIcon = (auction, top, left,) => {
    if(!auction.starPrize) return <></>
    return  <div className="inline-block absolute"  style={{top: top, left: left, zIndex: '999'}}>
        <i className="fas fa-star text-yellow" title={'Star prize'} />
    </div>
}




let CharityAuctionSlide = ({auction, generalInteraction}) => {
    return (
        <Panel additionalClasses={'mx-4 shadow flex flex-col flex-grow relative h-full'}>
                {venueImage(auction)}
                {venueDonatedBy(auction)}
                <div className="inline-block absolute"  style={{top: '20px', left: '20px', zIndex: '999'}}>
                    <div
                        className={'rounded-sm py-1 px-2 uppercase text-white text-xs font-normal ' + statusColor(auction)}>
                        {`#${auction.lotId}`} - {auction.status}
                    </div>
                </div>
                {starIcon(auction, '20px', '86%')}
                <div className={'text-md'}>
                    {venueText(auction)}
                </div>
                <div className="py-2 px-2 font-bold text-lg mt-auto text-edge-blue">
                    {bidButton(auction, 'home', generalInteraction)}
                </div>
        </Panel>
    )
}


let CharityAuctionSlideSingle = ({auction, generalInteraction}) => {
    return (
        <div className={'relative'}>
            {venueImage(auction)}
            {/*{venueDonatedBy(auction)}*/}
            <div className="inline-block absolute" style={{top: '5px', left: '5px', zIndex: '999'}}>
                <div
                    className={'rounded-sm py-1 px-2 uppercase text-white text-xxs font-normal ' + statusColor(auction)}>
                    {`#${auction.lotId}`} - {auction.status}
                </div>
            </div>
            {starIcon(auction, '5px', '88%')}
            <div className={'text-md font-bold'}>
                {venueText(auction)}
            </div>
            {bidButton(auction, 'single', generalInteraction)}
        </div>
    )
}


export {CharityAuctionSlide, CharityAuctionSlideSingle}