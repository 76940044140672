import {
  CORPORATE_ROLES_LIST_ERROR,
  CORPORATE_ROLES_LIST_RECEIVED,
  CORPORATE_ROLES_LIST_REQUEST,
  ROLES_LIST_ERROR,
  ROLES_LIST_RECEIVED,
  ROLES_LIST_REQUEST,
  ROLES_UPDATE_RECEIVED_REQUEST,
  ROLES_UPDATE_RECEIVED_REQUEST_ERROR,
  USER_ROLES_UPDATE_REQUEST
} from "./types";

export const roles = (state = {
  roles: {},
  corporateRoles: {},
  isFetchingRoles: false,
}, action) => {
  switch (action.type) {
    case ROLES_LIST_REQUEST:
    case  CORPORATE_ROLES_LIST_REQUEST:
      return {
        ...state,
        isFetchingRoles: true
      }
    case CORPORATE_ROLES_LIST_RECEIVED:
      return {
        ...state,
        isFetchingRoles: false,
        corporateRoles: action.data.data
      }
    case ROLES_LIST_RECEIVED:
      return {
        ...state,
        isFetchingRoles: false,
        roles: action.data.data
      }
    case ROLES_LIST_ERROR:
    case CORPORATE_ROLES_LIST_ERROR:
      return {
        ...state,
        isFetchingRoles: false
      }
    case USER_ROLES_UPDATE_REQUEST:
      return {
        ...state,
        isFetchingRoles: true,
      }
    case ROLES_UPDATE_RECEIVED_REQUEST:
      return {
        ...state,
        isFetchingRoles: false
      }
    case ROLES_UPDATE_RECEIVED_REQUEST_ERROR:
      return {
        ...state,
        isFetchingRoles: false,
        error: action.error,
      }
    default:
      return state;
  }
}
