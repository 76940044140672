import axios from "axios";
import * as ROUTES from "./../../Router/corporate_routes";
import {
  BUSINESS_UNIT_CREATED,
  BUSINESS_UNIT_CREATED_ERROR,
  BUSINESS_UNIT_DELETED,
  BUSINESS_UNIT_DELETED_ERROR,
  BUSINESS_UNIT_REQUEST,
  BUSINESS_UNIT_REQUEST_RECEIVED,
  BUSINESS_UNIT_REQUEST_RECEIVED_ERROR,
  BUSINESS_UNIT_UPDATE_ERROR,
  BUSINESS_UNIT_UPDATED,
  BUSINESS_UNITS_LIST_REQUEST,
  BUSINESS_UNITS_LIST_REQUEST_RECEIVED,
  BUSINESS_UNITS_LIST_REQUEST_RECEIVED_ERROR,
  BUSINESS_UNITS_UNLOAD,
} from "../reducers/types";
import handleErrors from "./utils";

export const businessUnitsListRequest = () => ({
  type: BUSINESS_UNITS_LIST_REQUEST,
});

export const businessUnitCreated = data => ({
  type: BUSINESS_UNIT_CREATED,
  data,
});

export const businessUnitCreatedError = error => ({
  type: BUSINESS_UNIT_CREATED_ERROR,
  error,
});

export const businessUnitDeleted = data => ({
  type: BUSINESS_UNIT_DELETED,
  data,
});
export const businessUnitDeletedError = error => ({
  type: BUSINESS_UNIT_DELETED_ERROR,
  error,
});

export const businessUnitUpdated = data => ({
  type: BUSINESS_UNIT_UPDATED,
  data,
});

export const businessUnitUpdateError = error => ({
  type: BUSINESS_UNIT_UPDATE_ERROR,
  error,
});

export const businessUnitsListRequestReceived = data => ({
  type: BUSINESS_UNITS_LIST_REQUEST_RECEIVED,
  data,
});

export const businessUnitsListRequestReceivedError = error => ({
  type: BUSINESS_UNITS_LIST_REQUEST_RECEIVED_ERROR,
  error,
});

export const businessUnitRequest = () => ({
  type: BUSINESS_UNIT_REQUEST,
});

export const businessUnitRequestReceived = data => ({
  type: BUSINESS_UNIT_REQUEST_RECEIVED,
  data,
});

export const businessUnitRequestReceivedError = error => ({
  type: BUSINESS_UNIT_REQUEST_RECEIVED_ERROR,
  error,
});

export const unloadBusinessUnits = () => ({
  type: BUSINESS_UNITS_UNLOAD,
});

export const fetchBusinessUnits = (agencyId, token) => {
  return (dispatch) => {
    dispatch(businessUnitsListRequest());
    return axios.get(window.API + ROUTES.API.AGENCY.BUSINESS_UNITS.LIST.replace(":id", agencyId), {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(businessUnitsListRequestReceived(response.data)))
      .catch(error => dispatch(businessUnitsListRequestReceivedError(error)));
  };
};

export const editBusinessUnit = (agencyId, businessUnitId, formValues, token) => {
  return (dispatch) => {
    dispatch(businessUnitRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.BUSINESS_UNITS.SINGLE.replace(":id", agencyId).replace(":businessUnit", businessUnitId), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(businessUnitUpdated(response.data)))
      .catch(error => {
        dispatch(businessUnitUpdateError(error));
        handleErrors(error);
      });
  };
};

export const createBusinessUnit = (agencyId, formValues, token) => {
  return (dispatch) => {
    dispatch(businessUnitRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.BUSINESS_UNITS.CREATE.replace(":id", agencyId), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(businessUnitCreated(response.data)))
      .catch(error => {
        dispatch(businessUnitCreatedError(error));
        handleErrors(error);
      });
  };
};

export const deleteBusinessUnit = (agencyId, businessUnitId, formValues, token) => {
  // console.log(agencyId, businessUnitId, formValues)
  return (dispatch) => {
    dispatch(businessUnitRequest());
    return axios.post(window.API + ROUTES.API.AGENCY.BUSINESS_UNITS.SINGLE.replace(":id", agencyId).replace(":businessUnit", businessUnitId), formValues, {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(businessUnitDeleted(response.data)))
      .catch(error => {
        dispatch(businessUnitDeletedError(error));
        handleErrors(error);
      });
  };
};
