import { isCancel } from "axios";
import get from "lodash/fp/get";
import { withAxios } from "../withAxios";

export const getErrorStatus = get("response.status");
export const getErrorMessage = get("response.data.message");

const noop = () => {};

export const createHandleAxiosError = ({ history, location }) => (
  onError = noop
) => err => {
  // Ignore Axios cancels
  if (isCancel(err)) {
    return;
  }

  const status = getErrorStatus(err);
  const message = getErrorMessage(err);

  // Handle 401's
  if (status === 401) {
    switch (message) {
      // Agency requires branding
      case "Branding not confirmed":
        history.push({
          pathname: "/admin/profile",
          state: {
            message: "AGENCY-PROFILE-REQUIRED",
          },
        });
        return;

      // Redirect to login
      default:
        history.push(
          `/login?return=${encodeURI(location.pathname)}&sessionExpired=1`
        );
        return;
    }
  }

  onError(err);
};

export const withApi = createApi => WrappedComponent =>
  withAxios(WrappedComponent, { createApi });
