import axios from 'axios';
import * as ROUTES from '../../Router/user_routes';
import {
  SHORTLIST_DELETE_ERROR,
  SHORTLIST_DELETE_REQUEST,
  SHORTLIST_DELETE_REQUEST_RECEIVED,
  SHORTLIST_UNLOAD
} from "../reducers/types";


export const shortlistDeleteRequest = () => ({
  type: SHORTLIST_DELETE_REQUEST
});

export const shortlistDeleteRequestReceived = data => ({
  type: SHORTLIST_DELETE_REQUEST_RECEIVED,
  data
});

export const shortlistDeleteError = error => ({
  type: SHORTLIST_DELETE_ERROR,
  error
});

export const shortlistUnload = () => ({
  type: SHORTLIST_UNLOAD
});

export const deleteShortlist = (id, token) => {
  return (dispatch) => {
    dispatch(shortlistDeleteRequest());
    return axios.delete(window.API + ROUTES.API.SHORTLISTS.SINGLE.replace(':id', id), {
      headers: {Authorization: `Bearer ${token}`}
    }).then(response => {
      dispatch(shortlistDeleteRequestReceived(response.data))
    }).catch(error => {
      dispatch(shortlistDeleteError(error))
    })
  }
}
