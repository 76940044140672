import React from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "./General/BackgroundImage";

const FeaturedBox = ({ href, img, name }) => {
  return (
    <Link
      className="col md:col-w-1/2 lg:col-w-1/3 text-white text-3xl mb-5"
      to={href}
    >
      <BackgroundImage className={'bg-center bg-cover re-1/2 relative'} imagePath={img} edits={{
      "resize": {
        "width": 500,
        "height": 250,
        "fit": "cover"
      }}}>

        <h4
          style={{
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
          className="absolute pin-t pin-b pin-l pin-r font-body font-normal flex flex-col items-center justify-center"
        >
          {name}
        </h4>
      </BackgroundImage>
    </Link>
  );
};

export default FeaturedBox;
