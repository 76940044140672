import React from "react";
import { arrayOf, object, string } from "prop-types";
import { connect } from "react-redux";
import Modal from "Components/Modal";
import { venueInteraction } from "modules/analytics";
import ImageGallery from "react-image-gallery";
import "styles/external/react-image-gallery.css"; // importing this from node_modules screws up the load order. It's a copy paste from latest module CSS
import { FaImages } from "react-icons/fa";
import RoundedIcon from "./RoundedIcon";
import BackgroundImage from "../../Components/General/BackgroundImage";
import filterImageExtensions from "../../helpers/filterImageExtensions";
import { isLoggedIn } from "../../modules/auth";
import RegisterBlurPanel from "../../Components/RegisterBlurPanel";

const UNAUTHED_IMAGES_TO_SHOW = 1; // array so this is 2 images

class Gallery extends React.Component {
  state = {
    galleryOpen: false,
  };

  static propTypes = {
    images: arrayOf(string.isRequired),
    classes: string,
    style: object,
  };

  toggleGallery = () => {
    if (!this.state.galleryOpen) {
      this.props.venueInteraction({
        type: "view_gallery_image",
        id: this.props.venueId,
        impression_id: this.props.impression_id,
        data_score: this.props.data_score,
      });
    }
    this.setState({
      galleryOpen: !this.state.galleryOpen,
    });
  };

  getImages = () => {
    const arr = [...this.props.images];
    return arr.filter(img => filterImageExtensions(img.path)).map((img, index) => {
      return ({
        original: img.path,
        thumbnail: img.path,
        description: img.caption,
        renderThumbInner: () => (
          !this.props.isLoggedIn && index > UNAUTHED_IMAGES_TO_SHOW ? (
            <RegisterBlurPanel>
              <BackgroundImage
                nonDiv={true}
                alt={"Gallery"}
                imagePath={img.path}
                width={92}
                height={92}
                edits={{
                  "resize": {
                    "width": 92,
                    "height": 92,
                    "fit": "cover",
                  },
                }}
              />
            </RegisterBlurPanel>
          ) : (
            <BackgroundImage
              nonDiv={true}
              alt={"Gallery"}
              imagePath={img.path}
              width={92}
              height={92}
              edits={{
                "resize": {
                  "width": 92,
                  "height": 92,
                  "fit": "cover",
                },
              }}
            />
          )
        ),
        renderItem: () => (
          <>
            {!this.props.isLoggedIn && index > UNAUTHED_IMAGES_TO_SHOW ? (
              <RegisterBlurPanel showContent={true} contentText={"To view more images for this venue please login or register below"}>
                <div className="re-16/9 relative max-w-full">
                  <BackgroundImage
                    nonDiv={true}
                    alt={"Gallery"}
                    className={"absolute-center"}
                    imagePath={img.path}
                    edits={{
                      "resize": {
                        "width": 1400,
                        "fit": "cover",
                      },
                    }}
                  />
                  {img.caption ? (
                    <div className="relative max-w-full mb-5">
                      <div className="flex">
                  <span
                    className="flex justify-center items-center h-10 text-center px-2 py-0 border w-full outline-none font-normal text-black text-lg bg-white border-grey-md"
                  >
                    {img.caption}
                  </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </RegisterBlurPanel>
            ) : (
              <div className="re-16/9 relative max-w-full">
                <BackgroundImage
                  nonDiv={true}
                  alt={"Gallery"}
                  className={"absolute-center"}
                  imagePath={img.path}
                  edits={{
                    "resize": {
                      "width": 1400,
                      "fit": "cover",
                    },
                  }}
                />
                {img.caption ? (
                  <div className="relative max-w-full mb-5">
                    <div className="flex">
                  <span
                    className="flex justify-center items-center h-10 text-center px-2 py-0 border w-full outline-none font-normal text-black text-lg bg-white border-grey-md"
                  >
                    {img.caption}
                  </span>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </>

        ),
      });
    });
  };

  trackSlide = () => {
    // const current = (i + 1).toString();
    // const total = this.props.images.length.toString();
    // const fileName = this.props.images[i];
    // const dump = `${current},${total},${fileName}`;
    this.props.venueInteraction({
      type: "view_gallery_image",
      id: this.props.venueId,
      impression_id: this.props.impression_id,
      data_score: this.props.data_score,
    });
  };

  render() {
    return (
      <>
        {this.props.small && (
          <RoundedIcon
            name="picture"
            size={24}
            classes="mr-2 pointer"
            onClick={this.toggleGallery}
          />
        )}
        {!this.props.small && (
          <div
            onClick={this.toggleGallery}
            className={this.props.classes}
            style={
              this.props.red ? undefined : { width: 64, height: 64, top: -30 }
            }
          >
            <FaImages
              className={`${
                this.props.red ? "text-dark-red" : "text-white"
              } text-2xl`}
            />
            <span
              className={`font-heading font-bold uppercase ${
                this.props.red ? "text-dark-red" : "text-white"
              } text-xs`}
            >
              Gallery
            </span>
          </div>
        )}
        {this.state.galleryOpen ? (
          <Modal>
            <div className="modal">
              <div
                className="center absolute lg:w-3/4"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <i
                  className="fal fa-times absolute z-50 pin-t pin-r text-white mr-2 mt-2 text-2xl pointer"
                  onClick={this.toggleGallery}
                />
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  items={this.getImages()}
                  onSlide={this.trackSlide}
                />
              </div>
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: isLoggedIn(state),
  };
};

export default connect(
  mapStateToProps,
  { venueInteraction },
)(Gallery);
