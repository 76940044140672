import { func, number, string, object } from "prop-types";
import React from "react";
import useMeasure from "../../../../hooks/useMeasure";

export const ResizeContainer = ({
  height: minHeight,
  children,
  className = "",
  style = {},
}) => {
  const [bind, { width, height }] = useMeasure();

  return (
    <div
      {...bind}
      className={"relative " + className}
      style={{ minHeight: `${minHeight}px`, ...style }}
    >
      {width && height ? (
        <div
          className="absolute"
          style={{ minHeight: `${height}px`, minWidth: `${width}px` }}
        >
          {children({ height, width })}
        </div>
      ) : null}
    </div>
  );
};

ResizeContainer.propTypes = {
  height: number.isRequired,
  children: func.isRequired,
  className: string,
  style: object,
};
