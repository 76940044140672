import React, {Component, useState, useEffect} from "react";
import {Field, reduxForm, change} from "redux-form";
import {connect} from 'react-redux';
import {ButtonPrimary} from "../../../../Components/Button";

const mapStateToProps = (state, props) => {
  return {
    corporateDocument: {...state.corporateDocument},
  };
};

let CorporateAccountDocumentCategoryAddContainer = (props) => {
  const [categories, setCategories] = useState(props.corporateAccount.categories)
  const [newCategory, setNewCategory] = useState('');
  const [filesAffected, setFilesAffected] = useState([])
  useEffect(() => {
    if (props.corporateAccount) {
      setCategories(props.corporateAccount.categories)
    }
  }, [props.corporateAccount]);

  let onNewCategory = (e) => {
    setNewCategory(e.target.value)
  }

  return (
    <React.Fragment>
      <h3 className={'mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center uppercase'}>
        DOCUMENT CATEGORIES
      </h3>
      <p>Define the categories to store Useful Documents under, for Users accessing this Corporate Account</p>
      {filesAffected.length > 0 ?
        <p className={'text-red'}>These files have had their category removed:  {filesAffected.join(', ')}</p> : null
      }
      <ul className={'list-reset mb-5'}>
        {categories && categories.map(function (category) {
          return (
            <li key={category.id} className={"mt-1"}>
              <strong>{category.name}</strong>
                <i className="pt-3 ml-2 fas fa-trash text-red cursor-pointer"
                   onClick={() => {
                      let affectedFiles = props.corporateAccount.documents.filter((document) => document.category_names.filter((category_name) => category_name === category.name).length > 0);
                      setFilesAffected(affectedFiles.map((file) => file.name));
                      props.onDeleteCategory(category);
                   }}/>
            </li>
          )
        })}
      </ul>

      <div className="row">
        <div className={'col col-w-full add-category-container'}>
          <div className="field mb-4">
            <label className={` mb-1 block font-normal font-heading uppercase`}>{'Add A New Category'}</label>
            <input
              value={newCategory}
              onChange={onNewCategory}
              className={` px-2 py-0 border w-full outline-none font-normal text-black text-sm focus:border-primary bg-white rounded-none appearance-none border-grey-md h-10`}
              autoComplete="off"
            />
          </div>
          <ButtonPrimary onClick={() => {
            props.onAddNewCategory(newCategory).then((response) => {
              setNewCategory('');
            })
          }} classes="mt-1 ml-2">Add</ButtonPrimary>
        </div>
      </div>
      <hr className="my-6"/>
    </React.Fragment>


  )
}


CorporateAccountDocumentCategoryAddContainer = reduxForm({
  form: 'corporateAccountDocumentCategoryForm',
  enableReinitialize: true
})(CorporateAccountDocumentCategoryAddContainer);

export default connect(mapStateToProps, null)(CorporateAccountDocumentCategoryAddContainer);
