import React, { useState, useEffect, memo } from "react";
import useAxios from "hooks/useAxios";
import HomeVenueType from "Components/HomeVenueType";
import { ButtonBrand } from "Components/Button";
import { Link } from "react-router-dom";

const SloganWrapper = ({children}) => <h2 className="text-2xl md:text-2rem">{children}</h2>

const types = [
  {
    name: "Dinner Venues",
    slogan: (
      <SloganWrapper>
          The finest awards dinner venues in <span className="text-blue">the capital</span>
      </SloganWrapper>
    ),
    url: "/advanced-search?results=306&addressType=locality&back_projection=0&exhibition=0&lat=51.5073509&lng=-0.1277583&location=London%2C%20UK&location_type=Map&radius=8046.72&sortBy=Distance&types=9&types_text=Hotels&closePanels=1",
  },
  {
    name: "Auditoriums",
    slogan: (
      <SloganWrapper>
        See auditoriums <span className="text-blue">in the UK</span>
      </SloganWrapper>
    ),
    url: "/advanced-search?results=519&back_projection=0&exhibition=0&location_type=Map&radius=32186.88&sortBy=Capacity&types=4&types_text=Hotels&closePanels=1",
  },
  {
    name: "Outside spaces",
    slogan: (
      <SloganWrapper>
        Looking for a venue in <span className="text-blue">London</span> with outside space?
      </SloganWrapper>
    ),
    url: "/advanced-search?results=68&addressType=locality&back_projection=0&exhibition=0&lat=51.5073509&lng=-0.1277583&location=London%2C%20UK&location_type=Map&radius=16093.44&sortBy=Distance&types=5&types_text=Hotels&closePanels=1",
  },
  {
    name: "Premium Venues",
    slogan: (
      <SloganWrapper>
        <span className="text-blue">London's</span> Premium Venues
      </SloganWrapper>
    ),
    url: "/advanced-search?results=288&addressType=locality&back_projection=0&exhibition=0&lat=51.5073509&lng=-0.1277583&location=London%2C%20UK&location_type=Map&radius=8046.72&sortBy=Distance&types=2&types_text=Hotels&closePanels=1",
  },
  {
    name: "Premium Venues",
    slogan: (
      <SloganWrapper>
        <span className="text-blue">Manchester's</span> Premium Venues
      </SloganWrapper>
    ),
    url: "/advanced-search?results=50&addressType=locality&back_projection=0&exhibition=0&lat=53.4807593&lng=-2.2426305&location=Manchester%2C%20UK&location_type=Map&radius=8046.72&sortBy=Distance&types=2&types_text=Hotels&closePanels=1",
  },
  {
    name: "Premium Venues",
    slogan: (
        <SloganWrapper>
          <span className="text-blue">Birmingham's</span> Premium Venues
        </SloganWrapper>
    ),
    url: "/advanced-search?results=48&addressType=locality&back_projection=0&exhibition=0&lat=52.48624299999999&lng=-1.890401&location=Birmingham%2C%20UK&location_type=Map&radius=8046.72&sortBy=Distance&types=2&types_text=Hotels&closePanels=1",
  },
  {
    name: "Exhibition Venues",
    slogan: (
        <SloganWrapper>
          View UK's <span className="text-blue">Exhibition venues</span>
        </SloganWrapper>
    ),
    url: "/advanced-search?results=524&back_projection=0&country=England&country_id=252&exhibition=0&location_type=Country&radius=24140.16&sortBy=Capacity&types=11&types_text=Hotels&closePanels=1",
  },
  {
    name: "Conference Centres",
    slogan: (
      <SloganWrapper>
        View purpose built Conference centres in <span className="text-blue">Leeds</span>
      </SloganWrapper>
    ),
    url: "/advanced-search?results=53&addressType=locality&back_projection=0&exhibition=0&lat=53.8007554&lng=-1.5490774&location=Leeds%2C%20UK&location_type=Map&radius=16093.44&sortBy=Distance&types=8&types_text=Hotels&closePanels=1",
  },
  {
    name: "Conference Centres",
    slogan: (
      <SloganWrapper>
        View purpose built Conference centres in <span className="text-blue">Manchester</span>
      </SloganWrapper>
    ),
    url: "/advanced-search?results=79&addressType=locality&back_projection=0&exhibition=0&lat=53.4807593&lng=-2.2426305&location=Manchester%2C%20UK&location_type=Map&radius=16093.44&sortBy=Distance&types=8&types_text=Hotels&closePanels=1",
  },
];

const VenueTypes = ({ mobile }) => {
  const { loading, error, data } = useAxios({
    url: `${window.API}/venue-categories`,
  });
  const [rows, setRows] = useState([[], []]); //create two rows

  useEffect(() => {
    if (data) {
      const newRows = rows;
      data
        .filter(item => item.show_on_location === 1)
        .map((item, index) => {
          if (index <= 2) newRows[0].push(item);
          else newRows[1].push(item);

          return newRows;
        });
      setRows([...newRows]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const items = mobile ? types.slice(0, 4) : types;
  return (
    <div className="bg-black pt-14 pb-12 md:pb-8 border-green border-b-4">
      <div className="container">
        <h1 className="text-2rem md:text-4xl text-center text-white mb-8">Quick links to popular searches...</h1>
        {!loading && error && (
          <p>Sorry, there was a problem fetching the data</p>
        )}
        <div className="flex flex-wrap relative md:static -mt-24 md:mt-0">
          {items.map(type => {
            return (
              <div
                className="col mb-10 lg:mb-13 md:col-w-1/2 lg:col-w-1/3"
                key={type.url}
              >
                <HomeVenueType
                  name={type.name}
                  url={type.url}
                  slogan={type.slogan}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="container flex justify-center items-center pb-2">
        <div className="col col-w-full xl:col-w-1/3">
          <Link to="/advanced-search?results=13326&back_projection=0&exhibition=0&location_type=Map&radius=24140.16&sortBy=Capacity&types_text=Hotels">
            <ButtonBrand
              style={{ height: "auto" }}
              classes="rounded-sm py-3 md:px-20 font-bold text-lg w-full"
            >
              Search for all venue types
            </ButtonBrand>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(VenueTypes);
