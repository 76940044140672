import classnames from "classnames";
import humanNumber from "human-number";
import React from "react";
import { ButtonSpan } from "../../../Components/Button";
import { Row } from "../../../Components/grid";

export const Title = ({ children, className }) => (
  <h3 className={classnames("uppercase mb-1 text-base", className)}>
    {children}
  </h3>
);

export const WhiteBox = ({ className, noPadding, tall, flexCol, ...rest }) => (
  <div
    className={classnames(
      "bg-white shadow-lg",
      {
        "p-5": !noPadding,
        "h-full": tall,
        "flex flex-col justify-between": flexCol,
      },
      className
    )}
    {...rest}
  />
);

export const Tabs = ({
  items,
  selected,
  onChange,
  isSelected = x => x === selected,
  itemToTitle = x => x,
}) => (
  <Row className="justify-end">
    {items.map(item => {
      const isItemSelected = Boolean(isSelected(item));
      return (
        <button
          key={itemToTitle(item)}
          type="button"
          className={classnames(
            "inline-block font-normal font-heading p-2 leading-none",
            { "bg-primary text-white": isItemSelected }
          )}
          onClick={() => {
            onChange && onChange(item);
          }}
        >
          {itemToTitle(item)}
        </button>
      );
    })}
  </Row>
);

export const DataItem = ({ value, title }) => (
  <div className="w-1/4 mb-3">
    <div className="text-2xl mb-1">{value}</div>
    <div>{title}</div>
  </div>
);

export class DataItemWithDetail extends React.Component {
  state = {
    showDetail: false,
  };

  toggle = () =>
    this.setState(state => ({
      showDetail: !state.showDetail,
    }));

  render() {
    const { renderDetails, value, title } = this.props;
    const { showDetail } = this.state;
    return (
      <React.Fragment>
        <ButtonSpan onClick={this.toggle} className="w-1/4 mb-3 text-brand">
          <div className="text-2xl mb-1">{value}</div>
          <div>{title}</div>
        </ButtonSpan>

        {showDetail ? renderDetails({ close: this.toggle }) : null}
      </React.Fragment>
    );
  }
}

export const getRecordValue = record =>
  (record.totalEventValue ? parseFloat(record.totalEventValue) : parseFloat(record.approxValue)) || parseFloat(0);

export const formatMoney = value =>
  `£${humanNumber(value, value => {
    const rounded = value.toFixed(1);
    return rounded.endsWith(".0") ? rounded.slice(0, -2) : rounded;
  })}`;
