import React, {  useState } from "react";
import { ButtonPrimary } from "Components/Button";
import Input from "../Components/Form/Input";
import Loading from "../Components/Loading";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import {withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { reset } from 'redux-form';
import {
    maxLength,
    required,
    phone, email,
} from "modules/validation";

const v = {
    contact_name: [required(), maxLength(255)],
    job_title: [required(), maxLength(255)],
    name_of_venue_or_group: [required(), maxLength(255)],
    contact_email_address: [required(), maxLength(255), email],
    daytime_contact_tel_number: [required(), phone],
};
const form = "upgrade-venue";
const UpgradeVenue = (props) => {
    const [success, setSuccess] = useState(false);
    const handleForm = (values) => {
        return axios
            .post(`${window.API}/upgrade-venue`, values)
            .then(response => {
                setSuccess(true);
                props.dispatch(reset('upgrade-venue'));
            })
            .catch(err => {
//                const error =                    err.response && err.response.status === 401
            });
    };

    return(
        <div className="container">
            <div
                id="signup"
                className="md:my-5 p-3 md:p-8 text-white md:flex md:flex-wrap md:justify-around"
                style={{
                    backgroundImage: 'url("/img/landing/conference-banner.jpg")',
                    backgroundColor: '#555'
                }}
            >
                <div className="w-full lg:w-1/2">


                    <h1 className="mb-5 text-center mt-5">Upgrade today</h1>
                    <h2 className="mb-16 text-center uppercase">Make your venue stand out from the crowd</h2>


                    <div className="row">
                        <div className="col col-w-full md:col-w-1/3">
                            <span className="text-center text-4xl">
                                <i className="fal fa-search block mb-4"/>
                            </span>
                            <h3 className="text-center mb-10">Priority on searches and venue Quick View</h3>
                        </div>
                        <div className="col col-w-full md:col-w-1/3">
                            <span className="text-center text-4xl">
                                <i className="fal fa-analytics block mb-4"/>
                            </span>
                            <h3 className="text-center mb-10">Real-time analytics</h3>
                        </div>
                        <div className="col col-w-full md:col-w-1/3">
                            <span className="text-center text-4xl">
                                <i className="fal fa-photo-video block mb-4"/>
                            </span>
                            <h3 className="text-center mb-10">Unlimited photographs and virtual tours</h3>
                        </div>
                        <div className="col col-w-full md:col-w-1/3">
                            <span className="text-center text-4xl">
                                <i className="fal fa-comments block mb-4"/>
                            </span>
                            <h3 className="text-center mb-10">Unlimited engagement opportunities with Agents, Corporate buyers and planners</h3>
                        </div>
                        <div className="col col-w-full md:col-w-1/3">
                            <span className="text-center text-4xl">
                                <i className="fal fa-envelope block mb-4"/>
                            </span>
                            <h3 className="text-center mb-10">Invitations to maketing summits &amp; venue expo's with Trinity Event Solutions</h3>
                        </div>
                        <div className="col col-w-full md:col-w-1/3">
                            <span className="text-center text-4xl">
                                <i className="fal fa-file-check block mb-4"/>
                            </span>
                            <h3 className="text-center mb-10">Simple and effective direct RFP process</h3>
                        </div>
                    </div>



                </div>
                <div className="w-full lg:w-2/5 p-5 md:p-10" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                    {success ? <p className="text-center text-lg">
                        An e-mail has just been sent to you.
                    </p> : null}
                    <form onSubmit={props.handleSubmit(handleForm.bind(this))} >

                        <Field
                            name="contact_name"
                            type="text"
                            label="Contact Name"
                            component={Input}
                            validate={v.contact_name}
                        />
                        <Field
                            name="job_title"
                            type="text"
                            label="Job Title"
                            component={Input}
                            validate={v.job_title}
                        />
                        <Field
                            name="name_of_venue_or_group"
                            type="text"
                            label="Name Of Venue Or Group"
                            component={Input}
                            validate={v.name_of_venue_or_group}
                        />
                        <Field
                            name="contact_email_address"
                            type="text"
                            label="Contact Email Address"
                            component={Input}
                            validate={v.contact_email_address}
                        />
                        <Field
                            name="daytime_contact_tel_number"
                            type="text"
                            label="Daytime Contact Tel Number"
                            component={Input}
                            validate={v.daytime_contact_tel_number}
                        />

                        <ButtonPrimary type="submit">
                            {props.submitting ? <Loading inline /> : "Submit"}
                        </ButtonPrimary>

                    </form>
                </div>
            </div>
        </div>
    )
}


export default withRouter(
    reduxForm({
        form,
    })(
        connect(
        )(UpgradeVenue)
    )
);
