import React from "react";
import { bool, string, arrayOf, shape } from "prop-types";
import TrueFalseListItem from "Components/TrueFalseListItem";

export default class TrueFalseList extends React.Component {
  static propTypes = {
    items: arrayOf(
      shape({
        name: string.isRequired,
        value: bool.isRequired,
      }).isRequired
    ).isRequired,
  };

  render() {
    return (
      <ul className="list-reset">
        {this.props.items.map(item => (
          <TrueFalseListItem
            key={item.name}
            name={item.name}
            value={item.value}
          />
        ))}
      </ul>
    );
  }
}
