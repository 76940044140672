import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { object, array, string, bool } from "prop-types";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import {
  shortlists,
  deleteShortlist,
  updateShortlist,
  token, isInternal,
} from "modules/auth";
import { venueInteraction } from "../../modules/analytics";
import Table from "Components/Table";
import { stateToReferCode } from "../../modules/brand";
import { createHandleAxiosError } from "../../modules/api-tools";
import differenceBy from "lodash/differenceBy";
import Loading from "../Loading";
import { user } from "../../modules/auth";
import {
  FaChevronUp,
  FaChevronDown,
  FaRegListAlt,
  FaShareAlt,
  FaRegTrashAlt,
} from "react-icons/fa";
import RoundedCheckbox from "Components/RoundedCheckbox";
import {ButtonBrand} from "../Button";
import {VENUE_TAGS} from "../../modules/analytics/tags";
import {isAgencyUser} from "../../modules/permissions";

class ShortlistsDisplay extends React.Component {
  static propTypes = {
    history: object.isRequired,
    shortlists: array.isRequired,
    selectedVenuesForm: string,
    canAppend: bool,
    hasIconsInList: bool,
  };

  state = {
    showVenues: null,
    isBusy: false,
  };

  delete = id => {
    const { history, location } = this.props;
    const handleAxiosError = createHandleAxiosError({ history, location });

    if (window.confirm("Are you sure you want to delete the shortlist")) {
      this.setState({ isBusy: true });

      axios
        .delete(`${window.API}/users/me/shortlists/${id}`, {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .then(() => {
          this.props.deleteShortlist(id);
          this.setState({ isBusy: false });
        })
        .catch(
          handleAxiosError(() => {
            alert("Sorry there was an error deleting your shortlist");
          })
        );
    }
  };

  shortlistAppend = ({ shortlist, venues: appendingVenues }) => {
    const {
      token,
      updateShortlist,
      history,
      location,
      venueInteraction,
    } = this.props;
    const { id, venues: existingVenues } = shortlist;

    const venues = [...existingVenues, ...appendingVenues];

    const handleAxiosError = createHandleAxiosError({ history, location });

    this.setState({ isBusy: true });

    if (appendingVenues) {
      appendingVenues.forEach(venue =>
        venueInteraction({
          type: VENUE_TAGS.VENUE_SHORTLISTED.ADD_TO_SHORTLIST,
          id: venue.id,
          data_score: venue.data_score,
        })
      );
    }

    axios
      .patch(
        `${window.API}/users/me/shortlists/${id}`,
        {
          venues,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(response => {
        const shortlist = response.data;
        updateShortlist(shortlist.id, shortlist);
        this.setState({ isBusy: false });
      })
      .catch(
        handleAxiosError(() => {
          alert("Sorry there was an error adding the venues");
        })
      );
  };

  addableVenues = ({ shortlist, venues }) =>
    differenceBy(venues, shortlist.venues, venue => venue.id);

  createShortlistUrl = ({
    shortlist: { id, name, venues },
    withDomain = false,
    referCode = false,
    isEmail = false
  }) => {
    const {isAgencyUser} = this.props;
    const domain = withDomain ? window.BASE_URL : "";
    const venueIds = venues.map(v => v.id).join(",");
    const referrer = referCode ? `&referrer=${referCode}` : "";
    if (isAgencyUser && isEmail) {
      return `${domain}/compare-venues-feedback?id=${id}&name=${encodeURIComponent(
        name
      )}&venues=${venueIds}${referrer}`;
    }
    return `${domain}/compare-venues?id=${id}&name=${encodeURIComponent(
      name
    )}&venues=${venueIds}${referrer}`;
  };

  createShareHref = ({ shortlist }) => {
    return `mailto:?subject=Venues comparison&body=${this.shareEmailBody({
      shortlist,
    })}`;
  };

  shareEmailBody({ shortlist }) {
    const { referCode, user, brand, isInternal, isAgencyUser } = this.props;
    let content;

    const comparisonOf = shortlist ? shortlist.name : "venues";

    const sharedBy =
      user && user.first_name ? ` by ${user.first_name} ${user.surname}` : "";

    const url = this.createShortlistUrl({
      shortlist,
      referCode,
      withDomain: true,
      isEmail: true
    });

    const from =
      brand && brand.company_name
        ? `${brand.company_name}, powered by EDGE Venues`
        : "EDGE Venues";

    if (!isAgencyUser) {
      content = `A comparison of ${comparisonOf} has been shared with you${sharedBy}.

  ${url}

${from}`;
    } else {
      content = isInternal
        ? `Please provide ${sharedBy} with your feedback on the suggested venue shortlist previously shared with you.
      
Shortlist with venues removed: ${url}

Your feedback:`
        : `A comparison of ${comparisonOf} has been shared with you${sharedBy}.

  ${url}

${from}`;
    }

    return encodeURIComponent(content);
  }

  renderActionIcons = shortlist => {
    const { isBusy } = this.state;

    if (isBusy) return <Loading classes="text-xs" />;

    return (

        <Fragment>
        <div className="flex justify-between mt-5 pl-2">
        <div>
          <Link to={this.createShortlistUrl({ shortlist })} className="mr-3">
            <FaRegListAlt className="text-primary" />
          </Link>
          <a href={this.createShareHref({ shortlist })} onClick={() => {
            shortlist.venues.map(venue => {
              this.props.venueInteraction({
                type: VENUE_TAGS.VENUE_SHARED.SHARE_COMPARISON,
                id: venue.id
              })
            })
          }}>
            <FaShareAlt className="text-primary" />
          </a>
        </div>
        <div>
          <button onClick={() => this.delete(shortlist.id)}>
            <FaRegTrashAlt className="text-warning" />
          </button>
        </div>
        </div>
        <div>

          <Link to={this.createShortlistUrl({ shortlist })}>
            <ButtonBrand classes="w-full ml-2 mt-2 font-small p2">
              Compare and request quotes
            </ButtonBrand>
          </Link>
        </div>
      </Fragment>
    );
  };

  addToExistingShortlist = (e, shortlist, addableVenues) => {
    e.stopPropagation();
    this.shortlistAppend({
      shortlist,
      venues: addableVenues,
    });
  };

  shortlistData = shortlist => {
    const { hasIconsInList, venues } = this.props;
    const { showVenues } = this.state;
    const isExpanded = showVenues === shortlist.id;

    const { selectedVenues, canAppend } = this.props;

    const addableVenues = this.addableVenues({
      shortlist,
      venues: selectedVenues,
    });

    const hasAddableVenues = addableVenues.length > 0;

    return {
      name: () => (
        <React.Fragment>
          <div className="flex justify-between pl-2">
            <div
              className="flex items-center text-sm text-left justify-between w-full pointer"
              onClick={() =>
                this.setState({
                  showVenues:
                    this.state.showVenues === shortlist.id
                      ? false
                      : shortlist.id,
                })
              }
            >
              <div className="flex items-center justify-center">
                {isExpanded ? (
                  <FaChevronUp className="mr-2" />
                ) : (
                  <FaChevronDown className="mr-2" />
                )}
              </div>
              <div className="w-full">
                <span>
                  {shortlist && shortlist.name.length > 20
                    ? `${shortlist.name.substr(0, 20).trim()}...`
                    : shortlist.name}
                </span>
              </div>
              {venues && venues.length > 0 && (
                <div>
                  {canAppend && hasAddableVenues ? (
                    <RoundedCheckbox
                      size={22}
                      onClick={e =>
                        this.addToExistingShortlist(e, shortlist, addableVenues)
                      }
                    />
                  ) : (
                    <RoundedCheckbox size={22} checked />
                  )}
                </div>
              )}
            </div>
            {hasIconsInList ? (
              <div>{this.renderActionIcons(shortlist)}</div>
            ) : null}
          </div>

          {isExpanded ? (
            <React.Fragment>
              <ul className="list-reset mt-2 font-small">
                {shortlist.venues.map(venue => (
                  <li key={venue.id} className="mb-2 mx-2">
                    <Link
                      to={`/venue/${venue.slug}`}
                      className="text-black border-b border-grey-md"
                    >
                      {venue.name}
                    </Link>
                  </li>
                ))}
              </ul>
              {!hasIconsInList ? (
                <div>
                  {this.renderActionIcons(shortlist)}
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ),
    };
  };

  render() {
    const { shortlists, venues } = this.props;
    const { isBusy } = this.state;

    if (isBusy) return <Loading classes="text-xs" />;
    return (
      <div>
        {shortlists && shortlists.length ? (
          <div>
            <p className="font-small uppercase font-bold text-grey-md mt-5 mb-3">
              {venues && venues.length > 0
                ? "Or add to an existing shortlist"
                : "Existing shortlists"}
            </p>
            <Table
              showHeader={false}
              headers={["name"]}
              data={shortlists.map(this.shortlistData)}
            />
          </div>
        ) : (
          <p>You have no shortlists</p>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => {
      const canAppend = !!props.selectedVenuesForm;
      const selectedVenues =
        canAppend && getFormValues(props.selectedVenuesForm)(state);
      return {
        canAppend,
        shortlists: shortlists(state),
        token: token(state),
        referCode: stateToReferCode(state),
        selectedVenues: selectedVenues ? selectedVenues.venues : [],
        user: user(state),
        brand: state.brand,
        isInternal: isInternal(state),
        isAgencyUser: isAgencyUser(state.auth)
      };
    },
    { deleteShortlist, updateShortlist, venueInteraction }
  )(ShortlistsDisplay)
);
