import React, {useEffect} from "react";
import {kebabCase} from "lodash";

const EventWrapper = ({event}) => {
  useEffect(() => {
    let parentEl = document.getElementById(event.id).parentNode
    if (parentEl) {
      parentEl.classList.add(`${kebabCase(event.type)}-event`)
    }
  }, [])
  return (
      <span id={event.id} className={`${event.type}-event`} title={event.title}>
      <strong>{event.title}</strong>
    </span>
  );
};

export default EventWrapper;
