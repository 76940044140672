import React from "react";
import { Link } from "react-router-dom";
import { ButtonBrand, ButtonLink, ButtonSpan } from "Components/Button";
import * as CORPORATE_ROUTES from "../Router/corporate_routes";
import { saveData } from "../services/downloadFile";
import axios from "axios";

export default function AdminDashPanels(props) {
  const { items, corporateAccount, corporateStyle = false, twoUp = false, token } = props;

  const downloadKpiFile = async () => {
    if (corporateAccount && corporateAccount.id && corporateAccount.kpi_document) {
      try {
        let fileName = corporateAccount.kpi_document.name;
        await axios.get(
          `${window.API}` + CORPORATE_ROUTES.API.CORPORATE.DOCUMENTS.KPI_GET.replace(":slug", corporateAccount.id).replace(":fileId", corporateAccount.kpi_document.id),
          {
            responseType: "blob",
            headers: { Authorization: `Bearer ${token}` },
          },
        ).then(
          (response) => {
            saveData(
              false,
              response.data,
              `${fileName}`,
            );
          },
        );
      } catch (err) {
        console.error(err);
      }
    }
  };

  if (corporateStyle) {
    return items.map(item => (
      <div
        className={`w-full lg:w-1/${twoUp ? "2" : "4"} p-2 mb-0 corporate-admin__dashboard__panelWrap`}
        key={item.path}
      >
        <div
          className="corporate-admin__dashboard__panel bg-black text-white h-full p-6 flex flex-col rounded-lg justify-between"
          style={{
            backgroundSize: "cover",
          }}
        >
          <div>
            <div className="flex flex-row items-center">
              {item.icon &&
                <span className="text-2xl mr-4">
                  <i className={item.icon} />
                </span>
              }
              <h2 className="mb-0 text-lg xl:text-xl font-normal">{item.text}</h2>
            </div>
            <p className="opacity-75 mt-4">{item.desc}</p>
          </div>
          {item.path === CORPORATE_ROUTES.API.CORPORATE.DOCUMENTS.KPI_GET ? (
            <ButtonSpan onClick={() => downloadKpiFile()} classes={"corporate-admin__dashboard__panel__link"}>
              {item.buttonLabel} <i className="xl:visible fa fas fa-arrow-right text-white ml-2 text-xl" />
            </ButtonSpan>
          ) : (
            <ButtonLink
              Element={Link}
              to={item.type === "corporate" && corporateAccount?.id ? item.path.replace(":slug", corporateAccount.id) : item.path}
              classes={"corporate-admin__dashboard__panel__link"}
            >
              {item.buttonLabel} <i className="xl:visible fa fas fa-arrow-right text-white ml-2 text-xl" />
            </ButtonLink>
          )}
        </div>
      </div>
    ));
  }

  return items.map(item => (
    <div
      className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2 mb-3"
      key={item.path}
    >
      <div className="bg-white h-full p-5 flex flex-col">
        {item.icon &&
          <span className="block text-center text-4xl mb-2">
            <i className={item.icon} />
          </span>
        }
        <h2 className="mb-4 text-center">{item.text}</h2>
        {item.listContent ? (
          <ul className="fa-ul mb-2">
            {item.listContent.map((item, index) => (
              <li key={index} className="my-2">
                  <span className="text-success fa-li">
                    <i className="fas fa-check-circle" />
                  </span>
                {item}
              </li>
            ))}
          </ul>
        ) : null}
        {item.desc ? <p className="flex-1 text-center mb-5">{item.desc}</p> : null}
        <ButtonBrand
          Element={Link}
          to={item.type === "corporate" && corporateAccount?.id ? item.path.replace(":slug", corporateAccount.id) : item.path}
          classes="w-full"
        >
          {item.buttonLabel}
        </ButtonBrand>
      </div>
    </div>
  ));
}