import axios from "axios";

export default (props, setState, id) => {
  axios
    .delete(
      `${window.API}/venues/${props.match.params.venue}/${props.name.replace(
        "_",
        "-"
      )}/${id}`,
      {
        headers: { Authorization: `Bearer ${props.token}` },
      }
    )
    .then(response => {
      setState.complete(response.data[`${props.name}s`]);
    });
};
