import React from "react";
import {connect} from "react-redux";
import {Field} from "redux-form";
import {withRouter} from "react-router-dom";
import {Select} from "Components/Form";

const options = [
  {
    text: "Quarter of a mile",
    value: 0.25,
  },
  {
    text: "Half a mile",
    value: 0.5,
  },
  {
    text: "1 mile",
    value: 1,
  },
  {
    text: "2 miles",
    value: 2,
  },
  {
    text: "3 miles",
    value: 3,
  },
  {
    text: "4 miles",
    value: 4,
  },
  {
    text: "5 miles",
    value: 5,
  },
  {
    text: "6 miles",
    value: 6,
  },
  {
    text: "7 miles",
    value: 7,
  },
  {
    text: "8 miles",
    value: 8,
  },
  {
    text: "9 miles",
    value: 9,
  },
  {
    text: "10 miles",
    value: 10,
  }
];

class EditRadius extends React.Component {
  searchOnChange = (e, v, pv, name) => {
    this.props.search({
      [name]: v,
    });
  };

  componentDidMount() {
    if (options.length <= 12) {
      for (let i = 20; i <= 100; i += 10) {
        options.push({
          text: `${i} miles`,
          value: i
        })
      }
      for (let i = 150; i > 100 && i <= 200; i += 50) {
        options.push({
          text: `${i} miles`,
          value: i
        })
      }
    }
  }

  render() {
    const convertMilesOptionsToMetres = () => {
      return options.map(o => ({
        text: o.text,
        value: o.value * 1609.344,
      }))
    }

    return (
      <Field
        name="radius"
        component={Select}
        label="Radius"
        options={convertMilesOptionsToMetres()}
        wrapperClassName="mb-5 mr-6 w-40"
        onChange={this.searchOnChange}
      />
    );
  }
}

export default withRouter(
  connect(
    undefined,
    {}
  )(EditRadius)
);
