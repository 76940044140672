import axios from "axios";

const getRoomPurposes = async (venueId = "", token) => {
  try {
    const response = await axios.get(
      `${window.API}/rfp/meeting-room-purpose/${venueId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (!response.data.data) {
      return {
        result: [],
      };
    }

    return {
      result: response.data.data.map(type => ({
        text: type.purpose,
        value: type.id,
      })),
    };
  } catch (error) {
    return { error, result: [] };
  }
};

const addRoomPurpose = async ({ venueId, purpose }, token) => {
  try {
    let res = await axios.post(
      `${window.API}/rfp/meeting-room-purpose`,
      {
        venue_id: venueId,
        purpose,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return {
      result: true,
      purpose: res && res.status === 201 && res.data && res.data.data && res.data.data.id ? {
        "text": res.data.data.purpose,
        "value": res.data.data.id
      } : {}
    };
  } catch (error) {
    return { error, result: false };
  }
};

export { getRoomPurposes, addRoomPurpose };
