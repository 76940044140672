import { isBefore, format, addDays, startOfDay, endOfDay } from "date-fns";

export default (start, end) => {
  start = startOfDay(start);
  end = endOfDay(end);

  if (isBefore(end, start)) {
    alert("End date must not be before start date");
    return;
  }

  const dates = [];
  for (let day = start; isBefore(day, end); day = addDays(day, 1)) {
    dates.push(format(day, "YYYY-MM-DD"));
  }

  return dates;
};
