export const color = "#e65100";

export const averageTemperatureFields = [
  "at_jan",
  "at_feb",
  "at_mar",
  "at_apr",
  "at_may",
  "at_jun",
  "at_jul",
  "at_aug",
  "at_sep",
  "at_oct",
  "at_nov",
  "at_dec",
];
