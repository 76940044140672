import {resetNamedLocations as resetAction} from 'modules/named-locations.js';
import { reset} from "redux-form";
function resetNamedLocations() {
    return dispatch => {
        dispatch(reset('advancedsearch'));
        dispatch(resetAction());
       
    }
}

export default resetNamedLocations;