import axios from 'axios';

import {
  ROLES_LIST_ERROR,
  ROLES_LIST_RECEIVED,
  ROLES_LIST_REQUEST,
  ROLES_UPDATE_RECEIVED_REQUEST,
  USER_ROLES_UPDATE_REQUEST,
  ROLES_UPDATE_RECEIVED_REQUEST_ERROR,
  CORPORATE_ROLES_LIST_REQUEST,
  CORPORATE_ROLES_LIST_RECEIVED, CORPORATE_ROLES_LIST_ERROR, CORPORATE_ROLES_UPDATE_RECEIVED_REQUEST
} from '../reducers/types';
import * as ROUTES from '../../Router/corporate_routes';

/** Roles */
export const rolesListRequest = () => ({
  type: ROLES_LIST_REQUEST
});

export const rolesListReceivedRequest = (data) => ({
  type: ROLES_LIST_RECEIVED,
  data
});

export const rolesListReceivedError = (error) => ({
  type: ROLES_LIST_ERROR,
  error
});

export const userRolesUpdateRequest = () => ({
  type: USER_ROLES_UPDATE_REQUEST
});

export const rolesUpdateReceivedRequest = (data) => ({
  type: ROLES_UPDATE_RECEIVED_REQUEST,
  data
});

export const rolesUpdateReceivedError = (error) => ({
  type: ROLES_UPDATE_RECEIVED_REQUEST_ERROR,
  error
});

/** Roles */
export const corporateRolesListRequest = () => ({
  type: CORPORATE_ROLES_LIST_REQUEST
});

export const corporateRolesUpdateReceivedRequest = (data) => ({
  type: CORPORATE_ROLES_UPDATE_RECEIVED_REQUEST,
  data
});

export const corporateRolesListReceivedRequest = (data) => ({
  type: CORPORATE_ROLES_LIST_RECEIVED,
  data
});

export const corporateRolesListReceivedError = (error) => ({
  type: CORPORATE_ROLES_LIST_ERROR,
  error
});

/** Roles */
export const fetchRoles = (token) => {
  return (dispatch) => {
    dispatch(rolesListRequest());
    return axios.get(window.API + ROUTES.API.ROLES.LIST, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(rolesListReceivedRequest(response.data)))
      .catch(error => dispatch(rolesListReceivedError(error)));
  }
}

/** Roles */
export const fetchCorporateRoles = (token) => {
  return (dispatch) => {
    dispatch(corporateRolesListRequest());
    return axios.get(window.API + ROUTES.API.ROLES.CORPORATE_LIST, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => dispatch(corporateRolesListReceivedRequest(response.data)))
        .catch(error => dispatch(corporateRolesListReceivedError(error)));
  }
}


export const giveUserRoles = (formValues, token) => {
  return (dispatch) => {
    dispatch(userRolesUpdateRequest());
    return axios.post(window.API + ROUTES.API.ROLES.UPDATE, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(rolesUpdateReceivedRequest(response.data))
    })
      .catch(error => dispatch(rolesUpdateReceivedError(error)));
  }
}

export const revokeUserRoles = (formValues, token) => {
  return (dispatch) => {
    dispatch(userRolesUpdateRequest());
    return axios.post(window.API + ROUTES.API.ROLES.REVOKE, formValues, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(rolesUpdateReceivedRequest(response.data))
    })
      .catch(error => dispatch(rolesUpdateReceivedError(error)));
  }
}