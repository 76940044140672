import {fetchNamedLocationSubGroupsPending, fetchNamedLocationSubGroupsSuccess, fetchNamedLocationSubGroupsError} from 'modules/named-locations.js';

function fetchNamedLocationSubGroups(city, type) {
    return dispatch => {
        dispatch(fetchNamedLocationSubGroupsPending());
        fetch(`${window.API}/named/locations/groups?city=${city}&type=${type}`)
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(fetchNamedLocationSubGroupsSuccess(res));
                return res;
            })
            .catch(error => {
                dispatch(fetchNamedLocationSubGroupsError(error));
            })
    }
}

export default fetchNamedLocationSubGroups;