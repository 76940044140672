import React from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { otherVenuesViewed } from "../../../../modules/analytics";

const OtherVenuesViewed = ({ venues }) =>
  venues.length ? (
    <div className="p-3">
      <div className="flex mb-3">
        <div className="flex-grow font-bold">Venue Name</div>
        <div className="flex-no-shrink w-16" />
      </div>
      {venues.map((item, i) => (
        <div className="flex mb-3" key={i}>
          <div
            className="flex-grow overflow-hidden pr-3 text-sm"
            style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
          >
            {item.name}
          </div>
          <div className="flex-no-shrink w-16">
            <a
              href={`/venue/${item.slug}`}
              target="_blank"
              rel="noopener noreferrer"
              className="font-normal"
            >
              view
            </a>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="p-3">
      <div className="flex mb-3">
        <div className="flex-grow font-bold text-dark-grey">
          No venues to display
        </div>
      </div>
    </div>
  );

export default connect(state => {
  const v = getFormValues("analytics")(state) || {};
  return {
    venues: otherVenuesViewed(v.venue_id, v.start_date, v.end_date)(state),
  };
})(OtherVenuesViewed);
