import React from "react";
import { connect } from "react-redux";

import { isAgency } from "../../modules/auth";
import { CATEGORY_GROUP_IDS } from "modules/cms";

import Breadcrumbs from "Components/Breadcrumbs";
import TrinityNews from "./TrinityNews";

const Voice = ({ isAgency }) => (
  <div className="container bg-white mt-4 p-5">
    <Breadcrumbs
      items={[
        { name: "Home", to: "/" },
        { name: (isAgency ? "Agency " : "") + "Voice" },
      ]}
    />
    <TrinityNews categories={CATEGORY_GROUP_IDS.voice} />
  </div>
);

export default connect(state => ({ isAgency: isAgency(state) }))(Voice);
