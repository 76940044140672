import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { token, addShortlist, isLoggedIn } from 'modules/auth';
import {
  reduxForm,
  Field,
  change,
  getFormValues,
  SubmissionError,
  destroy,
} from 'redux-form';
import parseErrors from 'modules/parse-errors';
import { Input } from 'Components/Form';
import Loading from 'Components/Loading';
import { ButtonBrand } from 'Components/Button';
import TrapezeHeader from 'Components/TrapezeHeader';
import { createHandleAxiosError } from '../../modules/api-tools';
import { venueInteraction } from '../../modules/analytics';
import {
  FaExchangeAlt,
  FaRegPlusSquare,
  FaRegMinusSquare,
} from 'react-icons/fa';
import VenuesList from './VenuesList';
import { CompareButton } from 'Components/Search/CompareButton';
import { getWidth } from 'redux-window';
import ShortlistsDisplay from 'Components/Shortlists/Display';
import RoundedCheckbox from 'Components/RoundedCheckbox';
import {VENUE_TAGS} from "../../modules/analytics/tags";

class CreateShortlist extends React.Component {
  state = {
    savingShortlist: false,
    savedShortlist: false,
    expanded: false,
    addToNewShortlist: false,
  };

  removeFromShortlist = venueIndex => {
    const venues = this.props.active.venues;

    this.props.change(
        this.props.form,
        'venues',
        venues.filter((_, i) => i !== venueIndex),
    );
  };

  clearShortlist = () => {
    this.props.change(this.props.form, 'venues', []);
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  submit = values => {
    this.setState({
      savingShortlist: true,
    });

    const { history, location, venueInteraction } = this.props;
    const handleAxiosError = createHandleAxiosError({ history, location });

    const venues = values.venues;

    if (venues) {
      venues.forEach(venue =>
          venueInteraction({
            type: VENUE_TAGS.VENUE_SHORTLISTED.ADD_TO_SHORTLIST,
            id: venue.id,
            data_score: venue.data_score,
          }),
      );
    }

    return axios[this.props.active.id ? 'patch' : 'post'](
        `${window.API}/users/me/shortlists`,
        values,
        {
          headers: { Authorization: `Bearer ${this.props.token}` },
        },
    )
        .then(response => {
          this.props.addShortlist(response.data);
          this.setState({
            savedShortlist: true,
            savingShortlist: false,
            addToNewShortlist: false,
          });
          if (!this.props.venue) {
            this.props.destroy(this.props.form);
          }
        })
        .catch(
            handleAxiosError(err => {
              this.setState({
                savedShortlist: false,
                savingShortlist: false,
              });

              throw new SubmissionError({
                ...parseErrors(err),
                _error: 'Sorry, there was an error submitting your data',
              });
            }),
        );
  };

  getSearchName = () => {
    const values = this.props.searchParams;
    if (!values) return;
    return values.location || values.name || values.venueType;
  };

  componentDidMount() {
    const searchName = this.getSearchName();
    const shortlistName = searchName ? `${searchName} Venues` : 'My Shortlist';
    this.props.change(this.props.form, 'name', shortlistName);
    if (!!this.props.venue) {
      this.props.change(this.props.form, 'venues', [this.props.venue]);
    }
  }

  componentWillUnmount() {
    if (this.props.venue) {
      this.props.destroy(this.props.form);
    }
  }

  render() {
    const { active, isLoggedIn, venue } = this.props;
    const venues = active && active.venues ? active.venues : [];
    return (
        <div className="mb-0 md:mb-5">
          <form onSubmit={this.props.handleSubmit(this.submit)}>
            <Fragment>
              <div
                  className={`flex flex-wrap justify-between items-center bg-white pointer ${this
                      .props.mobile && 'pr-4'}`}
                  onClick={() => this.toggleExpanded()}
              >
                <TrapezeHeader
                    bgColor="edge-blue"
                    title="Compare / Shortlist"
                    icon={<FaExchangeAlt className="mr-2 text-white text-lg"/>}
                    bigText
                    textLeft
                />
                {this.state.expanded ? (
                    <FaRegMinusSquare className="pointer text-xl md:text-base mr-3"/>
                ) : (
                    <FaRegPlusSquare className="pointer text-xl md:text-base mr-3"/>
                )}
              </div>
              {this.state.expanded && (
                  <div
                      className="bg-white p-3"
                      style={
                        !this.props.desktop
                            ? { maxHeight: 300, overflowY: 'scroll' }
                            : undefined
                      }
                  >
                    {venues.length === 0 && !venue && (
                        <>
                          <p className="font-small uppercase font-bold text-grey-md mb-3">
                            Selected venues
                          </p>
                          <p className="font-normal text-center">
                            You haven't selected any venues to compare yet - choose
                            some venues from the results
                          </p>
                          <div className="my-2">
                            <CompareButton venues={venues}/>
                          </div>
                        </>
                    )}
                    {isLoggedIn && !!venue && (
                        <div className="my-4 flex items-center">
                          <RoundedCheckbox
                              size={22}
                              checked={this.state.addToNewShortlist}
                              className="mr-1"
                              onClick={() => {
                                this.setState({
                                  addToNewShortlist: !this.state.addToNewShortlist,
                                });
                              }}
                          />
                          <span className="text-sm">Add to a new shortlist</span>
                        </div>
                    )}
                    {venues.length > 0 && !venue && (
                        <>
                          <VenuesList
                              items={venues}
                              onRemove={i => this.removeFromShortlist(i)}
                          />
                          {isLoggedIn && (
                              <div className="my-2">
                                <CompareButton
                                    venues={venues}
                                    onClick={venues.forEach(venue =>
                                        venueInteraction({
                                          type: VENUE_TAGS.COMPARE,
                                          id: venue.id,
                                          data_score: venue.data_score,
                                        }),
                                    )}
                                />
                              </div>
                          )}
                          {isLoggedIn && (
                              <div className="my-4 flex items-center">
                                <RoundedCheckbox
                                    size={22}
                                    checked={this.state.addToNewShortlist}
                                    className="mr-1"
                                    onClick={() =>
                                        this.setState({
                                          addToNewShortlist: !this.state.addToNewShortlist,
                                        })
                                    }
                                />
                                <span className="text-sm">
                          Add venues to a new shortlist
                        </span>
                              </div>
                          )}
                        </>
                    )}
                    {this.state.addToNewShortlist && (
                        <>
                          <div className="mt-2">
                            <p className="font-small uppercase font-bold text-grey-md mt-5 mb-3">
                              Shortlist name
                            </p>
                            <Field component={Input} name="name"/>
                          </div>
                          <ButtonBrand classes="w-full" submit>
                            {this.state.savingShortlist ? (
                                <div className="text-center">
                                  <Loading inline/>
                                </div>
                            ) : this.state.savedShortlist ? (
                                <React.Fragment>
                                  <i className="fal fa-star"/>
                                  &nbsp;&nbsp;Saved
                                </React.Fragment>
                            ) : (
                                <React.Fragment>&nbsp;Save shortlist</React.Fragment>
                            )}
                          </ButtonBrand>
                        </>
                    )}
                    {isLoggedIn ? (
                        <React.Fragment>
                          <div className="mb-6"/>
                          <ShortlistsDisplay
                              venues={venues}
                              selectedVenuesForm={this.props.form}
                          />
                        </React.Fragment>
                    ) : (
                        <Fragment>
                          <div className="mt-8">
                            <p className="mb-0">
                              Please{' '}
                              <Link
                                  to={`/login?return=${encodeURI(
                                      window.location.pathname,
                                  )}&logInToProceed=1`}
                                  className="font-normal"
                              >
                                login
                              </Link>{' '}
                              or{' '}
                              <Link to="/register" className="font-normal">
                                register
                              </Link>{' '}
                              to save your searches, create shortlists and compare
                              venues.
                            </p>
                          </div>
                        </Fragment>
                    )}
                  </div>
              )}
            </Fragment>
          </form>
        </div>
    );
  }
}

export default withRouter(
    reduxForm({
      destroyOnUnmount: false,
    })(
        connect(
            (state, props) => ({
              token: token(state),
              active: getFormValues(props.form)(state),
              searchParams: getFormValues(props.searchForm)(state),
              isLoggedIn: isLoggedIn(state),
              desktop: getWidth(state) > 991,
            }),
            { change, destroy, addShortlist, venueInteraction },
        )(CreateShortlist),
    ),
);
