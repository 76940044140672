import React from "react";
import { string, bool } from "prop-types";

class Error extends React.Component {
  static propTypes = {
    touched: bool,
    error: string,
    warning: string,
  };

  render() {
    const { touched, error, warning } = this.props;
    if (touched && error) {
      return <span className="text-warning font-normal mb-2">{error}</span>;
    }

    if (warning) {
      return <span className="text-orange font-normal mb-2">{warning}</span>;
    }

    return null;
  }
}

export default Error;
