import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import RfpProposals from "../../Components/RfpProposals/RfpProposals";
import Loading from "../../Components/Loading";
import BriefSummary from "../../Components/RfpBriefSection/BriefSummary";
import { ButtonPrimary, ButtonPrimaryRed } from "../../Components/Button";
import useService from "../../hooks/useService";
import { getEnquiryByAuthoriseId } from "../../services/rfpService/enquiries";
import { company, token, user } from "../../modules/auth";
import { authoriseProposal } from "../../services/rfpService/authoriseProposal";
import { declineProposal } from "../../services/rfpService/declineProposal";
import moment from "moment";
import { additionalDataCapture as v } from "../Rfp/validate";
import { LiveSearchSimple } from "../../Components/Form/LiveSearch/LiveSearchSimple";
import { nameFormatter } from "../../utils";
import { reassignAndAuthoriseProposal } from "../../services/rfpService/reassignAndAuthoriseProposal";
import { ROCHE_COMPANY_CODE } from "../../constants";

const mapStateToProps = state => {
  return {
    user: user(state),
    token: token(state),
    company: company(state),
  };
};


const ProposalShareAuthorise = ({ user, token, company }) => {
  const [client, setClient] = useState();
  const [shouldClearSearches, setShouldClearSearches] = useState(false);
  const [showReassignAndAuthorise, setShowReassignAndAuthorise] = useState(false);
  let { authoriseId } = useParams();
  let [submitted, setSubmitted] = useState(null);

  const { loading, data, error } = useService({
    service: getEnquiryByAuthoriseId,
    trigger: authoriseId,
    params: authoriseId,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className={"container"}>
        <div className={"bg-white p-5 mt-10"}>
          <h2 className={"mb-4 md:mt-4 md:mb-6 md:flex md:flex-row md:justify-between md:items-center uppercase"}>
            You don't have permissions to view this proposal
          </h2>
        </div>
      </div>
    );
  }

  let authorise = () => {
    authoriseProposal(authoriseId, token).then((response) => {
      setSubmitted("authorisation");
    });
  };

  let reassignAndAuthorise = () => {
    if (client) {
      reassignAndAuthoriseProposal(authoriseId, client.id, token).then((response) => {
        setSubmitted("authorisation");
      });
    }
  };

  let decline = () => {
    declineProposal(authoriseId, token).then((response) => {
      setSubmitted("rejection");
    });
  };

  if (Object.entries(data).length === 0 && data.constructor === Object) {
    return (
      <div>Proposal not found</div>
    );
  }

  let submittedText = (data) => {
    return <div
      className={(data.brief.authorised_state === "Authorised" || submitted === "authorisation" ? "bg-primary " : "bg-warning ") + "px-3 py-6 my-5 block font-bold text-white"}>
      <p className={"mb-0"}>We've emailed the person who submitted the enquiry to inform them you
        have {data.brief.authorised_state === "Declined" || submitted === "rejection" ? "rejected" : "authorised"} the
        brief.</p>
    </div>;
  };

  return (
    <div className={"row"}>
      <div className={`col col-w-full px-2 mb-0 bg-white`}>
        <div className="px-2 container pt-4">
          <h1 className="mb-4 uppercase">{data.brief.event_name}</h1>
          <p className="my-4">Ref: {data.ref_id}</p>
          {!submitted && data && (data.brief.authorised_state !== "Authorised" && data.brief.authorised_state !== "Declined") ?
            <p>The below event brief has been submitted
              by {data.client_details.email} on {moment(data.brief.created_at).format("DD/MM/YYYY")}. Please review the
              requirements and authorise or decline it below. If authorised this brief will be submitted to an agent and
              on to any venues to respond. If declined, then {data.client_details.name} will be notified by
              email</p> : null}
          {submitted ? <p>
              {submittedText(data)}
            </p> :
            data && (data.brief.authorised_state === "Authorised" || data.brief.authorised_state === "Declined") ?
              submittedText(data)
              :
              <Fragment>
                <div className="flex my-5">
                  <ButtonPrimary
                    classes={`px-6 py-2 flex items-center mr-5`}
                    style={{ height: "auto" }}
                    onClick={() => authorise()}
                  >
                    Authorise Enquiry
                    <i className="hidden sm:inline ml-5 fal fa-chevron-circle-right text-3xl" />
                  </ButtonPrimary>

                  {company && company.code !== ROCHE_COMPANY_CODE ? (
                    <ButtonPrimary
                      classes={`px-6 py-2 flex items-center mr-5`}
                      style={{ height: "auto" }}
                      onClick={() => setShowReassignAndAuthorise(!showReassignAndAuthorise)}
                    >
                      Authorise and Re-assign
                      <i className="hidden sm:inline ml-5 fal fa-chevron-circle-right text-3xl" />
                    </ButtonPrimary>
                  ) : null}

                  {/*<ButtonPrimaryRed*/}
                  {/*  classes={`px-6 py-2 flex items-center`}*/}
                  {/*  style={{ height: "auto" }}*/}
                  {/*  onClick={() => decline()}*/}
                  {/*>*/}
                  {/*  Decline Enquiry*/}
                  {/*  <i className="hidden sm:inline ml-5 fal fa-chevron-circle-right text-3xl" />*/}
                  {/*</ButtonPrimaryRed>*/}
                </div>
                {showReassignAndAuthorise && (
                  <div className="flex">
                    <div className="w-1/2">
                      <LiveSearchSimple
                        auth
                        label="Update Client User"
                        getText={result => result.first_name + " " + result.surname + ": " + result.email}
                        url={`${window.API}/users?companyUser=1&companyId=${company.id}`}
                        onChange={setClient}
                        query="companyUser"
                        onClear={() => setClient(null)}
                        shouldClearSearches={shouldClearSearches}
                        placeholder={client ? nameFormatter(client, true) : data?.client_details?.name}
                        fieldClassName="w-full"
                      />
                    </div>
                    <div className={"mx-5 pt-5"}>
                      <ButtonPrimary
                        classes={`px-6`}
                        style={{ height: 37, lineHeight: "37px" }}
                        onClick={() => reassignAndAuthorise()}
                      >
                        Submit
                        <i className="hidden sm:inline ml-5 fal fa-chevron-circle-right text-3xl" />
                      </ButtonPrimary>
                    </div>
                  </div>
                )}
              </Fragment>

          }
        </div>
        <div className="py-5 container">
          <BriefSummary {...data.brief} briefPreferences={data.brief_preferences} />
          {data.enquiry_venue_datas.length > 0 ? <RfpProposals data={data} publicView={true} /> : null}

        </div>
      </div>
    </div>

  );
};

export default connect(mapStateToProps, null)(ProposalShareAuthorise);
