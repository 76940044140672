import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { token, userEmail, updateUser } from "../../modules/auth";
import StripeCheckout from "react-stripe-checkout";

class BuyItNowGetCard extends Component {
  onToken = stripeToken => {
    const {
      apiToken,
      onSuccess,
      onError,
      updateUser,
      receivedToken,
    } = this.props;

    if (typeof receivedToken === "function") {
      receivedToken();
    }

    axios
      .post(
        window.API + "/users/me/card",
        { stripeToken: stripeToken.id },
        {
          headers: { Authorization: `Bearer ${apiToken}` },
        }
      )
      .then(response => {
        const { data } = response;
        updateUser({ user: data });

        if (typeof onSuccess === "function") {
          onSuccess(data);
        }
      })
      .catch(err => {
        if (typeof onError === "function") {
          onError(err);
        }
      });
  };

  render() {
    const { userEmail } = this.props;

    return (
      <StripeCheckout
        stripeKey={window.STRIPE_KEY}
        email={userEmail}
        allowRememberMe={false}
        label="Setup Billing"
        panelLabel="Setup Billing"
        name="EDGE Venues"
        zipCode={true}
        billingAddress={true}
        locale="auto"
        currency="GBP"
        token={this.onToken}
        children={this.props.children}
      />
    );
  }
}

export default connect(
  state => ({
    apiToken: token(state),
    userEmail: userEmail(state),
  }),
  { updateUser }
)(BuyItNowGetCard);
