import React from "react";
import componentClasses from "modules/component-classes";

const Panel = ({
  children,
  defaultClasses = ["p-3 mb-5 bg-off-white"],
  additionalClasses = [],
  removeClasses = [],
}) => {
  const className = componentClasses(
    defaultClasses,
    additionalClasses,
    removeClasses
  );

  return <div className={className}>{children}</div>;
};

export default Panel;
