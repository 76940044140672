import {
  string,
  func,
  bool,
  number,
  array,
  arrayOf,
  oneOfType,
} from "prop-types";

export default {
  // user
  formId: string.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  validate: oneOfType([array, func]),
  setOther: oneOfType([array, string]),
  clearOnSearch: arrayOf(string),
  labelClassName: string,
  fieldClassName: string,
  placeholder: string,
  minLength: number,
  auth: bool,
  params: func,
  getText: func,
  icon: string,
  wrapperClassName: string,

  // stp
  query: string,
  queryId: oneOfType([string, number]),
  multiValues: array,
  token: string,

  // dtp
  change: func.isRequired,
  clearFields: func.isRequired,
};

export const defaultProps = {
  params: value => ({ query: value, raw: 1 }),
  getText: result => result.name,
  minLength: 3,
  clearOnSearch: [],
  auth: false,
  query: "",
};
