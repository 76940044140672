import React, {useEffect, useState} from "react";

import {fetchPriceBandingData, fetchVenuePriceBandingData} from "../../services/priceBandingService/fetchPriceBandings";
import {token} from "../../modules/auth";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import Loading from "../../Components/Loading";
import PriceBandingBanner from "../../Components/PriceBanding/PriceBandingBanner";

const mapDispatchToProps = {};

const mapStateToProps = state => ({
    token: token(state),
});

let PricingBandsSingleResult = ({venue, match, details, token, PB=false}) => {

    let [priceBandings, setPriceBandings] = useState(PB)
    let [venuePriceBandings, setVenuePriceBandings] = useState(false)
    let [loading, setLoading] = useState(true)

    useEffect(() => {
        if(!PB) {
            fetchPriceBandingData(token).then((response) => {
                setPriceBandings(response)
            })
        }
        fetchVenuePriceBandingData(token, venue.slug).then((response) => {
            if(Object.keys(response).length !== 0) {
                setVenuePriceBandings(response)
            }
            setLoading(false)
        })
    }, []);

    if (loading) {
        return <Loading/>
    }

    let innerHtml = (code, title) => {
        return (
            <div
                key={code}
                className="flex flex-wrap border-b-2 border-light-grey py-2"
            >
                <div className="w-3/5">
                            <span className="font-bold">
                              {title}
                            </span>
                    <PriceBandingBanner type={code} vPB={venuePriceBandings} PB={priceBandings} />
                </div>

                <div className="w-2/5 flex justify-end">
                    {
                        !venuePriceBandings[code + '_price_banding_rate'] || venuePriceBandings['is_international'] ? (
                            <span className="font-heading text-edge-blue">
                                        POA
                                      </span>
                        ) : (
                            <span
                                className="font-heading text-edge-blue"
                                dangerouslySetInnerHTML={{
                                    __html: `${
                                        details.currency
                                            ? details.currency.html_code
                                            : ''
                                    }${parseFloat(
                                        venuePriceBandings[code + '_price_banding_rate'] ? venuePriceBandings[ code +'_price_banding_rate']['min_price'] : 0

                                    ).toFixed(2)} - ${
                                        details.currency
                                            ? details.currency.html_code
                                            : ''
                                    }${parseFloat(
                                        venuePriceBandings[code + '_price_banding_rate'] ? venuePriceBandings[code + '_price_banding_rate']['max_price'] : 0

                                    ).toFixed(2)}`,
                                }}
                            />
                        )}

                </div>
                <br/>
                <div className={'w-full pt-2'}><span >{venuePriceBandings[code +'_exclusion_text']}</span></div>
            </div>
        )
    }



    return (
        <div>
            {
                venuePriceBandings['show_24hr_on_profile'] || ! venuePriceBandings?
                innerHtml('twenty_four_hr', '24 Hour Delegate Rate') : null

            }
            {
                !venuePriceBandings['show_24hr_on_profile'] && venuePriceBandings ?
                innerHtml('bb', 'Bed & Breakfast Delegate Rate') : null
            }
            {innerHtml('ddr', 'Day Delegate Rate')}
        </div>
    )

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PricingBandsSingleResult));