import axios from 'axios';
import * as ROUTES from "../../Router/misc_routes";
import {
  VAT_RATES_LIST_REQUEST,
  VAT_RATES_LIST_RECEIVED,
  VAT_RATES_LIST_ERROR, NOMINAL_CODE_LIST_REQUEST, NOMINAL_CODE_LIST_RECEIVED, NOMINAL_CODE_LIST_ERROR
} from "../reducers/types";
import handleErrors from "./utils";

export const nominalCodeRequest = () => ({
  type: NOMINAL_CODE_LIST_REQUEST
});

export const nominalCodeReceivedRequest = (data) => ({
  type: NOMINAL_CODE_LIST_RECEIVED,
  data
});

export const nominalCodeReceivedError = (error) => ({
  type: NOMINAL_CODE_LIST_ERROR,
  error
});

export const fetchNominalCodes = (token, agencyId) => {
  return (dispatch) => {
    dispatch(nominalCodeRequest());
    return axios.get(window.API + ROUTES.API.NOMINAL_CODE.LIST, {
      headers: {Authorization: `Bearer ${token}`},
    }).then(response => {
      dispatch(nominalCodeReceivedRequest(response))
    })
      .catch(error => {
        dispatch(nominalCodeReceivedError(error))
        handleErrors(error);
      });
  }
}