import {LAST_SIMPLE_SEARCH} from "../reducers/types";
import * as ROUTES from "../../Router/corporate_routes";
import {companyReceivedError, companyReceivedRequest, companyRequest} from "./company";

export const lastSSimpleSearch= (data) => ({
    type: LAST_SIMPLE_SEARCH,
    data
});

export const storeLastSimpleSearch = (formValues) => {
    return (dispatch) => {
        dispatch(lastSSimpleSearch(formValues));
    }
}