import React, {useEffect} from "react";
import {connect} from "react-redux";
import {isAgency, isAgencyApproved} from "../../modules/auth";
import {capitalize} from "../../modules/string-tools";
import CmsPage from "../../Components/CmsPage";
import Breadcrumbs from "../../Components/Breadcrumbs";
import {withRouter} from "react-router-dom";
import {generalInteraction} from "../../modules/analytics";

const SinglePost = ({location, match, isAgency, generalInteraction}) => {
  const post = location.state && location.state.post;
  const {section} = match.params;
  const slug = location.pathname.split('/').pop()

  useEffect(() => {
    generalInteraction({
      type: "general_article_page_view",
      data: slug
    })
  }, []);

  const prependAgency = isAgency && isAgencyApproved && section !== "the-word";

  return (
    <div className="container bg-white mt-4 p-5 pb-10 mb-12">
      <Breadcrumbs
        items={[
          {name: "Home", to: "/"},
          {
            name:
              (prependAgency ? "Agency " : "") +
              capitalize(section.replace("-", " ")),
            to: "/" + section,
          },
          {name: "EDGE Insights"},
        ]}
      />
      <CmsPage slug={slug} page={post}/>
    </div>
  );
};

export default withRouter(
  connect(state => ({
      isAgency: isAgency(state),
      isAgencyApproved: isAgencyApproved(state)
    }
  ), {generalInteraction})(SinglePost)
);
